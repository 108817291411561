import {GetOfferSellTransactionTypes} from './actionTypes';
import {
    IGetListFilterDebounceOfferSellTransaction,
    IGetListFilterOfferSellTransaction,
    IGetOfferSellTransaction,
    IGetOfferSellTransactionFaild,
    IGetOfferSellTransactionFetching,
    IGetOfferSellTransactionSuccess
} from './interfaces';

export const getOfferSellTransactionAtFetching = (isFetching:boolean) : IGetOfferSellTransactionFetching  => ({
    type: GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION_FETCHING,
    isFetching
})

export const getOfferSellTransaction = (payload: Object) : IGetOfferSellTransaction => ({
    type: GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION,
    payload
})

export const getOfferSellTransactionSuccess = (data:any) : IGetOfferSellTransactionSuccess => ({
    type: GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION_SUCCESS,
    data
})

export const getOfferSellTransactionFaild = (error:any) : IGetOfferSellTransactionFaild => ({
    type: GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION_FAILD,
    error
})

export const setFilterOfferSellTransaction = (payload: any): IGetListFilterOfferSellTransaction => ({
    type: GetOfferSellTransactionTypes.GET_LIST_FILTER_OFFER_SELL_TRANSACTION,
    payload
})

export const setFilterDebounceOfferSellTransaction = (payload: any): IGetListFilterDebounceOfferSellTransaction => ({
    type: GetOfferSellTransactionTypes.GET_LIST_FILTER_DEBOUNCE_OFFER_SELL_TRANSACTION,
    payload 
})