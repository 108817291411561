import {put, takeEvery} from 'redux-saga/effects';
import { handleGetWalletsUser } from 'src/apis/service/Wallet';
import { getWalletsUserFaild, getWalletsUserIsFetching, getWalletsUserSuccess, GetWalletsUserType } from 'src/store/actions';

function* getListWalletsUserSaga(): Generator<any> {
    yield put(getWalletsUserIsFetching(true))
    try {
      const data:any = yield handleGetWalletsUser()      
      if(data && data?.status === 200){
        yield put(getWalletsUserSuccess(data));
      } else {
        yield put(getWalletsUserFaild('error'))
      }
    } catch (error) {
        yield put(getWalletsUserFaild(error))
    }
}

export function* watchGetListWalletsUser() {
   yield takeEvery(GetWalletsUserType.GET_WALLETS_USER , getListWalletsUserSaga)
}