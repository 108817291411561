import { AnyAction, Reducer } from 'redux';
import { UnPostAndPostAnnouncementsType } from 'src/store/actions/Announcement/Announcements/UnpostAndPost';
import { initialUnPostAndPostAnnouncementsState, IUnPostAndPostAnnouncementsState } from 'src/store/reducers/Announcement/Announcements/SetUnPostAndPost';


export const UnPostAndPostAnnouncementsReducer: Reducer = (
  state: IUnPostAndPostAnnouncementsState = initialUnPostAndPostAnnouncementsState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        success: true
      }
    }
    default:
      return state;
  }
};