import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeTransactionsAgent } from 'src/apis/service/P2PExchange';
import { getP2PExchangeTransactionsAgentFaild, getP2PExchangeTransactionsAgentIsFetching, getP2PExchangeTransactionsAgentSuccess, GetP2PExchangeTransactionsAgentType, setFilterP2PExchangeTransactionsAgent } from 'src/store/actions/P2PExchangeTransactionsAgent';

function* getListP2PExchangeTransactionsAgentSaga(action: any): Generator<any> {
    yield put(getP2PExchangeTransactionsAgentIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeTransactionsAgent, payload)  
      if(data && data?.status === 200){
        yield put(getP2PExchangeTransactionsAgentSuccess(data));
      } else {
        yield put(getP2PExchangeTransactionsAgentFaild('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeTransactionsAgentFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterP2PExchangeTransactionsAgent(action.payload));
}

export function* watchGetListP2PExchangeTransactionsAgent() {
  yield takeEvery(GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT , getListP2PExchangeTransactionsAgentSaga)
  yield debounce(500, GetP2PExchangeTransactionsAgentType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_TRANSACTIONS_AGENT, handleSearchDebounce)
}