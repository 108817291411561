import { AnyAction, Reducer } from 'redux';
import { IAgentTransferMoneyState, initialAgentTransferMoneyState } from './state';
import { AgentTransferMoneyTypes } from 'src/store/actions/agentTransferMoney';

export const AgentTransferMoneyReducer: Reducer = (
  state: IAgentTransferMoneyState = initialAgentTransferMoneyState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
