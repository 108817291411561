import {AgentSubmitBankTypes} from './actionTypes';
import { IAgentSubmitBank, IAgentSubmitBankFetching, IAgentSubmitBankSuccess, IAgentSubmitBankError, IAgentSubmitBankFilter, IAgentSubmitBankFiterDebounce } from "./interfaces";


export const agentSubmitBank = (payload:Object,getData:any): IAgentSubmitBank => ({
    type: AgentSubmitBankTypes.AGENT_SUBMIT_BANK,
    payload,
    getData
})

export const agentSubmitBankFetching = (isFetching:boolean) : IAgentSubmitBankFetching => ({
    type: AgentSubmitBankTypes.AGENT_SUBMIT_BANK_FETCHING,
    isFetching
})

export const agentSubmitBankSuccess = (data:any) : IAgentSubmitBankSuccess => ({
   type: AgentSubmitBankTypes.AGENT_SUBMIT_BANK_SUCCESS,
   data
})

export const agentSubmitBankError = (errorMsg:any) : IAgentSubmitBankError => ({
    type: AgentSubmitBankTypes.AGENT_SUBMIT_BANK_FAILD,
    error:errorMsg
})

export const agentSubmitBankFilter = (payload: any): IAgentSubmitBankFilter => ({
    type: AgentSubmitBankTypes.AGENT_SUBMIT_BANK_FILTER,
    payload
})

export const agentSubmitBankFilterDebounce = (payload: any): IAgentSubmitBankFiterDebounce => ({
    type: AgentSubmitBankTypes.AGENT_SUBMIT_BANK_FILTER_DEBOUNCE,
    payload 
})
