import { Set2FAEnabledTypes } from './actionTypes';
import { ISet2FAEnabled, ISet2FAEnabledFetching, ISet2FAEnabledSuccess, ISet2FAEnabledError, ISet2FAEnabledFilter, ISet2FAEnabledFiterDebounce } from "./interfaces";


export const set2FAEnabled = (payload: Object): ISet2FAEnabled => ({
    type: Set2FAEnabledTypes.SET2FA_ENABLED,
    payload
})

export const set2FAEnabledFetching = (isFetching: boolean): ISet2FAEnabledFetching => ({
    type: Set2FAEnabledTypes.SET2FA_ENABLED_FETCHING,
    isFetching
})

export const set2FAEnabledSuccess = (data: any): ISet2FAEnabledSuccess => ({
    type: Set2FAEnabledTypes.SET2FA_ENABLED_SUCCESS,
    data
})

export const set2FAEnabledError = (errorMsg: any): ISet2FAEnabledError => ({
    type: Set2FAEnabledTypes.SET2FA_ENABLED_FAILD,
    error: errorMsg
})

export const set2FAEnabledFilter = (payload: any): ISet2FAEnabledFilter => ({
    type: Set2FAEnabledTypes.SET2FA_ENABLED_FILTER,
    payload
})

export const set2FAEnabledFilterDebounce = (payload: any): ISet2FAEnabledFiterDebounce => ({
    type: Set2FAEnabledTypes.SET2FA_ENABLED_FILTER_DEBOUNCE,
    payload
})
