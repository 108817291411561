import { AnyAction, Reducer } from 'redux';
import { IGetAgentDirectTransfersState, initialGetAgentDirectTransfersState } from './state';
import { GetAgentDirectTransfersTypes } from 'src/store/actions/getAgentDirectTransfers';

export const GetAgentDirectTransfersReducer: Reducer = (
  state: IGetAgentDirectTransfersState = initialGetAgentDirectTransfersState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
