import { UpdateLanguageTextType } from './actionTypes';
import { IUpdateLanguageText, IFetchingUpdateLanguageText, IUpdateLanguageTextSuccess, IUpdateLanguageTextError } from './interfaces';
import { History } from 'history';

export const updateLanguageTextIsFetching = (isFetching:boolean) : IFetchingUpdateLanguageText => ({
    type:UpdateLanguageTextType.UPDATE_LANGUAGE_TEXT_FETCHING,
    isFetching
})

export const updateLanguageText = (payload: Object, history: History) : IUpdateLanguageText => ({
    type:UpdateLanguageTextType.UPDATE_LANGUAGE_TEXT,
    payload,
    history
})

export const updateLanguageTextSuccess = (data:any) : IUpdateLanguageTextSuccess => ({
    type:UpdateLanguageTextType.UPDATE_LANGUAGE_TEXT_SUCCESS,
    data
})

export const updateLanguageTextFaild = (error:any) : IUpdateLanguageTextError => ({
    type:UpdateLanguageTextType.UPDATE_LANGUAGE_TEXT_ERROR,
    error
})