import * as Sentry from '@sentry/react';
import Request from '../../config';
import { setStorageItem } from 'src/utils/newUtils/localStorageHelper';
import { LocalStorageKey } from 'src/contants/constantUtils/localStorageKey';
import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';

const requestApiUser = new Request({
  url: process.env.REACT_APP_BASE_URL_ACCOUNT,
});

const requestApiTransfer = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});

const requestApiCrypto = new Request({
  url: process.env.REACT_APP_BASE_AGENT_CRYPTO_URL,
});

const handleAgentChangePINCode = async (request: any): Promise<any[]> => {
  try {
    return await requestApiUser.post(`/Account/PIN/Update`, request);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAgentCryptoSetting = async (): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/custodial/wallet-settings`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListCryptoWallet = async (): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/custodial/wallets`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListCryptoDetail = async (walletId: string): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/custodial/wallets/${walletId}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentBuySearch = async (data: any): Promise<any> => {
  try {
    return await requestApiTransfer.post(`/Offer/Buy/Search`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentSellSearch = async (data: any): Promise<any> => {
  try {
    return await requestApiTransfer.post(`/Offer/Sell/Search`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentTransferMoney = async (data: any): Promise<any> => {
  try {
    return await requestApiTransfer.post(`/Wallet/WLT2WLT`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentGetAllWallets = async (): Promise<any> => {
  try {
    return await requestApiTransfer.get(`/Wallet/GetAllWallets`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentInviteUser = async (data: any): Promise<any> => {
  try {
    return await requestApiTransfer.post(`/Sms/Invite`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetCryptoExchangeOffersList = async (
  params: any
): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/custodial/p2p/offers`, { params });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleEditWalletCrypto = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.patch(
      `/custodial/wallets/${data?.walletId}`,
      data
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateCryptoExchangeOffersList = async (
  data: any
): Promise<any[]> => {
  try {
    return await requestApiCrypto.post(`/custodial/p2p/offers`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetTokenDetail = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(
      `/custodial/wallets/${data.walletId}/tokens/${data.cryptoCurrency}`
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateCryptoExchangeOffersList = async (
  offerId: string,
  data: any
): Promise<any[]> => {
  try {
    return await requestApiCrypto.patch(
      `/custodial/p2p/offers/${offerId}`,
      data
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
const handleGetCryptoTransactionHistory = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(
      `/custodial/wallets/${data.walletId}/tokens/${data.cryptoCurrency}/transactions`,
      { params: data }
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetCryptoTransactionFee = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(`/custodial/transactions/commission`, {
      params: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetCryptoTransactionWithdrawFee = async (
  data: any
): Promise<any> => {
  try {
    return await requestApiCrypto.get(
      `/custodial/transactions/commission/withdraw`,
      {
        params: data,
      }
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCheckAddressBooks = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(
      `/custodial/address-books/${data?.address}`,
      {
        params: data,
      }
    );
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const handleAddAddressBook = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.post(`/custodial/address-books`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleEditAddressBook = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.patch(
      `/custodial/address-books/${data?.addressBookId}`,
      data
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListAddressBooks = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(`/custodial/address-books`, {
      params: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteCryptoExchangeOffersList = async (
  offerId: string
): Promise<any[]> => {
  try {
    return await requestApiCrypto.delete(`/custodial/p2p/offers/${offerId}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleSet2FAEnabled = async (data: any): Promise<any[]> => {
  try {
    return await requestApiUser.post(`/Token/2FA/Google/Enable`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleSet2FADisable = async (): Promise<any[]> => {
  try {
    return await requestApiUser.post(`/Token/2FA/Google/Disable`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGenerateCode2FA = async (): Promise<any[]> => {
  try {
    return await requestApiUser.post(`/Token/2FA/Google/Generate`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetCryptoExchangeOffersListHistories = async (
  offerId: string,
  params: any
): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(
      `custodial/p2p/offers/${offerId}/histories`,
      { params }
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteAddressBooks = async (addressBookId: any): Promise<any> => {
  try {
    return await requestApiCrypto.delete(
      `/custodial/address-books/${addressBookId}`
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleWithdrawCrypto = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.post(
      `/custodial/wallets/${data?.walletId}/withdraw`,
      data
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetWithdrawLimit = async (currencyCode: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(
      `/custodial/tokens/${currencyCode}/withdraw-limitation`
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCheckPhoneHasWallet = async (phone: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(
      `/custodial/wallets/find-by-phone/${phone}`
    );
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const handleTransferCrypto = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.post(
      `/custodial/wallets/${data.walletId}/transfer`,
      data
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handlePostCryptoCreateExchangeTransaction = async (
  offerId: string,
  data: any
): Promise<any[]> => {
  try {
    return await requestApiCrypto.post(
      `/custodial/p2p/offers/${offerId}/accept`,
      data
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleSetAgentCryptoSetting = async (data: any): Promise<any> => {
  try {
    return await requestApiCrypto.post(`/custodial/wallet-settings`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentGetSupportedCrypto = async (): Promise<any> => {
  try {
    const result = await requestApiCrypto.get(
      '/product/supported-crypto-currencies'
    );
    setStorageItem(
      LocalStorageKey.ListCryptoSupported,
      JSON.stringify(result?.data)
    );
    return result;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListTokenSupport = async (): Promise<any> => {
  try {
    const result = await requestApiCrypto.get('/custodial/tokens');
    setStorageItem(
      LocalStorageKey.ListCryptoSupported,
      JSON.stringify(result?.data)
    );
    return result;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetChatMessByTransactionId = async (
  transactionId: string,
  currentPage: number
): Promise<any> => {
  try {
    return await requestApiUser.get(
      `/ChatMessage/HistoryTransaction?TransactionId=${transactionId}&currentPage=${currentPage}&pageLimit=${PAGE_SIZE_DEFAULT}`
    );
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const handleReadChatMessage = async (
  transactionId: string
): Promise<any> => {
  try {
    return await requestApiUser.post(
      `/ChatMessage/ReadMessageTransaction?TransactionId=${transactionId}`
    );
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

const handleUploadFileChat = async (data: any): Promise<any> => {
  const url = `/UploadFileToGoogleCloudStorage`;
  try {
    return await requestApiUser.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
};

export {
  handleAgentChangePINCode,
  handleGetAgentCryptoSetting,
  handleGetListCryptoWallet,
  handleGetListCryptoDetail,
  handleAgentBuySearch,
  handleAgentSellSearch,
  handleAgentTransferMoney,
  handleAgentGetAllWallets,
  handleAgentInviteUser,
  handleGetCryptoExchangeOffersList,
  handleCreateCryptoExchangeOffersList,
  handleUpdateCryptoExchangeOffersList,
  handleEditWalletCrypto,
  handleGetTokenDetail,
  handleGetCryptoTransactionHistory,
  handleDeleteCryptoExchangeOffersList,
  handleGetCryptoTransactionFee,
  handleCheckAddressBooks,
  handleAddAddressBook,
  handleGetListAddressBooks,
  handleEditAddressBook,
  handleSet2FAEnabled,
  handleSet2FADisable,
  handleGenerateCode2FA,
  handleGetCryptoExchangeOffersListHistories,
  handleDeleteAddressBooks,
  handleWithdrawCrypto,
  handleGetWithdrawLimit,
  handleCheckPhoneHasWallet,
  handleTransferCrypto,
  handlePostCryptoCreateExchangeTransaction,
  handleSetAgentCryptoSetting,
  handleAgentGetSupportedCrypto,
  handleGetListTokenSupport,
  handleGetCryptoTransactionWithdrawFee,
  handleGetChatMessByTransactionId,
  handleUploadFileChat,
  handleReadChatMessage
};
