import { AnyAction, Reducer } from 'redux';
import { IGetListTokenSupportState, initialGetListTokenSupportState } from './state';
import { GetListTokenSupportTypes } from 'src/store/actions/getListTokenSupport';

export const GetListTokenSupportReducer: Reducer = (
  state: IGetListTokenSupportState = initialGetListTokenSupportState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
