import {GetTokenDetailTypes} from './actionTypes';
import { IGetTokenDetail, IGetTokenDetailFetching, IGetTokenDetailSuccess, IGetTokenDetailError, IGetTokenDetailFilter, IGetTokenDetailFiterDebounce } from "./interfaces";


export const getTokenDetail = (payload:Object): IGetTokenDetail => ({
    type: GetTokenDetailTypes.GET_TOKEN_DETAIL,
    payload
})

export const getTokenDetailFetching = (isFetching:boolean) : IGetTokenDetailFetching => ({
    type: GetTokenDetailTypes.GET_TOKEN_DETAIL_FETCHING,
    isFetching
})

export const getTokenDetailSuccess = (data:any) : IGetTokenDetailSuccess => ({
   type: GetTokenDetailTypes.GET_TOKEN_DETAIL_SUCCESS,
   data
})

export const getTokenDetailError = (errorMsg:any) : IGetTokenDetailError => ({
    type: GetTokenDetailTypes.GET_TOKEN_DETAIL_FAILD,
    error:errorMsg
})

export const getTokenDetailFilter = (payload: any): IGetTokenDetailFilter => ({
    type: GetTokenDetailTypes.GET_TOKEN_DETAIL_FILTER,
    payload
})

export const getTokenDetailFilterDebounce = (payload: any): IGetTokenDetailFiterDebounce => ({
    type: GetTokenDetailTypes.GET_TOKEN_DETAIL_FILTER_DEBOUNCE,
    payload 
})
