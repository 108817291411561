import { AnyAction, Reducer } from 'redux';
import { GetP2PBuyTransactionsType } from 'src/store/actions';
import { IListP2PBuyTransactionsState, initialListP2PBuyTransactionsState } from './state';


export const GetListP2PBuyTransactionsReducer: Reducer = (
  state: IListP2PBuyTransactionsState = initialListP2PBuyTransactionsState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS_SUCCESS : {
      return {
        ...state,
        data: action?.data?.items,
        isFetching: false,
        pagination: action.data.totalCount
      }
    }
    case GetP2PBuyTransactionsType.SET_LIST_FILTER_P2P_BUY_TRANSACTIONS: {
      return {
          ...state,
          filter: action.payload
      }
    }
      default:
        return state;
    }
};