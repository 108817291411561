import { RemoveLanguageTextType } from './actionTypes';
import { IFetchingRemoveLanguageText, IRemoveLanguageText, IRemoveLanguageTextError, IRemoveLanguageTextSuccess } from './interfaces';


export const removeLanguageTextIsFetching = (isFetching: boolean): IFetchingRemoveLanguageText => ({
    type: RemoveLanguageTextType.REMOVE_LANGUAGE_TEXT_FETCHING,
    isFetching
})

export const removeLanguageText = (languageKey?: string): IRemoveLanguageText => ({
    type: RemoveLanguageTextType.REMOVE_LANGUAGE_TEXT,
    languageKey
})

export const removeLanguageTextSuccess = (data: any): IRemoveLanguageTextSuccess => ({
    type: RemoveLanguageTextType.REMOVE_LANGUAGE_TEXT_SUCCESS,
    data
})

export const removeLanguageTextFaild = (error: any): IRemoveLanguageTextError => ({
    type: RemoveLanguageTextType.REMOVE_LANGUAGE_TEXT_ERROR,
    error
})