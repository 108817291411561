import { AnyAction, Reducer } from 'redux';
import { IListAllAdminBOState, initialListAllAdminBOState } from './state';
import { GetAllAdminBOType } from '../../../actions/admin-managements/getAllAdminBO';

export const GetListAllAdminBOReducer: Reducer = (
  state: IListAllAdminBOState = initialListAllAdminBOState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAllAdminBOType.GET_ADMIN_BO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAllAdminBOType.GET_ADMIN_BO_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAllAdminBOType.GET_ADMIN_BO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAllAdminBOType.FILTER_ADMIN_BO: {
      return {
        ...state,
        filter: action.payload
      }
    }
    default: {
      return state;
    }
  }
};