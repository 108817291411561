
import { GetDataBankAccountAgent } from "./actionTypes";
import {
  IFetchingDataBankAccountAgent,
  IGetDataBankAccountAgent,
  IGetDataBankAccountAgentError,
  IGetDataBankAccountAgentSuccess
} from "./interfaces";

export const getDataBankAccountAgentIsFetching = (isFetching:boolean) : IFetchingDataBankAccountAgent => ({
    type: GetDataBankAccountAgent.GET_DATA_BANK_AGENT_FETCHING,
    isFetching
})

export const getDataBankAccountAgent =  (payload:any) : IGetDataBankAccountAgent => ({
    type: GetDataBankAccountAgent.GET_DATA_BANK_AGENT,
    payload: payload
})

export const getDataBankAccountAgentSuccess = (data:any[]) : IGetDataBankAccountAgentSuccess => ({
    type: GetDataBankAccountAgent.GET_DATA_BANK_AGENT_SUCCESS,
    data
})

export const getDataBankAccountAgentError = (error:any) : IGetDataBankAccountAgentError => ({
    type: GetDataBankAccountAgent.GET_DATA_BANK_AGENT_ERROR,
    error
})