import {put, takeEvery,debounce} from 'redux-saga/effects';
import {ApplyKycDeclarationTypes} from '../../actions/ApplyKycDeclaration';
import {ApplyKycDeclarationFetching,ApplyKycDeclarationSuccess,ApplyKycDeclarationError, ApplyKycDeclarationFilter} from '../../actions/ApplyKycDeclaration/action';
import {handleApplyKycDeclaration, handleGetListUser} from '../../../apis/service/user';
import { getAllKYC } from 'src/store/actions/getAllKYC';

function* ApplyKycDeclarationSaga(action:any):Generator<any> {
    yield put(ApplyKycDeclarationFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleApplyKycDeclaration(payload)
      if(data && data?.status === 200){
        yield put(getAllKYC())
        yield put(ApplyKycDeclarationSuccess(data))
      } else {
        yield put(ApplyKycDeclarationError('error'))
      }
    } catch (error) {
        yield put(ApplyKycDeclarationError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(ApplyKycDeclarationFilter(action.payload));
}
export function* watchApplyKycDeclaration() {
   yield takeEvery(ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION, ApplyKycDeclarationSaga)
   yield debounce(500,ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_FILTER_DEBOUNCE,handleSearchDebounce)
}
