import { debounce, put, takeLatest } from 'redux-saga/effects';
import { handleGetListUserScore } from 'src/apis/service/user';
import { getListUserScoreError, getListUserScoreFetching, getListUserScoreSuccess, GetListUserScoreType, setFilterUserScore } from 'src/store/actions';

function* getListUserScore(action:any):Generator<any>{
   yield put(getListUserScoreFetching(true)) 
   const { payload } = action;
    try {
      const dataListUserScore:any = yield handleGetListUserScore(payload)
      if (dataListUserScore.status === 200) {
        yield put(getListUserScoreSuccess(dataListUserScore))
      }  
    } catch (error) {
        yield put(getListUserScoreError(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterUserScore(action.payload));
}

export function* watchGetListUserScore () {
  yield takeLatest(GetListUserScoreType.GET_LIST_USER_SCORE, getListUserScore);
  yield debounce(500, GetListUserScoreType.GET_LIST_FILTER_DEBOUNCE_USER_SCORE, handleSearchDebounce)
}