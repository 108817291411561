import { AnyAction, Reducer } from 'redux';
import { RemoveWalletAdminBOState, initialRemoveWalletAdminBOState } from './state';
import { DeleteWalletAdminBOType } from 'src/store/actions/WalletAdminBO/deleteWalletAdminBO';
import { toastSuccess } from 'src/helpers/toastHelper';
export const RemoveWalletAdminReducer: Reducer = (
  state: RemoveWalletAdminBOState = initialRemoveWalletAdminBOState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
