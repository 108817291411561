import { delay, put, takeLatest } from 'redux-saga/effects';
import { setKycUpdateStage } from 'src/apis/service/Kyc';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { updateKycStageFaild, updateKycStageIsFetching, updateKycStageSuccess, UpdateKycStageType, getKycDetail, getKycListHistory } from 'src/store/actions';

function* updateKycStage(action: any): Generator<any> {


  const { payload, kycId } = action;  
  yield put(updateKycStageIsFetching(true))
  const data: any = yield setKycUpdateStage(payload);
  try {
    if(data && data?.status === 200){
      yield put(getKycDetail(kycId));
      yield put(getKycListHistory({
        pageNumber: 1,
        pageSize: 10,
        kycId: kycId
      }));
      yield delay(1000)
      yield put(updateKycStageSuccess(data));
      yield toastSuccess('bo_update_kyc_success')
    } else {
      yield toastError('bo_error_label')
      yield put(updateKycStageFaild('error'))
    }
  } catch (error: any) {
    yield put(updateKycStageFaild(error))
    yield toastError('bo_error_label')
  }
}

export function* watchUpdateKycStage() {
   yield takeLatest(UpdateKycStageType.UPDATE_KYC_STAGE , updateKycStage)
}