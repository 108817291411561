import { AnyAction, Reducer } from 'redux';
import { IUserAvatarState, initialUserAvatarState } from './state';
import { UserAvatarTypes } from 'src/store/actions/userAvatar';

export const UserAvatarReducer: Reducer = (
  state: IUserAvatarState = initialUserAvatarState,
  action: AnyAction
) => {
  switch (action.type) {
    case UserAvatarTypes.USER_AVATAR: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UserAvatarTypes.USER_AVATAR_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    }
    case UserAvatarTypes.USER_AVATAR_FAILD: {
      return {
        ...state,
        isFetching: false
      };
    }
    case UserAvatarTypes.USER_AVATAR_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
