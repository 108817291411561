import { BlockAndUnblockP2PExchangeOffer } from './actionTypes';
import {
    IBlockAndUnBlockP2PExchangeOffer,
    IBlockAndUnBlockP2PExchangeOfferFaild,
    IBlockAndUnBlockP2PExchangeOfferFetching,
    IBlockAndUnBlockP2PExchangeOfferSuccess,
    Payload
} from './interfaces';

export const blockAndUnBlockP2PExchangeOfferAtFetching = (isFetching: boolean): IBlockAndUnBlockP2PExchangeOfferFetching => ({
    type: BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER_IS_FETCHING,
    isFetching
})

export const blockAndUnBlockP2PExchangeOffer = (payload: Payload, isBlock: boolean): IBlockAndUnBlockP2PExchangeOffer => ({
    type: BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER,
    payload,
    isBlock
})

export const blockAndUnBlockP2PExchangeOfferSuccess = (data: any): IBlockAndUnBlockP2PExchangeOfferSuccess => ({
    type: BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER_SUCCESS,
    data
})

export const blockAndUnBlockP2PExchangeOfferFaild = (error?: any): IBlockAndUnBlockP2PExchangeOfferFaild => ({
    type: BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER_FAILD,
    error
})