import { AnyAction, Reducer } from 'redux';
import { initialEditNotificationState, EditNotificationState } from './state';
import { EditNotificationType } from '../../actions/editNotification/actionTypes';

export const EditNotificationReducer: Reducer = (
  state: EditNotificationState = initialEditNotificationState,
  action: AnyAction
) => {
  switch (action.type) {
    case EditNotificationType.EDIT_NOTIFICATION: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case EditNotificationType.EDIT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true
      };
    }
    case EditNotificationType.EDIT_NOTIFICATION_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
