import { put, takeEvery, debounce } from "redux-saga/effects";
import { UpdateWalletStatusTypes } from "../../actions/updateWalletStatus";
import {
  updateWalletStatusError,
  updateWalletStatusFetching,
  updateWalletStatusFilter,
  updateWalletStatusSuccess,
} from "../../actions/updateWalletStatus/action";
import { handleUpdateWalletStatus } from "src/apis/service/CryptoWallet";
import { getListUserWallet } from "src/store/actions/getListUserWallet";
import { toastError, toastSuccess } from "src/helpers/toastHelper";
import i18next from "i18next";

function* updateWalletStatusSaga(action: any): Generator<any> {
  const { payload } = action;
  try {
    yield put(updateWalletStatusFetching(payload));
    const data: any = yield handleUpdateWalletStatus(payload);
    if (data && data?.status === 200) {
      toastSuccess(i18next.t('bo_update_wallet_status_success'));
      yield put(getListUserWallet({}));
      yield put(updateWalletStatusSuccess(payload));
    } else {
      toastError(i18next.t('bo_update_wallet_status_error_label'));
      yield put(updateWalletStatusError(payload));
    }
  } catch (error) {
    toastError(i18next.t('bo_update_wallet_status_error_label'));
    yield put(updateWalletStatusError(payload));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(updateWalletStatusFilter(action.payload));
}
export function* watchUpdateWalletStatus() {
  yield takeEvery(
    UpdateWalletStatusTypes.UPDATE_WALLET_STATUS,
    updateWalletStatusSaga
  );
  yield debounce(
    500,
    UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
