export const EXCHANGE_TRANSACTION = [

  {
    id: 1,
    label: 'bo_status_declined',
    value: 1,
    sqlValue: '1',
    background: '#F66C6C',
    color: '#fff',
    labelButton: 'Decline',
    buttonColor: 'secondary',
  },
  {
    id: 2,
    label: 'bo_status_done',
    value: 2,
    sqlValue: "2",
    background: '#58D69B',
    color: '#fff',
    labelButton: 'Done',
    buttonColor: 'error',
  },
  {
    id: 3,
    label: 'bo_error_label',
    value: 3,
    sqlValue: '3',
    background: '#F9C229',
    color: '#495057',
    labelButton: 'Error',
    buttonColor: 'primary',
  },
]

export const OFFER_HISTORY = [

  {
    id: 0,
    label: 'bo_status_expired',
    value: 0,
    background: '#0408491A',
    color: '#fff',
    labelButton: 'Expired',
    buttonColor: 'secondary',
  },
  {
    id: 1,
    label: 'bo_offer_status_placed',
    value: 2,
    background: '#048f20',
    color: '#fff',
    labelButton: 'Placed',
    buttonColor: 'error',
  },
  {
    id: 2,
    label: 'bo_exchange_offer_status_on_going',
    value: 2,
    background: '#c8e6c9',
    color: '#fff',
    labelButton: 'On Going',
    buttonColor: 'primary',
  },
  {
    id: 3,
    label: 'bo_status_declined',
    value: 3,
    background: '#ffd8b2',
    color: '#fff',
    labelButton: 'Declined',
    buttonColor: 'primary',
  },
  {
    id: 4,
    label: 'bo_status_canceled',
    value: 4,
    background: '#c8e6c9',
    color: '#fff',
    labelButton: 'Cancelled',
    buttonColor: 'primary',
  },
  {
    id: 5,
    label: 'bo_status_done',
    value: 5,
    background: '#ffcdd2',
    color: '#fff',
    labelButton: 'Done',
    buttonColor: 'primary',
  },
  {
    id: 6,
    label: 'bo_status_blocked',
    value: 6,
    background: '#ffd8b2',
    color: '#fff',
    labelButton: 'Blocked',
    buttonColor: 'primary',
  },
]