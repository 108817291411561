import { GetP2PExchangeTransactionsAgentType } from './actionTypes';
import { IFetchingGetP2PExchangeTransactionsAgent, IGetListFilterDebounceP2PExchangeTransactionsAgent, IGetListFilterP2PExchangeTransactionsAgent, IGetP2PExchangeTransactionsAgent, IGetP2PExchangeTransactionsAgentError, IGetP2PExchangeTransactionsAgentSuccess } from './interfaces';

export const getP2PExchangeTransactionsAgentIsFetching = (isFetching:boolean) : IFetchingGetP2PExchangeTransactionsAgent => ({
    type: GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT_FETCHING,
    isFetching
})

export const getP2PExchangeTransactionsAgent = (payload: object) : IGetP2PExchangeTransactionsAgent => ({
    type: GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT,
    payload
})

export const getP2PExchangeTransactionsAgentSuccess = (data:any) : IGetP2PExchangeTransactionsAgentSuccess => ({
    type: GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT_SUCCESS,
    data
})

export const getP2PExchangeTransactionsAgentFaild = (error:any) : IGetP2PExchangeTransactionsAgentError => ({
    type: GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT_ERROR,
    error
})

export const setFilterP2PExchangeTransactionsAgent = (payload: any): IGetListFilterP2PExchangeTransactionsAgent => ({
    type: GetP2PExchangeTransactionsAgentType.SET_LIST_FILTER_P2P_EXCHANGE_TRANSACTIONS_AGENT,
    payload
})

export const setFilterDebounceP2PExchangeTransactionsAgent = (payload: any): IGetListFilterDebounceP2PExchangeTransactionsAgent => ({
    type: GetP2PExchangeTransactionsAgentType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_TRANSACTIONS_AGENT,
    payload 
})