import { put, takeEvery } from 'redux-saga/effects';
import { GetListAdminRolesTypes } from '../../actions/getListAdminRoles';
import { handleGetRoleFunctionByAdminId } from 'src/apis/service/AdminRoleSetting';
import {
  getListAdminRolesError, getListAdminRolesFetching,
  getListAdminRolesSuccess
} from '../../actions/getListAdminRoles/action';
import { getUserInfor } from 'src/utils/storage';

function* getListAdminRolesSaga(): Generator<any> {
  yield put(getListAdminRolesFetching(true));
  try {
    const { userId } = getUserInfor();
    const data: any = yield handleGetRoleFunctionByAdminId({ userId });
    if (data && data?.status === 200) {
      yield put(getListAdminRolesSuccess(data));
    } else {
      yield put(getListAdminRolesError('error'));
    }
  } catch (error) {
    yield put(getListAdminRolesError(error));
  }
}
export function* watchGetListAdminRoles() {
  yield takeEvery(
    GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES,
    getListAdminRolesSaga
  );
}
