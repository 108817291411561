export interface TransactionManualPayment  {
    data:any,
    errors:any,
    success:any
}

export interface TransactionManualPaymentState {
    data: TransactionManualPayment,
    isFetching:boolean
}

export const initialTransactionManualPaymentState: TransactionManualPaymentState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}