import { AnyAction, Reducer } from 'redux';
import { RemoveOfferSellTransactionType } from 'src/store/actions';
import { initialRemoveOfferSellTransactionState, RemoveOfferSellTransactionState } from './state';

export const RemoveOfferSellTransactionReducer: Reducer = (
  state: RemoveOfferSellTransactionState = initialRemoveOfferSellTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
