import { AnyAction, Reducer } from 'redux';
import { SetMainPostAnnouncementsType } from 'src/store/actions/Announcement/Announcements/SetMainPost';
import {
  initialSetMainPostAnnouncementsState,
  ISetMainPostAnnouncementsState,
} from 'src/store/reducers/Announcement/Announcements/SetMainPost';

export const SetMainPostAnnouncementsReducer: Reducer = (
  state: ISetMainPostAnnouncementsState = initialSetMainPostAnnouncementsState,
  action: AnyAction
) => {
  switch (action.type) {
    case SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS: {
      return {
        ...state,
        isFetching: true,
        success: false,
      };
    }
    case SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        success: true,
      };
    }
    case SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        successCount: state.successCount + 1,
      };
    }
    default:
      return state;
  }
};
