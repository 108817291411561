import { AnyAction, Reducer } from 'redux';
import { IDeleteScoreKPIState, initialDeleteScoreKPIState } from './state';
import { DeleteScoreKPITypes } from 'src/store/actions/deleteScoreKPI';

export const DeleteScoreKPIReducer: Reducer = (
  state: IDeleteScoreKPIState = initialDeleteScoreKPIState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteScoreKPITypes.DELETE_SCORE_KPI: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case DeleteScoreKPITypes.DELETE_SCORE_KPI_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        countSuccess: state.countSuccess + 1,
      };
    }
    case DeleteScoreKPITypes.DELETE_SCORE_KPI_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case DeleteScoreKPITypes.DELETE_SCORE_KPI_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
