import {HandleDeleteOfferTypes} from './actionTypes';
import { IHandleDeleteOffer, IHandleDeleteOfferFetching, IHandleDeleteOfferSuccess, IHandleDeleteOfferError, IHandleDeleteOfferFilter, IHandleDeleteOfferFiterDebounce } from "./interfaces";


export const handleDeleteOffer = (payload:Object): IHandleDeleteOffer => ({
    type: HandleDeleteOfferTypes.HANDLE_DELETE_OFFER,
    payload
})

export const handleDeleteOfferFetching = (isFetching:boolean) : IHandleDeleteOfferFetching => ({
    type: HandleDeleteOfferTypes.HANDLE_DELETE_OFFER_FETCHING,
    isFetching
})

export const handleDeleteOfferSuccess = (data:any) : IHandleDeleteOfferSuccess => ({
   type: HandleDeleteOfferTypes.HANDLE_DELETE_OFFER_SUCCESS,
   data
})

export const handleDeleteOfferError = (errorMsg:any) : IHandleDeleteOfferError => ({
    type: HandleDeleteOfferTypes.HANDLE_DELETE_OFFER_FAILD,
    error:errorMsg
})

export const handleDeleteOfferFilter = (payload: any): IHandleDeleteOfferFilter => ({
    type: HandleDeleteOfferTypes.HANDLE_DELETE_OFFER_FILTER,
    payload
})

export const handleDeleteOfferFilterDebounce = (payload: any): IHandleDeleteOfferFiterDebounce => ({
    type: HandleDeleteOfferTypes.HANDLE_DELETE_OFFER_FILTER_DEBOUNCE,
    payload 
})
