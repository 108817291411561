import { put, takeEvery } from 'redux-saga/effects';
import { getStorageItem } from 'src/utils/storage/auth';
import { deleteAnnouncement } from 'src/apis/service/Announcements';
import { deleteAnnouncementsFaild, deleteAnnouncementsIsFetching, deleteAnnouncementsSuccess, DeleteAnnouncementsType, getAnnouncements } from 'src/store/actions';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* deleteAnnouncements(action: any): Generator<any> {


  yield put(deleteAnnouncementsIsFetching(true))
  const { payload } = action;
  const filter = {
    pageNumber: 1,
    pageSize: 10,
  }
  try {
    const result: any = yield deleteAnnouncement(payload);
    if (result.message === "Success") {
      yield put(deleteAnnouncementsSuccess(result))
      toastSuccess('bo_annoucement_delete_success')
    } else {
      toastError('bo_annoucement_delete_fail')
      yield put(deleteAnnouncementsFaild(result.errors[0]))
    }
  } catch (error) {
    yield put(deleteAnnouncementsFaild(error))
  }
}
export function* watchDeleteAnnouncements() {
  yield takeEvery(DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS, deleteAnnouncements)
}