import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetOfferDetailExchangeTypes} from '../../actions/getOfferDetailExchange';
import {getOfferDetailExchangeFetching,getOfferDetailExchangeSuccess,getOfferDetailExchangeError, getOfferDetailExchangeFilter} from '../../actions/getOfferDetailExchange/action';
import { getOfferDetail } from 'src/apis/service/CryptoExchange';

function* getOfferDetailExchangeSaga(action:any):Generator<any> {
    yield put(getOfferDetailExchangeFetching(true))
    const {payload} = action;
    try {
      const data:any = yield getOfferDetail(payload)
      if(data && data?.status === 200){
        yield put(getOfferDetailExchangeSuccess(data))
      } else {
        yield put(getOfferDetailExchangeError('error'))
      }
    } catch (error) {
        yield put(getOfferDetailExchangeError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getOfferDetailExchangeFilter(action.payload));
}
export function* watchGetOfferDetailExchange() {
   yield takeEvery(GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE, getOfferDetailExchangeSaga)
   yield debounce(500,GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE_FILTER_DEBOUNCE,handleSearchDebounce)
}
