import { LoginActionsTypes } from "./actionTypes";
import { IGetLoginError, IGetLoginAttempt, IGetLoginSuccess} from "./interfaces";


export const getLogin = (payload:Object) => ({
    type: LoginActionsTypes.GET_LOGIN,
    payload
})

export const getLoginAtFetching = (isFetching:boolean) : IGetLoginAttempt => ({
    type: LoginActionsTypes.GET_LOGIN_ATTEMPT,
    isFetching
})

export const getLoginSuccess = (data:any) : IGetLoginSuccess => ({
   type: LoginActionsTypes.GET_LOGIN_SUCCESS,
   login:data
})

export const getLoginError = (errorMsg:any) : IGetLoginError => ({
    type: LoginActionsTypes.GET_LOGIN_FAIL,
    error:errorMsg
})