import { UpdateAnnouncementsCategoryType } from './actionTypes';
import { IFetchingUpdateAnnouncementsCategory, IUpdateAnnouncementsCategory, IUpdateAnnouncementsCategorySuccess, IUpdateAnnouncementsCategoryError} from './interfaces';

export const updateAnnouncementsCategoryIsFetching = (isFetching:boolean) : IFetchingUpdateAnnouncementsCategory => ({
    type: UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY_FETCHING,
    isFetching
})

export const updateAnnouncementsCategory = (payload: any) : IUpdateAnnouncementsCategory => ({
    type: UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY,
    payload
})

export const updateAnnouncementsCategorySuccess = (data:any) : IUpdateAnnouncementsCategorySuccess => ({
    type: UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY_SUCCESS,
    data
})

export const updateAnnouncementsCategoryFaild = (error:any) : IUpdateAnnouncementsCategoryError => ({
    type: UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY_ERROR,
    error
})