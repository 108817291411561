import { UnPostAndPostAnnouncementsType } from './actionTypes';
import { 
    IFetchingUnPostAndPostAnnouncements,
    IUnPostAndPostAnnouncements,
    IUnPostAndPostAnnouncementsError,
    IUnPostAndPostAnnouncementsSuccess
 } from './interfaces';

export const UnPostAndPostAnnouncementsIsFetching = (isFetching:boolean) : IFetchingUnPostAndPostAnnouncements => ({
    type: UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS_IS_FETCHING,
    isFetching
})

export const UnPostAndPostAnnouncements = (payload: Object, isPost: boolean) : IUnPostAndPostAnnouncements => ({
    type: UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS,
    payload,
    isPost
})

export const UnPostAndPostAnnouncementsSuccess = (data:any) : IUnPostAndPostAnnouncementsSuccess => ({
    type: UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS_SUCCESS,
    data
})

export const UnPostAndPostAnnouncementsFaild = (error:any) : IUnPostAndPostAnnouncementsError => ({
    type: UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS_FAILD,
    error
})