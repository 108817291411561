import { ListParams } from 'src/models/common';
import { GetCryptoWalletReceiveHistoryType } from './actionTypes';
import { IFetchingGetCryptoWalletReceiveHistory, IGetCryptoWalletReceiveHistory, IGetCryptoWalletReceiveHistoryError, IGetCryptoWalletReceiveHistorySuccess, IGetListFilterCryptoWalletReceiveHistory } from './interfaces';

export const getCryptoWalletReceiveHistoryIsFetching = (isFetching:boolean) : IFetchingGetCryptoWalletReceiveHistory => ({
    type: GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY_FETCHING,
    isFetching
})

export const getCryptoWalletReceiveHistory = (payload: object) : IGetCryptoWalletReceiveHistory => ({
    type: GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY,
    payload
})

export const getCryptoWalletReceiveHistorySuccess = (data:any) : IGetCryptoWalletReceiveHistorySuccess => ({
    type: GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY_SUCCESS,
    data
})

export const getCryptoWalletReceiveHistoryFaild = (error:any) : IGetCryptoWalletReceiveHistoryError => ({
    type: GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY_ERROR,
    error
})

export const setFilterCryptoWalletReceiveHistory = (payload: ListParams): IGetListFilterCryptoWalletReceiveHistory => ({
    type: GetCryptoWalletReceiveHistoryType.GET_LIST_FILTER_CRYPTO_WALLET_RECEIVE_HISTORY,
    payload
})