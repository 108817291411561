import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletOverviewStatisticalType } from 'src/store/actions';
import { IListCryptoWalletOverviewStatisticalState, initialListCryptoWalletOverviewStatisticalState } from './state';


export const GetListCryptoWalletOverviewStatisticalReducer: Reducer = (
  state: IListCryptoWalletOverviewStatisticalState = initialListCryptoWalletOverviewStatisticalState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCryptoWalletOverviewStatisticalType.GET_CRYPTO_WALLET_OVERVIEW_STATISTICAL : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoWalletOverviewStatisticalType.GET_CRYPTO_WALLET_OVERVIEW_STATISTICAL_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetCryptoWalletOverviewStatisticalType.GET_CRYPTO_WALLET_OVERVIEW_STATISTICAL_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
      }
    }
    case GetCryptoWalletOverviewStatisticalType.GET_LIST_FILTER_CRYPTO_WALLET_OVERVIEW_STATISTICAL: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};