import { AnyAction, Reducer } from 'redux';
import { IUpdateOfferNoteState, initialUpdateOfferNoteState } from './state';
import { UpdateOfferNoteTypes } from 'src/store/actions/updateOfferNote';

export const UpdateOfferNoteReducer: Reducer = (
  state: IUpdateOfferNoteState = initialUpdateOfferNoteState,
  action: AnyAction
) => {
  switch (action.type) {
    case UpdateOfferNoteTypes.UPDATE_OFFER_NOTE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
