import { put, takeEvery, debounce } from 'redux-saga/effects';
import { ApplyKYCAddressTypes } from '../../actions/applyKYCAddress';
import { applyKYCAddressFetching, applyKYCAddressSuccess, applyKYCAddressError, applyKYCAddressFilter } from '../../actions/applyKYCAddress/action';
import { handleApplyKYCAddress, handleGetListUser } from '../../../apis/service/user';
import { getAllKYC } from 'src/store/actions/getAllKYC';

function* applyKYCAddressSaga(action: any): Generator<any> {
  yield put(applyKYCAddressFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleApplyKYCAddress(payload)
    if (data && data?.status === 200) {
      yield put(getAllKYC())
      yield put(applyKYCAddressSuccess(data))
    } else {
      yield put(applyKYCAddressError('error'))
    }
  } catch (error) {
    yield put(applyKYCAddressError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(applyKYCAddressFilter(action.payload));
}
export function* watchApplyKYCAddress() {
  yield takeEvery(ApplyKYCAddressTypes.APPLYKYC_ADDRESS, applyKYCAddressSaga)
  yield debounce(500, ApplyKYCAddressTypes.APPLYKYC_ADDRESS_FILTER_DEBOUNCE, handleSearchDebounce)
}
