import { AnyAction, Reducer } from 'redux';
import { GetAnnouncementsType } from 'src/store/actions';
import { IListAnnouncementsState, initialListAnnouncementsState } from './state';


export const GetListAnnouncementsReducer: Reducer = (
  state: IListAnnouncementsState = initialListAnnouncementsState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetAnnouncementsType.GET_ANNOUNCEMENTS : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAnnouncementsType.GET_ANNOUNCEMENTS_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case GetAnnouncementsType.GET_ANNOUNCEMENTS_SUCCESS : {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      }
    }
    case GetAnnouncementsType.SET_LIST_FILTER_ANNOUNCEMENTS: {
      return {
          ...state,
          filter: action.payload
      }
    }
      default:
        return state;
    }
};