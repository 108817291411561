import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { ListParams } from "src/models/common";

interface IDataGetAgentRankByAdmin {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  success:boolean;
}

export interface IGetAgentRankByAdminState {
    data:IDataGetAgentRankByAdmin;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetAgentRankByAdminState: IGetAgentRankByAdminState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT
    },
    pagination: {
        totalCount: 0,
    }
}


