import { AnyAction, Reducer } from 'redux';
import { IAdminGetInvoiceListState, initialAdminGetInvoiceListState } from './state';
import { AdminGetInvoiceListTypes } from 'src/store/actions/adminGetInvoiceList';

export const AdminGetInvoiceListReducer: Reducer = (
  state: IAdminGetInvoiceListState = initialAdminGetInvoiceListState,
  action: AnyAction
) => {
  switch (action.type) {
    case AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
