const PRERIOD_RANK = [
  {
    id: 1,
    label: 'bo_rank_period_1_week',
    value: '1_WEEK'
  },
  {
    id: 2,
    label: 'bo_rank_period_2_weeks',
    value: '2_WEEKS'
  },
  {
    id: 3,
    label: 'bo_rank_period_month',
    value: '1_MONTH'
  },
  {
    id: 4,
    label: 'bo_rank_period_2_months',
    value: '2_MONTHS'
  },
  {
    id: 5,
    label: 'bo_rank_period_3_months',
    value: '3_MONTHS'
  },
  {
    id: 6,
    label: 'bo_rank_period_1_year',
    value: '1_YEAR'
  },
  {
    id: 7,
    label: 'bo_rank_period_2_years',
    value: '2_YEARS'
  },
  {
    id: 8,
    label: 'bo_rank_period_custom',
    value: 'CUSTOM'
  }
];

export { PRERIOD_RANK };
