import {EditUserBankInfoTypes} from './actionTypes';
import { IEditUserBankInfo, IEditUserBankInfoFetching, IEditUserBankInfoSuccess, IEditUserBankInfoError, IEditUserBankInfoFilter, IEditUserBankInfoFiterDebounce } from "./interfaces";


export const editUserBankInfo = (payload:Object): IEditUserBankInfo => ({
    type: EditUserBankInfoTypes.EDIT_USER_BANK_INFO,
    payload
})

export const editUserBankInfoFetching = (isFetching:boolean) : IEditUserBankInfoFetching => ({
    type: EditUserBankInfoTypes.EDIT_USER_BANK_INFO_FETCHING,
    isFetching
})

export const editUserBankInfoSuccess = (data:any) : IEditUserBankInfoSuccess => ({
   type: EditUserBankInfoTypes.EDIT_USER_BANK_INFO_SUCCESS,
   data
})

export const editUserBankInfoError = (errorMsg:any) : IEditUserBankInfoError => ({
    type: EditUserBankInfoTypes.EDIT_USER_BANK_INFO_FAILD,
    error:errorMsg
})

export const editUserBankInfoFilter = (payload: any): IEditUserBankInfoFilter => ({
    type: EditUserBankInfoTypes.EDIT_USER_BANK_INFO_FILTER,
    payload
})

export const editUserBankInfoFilterDebounce = (payload: any): IEditUserBankInfoFiterDebounce => ({
    type: EditUserBankInfoTypes.EDIT_USER_BANK_INFO_FILTER_DEBOUNCE,
    payload 
})
