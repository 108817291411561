import { AnyAction, Reducer } from "redux";
import {
  initialChangeWalletUserBalanceState,
  IChangeWalletUserBalanceState,
} from "./state";
import { ChangeUserBalanceType } from "../../actions/changeWalletUserBalance";
import { toastError, toastSuccess } from "../../../helpers/toastHelper";

export const ChangeWalletUserBalanceReducer: Reducer = (
  state: IChangeWalletUserBalanceState = initialChangeWalletUserBalanceState,
  action: AnyAction
) => {
  switch (action.type) {
    case ChangeUserBalanceType.CHANGE_USER_BALANCE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ChangeUserBalanceType.CHANGE_USER_BALANCE_SUCCESS: {
      toastSuccess("bo_balance_successfully_changed");
      return {
        ...state,
        data: action.data,
        isFetching: false,
        countSuccess: state.countSuccess + 1,
      };
    }
    case ChangeUserBalanceType.CHANGE_USER_BALANCE_ERROR: {
      toastError(action.error);
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
