import { AnyAction, Reducer } from 'redux';
import { ITopupWalletListSelectState, initialTopupWalletListSelectState } from './state';
import { TopupWalletListSelectTypes } from 'src/store/actions/topupWalletListSelect';

export const TopupWalletListSelectReducer: Reducer = (
  state: ITopupWalletListSelectState = initialTopupWalletListSelectState,
  action: AnyAction
) => {
  switch (action.type) {
    case TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    }
    default: {
      return state;
    }
  }
};
