import {GetKYCSystemStatusHistoryTypes} from './actionTypes';
import { IGetKYCSystemStatusHistory, IGetKYCSystemStatusHistoryFetching, IGetKYCSystemStatusHistorySuccess, IGetKYCSystemStatusHistoryError, IGetKYCSystemStatusHistoryFilter, IGetKYCSystemStatusHistoryFiterDebounce } from "./interfaces";


export const getKYCSystemStatusHistory = (): IGetKYCSystemStatusHistory => ({
    type: GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY
})

export const getKYCSystemStatusHistoryFetching = (isFetching:boolean) : IGetKYCSystemStatusHistoryFetching => ({
    type: GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_FETCHING,
    isFetching
})

export const getKYCSystemStatusHistorySuccess = (data:any) : IGetKYCSystemStatusHistorySuccess => ({
   type: GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_SUCCESS,
   data
})

export const getKYCSystemStatusHistoryError = (errorMsg:any) : IGetKYCSystemStatusHistoryError => ({
    type: GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_FAILD,
    error:errorMsg
})

export const getKYCSystemStatusHistoryFilter = (payload: any): IGetKYCSystemStatusHistoryFilter => ({
    type: GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_FILTER,
    payload
})

export const getKYCSystemStatusHistoryFilterDebounce = (payload: any): IGetKYCSystemStatusHistoryFiterDebounce => ({
    type: GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_FILTER_DEBOUNCE,
    payload 
})
