import {GetCryptoWalletGetNewOverviewType} from './actionTypes';
import {
    IFetchingGetCryptoWalletGetNewOverview,
    IGetCryptoWalletGetOverviewNewError,
    IGetCryptoWalletGetOverviewNewSuccess,
    IGetNewCryptoWalletOverview
} from './interfaces';
import {IReqDataCryptoWalletNewOverviewGet} from "../../reducers";

export const getCryptoWalletNewOverviewIsFetching = (isFetching: boolean): IFetchingGetCryptoWalletGetNewOverview => ({
    type: GetCryptoWalletGetNewOverviewType.NEW_CRYPTO_WALLET_OVERVIEW_IS_FETCHING,
    isFetching
})

export const getCryptoWalletNewOverviewData = (): IGetNewCryptoWalletOverview => ({
    type: GetCryptoWalletGetNewOverviewType.GET_NEW_CRYPTO_WALLET_OVERVIEW,
})

export const getCryptoWalletGetNewOverviewSuccess = (data: IReqDataCryptoWalletNewOverviewGet): IGetCryptoWalletGetOverviewNewSuccess => ({
    type: GetCryptoWalletGetNewOverviewType.NEW_CRYPTO_WALLET_OVERVIEW_SUCCESS,
    data
})

export const getCryptoWalletGetNewOverviewFailed = (error: any): IGetCryptoWalletGetOverviewNewError => ({
    type: GetCryptoWalletGetNewOverviewType.NEW_CRYPTO_WALLET_OVERVIEW_ERROR,
    error
})
