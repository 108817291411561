import * as Sentry from "@sentry/react";
import Request from "src/apis/config";

const requestApi = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});

interface DataDeleteBankDto {
  userId: number;
  bankId: number;
}
export interface BankAccParams {
  userId?: string;
  pageSize?: string;
  pageNumber?: string;
  bankId?: string;
}
const handleGetAllDataBankAccUser = async (
  params: BankAccParams
): Promise<any[]> => {
  const url = "/BankAccount/GetBankByAdmin";
  try {
    return await requestApi.get(url, { params });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAddUserBankAccount = async (data: Object): Promise<any[]> => {
  const url = "/BankAccount/CreateByAdmin";
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteUserBankAccount = async (
  data: DataDeleteBankDto
): Promise<any> => {
  const url = `BankAccount/DeleteByAdmin?userId=${data.userId}&bankId=${data.bankId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateUserBankAccount = async (data: Object): Promise<any[]> => {
  const url = "/BankAccount/UpdateByAdmin";
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetBankByUser = async (payload: any): Promise<any[]> => {
  const url = "/BankAccount/GetBankByAgent";
  try {
    return await requestApi.post(url, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateBankByUser = async (data: any): Promise<any[]> => {
  const url = "/BankAccount/CreateByUser";
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateBankByUser = async (data: any): Promise<any[]> => {
  const url = "/BankAccount/UpdateByUser";
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteBankByUser = async (data: any): Promise<any> => {
  const url = `/BankAccount/DeleteByUser?bankId=${data}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleGetAllDataBankAccUser,
  handleAddUserBankAccount,
  handleDeleteUserBankAccount,
  handleUpdateUserBankAccount,
  handleGetBankByUser,
  handleCreateBankByUser,
  handleUpdateBankByUser,
  handleDeleteBankByUser,
};
