import { AnyAction, Reducer } from 'redux';
import { P2PExchangeOffersNoteType } from 'src/store/actions/P2PExchangeOfferNote';
import { ISendNoteP2PExchangeOfferState, initialSendNoteP2PExchangeOffer } from './state';


export const sendNoteP2PExchangeOffersReducer: Reducer = (
  state: ISendNoteP2PExchangeOfferState = initialSendNoteP2PExchangeOffer,
  action: AnyAction,
) => {
  switch (action.type) {
    case P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        successCount: state.successCount + 1
      }
    }
    default:
      return state;
  }
};