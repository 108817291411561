import { ListParams } from "src/models/common";

export interface ISelectWalletTypeState {
    data:any;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialSelectWalletTypeState: ISelectWalletTypeState = {
    data:undefined,
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


