import { ActiveCurrencyType } from './actionTypes';
import { IActiveCurrency,
    IActiveCurrencyError,
    IActiveCurrencySuccess,
    IFetchingActiveCurrency
} from './interfaces';


export const activeCurrencyIsFetching = (isFetching:boolean) : IFetchingActiveCurrency => ({
    type:ActiveCurrencyType.ACTIVE_CURRENCY_FETCHING,
    isFetching
})

export const activeCurrency = (payload:string) : IActiveCurrency => ({
    type:ActiveCurrencyType.ACTIVE_CURRENCY,
    payload,
})

export const activeCurrencySuccess = (data:any) : IActiveCurrencySuccess => ({
    type:ActiveCurrencyType.ACTIVE_CURRENCY_SUCCESS,
    data
})

export const activeCurrencyFaild = (error:any) : IActiveCurrencyError => ({
    type:ActiveCurrencyType.ACTIVE_CURRENCY_FAILD,
    error
})