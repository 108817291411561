import { put, takeEvery, select } from 'redux-saga/effects';
import { handleDeleteOffer } from 'src/apis/service/P2PTransaction';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getOfferTransaction, removeOfferBuyTransactionFaild, removeOfferBuyTransactionIsFetching, removeOfferBuyTransactionSuccess } from 'src/store/actions';
import { IApplicationState } from 'src/store/IApplicationState';
import { RemoveOfferBuyTransactionType } from '../../actions/removeOfferBuyTransaction/actionTypes';

function* RemoveOfferBuyTransactionSaga(action:any):Generator<any>{


  yield put(removeOfferBuyTransactionIsFetching(true))
  const { id } = action;
  try {
    const dataRemoveOfferBuyTransaction: any = yield handleDeleteOffer(id)
    if (dataRemoveOfferBuyTransaction.status === 200) {
      const IFilterOfferBuy : any = yield select((state:IApplicationState) => state.offerTransaction.filter)
      toastSuccess('bo_delete_offer_transaction_success')
      yield put(removeOfferBuyTransactionSuccess(dataRemoveOfferBuyTransaction))
      yield put(getOfferTransaction(IFilterOfferBuy));
    } else {
      toastError('bo_you_can_t_delete_this_offer_due_to_pending_transaction')
    }
  } catch (error) {
     yield put(removeOfferBuyTransactionFaild(error)) 
  }
}

export function* watchRemoveOfferBuyTransaction(){
    yield takeEvery(RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION, RemoveOfferBuyTransactionSaga)
}