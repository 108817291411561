import { AnyAction, Reducer } from 'redux';
import { ICreateScorePeriodConfigState, initialCreateScorePeriodConfigState } from './state';
import { CreateScorePeriodConfigTypes } from 'src/store/actions/createScorePeriodConfig';

export const CreateScorePeriodConfigReducer: Reducer = (
  state: ICreateScorePeriodConfigState = initialCreateScorePeriodConfigState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
