import { GetP2PExchangeTransactionsHistoryType } from './actionTypes';
import { IFetchingGetP2PExchangeTransactionsHistory, IGetP2PExchangeTransactionsHistory, IGetP2PExchangeTransactionsHistoryError, IGetP2PExchangeTransactionsHistorySuccess } from './interfaces';

export const getP2PExchangeTransactionsHistoryIsFetching = (isFetching:boolean) : IFetchingGetP2PExchangeTransactionsHistory => ({
    type: GetP2PExchangeTransactionsHistoryType.GET_P2P_EXCHANGE_TRANSACTIONS_HISTORY_FETCHING,
    isFetching
})

export const getP2PExchangeTransactionsHistory = (payload: object) : IGetP2PExchangeTransactionsHistory => ({
    type: GetP2PExchangeTransactionsHistoryType.GET_P2P_EXCHANGE_TRANSACTIONS_HISTORY,
    payload
})

export const getP2PExchangeTransactionsHistorySuccess = (data:any) : IGetP2PExchangeTransactionsHistorySuccess => ({
    type: GetP2PExchangeTransactionsHistoryType.GET_P2P_EXCHANGE_TRANSACTIONS_HISTORY_SUCCESS,
    data
})

export const getP2PExchangeTransactionsHistoryFaild = (error:any) : IGetP2PExchangeTransactionsHistoryError => ({
    type: GetP2PExchangeTransactionsHistoryType.GET_P2P_EXCHANGE_TRANSACTIONS_HISTORY_ERROR,
    error
})