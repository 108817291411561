import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletGetNewOverviewType } from 'src/store/actions';
import { IListCryptoWalletGetNewOverviewState, initialListCryptoWalletGetNewOverviewState } from './state';

export const GetListCryptoWalletGetNewOverviewReducer: Reducer = (
    state: IListCryptoWalletGetNewOverviewState = initialListCryptoWalletGetNewOverviewState,
    action: AnyAction,
) => {
    switch (action.type) {
        case GetCryptoWalletGetNewOverviewType.NEW_CRYPTO_WALLET_OVERVIEW_IS_FETCHING : {
            return {
                ...state,
                isFetching: !!action?.isFetching,
            };
        }
        case GetCryptoWalletGetNewOverviewType.NEW_CRYPTO_WALLET_OVERVIEW_ERROR : {
            return {
                ...state,
                error: action.error,
                isFetching: false,
            };
        }
        case GetCryptoWalletGetNewOverviewType.NEW_CRYPTO_WALLET_OVERVIEW_SUCCESS : {
            return {
                ...state,
                requestData: action?.data,
                isFetching: false,
            }
        }
        default:
            return state;
    }
};
