import { put, takeEvery } from "redux-saga/effects";
import { createOfferBuy, createOfferSell } from "src/apis/service/Offer";
import { toastError, toastSuccess } from "src/helpers/toastHelper";
import {
  CreateOfferSellOrBuy,
  createOfferSellOrBuyAtFetching,
  createOfferSellOrBuyFaild,
  createOfferSellOrBuySuccess,
} from "src/store/actions/myOfferFromAgent/createOfferBuyOrSell";

function* createOfferSellOrBuySaga(action: any): Generator<any> {
  const { payload, isP2POfferBuy, closeModal, getDataAfterSuccess } = action;
  yield put(createOfferSellOrBuyAtFetching(true));
  try {
    if (isP2POfferBuy) {
      const response: any = yield createOfferBuy(payload);
      if (response && response.status === 200) {
        getDataAfterSuccess();
        yield put(createOfferSellOrBuySuccess(response));
        toastSuccess("bo_create_new_offer_buy_success");
        closeModal();
      } else {
        toastError("bo_create_new_offer_buy_failed");
        yield put(createOfferSellOrBuyFaild("error"));
      }
    } else {
      const response: any = yield createOfferSell(payload);
      if (response && response.status === 200) {
        getDataAfterSuccess();
        yield put(createOfferSellOrBuySuccess(response));
        toastSuccess("bo_create_new_offer_sell_success");
        closeModal();
      } else {
        toastError("bo_create_new_offer_sell_failed");
        yield put(createOfferSellOrBuyFaild("error"));
      }
    }
  } catch (error: any) {
    yield put(createOfferSellOrBuyFaild(error.message));
  }
}

export function* watchCreateOfferBuyOrSell() {
  yield takeEvery(
    CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY,
    createOfferSellOrBuySaga
  );
}
