import { delay, put, takeEvery } from 'redux-saga/effects';
import { createCurrency, editCurrency } from 'src/apis/service/Currencies';
import {
  addAndEditCurrencyFaild,
  addAndEditCurrencyIsFetching,
  addAndEditCurrencySuccess,
  CreateAndEditCurrencyType,
} from 'src/store/actions/currency';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getCurrency } from 'src/store/actions';

function* createAndEditCurrencySaga(action: any): Generator<any> {
  const { payload, isEdit, history } = action;
  yield put(addAndEditCurrencyIsFetching(true));
  try {
    if (isEdit) {
      const response: any = yield editCurrency(payload);
      yield delay(1000);
      if (response && response.status === 200) {
        yield put(addAndEditCurrencySuccess(response));
        toastSuccess('bo_edit_new_currency_success');

        yield put(getCurrency());
      } else {
        toastError('bo_edit_new_currency_failed');
        yield put(addAndEditCurrencyFaild('error'));
      }
    } else {
      const response: any = yield createCurrency(payload);
      yield delay(1000);
      if (response && response.status === 200) {
        yield put(addAndEditCurrencySuccess(response));
        toastSuccess('bo_add_new_currency_success');
        yield put(getCurrency());
      } else {
        toastError('bo_add_new_currency_failed');
        yield put(addAndEditCurrencyFaild('error'));
      }
    }
  } catch (error) {
    yield put(addAndEditCurrencyFaild(error));
  }
}

export function* watchCreateAndEditCurrency() {
  yield takeEvery(
    CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY,
    createAndEditCurrencySaga
  );
}
