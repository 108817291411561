import { AnyAction, Reducer } from 'redux';
import { IGetTransactionDetailByAgentState, initialGetTransactionDetailByAgentState } from './state';
import { GetTransactionDetailByAgentTypes } from 'src/store/actions/getTransactionDetailByAgent';

export const GetTransactionDetailByAgentReducer: Reducer = (
  state: IGetTransactionDetailByAgentState = initialGetTransactionDetailByAgentState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_SUCCESS: {
      return {
        ...state,
        data: action.data.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_CLEAN: {
      return {
        ...initialGetTransactionDetailByAgentState
      };
    }
    default: {
      return state;
    }
  }
};
