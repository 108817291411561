export interface RemoveUserBankAcc  {
    data:any,
    errors:any,
    success:any
}

export interface RemoveUserBankAccState {
    data:RemoveUserBankAcc,
    isFetching:boolean
}

export const initialRemoveUserBankAccState: RemoveUserBankAccState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}