import {GetOfferDetailExchangeTypes} from './actionTypes';
import { IGetOfferDetailExchange, IGetOfferDetailExchangeFetching, IGetOfferDetailExchangeSuccess, IGetOfferDetailExchangeError, IGetOfferDetailExchangeFilter, IGetOfferDetailExchangeFiterDebounce } from "./interfaces";


export const getOfferDetailExchange = (payload:Object): IGetOfferDetailExchange => ({
    type: GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE,
    payload
})

export const getOfferDetailExchangeFetching = (isFetching:boolean) : IGetOfferDetailExchangeFetching => ({
    type: GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE_FETCHING,
    isFetching
})

export const getOfferDetailExchangeSuccess = (data:any) : IGetOfferDetailExchangeSuccess => ({
   type: GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE_SUCCESS,
   data
})

export const getOfferDetailExchangeError = (errorMsg:any) : IGetOfferDetailExchangeError => ({
    type: GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE_FAILD,
    error:errorMsg
})

export const getOfferDetailExchangeFilter = (payload: any): IGetOfferDetailExchangeFilter => ({
    type: GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE_FILTER,
    payload
})

export const getOfferDetailExchangeFilterDebounce = (payload: any): IGetOfferDetailExchangeFiterDebounce => ({
    type: GetOfferDetailExchangeTypes.GET_OFFER_DETAIL_EXCHANGE_FILTER_DEBOUNCE,
    payload 
})
