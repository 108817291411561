import { supportedCryptoCurrenciesActionTypes } from './actionTypes';
import { supportedCryptoCurrencyObjectType } from '../../reducers';

export const setSupportedCryptoCurrenciesAction = (data : supportedCryptoCurrencyObjectType[])=> ({
    type: supportedCryptoCurrenciesActionTypes.SET_SUPPORTED_CRYPTO_CURRENCIES,
    data
});

export const getAllSupportedCryptoCurrenciesAction = ()=> ({
    type: supportedCryptoCurrenciesActionTypes.GET_SUPPORTED_CRYPTO_CURRENCIES
});


