import {put, takeEvery, select} from 'redux-saga/effects';
import {RemoveLanguageTextType} from '../../actions/removeLanguageText/actionTypes';
import {removeLanguageTextIsFetching,removeLanguageTextSuccess,removeLanguageTextFaild} from '../../actions/removeLanguageText/action';
import {toastSuccess,toastError} from 'src/helpers/toastHelper';
import {getListLangText} from 'src/store/actions/getListLanguageText';
import {handleDeleteLanguageText} from 'src/apis/service/user';
import {IApplicationState} from 'src/store/IApplicationState';

function* RemoveLanguageTextSaga(action:any):Generator<any>{


  yield put(removeLanguageTextIsFetching(true))
  const {languageKey} = action;
  try {
    const dataRemoveLanguageText: any = yield handleDeleteLanguageText(languageKey)  
    if (dataRemoveLanguageText.status === 200) {
      toastSuccess('bo_delete_language_text_success')
      yield put(removeLanguageTextSuccess(dataRemoveLanguageText))
      // const payload : any = yield select((state:IApplicationState) => state.listLaguageText.filter)
      // yield put(getListLangText(payload))
    } else {
      toastError('bo_delete_language_text_fail')
    }
  } catch (error) {
     yield put(removeLanguageTextFaild(error)) 
  }
}
export function* watchRemoveLanguageText(){
    yield takeEvery(RemoveLanguageTextType.REMOVE_LANGUAGE_TEXT, RemoveLanguageTextSaga)
}