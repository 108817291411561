import { AnyAction, Reducer } from 'redux';
import { initialExportExchangeState, ExportExchangeState } from './state';
import { ExportExchangeType } from '../../actions/ExportExchange/actionTypes';


export const ExportExchangeReducer: Reducer = (
  state: ExportExchangeState = initialExportExchangeState,
  action: AnyAction
) => {
  switch (action.type) {
    case ExportExchangeType.EXPORT_EXCHANGE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ExportExchangeType.EXPORT_EXCHANGE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case ExportExchangeType.EXPORT_EXCHANGE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
