import { AnyAction, Reducer } from 'redux';
import { GetAllTestType } from 'src/store/actions/testAction';
import { initialDataListTestState, IDataListTestState } from './state';

export const ListTestReducer: Reducer = (
  state: IDataListTestState = initialDataListTestState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAllTestType.GET_ALL_TEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GetAllTestType.GET_ALL_TEST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetAllTestType.GET_ALL_TEST_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case GetAllTestType.GET_LIST_FILTER_TEST: {
      return {
        ...state,
        filter: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
