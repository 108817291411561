import { put, takeEvery } from "redux-saga/effects";
import {
  AddUserBankAccountforAgentType,
  addBankAccAgentFaild,
  addBankAccAgentIsFetching,
  addBankAccAgentSuccess,
} from "src/store/actions/bankAccountByAgent/addUserBankAccountForAgent";
import { toastSuccess, toastError } from "src/helpers/toastHelper";
import {
  handleCreateBankByUser,
  handleUpdateBankByUser,
} from "src/apis/service/BankAccount";
import { getDataBankAccountAgent } from "src/store/actions/bankAccountByAgent/getDataBankAccountByAgent/action";

function* handleAddUserBankAccAgentSaga(action: any): Generator<any> {
  yield put(addBankAccAgentIsFetching(true));
  const { payload, isEdit, getListRequest,handleClose } = action;
  const dataAddBankAcc: any = isEdit
    ? yield handleUpdateBankByUser(payload)
    : yield handleCreateBankByUser(payload);
  try {
    if (dataAddBankAcc) {
      yield put(addBankAccAgentSuccess(dataAddBankAcc));
      if (dataAddBankAcc.status === 200) {
        if (isEdit) {
          toastSuccess("bo_bank_account_updated");
        } else {
          toastSuccess("bo_bank_account_created");
        }
        handleClose()
        yield put(getDataBankAccountAgent({ ...getListRequest }));
      } else {
        toastError(dataAddBankAcc.message);
      }
    }
  } catch (error) {
    yield put(addBankAccAgentFaild(error));
  }
}
export function* watchAddBankAccAgent() {
  yield takeEvery(
    AddUserBankAccountforAgentType.ADD_USER_BANK_ACOOUNT_AGENT,
    handleAddUserBankAccAgentSaga
  );
}
