import { put, select, takeEvery } from 'redux-saga/effects';
import { updateAnnouncementCategory } from 'src/apis/service/Announcements';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import {
  getAnnouncementsCategory, updateAnnouncementsCategoryFaild,
  updateAnnouncementsCategoryIsFetching,
  updateAnnouncementsCategorySuccess,
  UpdateAnnouncementsCategoryType
} from 'src/store/actions';
import { IApplicationState } from 'src/store/IApplicationState';

function* updateAnnouncementsCaregorySaga(action: any): Generator<any> {


  yield put(updateAnnouncementsCategoryIsFetching(true));
  const { payload } = action;
  try {
    const data = yield updateAnnouncementCategory(payload);
    if (data) {
      toastSuccess('bo_update_announcement_category_success');
      const filter = yield select((state:IApplicationState) => state.listAnnouncementsCategory.filter) 
      yield put(updateAnnouncementsCategorySuccess(data));
      // yield put(getAnnouncementsCategory(filter))
    } else {
      toastError('bo_you_can_t_update_announcement_category');
    }
  } catch (error) {
    toastError('bo_you_can_t_update_announcement_category');
    yield put(updateAnnouncementsCategoryFaild(error));
  }
}

export function* watchUpdateListAnnouncementsCategory() {
  yield takeEvery(
    UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY,
    updateAnnouncementsCaregorySaga
  );
}