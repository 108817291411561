

interface IDataAllRankConfig {
    data:[];
    status: number;
    message: string;
}

export interface IDataAllRankConfigState {
   data: IDataAllRankConfig,
   isFetching: boolean,
   pagination: {
    totalCount: number,
   },
}

export const initialAllRankConfigState : IDataAllRankConfigState = {
    data: {
        data: [],
        message:'',
        status:0
    },
    isFetching:false,
    pagination: {
        totalCount: 0,
    }
}