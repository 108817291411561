import { debounce, put, takeEvery } from "redux-saga/effects";
import { handleAgentSellSearch } from "src/apis/service/Agent";
import { toastDismiss, toastError } from "src/utils/newUtils/toastHelper";
import { AgentSellSearchTypes } from "../../actions/agentSellSearch";
import {
  agentSellSearchError,
  agentSellSearchFetching,
  agentSellSearchFilter,
  agentSellSearchSuccess,
} from "../../actions/agentSellSearch/action";

function* agentSellSearchSaga(action: any): Generator<any> {
  yield put(agentSellSearchFetching(true));
  const { payload, getDataAfterSuccess } = action;
  try {
    const data: any = yield handleAgentSellSearch(payload);
    if (data && data?.status === 200) {
      toastDismiss();
      getDataAfterSuccess();
      yield put(agentSellSearchSuccess(data));
    } else {
      toastError("bo_failed_to_match_label", "bo_failed_to_match_label", true);
      yield put(agentSellSearchError("error"));
    }
  } catch (error) {
    toastError("bo_failed_to_match_label", "bo_failed_to_match_label", true);
    yield put(agentSellSearchError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentSellSearchFilter(action.payload));
}
export function* watchAgentSellSearch() {
  yield takeEvery(AgentSellSearchTypes.AGENT_SELL_SEARCH, agentSellSearchSaga);
  yield debounce(
    500,
    AgentSellSearchTypes.AGENT_SELL_SEARCH_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
