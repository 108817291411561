import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetListLanguageTextTypes} from '../../actions/getListLanguageText/actionTypes';
import {getListLangTextAtFetching, getListLangTextSuccess, getListLangTextFaild, setFilterLangText} from '../../actions/getListLanguageText/action';
import {handleGetListLanguageText} from '../../../apis/service/user';

function* getListLanguageTextSaga(action:any):Generator<any> {
    yield put(getListLangTextAtFetching(true))
    const {payload} = action;
    try {
      const dataListLanguageText:any = yield handleGetListLanguageText(payload)
      if(dataListLanguageText && dataListLanguageText?.status === 200){
        yield put(getListLangTextSuccess(dataListLanguageText))
      } else {
        yield put(getListLangTextFaild('error'))
      }
    } catch (error) {
        yield put(getListLangTextFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterLangText(action.payload));
}
export function* watchGetListLanguageText() {
   yield takeEvery(GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT, getListLanguageTextSaga)
   yield debounce(500,GetListLanguageTextTypes.GET_LIST_SEARCH_DEBOUNCE_LANGUAGE_TEXT, handleSearchDebounce)
}