import {GetOfferHistoryTypes} from './actionTypes';
import { IGetOfferHistory, IGetOfferHistoryFetching, IGetOfferHistorySuccess, IGetOfferHistoryError, IGetOfferHistoryFilter, IGetOfferHistoryFiterDebounce } from "./interfaces";


export const getOfferHistory = (payload:Object): IGetOfferHistory => ({
    type: GetOfferHistoryTypes.GET_OFFER_HISTORY,
    payload
})

export const getOfferHistoryFetching = (isFetching:boolean) : IGetOfferHistoryFetching => ({
    type: GetOfferHistoryTypes.GET_OFFER_HISTORY_FETCHING,
    isFetching
})

export const getOfferHistorySuccess = (data:any) : IGetOfferHistorySuccess => ({
   type: GetOfferHistoryTypes.GET_OFFER_HISTORY_SUCCESS,
   data
})

export const getOfferHistoryError = (errorMsg:any) : IGetOfferHistoryError => ({
    type: GetOfferHistoryTypes.GET_OFFER_HISTORY_FAILD,
    error:errorMsg
})

export const getOfferHistoryFilter = (payload: any): IGetOfferHistoryFilter => ({
    type: GetOfferHistoryTypes.GET_OFFER_HISTORY_FILTER,
    payload
})

export const getOfferHistoryFilterDebounce = (payload: any): IGetOfferHistoryFiterDebounce => ({
    type: GetOfferHistoryTypes.GET_OFFER_HISTORY_FILTER_DEBOUNCE,
    payload 
})
