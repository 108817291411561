interface DetailTransactionManualPaymentHistory {
  id: number;
  transId: number;
  walletTransactionId: number;
  reason: string;
  date: number;
  executedDate: string;
  executedBy: any;
  amount: number;
  currencyId: number;
  destinationUserId: string;
}
export interface TransactionManualPaymentHistory {
  data: DetailTransactionManualPaymentHistory[];
  status: number;
  message: string;
}

export interface TransactionManualPaymentHistoryState {
  dataBuy: TransactionManualPaymentHistory;
  dataSell: TransactionManualPaymentHistory;
  isFetching: boolean;
}

export const initialTransactionManualPaymentHistoryState: TransactionManualPaymentHistoryState =
  {
    dataBuy: {
      data: [],
      message: '',
      status: 0
    },
    dataSell: {
      data: [],
      message: '',
      status: 0
    },
    isFetching: false
  };
