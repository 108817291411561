interface IDataListCryptoWalletReceiveStatistical {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListCryptoWalletReceiveStatisticalState {
    data: IDataListCryptoWalletReceiveStatistical;
    isFetching: boolean,
    pagination: number
}

export const initialListCryptoWalletReceiveStatisticalState: IListCryptoWalletReceiveStatisticalState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    pagination: 0,
}

