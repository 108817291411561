import encode from "../utils/encode"

/**
 * Define constant variable.
 */
export const SUPERADMIN: string = 'superadmin'
export const AGENT: string = 'agent'
export const STORE: string = 'store'

/**
 * Define role type options.
 */
export const OPTIONS_ROLE_TYPE: string[] = [
  SUPERADMIN,
  AGENT,
  STORE
]

export const ROLE = encode('guard', 3)
export const ACCESS_TOKEN = encode('accessToken', 4)
export const ID_TOKEN = encode('idToken', 3)
export const REFRESH_TOKEN = encode('refreshToken', 4)
export const REGISTRATION_USER = encode('registrationUser', 3)

const favouriteKey = `favorites-${window.localStorage.getItem(encode('guard', 3))}`
const favouriteOffersKey = `favoriteOffers-${window.localStorage.getItem(encode('guard', 3))}`
export const FAVOURITES = encode(favouriteKey, 0)
export const FAVOURITES_OFFERS = encode(favouriteOffersKey, 0)

// API response code
export const INVALID_TOKEN = 'Invalid Access Token'
export const TOKEN_EXPIRED = 'Access Token has expired'
export const TOKEN_NOT_PROVIDED = 'Token not provided'

export const REFRESH_TIME = 'refreshTime'
export const USER_PHONE = 'userPhone'
export const DEACTIVE_USER = 'deactiveUser'
