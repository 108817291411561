import { DeleteCurrencyType } from './actionTypes';
import {
    IDeleteCurrency,
    IDeleteCurrencyError,
    IDeleteCurrencySuccess,
    IFetchingDeleteCurrency
} from './interfaces';


export const deleteCurrencyIsFetching = (isFetching: boolean): IFetchingDeleteCurrency => ({
    type: DeleteCurrencyType.DELETE_CURRENCY_FETCHING,
    isFetching
})

export const deleteCurrency = (payload: any): IDeleteCurrency => ({
    type: DeleteCurrencyType.DELETE_CURRENCY,
    payload,
})

export const deleteCurrencySuccess = (data: any): IDeleteCurrencySuccess => ({
    type: DeleteCurrencyType.DELETE_CURRENCY_SUCCESS,
    data
})

export const deleteCurrencyFaild = (error: any): IDeleteCurrencyError => ({
    type: DeleteCurrencyType.DELETE_CURRENCY_FAILD,
    error
})