import { ListParams } from 'src/models/common';
import Request from '../../config';
import * as Sentry from '@sentry/react';

export interface MainAnnoucement {
  model: boolean;
  announcementId: number;
}

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_API });
const getAnnouncement = async (listparam: ListParams): Promise<any> => {
  const url = `/Announcement/search`;
  try {
    return await requestApi.post(url, listparam);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getAnnouncementCategory = async (listparam: ListParams): Promise<any> => {
  const url = `/AnnouncementCategory/search`;
  try {
    return await requestApi.post(url, listparam);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const updateAnnouncement = async (data: Object): Promise<any[]> => {
  const url = `/Announcement/update`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const updateAnnouncementCategory = async (data: Object): Promise<any[]> => {
  const url = `/AnnouncementCategory/update`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const createAnnouncement = async (data: Object): Promise<any[]> => {
  const url = `/Announcement/create`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const deleteAnnouncement = async (data: any): Promise<any[]> => {
  const url = `/Announcement/delete?curUserId=${data.curUserId}&id=${data.id}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const deleteAnnouncementCategory = async (data: any): Promise<any[]> => {
  const url = `/AnnouncementCategory/Delete?categoryId=${data.categoryId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const createAnnouncementCategory = async (data: Object): Promise<any[]> => {
  const url = `/AnnouncementCategory/create`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handlePostToUser = async (data: Object): Promise<any[]> => {
  const url = `/Announcement/PostToUser`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUnPostToUser = async (announcementId: number): Promise<any[]> => {
  const url = `/Announcement/UnpostToUser?announcementId=${announcementId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateMainAnnouncement = async (
  data: MainAnnoucement
): Promise<any[]> => {
  const url = `/Announcement/UpdateIsMainAnnouncement?model=${data.model}&announcementId=${data.announcementId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  getAnnouncement,
  handleUpdateMainAnnouncement,
  updateAnnouncement,
  getAnnouncementCategory,
  updateAnnouncementCategory,
  createAnnouncementCategory,
  createAnnouncement,
  deleteAnnouncement,
  deleteAnnouncementCategory,
  handlePostToUser,
  handleUnPostToUser
};
