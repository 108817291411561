import { toastError } from './../../../helpers/toastHelper';
import { put, takeEvery, debounce } from 'redux-saga/effects';
import { VerifyPINByAgentTypes } from '../../actions/verifyPINByAgent';
import { verifyPINByAgentFetching, verifyPINByAgentSuccess, verifyPINByAgentError, verifyPINByAgentFilter } from '../../actions/verifyPINByAgent/action';
import { handleVerifyPINByAgent } from '../../../apis/service/user';

function* verifyPINByAgentSaga(action: any): Generator<any> {
  yield put(verifyPINByAgentFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleVerifyPINByAgent(payload)
    if (data && data?.status === 200) {
      yield put(verifyPINByAgentSuccess(data))
    } else {
      if(data?.message === 'pin.incorrect'){
        toastError('bo_incorrect_pin');
      }else{
        toastError('bo_error_label');
      }
      yield put(verifyPINByAgentError('error'))
    }
  } catch (error) {
    toastError('bo_error_label');
    yield put(verifyPINByAgentError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(verifyPINByAgentFilter(action.payload));
}
export function* watchVerifyPINByAgent() {
  yield takeEvery(VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT, verifyPINByAgentSaga)
  yield debounce(500, VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT_FILTER_DEBOUNCE, handleSearchDebounce)
}
