import { GetRoleFunctionByAdminIdTypes } from './actionTypes';
import { IGetRoleFunctionByAdminId, IGetRoleFunctionByAdminIdFetching, IGetRoleFunctionByAdminIdSuccess, IGetRoleFunctionByAdminIdError, IGetRoleFunctionByAdminIdFilter, IGetRoleFunctionByAdminIdFiterDebounce } from "./interfaces";


export const getRoleFunctionByAdminId = (payload: Object): IGetRoleFunctionByAdminId => ({
    type: GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID,
    payload
})

export const getRoleFunctionByAdminIdFetching = (isFetching: boolean): IGetRoleFunctionByAdminIdFetching => ({
    type: GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_FETCHING,
    isFetching
})

export const getRoleFunctionByAdminIdSuccess = (data: any): IGetRoleFunctionByAdminIdSuccess => ({
    type: GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_SUCCESS,
    data
})

export const getRoleFunctionByAdminIdError = (errorMsg: any): IGetRoleFunctionByAdminIdError => ({
    type: GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_FAILD,
    error: errorMsg
})

export const getRoleFunctionByAdminIdFilter = (payload: any): IGetRoleFunctionByAdminIdFilter => ({
    type: GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_FILTER,
    payload
})

export const getRoleFunctionByAdminIdFilterDebounce = (payload: any): IGetRoleFunctionByAdminIdFiterDebounce => ({
    type: GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_FILTER_DEBOUNCE,
    payload
})

export const cleanRoleFunctionByAdminId = (): any => ({
    type: GetRoleFunctionByAdminIdTypes.CLEAN_ROLE_FUNCTION_BY_ADMIN_ID
})
