import { GetP2PExchangeOffersAgentType } from './actionTypes';
import { IFetchingGetP2PExchangeOffersAgent, IGetListFilterDebounceP2PExchangeOffersAgent, IGetListFilterP2PExchangeOffersAgent, IGetP2PExchangeOffersAgent, IGetP2PExchangeOffersAgentError, IGetP2PExchangeOffersAgentSuccess } from './interfaces';

export const getP2PExchangeOffersAgentIsFetching = (isFetching:boolean) : IFetchingGetP2PExchangeOffersAgent => ({
    type: GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT_FETCHING,
    isFetching
})

export const getP2PExchangeOffersAgent = (payload: object) : IGetP2PExchangeOffersAgent => ({
    type: GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT,
    payload
})

export const getP2PExchangeOffersAgentSuccess = (data:any) : IGetP2PExchangeOffersAgentSuccess => ({
    type: GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT_SUCCESS,
    data
})

export const getP2PExchangeOffersAgentFaild = (error:any) : IGetP2PExchangeOffersAgentError => ({
    type: GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT_ERROR,
    error
})

export const setFilterP2PExchangeOffersAgent = (payload: any): IGetListFilterP2PExchangeOffersAgent => ({
    type: GetP2PExchangeOffersAgentType.SET_LIST_FILTER_P2P_EXCHANGE_OFFERS_AGENT,
    payload
})

export const setFilterDebounceP2PExchangeOffersAgent = (payload: any): IGetListFilterDebounceP2PExchangeOffersAgent => ({
    type: GetP2PExchangeOffersAgentType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_OFFERS_AGENT,
    payload 
})