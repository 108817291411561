import {put, takeEvery} from 'redux-saga/effects';
import {ResetPinType} from '../../actions/resetPIN/actionTypes';
import {resetPinIsFetching, resetPinSuccess, resetPinFaild} from '../../actions/resetPIN/action';
import {handleResetPin} from '../../../apis/service/user';
function* resetPinSaga(action:any):Generator<any>{
  yield put(resetPinIsFetching(true))
  const {payload} = action;
  try {
    const dataResetPin: any = yield handleResetPin(payload)  
    if (dataResetPin) {
        yield put(resetPinSuccess(dataResetPin))
    }
  } catch (error) {
     yield put(resetPinFaild(error)) 
  }
}
export function* watchResetPIN(){
    yield takeEvery(ResetPinType.RESET_PIN, resetPinSaga)
}