import { AnyAction, Reducer } from 'redux';
import { GetCurrencyType } from 'src/store/actions';
import { IListCurrencyState, initialListCurrencyState } from './state';


export const GetListCurrencyReducer: Reducer = (
  state: IListCurrencyState = initialListCurrencyState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCurrencyType.GET_CURRENCY : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCurrencyType.GET_CURRENCY_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case GetCurrencyType.GET_CURRENCY_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
      }
    }
    default:
      return state;
  }
};