import { AnyAction, Reducer } from 'redux';
import { IGetListCryptoDetailState, initialGetListCryptoDetailState } from './state';
import { GetListCryptoDetailTypes } from 'src/store/actions/getListCryptoDetail';

export const GetListCryptoDetailReducer: Reducer = (
  state: IGetListCryptoDetailState = initialGetListCryptoDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
