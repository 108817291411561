import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleCreateTopupTransaction } from 'src/apis/service/CryptoWallet';
import { CreateTopupTransactionTypes } from '../../actions/createTopupTransaction';
import { createTopupTransactionError, createTopupTransactionFetching, createTopupTransactionFilter, createTopupTransactionSuccess } from '../../actions/createTopupTransaction/action';

function* createTopupTransactionSaga(action:any):Generator<any> {
    yield put(createTopupTransactionFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleCreateTopupTransaction(payload)
      if(data && data?.status === 201){
        yield put(createTopupTransactionSuccess(data))
      } else {
        yield put(createTopupTransactionError('error'))
      }
    } catch (error) {
        yield put(createTopupTransactionError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(createTopupTransactionFilter(action.payload));
}
export function* watchCreateTopupTransaction() {
   yield takeEvery(CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION, createTopupTransactionSaga)
   yield debounce(500,CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION_FILTER_DEBOUNCE,handleSearchDebounce)
}
