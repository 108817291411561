import { GetListAdminRolesTypes } from './actionTypes';
import { IGetListAdminRoles, IGetListAdminRolesFetching, IGetListAdminRolesSuccess, IGetListAdminRolesError, IGetListAdminRolesFilter, IGetListAdminRolesFiterDebounce } from "./interfaces";


export const getListAdminRoles = (): IGetListAdminRoles => ({
    type: GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES
})

export const getListAdminRolesFetching = (isFetching: boolean): IGetListAdminRolesFetching => ({
    type: GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_FETCHING,
    isFetching
})

export const getListAdminRolesSuccess = (data: any): IGetListAdminRolesSuccess => ({
    type: GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_SUCCESS,
    data
})

export const getListAdminRolesError = (errorMsg: any): IGetListAdminRolesError => ({
    type: GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_FAILD,
    error: errorMsg
})

export const getListAdminRolesFilter = (payload: any): IGetListAdminRolesFilter => ({
    type: GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_FILTER,
    payload
})

export const getListAdminRolesFilterDebounce = (payload: any): IGetListAdminRolesFiterDebounce => ({
    type: GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_FILTER_DEBOUNCE,
    payload
})

export const cleanListAdminRoles = (): any => ({
    type: GetListAdminRolesTypes.CLEAN_LIST_ADMIN_ROLES
})