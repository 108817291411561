import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetAllTransaction } from 'src/apis/service/Transactions';
import { getTransactionsAtFetching, getTransactionsFaild, getTransactionsSuccess, GetTransactionsTypes, setFilterTransaction } from 'src/store/actions';

function* getTransactionSaga(action:any):Generator<any> {
    yield put(getTransactionsAtFetching(true))
    const {payload} = action;
    try {
      const dataTransactions:any = yield handleGetAllTransaction(payload)
      if(dataTransactions && dataTransactions?.status === 200) {
        yield put(getTransactionsSuccess(dataTransactions))
      } else {
        yield put(getTransactionsFaild('error'))
      }
    } catch (error) {
        yield put(getTransactionsFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterTransaction(action.payload));
}

export function* watchGetListTransaction() {
   yield takeEvery(GetTransactionsTypes.GET_TRANSACTION, getTransactionSaga)
   yield debounce(500, GetTransactionsTypes.GET_LIST_FILTER_DEBOUNCE_TRANSACTION, handleSearchDebounce)
}