import { put, takeEvery, debounce } from 'redux-saga/effects';
import { PostCreateExchangeTransactionTypes } from '../../actions/postCreateExchangeTransaction';
import {
  postCreateExchangeTransactionFetching,
  postCreateExchangeTransactionSuccess,
  postCreateExchangeTransactionError,
  postCreateExchangeTransactionFilter,
} from '../../actions/postCreateExchangeTransaction/action';
import { handlePostCreateExchangeTransaction } from 'src/apis/service/P2PExchange';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { find } from 'lodash';
import { STATUS_TRANSACTION_OPTIONS } from 'src/contants';

function* postCreateExchangeTransactionSaga(action: any): Generator<any> {
  yield put(postCreateExchangeTransactionFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handlePostCreateExchangeTransaction(payload);
    if (data && data?.status === 200) {
      yield put(postCreateExchangeTransactionSuccess(data));

      toastSuccess('bo_success');
    } else {
      yield put(postCreateExchangeTransactionError('error'));
      toastError(
        find(STATUS_TRANSACTION_OPTIONS, { value: data?.message })?.label ||
          data?.message ||
          'bo_error'
      );
    }
  } catch (error: any) {
    yield put(postCreateExchangeTransactionError(error));
    toastError(
      find(STATUS_TRANSACTION_OPTIONS, { value: error?.message })?.label ||
        error?.message ||
        'bo_error'
    );
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(postCreateExchangeTransactionFilter(action.payload));
}
export function* watchPostCreateExchangeTransaction() {
  yield takeEvery(
    PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION,
    postCreateExchangeTransactionSaga
  );
  yield debounce(
    500,
    PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
