import { AnyAction, Reducer } from 'redux';
import { UpdateAnnouncementsType } from 'src/store/actions';
import { IListAnnouncementsState, initialListAnnouncementsState } from 'src/store/reducers';


export const UpdateAnnouncementsReducer: Reducer = (
  state: IListAnnouncementsState = initialListAnnouncementsState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        successCount: state.successCount + 1
      }
    }
    default:
      return state;
  }
};