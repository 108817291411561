import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetListTransactionCryptoTypes} from '../../actions/getListTransactionCrypto';
import {getListTransactionCryptoFetching,getListTransactionCryptoSuccess,getListTransactionCryptoError, getListTransactionCryptoFilter} from '../../actions/getListTransactionCrypto/action';
import { getListTransaction } from 'src/apis/service/CryptoTransactionManagement';

function* getListTransactionCryptoSaga(action:any):Generator<any> {
    yield put(getListTransactionCryptoFetching(true))
    const {payload} = action;
    const { page, size, type, startDate, endDate, status } = payload;
    let newPayload = {
      page,
      size,
      type,
      startDate,
      endDate,
    }
    if (status) {
      newPayload = {...payload};
    }
    try {
      const data:any = yield getListTransaction(newPayload)
      if(data && data?.status === 200){
        yield put(getListTransactionCryptoSuccess(data))
      } else {
        yield put(getListTransactionCryptoError('error'))
      }
    } catch (error) {
        yield put(getListTransactionCryptoError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListTransactionCryptoFilter(action.payload));
}
export function* watchGetListTransactionCrypto() {
   yield takeEvery(GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO, getListTransactionCryptoSaga)
   yield debounce(500,GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_FILTER_DEBOUNCE,handleSearchDebounce)
}
