import { GetDirectTransfersTypes } from './actionTypes';
import {
    IGetDirectTransfers,
    IGetDirectTransfersFetching,
    IGetDirectTransfersSuccess,
    IGetDirectTransfersFaild,
    IFilterDirectTransfers,
    IFilterDebounceDirectTransfers
} from './interfaces';

export const getDirectTransfersAtFetching = (isFetching:boolean) : IGetDirectTransfersFetching  => ({
    type: GetDirectTransfersTypes.GET_DIRECT_TRANSFERS_FETCHING,
    isFetching
})

export const getDirectTransfers = (payload:Object) : IGetDirectTransfers => ({
    type: GetDirectTransfersTypes.GET_DIRECT_TRANSFERS,
    payload
})

export const getDirectTransfersSuccess = (data:any) : IGetDirectTransfersSuccess => ({
    type: GetDirectTransfersTypes.GET_DIRECT_TRANSFERS_SUCCESS,
    data
})

export const getDirectTransfersFaild = (error:any) : IGetDirectTransfersFaild => ({
    type: GetDirectTransfersTypes.GET_DIRECT_TRANSFERS_FAILD,
    error
})

export const setFilterDirectTransfers = (payload: any): IFilterDirectTransfers => ({
    type: GetDirectTransfersTypes.FILTER_DIRECT_TRANSFERS,
    payload
})

export const setFilterDebounceDirectTransfers = (payload: any): IFilterDebounceDirectTransfers => ({
    type: GetDirectTransfersTypes.FILTER_DEBOUNCE_DIRECT_TRANSFERS,
    payload 
})