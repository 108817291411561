import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetAgentRankByAdminTypes} from '../../actions/getAgentRankByAdmin';
import {getAgentRankByAdminFetching,getAgentRankByAdminSuccess,getAgentRankByAdminError, getAgentRankByAdminFilter} from '../../actions/getAgentRankByAdmin/action';
import { adminP2PGetAgentRank } from 'src/apis/service/Priority';

function* getAgentRankByAdminSaga(action:any):Generator<any> {
    yield put(getAgentRankByAdminFetching(true))
    const {payload} = action;
    try {
      const data:any = yield adminP2PGetAgentRank(payload)
      if(data && data?.status === 200){
        yield put(getAgentRankByAdminSuccess(data))
      } else {
        yield put(getAgentRankByAdminError('error'))
      }
    } catch (error) {
        yield put(getAgentRankByAdminError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAgentRankByAdminFilter(action.payload));
}
export function* watchGetAgentRankByAdmin() {
   yield takeEvery(GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN, getAgentRankByAdminSaga)
   yield debounce(500,GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_FILTER_DEBOUNCE,handleSearchDebounce)
}
