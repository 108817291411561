import { AnyAction, Reducer } from 'redux';
import { GetAgencyManagementType } from 'src/store/actions';
import { IListAgencyState, initialListAgencyState } from './state';


export const GetListAgencyReducer: Reducer = (
  state: IListAgencyState = initialListAgencyState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetAgencyManagementType.GET_AGENCY_MANAGEMENT : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAgencyManagementType.GET_AGENCY_MANAGEMENT_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case GetAgencyManagementType.GET_AGENCY_MANAGEMENT_SUCCESS : {
      return {
          ...state,
          data: action?.data?.data?.items,
          isFetching: false,
          pagination: action.data?.data?.totalCount
      }
    }
    case GetAgencyManagementType.GET_LIST_AGENCY_MANAGEMENT_FILTER: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};