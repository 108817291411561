import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeHistoryOffer } from 'src/apis/service/P2PExchange';
import { getP2PExchangeOffersHistoryFaild, getP2PExchangeOffersHistoryIsFetching, getP2PExchangeOffersHistorySuccess, GetP2PExchangeOffersHistoryType } from 'src/store/actions/P2PExchangeOfferHistory';

function* getListP2PExchangeOffersHistorySaga(action: any): Generator<any> {
    yield put(getP2PExchangeOffersHistoryIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeHistoryOffer, payload)      
      if(data && data?.status === 200){
        yield put(getP2PExchangeOffersHistorySuccess(data));
      } else {
        yield put(getP2PExchangeOffersHistoryFaild('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeOffersHistoryFaild(error))
    }
}

export function* watchGetListP2PExchangeOffersHistory() {
   yield takeEvery(GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY , getListP2PExchangeOffersHistorySaga)
}