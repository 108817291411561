import { put, takeEvery, select } from 'redux-saga/effects';
import { WalletRefillType } from '../../actions/wallet-refill/actionTypes';
import {
  walletRefillIsFetching,
  walletRefillSuccess,
  walletRefillFaild,
} from '../../actions/wallet-refill/action';
import { handleWalletRefil } from '../../../apis/service/Wallet';
import { getStorageItem } from '../../../utils/storage/auth';
import { getListWallet } from '../../actions/getListWallet/action';
import { getAllWalletSuperAdminBO } from '../../actions/WalletAdminBO/getWalletSuperAdminBO/action';
import { IApplicationState } from '../../../store/IApplicationState';

function* walletRefillSaga(action: any): Generator<any> {
  yield put(walletRefillIsFetching(true));
  const { payload } = action;
  try {
    const dataWalletRefill: any = yield handleWalletRefil(payload);
    if (dataWalletRefill.status === 200) {
      const user = getStorageItem('user');
      yield put(walletRefillSuccess(dataWalletRefill));
      if (user) {
        const id = JSON.parse(user).userId;
        const filter: any = yield select(
          (state: IApplicationState) => state.listAllWalletAdmin.filter
        );
        yield put(getListWallet(id, true));
        yield put(getAllWalletSuperAdminBO({ filter, userId: id }));
      }
    } else {
      yield put(walletRefillFaild(dataWalletRefill.errors[0]));
    }
  } catch (error) {
    yield put(walletRefillFaild(error));
  }
}
export function* watchWalletRefill() {
  yield takeEvery(WalletRefillType.WALLET_REFILL, walletRefillSaga);
}
