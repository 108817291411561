import { AnyAction, Reducer } from 'redux';
import { GetDirectTransfersTypes } from 'src/store/actions';
import { IDirectTransfersState, initialDirectTransfersState } from './state';

export const GetDirectTransfersReducer: Reducer = (
  state: IDirectTransfersState = initialDirectTransfersState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetDirectTransfersTypes.GET_DIRECT_TRANSFERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetDirectTransfersTypes.GET_DIRECT_TRANSFERS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetDirectTransfersTypes.GET_DIRECT_TRANSFERS_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetDirectTransfersTypes.FILTER_DIRECT_TRANSFERS: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
