export interface IDataDeleteCurrency {
    status: number;
    data: any;
    message: string;
}

export interface IDataDeleteCurrencyState {
    data: IDataDeleteCurrency,
    isFetching: boolean,
    success: boolean,
    successCount: number
}

export const initialDeleteCurrencyState: IDataDeleteCurrencyState = {
    data: {
        data: [],
        status: 0,
        message: ''
    },
    isFetching: false,
    success: false,
    successCount: 0
}