interface IDataItemKycDetail {
  data: Object;
  status: number;
  message: string;
}

export interface IListKycDetailState {
  data: IDataItemKycDetail;
  isFetching: boolean;
  isSuccess: boolean;
}

export const initialListKycDetailState: IListKycDetailState = {
  data: {
    data: {},
    status: 0,
    message: '',
  },
  isFetching: false,
  isSuccess: false,
};
