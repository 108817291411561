import { call, put, takeEvery } from 'redux-saga/effects';
import {
    GetCryptoWalletGetNewOverviewType,
    getCryptoWalletGetNewOverviewSuccess,
    getCryptoWalletNewOverviewIsFetching,
    getCryptoWalletGetNewOverviewFailed
} from 'src/store/actions';
import { handleGetNewCryptoOverviewData } from '../../../apis/service/CryptoWalletNew';

function* getCryptoWalletNewOverviewSaga():Generator<any> {
    yield put(getCryptoWalletNewOverviewIsFetching(true))
    try {
        const data:any = yield call(handleGetNewCryptoOverviewData)
        if(data && data?.status === 200) {
            yield put(getCryptoWalletGetNewOverviewSuccess(data))
        } else {
            yield put(getCryptoWalletGetNewOverviewFailed('error'))
        }
    } catch (error) {
        yield put(getCryptoWalletGetNewOverviewFailed(error))
    } finally {
        yield put(getCryptoWalletNewOverviewIsFetching(false))
    }
}

export function* watchGetListCryptoWalletNewOverview() {
    yield takeEvery(GetCryptoWalletGetNewOverviewType.GET_NEW_CRYPTO_WALLET_OVERVIEW, getCryptoWalletNewOverviewSaga)
}
