import { Set2FADisableTypes } from './actionTypes';
import { ISet2FADisable, ISet2FADisableFetching, ISet2FADisableSuccess, ISet2FADisableError, ISet2FADisableFilter, ISet2FADisableFiterDebounce } from "./interfaces";


export const set2FADisable = (): ISet2FADisable => ({
    type: Set2FADisableTypes.SET2FA_DISABLE,
})

export const set2FADisableFetching = (isFetching: boolean): ISet2FADisableFetching => ({
    type: Set2FADisableTypes.SET2FA_DISABLE_FETCHING,
    isFetching
})

export const set2FADisableSuccess = (data: any): ISet2FADisableSuccess => ({
    type: Set2FADisableTypes.SET2FA_DISABLE_SUCCESS,
    data
})

export const set2FADisableError = (errorMsg: any): ISet2FADisableError => ({
    type: Set2FADisableTypes.SET2FA_DISABLE_FAILD,
    error: errorMsg
})

export const set2FADisableFilter = (payload: any): ISet2FADisableFilter => ({
    type: Set2FADisableTypes.SET2FA_DISABLE_FILTER,
    payload
})

export const set2FADisableFilterDebounce = (payload: any): ISet2FADisableFiterDebounce => ({
    type: Set2FADisableTypes.SET2FA_DISABLE_FILTER_DEBOUNCE,
    payload
})
