import { put, takeEvery, debounce } from "redux-saga/effects";
import { HandleWithdrawTransactionTypes } from "../../actions/handleWithdrawTransaction";
import {
  handleWithdrawTransactionFetching,
  handleWithdrawTransactionSuccess,
  handleWithdrawTransactionError,
  handleWithdrawTransactionFilter,
} from "../../actions/handleWithdrawTransaction/action";
import { handleWithdrawTransaction } from "src/apis/service/CryptoTransactionManagement";
import { getWalletDetail } from "src/store/actions/getWalletDetail";

function* handleWithdrawTransactionSaga(action: any): Generator<any> {
  yield put(handleWithdrawTransactionFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleWithdrawTransaction(payload);
    if (data && data?.status === 200) {
      if (payload.paramsGetWalletDetail) {
        yield put(getWalletDetail(payload.paramsGetWalletDetail));
      }
      yield put(handleWithdrawTransactionSuccess(data));
    } else {
      yield put(handleWithdrawTransactionError("error"));
    }
  } catch (error) {
    yield put(handleWithdrawTransactionError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(handleWithdrawTransactionFilter(action.payload));
}
export function* watchHandleWithdrawTransaction() {
  yield takeEvery(
    HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION,
    handleWithdrawTransactionSaga
  );
  yield debounce(
    500,
    HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
