import * as Sentry from "@sentry/react";
import Request from "../../config";

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_TRANSFER});
const requestApiAccount = new Request({url: process.env.REACT_APP_BASE_URL_ACCOUNT});
const handleGetTransactionOverview = async () : Promise<any[]> => {
  const url = `/Transaction/Overview`;
  try {
    return await requestApi.post(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetUserOverview = async () : Promise<any[]> => {
  const url = `/User/Overview`;
  try {
    return await requestApiAccount.post(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleTransactionAgentOverview = async () : Promise<any[]> => {
  try {
    return await requestApi.get('/Transaction/AgentOverview')
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export {
  handleGetTransactionOverview,
  handleGetUserOverview,
  handleTransactionAgentOverview
};

