import { PAGE_SIZE_DEFAULT } from './../../../contants/pageSize';
import { ListParams } from 'src/models/common';

interface IDataGetTranscationsByAgent {
  data: {
    items: any[];
    totalCount: number;
  };
  error: any;
  success: boolean;
}

export interface IGetTranscationsByAgentState {
  data: IDataGetTranscationsByAgent;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialGetTranscationsByAgentState: IGetTranscationsByAgentState =
  {
    data: {
      data: {
        items: [],
        totalCount: 0,
      },
      error: null,
      success: false,
    },
    isFetching: false,
    filter: {
      pageSize: PAGE_SIZE_DEFAULT,
      pageNumber: 1,
    },
    pagination: {
      totalCount: 0,
    },
  };
