import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetTransactionsFilterTypes } from 'src/apis/service/P2PTransaction';
import { GetTransactionsFilterTypesTypes } from '../../actions/getTransactionsFilterTypes';
import { getTransactionsFilterTypesError, getTransactionsFilterTypesFetching, getTransactionsFilterTypesFilter, getTransactionsFilterTypesSuccess } from '../../actions/getTransactionsFilterTypes/action';
import { setStorageItem } from 'src/utils/newUtils/localStorageHelper';
import { LocalStorageKey } from 'src/contants/constantUtils/localStorageKey';

function* getTransactionsFilterTypesSaga():Generator<any> {
    yield put(getTransactionsFilterTypesFetching(true))
    try {
      const data:any = yield handleGetTransactionsFilterTypes()
      if(data && data?.status === 200){
        yield put(getTransactionsFilterTypesSuccess(data))
        setStorageItem(LocalStorageKey.TransactionsFilterTypes, JSON.stringify(data?.data));
      } else {
        yield put(getTransactionsFilterTypesError('error'))
      }
    } catch (error) {
        yield put(getTransactionsFilterTypesError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getTransactionsFilterTypesFilter(action.payload));
}
export function* watchGetTransactionsFilterTypes() {
   yield takeEvery(GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES, getTransactionsFilterTypesSaga)
   yield debounce(500,GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES_FILTER_DEBOUNCE,handleSearchDebounce)
}
