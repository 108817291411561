import {GetOfferAllTypes} from './actionTypes';
import {
    IGetListFilterDebounceOfferAll,
    IGetListFilterOfferAll,
    IGetOfferAll,
    IGetOfferAllFaild,
    IGetOfferAllFetching,
    IGetOfferAllSuccess
} from './interfaces';

export const getOfferAllAtFetching = (isFetching:boolean) : IGetOfferAllFetching  => ({
    type: GetOfferAllTypes.GET_OFFER_ALL_FETCHING,
    isFetching
})

export const getOfferAll = (payload: Object) : IGetOfferAll => ({
    type: GetOfferAllTypes.GET_OFFER_ALL,
    payload
})

export const getOfferAllSuccess = (data:any) : IGetOfferAllSuccess => ({
    type: GetOfferAllTypes.GET_OFFER_ALL_SUCCESS,
    data
})

export const getOfferAllFaild = (error:any) : IGetOfferAllFaild => ({
    type: GetOfferAllTypes.GET_OFFER_ALL_FAILD,
    error
})

export const setFilterOfferAll = (payload: any): IGetListFilterOfferAll => ({
    type: GetOfferAllTypes.GET_LIST_FILTER_OFFER_ALL,
    payload
})

export const setFilterDebounceOfferAll = (payload: any): IGetListFilterDebounceOfferAll => ({
    type: GetOfferAllTypes.GET_LIST_FILTER_DEBOUNCE_OFFER_ALL,
    payload 
})