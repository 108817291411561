import {GetListTokenSupportTypes} from './actionTypes';
import { IGetListTokenSupport, IGetListTokenSupportFetching, IGetListTokenSupportSuccess, IGetListTokenSupportError, IGetListTokenSupportFilter, IGetListTokenSupportFiterDebounce } from "./interfaces";


export const getListTokenSupport = (): IGetListTokenSupport => ({
    type: GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT
})

export const getListTokenSupportFetching = (isFetching:boolean) : IGetListTokenSupportFetching => ({
    type: GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_FETCHING,
    isFetching
})

export const getListTokenSupportSuccess = (data:any) : IGetListTokenSupportSuccess => ({
   type: GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_SUCCESS,
   data
})

export const getListTokenSupportError = (errorMsg:any) : IGetListTokenSupportError => ({
    type: GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_FAILD,
    error:errorMsg
})

export const getListTokenSupportFilter = (payload: any): IGetListTokenSupportFilter => ({
    type: GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_FILTER,
    payload
})

export const getListTokenSupportFilterDebounce = (payload: any): IGetListTokenSupportFiterDebounce => ({
    type: GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_FILTER_DEBOUNCE,
    payload 
})
