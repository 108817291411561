import { AnyAction, Reducer } from 'redux';
import { initialExportCryptoReceiveTransactionState, ExportCryptoReceiveTransactionState } from './state';
import { ExportCryptoReceiveTransactionType } from '../../actions/ExportCryptoReceiveTransaction/actionTypes';


export const ExportCryptoReceiveTransactionReducer: Reducer = (
  state: ExportCryptoReceiveTransactionState = initialExportCryptoReceiveTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case ExportCryptoReceiveTransactionType.EXPORT_CRYPTO_RECEIVE_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ExportCryptoReceiveTransactionType.EXPORT_CRYPTO_RECEIVE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case ExportCryptoReceiveTransactionType.EXPORT_CRYPTO_RECEIVE_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
