import {EditWalletCryptoTypes} from './actionTypes';
import { IEditWalletCrypto, IEditWalletCryptoFetching, IEditWalletCryptoSuccess, IEditWalletCryptoError, IEditWalletCryptoFilter, IEditWalletCryptoFiterDebounce } from "./interfaces";


export const editWalletCrypto = (payload:Object, closeModal?:any): IEditWalletCrypto => ({
    type: EditWalletCryptoTypes.EDIT_WALLET_CRYPTO,
    payload,
    closeModal
})

export const editWalletCryptoFetching = (isFetching:boolean) : IEditWalletCryptoFetching => ({
    type: EditWalletCryptoTypes.EDIT_WALLET_CRYPTO_FETCHING,
    isFetching
})

export const editWalletCryptoSuccess = (data:any) : IEditWalletCryptoSuccess => ({
   type: EditWalletCryptoTypes.EDIT_WALLET_CRYPTO_SUCCESS,
   data
})

export const editWalletCryptoError = (errorMsg:any) : IEditWalletCryptoError => ({
    type: EditWalletCryptoTypes.EDIT_WALLET_CRYPTO_FAILD,
    error:errorMsg
})

export const editWalletCryptoFilter = (payload: any): IEditWalletCryptoFilter => ({
    type: EditWalletCryptoTypes.EDIT_WALLET_CRYPTO_FILTER,
    payload
})

export const editWalletCryptoFilterDebounce = (payload: any): IEditWalletCryptoFiterDebounce => ({
    type: EditWalletCryptoTypes.EDIT_WALLET_CRYPTO_FILTER_DEBOUNCE,
    payload 
})
