import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletGetOverviewType } from 'src/store/actions';
import { IListCryptoWalletGetOverviewState, initialListCryptoWalletGetOverviewState } from './state';


export const GetListCryptoWalletGetOverviewReducer: Reducer = (
  state: IListCryptoWalletGetOverviewState = initialListCryptoWalletGetOverviewState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW_SUCCESS : {
      return {
          ...state,
          data: action?.data.data,
          isFetching: false,
          pagination: action.data.data.totalCount
      }
    }
    case GetCryptoWalletGetOverviewType.GET_LIST_FILTER_CRYPTO_WALLET_GET_OVERVIEW: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};