import { put, takeEvery } from 'redux-saga/effects';
import { handleDeleteExchangeOffer } from 'src/apis/service/P2PExchange';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { removeExchangeOfferFaild, removeExchangeOfferIsFetching, removeExchangeOfferSuccess } from '../../actions/removeExchangeOffer/action';
import { RemoveExchangeOfferType } from '../../actions/removeExchangeOffer/actionTypes';

function* RemoveExchangeOfferSaga(action:any):Generator<any>{


  yield put(removeExchangeOfferIsFetching(true))
  const {payload} = action;
  try {
    const data: any = yield handleDeleteExchangeOffer(payload)  
    if (data.status === 200) {
      toastSuccess('bo_delete_offer_success')
      yield put(removeExchangeOfferSuccess(data))
    } else {
      toastError('bo_you_can_t_delete_this_offer_due_to_pending_transaction')
    }
  } catch (error) {
     yield put(removeExchangeOfferFaild(error)) 
  }
}
export function* watchRemoveExchangeOffer(){
    yield takeEvery(RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER, RemoveExchangeOfferSaga)
}