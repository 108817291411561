import { ListParams } from "src/models/common";

interface IDataUpdateScoreKPI {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  success:boolean;
}

export interface IUpdateScoreKPIState {
    data:IDataUpdateScoreKPI;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
    successCount: number
}

export const initialUpdateScoreKPIState: IUpdateScoreKPIState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    },
    successCount: 0
}


