import { AnyAction, Reducer } from 'redux';
import { initialScoreHistoryState, IDataScoreHistoryState } from './state';
import { GetScoreHistoryType } from '../../actions/getScoreHistory';


export const GetScoreHistoryReducer: Reducer = (
  state: IDataScoreHistoryState = initialScoreHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
      case GetScoreHistoryType.GET_SCORE_HISTORY: {
         return {
             ...state,
             isFetching:true
         }
      }
      case GetScoreHistoryType.GET_SCORE_HISTORY_SUCCESS: {
          return {
              ...state,
              data: action.data,
              isFetching:false,
              pagination: action.data.data.totalCount
          }
      }
      case GetScoreHistoryType.GET_SCORE_HISTORY_ERROR: {
        return {
            ...state,
            error: action.error,
            isFetching:false
        }
    }
    case GetScoreHistoryType.SET_LIST_FILTER_SCORE_HISTORY: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:{
      return state
    }
  }
}