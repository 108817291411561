import { AnyAction, Reducer } from 'redux';
import { IPostCreateExchangeTransactionState, initialPostCreateExchangeTransactionState } from './state';
import { PostCreateExchangeTransactionTypes } from 'src/store/actions/postCreateExchangeTransaction';

export const PostCreateExchangeTransactionReducer: Reducer = (
  state: IPostCreateExchangeTransactionState = initialPostCreateExchangeTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
