import { put, select, takeEvery } from 'redux-saga/effects';
import { adminP2PSetAgentRank } from 'src/apis/service/Priority';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import {
  setAgentRankAtFetching,
  setAgentRankFaild,
  setAgentRankSuccess, SetAgentRankType
} from 'src/store/actions/Agent/SetAgentRank';
import { IApplicationState } from 'src/store/IApplicationState';
import { getP2PExRankAndScore } from './../../../actions/getP2PExRankAndScore/action';


function* setAndEditAgentRankSaga(action: any): Generator<any> {


  const { payload,isUserScore,history  } = action;
  yield put(setAgentRankAtFetching(true))
  try {
    const data : any = yield adminP2PSetAgentRank(payload)
    if (data.status ===200) {
      const filter: any = yield (select((state:IApplicationState) => state.listAgentRank.filter))
       yield put(setAgentRankSuccess(data))
      //  yield put(getP2PExRankAndScore())
       toastSuccess('bo_set_rank_for_user_success')
       if(history !== undefined){
        if(isUserScore) {
          history.push('/dashboard/user/user-score')
          return;
         }
         history.push('/dashboard/settings/priorities')
       }
    }
  } catch (error:any) {
      toastError('bo_set_rank_for_user_fail')
      yield put(setAgentRankFaild(error.message))
  }
}

export function* watchsetAndEditAgentRankBO() {
   yield takeEvery(SetAgentRankType.SET_AGENT_RANK , setAndEditAgentRankSaga)
}