import { toastSuccess } from 'src/helpers/toastHelper';
import {put, takeEvery, select} from 'redux-saga/effects';
import {CreateAdminTypes} from '../../actions/createAdmin/actionTypes';
import {createAdminAtFetching, createAdminSuccess, createAdminFaild} from '../../actions/createAdmin/action';
import {handleCreateAdmin} from '../../../apis/service/user';
import {IApplicationState} from '../../IApplicationState';
import {getListUser} from '../../actions/getListUser/action';

function* createAdminSaga(action:any):Generator<any>{


  yield put(createAdminAtFetching(true))
  const { payload, history } = action;
  try {
    const dataCreateAdmin: any = yield handleCreateAdmin(payload)  
    if (dataCreateAdmin.status === 200) {
      const filter : any = yield select((state:IApplicationState) => state.listUser.filter)
      toastSuccess('bo_create_admin_success');
      yield put(history.push('/dashboard/user/list'))
      yield put(createAdminSuccess(dataCreateAdmin))
      yield put(getListUser(filter))
    } else {
      yield put(createAdminFaild(dataCreateAdmin?.errors[0]))
      yield put(history.push('/dashboard/user/list'))
    }
  } catch (error) {
     yield put(createAdminFaild(error)) 
  }
}
export function* watchCreateAdmin(){
    yield takeEvery(CreateAdminTypes.CREATE_ADMIN, createAdminSaga)
}