import { DeleteWalletAdminBOType } from './actionTypes';
import {
    IDeleteWalletAdminBO,
    IDeleteWalletAdminBOFaild,
    IDeleteWalletAdminBOFetching,
    IDeleteWalletAdminBOSuccess
} from './interfaces';

export const deleteWalletAdminBOAtFetching = (isFetching: boolean): IDeleteWalletAdminBOFetching => ({
    type: DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO_FETCHING,
    isFetching
})

export const deleteWalletAdminBO = (payload: any): IDeleteWalletAdminBO => ({
    type: DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO,
    payload
})

export const deleteWalletAdminBOSuccess = (data: any): IDeleteWalletAdminBOSuccess => ({
    type: DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO_SUCCESS,
    data
})

export const deleteWalletAdminBOFaild = (error: any): IDeleteWalletAdminBOFaild => ({
    type: DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO_FAILD,
    error
})
