import { AnyAction, Reducer } from 'redux';
import { IGetListAddressBooksState, initialGetListAddressBooksState } from './state';
import { GetListAddressBooksTypes } from 'src/store/actions/getListAddressBooks';

export const GetListAddressBooksReducer: Reducer = (
  state: IGetListAddressBooksState = initialGetListAddressBooksState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
