import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetListUserTypes} from '../../actions/getListUser/actionTypes';
import {getListUserAtFetching,getListUserSuccess,getListUserFaild, setFilterListUser} from '../../actions/getListUser/action';
import {handleGetListUser} from '../../../apis/service/user';

function* getListUserSaga(action:any):Generator<any> {
    yield put(getListUserAtFetching(true))
    const {payload} = action;
    try {
      const dataTransactions:any = yield handleGetListUser(payload)
      if(dataTransactions && dataTransactions?.status === 200){
        yield put(getListUserSuccess(dataTransactions))
      } else {
        yield put(getListUserFaild('error'))
      }
    } catch (error) {
        yield put(getListUserFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterListUser(action.payload));
}
export function* watchGetListUser() {
   yield takeEvery(GetListUserTypes.GET_LIST_USER, getListUserSaga)
   yield debounce(500,GetListUserTypes.GET_LIST_FILTER_DEBOUNCE_USER,handleSearchDebounce)
}