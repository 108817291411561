import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetAllTransactionsByAdmin } from 'src/apis/service/P2PTransaction';
import { GetAllTransactionsByAdminTypes } from '../../actions/getAllTransactionsByAdmin';
import { getAllTransactionsByAdminError, getAllTransactionsByAdminFetching, getAllTransactionsByAdminFilter, getAllTransactionsByAdminSuccess } from '../../actions/getAllTransactionsByAdmin/action';

function* getAllTransactionsByAdminSaga(action:any):Generator<any> {
    yield put(getAllTransactionsByAdminFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetAllTransactionsByAdmin(payload)
      if(data && data?.status === 200){
        yield put(getAllTransactionsByAdminSuccess(data))
      } else {
        yield put(getAllTransactionsByAdminError('error'))
      }
    } catch (error) {
        yield put(getAllTransactionsByAdminError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAllTransactionsByAdminFilter(action.payload));
}
export function* watchGetAllTransactionsByAdmin() {
   yield takeEvery(GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN, getAllTransactionsByAdminSaga)
   yield debounce(500,GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN_FILTER_DEBOUNCE,handleSearchDebounce)
}
