import { EditOfferSellOrBuy } from './actionTypes';
import {
   IeditOfferBuyOrSell,
   IeditOfferBuyOrSellFaild,
   IeditOfferBuyOrSellFetching,
   IeditOfferBuyOrSellSuccess
} from './interfaces';

export const editOfferSellOrBuyAtFetching = (isFetching:boolean) : IeditOfferBuyOrSellFetching  => ({
    type: EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY_IS_FETCHING,
    isFetching
})

export const editOfferSellOrBuy = (payload:Object, history:any, editType:string) : IeditOfferBuyOrSell => ({
    type: EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY,
    payload,
    history,
    editType
})

export const editOfferSellOrBuySuccess = (data:any) : IeditOfferBuyOrSellSuccess => ({
    type: EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY_SUCCESS,
    data
})

export const editOfferSellOrBuyFaild = (error:any) : IeditOfferBuyOrSellFaild => ({
    type: EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY_FAILD,
    error
})