import { GetAllAdminBOType } from './actionTypes';
import {
    IFilterAdminBO,
    IFilterDebounceAdminBO,
    IGetAdminBO,
    IGetAdminBOFaild,
    IGetAdminBOFetching,
    IGetAdminBOSuccess
} from './interfaces';

export const getAdminBOAtFetching = (isFetching:boolean) : IGetAdminBOFetching  => ({
    type: GetAllAdminBOType.GET_ADMIN_BO_FETCHING,
    isFetching
})

export const getAdminBO = (payload:Object) : IGetAdminBO => ({
    type: GetAllAdminBOType.GET_ADMIN_BO,
    payload
})

export const getAdminBOSuccess = (data:any) : IGetAdminBOSuccess => ({
    type: GetAllAdminBOType.GET_ADMIN_BO_SUCCESS,
    data
})

export const getAdminBOFaild = (error:any) : IGetAdminBOFaild => ({
    type: GetAllAdminBOType.GET_ADMIN_BO_FAILD,
    error
})

export const setFilterAdminBO = (payload: any): IFilterAdminBO => ({
    type: GetAllAdminBOType.FILTER_ADMIN_BO,
    payload
})

export const setFilterDebounceAdminBO = (payload: any): IFilterDebounceAdminBO => ({
    type: GetAllAdminBOType.FILTER_DEBOUNCE_ADMIN_BO,
    payload 
})

