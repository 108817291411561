import { AnyAction, Reducer } from 'redux';
import { IApplyKYCUserInfoState, initialApplyKYCUserInfoState } from './state';
import { ApplyKYCUserInfoTypes } from 'src/store/actions/ApplyKYCUserInfo';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

export const ApplyKYCUserInfoReducer: Reducer = (
  state: IApplyKYCUserInfoState = initialApplyKYCUserInfoState,
  action: AnyAction
) => {
  switch (action.type) {
    case ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_SUCCESS: {
      // toastSuccess("update_success")
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
        successCount: state.successCount + 1
      };
    }
    case ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_FAILD: {
      toastError("bo_failed")
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
