import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleDeleteScoreKPI } from 'src/apis/service/ScorePeriodConfig';
import { DeleteScoreKPITypes } from '../../actions/deleteScoreKPI';
import {
  deleteScoreKPIError,
  deleteScoreKPIFetching,
  deleteScoreKPIFilter,
  deleteScoreKPISuccess,
} from '../../actions/deleteScoreKPI/action';
import { toastError, toastSuccess } from './../../../helpers/toastHelper';

function* deleteScoreKPISaga(action: any): Generator<any> {
  yield put(deleteScoreKPIFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleDeleteScoreKPI(payload);
    if (data && data?.status === 200) {
      // yield put(getAllScoreKPI());
      toastSuccess('bo_delete_score_kpi_config_success');
      yield put(deleteScoreKPISuccess());
    } else {
      toastError('bo_you_can_t_delete_this_score_kpi_config');
      yield put(deleteScoreKPIError('error'));
    }
  } catch (error) {
    yield put(deleteScoreKPIError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(deleteScoreKPIFilter(action.payload));
}
export function* watchDeleteScoreKPI() {
  yield takeEvery(DeleteScoreKPITypes.DELETE_SCORE_KPI, deleteScoreKPISaga);
  yield debounce(
    500,
    DeleteScoreKPITypes.DELETE_SCORE_KPI_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
