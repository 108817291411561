import { DeleteOfferByAgentTypes } from './actionTypes';
import {
  IDeleteOfferByAgent,
  IDeleteOfferByAgentFetching,
  IDeleteOfferByAgentSuccess,
  IDeleteOfferByAgentError,
  IDeleteOfferByAgentFilter,
  IDeleteOfferByAgentFiterDebounce,
} from './interfaces';

export const deleteOfferByAgent = (
  payload: Object,
  isP2PBuy: boolean,
  filters: Object
): IDeleteOfferByAgent => ({
  type: DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT,
  payload,
  isP2PBuy,
  filters,
});

export const deleteOfferByAgentFetching = (
  isFetching: boolean
): IDeleteOfferByAgentFetching => ({
  type: DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT_FETCHING,
  isFetching,
});

export const deleteOfferByAgentSuccess = (
  data: any
): IDeleteOfferByAgentSuccess => ({
  type: DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT_SUCCESS,
  data,
});

export const deleteOfferByAgentError = (
  errorMsg: any
): IDeleteOfferByAgentError => ({
  type: DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT_FAILD,
  error: errorMsg,
});

export const deleteOfferByAgentFilter = (
  payload: any
): IDeleteOfferByAgentFilter => ({
  type: DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT_FILTER,
  payload,
});

export const deleteOfferByAgentFilterDebounce = (
  payload: any
): IDeleteOfferByAgentFiterDebounce => ({
  type: DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT_FILTER_DEBOUNCE,
  payload,
});
