import { debounce, put, takeEvery } from 'redux-saga/effects';
import { getKycList } from 'src/apis/service/Kyc';
import { getKycListAtFetching, getKycListFaild, getKycListSuccess, GetKycListTypes, setFilterKycList } from 'src/store/actions';

function* getKycListSaga(action:any):Generator<any> {
    yield put(getKycListAtFetching(true))
    const {payload} = action;
    try {
      const dataKycList:any = yield getKycList(payload)
      if(dataKycList?.status === 200) {
        yield put(getKycListSuccess(dataKycList))
      } else {
        yield put(getKycListFaild('error'))
      }
    } catch (error) {
        yield put(getKycListFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterKycList(action.payload));
}

export function* watchGetListKycList() {
   yield takeEvery(GetKycListTypes.GET_KYC_LIST, getKycListSaga)
   yield debounce(500, GetKycListTypes.GET_LIST_FILTER_DEBOUNCE_KYC_LIST, handleSearchDebounce)
}