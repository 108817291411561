import { debounce, put, takeEvery } from "redux-saga/effects";
import { handleGetAgentCryptoSetting } from "src/apis/service/Agent";
import { GetAgentCryptoSettingTypes } from "../../actions/getAgentCryptoSetting";
import {
  getAgentCryptoSettingError,
  getAgentCryptoSettingFetching,
  getAgentCryptoSettingFilter,
  getAgentCryptoSettingSuccess,
} from "../../actions/getAgentCryptoSetting/action";
import { setStorageItem } from "src/utils/newUtils/localStorageHelper";
import { LocalStorageKey } from "src/contants/constantUtils/localStorageKey";

function* getAgentCryptoSettingSaga(action: any): Generator<any> {
  yield put(getAgentCryptoSettingFetching(true));
  try {
    const data: any = yield handleGetAgentCryptoSetting();
    if (data && data?.status === 200) {
      yield put(getAgentCryptoSettingSuccess(data));
      setStorageItem(LocalStorageKey.CryptoSetting, JSON.stringify(data?.data));
    } else {
      setStorageItem(LocalStorageKey.CryptoSetting, JSON.stringify({"currency":"USD","enablePushNotification":true}));
      yield put(getAgentCryptoSettingError("error"));
    }
  } catch (error) {
    setStorageItem(LocalStorageKey.CryptoSetting, JSON.stringify({"currency":"USD","enablePushNotification":true}));
    yield put(getAgentCryptoSettingError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAgentCryptoSettingFilter(action.payload));
}
export function* watchGetAgentCryptoSetting() {
  yield takeEvery(
    GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING,
    getAgentCryptoSettingSaga
  );
  yield debounce(
    500,
    GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
