interface IBlockAndUnBlockP2PExchangeOffer {
    status: number,
    message: string,
    data: any
}

export interface IBlockAndUnBlockP2PExchangeOfferState {
    data: IBlockAndUnBlockP2PExchangeOffer,
    isFetching: boolean,
    successCount: number
}

export const initialBlockAndUnBlockP2PExchangeOfferState: IBlockAndUnBlockP2PExchangeOfferState = {
    data: {
        data: false,
        message: '',
        status: 0
    },
    isFetching: false,
    successCount: 0
}