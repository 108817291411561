import { put, takeEvery } from 'redux-saga/effects';
import { ReSendFeatureNotificationType } from '../../actions/reSendFeatureNotification/actionTypes';
import {
  resendFeaturedNotificationFaild,
  resendFeaturedNotificationIsFetching,
  resendFeaturedNotificationSuccess
} from '../../actions/reSendFeatureNotification/action';
import { handleResendNotification } from '../../../apis/service/Notifications';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* resendFeaturedNotificationSaga(action: any): Generator<any> {


  yield put(resendFeaturedNotificationIsFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleResendNotification(payload);
    if (data.status === 200) {
      yield put(resendFeaturedNotificationSuccess(data));
      toastSuccess('bo_resend_featured_notification_success');
    } else {
      yield put(resendFeaturedNotificationFaild(data.message));
      toastError('bo_resend_featured_notification_faild');
    }
  } catch (error) {
    yield put(resendFeaturedNotificationFaild(error));
  }
}
export function* watchResendFeaturedNotification() {
  yield takeEvery(
    ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION,
    resendFeaturedNotificationSaga
  );
}
