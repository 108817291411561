import { AnyAction, Reducer } from 'redux';
import { ICheckPasswordAdminState, initialCheckPasswordAdminState } from './state';
import { CheckPasswordAdminTypes } from 'src/store/actions/checkPasswordAdmin';

export const CheckPasswordAdminReducer: Reducer = (
  state: ICheckPasswordAdminState = initialCheckPasswordAdminState,
  action: AnyAction
) => {
  switch (action.type) {
    case CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
