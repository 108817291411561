import { AnyAction, Reducer } from 'redux';
import { ICreateOfferBuyOrSellState, initialCreateOfferBuyOrSellState } from './state';
import { CreateOfferSellOrBuy } from '../../../actions/myOfferFromAgent/createOfferBuyOrSell';

export const CreateOfferSellOrBuyReducer: Reducer = (
  state: ICreateOfferBuyOrSellState = initialCreateOfferBuyOrSellState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};