import { delay, put, takeEvery } from 'redux-saga/effects';
import { getUserBalanceHistory, setFiltertUserBalanceHistory } from 'src/store/actions';
import { getListWalletsForUser } from 'src/store/actions/getListWalletsForUser/action';
import { handleChangeUserBalance } from '../../../apis/service/user';
import {
    changeUserBalanceFaild, changeUserBalanceIsFetching,
    changeUserBalanceSuccess, ChangeUserBalanceType
} from '../../actions/changeWalletUserBalance';

function* handleChangeUserBalanceSaga(action:any):Generator<any> {
    yield put(changeUserBalanceIsFetching(true))
    yield delay(500)
    const { payload } = action;
    const payloadUserChangeBalance = { ...payload };
    delete payloadUserChangeBalance.payloadUserBalanceHistory;
    const dataChangeUserBalance:any = yield handleChangeUserBalance(payloadUserChangeBalance)
    try {
        if (dataChangeUserBalance.status === 200) {
            yield put(changeUserBalanceSuccess(dataChangeUserBalance));
            // yield put(getListWalletsForUser(payload.userId));
            // yield put(getUserBalanceHistory(payload.payloadUserBalanceHistory))      
            // yield put(setFiltertUserBalanceHistory({...payload.payloadUserBalanceHistory, pageNumber: 1}))     
        } else {
            yield put(changeUserBalanceFaild(dataChangeUserBalance.message))
        }
    } catch (error) {
        yield put(changeUserBalanceFaild(error))
    }
}
export function* watchChangeWalletUserBalance() {
    yield takeEvery(ChangeUserBalanceType.CHANGE_USER_BALANCE, handleChangeUserBalanceSaga)
}
