import {GetListWalletsForUserTypes} from './actionTypes';
import { IGetListWalletsForUser, IGetListWalletsForUserFetching, IGetListWalletsForUserSuccess, IGetListWalletsForUserError, IGetListWalletsForUserFilter, IGetListWalletsForUserFiterDebounce } from "./interfaces";


export const getListWalletsForUser = (payload:Object): IGetListWalletsForUser => ({
    type: GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER,
    payload
})

export const getListWalletsForUserFetching = (isFetching:boolean) : IGetListWalletsForUserFetching => ({
    type: GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER_FETCHING,
    isFetching
})

export const getListWalletsForUserSuccess = (data:any) : IGetListWalletsForUserSuccess => ({
   type: GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER_SUCCESS,
   data
})

export const getListWalletsForUserError = (errorMsg:any) : IGetListWalletsForUserError => ({
    type: GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER_FAILD,
    error:errorMsg
})

export const getListWalletsForUserFilter = (payload: any): IGetListWalletsForUserFilter => ({
    type: GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER_FILTER,
    payload
})

export const getListWalletsForUserFilterDebounce = (payload: any): IGetListWalletsForUserFiterDebounce => ({
    type: GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER_FILTER_DEBOUNCE,
    payload 
})
