import {put, takeEvery} from 'redux-saga/effects';
import {CreatePinType} from '../../actions/createPIN/actionTypes';
import {createPinIsFetching,createPinFaild,createPinSuccess} from '../../actions/createPIN/action';
import {handleCreatePin} from '../../../apis/service/user';
function* createPinSaga(action:any):Generator<any>{
  yield put(createPinIsFetching(true))
  const {payload} = action;
  try {
    const dataCreatePin: any = yield handleCreatePin(payload)  
    if (dataCreatePin) {
        yield put(createPinSuccess(dataCreatePin))
    }
  } catch (error) {
     yield put(createPinFaild(error)) 
  }
}
export function* watchCreatePIN(){
    yield takeEvery(CreatePinType.CREATE_PIN, createPinSaga)
}