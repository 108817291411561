import { GetFileByIdTypes } from './actionTypes';
import { IGetFileById, IGetFileByIdFetching, IGetFileByIdSuccess, IGetFileByIdError, IGetFileByIdFilter, IGetFileByIdFiterDebounce, IGetFileByIdClean } from "./interfaces";


export const getFileById = (payload: Object): IGetFileById => ({
    type: GetFileByIdTypes.GET_FILE_BY_ID,
    payload
})

export const getFileByIdFetching = (isFetching: boolean): IGetFileByIdFetching => ({
    type: GetFileByIdTypes.GET_FILE_BY_ID_FETCHING,
    isFetching
})

export const getFileByIdSuccess = (data: any): IGetFileByIdSuccess => ({
    type: GetFileByIdTypes.GET_FILE_BY_ID_SUCCESS,
    data
})

export const getFileByIdError = (errorMsg: any): IGetFileByIdError => ({
    type: GetFileByIdTypes.GET_FILE_BY_ID_FAILD,
    error: errorMsg
})

export const getFileByIdFilter = (payload: any): IGetFileByIdFilter => ({
    type: GetFileByIdTypes.GET_FILE_BY_ID_FILTER,
    payload
})

export const getFileByIdFilterDebounce = (payload: any): IGetFileByIdFiterDebounce => ({
    type: GetFileByIdTypes.GET_FILE_BY_ID_FILTER_DEBOUNCE,
    payload
})

export const getFileByIdClean = (): IGetFileByIdClean => ({
    type: GetFileByIdTypes.GET_FILE_BY_ID_CLEAN,
})
