import {ExportFileType} from './actionTypes';
import {
    IExportFileIsFetching,
    IExportFileSuccess,
    IExportFileFaild,
    IExportFile
} from './interfaces';

export const exportFileAtFetching = (isFetching:boolean) : IExportFileIsFetching  => ({
    type: ExportFileType.EXPORT_FILE_FETCHING,
    isFetching
})

export const exportFile = (payload:Object, xlsx:boolean) : IExportFile => ({
    type: ExportFileType.EXPORT_FILE,
    payload,
    xlsx
})

export const exportFileSuccess = (data:any) : IExportFileSuccess => ({
    type: ExportFileType.EXPORT_FILE_SUCCESS,
    data
})

export const exportFileFaild = (error:any) : IExportFileFaild => ({
    type: ExportFileType.EXPORT_FILE_FAILD,
    error
})
