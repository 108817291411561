export interface WalletRefill  {
    data:any,
    errors:any,
    success:any
}

export interface WalletRefillState {
    data: WalletRefill,
    isFetching:boolean
}

export const initialWalletRefillState: WalletRefillState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}