interface IEditOfferBuyOrSell {
    data:any;
    status:number;
    message:string;
  }
  
  export interface IEditOfferBuyOrSellState {
      data:IEditOfferBuyOrSell;
      isFetching:boolean
  }
  
  export const initialEditOfferBuyOrSellState: IEditOfferBuyOrSellState = {
      data:{
          data:false,
          status:0,
          message:''
      },
      isFetching:false,
}