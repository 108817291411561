import { AnyAction, Reducer } from 'redux';
import { IUserDetailState, initialUserDetailState } from './state';
import { GetUserDetailType } from '../../actions/getDataUserDetail';

export const GetUserDetailReducer: Reducer = (
  state: IUserDetailState = initialUserDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetUserDetailType.GET_USER_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetUserDetailType.GET_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case GetUserDetailType.GET_USER_DETAIL_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
