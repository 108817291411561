import { LanguageType } from './actionTypes';
import { IFetchingLanguages, ILanguages, ILanguagesError, ILanguagesSuccess } from './interfaces';

export const languagesIsFetching = (isFetching:boolean) : IFetchingLanguages => ({
    type: LanguageType.LOAD_LANGUAGE_FETCHING,
    isFetching
})

export const languages = (payload: number) : ILanguages => ({
    type: LanguageType.LOAD_LANGUAGE,
    payload
})

export const languagesSuccess = (data:any) : ILanguagesSuccess => ({
    type: LanguageType.LOAD_LANGUAGE_SUCCESS,
    data
})

export const languagesFaild = (error:any) : ILanguagesError => ({
    type: LanguageType.LOAD_LANGUAGE_ERROR,
    error
})