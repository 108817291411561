import { DeleteAnnouncementsCategoryType } from "./actionTypes"
import { IFetchingDeleteAnnouncementsCategory, IDeleteAnnouncementsCategory, IDeleteAnnouncementsCategorySuccess, IDeleteAnnouncementsCategoryError } from './interfaces';

export const deleteAnnouncementsCategoryIsFetching = (isFetching: boolean) : IFetchingDeleteAnnouncementsCategory => ({
    type: DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY_FETCHING,
    isFetching
})

export const deleteAnnouncementsCategory = (payload?: any) : IDeleteAnnouncementsCategory => ({
    type: DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY,
    payload
})

export const deleteAnnouncementsCategorySuccess = (data: any) : IDeleteAnnouncementsCategorySuccess => ({
    type: DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY_SUCCESS,
    data
})

export const deleteAnnouncementsCategoryFaild = (error: any) : IDeleteAnnouncementsCategoryError => ({
    type: DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY_ERROR,
    error
})