import { put, takeEvery } from 'redux-saga/effects';
import { getKycListHistory } from 'src/apis/service/Kyc';
import { 
  GetKycListHistoryTypes,
  getKycListHistorySuccess,
  getKycListHistoryFaild,
  getKycListHistoryAtFetching
} from 'src/store/actions';

function* getKycListHistorySaga(action:any):Generator<any> {
    yield put(getKycListHistoryAtFetching(true))
    const {payload} = action;
    try {
      const dataKycListHistory:any = yield getKycListHistory(payload)
        yield put(getKycListHistorySuccess(dataKycListHistory))
    } catch (error) {
        yield put(getKycListHistoryFaild(error))
    }
}

export function* watchGetListKycListHistory() {
   yield takeEvery(GetKycListHistoryTypes.GET_KYC_LIST_HISTORY, getKycListHistorySaga)
}