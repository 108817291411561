import { AnyAction, Reducer } from 'redux';
import { GetP2PExchangeOfferDetailType } from 'src/store/actions/P2PExchangeOfferDetail';
import { IListP2PExchangeOfferDetailState, initialListP2PExchangeOfferDetailState } from './state';


export const GetListP2PExchangeOfferDetailReducer: Reducer = (
  state: IListP2PExchangeOfferDetailState = initialListP2PExchangeOfferDetailState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL_ERROR : {
      return {
        ...state,
        error: action?.error,
        isFetching: false,
      };
    }
    case GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL_SUCCESS : {
      return {
          ...state,
          data: action?.data?.data,
          isFetching: false,
      }
    }
    default:
      return state;
  }
};