import { CreateAnnouncementsCategoryType } from './actionTypes';
import { IFetchingCreateAnnouncementsCategory, ICreateAnnouncementsCategory, ICreateAnnouncementsCategorySuccess, ICreateAnnouncementsCategoryError, payload } from './interfaces';

export const CreateAnnouncementsCategoryIsFetching = (isFetching:boolean) : IFetchingCreateAnnouncementsCategory => ({
    type: CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY_FETCHING,
    isFetching
})

export const CreateAnnouncementsCategory = (payload: payload) : ICreateAnnouncementsCategory => ({
    type: CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY,
    payload
})

export const CreateAnnouncementsCategorySuccess = (data:any) : ICreateAnnouncementsCategorySuccess => ({
    type: CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY_SUCCESS,
    data
})

export const CreateAnnouncementsCategoryFaild = (error:any) : ICreateAnnouncementsCategoryError => ({
    type: CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY_ERROR,
    error
})