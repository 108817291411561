import {SetKYCPriorityAllUserTypes} from './actionTypes';
import { ISetKYCPriorityAllUser, ISetKYCPriorityAllUserFetching, ISetKYCPriorityAllUserSuccess, ISetKYCPriorityAllUserError, ISetKYCPriorityAllUserFilter, ISetKYCPriorityAllUserFiterDebounce } from "./interfaces";


export const setKYCPriorityAllUser = (payload:Object): ISetKYCPriorityAllUser => ({
    type: SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER,
    payload
})

export const setKYCPriorityAllUserFetching = (isFetching:boolean) : ISetKYCPriorityAllUserFetching => ({
    type: SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER_FETCHING,
    isFetching
})

export const setKYCPriorityAllUserSuccess = (data:any) : ISetKYCPriorityAllUserSuccess => ({
   type: SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER_SUCCESS,
   data
})

export const setKYCPriorityAllUserError = (errorMsg:any) : ISetKYCPriorityAllUserError => ({
    type: SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER_FAILD,
    error:errorMsg
})

export const setKYCPriorityAllUserFilter = (payload: any): ISetKYCPriorityAllUserFilter => ({
    type: SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER_FILTER,
    payload
})

export const setKYCPriorityAllUserFilterDebounce = (payload: any): ISetKYCPriorityAllUserFiterDebounce => ({
    type: SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER_FILTER_DEBOUNCE,
    payload 
})
