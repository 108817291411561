interface RemoveAdminBO {
  data: any;
  status: number;
  message: string;
}

export interface RemoveAdminBOState {
  data: RemoveAdminBO;
  isFetching: boolean;
  countSuccess: number;
}

export const initialRemoveAdminBOState: RemoveAdminBOState = {
  data: {
    data: false,
    message: '',
    status: 0,
  },
  isFetching: false,
  countSuccess: 0,
};
