import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleUpdateScoreKPI } from 'src/apis/service/ScorePeriodConfig';
import { UpdateScoreKPITypes } from '../../actions/updateScoreKPI';
import {
  updateScoreKPIError,
  updateScoreKPIFetching,
  updateScoreKPIFilter,
  updateScoreKPISuccess
} from '../../actions/updateScoreKPI/action';
import { toastError, toastSuccess } from './../../../helpers/toastHelper';

function* updateScoreKPISaga(action: any): Generator<any> {


  yield put(updateScoreKPIFetching(true));
  const { payload, history } = action;
  try {
    const data: any = yield handleUpdateScoreKPI(payload);
    if (data && data?.status === 200) {

      yield put(updateScoreKPISuccess(true));
      toastSuccess('bo_update_score_kpi_config_success');
    } else {
      toastError('bo_you_can_t_update_score_kpi_config');
    }
    // history.push('/dashboard/settings/user-score-setting');
  } catch (error) {
    yield put(updateScoreKPIError(error));
    toastError('bo_you_can_t_update_score_kpi_config');
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(updateScoreKPIFilter(action.payload));
}
export function* watchUpdateScoreKPI() {
  yield takeEvery(UpdateScoreKPITypes.UPDATE_SCORE_KPI, updateScoreKPISaga);
  yield debounce(
    500,
    UpdateScoreKPITypes.UPDATE_SCORE_KPI_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
