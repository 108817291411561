interface ISendNoteP2PExchangeOffer {
  status: number,
  data: any,
  message: string
}

export interface ISendNoteP2PExchangeOfferState {
  isFetching: boolean,
  data: ISendNoteP2PExchangeOffer,
  successCount: number
}

export const initialSendNoteP2PExchangeOffer: ISendNoteP2PExchangeOfferState = {
  data: {
    status: 0,
    data: false,
    message: ''
  },
  isFetching: false,
  successCount: 0
}