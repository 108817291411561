import {WalletInputTypes} from './actionTypes';
import { IWalletInput, IWalletInputFetching, IWalletInputSuccess, IWalletInputError, IWalletInputFilter, IWalletInputFiterDebounce } from "./interfaces";


export const walletInput = (payload:any): IWalletInput => ({
    type: WalletInputTypes.WALLET_INPUT,
    payload
})

export const walletInputFetching = (isFetching:boolean) : IWalletInputFetching => ({
    type: WalletInputTypes.WALLET_INPUT_FETCHING,
    isFetching
})

export const walletInputSuccess = (data:any) : IWalletInputSuccess => ({
   type: WalletInputTypes.WALLET_INPUT_SUCCESS,
   data
})

export const walletInputError = (errorMsg:any) : IWalletInputError => ({
    type: WalletInputTypes.WALLET_INPUT_FAILD,
    error:errorMsg
})

export const walletInputFilter = (payload: any): IWalletInputFilter => ({
    type: WalletInputTypes.WALLET_INPUT_FILTER,
    payload
})

export const walletInputFilterDebounce = (payload: any): IWalletInputFiterDebounce => ({
    type: WalletInputTypes.WALLET_INPUT_FILTER_DEBOUNCE,
    payload 
})
