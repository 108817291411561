import { AnyAction, Reducer } from 'redux';
import { GetKycListHistoryTypes } from 'src/store/actions';
import { IListKycListHistoryState, initialListKycListHistoryState } from './state';


export const GetListKycListHistoryReducer: Reducer = (
  state: IListKycListHistoryState = initialListKycListHistoryState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetKycListHistoryTypes.GET_KYC_LIST_HISTORY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetKycListHistoryTypes.GET_KYC_LIST_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetKycListHistoryTypes.GET_KYC_LIST_HISTORY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case GetKycListHistoryTypes.GET_LIST_FILTER_LIST_HISTORY: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default:
      return state;
  }
};