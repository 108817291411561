export interface DeleteAnnouncementsCategory  {
    data: any,
    errors: any,
    success: any
}

export interface DeleteAnnouncementsCategoryState {
    data: DeleteAnnouncementsCategory,
    isFetching: boolean
}

export const initialDeleteAnnouncementsCategoryState: DeleteAnnouncementsCategoryState = {
    data: {
        data: {},
        errors: '',
        success: false
    },
    isFetching: false
}