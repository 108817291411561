import { AnyAction, Reducer } from 'redux';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { EditUserPriorityType } from 'src/store/actions/EditUserPriority';
import { initialEditUserPriorityState, EditUserPriorityState } from './state';

export const EditUserPriorityReducer: Reducer = (
  state: EditUserPriorityState = initialEditUserPriorityState,
  action: AnyAction
) => {
  switch (action.type) {
    case EditUserPriorityType.EDIT_USER_PRIORITY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case EditUserPriorityType.EDIT_USER_PRIORITY_SUCCESS: {
      
      toastSuccess('bo_priority_updated');
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case EditUserPriorityType.EDIT_USER_PRIORITY_ERROR: {
      
      toastError('bo_priority_update_fail');
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
