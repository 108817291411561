import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetOverviewTransactionCrypto } from 'src/apis/service/CryptoWallet';
import { getCryptoWalletGetOverviewTransactionCryptoFaild, getCryptoWalletGetOverviewTransactionCryptoIsFetching, getCryptoWalletGetOverviewTransactionCryptoSuccess, GetCryptoWalletGetOverviewTransactionCryptoType } from 'src/store/actions';

function* getCryptoWalletOverviewTransactionCryptoSaga(action:any):Generator<any> {
    yield put(getCryptoWalletGetOverviewTransactionCryptoIsFetching(true))
    const {payload} = action;
    try {
      const data:any = yield call(handleGetOverviewTransactionCrypto, payload)
      if(data && data?.status === 200) {
        yield put(getCryptoWalletGetOverviewTransactionCryptoSuccess(data))
      } else {
        yield put(getCryptoWalletGetOverviewTransactionCryptoFaild('error'))
      }
    } catch (error) {
        yield put(getCryptoWalletGetOverviewTransactionCryptoFaild(error))
    }
}

export function* watchGetListCryptoWalletOverviewTransactionCrypto() {
   yield takeEvery(GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO, getCryptoWalletOverviewTransactionCryptoSaga)
}