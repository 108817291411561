import { GetUserBalanceHistoryType } from './actionTypes';
import { IFetchingGetUserBalanceHistory, IGetListFitertUserBalanceHistory, IGetUserBalanceHistory, IGetUserBalanceHistoryError, IGetUserBalanceHistorySuccess } from './interfaces';

export const getUserBalanceHistoryIsFetching = (isFetching:boolean) : IFetchingGetUserBalanceHistory => ({
    type: GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY_FETCHING,
    isFetching
})

export const getUserBalanceHistory = (payload: any) : IGetUserBalanceHistory => ({
    type: GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY,
    payload
})

export const getUserBalanceHistorySuccess = (data:any) : IGetUserBalanceHistorySuccess => ({
    type: GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY_SUCCESS,
    data
})

export const getUserBalanceHistoryFaild = (error:any) : IGetUserBalanceHistoryError => ({
    type: GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY_ERROR,
    error
})

export const setFiltertUserBalanceHistory = (payload: any): IGetListFitertUserBalanceHistory => ({
    type: GetUserBalanceHistoryType.GET_LIST_FILTER_USER_BALANCE_HISTORY,
    payload
})