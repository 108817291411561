export const LANGUAGE_TEXT = [
  {
    id: 1,
    name: 'Korea',
    value: 1,
    label: 'bo_korea',
    code: 'ko',
  },
  {
    id: 2,
    name: 'English',
    value: 2,
    label: 'bo_english',
    code: 'en',
  },
  // {
  //   id: 3,
  //   name: 'Spanish',
  //   value: 3,
  //   label: 'Spanish'
  // }
];

export const LANGUAGE_TO = [
  {
    id: 1,
    value: 1,
    label: 'bo_language_type_back_office',
  },
  {
    id: 2,
    value: 2,
    label: 'bo_language_type_mobile',
  },
];
