import { AnyAction, Reducer } from 'redux';
import { GetKycListByStageTypes } from 'src/store/actions';
import { IListKycListByStageState, initialListKycListByStageState } from './state';


export const GetListKycListByStageReducer: Reducer = (
  state: IListKycListByStageState = initialListKycListByStageState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case GetKycListByStageTypes.GET_LIST_FILTER_KYC_LIST_BY_STAGE: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};