import { put, takeLatest } from 'redux-saga/effects';
import { getPriority } from 'src/apis/service/Priority';
import { 
  getPrioriryIsFetching,
  getPrioritySuccess,
  getPriorityFaild,
  PriorityType
 } from 'src/store/actions/priority/getPriority';

function* getPriorityForUser(action: any):Generator<any>{
   yield put(getPrioriryIsFetching(true));
    try {
      const dataPriority:any = yield getPriority() 
      if (dataPriority && dataPriority?.status === 200) {
        yield put(getPrioritySuccess(dataPriority))
      } else {
        yield put(getPriorityFaild('error'))
      } 
    } catch (error) {
        yield put(getPriorityFaild(error))
    }
}

export function* watchGetPriority () {
  yield takeLatest(PriorityType.GET_PRIORITY, getPriorityForUser)
}