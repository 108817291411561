interface ICancelP2PExchangeOffer {
    status:number,
    message:string,
    data:any
}

export interface ICancelP2PExchangeOfferState {
    data:ICancelP2PExchangeOffer,
    isFetching:boolean
}

export const initialCancelP2PExchangeOfferState  : ICancelP2PExchangeOfferState =  {
    data: {
        data: false,
        message:'',
        status: 0
    },
    isFetching: false
}