import { AnyAction, Reducer } from 'redux';
import { initialPriorityState, PriorityState } from './state';
import { PriorityType } from 'src/store/actions/priority/getPriority';

export const PriorityReducer: Reducer = (
  state: PriorityState = initialPriorityState,
  action: AnyAction
) => {
  switch (action.type) {
    case PriorityType.GET_PRIORITY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case PriorityType.GET_PRIORITY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case PriorityType.GET_PRIORITY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
