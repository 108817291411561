import { ListParams } from "src/models/common";

interface IDataGetHistoryDeactivateUser {
  data:any[];
  error:any;
  success:boolean;
}

export interface IGetHistoryDeactivateUserState {
    data:IDataGetHistoryDeactivateUser;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetHistoryDeactivateUserState: IGetHistoryDeactivateUserState = {
    data:{
        data:[],
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


