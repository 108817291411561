import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeOffersAgent } from 'src/apis/service/P2PExchange';
import { getP2PExchangeOffersAgentFaild, getP2PExchangeOffersAgentIsFetching, getP2PExchangeOffersAgentSuccess, GetP2PExchangeOffersAgentType, setFilterP2PExchangeOffersAgent } from 'src/store/actions/P2PExchangeOffersAgent';

function* getListP2PExchangeOffersAgentSaga(action: any): Generator<any> {
    yield put(getP2PExchangeOffersAgentIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeOffersAgent, payload)  
      if(data && data?.status === 200){
        yield put(getP2PExchangeOffersAgentSuccess(data));
      } else {
        yield put(getP2PExchangeOffersAgentFaild('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeOffersAgentFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterP2PExchangeOffersAgent(action.payload));
}

export function* watchGetListP2PExchangeOffersAgent() {
  yield takeEvery(GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT , getListP2PExchangeOffersAgentSaga)
  yield debounce(500, GetP2PExchangeOffersAgentType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_OFFERS_AGENT, handleSearchDebounce)
}