import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApi = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});

const requestCryptoApi = new Request({
  url: process.env.REACT_APP_BASE_ADMIN_CRYPTO_URL,
});

interface WalletRefillPayload {
  amount: number;
  currencyId: number;
  notes: string;
}
const handleWalletRefil = async (data: WalletRefillPayload) => {
  const url = '/Admin/AdminRefill';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetHistoryWalletBalance = async (payload: any) => {
  const url = '/Wallet/WalletBalanceHistory';
  try {
    return await requestApi.post(url, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListSuperWalletBO = async (data: any) => {
  const url = '/Wallet/GetAllWalletsBO';
  try {
    return await requestApi.post(url, data);
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListWalletBO = async (data: any) => {
  const url = '/Wallet/GetAllAdminWalletsBO';
  try {
    return await requestApi.post(url, data);
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateWalletBO = async (data: any) => {
  const url = `/Wallet/CreateWallet?currencyId=${data.currencyId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteWalletBO = async (data: any) => {
  const url = `/Wallet/DeleteWalletById?walletId=${data.walletId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetWalletsUser = async () => {
  const url = '/Wallet/GetAllWallets';
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetVenlyWalletsUser = async () => {
  const url = '/VenLy/GetWalletCrypto';
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetUserWallet = async () => {
  const url = '/user-wallets';
  try {
    return await requestCryptoApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetSystemWallet = async () => {
  const url = '/wallet';
  try {
    return await requestCryptoApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleWalletRefil,
  handleGetHistoryWalletBalance,
  handleGetListSuperWalletBO,
  handleDeleteWalletBO,
  handleCreateWalletBO,
  handleGetWalletsUser,
  handleGetVenlyWalletsUser,
  handleGetUserWallet,
  handleGetSystemWallet,
  handleGetListWalletBO,
};
