import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";

interface IDataListLanguageText {
  data:{
    items:any[];
    totalCount:number;
  };
  error:any;
  success:boolean;
}

export interface IListLanguageTextState {
    data:IDataListLanguageText;
    isFetching:boolean,
    filter: any,
    pagination: any,
}

export const initialListLanguageTextState: IListLanguageTextState = {
    data:{
        data:{
            items:[],
            totalCount:0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        searchText:''
    },
    pagination: {
        totalCount: 0,
    }
}

