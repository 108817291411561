import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetUserStatusHistoryDeactivate } from 'src/apis/service/user';
import { GetHistoryDeactivateUserTypes } from '../../actions/getHistoryDeactivateUser';
import { getHistoryDeactivateUserError, getHistoryDeactivateUserFetching, getHistoryDeactivateUserFilter, getHistoryDeactivateUserSuccess } from '../../actions/getHistoryDeactivateUser/action';

function* getHistoryDeactivateUserSaga(action: any): Generator<any> {
  yield put(getHistoryDeactivateUserFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleGetUserStatusHistoryDeactivate(payload)
    if (data && data?.status === 200) {
      yield put(getHistoryDeactivateUserSuccess(data))
    } else {
      yield put(getHistoryDeactivateUserError('error'))
    }
  } catch (error) {
    yield put(getHistoryDeactivateUserError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getHistoryDeactivateUserFilter(action.payload));
}
export function* watchGetHistoryDeactivateUser() {
  yield takeEvery(GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER, getHistoryDeactivateUserSaga)
  yield debounce(500, GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER_FILTER_DEBOUNCE, handleSearchDebounce)
}
