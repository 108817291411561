import * as Sentry from '@sentry/react';
import Request from '../../config';
import { ListParams } from 'src/models/common';

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_API });
const requestApiTransfer = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});
interface PayloadEdit {
  description: string;
  id: number;
  languageId: number;
  shortDescription: string;
  title: string;
}
interface PayloadCreate {
  FileToUpload: BinaryData;
  title: string;
  shortDescription: string;
  description: number;
  languageId: number;
}

interface FeatureNotification {
  searchText?: string;
  languageId: null | string;
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  orderDirection: string;
}

interface DeleteFeatureNotification {
  curUserId: string;
  id: string;
}

const handleGetListNotification = async (data: FeatureNotification) => {
  const url = '/FeatureNotification/search';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleEditNotification = async (data: PayloadEdit) => {
  const url = '/FeatureNotification/update';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateNotification = async (data: PayloadCreate) => {
  const url = '/FeatureNotification/create';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteNotification = async (data: DeleteFeatureNotification) => {
  const url = `/FeatureNotification/Delete?curUserId=${data.curUserId}&id=${data.id}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateImage = async (data: Object) => {
  const url = '/Gallery/upload';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListNotificationFirebase = async (data: ListParams) => {
  const url = '/Notification/Search';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const updateTokenFirebase = async (token: string) => {
  const url = '/FireBase/Update';
  const data = {
    fireBaseToken: token,
    isMobile: false,
  };
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleSetAllAsReadNotification = async () => {
  const url = '/Notification/SetAllAsRead';
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleSetAsReadNotification = async (notificationIds: number[]) => {
  const url = '/Notification/SetAsRead';
  try {
    return await requestApiTransfer.post(url, { notificationIds });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetUnreadCountNotification = async () => {
  const url = '/Notification/GetCountUnread';
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleResendNotification = async (data: any) => {
  const url = '/FeatureNotification/SendToUser';
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetFileById = async (data: Object) => {
  const url = `/Gallery/GetFileById?fileId=${data}`;
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleGetListNotification,
  handleEditNotification,
  handleCreateNotification,
  handleDeleteNotification,
  handleUpdateImage,
  handleGetListNotificationFirebase,
  updateTokenFirebase,
  handleSetAllAsReadNotification,
  handleSetAsReadNotification,
  handleResendNotification,
  handleGetFileById,
  handleGetUnreadCountNotification,
};
