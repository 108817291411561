import {AgentGetDirectDetailTypes} from './actionTypes';
import { IAgentGetDirectDetail, IAgentGetDirectDetailFetching, IAgentGetDirectDetailSuccess, IAgentGetDirectDetailError, IAgentGetDirectDetailFilter, IAgentGetDirectDetailFiterDebounce } from "./interfaces";


export const agentGetDirectDetail = (payload:Object): IAgentGetDirectDetail => ({
    type: AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL,
    payload
})

export const agentGetDirectDetailFetching = (isFetching:boolean) : IAgentGetDirectDetailFetching => ({
    type: AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_FETCHING,
    isFetching
})

export const agentGetDirectDetailSuccess = (data:any) : IAgentGetDirectDetailSuccess => ({
   type: AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_SUCCESS,
   data
})

export const agentGetDirectDetailError = (errorMsg:any) : IAgentGetDirectDetailError => ({
    type: AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_FAILD,
    error:errorMsg
})

export const agentGetDirectDetailFilter = (payload: any): IAgentGetDirectDetailFilter => ({
    type: AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_FILTER,
    payload
})

export const agentGetDirectDetailFilterDebounce = (payload: any): IAgentGetDirectDetailFiterDebounce => ({
    type: AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_FILTER_DEBOUNCE,
    payload 
})
