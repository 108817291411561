
import { put, takeEvery } from 'redux-saga/effects';
import { deleteAdminBO } from 'src/apis/service/Admin-management';
import { toastError } from 'src/helpers/toastHelper';
import {
  DeleteAdminBOType,
  deleteAdminBOAtFetching,
  deleteAdminBOFaild,
  deleteAdminBOSuccess
} from 'src/store/actions/admin-managements/removeAdminBO';

function* deleteAdminBOSaga(action: any): Generator<any> {
  yield put(deleteAdminBOAtFetching(true))
  const { payload } = action
  try {
    const response: any = yield deleteAdminBO(payload)
    if (response.status === 200) {
      //  const filter : any = yield select((state:IApplicationState) => state.listAllAdmin.filter)
      yield put(deleteAdminBOSuccess());
      // yield put(getAdminBO(filter))
    }
  } catch (error) {
    toastError('bo_delete_admin_failed')
    yield put(deleteAdminBOFaild(error))
  }
}

export function* watchDeleteAdminBO() {
  yield takeEvery(DeleteAdminBOType.DELETE_ADMIN_BO, deleteAdminBOSaga)
}