import { AnyAction, Reducer } from 'redux';
import { GetP2PExchangeTransactionsType } from 'src/store/actions/P2PExchangeTransactions';
import { IListP2PExchangeTransactionsState, initialListP2PExchangeTransactionsState } from './state';


export const GetListP2PExchangeTransactionsReducer: Reducer = (
  state: IListP2PExchangeTransactionsState = initialListP2PExchangeTransactionsState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS_ERROR: {
      return {
        ...state,
        error: action?.error,
        isFetching: false,
      };
    }
    case GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        data: action?.data?.data?.items ? action?.data?.data?.items : [],
        isFetching: false,
        pagination: action?.data?.data?.totalCount
      }
    }
    case GetP2PExchangeTransactionsType.SET_LIST_FILTER_P2P_EXCHANGE_TRANSACTIONS: {
      return {
        ...state,
        filter: action.payload
      }
    }
    default:
      return state;
  }
};