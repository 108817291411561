import { GetAgencyStatusType } from './actionTypes';
import { IFetchingGetAgencyStatus, IGetAgencyStatus, IGetAgencyStatusError, IGetAgencyStatusSuccess } from './interfaces';

export const getAgencyStatusIsFetching = (isFetching:boolean) : IFetchingGetAgencyStatus => ({
    type: GetAgencyStatusType.GET_AGENCY_STATUS_FETCHING,
    isFetching
})

export const getAgencyStatus = (payload: object) : IGetAgencyStatus => ({
    type: GetAgencyStatusType.GET_AGENCY_STATUS,
    payload
})

export const getAgencyStatusSuccess = (data:any) : IGetAgencyStatusSuccess => ({
    type: GetAgencyStatusType.GET_AGENCY_STATUS_SUCCESS,
    data
})

export const getAgencyStatusFaild = (error:any) : IGetAgencyStatusError => ({
    type: GetAgencyStatusType.GET_AGENCY_STATUS_ERROR,
    error
})