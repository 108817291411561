import { all, call, put, takeEvery } from 'redux-saga/effects';
import { handleGetSendCryptoHistory, handleGetStatistical } from 'src/apis/service/CryptoWallet';
import { getCryptoWalletSendHistoryFaild, getCryptoWalletSendHistoryIsFetching, getCryptoWalletSendHistorySuccess, GetCryptoWalletSendHistoryType, getCryptoWalletSendStatisticalSuccess } from 'src/store/actions';

function* getListCryptoWalletSendHistorySaga(action: any): Generator<any>  {
  const data: any = yield call(handleGetSendCryptoHistory, action)      
  yield put(getCryptoWalletSendHistorySuccess(data));
}

function* getListStatisticalSendSaga(): Generator<any>  {
  const data: any = yield call(handleGetStatistical, 1)      
  const strimData = data?.data.filter((_statistical: any, index: number) => index < 3)
  const sumPercent = strimData.reduce((acc: any, o: any) => acc + parseFloat(o.percent), 0);
  // parseFloat(strimData[0]?.percent).toFixed(2)
  strimData.push({symbol: "bo_others_label", percent: (100 - sumPercent).toFixed(2)})
  const template = [
    {
        "id": 0,
        "color": "#3f51b5"
    },
    {
        "id": 1,
        "color": "#7986cb"
    },
    {
        "id": 2,
        "color": "#c5cae9"
    },
    {
      "id": 3,
      "color": "#c5cae9"
    }
  ]
  strimData?.map((item: any, index: number)=> Object.assign(item, template.find(itemTemp => itemTemp.id===index)))
  yield put(getCryptoWalletSendStatisticalSuccess(strimData));
}

function* fetchListCryptoReceiveWallet(action: any) {
  const { payload } = action;
  yield put(getCryptoWalletSendHistoryIsFetching(true))
  try {
    yield all([
      call(() => getListCryptoWalletSendHistorySaga(payload)),
      call(getListStatisticalSendSaga),
    ]);
    yield put(getCryptoWalletSendHistorySuccess('success'));
  } catch (error) {
    yield put(getCryptoWalletSendHistoryFaild('error'))
  }
}

export function* watchGetListCryptoWalletSendHistory() {
   yield takeEvery(GetCryptoWalletSendHistoryType.GET_CRYPTO_WALLET_SEND_HISTORY , fetchListCryptoReceiveWallet)
}