import { RemoveUserBankAccType } from './actionTypes';
import { IFetchingRemoveUserBankAcc,IRemoveBankAccError,IRemoveUserBankAcc,IRemoveUserBankAccSuccess } from './interfaces';


export const removeUserBankAccIsFetching = (isFetching:boolean) : IFetchingRemoveUserBankAcc => ({
    type:RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT_FETCHING,
    isFetching
})

export const removeUserBankAcc = (payload?:number) : IRemoveUserBankAcc => ({
    type:RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT,
    payload
})

export const removeUserBankAccSuccess = (data:any) : IRemoveUserBankAccSuccess => ({
    type:RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT_SUCCESS,
    data
})

export const removeUserBankAccFaild = (error:any) : IRemoveBankAccError => ({
    type:RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT_ERROR,
    error
})