import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletTransactionsTypes } from 'src/store/actions';
import { ICryptoWalletTransactionsState, initialCryptoWalletTransactionsState } from './state';

export const cryptoWalletTransactionsReducer: Reducer = (
    state: ICryptoWalletTransactionsState = initialCryptoWalletTransactionsState,
    action: AnyAction,
) => {
    switch (action.type) {
        case GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS_IS_FETCHING : {
            return {
                ...state,
                isFetching: !!action?.isFetching,
            };
        }
        case GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS_ERROR : {
            return {
                ...state,
                error: action.error,
                isFetching: false,
            };
        }
        case GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS_SUCCESS : {
            return {
                ...state,
                requestData: action?.data,
                isFetching: false,
            }
        }

        case GetCryptoWalletTransactionsTypes.SET_CRYPTO_WALLET_TRANSACTIONS_FILTER : {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.action
                }
            }
        }
        default:
            return state;
    }
};
