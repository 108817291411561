import {AgentBuySearchTypes} from './actionTypes';
import { IAgentBuySearch, IAgentBuySearchFetching, IAgentBuySearchSuccess, IAgentBuySearchError, IAgentBuySearchFilter, IAgentBuySearchFiterDebounce } from "./interfaces";


export const agentBuySearch = (payload:Object,getDataAfterSuccess:any): IAgentBuySearch => ({
    type: AgentBuySearchTypes.AGENT_BUY_SEARCH,
    payload,
    getDataAfterSuccess
})

export const agentBuySearchFetching = (isFetching:boolean) : IAgentBuySearchFetching => ({
    type: AgentBuySearchTypes.AGENT_BUY_SEARCH_FETCHING,
    isFetching
})

export const agentBuySearchSuccess = (data:any) : IAgentBuySearchSuccess => ({
   type: AgentBuySearchTypes.AGENT_BUY_SEARCH_SUCCESS,
   data
})

export const agentBuySearchError = (errorMsg:any) : IAgentBuySearchError => ({
    type: AgentBuySearchTypes.AGENT_BUY_SEARCH_FAILD,
    error:errorMsg
})

export const agentBuySearchFilter = (payload: any): IAgentBuySearchFilter => ({
    type: AgentBuySearchTypes.AGENT_BUY_SEARCH_FILTER,
    payload
})

export const agentBuySearchFilterDebounce = (payload: any): IAgentBuySearchFiterDebounce => ({
    type: AgentBuySearchTypes.AGENT_BUY_SEARCH_FILTER_DEBOUNCE,
    payload 
})
