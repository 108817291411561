import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetKYCStatusTypes } from '../../actions/getKYCStatus';
import {
  getKYCStatusFetching,
  getKYCStatusSuccess,
  getKYCStatusError,
  getKYCStatusFilter,
} from '../../actions/getKYCStatus/action';
import { handleGetKYCStatus } from '../../../apis/service/user';
import { KYC_STAGE_STATUS } from 'src/contants';
import { getKYCFuncByIdFetch } from 'src/store/actions/getKYCFunctionById';

function* getKYCStatusSaga(action: any): Generator<any> {
  yield put(getKYCStatusFetching(true));

  try {
    const data: any = yield handleGetKYCStatus();
    if (data && data?.status === 200) {
      yield put(getKYCStatusSuccess(data));

      if (data?.data?.kycStage === KYC_STAGE_STATUS.LIMITED_KYC)
        yield put(getKYCFuncByIdFetch());
    } else {
      yield put(getKYCStatusError('error'));
    }
  } catch (error) {
    yield put(getKYCStatusError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getKYCStatusFilter(action.payload));
}
export function* watchGetKYCStatus() {
  yield takeEvery(GetKYCStatusTypes.GETKYC_STATUS, getKYCStatusSaga);
  yield debounce(
    500,
    GetKYCStatusTypes.GETKYC_STATUS_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
