import { AnyAction, Reducer } from "redux";
import { IGetWalletDetailState, initialGetWalletDetailState } from "./state";
import { GetWalletDetailTypes } from "src/store/actions/getWalletDetail";

export const GetWalletDetailReducer: Reducer = (
  state: IGetWalletDetailState = initialGetWalletDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetWalletDetailTypes.GET_WALLET_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetWalletDetailTypes.GET_WALLET_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.data.data.items,
        totalValueUSD: action.data.data.totalValueUSD,
        totalValueKRW: action.data.data.totalValueKRW,
        totalTransaction: action.data.data.totalCount,
        isFetching: false,
      };
    }
    case GetWalletDetailTypes.GET_WALLET_DETAIL_FAILD: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case GetWalletDetailTypes.GET_WALLET_DETAIL_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
