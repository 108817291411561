import { AnyAction, Reducer } from 'redux';
import { IListLanguageTextState, initialListLanguageTextState } from './state';
import { GetListLanguageTextTypes } from '../../actions/getListLanguageText';

export const GetListLanguageTextReducer: Reducer = (
  state: IListLanguageTextState = initialListLanguageTextState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListLanguageTextTypes.GET_LIST_FILTER_LANGUAGE_TEXT: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
