import { AnyAction, Reducer } from 'redux';
import { IGetCryptoTransactionHistoryState, initialGetCryptoTransactionHistoryState } from './state';
import { GetCryptoTransactionHistoryTypes } from 'src/store/actions/getCryptoTransactionHistory';

export const GetCryptoTransactionHistoryReducer: Reducer = (
  state: IGetCryptoTransactionHistoryState = initialGetCryptoTransactionHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
