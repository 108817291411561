export interface ITransaction {
  id: number;
  fromCustodialWallet: number;
  toCustodialWallet: number;
  type: number;
  status: number;
  amount: string;
  from: string;
  to: string;
  network: number;
  transactionCurrency: string;
  txnHash: string;
  gasFee: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  currency: string;
  commissionFee: string;
  withdrawAddress?:string;
}

export interface IGetWalletDetailState {
  data: ITransaction[];
  isFetching: boolean;
  totalValueUSD: number;
  totalValueKRW: number;
  totalTransaction: number;
}

export const initialGetWalletDetailState: IGetWalletDetailState = {
  data: [],
  isFetching: false,
  totalValueUSD: 0,
  totalValueKRW: 0,
  totalTransaction: 0,
};
