import {VerifyPINByAgentTypes} from './actionTypes';
import { IVerifyPINByAgent, IVerifyPINByAgentFetching, IVerifyPINByAgentSuccess, IVerifyPINByAgentError, IVerifyPINByAgentFilter, IVerifyPINByAgentFiterDebounce } from "./interfaces";


export const verifyPINByAgent = (payload:Object): IVerifyPINByAgent => ({
    type: VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT,
    payload
})

export const verifyPINByAgentFetching = (isFetching:boolean) : IVerifyPINByAgentFetching => ({
    type: VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT_FETCHING,
    isFetching
})

export const verifyPINByAgentSuccess = (data:any) : IVerifyPINByAgentSuccess => ({
   type: VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT_SUCCESS,
   data
})

export const verifyPINByAgentError = (errorMsg:any) : IVerifyPINByAgentError => ({
    type: VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT_FAILD,
    error:errorMsg
})

export const verifyPINByAgentFilter = (payload: any): IVerifyPINByAgentFilter => ({
    type: VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT_FILTER,
    payload
})

export const verifyPINByAgentFilterDebounce = (payload: any): IVerifyPINByAgentFiterDebounce => ({
    type: VerifyPINByAgentTypes.VERIFYPIN_BY_AGENT_FILTER_DEBOUNCE,
    payload 
})
