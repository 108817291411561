export interface SendNote {
    data: any,
    message: any,
    status: any
}

export interface SendNoteState {
    data: SendNote,
    isFetching: boolean,
    successCount: number
}

export const initialSendNoteState: SendNoteState = {
    data: {
        data: '',
        message: '',
        status: 0
    },
    isFetching: false,
    successCount: 0
}