import { put, takeEvery } from 'redux-saga/effects';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { handleCreateNotification } from '../../../apis/service/Notifications';
import { createNotificationFaild, createNotificationFetching, createNotificationSuccess } from '../../actions/createNotification/action';
import { CreateNotificationTypes } from '../../actions/createNotification/actionTypes';
import { getNotifications } from '../../actions/getNotifications/action';

function* createNotificationSaga(action:any):Generator<any>{
  yield put(createNotificationFetching(true))
  const { payload, history } = action;
  try {
    const dataCreateNotification: any = yield handleCreateNotification(payload)  
    if (dataCreateNotification.status === 200) {
        const filter = {
          pageNumber: 1,
          pageSize: 10,
          searchText: '',
          languageId: null,
        }
        yield put(createNotificationSuccess(dataCreateNotification))
        toastSuccess('bo_create_notification_success');
        // yield put(getNotifications(filter));
        // history.push('/dashboard/feature-notifications');
    } else {
      dataCreateNotification.errors.forEach((err:any) => {
        toastError(err)
      })
      // history.push('/dashboard/feature-notifications');
    }
  } catch (error) {
     yield put(createNotificationFaild(error)) 
  }
}
export function* watchCreateNotification(){
    yield takeEvery(CreateNotificationTypes.CREATE_NOTIFICATION, createNotificationSaga)
}