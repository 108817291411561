import { AnyAction, Reducer } from 'redux';
import { RemoveOfferBuyTransactionType } from 'src/store/actions';
import { initialRemoveOfferBuyTransactionState, RemoveOfferBuyTransactionState } from './state';

export const RemoveOfferBuyTransactionReducer: Reducer = (
  state: RemoveOfferBuyTransactionState = initialRemoveOfferBuyTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
