import {AgentCancelTransactionTypes} from './actionTypes';
import { IAgentCancelTransaction, IAgentCancelTransactionFetching, IAgentCancelTransactionSuccess, IAgentCancelTransactionError, IAgentCancelTransactionFilter, IAgentCancelTransactionFiterDebounce } from "./interfaces";


export const agentCancelTransaction = (payload:Object,getData:any): IAgentCancelTransaction => ({
    type: AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION,
    payload,
    getData
})

export const agentCancelTransactionFetching = (isFetching:boolean) : IAgentCancelTransactionFetching => ({
    type: AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION_FETCHING,
    isFetching
})

export const agentCancelTransactionSuccess = (data:any) : IAgentCancelTransactionSuccess => ({
   type: AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION_SUCCESS,
   data
})

export const agentCancelTransactionError = (errorMsg:any) : IAgentCancelTransactionError => ({
    type: AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION_FAILD,
    error:errorMsg
})

export const agentCancelTransactionFilter = (payload: any): IAgentCancelTransactionFilter => ({
    type: AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION_FILTER,
    payload
})

export const agentCancelTransactionFilterDebounce = (payload: any): IAgentCancelTransactionFiterDebounce => ({
    type: AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION_FILTER_DEBOUNCE,
    payload 
})
