import {GetAdminTransactionUserTypes} from './actionTypes';
import { IGetAdminTransactionUser, IGetAdminTransactionUserFetching, IGetAdminTransactionUserSuccess, IGetAdminTransactionUserError, IGetAdminTransactionUserFilter, IGetAdminTransactionUserFiterDebounce } from "./interfaces";


export const getAdminTransactionUser = (payload:Object): IGetAdminTransactionUser => ({
    type: GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER,
    payload
})

export const getAdminTransactionUserFetching = (isFetching:boolean) : IGetAdminTransactionUserFetching => ({
    type: GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_FETCHING,
    isFetching
})

export const getAdminTransactionUserSuccess = (data:any) : IGetAdminTransactionUserSuccess => ({
   type: GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_SUCCESS,
   data
})

export const getAdminTransactionUserError = (errorMsg:any) : IGetAdminTransactionUserError => ({
    type: GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_FAILD,
    error:errorMsg
})

export const getAdminTransactionUserFilter = (payload: any): IGetAdminTransactionUserFilter => ({
    type: GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_FILTER,
    payload
})

export const getAdminTransactionUserFilterDebounce = (payload: any): IGetAdminTransactionUserFiterDebounce => ({
    type: GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_FILTER_DEBOUNCE,
    payload 
})
