import { GetCryptoExchangeOffersListTypes } from './actionTypes';
import { IGetCryptoExchangeOffersList, IGetCryptoExchangeOffersListFetching, IGetCryptoExchangeOffersListSuccess, IGetCryptoExchangeOffersListError, IGetCryptoExchangeOffersListFilter, IGetCryptoExchangeOffersListFiterDebounce } from "./interfaces";


export const getCryptoExchangeOffersList = (payload: Object): IGetCryptoExchangeOffersList => ({
    type: GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST,
    payload
})

export const getCryptoExchangeOffersListFetching = (isFetching: boolean): IGetCryptoExchangeOffersListFetching => ({
    type: GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_FETCHING,
    isFetching
})

export const getCryptoExchangeOffersListSuccess = (data: any): IGetCryptoExchangeOffersListSuccess => ({
    type: GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS,
    data
})

export const getCryptoExchangeOffersListError = (errorMsg: any): IGetCryptoExchangeOffersListError => ({
    type: GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD,
    error: errorMsg
})

export const getCryptoExchangeOffersListFilter = (payload: any): IGetCryptoExchangeOffersListFilter => ({
    type: GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER,
    payload
})

export const getCryptoExchangeOffersListFilterDebounce = (payload: any): IGetCryptoExchangeOffersListFiterDebounce => ({
    type: GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE,
    payload
})
