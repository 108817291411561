import {ApplyKYCAddressTypes} from './actionTypes';
import { IApplyKYCAddress, IApplyKYCAddressFetching, IApplyKYCAddressSuccess, IApplyKYCAddressError, IApplyKYCAddressFilter, IApplyKYCAddressFiterDebounce } from "./interfaces";


export const applyKYCAddress = (payload:Object): IApplyKYCAddress => ({
    type: ApplyKYCAddressTypes.APPLYKYC_ADDRESS,
    payload
})

export const applyKYCAddressFetching = (isFetching:boolean) : IApplyKYCAddressFetching => ({
    type: ApplyKYCAddressTypes.APPLYKYC_ADDRESS_FETCHING,
    isFetching
})

export const applyKYCAddressSuccess = (data:any) : IApplyKYCAddressSuccess => ({
   type: ApplyKYCAddressTypes.APPLYKYC_ADDRESS_SUCCESS,
   data
})

export const applyKYCAddressError = (errorMsg:any) : IApplyKYCAddressError => ({
    type: ApplyKYCAddressTypes.APPLYKYC_ADDRESS_FAILD,
    error:errorMsg
})

export const applyKYCAddressFilter = (payload: any): IApplyKYCAddressFilter => ({
    type: ApplyKYCAddressTypes.APPLYKYC_ADDRESS_FILTER,
    payload
})

export const applyKYCAddressFilterDebounce = (payload: any): IApplyKYCAddressFiterDebounce => ({
    type: ApplyKYCAddressTypes.APPLYKYC_ADDRESS_FILTER_DEBOUNCE,
    payload 
})
