import { put, takeEvery, delay } from 'redux-saga/effects';
import { handleCreateWalletBO } from 'src/apis/service/Wallet';
import {
  createWalletAdminIsFetching,
  createWalletAdminFaild,
  createWalletAdminSuccess,
  CreateWalletAdminBOType
} from 'src/store/actions/WalletAdminBO/createWalletAdminBO';
import { toastError, toastErrorPutMess, toastSuccess } from 'src/helpers/toastHelper';
import { getUserInfor } from 'src/utils/storage';
import { getListWallet } from 'src/store/actions';

function* createWalletAdminBOSaga(action: any): Generator<any> {


  const { payload, history } = action;
  yield put(createWalletAdminIsFetching(true))
  try {
    const res: any = yield handleCreateWalletBO(payload)
    yield delay(1000)
    if (res.status === 200) {
      const userInfo = getUserInfor();
      const { userId } = userInfo
      yield createWalletAdminSuccess(res)
      // yield put(getListWallet(userId,true))
      // history.push('/dashboard/wallet-management')
      yield put(createWalletAdminSuccess(true))
      toastSuccess('bo_created_wallet_successfully')
    } else {
      toastError('bo_created_wallet_faild_because')
    }
  } catch (error: any) {
    yield put(createWalletAdminFaild(error.message))
  }
}

export function* watchCreateWalletAdminBO() {
  yield takeEvery(CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO, createWalletAdminBOSaga)
}