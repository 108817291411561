import { put, takeEvery } from 'redux-saga/effects';
import { TransactionManualPaymentType } from '../../actions/transactionManualPayment/actionTypes';
import {
  transactionManualPaymentIsFetching,
  transactionManualPaymentSuccess,
  transactionManualPaymentFaild,
} from '../../actions/transactionManualPayment/action';
import { handleTransactionManualPayment } from 'src/apis/service/P2PTransaction';
import { toastSuccess, toastError } from 'src/helpers/toastHelper';
import { getP2PBuyTransactions } from 'src/store/actions/P2PBuyTransactions/action';
import { getP2PSellTransactions } from 'src/store/actions/P2PSellTransactions/action';

function* transactionManualPaymentSaga(action: any): Generator<any> {
  yield put(transactionManualPaymentIsFetching(true));
  const { payload, transactionType, filters } = action;

  try {
    const data: any = yield handleTransactionManualPayment(payload);
    if (data?.status === 200) {
      if (transactionType === 'BUY') {
        // const params: any = yield select(
        //   (state: IApplicationState) => state.p2pBuyTransaction.filter
        // );
        yield put(transactionManualPaymentSuccess(data));
        yield put(getP2PBuyTransactions(filters));
      }
      if (transactionType === 'SELL') {
        // const params: any = yield select(
        //   (state: IApplicationState) => state.p2pSellTransaction.filter
        // );
        yield put(transactionManualPaymentSuccess(data));
        yield put(getP2PSellTransactions(filters));
      }
      toastSuccess('bo_manual_payment_success_done');
    } else {
      toastError('bo_manual_payment_fail');
      yield put(transactionManualPaymentFaild(data?.errors[0]));
    }
  } catch (error) {
    yield put(transactionManualPaymentFaild(error));
  }
}
export function* watchTransactionManualPayment() {
  yield takeEvery(
    TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT,
    transactionManualPaymentSaga
  );
}
