import { AnyAction, Reducer } from 'redux';
import { initialSaveInformationPhoneUser, SaveInformationPhoneUser } from './state';
import { SaveInformationPhoneUserType } from 'src/store/actions/saveInformationPhonrUser/actionTypes';

export const InformationPhoneUserReducer: Reducer = (
  state: SaveInformationPhoneUser = initialSaveInformationPhoneUser,
  action: AnyAction
) => {
  switch (action.type) {
    case SaveInformationPhoneUserType.SAVE_PHONE: {
      return {
        ...state,
        phoneCode:action.payload,
      };
    }
    default:
      return state;
  }
};
