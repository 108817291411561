import { ListParams } from 'src/models/common';
import { GetCryptoWalletOverviewStatisticalType } from './actionTypes';
import { IFetchingGetCryptoWalletOverviewStatistical, IGetCryptoWalletOverviewStatistical, IGetCryptoWalletOverviewStatisticalError, IGetCryptoWalletOverviewStatisticalSuccess, IGetListFilterCryptoWalletOverviewStatistical } from './interfaces';

export const getCryptoWalletOverviewStatisticalIsFetching = (isFetching:boolean) : IFetchingGetCryptoWalletOverviewStatistical => ({
    type: GetCryptoWalletOverviewStatisticalType.GET_CRYPTO_WALLET_OVERVIEW_STATISTICAL_FETCHING,
    isFetching
})

export const getCryptoWalletOverviewStatistical = (payload: object) : IGetCryptoWalletOverviewStatistical => ({
    type: GetCryptoWalletOverviewStatisticalType.GET_CRYPTO_WALLET_OVERVIEW_STATISTICAL,
    payload
})

export const getCryptoWalletOverviewStatisticalSuccess = (data:any) : IGetCryptoWalletOverviewStatisticalSuccess => ({
    type: GetCryptoWalletOverviewStatisticalType.GET_CRYPTO_WALLET_OVERVIEW_STATISTICAL_SUCCESS,
    data
})

export const getCryptoWalletOverviewStatisticalFaild = (error:any) : IGetCryptoWalletOverviewStatisticalError => ({
    type: GetCryptoWalletOverviewStatisticalType.GET_CRYPTO_WALLET_OVERVIEW_STATISTICAL_ERROR,
    error
})

export const setFilterCryptoWalletOverviewStatistical = (payload: ListParams): IGetListFilterCryptoWalletOverviewStatistical => ({
    type: GetCryptoWalletOverviewStatisticalType.GET_LIST_FILTER_CRYPTO_WALLET_OVERVIEW_STATISTICAL,
    payload
})