import { AnyAction, Reducer } from 'redux';
import { GetP2PExchangeOffersAgentType } from 'src/store/actions/P2PExchangeOffersAgent';
import { IListP2PExchangeOffersAgentState, initialListP2PExchangeOffersAgentState } from './state';


export const GetListP2PExchangeOffersAgentReducer: Reducer = (
  state: IListP2PExchangeOffersAgentState = initialListP2PExchangeOffersAgentState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT_ERROR : {
      return {
        ...state,
        error: action?.error,
        isFetching: false,
      };
    }
    case GetP2PExchangeOffersAgentType.GET_P2P_EXCHANGE_OFFERS_AGENT_SUCCESS : {
      return {
          ...state,
          data: action?.data?.data?.items,
          isFetching: false,
          pagination: action?.data?.data?.totalCount
      }
    }
    case GetP2PExchangeOffersAgentType.SET_LIST_FILTER_P2P_EXCHANGE_OFFERS_AGENT: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};