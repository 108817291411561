import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';

interface IDataListAllWalletAdminBO {
  data: {
    items: any[];
    totalCount: number;
  };
  error: any;
  success: boolean;
}

export interface IListAllWalletAdminBOState {
  data: IDataListAllWalletAdminBO;
  isFetching: boolean;
  filter: any;
  pagination: any;
}

export const initialListAllWalletAdminBOState: IListAllWalletAdminBOState = {
  data: {
    data: {
      items: [],
      totalCount: 0,
    },
    error: null,
    success: false,
  },
  isFetching: false,
  filter: {
    pageNumber: 1,
    pageSize: PAGE_SIZE_DEFAULT,
  },
  pagination: {
    totalCount: 0,
  },
};
