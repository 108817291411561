import { formatDateYMD } from "src/helpers/date";

interface IDataListCryptoWalletGetOverview {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListCryptoWalletGetOverviewState {
    data: IDataListCryptoWalletGetOverview;
    isFetching: boolean,
    filter: any,
    pagination: number
}

export const initialListCryptoWalletGetOverviewState: IListCryptoWalletGetOverviewState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        startDate: formatDateYMD(new Date),
        endDate: formatDateYMD(new Date)
    },
    pagination: 0,
}

