import {CreateTopupTransactionTypes} from './actionTypes';
import { ICreateTopupTransaction, ICreateTopupTransactionFetching, ICreateTopupTransactionSuccess, ICreateTopupTransactionError, ICreateTopupTransactionFilter, ICreateTopupTransactionFiterDebounce } from "./interfaces";


export const createTopupTransaction = (payload:Object): ICreateTopupTransaction => ({
    type: CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION,
    payload
})

export const createTopupTransactionFetching = (isFetching:boolean) : ICreateTopupTransactionFetching => ({
    type: CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION_FETCHING,
    isFetching
})

export const createTopupTransactionSuccess = (data:any) : ICreateTopupTransactionSuccess => ({
   type: CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION_SUCCESS,
   data
})

export const createTopupTransactionError = (errorMsg:any) : ICreateTopupTransactionError => ({
    type: CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION_FAILD,
    error:errorMsg
})

export const createTopupTransactionFilter = (payload: any): ICreateTopupTransactionFilter => ({
    type: CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION_FILTER,
    payload
})

export const createTopupTransactionFilterDebounce = (payload: any): ICreateTopupTransactionFiterDebounce => ({
    type: CreateTopupTransactionTypes.CREATE_TOPUP_TRANSACTION_FILTER_DEBOUNCE,
    payload 
})
