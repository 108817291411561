import { AnyAction, Reducer } from 'redux';
import { CountryType } from 'src/store/actions';
import { IListCountryState, initialListCountryState } from './state';


export const GetListCountryReducer: Reducer = (
  state: IListCountryState = initialListCountryState,
  action: AnyAction,
) => {
  switch (action.type) {
    case CountryType.LOAD_COUNTRY : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CountryType.LOAD_COUNTRY_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case CountryType.LOAD_COUNTRY_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
      }
    }
    default:
      return state;
  }
};