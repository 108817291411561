import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetUserKYCPriorityHistory } from 'src/apis/service/Priority';
import { GetUserKYCPriorityHistoryTypes } from '../../actions/getUserKYCPriorityHistory';
import { getUserKYCPriorityHistoryError, getUserKYCPriorityHistoryFetching, getUserKYCPriorityHistoryFilter, getUserKYCPriorityHistorySuccess } from '../../actions/getUserKYCPriorityHistory/action';

function* getUserKYCPriorityHistorySaga(action:any):Generator<any> {
    yield put(getUserKYCPriorityHistoryFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetUserKYCPriorityHistory(payload)
      if(data && data?.status === 200){
        yield put(getUserKYCPriorityHistorySuccess(data))
      } else {
        yield put(getUserKYCPriorityHistoryError('error'))
      }
    } catch (error) {
        yield put(getUserKYCPriorityHistoryError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getUserKYCPriorityHistoryFilter(action.payload));
}
export function* watchGetUserKYCPriorityHistory() {
   yield takeEvery(GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY, getUserKYCPriorityHistorySaga)
   yield debounce(500,GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_FILTER_DEBOUNCE,handleSearchDebounce)
}
