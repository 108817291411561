import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletReceiveStatisticalType } from 'src/store/actions';
import { IListCryptoWalletReceiveStatisticalState, initialListCryptoWalletReceiveStatisticalState } from './state';


export const GetListCryptoWalletReceiveStatisticalReducer: Reducer = (
  state: IListCryptoWalletReceiveStatisticalState = initialListCryptoWalletReceiveStatisticalState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCryptoWalletReceiveStatisticalType.GET_CRYPTO_WALLET_RECEIVE_STATISTICAL : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoWalletReceiveStatisticalType.GET_CRYPTO_WALLET_RECEIVE_STATISTICAL_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetCryptoWalletReceiveStatisticalType.GET_CRYPTO_WALLET_RECEIVE_STATISTICAL_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
      }
    }
    case GetCryptoWalletReceiveStatisticalType.GET_LIST_FILTER_CRYPTO_WALLET_RECEIVE_STATISTICAL: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};