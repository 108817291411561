import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handlePostAllRankPeriod } from 'src/apis/service/Setting';
import { GetAllRankPeriodTypes } from '../../actions/getAllRankPeriod';
import { getAllRankPeriodError, getAllRankPeriodFetching, getAllRankPeriodFilter, getAllRankPeriodSuccess } from '../../actions/getAllRankPeriod/action';

function* getAllRankPeriodSaga(action: any): Generator<any> {
  yield put(getAllRankPeriodFetching(true))
  try {
    const data: any = yield handlePostAllRankPeriod(action.payload)
    if (data && data?.status === 200) {
      yield put(getAllRankPeriodSuccess(data))
    } else {
      yield put(getAllRankPeriodError('error'))
    }
  } catch (error) {
    yield put(getAllRankPeriodError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAllRankPeriodFilter(action.payload));
}
export function* watchGetAllRankPeriod() {
  yield takeEvery(GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD, getAllRankPeriodSaga)
  yield debounce(500, GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD_FILTER_DEBOUNCE, handleSearchDebounce)
}
