import { AnyAction, Reducer } from 'redux';
import { initialDeleteCurrencyState, IDataDeleteCurrencyState } from './state';
import { DeleteCurrencyType } from '../../../actions/currency';

export const DeleteCurrencyReducer: Reducer = (
  state: IDataDeleteCurrencyState = initialDeleteCurrencyState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteCurrencyType.DELETE_CURRENCY: {
      return {
        ...state,
        isFetching: true,
        success: false
      }
    }
    case DeleteCurrencyType.DELETE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true,
        successCount: state.successCount + 1
      }
    }
    case DeleteCurrencyType.DELETE_CURRENCY_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      }
    }
    default: {
      return state
    }
  }
}