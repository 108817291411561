import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetTransactionSellBuySearch } from 'src/apis/service/P2PTransaction';
import { getP2PSellTransactionsFaild, getP2PSellTransactionsIsFetching, getP2PSellTransactionsSuccess, GetP2PSellTransactionsType, setFilterP2PSellTransactions } from 'src/store/actions';

function* getListP2PSellTransactions(action: any): Generator<any> {
    yield put(getP2PSellTransactionsIsFetching(true))
    const { payload } = action;
    try {
      const data : any = yield call(handleGetTransactionSellBuySearch, payload);     
      if(data?.status === 200) {
        yield put(getP2PSellTransactionsSuccess(data?.data === null ? [] : data?.data));
      } else {
        yield put(getP2PSellTransactionsFaild('error'));
      } 
    } catch (error) {
        yield put(getP2PSellTransactionsFaild(error));
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterP2PSellTransactions(action.payload));
}

export function* watchGetListP2PSellTransactions() {
  yield takeEvery(GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS , getListP2PSellTransactions)
  yield debounce(500, GetP2PSellTransactionsType.SET_LIST_FILTER_DEBOUNCE_P2P_SELL_TRANSACTIONS, handleSearchDebounce)
}