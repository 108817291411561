import { SendNoteTypes } from './actionTypes';
import {
  ISendNote,
  ISendNoteFaild,
  ISendNoteFetching,
  ISendNoteSuccess,
  ParamsNote
} from './interfaces';

export const sendNoteKycAtFetching = (isFetching:boolean) : ISendNoteFetching  => ({
    type: SendNoteTypes.NOTE_KYC_IS_FETCHING,
    isFetching
})

export const sendNoteKyc = (payload:ParamsNote) : ISendNote => ({
    type: SendNoteTypes.NOTE_KYC,
    payload
})

export const sendNoteKycSuccess = (data:any) : ISendNoteSuccess => ({
    type: SendNoteTypes.NOTE_KYC_SUCCESS,
    data
})

export const sendNoteKycFaild = (error:any) : ISendNoteFaild => ({
    type: SendNoteTypes.NOTE_KYC_FAILD,
    error
})
