import { AnyAction, Reducer } from 'redux';
import { UpdateExchangeOfferType } from 'src/store/actions/updateExchangeOffer';
import { initialUpdateExchangeOfferState, UpdateExchangeOfferState } from './state';

export const UpdateExchangeOfferReducer: Reducer = (
  state: UpdateExchangeOfferState = initialUpdateExchangeOfferState,
  action: AnyAction
) => {
  switch (action.type) {
    case UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
