import {CheckPasswordAdminTypes} from './actionTypes';
import { ICheckPasswordAdmin, ICheckPasswordAdminFetching, ICheckPasswordAdminSuccess, ICheckPasswordAdminError, ICheckPasswordAdminFilter, ICheckPasswordAdminFiterDebounce } from "./interfaces";


export const checkPasswordAdmin = (payload:Object): ICheckPasswordAdmin => ({
    type: CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN,
    payload
})

export const checkPasswordAdminFetching = (isFetching:boolean) : ICheckPasswordAdminFetching => ({
    type: CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_FETCHING,
    isFetching
})

export const checkPasswordAdminSuccess = (data:any) : ICheckPasswordAdminSuccess => ({
   type: CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_SUCCESS,
   data
})

export const checkPasswordAdminError = (errorMsg:any) : ICheckPasswordAdminError => ({
    type: CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_FAILD,
    error:errorMsg
})

export const checkPasswordAdminFilter = (payload: any): ICheckPasswordAdminFilter => ({
    type: CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_FILTER,
    payload
})

export const checkPasswordAdminFilterDebounce = (payload: any): ICheckPasswordAdminFiterDebounce => ({
    type: CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_FILTER_DEBOUNCE,
    payload 
})
