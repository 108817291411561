import {initializeApp} from 'firebase/app';
import { getMessaging } from 'firebase/messaging'
const firebaseConfig = {
    apiKey: "AIzaSyCyLMW4qASKeNPaKTK-wupfxv_I6coZ6ek",
    authDomain: "holapay-7e168.firebaseapp.com",
    databaseURL: "https://holapay-7e168-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "holapay-7e168",
    storageBucket: "holapay-7e168.appspot.com",
    messagingSenderId: "47885028292",
    appId: "1:47885028292:web:e18312d7094db2dbd7f291",
    measurementId: "G-Q49MEVFZKP"
  };
const app = initializeApp(firebaseConfig)

export const messaging = getMessaging(app)