import { AnyAction, Reducer } from 'redux';
import { GetKycHistoryNoteTypes } from 'src/store/actions/kycHistoryNote/actionTypes';
import { IListKycListHistoryNoteState, initialListKycListHistoryNoteState } from './state';


export const GetListKycListHistoryNoteReducer: Reducer = (
  state: IListKycListHistoryNoteState = initialListKycListHistoryNoteState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case GetKycHistoryNoteTypes.GET_HISTORY_NOTE_FILTER_KYC_HISTORY_NOTE: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};