import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetRoleFunctionByAdminId } from 'src/apis/service/AdminRoleSetting';
import { GetRoleFunctionByAdminIdTypes } from '../../actions/getRoleFunctionByAdminId';
import { getRoleFunctionByAdminIdError, getRoleFunctionByAdminIdFetching, getRoleFunctionByAdminIdFilter, getRoleFunctionByAdminIdSuccess } from '../../actions/getRoleFunctionByAdminId/action';

function* getRoleFunctionByAdminIdSaga(action:any):Generator<any> {
    yield put(getRoleFunctionByAdminIdFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetRoleFunctionByAdminId(payload)
      if(data && data?.status === 200){
        yield put(getRoleFunctionByAdminIdSuccess(data))
      } else {
        yield put(getRoleFunctionByAdminIdError('error'))
      }
    } catch (error) {
        yield put(getRoleFunctionByAdminIdError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getRoleFunctionByAdminIdFilter(action.payload));
}
export function* watchGetRoleFunctionByAdminId() {
   yield takeEvery(GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID, getRoleFunctionByAdminIdSaga)
   yield debounce(500,GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_FILTER_DEBOUNCE,handleSearchDebounce)
}
