import {GetAllTransactionsByAdminTypes} from './actionTypes';
import { IGetAllTransactionsByAdmin, IGetAllTransactionsByAdminFetching, IGetAllTransactionsByAdminSuccess, IGetAllTransactionsByAdminError, IGetAllTransactionsByAdminFilter, IGetAllTransactionsByAdminFiterDebounce } from "./interfaces";


export const getAllTransactionsByAdmin = (payload:Object): IGetAllTransactionsByAdmin => ({
    type: GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN,
    payload
})

export const getAllTransactionsByAdminFetching = (isFetching:boolean) : IGetAllTransactionsByAdminFetching => ({
    type: GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN_FETCHING,
    isFetching
})

export const getAllTransactionsByAdminSuccess = (data:any) : IGetAllTransactionsByAdminSuccess => ({
   type: GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN_SUCCESS,
   data
})

export const getAllTransactionsByAdminError = (errorMsg:any) : IGetAllTransactionsByAdminError => ({
    type: GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN_FAILD,
    error:errorMsg
})

export const getAllTransactionsByAdminFilter = (payload: any): IGetAllTransactionsByAdminFilter => ({
    type: GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN_FILTER,
    payload
})

export const getAllTransactionsByAdminFilterDebounce = (payload: any): IGetAllTransactionsByAdminFiterDebounce => ({
    type: GetAllTransactionsByAdminTypes.GET_ALL_TRANSACTIONS_BY_ADMIN_FILTER_DEBOUNCE,
    payload 
})
