import {GetOfferTransactionTypes} from './actionTypes';
import {
    IGetListFilterDebounceOfferTransaction,
    IGetListFilterOfferTransaction,
    IGetOfferTransaction,
    IGetOfferTransactionFaild,
    IGetOfferTransactionFetching,
    IGetOfferTransactionSuccess
} from './interfaces';

export const getOfferTransactionAtFetching = (isFetching:boolean) : IGetOfferTransactionFetching  => ({
    type: GetOfferTransactionTypes.GET_OFFER_TRANSACTION_FETCHING,
    isFetching
})

export const getOfferTransaction = (payload: Object) : IGetOfferTransaction => ({
    type: GetOfferTransactionTypes.GET_OFFER_TRANSACTION,
    payload
})

export const getOfferTransactionSuccess = (data:any) : IGetOfferTransactionSuccess => ({
    type: GetOfferTransactionTypes.GET_OFFER_TRANSACTION_SUCCESS,
    data
})

export const getOfferTransactionFaild = (error:any) : IGetOfferTransactionFaild => ({
    type: GetOfferTransactionTypes.GET_OFFER_TRANSACTION_FAILD,
    error
})

export const setFilterOfferTransaction = (payload: any): IGetListFilterOfferTransaction => ({
    type: GetOfferTransactionTypes.GET_LIST_FILTER_OFFER_TRANSACTION,
    payload
})

export const setFilterDebounceOfferTransaction = (payload: any): IGetListFilterDebounceOfferTransaction => ({
    type: GetOfferTransactionTypes.GET_LIST_FILTER_DEBOUNCE_OFFER_TRANSACTION,
    payload 
})