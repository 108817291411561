import { put, takeLatest, select } from 'redux-saga/effects';
import { updatePriority } from 'src/apis/service/Priority';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { 
   UpdatePriorityType,
   updatePrioriryIsFetching,
   updatePrioritySuccess,
   updatePriorityFaild
 } from 'src/store/actions/priority/updatePriority';
import { getPriority } from 'src/store/actions/priority'; 

function* updatePriorityForUser(action: any):Generator<any>{


   yield put(updatePrioriryIsFetching(true));
    try {
      const dataPriority:any = yield updatePriority(action.payload) 
      if (dataPriority && dataPriority?.status === 200) {
        yield put(updatePrioritySuccess(dataPriority))
        toastSuccess('bo_update_priority_success')
        yield put(getPriority())
      } else {
        yield put(updatePriorityFaild('Update priority faild'))
        toastError('bo_update_priority_fail')
      } 
    } catch (error) {
        yield put(updatePriorityFaild(error))
    }
}

export function* watchUpdatePriority () {
  yield takeLatest(UpdatePriorityType.UPDATE_PRIORITY, updatePriorityForUser)
}