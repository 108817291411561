import { AnyAction, Reducer } from 'redux';
import { IUpdateScoreKPIState, initialUpdateScoreKPIState } from './state';
import { UpdateScoreKPITypes } from 'src/store/actions/updateScoreKPI';

export const UpdateScoreKPIReducer: Reducer = (
  state: IUpdateScoreKPIState = initialUpdateScoreKPIState,
  action: AnyAction
) => {
  switch (action.type) {
    case UpdateScoreKPITypes.UPDATE_SCORE_KPI: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateScoreKPITypes.UPDATE_SCORE_KPI_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case UpdateScoreKPITypes.UPDATE_SCORE_KPI_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case UpdateScoreKPITypes.UPDATE_SCORE_KPI_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
