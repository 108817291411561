import { GetCryptoWalletGetOverviewTransactionCryptoType } from './actionTypes';
import { IFetchingGetCryptoWalletGetOverviewTransactionCrypto, IGetCryptoWalletGetOverviewTransactionCrypto, IGetCryptoWalletGetOverviewTransactionCryptoError, IGetCryptoWalletGetOverviewTransactionCryptoSuccess, IGetListFilterCryptoWalletGetOverviewTransactionCrypto } from './interfaces';

export const getCryptoWalletGetOverviewTransactionCryptoIsFetching = (isFetching:boolean) : IFetchingGetCryptoWalletGetOverviewTransactionCrypto => ({
    type: GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO_FETCHING,
    isFetching
})

export const getCryptoWalletGetOverviewTransactionCrypto = (payload: object) : IGetCryptoWalletGetOverviewTransactionCrypto => ({
    type: GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO,
    payload
})

export const getCryptoWalletGetOverviewTransactionCryptoSuccess = (data:any) : IGetCryptoWalletGetOverviewTransactionCryptoSuccess => ({
    type: GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO_SUCCESS,
    data
})

export const getCryptoWalletGetOverviewTransactionCryptoFaild = (error:any) : IGetCryptoWalletGetOverviewTransactionCryptoError => ({
    type: GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO_ERROR,
    error
})

export const setFilterCryptoWalletGetOverviewTransactionCrypto = (payload: any): IGetListFilterCryptoWalletGetOverviewTransactionCrypto => ({
    type: GetCryptoWalletGetOverviewTransactionCryptoType.GET_LIST_FILTER_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO,
    payload
})