import { AnyAction, Reducer } from 'redux';
import { CreateAnnouncementsCategoryType } from 'src/store/actions';
import { ICreateListAnnouncementsCategoryState, initialCreateListAnnouncementsCategoryState } from './state';


export const CreateListAnnouncementsCategoryReducer: Reducer = (
  state: ICreateListAnnouncementsCategoryState = initialCreateListAnnouncementsCategoryState,
  action: AnyAction,
) => {
  switch (action.type) {
    case CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        success: true
      }
    }
    default:
      return state;
  }
};