import { number } from "yup/lib/locale";

// check phone
interface DataCheckUserAccount {
    isAgency: boolean;
    isGrantedUser: boolean;
    isMemberStore: boolean;
    isSuperAgency: boolean;
    passwordCreated: boolean;
    twoFactorEnabled: boolean;
  }
  
  interface authPermissionUser {
    data:DataCheckUserAccount;
    message:string;
    status:number;
    success:boolean;
  }
  
  export interface ICheckPhone {
      isFetching:boolean;
      checked: authPermissionUser,
  }
  
  export const initialCheckUserAccountState: ICheckPhone = {
      isFetching: false,
      checked:{
          data:{
              isAgency: false,
              isGrantedUser: false,
              isMemberStore: false,
              isSuperAgency: false,
              passwordCreated: false,
              twoFactorEnabled: false
          },
            status:0,
            message:'',
            success:false
        },
      
}