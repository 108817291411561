export interface CreatePin  {
    data:any,
    errors:any,
    success:any
}

export interface CreatePinState {
    data: CreatePin,
    isFetching:boolean
}

export const initialCreatePinState: CreatePinState = {
    data:{
        data:'',
        errors:'',
        success:false
    },
    isFetching:false
}