import { put, call, takeEvery, select } from 'redux-saga/effects';
import { handleGetP2PExchangeOfferNote } from 'src/apis/service/P2PExchange';
import { P2PExchangeOffersNoteType, sendNoteP2PExchangeOffersIsFetching, sendNoteP2PExchangeOffersSuccess, sendNoteP2PExchangeOffersFaild } from 'src/store/actions/P2PExchangeOfferNote';
import { toastSuccess,toastError } from 'src/helpers/toastHelper';
import { getP2PExchangeOffers } from 'src/store/actions/P2PExchangeOffers/action';
import { IApplicationState } from 'src/store/IApplicationState';

function* sendNoteP2PExchangeOffersSaga(action: any): Generator<any> {


    yield put(sendNoteP2PExchangeOffersIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeOfferNote, payload)      
      if(data && data?.status === 200){
        const filter : any = yield select((state:IApplicationState) => state.listP2PExchangeOffers.filter)
        yield put(sendNoteP2PExchangeOffersSuccess(data));
        toastSuccess('bo_update_note_success')
        yield put(getP2PExchangeOffers(filter))
      } else {
        yield put(sendNoteP2PExchangeOffersFaild('error'))
        toastError('bo_send_note_fail')
      }
    } catch (error) {
        yield put(sendNoteP2PExchangeOffersFaild(error))
        toastError('bo_send_note_fail')
    }
}

export function* watchSendNoteP2PExchangeOffers() {
   yield takeEvery(P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER , sendNoteP2PExchangeOffersSaga)
}