import { cloneDeep } from 'lodash'

export function convertObjectToParameter(data: any) {
  var result = ''
  if (!data) return result
  let payload = cloneDeep(data)
  Object.keys(payload).forEach((key) => {
    if (payload[key] === undefined || payload[key] === '') {
      delete payload[key]
    }
  })
  try {
    result =
      '?' +
      Object.keys(payload)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`)
        .join('&')
  } catch (error) {
    console.log(error)
  }
  return result
}
