import {GetManualPaymentHistoryTypes} from './actionTypes';
import {
    IGetListFiterDebounceManualPaymentHistory,
    IGetListFiterManualPaymentHistory,
    IGetManualPaymentHistory,
    IGetManualPaymentHistoryFaild,
    IGetManualPaymentHistoryFetching,
    IGetManualPaymentHistorySuccess
} from './interfaces';

export const getManualPaymentHistoryAtFetching = (isFetching:boolean) : IGetManualPaymentHistoryFetching  => ({
    type: GetManualPaymentHistoryTypes.GET_MANUAL_PAYMENT_HISTORY_FETCHING,
    isFetching
})

export const getManualPaymentHistory = (payload:Object) : IGetManualPaymentHistory => ({
    type: GetManualPaymentHistoryTypes.GET_MANUAL_PAYMENT_HISTORY,
    payload
})

export const getManualPaymentHistorySuccess = (data:any) : IGetManualPaymentHistorySuccess => ({
    type: GetManualPaymentHistoryTypes.GET_MANUAL_PAYMENT_HISTORY_SUCCESS,
    data
})

export const getManualPaymentHistoryFaild = (error:any) : IGetManualPaymentHistoryFaild => ({
    type: GetManualPaymentHistoryTypes.GET_MANUAL_PAYMENT_HISTORY_FAILD,
    error
})

export const setFilterManualPaymentHistory = (payload: any): IGetListFiterManualPaymentHistory => ({
    type: GetManualPaymentHistoryTypes.GET_LIST_FILTER_MANUAL_PAYMENT_HISTORY,
    payload
})

export const setFilterDebounceManualPaymentHistory = (payload: any): IGetListFiterDebounceManualPaymentHistory => ({
    type: GetManualPaymentHistoryTypes.GET_LIST_FILTER_DEBOUNCE_MANUAL_PAYMENT_HISTORY,
    payload 
})