interface IDataListBankAccountAgent {
  data: {
    items: any[];
    totalCount: number;
  };
  status: number;
  message: string;
}

export interface IListBankAccountAgentState {
  data: IDataListBankAccountAgent;
  isFetching: boolean;
}

export const initialListBankAccountAgentState: IListBankAccountAgentState = {
  data: {
    data: {
        items: [],
        totalCount: 0,
      },
    message: "",
    status: 0,
  },
  isFetching: false,
};
