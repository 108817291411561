import { debounce, put, takeEvery } from "redux-saga/effects";
import { handleRetryTransaction } from "src/apis/service/CryptoTransactionManagement";
import { getWalletDetail } from "src/store/actions/getWalletDetail";
import { toastError, toastSuccess } from "src/utils/newUtils/toastHelper";
import { HandleRetryTransactionTypes } from "../../actions/handleRetryTransaction";
import {
  handleRetryTransactionError,
  handleRetryTransactionFetching,
  handleRetryTransactionFilter,
  handleRetryTransactionSuccess,
} from "../../actions/handleRetryTransaction/action";

function* handleRetryTransactionSaga(action: any): Generator<any> {
  yield put(handleRetryTransactionFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleRetryTransaction(payload);
    if (data && data?.status === 200) {
      if (payload.paramsGetWalletDetail) {
        yield put(getWalletDetail(payload.paramsGetWalletDetail));
      }
      toastSuccess('bo_retry_transaction_success')
      yield put(handleRetryTransactionSuccess(data));
    } else {
      toastError('bo_retry_transaction_fail','bo_retry_transaction_fail')
      yield put(handleRetryTransactionError("error"));
    }
  } catch (error) {
    toastError('bo_retry_transaction_fail','bo_retry_transaction_fail')
    yield put(handleRetryTransactionError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(handleRetryTransactionFilter(action.payload));
}
export function* watchHandleRetryTransaction() {
  yield takeEvery(
    HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION,
    handleRetryTransactionSaga
  );
  yield debounce(
    500,
    HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
