import { debounce, put, takeEvery } from 'redux-saga/effects';
import { getKycList } from 'src/apis/service/Kyc';
import { getKycRiskManagementAtFetching, getKycRiskManagementFaild, getKycRiskManagementSuccess, GetKycRiskManagementTypes, setFilterKycRiskManagement } from 'src/store/actions';

function* getKycRiskManagementSaga(action:any):Generator<any> {
    yield put(getKycRiskManagementAtFetching(true))
    const {payload} = action;
    try {
      const dataKycRiskManagement:any = yield getKycList(payload);
      if(dataKycRiskManagement && dataKycRiskManagement?.status === 200) {
        yield put(getKycRiskManagementSuccess(dataKycRiskManagement))
      } else {
        yield put(getKycRiskManagementFaild('error'))
      }
    } catch (error) {
        yield put(getKycRiskManagementFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterKycRiskManagement(action.payload));
}

export function* watchGetListKycRiskManagement() {
   yield takeEvery(GetKycRiskManagementTypes.GET_KYC_RISK_MANAGEMENT, getKycRiskManagementSaga)
   yield debounce(500, GetKycRiskManagementTypes.GET_LIST_FILTER_DEBOUNCE_KYC_RISK_MANAGEMENT, handleSearchDebounce)
}