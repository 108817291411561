import Cookies from 'js-cookie';
import { AnyAction, Reducer } from 'redux';
import { routerConst } from 'src/routers/routerConstants';
import { LoginAgentActionsTypes } from 'src/store/actions/loginAgent';
import { AgentLogoutActionsTypes } from 'src/store/actions/logoutAgent';
import { clearStorageItems } from 'src/utils/newUtils/localStorageHelper';
import { ILoginAgentState, initialLoginAgentState } from './state';

export const LogginAgentReducer: Reducer = (
  state: ILoginAgentState = initialLoginAgentState,
  action: AnyAction
) => {
  switch (action.type) {
    case LoginAgentActionsTypes.GET_LOGIN_AGENT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case LoginAgentActionsTypes.GET_LOGIN_AGENT_SUCCESS: {
      return {
        ...state,
        login: action.login,
        isFetching: false,
        error: '',
        checkSuccess: true,
        expiredDate: action.login?.expiredDate,
      };
    }
    case LoginAgentActionsTypes.GET_LOGIN_AGENT_FAIL: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        login: initialLoginAgentState,
      };
    }

    //------------ verify otp------------

    case LoginAgentActionsTypes.VERIFY_OTP: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case LoginAgentActionsTypes.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        login: action.verify,
        isFetching: false,
        error: '',
        verifyOTPSuccess: true,
      };
    }
    case LoginAgentActionsTypes.VERIFY_OTP_FAIL: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        login: initialLoginAgentState,
      };
    }

    //------------ verify otp------------

    case LoginAgentActionsTypes.VERIFY_PIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case LoginAgentActionsTypes.VERIFY_PIN_SUCCESS: {
      return {
        ...state,
        login: action.verify,
        twoFAKey: action.login.data.twoFATokenValue,
        loginData: action.login.data,
        isFetching: false,
        error: '',
        verifyPINSuccess: true,
      };
    }
    case LoginAgentActionsTypes.VERIFY_PIN_FAIL: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        login: initialLoginAgentState,
      };
    }

    //------------ verify 2fa------------

    case LoginAgentActionsTypes.CLEAR_2FA: {
      return {
        ...state,
        twoFAKey: undefined,
        loginData: undefined,
      };
    }

    case AgentLogoutActionsTypes.AGENT_LOGOUT: {
      clearStorageItems();
      Cookies.remove('token');
      window.location.href = routerConst.Login;
      return {
        ...state,
        error: '',
        isFetching: false,
        login: initialLoginAgentState,
        verifyPINSuccess: false,
        verifyOTPSuccess: false,
        verify2FASuccess: false,
        checkSuccess: false,
      };
    }

    default:
      return state;
  }
};
