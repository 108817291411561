import { GetAllWalletAdminBOType } from './actionTypes';
import {
  IFilterWalletAdminBO,
  IGetAllWalletAdminBO,
  IGetAllWalletAdminBOFaild,
  IGetAllWalletAdminBOFetching,
  IGetAllWalletAdminBOSuccess,
  IGetFiterDebounceWalletAdminBO,
} from './interfaces';

export const getAllWalletAdminBOAtFetching = (
  isFetching: boolean
): IGetAllWalletAdminBOFetching => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO_FETCHING,
  isFetching,
});

export const getAllWalletAdminBO = (payload: Object): IGetAllWalletAdminBO => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO,
  payload,
});

export const getAllWalletAdminBOSuccess = (
  data: any
): IGetAllWalletAdminBOSuccess => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO_SUCCESS,
  data,
});

export const getAllWalletAdminBOFailed = (
  error: any
): IGetAllWalletAdminBOFaild => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO_FAILD,
  error,
});

export const setFilterWalletAdminBO = (payload: any): IFilterWalletAdminBO => ({
  type: GetAllWalletAdminBOType.FILTER_WALLET_ADMIN_BO,
  payload,
});

export const filterDebounceWalletAdminBO = (
  payload: any
): IGetFiterDebounceWalletAdminBO => ({
  type: GetAllWalletAdminBOType.GET_FILTER_DEBOUNCE_WALLET_ADMIN_BO,
  payload,
});
