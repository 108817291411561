import { AnyAction, Reducer } from 'redux';
import { SetOrAddAgentRankState, initialSetAgentRankState } from './state';
import { SetAgentRankType } from 'src/store/actions/Agent/SetAgentRank';

export const SetOrAddAgentRankReducer: Reducer = (
  state: SetOrAddAgentRankState = initialSetAgentRankState,
  action: AnyAction
) => {
  switch (action.type) {
    case SetAgentRankType.SET_AGENT_RANK: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SetAgentRankType.SET_AGENT_RANK_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case SetAgentRankType.SET_AGENT_RANK_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
