import {GetBlockchainDashboardTypes} from './actionTypes';
import { IGetBlockchainDashboard, IGetBlockchainDashboardFetching, IGetBlockchainDashboardSuccess, IGetBlockchainDashboardError, IGetBlockchainDashboardFilter, IGetBlockchainDashboardFiterDebounce } from "./interfaces";


export const getBlockchainDashboard = (payload:Object): IGetBlockchainDashboard => ({
    type: GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD,
    payload
})

export const getBlockchainDashboardFetching = (isFetching:boolean) : IGetBlockchainDashboardFetching => ({
    type: GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD_FETCHING,
    isFetching
})

export const getBlockchainDashboardSuccess = (data:any) : IGetBlockchainDashboardSuccess => ({
   type: GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD_SUCCESS,
   data
})

export const getBlockchainDashboardError = (errorMsg:any) : IGetBlockchainDashboardError => ({
    type: GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD_FAILD,
    error:errorMsg
})

export const getBlockchainDashboardFilter = (payload: any): IGetBlockchainDashboardFilter => ({
    type: GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD_FILTER,
    payload
})

export const getBlockchainDashboardFilterDebounce = (payload: any): IGetBlockchainDashboardFiterDebounce => ({
    type: GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD_FILTER_DEBOUNCE,
    payload 
})
