import {GetListTopupRequestTypes} from './actionTypes';
import { IGetListTopupRequest, IGetListTopupRequestFetching, IGetListTopupRequestSuccess, IGetListTopupRequestError, IGetListTopupRequestFilter, IGetListTopupRequestFiterDebounce } from "./interfaces";


export const getListTopupRequest = (payload:Object): IGetListTopupRequest => ({
    type: GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST,
    payload
})

export const getListTopupRequestFetching = (isFetching:boolean) : IGetListTopupRequestFetching => ({
    type: GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_FETCHING,
    isFetching
})

export const getListTopupRequestSuccess = (data:any) : IGetListTopupRequestSuccess => ({
   type: GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_SUCCESS,
   data
})

export const getListTopupRequestError = (errorMsg:any) : IGetListTopupRequestError => ({
    type: GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_FAILD,
    error:errorMsg
})

export const getListTopupRequestFilter = (payload: any): IGetListTopupRequestFilter => ({
    type: GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_FILTER,
    payload
})

export const getListTopupRequestFilterDebounce = (payload: any): IGetListTopupRequestFiterDebounce => ({
    type: GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_FILTER_DEBOUNCE,
    payload 
})
