import {call, debounce, put, takeEvery} from 'redux-saga/effects';
import { handleOfferSearch } from 'src/apis/service/P2PTransaction';
import { getOfferSellTransactionAtFetching, getOfferSellTransactionFaild, getOfferSellTransactionSuccess, GetOfferSellTransactionTypes, setFilterOfferSellTransaction } from 'src/store/actions';

function* getOfferSellTransactionSaga(action:any):Generator<any> {
    yield put(getOfferSellTransactionAtFetching(true))
    const {payload} = action;
    try {
      const dataOfferSellTransaction:any = yield call(handleOfferSearch, payload)
      if(dataOfferSellTransaction && dataOfferSellTransaction?.status === 200){
        yield put(getOfferSellTransactionSuccess(dataOfferSellTransaction))
      } else {
        yield put(getOfferSellTransactionFaild('error'))
      }
    } catch (error) {
        yield put(getOfferSellTransactionFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterOfferSellTransaction(action.payload));
}

export function* watchGetListOfferSellTransaction() {
  yield takeEvery(GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION, getOfferSellTransactionSaga);
  yield debounce(500, GetOfferSellTransactionTypes.GET_LIST_FILTER_DEBOUNCE_OFFER_SELL_TRANSACTION, handleSearchDebounce)
}