import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetListUserDeactivatedTypes,getListUserDeactivatedSuccess,getListUserDeactivatedFaild,getListUserDeactivatedAtFetching,setFilterListUserDeactivated} from '../../actions/getListUserDeactivated';
import { handleGetListUserDeactivated } from '../../../apis/service/user';

function* getListUserDeactivatedSaga(action:any):Generator<any> {
    yield put(getListUserDeactivatedAtFetching(true))
    const {payload} = action;
    try {
      const dataTransactions:any = yield handleGetListUserDeactivated(payload)
      if(dataTransactions && dataTransactions?.status === 200){
        yield put(getListUserDeactivatedSuccess(dataTransactions))
      } else {
        yield put(getListUserDeactivatedFaild('error'))
      }
    } catch (error) {
        yield put(getListUserDeactivatedFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterListUserDeactivated(action.payload));
}
export function* watchGetListUserDeactivated() {
   yield takeEvery(GetListUserDeactivatedTypes.GET_LIST_USER_DEACTIVATED, getListUserDeactivatedSaga)
   yield debounce(500,GetListUserDeactivatedTypes.GET_LIST_FILTER_DEBOUNCE_USER_DEACTIVATED,handleSearchDebounce)
}