import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetAllWallet } from '../../../apis/service/user';
import { GetListWalletsForUserTypes } from '../../actions/getListWalletsForUser';
import {
  getListWalletsForUserError,
  getListWalletsForUserFetching,
  getListWalletsForUserFilter,
  getListWalletsForUserSuccess,
} from '../../actions/getListWalletsForUser/action';

function* getListWalletsForUserSaga(action: any): Generator<any> {
  yield put(getListWalletsForUserFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetAllWallet(payload);
    if (data && data?.status === 200) {
      yield put(getListWalletsForUserSuccess(data));
    } else {
      yield put(getListWalletsForUserError('error'));
    }
  } catch (error) {
    yield put(getListWalletsForUserError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListWalletsForUserFilter(action.payload));
}
export function* watchGetListWalletsForUser() {
  yield takeEvery(
    GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER,
    getListWalletsForUserSaga
  );
  yield debounce(
    500,
    GetListWalletsForUserTypes.GET_LIST_WALLETS_FOR_USER_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
