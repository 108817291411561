import { AnyAction, Reducer } from 'redux';
import { initialExportFileState, ExportFileState } from './state';
import { ExportFileType } from '../../actions/ExportFile/actionTypes';


export const ExportFileReducer: Reducer = (
  state: ExportFileState = initialExportFileState,
  action: AnyAction
) => {
  switch (action.type) {
    case ExportFileType.EXPORT_FILE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ExportFileType.EXPORT_FILE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case ExportFileType.EXPORT_FILE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
