import { ListParams } from "src/models/common";

interface IDataGetListTokenSupport {
  data: any[];
  error: any;
  success: boolean;
}

export interface IGetListTokenSupportState {
  data: IDataGetListTokenSupport;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialGetListTokenSupportState: IGetListTokenSupportState = {
  data: {
    data: [],
    error: null,
    success: false,
  },
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
