import {AgentGetAllWalletsTypes} from './actionTypes';
import { IAgentGetAllWallets, IAgentGetAllWalletsFetching, IAgentGetAllWalletsSuccess, IAgentGetAllWalletsError, IAgentGetAllWalletsFilter, IAgentGetAllWalletsFiterDebounce } from "./interfaces";


export const agentGetAllWallets = (): IAgentGetAllWallets => ({
    type: AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS
})

export const agentGetAllWalletsFetching = (isFetching:boolean) : IAgentGetAllWalletsFetching => ({
    type: AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_FETCHING,
    isFetching
})

export const agentGetAllWalletsSuccess = (data:any) : IAgentGetAllWalletsSuccess => ({
   type: AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_SUCCESS,
   data
})

export const agentGetAllWalletsError = (errorMsg:any) : IAgentGetAllWalletsError => ({
    type: AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_FAILD,
    error:errorMsg
})

export const agentGetAllWalletsFilter = (payload: any): IAgentGetAllWalletsFilter => ({
    type: AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_FILTER,
    payload
})

export const agentGetAllWalletsFilterDebounce = (payload: any): IAgentGetAllWalletsFiterDebounce => ({
    type: AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_FILTER_DEBOUNCE,
    payload 
})
