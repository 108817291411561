
interface IDataCreateAnnouncements {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface ICreateAnnouncementsState {
    data: IDataCreateAnnouncements;
    isFetching: boolean,
}

export const initialDataCreateAnnouncementsState: ICreateAnnouncementsState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
}

