import {TransactionAgentOverviewTypes} from './actionTypes';
import { ITransactionAgentOverview, ITransactionAgentOverviewFetching, ITransactionAgentOverviewSuccess, ITransactionAgentOverviewError, ITransactionAgentOverviewFilter, ITransactionAgentOverviewFiterDebounce } from "./interfaces";


export const transactionAgentOverview = (): ITransactionAgentOverview => ({
    type: TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW
})

export const transactionAgentOverviewFetching = (isFetching:boolean) : ITransactionAgentOverviewFetching => ({
    type: TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_FETCHING,
    isFetching
})

export const transactionAgentOverviewSuccess = (data:any) : ITransactionAgentOverviewSuccess => ({
   type: TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_SUCCESS,
   data
})

export const transactionAgentOverviewError = (errorMsg:any) : ITransactionAgentOverviewError => ({
    type: TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_FAILD,
    error:errorMsg
})

export const transactionAgentOverviewFilter = (payload: any): ITransactionAgentOverviewFilter => ({
    type: TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_FILTER,
    payload
})

export const transactionAgentOverviewFilterDebounce = (payload: any): ITransactionAgentOverviewFiterDebounce => ({
    type: TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_FILTER_DEBOUNCE,
    payload 
})
