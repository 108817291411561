import {DeactiveScoreKPITypes} from './actionTypes';
import { IDeactiveScoreKPI, IDeactiveScoreKPIFetching, IDeactiveScoreKPISuccess, IDeactiveScoreKPIError, IDeactiveScoreKPIFilter, IDeactiveScoreKPIFiterDebounce } from "./interfaces";


export const deactiveScoreKPI = (payload:Object,
    history?: any): IDeactiveScoreKPI => ({
    type: DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI,
    payload ,
    history
})

export const deactiveScoreKPIFetching = (isFetching:boolean) : IDeactiveScoreKPIFetching => ({
    type: DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI_FETCHING,
    isFetching
})

export const deactiveScoreKPISuccess = (data:any) : IDeactiveScoreKPISuccess => ({
   type: DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI_SUCCESS,
   data
})

export const deactiveScoreKPIError = (errorMsg:any) : IDeactiveScoreKPIError => ({
    type: DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI_FAILD,
    error:errorMsg
})

export const deactiveScoreKPIFilter = (payload: any): IDeactiveScoreKPIFilter => ({
    type: DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI_FILTER,
    payload
})

export const deactiveScoreKPIFilterDebounce = (payload: any): IDeactiveScoreKPIFiterDebounce => ({
    type: DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI_FILTER_DEBOUNCE,
    payload 
})
