import { UpdatePriorityType } from './actionTypes';
import { 
    IFetchingUpdatePriority,
    IUpdatePriority,
    IUpdatePriorityError,
    IUpdatePrioritySuccess
} from './interfaces';

export const updatePrioriryIsFetching = (isFetching:boolean) : IFetchingUpdatePriority => ({
    type: UpdatePriorityType.UPDATE_PRIORITY_FETCHING,
    isFetching
})

export const updatePriority = (payload: Object) : IUpdatePriority => ({
    type: UpdatePriorityType.UPDATE_PRIORITY,
    payload
})

export const updatePrioritySuccess = (data:any) : IUpdatePrioritySuccess => ({
    type: UpdatePriorityType.UPDATE_PRIORITY_SUCCESS,
    data
})

export const updatePriorityFaild = (error:any) : IUpdatePriorityError => ({
    type: UpdatePriorityType.UPDATE_PRIORITY_FAILD,
    error
})
