import { ChangePasswordType } from './actionTypes';
import {
  IChangePasswordError,
  IChangePasswordIsFetching,
  IChangePasswordSuccess,
  IChangePassword
} from './interfaces';
export const ChangePasswordIsFetching = (isFetching:boolean): IChangePasswordIsFetching => ({
   type: ChangePasswordType.CHANGE_PASSWORD_IS_FETCHING,
   isFetching
});

export const ChangePasswordAcc = (payload:Object): IChangePassword => ({
   type: ChangePasswordType.CHANGE_PASSWORD,
   payload
}) 

export const ChangePasswordSuccess = (data:any) : IChangePasswordSuccess => ({
    type: ChangePasswordType.CHANGE_PASSWORD_SUCCESSS,
    data
})

export const ChangePassWordError = (error:any) : IChangePasswordError => ({
    type: ChangePasswordType.CHANGE_PASSWORD_ERROR,
    error
})