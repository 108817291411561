import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { handleOfferSearch } from 'src/apis/service/P2PTransaction';
import { getOfferAllAtFetching, getOfferAllFaild, getOfferAllSuccess, GetOfferAllTypes, setFilterOfferAll } from 'src/store/actions';

function* getOfferAllSaga(action:any):Generator<any> {
    yield put(getOfferAllAtFetching(true))
    const {payload} = action;
    try {
      const data:any = yield call(handleOfferSearch, payload)
      if(data && data?.status){
        yield put(getOfferAllSuccess(data))
      } else {
        yield put(getOfferAllFaild('error'))
      }
    } catch (error) {
        yield put(getOfferAllFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterOfferAll(action.payload));
}

export function* watchGetListOfferAll() {
  yield takeEvery(GetOfferAllTypes.GET_OFFER_ALL, getOfferAllSaga);
  yield debounce(500, GetOfferAllTypes.GET_LIST_FILTER_DEBOUNCE_OFFER_ALL, handleSearchDebounce)
}