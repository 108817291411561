import { RemoveExchangeOfferType } from './actionTypes';
import { IFetchingRemoveExchangeOffer, IRemoveExchangeOffer, IRemoveExchangeOfferError, IRemoveExchangeOfferSuccess } from './interfaces';


export const removeExchangeOfferIsFetching = (isFetching: boolean): IFetchingRemoveExchangeOffer => ({
    type: RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER_FETCHING,
    isFetching
})

export const removeExchangeOffer = (payload?: any): IRemoveExchangeOffer => ({
    type: RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER,
    payload
})

export const removeExchangeOfferSuccess = (data: any): IRemoveExchangeOfferSuccess => ({
    type: RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER_SUCCESS,
    data
})

export const removeExchangeOfferFaild = (error: any): IRemoveExchangeOfferError => ({
    type: RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER_ERROR,
    error
})