
interface IDataListKycStepStatusUpdate {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListKycStepStatusUpdateState {
    data: IDataListKycStepStatusUpdate;
    isFetching: boolean,
    success: boolean,
}

export const initialListKycStepStatusUpdateState: IListKycStepStatusUpdateState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    success: false
}

