import {GetListCryptoDetailTypes} from './actionTypes';
import { IGetListCryptoDetail, IGetListCryptoDetailFetching, IGetListCryptoDetailSuccess, IGetListCryptoDetailError, IGetListCryptoDetailFilter, IGetListCryptoDetailFiterDebounce } from "./interfaces";


export const getListCryptoDetail = (payload:Object): IGetListCryptoDetail => ({
    type: GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL,
    payload
})

export const getListCryptoDetailFetching = (isFetching:boolean) : IGetListCryptoDetailFetching => ({
    type: GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_FETCHING,
    isFetching
})

export const getListCryptoDetailSuccess = (data:any) : IGetListCryptoDetailSuccess => ({
   type: GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_SUCCESS,
   data
})

export const getListCryptoDetailError = (errorMsg:any) : IGetListCryptoDetailError => ({
    type: GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_FAILD,
    error:errorMsg
})

export const getListCryptoDetailFilter = (payload: any): IGetListCryptoDetailFilter => ({
    type: GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_FILTER,
    payload
})

export const getListCryptoDetailFilterDebounce = (payload: any): IGetListCryptoDetailFiterDebounce => ({
    type: GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_FILTER_DEBOUNCE,
    payload 
})
