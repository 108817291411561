import { AnyAction, Reducer } from 'redux';
import { GetListUserPriorityType } from 'src/store/actions/getUserPriority/actionTypes';
import { initialListUserPriorityState, IDataListUserPriorityState } from './state';


export const GetListUserPriorityReducer: Reducer = (
  state: IDataListUserPriorityState = initialListUserPriorityState,
  action: AnyAction
) => {
  switch (action.type) {
      case GetListUserPriorityType.GET_LIST_USER_PRIORITY: {
         return {
             ...state,
             isFetching:true
         }
      }
      case GetListUserPriorityType.GET_LIST_USER_PRIORITY_SUCCESS: {
          return {
              ...state,
              data: action.data,
              isFetching: false,
              pagination: action.data.data.totalCount
          }
      }
      case GetListUserPriorityType.GET_LIST_USER_PRIORITY_FAILD: {
        return {
            ...state,
            error: action.error,
            isFetching:false
        }
    }
    case GetListUserPriorityType.GET_LIST_FILTER: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:{
      return state
    }
  }
}