import { ListParams } from "src/models/common";

export interface IDataGetWalletProductBlockchain {
  data: {
    items: any[];
    total: number;
  };
  error: any;
  success: boolean;
}

export interface IGetWalletProductBlockchainState {
  data: IDataGetWalletProductBlockchain;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialGetWalletProductBlockchainState: IGetWalletProductBlockchainState =
  {
    data: {
      data: {
        items: [],
        total: 0,
      },
      error: null,
      success: false,
    },
    isFetching: false,
    filter: {},
    pagination: {
      totalCount: 0,
    },
  };
