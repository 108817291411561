import { PAGE_SIZE_DEFAULT } from './../../../contants/pageSize';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { put, takeLatest } from 'redux-saga/effects';
import { updateStatusAgency } from 'src/apis/service/Agency';
import { getAgencyManagement, getAgencyStatus, updateAgencyStatusFaild, updateAgencyStatusIsFetching, updateAgencyStatusReset, updateAgencyStatusSuccess, UpdateAgencyStatusType } from 'src/store/actions';

function* updateAgencyStatus(action: any): Generator<any> {


  const { payload } = action;
  yield put(updateAgencyStatusIsFetching(true))
  const data: any = yield updateStatusAgency(payload);
  const statusHistory = {
    pageNumber: 1,
    pageLimit: 1000,
    userId: payload?.userId
  }
  try {
    if (data && data?.status === 200) {
      yield put(updateAgencyStatusSuccess(data));
      // yield put(getAgencyStatus(statusHistory));
      // yield put(updateAgencyStatusReset());
      yield toastSuccess('bo_become_agent_update_status_success')
      // yield put(getAgencyManagement({
      //   pageNumber: 1,
      //   pageSize: PAGE_SIZE_DEFAULT,
      //   statusId: 0
      // }));
    } else {
      yield toastError('bo_error_label')
      yield put(updateAgencyStatusFaild('error'))
    }
  } catch (error: any) {
    yield put(updateAgencyStatusFaild(error))
    yield toastError('bo_error_label')
  }
}

export function* watchUpdateAgencyStatus() {
  yield takeLatest(UpdateAgencyStatusType.UPDATE_AGENCY_STATUS, updateAgencyStatus)
}