import { AnyAction, Reducer } from 'redux';
import { initialAllRankConfigState, IDataAllRankConfigState } from './state';
import { GetAllRankConfigType } from '../../actions/getAllRankConfig';


export const GetAllRankConfigReducer: Reducer = (
  state: IDataAllRankConfigState = initialAllRankConfigState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAllRankConfigType.GET_ALL_RANK_CONFIG: {
      return {
        ...state,
        isFetching: true
      }
    }
    case GetAllRankConfigType.GET_ALL_RANK_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.data,
        list: action.list,
        isFetching: false,
        pagination: action.data.data.totalCount
      }
    }
    case GetAllRankConfigType.GET_ALL_RANK_CONFIG_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      }
    }
    case GetAllRankConfigType.GET_FILTER_ALL_RANK_CONFIG: {
      return {
        ...state,
        filter: action.payload
      }
    }
    default: {
      return state
    }
  }
}