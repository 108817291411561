import { AnyAction, Reducer } from 'redux';
import { GetCryptoActivatedWalletListType } from 'src/store/actions';
import { IListCryptoActivatedWalletListState, initialListCryptoActivatedWalletListState } from './state';


export const GetListCryptoActivatedWalletListReducer: Reducer = (
  state: IListCryptoActivatedWalletListState = initialListCryptoActivatedWalletListState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST_SUCCESS : {
      return {
          ...state,
          data: action?.data.data.items,
          isFetching: false,
          pagination: action.data.data.totalCount
      }
    }
    case GetCryptoActivatedWalletListType.GET_LIST_FILTER_CRYPTO_ACTIVATED_WALLET_LIST: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};