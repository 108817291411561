import {PostCreateExchangeTransactionTypes} from './actionTypes';
import { IPostCreateExchangeTransaction, IPostCreateExchangeTransactionFetching, IPostCreateExchangeTransactionSuccess, IPostCreateExchangeTransactionError, IPostCreateExchangeTransactionFilter, IPostCreateExchangeTransactionFiterDebounce } from "./interfaces";


export const postCreateExchangeTransaction = (payload:Object): IPostCreateExchangeTransaction => ({
    type: PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION,
    payload
})

export const postCreateExchangeTransactionFetching = (isFetching:boolean) : IPostCreateExchangeTransactionFetching => ({
    type: PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_FETCHING,
    isFetching
})

export const postCreateExchangeTransactionSuccess = (data:any) : IPostCreateExchangeTransactionSuccess => ({
   type: PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_SUCCESS,
   data
})

export const postCreateExchangeTransactionError = (errorMsg:any) : IPostCreateExchangeTransactionError => ({
    type: PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_FAILD,
    error:errorMsg
})

export const postCreateExchangeTransactionFilter = (payload: any): IPostCreateExchangeTransactionFilter => ({
    type: PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_FILTER,
    payload
})

export const postCreateExchangeTransactionFilterDebounce = (payload: any): IPostCreateExchangeTransactionFiterDebounce => ({
    type: PostCreateExchangeTransactionTypes.POST_CREATE_EXCHANGE_TRANSACTION_FILTER_DEBOUNCE,
    payload 
})
