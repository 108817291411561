import { GetCryptoExchangeOffersListHistoriesTypes } from './actionTypes';
import { IGetCryptoExchangeOffersListHistories, IGetCryptoExchangeOffersListHistoriesFetching, IGetCryptoExchangeOffersListHistoriesSuccess, IGetCryptoExchangeOffersListHistoriesError, IGetCryptoExchangeOffersListHistoriesFilter, IGetCryptoExchangeOffersListHistoriesFiterDebounce } from "./interfaces";


export const getCryptoExchangeOffersListHistories = (offerId: any, payload: Object): IGetCryptoExchangeOffersListHistories => ({
    type: GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES,
    payload,
    offerId
})

export const getCryptoExchangeOffersListHistoriesFetching = (isFetching: boolean): IGetCryptoExchangeOffersListHistoriesFetching => ({
    type: GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_FETCHING,
    isFetching
})

export const getCryptoExchangeOffersListHistoriesSuccess = (data: any): IGetCryptoExchangeOffersListHistoriesSuccess => ({
    type: GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_SUCCESS,
    data
})

export const getCryptoExchangeOffersListHistoriesError = (errorMsg: any): IGetCryptoExchangeOffersListHistoriesError => ({
    type: GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_FAILD,
    error: errorMsg
})

export const getCryptoExchangeOffersListHistoriesFilter = (payload: any): IGetCryptoExchangeOffersListHistoriesFilter => ({
    type: GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_FILTER,
    payload
})

export const getCryptoExchangeOffersListHistoriesFilterDebounce = (payload: any): IGetCryptoExchangeOffersListHistoriesFiterDebounce => ({
    type: GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_FILTER_DEBOUNCE,
    payload
})
