import { ListParams } from "src/models/common";

interface IDataGetAllKYC {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  success:boolean;
}

export interface IGetAllKYCState {
    data:IDataGetAllKYC;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetAllKYCState: IGetAllKYCState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


