export interface EditUserScore  {
    data:any,
    errors:any,
    success:any
}

export interface EditUserScoreState {
    data:EditUserScore,
    isFetching:boolean
}

export const initialEditUserScoreState: EditUserScoreState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}