export interface CreateExchangeOffer {
    data: any,
    errors: any,
    success: any
}

export interface CreateExchangeOfferState {
    data: CreateExchangeOffer,
    isFetching: boolean,
    countSuccess: number
}

export const initialCreateExchangeOfferState: CreateExchangeOfferState = {
    data: {
        data: {},
        errors: '',
        success: false
    },
    isFetching: false,
    countSuccess: 0
}