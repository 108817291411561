import { CreatePinType } from './actionTypes';
import { ICreatePin, IFetchingCreatePin, ICreatePinSuccess, ICreatePinError } from './interfaces';


export const createPinIsFetching = (isFetching:boolean) : IFetchingCreatePin=> ({
    type: CreatePinType.CREATE_PIN_FETCHING,
    isFetching
})

export const createPin = (payload:Object) : ICreatePin => ({
    type: CreatePinType.CREATE_PIN,
    payload
})

export const createPinSuccess = (data:any) : ICreatePinSuccess => ({
    type: CreatePinType.CREATE_PIN_SUCCESS,
    data
})

export const createPinFaild = (error:any) : ICreatePinError => ({
    type: CreatePinType.CREATE_PIN_ERROR,
    error
})