import {GetKycHistoryNoteTypes} from './actionTypes';
import {
    IGetKycListHistoryNote,
    IGetKycListHistoryNoteFaild,
    IGetKycListHistoryNoteFetching,
    IGetKycListHistoryNoteSuccess,
    IGetListFiterKycListHistoryNote
} from './interfaces';

export const getKycListHistoryNoteAtFetching = (isFetching:boolean) : IGetKycListHistoryNoteFetching  => ({
    type: GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE_FETCHING,
    isFetching
})

export const getKycListHistoryNote = (payload:Object) : IGetKycListHistoryNote => ({
    type: GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE,
    payload
})

export const getKycListHistoryNoteSuccess = (data:any) : IGetKycListHistoryNoteSuccess => ({
    type: GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE_SUCCESS,
    data
})

export const getKycListHistoryNoteFaild = (error:any) : IGetKycListHistoryNoteFaild => ({
    type: GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE_FAILD,
    error
})

export const setFilterKycListHistoryNote = (payload: any): IGetListFiterKycListHistoryNote => ({
    type: GetKycHistoryNoteTypes.GET_HISTORY_NOTE_FILTER_KYC_HISTORY_NOTE,
    payload
})
