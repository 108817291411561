import {ExportCryptoSendTransactionType} from './actionTypes';
import {
    IExportCryptoSendTransactionIsFetching,
    IExportCryptoSendTransactionSuccess,
    IExportCryptoSendTransactionFaild,
    IExportCryptoSendTransaction
} from './interfaces';

export const exportCryptoSendTransactionAtFetching = (isFetching:boolean) : IExportCryptoSendTransactionIsFetching  => ({
    type: ExportCryptoSendTransactionType.EXPORT_CRYPTO_SEND_TRANSACTION_FETCHING,
    isFetching
})

export const exportCryptoSendTransaction = (payload:Object, xlsx:boolean) : IExportCryptoSendTransaction => ({
    type: ExportCryptoSendTransactionType.EXPORT_CRYPTO_SEND_TRANSACTION,
    payload,
    xlsx
})

export const exportCryptoSendTransactionSuccess = (data:any) : IExportCryptoSendTransactionSuccess => ({
    type: ExportCryptoSendTransactionType.EXPORT_CRYPTO_SEND_TRANSACTION_SUCCESS,
    data
})

export const exportCryptoSendTransactionFaild = (error:any) : IExportCryptoSendTransactionFaild => ({
    type: ExportCryptoSendTransactionType.EXPORT_CRYPTO_SEND_TRANSACTION_FAILD,
    error
})
