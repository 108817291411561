import { GetListTokenSupportReducer } from './reducers/getListTokenSupport';
import { GetUnreadCountNotificationReducer } from './reducers/getUnreadCountNotification';
import { PostCreateExchangeTransactionReducer } from './reducers/postCreateExchangeTransaction';
import { GetCryptoExchangeOffersListHistoriesReducer } from './reducers/getCryptoExchangeOffersListHistories';
import { GetUserReducer } from './reducers/getUser';
import { GenerateCode2FAReducer } from './reducers/generateCode2FA';
import { Set2FADisableReducer } from './reducers/set2FADisable';
import { Set2FAEnabledReducer } from './reducers/set2FAEnabled';
import { DeleteCryptoExchangeOffersListReducer } from './reducers/deleteCryptoExchangeOffersList';
import { GetCryptoExchangeOffersListReducer } from './reducers/getCryptoExchangeOffersList';
import { GetListAddressBooksReducer } from './reducers/getListAddressBooks';
import { GetCryptoTransactionFeeReducer } from './reducers/getCryptoTransactionFee';
import { GetCryptoTransactionHistoryReducer } from './reducers/getCryptoTransactionHistory';
import { GetTokenDetailReducer } from './reducers/getTokenDetail';
import { EditWalletCryptoReducer } from './reducers/editWalletCrypto';
import { AgentGetAllWalletsReducer } from './reducers/agentGetAllWallets';
import { AgentTransferMoneyReducer } from './reducers/agentTransferMoney';
import { AgentSellSearchReducer } from './reducers/agentSellSearch';
import { AgentBuySearchReducer } from './reducers/agentBuySearch';
import { GetAllCurrencyNoFilterReducer } from './reducers/getAllCurrencyNoFilter';
import { GetKYCStatusReducer } from './reducers/getKYCStatus';
import { GetListCryptoDetailReducer } from './reducers/getListCryptoDetail';
import { GetListCryptoWalletReducer } from './reducers/getListCryptoWallet';
import { GetTransactionsFilterStatusesReducer } from './reducers/getTransactionsFilterStatuses';
import { AgentGetAdminTrxDetailReducer } from './reducers/agentGetAdminTrxDetail';
import { AgentGetDirectDetailReducer } from './reducers/agentGetDirectDetail';
import { ApplyKycDeclarationReducer } from './reducers/ApplyKycDeclaration';
import { ApplyKYCBankInfoReducer } from './reducers/ApplyKYCBankInfo';
import { ApplyKYCDocsReducer } from './reducers/applyKYCDocs';
import { ApplyKYCAddressReducer } from './reducers/applyKYCAddress';
import { ApplyKYCUserInfoReducer } from './reducers/ApplyKYCUserInfo';
import { GetAllKYCReducer } from './reducers/getAllKYC';
import { GetAgentDirectTransfersReducer } from './reducers/getAgentDirectTransfers';
import { GetAgentCryptoSettingReducer } from './reducers/getAgentCryptoSetting';
import { AgentChangePINCodeReducer } from './reducers/agentChangePINCode';
import { GetAdminTransfersReducer } from './reducers/getAdminTransfers';
import { UserAvatarReducer } from './reducers/userAvatar';
import { TransactionOverviewReducer } from './reducers/transactionOverview';
import { GetUserWalletOverviewReducer } from './reducers/getUserWalletOverview';
import { HandleGetWalletDetailOverviewReducer } from './reducers/handleGetWalletDetailOverview';
import { GetOfferLogsReducer } from './reducers/getOfferLogs';
import { WalletInputReducer } from './reducers/walletInput';
import { SelectWalletTypeReducer } from './reducers/selectWalletType';
import { GetWalletDetailReducer } from './reducers/getWalletDetail';
import { UpdateWalletStatusReducer } from './reducers/updateWalletStatus';
import { GetListUserWalletReducer } from './reducers/getListUserWallet';
import { CreateTopupTransactionReducer } from './reducers/createTopupTransaction';
import { GetListTopupRequestReducer } from './reducers/getListTopupRequest';
import { GetWalletProductBlockchainReducer } from './reducers/getWalletProductBlockchain';
import { TopupWalletListSelectReducer } from './reducers/topupWalletListSelect';
import { GetBlockchainDashboardReducer } from './reducers/getBlockchainDashboard';
import { GetOffersReducer } from './reducers/getOffers';
import { GetOfferHistoryReducer } from './reducers/getOfferHistory';
import { GetInvoiceDetailReducer } from './reducers/getInvoiceDetail';
import { AdminGetInvoiceListReducer } from './reducers/adminGetInvoiceList';
import { CheckPasswordAdminReducer } from './reducers/checkPasswordAdmin';
import { SetUserLanguageReducer } from './reducers/setUserLanguage';
import { GetLanguageForBOReducer } from './reducers/getLanguageForBO';
import { GetAgentRankByAdminReducer } from './reducers/getAgentRankByAdmin';
import { ActiveAgentRankReducer } from './reducers/activeAgentRank';
import { GetAdminTransactionUserReducer } from './reducers/getAdminTransactionUser';
import { GetDirectTransactionDetailReducer } from './reducers/getDirectTransactionDetail';
import { GetAllTransactionsByAdminReducer } from './reducers/getAllTransactionsByAdmin';
import { combineReducers } from 'redux';
import { UpdateOfferNoteReducer } from './reducers/updateOfferNote';
import { HandleRetryTransactionReducer } from './reducers/handleRetryTransaction';
import { HandleWithdrawTransactionReducer } from './reducers/handleWithdrawTransaction';
import { GetListTransactionCryptoReducer } from './reducers/getListTransactionCrypto';
import { GetOfferDetailExchangeReducer } from './reducers/getOfferDetailExchange';
import { HandleDeleteOfferReducer } from './reducers/handleDeleteOffer';
import { HandleBlockOfferReducer } from './reducers/handleBlockOffer';
import {
  blockAndUnBlockP2PExchangeOfferReducer,
  cancelP2PExchangeOfferReducer,
} from './reducers/blockOrUnBlockAndCancelP2PExchangeOffer';
import {
  DeleteCurrencyReducer,
  ListCurrencyReducer,
} from './reducers/currency';
import { CreateAndEditCurrencyReducer } from './reducers/currency/createAndEdit';
import { GetListKycListHistoryReducer } from './reducers/kycHistory/reducer';
import { noteKycReducer } from './reducers/noteKyc/reducer';
import { sendNoteP2PExchangeOffersReducer } from './reducers/P2PExchangeOfferNote';
import { GetListTransactionByOfferReducer } from './reducers/TransactionByOffer/reducer';
import { TransactionHistoryLogReducer } from './reducers/transactionHistoryStatusLog/reducer';
import { IApplicationState } from './IApplicationState';
import {
  CreateAnnouncementsReducer,
  CreateListAnnouncementsCategoryReducer,
  ExportCryptoReceiveTransactionReducer,
  ExportCryptoReducer,
  ExportCryptoSendTransactionReducer,
  ExportDirectTransferReducer,
  ExportExchangeReducer,
  ExportOfferSearchReducer,
  GetAdminOrAgentTransfersReducer,
  GetDashboardReducer,
  GetDirectTransfersReducer,
  GetListAnnouncementsCategoryReducer,
  GetListAnnouncementsReducer,
  GetListCountryReducer,
  GetListCryptoActivatedWalletListReducer,
  GetListCryptoWalletGetOverviewReducer,
  GetListCryptoWalletGetNewOverviewReducer,
  GetListCryptoWalletOverviewStatisticalReducer,
  GetListCryptoWalletReceiveHistoryReducer,
  GetListCryptoWalletReceiveStatisticalReducer,
  GetListCryptoWalletSendHistoryReducer,
  GetListCryptoWalletSendStatisticalReducer,
  GetListCurrencyReducer,
  GetListKycDetailReducer,
  GetListKycListByStageReducer,
  GetListKycRiskManagementReducer,
  GetListKycSettingUpdateReducer,
  GetListKycStageUpdateReducer,
  GetListKycStepStatusUpdateReducer,
  GetListLanguageReducer,
  GetListManualPaymentHistoryReducer,
  GetListOfferHistoryStatusReducer,
  GetListP2PBuyTransactionsReducer,
  GetListP2PExchangeOfferDetailReducer,
  GetListP2PExchangeOffersReducer,
  GetListP2PExchangeTransactionsAgentReducer,
  GetListP2PExchangeTransactionsHistoryReducer,
  GetListP2PExchangeTransactionsReducer,
  GetListP2PSellTransactionsReducer,
  GetListWalletsUserReducer,
  GetOfferAllReducer,
  GetOfferSellTransactionReducer,
  InformationPhoneUserReducer,
  RemoveLanguageTextReducer,
  RemoveOfferAllReducer,
  RemoveOfferBuyTransactionReducer,
  RemoveOfferSellTransactionReducer,
  UpdateAnnouncementsReducer,
  UpdateListAnnouncementsCategoryReducer,
  cryptoWalletDashboardReducer,
  SupportedCryptoCurrenciesReducer,
  cryptoWalletTransactionsReducer,
  DeleteAnnouncementsCategoryReducer,
  DeleteAnnouncementsReducer,
} from './reducers';
import { ActiveScoreKPIReducer } from './reducers/activeScoreKPI';
import { AddBankAccReducer } from './reducers/addUserBankAccount';
import { CreateAndEditAdminBOReducer } from './reducers/admin-managements/createAndeditAdminBO/reducer';
import { GetListAllAdminBOReducer } from './reducers/admin-managements/getAllAdminBO/reducer';
import { RemoveAdminReducer } from './reducers/admin-managements/removeAdminBO/reducer';
import { AdminP2PSetAgentScoreReducer } from './reducers/adminP2PSetAgentScore';
import {
  GetListAgentRankReducer,
  RemoveAgentRankReducer,
  SetOrAddAgentRankReducer,
} from './reducers/Agent';
import { AgentCancelTransactionReducer } from './reducers/agentCancelTransaction';
import { AgentSubmitBankReducer } from './reducers/agentSubmitBank';
import { AgentTransactionConfirmReducer } from './reducers/agentTransactionConfirm';
import { AgentTransferredTransactionReducer } from './reducers/agentTransferredTransaction';
import { SetMainPostAnnouncementsReducer } from './reducers/Announcement/Announcements/SetMainPost/reducer';
import { UnPostAndPostAnnouncementsReducer } from './reducers/Announcement/Announcements/SetUnPostAndPost';
import {
  AddBankAccAgentReducer,
  GetDataBankAccountAgentReducer,
  RemoveUserBankAccAgentReducer,
} from './reducers/bankAccountByAgent';
import { ChangePasswordReducer } from './reducers/ChangePassword';
import { ChangeWalletUserBalanceReducer } from './reducers/changeWalletUserBalance';
import { CheckPhoneReducer } from './reducers/checkPhoneBeforeLogin';
import { ChoosePriorityReducer } from './reducers/choosePriority';
import { CreateAdminReducer } from './reducers/createAdmin';
import { CreateExchangeOfferReducer } from './reducers/createExchangeOffer';
import { CreateLanguageTextReducer } from './reducers/createLanguageText';
import { CreateNotificationReducer } from './reducers/createNotification';
import { CreateOrUpdateAdminFunctionReducer } from './reducers/createOrUpdateAdminFunction';
import { CreatePinReducer } from './reducers/createPIN';
import { CreateRankPeriodConfigReducer } from './reducers/createRankPeriodConfig';
import { CreateScoreKPIReducer } from './reducers/createScoreKPI';
import { CreateScorePeriodConfigReducer } from './reducers/createScorePeriodConfig';
import { GetListCryptoWalletGetOverviewTransactionCryptoReducer } from './reducers/CryptoWalletGetOverviewTransactionCrypto';
import { ActiveCurrencyReducer } from './reducers/currency/activeCurrency';
import { DeactivateUserReducer } from './reducers/deactivateUser';
import { DeactiveScoreKPIReducer } from './reducers/deactiveScoreKPI';
import { DeleteOfferByAgentReducer } from './reducers/deleteOfferByAgent';
import { DeleteScoreKPIReducer } from './reducers/deleteScoreKPI';
import { EditNotificationReducer } from './reducers/editNotification';
import { EditUserBankInfoReducer } from './reducers/editUserBankInfo';
import { EditUserByAdminReducer } from './reducers/editUserByAdmin';
import { EditUserDetailReducer } from './reducers/editUserDetail';
import { EditUserPriorityReducer } from './reducers/editUserPriority';
import { EditUserScoreReducer } from './reducers/editUserScore';
import { ExportFileReducer } from './reducers/exportFile';
import { GetListAgencyReducer } from './reducers/getAgencyManagement';
import { GetListAgencyStatusReducer } from './reducers/getAgencyStatus';
import { GetAllRankConfigReducer } from './reducers/getAllRankConfig';
import { GetAllRankPeriodReducer } from './reducers/getAllRankPeriod';
import { GetAllScoreKPIReducer } from './reducers/getAllScoreKPI';
import { GetAllScorePeriodConfigReducer } from './reducers/getAllScorePeriodConfig';
import { GetDataBankAccountUserReducer } from './reducers/getDataBankAccount';
import { GetFileByIdReducer } from './reducers/getFileById';
import { GetHistoryDeactivateUserReducer } from './reducers/getHistoryDeactivateUser';
import { GetKYCSystemStatusReducer } from './reducers/getKYCSystemStatus';
import { GetKYCSystemStatusHistoryReducer } from './reducers/getKYCSystemStatusHistory';
import { GetListAdminFunctionReducer } from './reducers/getListAdminFunction';
import { GetListLanguageTextReducer } from './reducers/getListLanguageText';
import { GetListUserReducer } from './reducers/getListUser';
import { GetListUserDeactivatedReducer } from './reducers/getListUserDeactivated';
import { GetListUserScoreReducer } from './reducers/getListUserScore';
import { GetListWalletReducer } from './reducers/getListWallet';
import { GetListWalletsForUserReducer } from './reducers/getListWalletsForUser';
import { GetNavbarForAdminReducer } from './reducers/getNavbarForAdmin';
import { GetListNotificationReducer } from './reducers/getNotifications';
import { GetOfferTransactionReducer } from './reducers/getOfferTransaction/reducer';
import { GetP2PExchangeHistoryAgentReducer } from './reducers/getP2PExchangeHistoryAgent';
import { GetP2PExRankAndScoreReducer } from './reducers/getP2PExRankAndScore';
import { GetRoleFunctionByAdminIdReducer } from './reducers/getRoleFunctionByAdminId';
import { GetScoreHistoryReducer } from './reducers/getScoreHistory';
import { GetListTransactionReducer } from './reducers/getTransaction';
import { GetTransactionDetailByAgentReducer } from './reducers/getTransactionDetailByAgent';
import { GetTransactionsFilterTypesReducer } from './reducers/getTransactionsFilterTypes';
import { GetTranscationsByAgentReducer } from './reducers/getTranscationsByAgent';
import { GetListUserBalanceHistoryReducer } from './reducers/getUserBalanceHistory/reducer';
import { GetUserDetailReducer } from './reducers/getUserDetail';
import { GetUserKYCPriorityHistoryReducer } from './reducers/getUserKYCPriorityHistory';
import { GetListUserPriorityReducer } from './reducers/getUserPriority';
import { GetListVenlyWalletsUserReducer } from './reducers/getVenlyWalletsUser';
import { HandleNotiRefreshDataReducer } from './reducers/handleNotiRefreshData';
import { GetListKycListReducer } from './reducers/kyc/reducer';
import { GetListKycListHistoryNoteReducer } from './reducers/kycHistoryNote';
import { LogginReducer } from './reducers/login';
import { LogginAgentReducer } from './reducers/loginAgent';
import { CreateOfferSellOrBuyReducer } from './reducers/myOfferFromAgent/createOfferBuyOrSell';
import { EditOfferSellOrBuyReducer } from './reducers/myOfferFromAgent/editOfferBuyOrSell';
import { GetOfferDetailReducer } from './reducers/myOfferFromAgent/offerDetail';
import { GetListNotificationsFirebaseReducer } from './reducers/notificationFromFirebase/getAllNotifications/reducer';
import { GetListP2PExchangeOffersHistoryReducer } from './reducers/P2PExchangeOfferHistory';
import { GetListP2PExchangeOffersAgentReducer } from './reducers/P2PExchangeOffersAgent';
import { PriorityReducer, UpdatePriorityReducer } from './reducers/priority';
import { ReactiveUserReducer } from './reducers/reactiveUser';
import { RemoveExchangeOfferReducer } from './reducers/removeExchangeOffer';
import { RemoveNotificationReducer } from './reducers/removeNotification';
import { RemoveUserBankAccReducer } from './reducers/removeUserBankAccount';
import { resendFeaturedNotificationReducer } from './reducers/reSendFeatureNotification';
import { ResetPinReducer } from './reducers/resetPIN';
import { SetKYCPriorityAllUserReducer } from './reducers/setKYCPriorityAllUser';
import { SetUserKYCPriorityReducer } from './reducers/setUserKYCPriority';
import { ListTestReducer } from './reducers/testReducer';
import { TransactionAgentOverviewReducer } from './reducers/transactionAgentOverview';
import { TransactionManualPaymentReducer } from './reducers/transactionManualPayment';
import { TransactionManualPaymentHistoryReducer } from './reducers/transactionManualPaymentHistory';
import { UnLockUserReducer } from './reducers/unLockUser';
import { GetListAgencyStatusUpdateReducer } from './reducers/updateAgencyStatus';
import { UpdateExchangeOfferReducer } from './reducers/updateExchangeOffer';
import { UpdateLanguageTextReducer } from './reducers/updateLanguageText';
import { UpdateScoreKPIReducer } from './reducers/updateScoreKPI';
import { VerifyPINByAgentReducer } from './reducers/verifyPINByAgent';
import { WalletRefillReducer } from './reducers/wallet-refill';
import { CreateWalletAdminBOReducer } from './reducers/WalletAdminBO/createWalletAdminBO/reducer';
import { RemoveWalletAdminReducer } from './reducers/WalletAdminBO/deleteWalletAdminBO/reducer';
import { GetListAllWalletSuperAdminBOReducer } from './reducers/WalletAdminBO/getAllWalletSuperAdminBO';
import { GetListAllWalletAdminBOReducer } from './reducers/WalletAdminBO/getAllWalletAdminBO';
import { GetListAdminRolesReducer } from './reducers/getListAdminRoles';
import { CreateCryptoExchangeOffersListReducer } from './reducers/createCryptoExchangeOffersList';
import { UpdateCryptoExchangeOffersListReducer } from './reducers/updateCryptoExchangeOffersList';
import { PostCryptoCreateExchangeTransactionReducer } from './reducers/postCryptoCreateExchangeTransaction';
import { GetKYCFunctionByIdReducer } from './reducers/getKYCFunctionById';

const rootReducer = combineReducers<IApplicationState>({
  checkPhone: CheckPhoneReducer,
  login: LogginReducer,
  bankAccount: GetDataBankAccountUserReducer,
  dataChangePassword: ChangePasswordReducer,
  listUserScore: GetListUserScoreReducer,
  editUserScore: EditUserScoreReducer,
  listTransaction: GetListTransactionReducer,
  listUserPriority: GetListUserPriorityReducer,
  editUserPriority: EditUserPriorityReducer,
  dataAddBankAcc: AddBankAccReducer,
  removeUserBankAccount: RemoveUserBankAccReducer,
  choosePriority: ChoosePriorityReducer,
  listUser: GetListUserReducer,
  userDetail: GetUserDetailReducer,
  editUserDetail: EditUserDetailReducer,
  listWallet: GetListWalletReducer,
  changeWalletUserBalance: ChangeWalletUserBalanceReducer,
  listAgency: GetListAgencyReducer,
  listAgencyStatus: GetListAgencyStatusReducer,
  editAgencyStatus: GetListAgencyStatusUpdateReducer,
  createPin: CreatePinReducer,
  reactiveUser: ReactiveUserReducer,
  resetPin: ResetPinReducer,
  unLockUser: UnLockUserReducer,
  deactivateUser: DeactivateUserReducer,
  createAdmin: CreateAdminReducer,
  listLaguageText: GetListLanguageTextReducer,
  updateLanguageText: UpdateLanguageTextReducer,
  createLanguageText: CreateLanguageTextReducer,
  listAnnouncements: GetListAnnouncementsReducer,
  listCreateAnnouncements: CreateAnnouncementsReducer,
  listUpdateAnnouncements: UpdateAnnouncementsReducer,
  listCountry: GetListCountryReducer,
  listLanguage: GetListLanguageReducer,
  listCreateAnnouncementsCategory: CreateListAnnouncementsCategoryReducer,
  listAnnouncementsCategory: GetListAnnouncementsCategoryReducer,
  listUpdateAnnouncementsCategory: UpdateListAnnouncementsCategoryReducer,
  listNotification: GetListNotificationReducer,
  editNotification: EditNotificationReducer,
  createNotification: CreateNotificationReducer,
  removeNotification: RemoveNotificationReducer,
  deleteAnnouncements: DeleteAnnouncementsReducer,
  deleteAnnouncementsCategory: DeleteAnnouncementsCategoryReducer,
  walletRefill: WalletRefillReducer,
  p2pBuyTransaction: GetListP2PBuyTransactionsReducer,
  manualPaymentHistory: GetListManualPaymentHistoryReducer,
  exportFile: ExportFileReducer,
  exportOfferSearch: ExportOfferSearchReducer,
  exportCrypto: ExportCryptoReducer,
  exportCryptoSendTransaction: ExportCryptoSendTransactionReducer,
  exportCryptoReceiveTransaction: ExportCryptoReceiveTransactionReducer,
  exportDirectTransfer: ExportDirectTransferReducer,
  exportExchange: ExportExchangeReducer,
  transactionManualPaymentHistory: TransactionManualPaymentHistoryReducer,
  transactionManualPayment: TransactionManualPaymentReducer,
  listOfferHistoryStatus: GetListOfferHistoryStatusReducer,
  listCurrency: GetListCurrencyReducer,
  removeLanguageText: RemoveLanguageTextReducer,
  offerTransaction: GetOfferTransactionReducer,
  offerSellTransaction: GetOfferSellTransactionReducer,
  informationPhoneUser: InformationPhoneUserReducer,
  p2pSellTransaction: GetListP2PSellTransactionsReducer,
  removeOfferSellTransaction: RemoveOfferSellTransactionReducer,
  removeOfferBuyTransaction: RemoveOfferBuyTransactionReducer,
  listNotificationFirebase: GetListNotificationsFirebaseReducer,
  listTransactionHistoryLog: TransactionHistoryLogReducer,
  adminOrAgentTransfers: GetAdminOrAgentTransfersReducer,
  listTransactionByOffer: GetListTransactionByOfferReducer,
  directTransfers: GetDirectTransfersReducer,
  kycList: GetListKycListReducer,
  kycListByStage: GetListKycListByStageReducer,
  kycRiskManagement: GetListKycRiskManagementReducer,
  kycSetting: GetListKycSettingUpdateReducer,
  kycDetail: GetListKycDetailReducer,
  kycUpdateStage: GetListKycStageUpdateReducer,
  kycUpdateStepStatus: GetListKycStepStatusUpdateReducer,
  kycHistory: GetListKycListHistoryReducer,
  noteKyc: noteKycReducer,
  createAndEditCurrency: CreateAndEditCurrencyReducer,
  deleteCurrency: DeleteCurrencyReducer,
  listCurrencyBO: ListCurrencyReducer,
  listP2PExchangeOffers: GetListP2PExchangeOffersReducer,
  listP2PExchangeTransactions: GetListP2PExchangeTransactionsReducer,
  listP2PExchangeTransactionsAgent: GetListP2PExchangeTransactionsAgentReducer,
  p2pExchangeOfferDetail: GetListP2PExchangeOfferDetailReducer,
  p2pExchangeOfferAgent: GetListP2PExchangeOffersAgentReducer,
  sendNoteP2PExchangeOffer: sendNoteP2PExchangeOffersReducer,
  blockOrUnBlockOffer: blockAndUnBlockP2PExchangeOfferReducer,
  cancelOffer: cancelP2PExchangeOfferReducer,
  listUserBalanceHistory: GetListUserBalanceHistoryReducer,
  UnPostOrPost: UnPostAndPostAnnouncementsReducer,
  mainPost: SetMainPostAnnouncementsReducer,
  listAllAdmin: GetListAllAdminBOReducer,
  createAndeditAdminBO: CreateAndEditAdminBOReducer,
  removeAdminBO: RemoveAdminReducer,
  createWalletAdminBO: CreateWalletAdminBOReducer,
  listAllWalletAdmin: GetListAllWalletSuperAdminBOReducer,
  listAllWalletNormalAdmin: GetListAllWalletAdminBOReducer,
  removeWalletAdminBO: RemoveWalletAdminReducer,
  listP2pExchangeOfferHistory: GetListP2PExchangeOffersHistoryReducer,
  resendFeaturedNotification: resendFeaturedNotificationReducer,
  listP2PExchangeTransactionsHistory:
    GetListP2PExchangeTransactionsHistoryReducer,
  priority: PriorityReducer,
  updatePriority: UpdatePriorityReducer,
  listAgentRank: GetListAgentRankReducer,
  setAgentRank: SetOrAddAgentRankReducer,
  removeAgentRank: RemoveAgentRankReducer,
  listCryptoWalletSendHistory: GetListCryptoWalletSendHistoryReducer,
  listCryptoWalletReceiveHistory: GetListCryptoWalletReceiveHistoryReducer,
  listCryptoWalletReceiveStatistical:
    GetListCryptoWalletReceiveStatisticalReducer,
  listCryptoWalletOverviewStatistical:
    GetListCryptoWalletOverviewStatisticalReducer,
  listCryptoWalletSendStatistical: GetListCryptoWalletSendStatisticalReducer,
  createOfferBuyOrSell: CreateOfferSellOrBuyReducer,
  editOfferAgent: EditOfferSellOrBuyReducer,
  listCryptoActivatedWallet: GetListCryptoActivatedWalletListReducer,
  listCryptoWalletOverview: GetListCryptoWalletGetOverviewReducer,
  listCryptoWalletNewOverview: GetListCryptoWalletGetNewOverviewReducer,
  cryptoWalletDashboard: cryptoWalletDashboardReducer,
  listCryptoWalletOverviewTransactionCrypto:
    GetListCryptoWalletGetOverviewTransactionCryptoReducer,
  listBankAccountAgent: GetDataBankAccountAgentReducer,
  addOrEditBankAccountAgent: AddBankAccAgentReducer,
  removeBankAccountAgent: RemoveUserBankAccAgentReducer,
  listUserDeactivated: GetListUserDeactivatedReducer,
  listWalletsUser: GetListWalletsUserReducer,
  listVenlyWalletsUser: GetListVenlyWalletsUserReducer,
  createExchangeOffer: CreateExchangeOfferReducer,
  removeExchangeOffer: RemoveExchangeOfferReducer,
  updateExchangeOffer: UpdateExchangeOfferReducer,
  offerDetail: GetOfferDetailReducer,
  editUserByAdmin: EditUserByAdminReducer,
  loginAgent: LogginAgentReducer,
  dashboard: GetDashboardReducer,
  kycHistoryNote: GetListKycListHistoryNoteReducer,
  offerAll: GetOfferAllReducer,
  removeOfferAll: RemoveOfferAllReducer,
  activeCurrency: ActiveCurrencyReducer,
  listTest: ListTestReducer,
  getP2PExRankAndScore: GetP2PExRankAndScoreReducer,
  getAllRankConfig: GetAllRankConfigReducer,
  getScoreHistory: GetScoreHistoryReducer,
  adminP2PSetAgentScore: AdminP2PSetAgentScoreReducer,
  setUserKYCPriority: SetUserKYCPriorityReducer,
  setKYCPriorityAllUser: SetKYCPriorityAllUserReducer,
  getListAdminFunction: GetListAdminFunctionReducer,
  getListAdminRoles: GetListAdminRolesReducer,
  createOrUpdateAdminFunction: CreateOrUpdateAdminFunctionReducer,
  getRoleFunctionByAdminId: GetRoleFunctionByAdminIdReducer,
  getNavbarForAdmin: GetNavbarForAdminReducer,
  getUserKYCPriorityHistory: GetUserKYCPriorityHistoryReducer,
  getKYCSystemStatus: GetKYCSystemStatusReducer,
  getKYCSystemStatusHistory: GetKYCSystemStatusHistoryReducer,
  createRankPeriodConfig: CreateRankPeriodConfigReducer,
  getAllRankPeriod: GetAllRankPeriodReducer,
  createScoreKPI: CreateScoreKPIReducer,
  updateScoreKPI: UpdateScoreKPIReducer,
  deleteScoreKPI: DeleteScoreKPIReducer,
  deactiveScoreKPI: DeactiveScoreKPIReducer,
  activeScoreKPI: ActiveScoreKPIReducer,
  getAllScoreKPI: GetAllScoreKPIReducer,
  getAllScorePeriodConfig: GetAllScorePeriodConfigReducer,
  createScorePeriodConfig: CreateScorePeriodConfigReducer,
  transactionAgentOverview: TransactionAgentOverviewReducer,
  deleteOfferByAgent: DeleteOfferByAgentReducer,
  getP2PExchangeHistoryAgent: GetP2PExchangeHistoryAgentReducer,
  getTranscationsByAgent: GetTranscationsByAgentReducer,
  agentCancelTransaction: AgentCancelTransactionReducer,
  getTransactionDetailByAgent: GetTransactionDetailByAgentReducer,
  verifyPINByAgent: VerifyPINByAgentReducer,
  agentTransferredTransaction: AgentTransferredTransactionReducer,
  getListWalletsForUser: GetListWalletsForUserReducer,
  editUserBankInfo: EditUserBankInfoReducer,
  getFileById: GetFileByIdReducer,
  getHistoryDeactivateUser: GetHistoryDeactivateUserReducer,
  agentSubmitBank: AgentSubmitBankReducer,
  agentTransactionConfirm: AgentTransactionConfirmReducer,
  getTransactionsFilterTypes: GetTransactionsFilterTypesReducer,
  handleNotiRefreshData: HandleNotiRefreshDataReducer,
  getAllTransactionsByAdmin: GetAllTransactionsByAdminReducer,
  getDirectTransactionDetail: GetDirectTransactionDetailReducer,
  getAdminTransactionUser: GetAdminTransactionUserReducer,
  activeAgentRank: ActiveAgentRankReducer,
  getAgentRankByAdmin: GetAgentRankByAdminReducer,
  getLanguageForBO: GetLanguageForBOReducer,
  setUserLanguage: SetUserLanguageReducer,
  checkPasswordAdmin: CheckPasswordAdminReducer,
  adminGetInvoiceList: AdminGetInvoiceListReducer,
  getInvoiceDetail: GetInvoiceDetailReducer,
  supportedCryptoCurrencies: SupportedCryptoCurrenciesReducer,
  cryptoWalletTransactions: cryptoWalletTransactionsReducer,
  getOfferHistory: GetOfferHistoryReducer,
  getOffers: GetOffersReducer,
  getBlockchainDashboard: GetBlockchainDashboardReducer,
  topupWalletListSelect: TopupWalletListSelectReducer,
  getWalletProductBlockchain: GetWalletProductBlockchainReducer,
  getListTopupRequest: GetListTopupRequestReducer,
  createTopupTransaction: CreateTopupTransactionReducer,
  selectWalletType: SelectWalletTypeReducer,
  walletInput: WalletInputReducer,
  getListUserWallet: GetListUserWalletReducer,
  updateWalletStatus: UpdateWalletStatusReducer,
  getWalletDetail: GetWalletDetailReducer,
  handleBlockOffer: HandleBlockOfferReducer,
  handleDeleteOffer: HandleDeleteOfferReducer,
  getOfferDetailExchange: GetOfferDetailExchangeReducer,
  getListTransactionCrypto: GetListTransactionCryptoReducer,
  handleWithdrawTransaction: HandleWithdrawTransactionReducer,
  handleRetryTransaction: HandleRetryTransactionReducer,
  updateOfferNote: UpdateOfferNoteReducer,
  handleGetWalletDetailOverview: HandleGetWalletDetailOverviewReducer,
  getUserWalletOverview: GetUserWalletOverviewReducer,
  transactionOverview: TransactionOverviewReducer,
  getOfferLogs: GetOfferLogsReducer,
  userAvatar: UserAvatarReducer,
  getAdminTransfers: GetAdminTransfersReducer,
  agentChangePINCode: AgentChangePINCodeReducer,
  getAgentCryptoSetting: GetAgentCryptoSettingReducer,
  getAgentDirectTransfers: GetAgentDirectTransfersReducer,
  getListCryptoWallet: GetListCryptoWalletReducer,
  getListCryptoDetail: GetListCryptoDetailReducer,
  getKYCStatus: GetKYCStatusReducer,
  getAllCurrencyNoFilter: GetAllCurrencyNoFilterReducer,
  getAllKYC: GetAllKYCReducer,
  applyKYCUserInfo: ApplyKYCUserInfoReducer,
  applyKYCAddress: ApplyKYCAddressReducer,
  applyKYCDocs: ApplyKYCDocsReducer,
  ApplyKYCBankInfo: ApplyKYCBankInfoReducer,
  ApplyKycDeclaration: ApplyKycDeclarationReducer,
  agentGetDirectDetail: AgentGetDirectDetailReducer,
  agentGetAdminTrxDetail: AgentGetAdminTrxDetailReducer,
  getTransactionsFilterStatuses: GetTransactionsFilterStatusesReducer,
  agentBuySearch: AgentBuySearchReducer,
  agentSellSearch: AgentSellSearchReducer,
  agentTransferMoney: AgentTransferMoneyReducer,
  agentGetAllWallets: AgentGetAllWalletsReducer,
  getCryptoExchangeOffersList: GetCryptoExchangeOffersListReducer,
  createCryptoExchangeOffersList: CreateCryptoExchangeOffersListReducer,
  updateCryptoExchangeOffersList: UpdateCryptoExchangeOffersListReducer,
  editWalletCrypto: EditWalletCryptoReducer,
  getTokenDetail: GetTokenDetailReducer,
  getCryptoTransactionHistory: GetCryptoTransactionHistoryReducer,
  deleteCryptoExchangeOffersList: DeleteCryptoExchangeOffersListReducer,
  getCryptoTransactionFee: GetCryptoTransactionFeeReducer,
  getListAddressBooks: GetListAddressBooksReducer,
  set2FAEnabled: Set2FAEnabledReducer,
  set2FADisable: Set2FADisableReducer,
  generateCode2FA: GenerateCode2FAReducer,
  getUser: GetUserReducer,
  getCryptoExchangeOffersListHistories:
    GetCryptoExchangeOffersListHistoriesReducer,
  postCreateExchangeTransaction: PostCreateExchangeTransactionReducer,
  postCryptoCreateExchangeTransaction:
    PostCryptoCreateExchangeTransactionReducer,
  getUnreadCountNotification: GetUnreadCountNotificationReducer,
  getListTokenSupport: GetListTokenSupportReducer,
  getKYCFunctionById: GetKYCFunctionByIdReducer,
});

export default rootReducer;
