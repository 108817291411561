import { debounce, put, takeEvery } from 'redux-saga/effects';
import { TopupWalletListSelectTypes } from '../../actions/topupWalletListSelect';
import { topupWalletListSelectFetching, topupWalletListSelectFilter, topupWalletListSelectSuccess } from '../../actions/topupWalletListSelect/action';

function* topupWalletListSelectSaga(action:any):Generator<any> {
    yield put(topupWalletListSelectFetching(true))
    const {payload} = action;
    yield put(topupWalletListSelectSuccess(payload))
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(topupWalletListSelectFilter(action.payload));
}
export function* watchTopupWalletListSelect() {
   yield takeEvery(TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT, topupWalletListSelectSaga)
   yield debounce(500,TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT_FILTER_DEBOUNCE,handleSearchDebounce)
}
