export const AGENCY_STATUS = [
  {
    id: 0,
    label: 'bo_become_agent_status_under_review',
    value: 0,
    background: '#F9C229',
    color: '#495057',
    labelButton: 'Move to review',
    buttonColor: 'primary',
    statusId: 0,
    selectColor: 'bg-info',
  },
  {
    id: 1,
    label: 'bo_approved_status',
    value: 1,
    background: '#58D69B',
    color: '#fff',
    labelButton: 'Approve',
    buttonColor: 'error',
    statusId: 1,
    selectColor: 'bg-success'
  },
  {
    id: 2,
    label: 'bo_status_declined',
    value: 2,
    background: '#F66C6C',
    color: '#fff',
    labelButton: 'Decline',
    buttonColor: 'secondary',
    statusId: 2,
    selectColor: 'bg-danger'
  },
]