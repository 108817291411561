interface ICreateWalletAdminBO {
    data: any;
    status: number;
    message: string;
}

export interface ICreateWalletAdminBOState {
    data: ICreateWalletAdminBO;
    isFetching: boolean,
    countSuccess: number
}

export const initialCreateWalletAdminBOState: ICreateWalletAdminBOState = {
    data: {
        data: false,
        status: 0,
        message: ''
    },
    isFetching: false,
    countSuccess: 0
}