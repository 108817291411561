export interface UpdateLanguageText {
    data: any,
    errors: any
}

export interface UpdateLanguageTextState {
    data: UpdateLanguageText,
    isFetching: boolean,
    success: any
}

export const initialUpdateLanguageTextState: UpdateLanguageTextState = {
    data: {
        data: {},
        errors: ''
    },
    isFetching: false,
    success: false
}