import {UpdateWalletStatusTypes} from './actionTypes';
import { IUpdateWalletStatus, IUpdateWalletStatusFetching, IUpdateWalletStatusSuccess, IUpdateWalletStatusError, IUpdateWalletStatusFilter, IUpdateWalletStatusFiterDebounce } from "./interfaces";


export const updateWalletStatus = (payload:Object): IUpdateWalletStatus => ({
    type: UpdateWalletStatusTypes.UPDATE_WALLET_STATUS,
    payload
})

export const updateWalletStatusFetching = (isFetching:boolean) : IUpdateWalletStatusFetching => ({
    type: UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_FETCHING,
    isFetching
})

export const updateWalletStatusSuccess = (data:any) : IUpdateWalletStatusSuccess => ({
   type: UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_SUCCESS,
   data
})

export const updateWalletStatusError = (errorMsg:any) : IUpdateWalletStatusError => ({
    type: UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_FAILD,
    error:errorMsg
})

export const updateWalletStatusFilter = (payload: any): IUpdateWalletStatusFilter => ({
    type: UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_FILTER,
    payload
})

export const updateWalletStatusFilterDebounce = (payload: any): IUpdateWalletStatusFiterDebounce => ({
    type: UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_FILTER_DEBOUNCE,
    payload 
})
