import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetOfferLogsTypes} from '../../actions/getOfferLogs';
import {getOfferLogsFetching,getOfferLogsSuccess,getOfferLogsError, getOfferLogsFilter} from '../../actions/getOfferLogs/action';
import { getOfferLogs } from 'src/apis/service/CryptoExchange';

function* getOfferLogsSaga(action:any):Generator<any> {
    yield put(getOfferLogsFetching(true))
    const {payload} = action;
    try {
      const data:any = yield getOfferLogs(payload)
      if(data && data?.status === 200){
        yield put(getOfferLogsSuccess(data))
      } else {
        yield put(getOfferLogsError('error'))
      }
    } catch (error) {
        yield put(getOfferLogsError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getOfferLogsFilter(action.payload));
}
export function* watchGetOfferLogs() {
   yield takeEvery(GetOfferLogsTypes.GET_OFFER_LOGS, getOfferLogsSaga)
   yield debounce(500,GetOfferLogsTypes.GET_OFFER_LOGS_FILTER_DEBOUNCE,handleSearchDebounce)
}
