import { put, takeEvery, select } from 'redux-saga/effects';
import {
  addBankAccIsFetching,
  addBankAccSuccess,
  addBankAccFaild,
  AddUserBankAccountType,
} from '../../actions/addUserBankAccount';
import { toastSuccess, toastError } from 'src/helpers/toastHelper';
import { handleAddUserBankAccount, handleUpdateUserBankAccount, handleGetAllDataBankAccUser } from 'src/apis/service/BankAccount';
import { getDataBankAccount } from 'src/store/actions/getDataBankAccount/action';
import { IApplicationState } from 'src/store/IApplicationState';

function* handleAddUserBankAccSaga(action: any): Generator<any> {


  yield put(addBankAccIsFetching(true));
  const { payload, isEdit, history , userId } = action;
  const dataAddBankAcc: any = isEdit
    ? yield handleUpdateUserBankAccount(payload)
    : yield handleAddUserBankAccount(payload);
  try {
    if (dataAddBankAcc) {
      if (dataAddBankAcc.status === 200) {
        const params : any = yield select((state:IApplicationState) => state.bankAccount.filter) 
        yield put(addBankAccSuccess(dataAddBankAcc));
        if (isEdit) {
          toastSuccess('bo_bank_account_updated');
        } else {
          toastSuccess('bo_bank_account_updated');
        }
        if (Boolean(userId)) {
          const data = {
            ...params,
            userId
          }
          yield put(getDataBankAccount(data));
          return;
        }
        yield put(getDataBankAccount(params));
        yield put(history.push('/dashboard/settings/user-bank-accounts'));
      } else {
        toastError(dataAddBankAcc.message);
        yield put(history.push('/dashboard/settings/user-bank-accounts'));
      }
    }
  } catch (error) {
    yield put(addBankAccFaild(error));
  }
}
export function* watchAddBankAcc() {
  yield takeEvery(AddUserBankAccountType.ADD_USER_BANK_ACOOUNT, handleAddUserBankAccSaga);
}
