import {put, takeEvery, debounce} from 'redux-saga/effects';
import { handleGetListNotification} from 'src/apis/service/Notifications';
import { getNotificationsIsFetching, getNotificationsSuccess, getNotificationsFaild, setSearchNotification } from 'src/store/actions/getNotifications/action';
import { GetNotificationsType } from 'src/store/actions/getNotifications/actionTypes';

function* getListNotificationSaga(action: any): Generator<any> {
    yield put(getNotificationsIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield handleGetListNotification(payload) 
      if(data.status === 200){
        yield put(getNotificationsSuccess(data));
      }  
    } catch (error) {
        yield put(getNotificationsFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setSearchNotification(action.payload));
}
export function* watchGetListNotification() {
   yield takeEvery(GetNotificationsType.GET_NOTIFICATIONS , getListNotificationSaga)
   yield debounce(500, GetNotificationsType.GET_NOTIFICATION_DEBOUNCE_SEARCH, handleSearchDebounce)
}