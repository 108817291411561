import {GetCryptoWalletTransactionsTypes} from './actionTypes';
import {
  IFetchingGetCryptoWalletTransaction,
  IGetCryptoWalletTransactions,
  IGetCryptoWalletTransactionsError,
  IGetCryptoWalletTransactionsSuccess, ISetCryptoWalletTransactionsFilter
} from './interfaces';

export const getCryptoWalletTransactionsIsFetching = (isFetching: boolean): IFetchingGetCryptoWalletTransaction => ({
    type: GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS_IS_FETCHING,
    isFetching
})

export const getCryptoWalletTransactionsData = (filter: any): IGetCryptoWalletTransactions => ({
    type: GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS,
    filter
})

export const getCryptoWalletTransactionsSuccess = (data: any): IGetCryptoWalletTransactionsSuccess => ({
    type: GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS_SUCCESS,
    data
})

export const getCryptoWalletTransactionsFailed = (error: any): IGetCryptoWalletTransactionsError => ({
    type: GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS_ERROR,
    error
})

export const setCryptoWalletTransactionsFilter = (action: any): ISetCryptoWalletTransactionsFilter => ({
    type: GetCryptoWalletTransactionsTypes.SET_CRYPTO_WALLET_TRANSACTIONS_FILTER,
    action
})
