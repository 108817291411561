import { P2PExchangeOffersNoteType } from './actionTypes';
import { IFetchingSendNoteP2PExchangeOffers, ISendNoteP2PExchangeOffers, ISendNoteP2PExchangeOffersError, ISendNoteP2PExchangeOffersSuccess } from './interfaces';

export const sendNoteP2PExchangeOffersIsFetching = (isFetching:boolean) : IFetchingSendNoteP2PExchangeOffers => ({
    type: P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER_IS_FETCHING,
    isFetching
})

export const sendNoteP2PExchangeOffers = (payload: object) : ISendNoteP2PExchangeOffers => ({
    type: P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER,
    payload
})

export const sendNoteP2PExchangeOffersSuccess = (data:any) : ISendNoteP2PExchangeOffersSuccess => ({
    type: P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER_SUCCESS,
    data
})

export const sendNoteP2PExchangeOffersFaild = (error:any) : ISendNoteP2PExchangeOffersError => ({
    type: P2PExchangeOffersNoteType.SEND_NOTE_P2P_EXCHANGE_OFFER_FAILD,
    error
})