import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeHistoryAgent } from 'src/apis/service/P2PExchange';
import { GetP2PExchangeHistoryAgentTypes } from '../../actions/getP2PExchangeHistoryAgent';
import { getP2PExchangeHistoryAgentError, getP2PExchangeHistoryAgentFetching, getP2PExchangeHistoryAgentFilter, getP2PExchangeHistoryAgentSuccess } from '../../actions/getP2PExchangeHistoryAgent/action';

function* getP2PExchangeHistoryAgentSaga(action:any):Generator<any> {
    yield put(getP2PExchangeHistoryAgentFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetP2PExchangeHistoryAgent(payload)
      if(data && data?.status === 200){
        yield put(getP2PExchangeHistoryAgentSuccess(data))
      } else {
        yield put(getP2PExchangeHistoryAgentError('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeHistoryAgentError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getP2PExchangeHistoryAgentFilter(action.payload));
}
export function* watchGetP2PExchangeHistoryAgent() {
   yield takeEvery(GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT, getP2PExchangeHistoryAgentSaga)
   yield debounce(500,GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_FILTER_DEBOUNCE,handleSearchDebounce)
}
