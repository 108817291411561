import { AnyAction, Reducer } from "redux";
import {
  IUpdateWalletStatusState,
  initialUpdateWalletStatusState,
} from "./state";
import { UpdateWalletStatusTypes } from "src/store/actions/updateWalletStatus";

export const UpdateWalletStatusReducer: Reducer = (
  state: IUpdateWalletStatusState = initialUpdateWalletStatusState,
  action: AnyAction
) => {
  switch (action.type) {
    case UpdateWalletStatusTypes.UPDATE_WALLET_STATUS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_FAILD: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case UpdateWalletStatusTypes.UPDATE_WALLET_STATUS_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
