import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetTransactionsByOfferId } from 'src/apis/service/TransactionByOffer';
import { 
  getListTransactionByOfferSuccess,
  getListTransactionByOfferFaild,
  getListTransactionByOfferIsFetching,
} from 'src/store/actions/TransactionByOffer/action';
import { GetTransactionByOfferTypes } from 'src/store/actions/TransactionByOffer/actionTypes';


function* getListTransactionByOffer(action: any): Generator<any> {
    yield put(getListTransactionByOfferIsFetching(true))
    const { payload } = action;
    try {
      const data : any = yield call(handleGetTransactionsByOfferId, payload);  
      if(data.status === 200) {
        yield put(getListTransactionByOfferSuccess(data));
      }  
    } catch (error) {
        yield put(getListTransactionByOfferFaild(error));
    }
}

export function* watchGetListTransactionByOffer() {
  yield takeEvery(GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER , getListTransactionByOffer)
}