import { toastSuccess, toastError } from './../../../helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleActiveScoreKPI } from 'src/apis/service/ScorePeriodConfig';
import { ActiveScoreKPITypes } from '../../actions/activeScoreKPI';
import {
  activeScoreKPIError,
  activeScoreKPIFetching,
  activeScoreKPIFilter,
  activeScoreKPISuccess
} from '../../actions/activeScoreKPI/action';

function* activeScoreKPISaga(action: any): Generator<any> {

  yield put(activeScoreKPIFetching(true));
  const { payload, history } = action;
  try {
    const data: any = yield handleActiveScoreKPI(payload);
    if (data && data?.status === 200) {
      yield put(activeScoreKPISuccess(true));
      toastSuccess('bo_active_score_kpi_config_success');
    } else {
      toastError('bo_you_can_t_active_score_kpi_config');
      // yield put(activeScoreKPIError('error'))
    }
    // yield put(history.push(`/dashboard/settings/user-score-setting`));
  } catch (error) {
    yield put(activeScoreKPIError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(activeScoreKPIFilter(action.payload));
}
export function* watchActiveScoreKPI() {
  yield takeEvery(ActiveScoreKPITypes.ACTIVE_SCORE_KPI, activeScoreKPISaga);
  yield debounce(
    500,
    ActiveScoreKPITypes.ACTIVE_SCORE_KPI_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
