import { put, takeEvery } from 'redux-saga/effects';
import { handleGetListNotificationFirebase } from 'src/apis/service/Notifications';
import {
  getNotificationsFirebaseFaild,
  getNotificationsFirebaseIsFetching,
  getNotificationsFirebaseSuccess
} from 'src/store/actions/notificationFromFirebase/getAllNotification/action';
import { GetNotificationInFirebaseType } from 'src/store/actions/notificationFromFirebase/getAllNotification/actionTypes';

function* getListNotificationsInFirebaseSaga(action: any): Generator<any> {
  yield put(getNotificationsFirebaseIsFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetListNotificationFirebase(payload);
    if (data.status === 200) {
      yield put(getNotificationsFirebaseSuccess(data));
    }
  } catch (error) {
    yield put(getNotificationsFirebaseFaild(error));
  }
}
export function* watchGetListNotificationsInFirebase() {
  yield takeEvery(
    GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE,
    getListNotificationsInFirebaseSaga
  );
}
