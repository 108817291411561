import { ReactiveUserType } from './actionTypes';
import { IFetchingReactivekUser, IReactiveUserError, IReactiveUserSuccess, IReactivekUser } from './interfaces';


export const reactivekUserIsFetching = (isFetching:boolean) : IFetchingReactivekUser=> ({
    type: ReactiveUserType.REACTIVE_USER_FETCHING,
    isFetching
})

export const reactiveUser = (payload:Object,listUserDeactive:boolean) : IReactivekUser => ({
    type: ReactiveUserType.REACTIVE_USER,
    payload,
    listUserDeactive
})

export const reactiveUserSuccess = (data:any) : IReactiveUserSuccess => ({
    type: ReactiveUserType.REACTIVE_USER_SUCCESS,
    data
})

export const reactivekUserFaild = (error:any) : IReactiveUserError => ({
    type: ReactiveUserType.REACTIVE_USER_ERROR,
    error
})