import { ListParams } from 'src/models/common';

export interface IWallet {
  id: string;
  createdAt: string;
  userId: string;
  name: string;
  phone: string;
  status: number;
  totalBalance: string;
  cryptoCurrency: string;
  totalValueInKRW: string;
  totalValueInUSD: string;
  hidePhone?: boolean;
  userName: string;
}

export interface IGetListUserWalletState {
  data: IWallet[];
  totalWallet: number;
  isFetching: boolean;
  filter: ListParams;
  totalValueInUSD: number;
  totalValueInKRW: number;
  totalCount: number;
}

export const initialGetListUserWalletState: IGetListUserWalletState = {
  data: [],
  totalWallet: 0,
  isFetching: false,
  filter: {},
  totalValueInUSD: 0,
  totalValueInKRW: 0,
  totalCount: 0,
};
