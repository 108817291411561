import { put, takeEvery } from 'redux-saga/effects';
import { GetUserDetailType } from '../../actions/getDataUserDetail/actionTypes';
import {
  getUserDetailIsFetching,
  getUserDetailSuccess,
  getUserDetailFaild,
} from '../../actions/getDataUserDetail/action';
import { handleGetUserDetail } from '../../../apis/service/user';
import { getFileById, getFileByIdClean } from 'src/store/actions/getFileById';

function* getUserDetailSaga(action: any): Generator<any> {
  yield put(getUserDetailIsFetching(true));
  const { payload, isGetAvatar } = action;
  try {
    const dataUserDetail: any = yield handleGetUserDetail(payload);
    if (dataUserDetail?.status === 200) {
      yield put(getUserDetailSuccess(dataUserDetail));
      if (isGetAvatar) {
        if (dataUserDetail?.data.imageId) {
          yield put(getFileById(dataUserDetail?.data.imageId));
        } else {
          yield put(getFileByIdClean());
        }
      }
    } else {
      yield put(getUserDetailFaild('error'));
    }
  } catch (error) {
    yield put(getUserDetailFaild(error));
  }
}
export function* watchGetUserDetail() {
  yield takeEvery(GetUserDetailType.GET_USER_DETAIL, getUserDetailSaga);
}
