import { AnyAction, Reducer } from 'redux';
import { initialCreateExchangeOfferState, CreateExchangeOfferState } from './state';
import { CreateExchangeOfferType } from '../../actions/createExchangeOffer/actionTypes';

export const CreateExchangeOfferReducer: Reducer = (
  state: CreateExchangeOfferState = initialCreateExchangeOfferState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateExchangeOfferType.CREATE_EXCHANGE_OFFER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateExchangeOfferType.CREATE_EXCHANGE_OFFER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case CreateExchangeOfferType.CREATE_EXCHANGE_OFFER_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
