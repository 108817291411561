import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeOfferDetail } from 'src/apis/service/P2PExchange';
import { getP2PExchangeOfferDetailFaild, getP2PExchangeOfferDetailIsFetching, getP2PExchangeOfferDetailSuccess, GetP2PExchangeOfferDetailType } from 'src/store/actions/P2PExchangeOfferDetail';

function* getListP2PExchangeOfferDetailSaga(action: any): Generator<any> {
    yield put(getP2PExchangeOfferDetailIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeOfferDetail, payload)      
      if(data && data?.status === 200){
        yield put(getP2PExchangeOfferDetailSuccess(data));
      } else {
        yield put(getP2PExchangeOfferDetailFaild('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeOfferDetailFaild(error))
    }
}

export function* watchGetListP2PExchangeOfferDetail() {
   yield takeEvery(GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL , getListP2PExchangeOfferDetailSaga)
}