import {GetListAddressBooksTypes} from './actionTypes';
import { IGetListAddressBooks, IGetListAddressBooksFetching, IGetListAddressBooksSuccess, IGetListAddressBooksError, IGetListAddressBooksFilter, IGetListAddressBooksFiterDebounce } from "./interfaces";


export const getListAddressBooks = (payload:Object): IGetListAddressBooks => ({
    type: GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS,
    payload
})

export const getListAddressBooksFetching = (isFetching:boolean) : IGetListAddressBooksFetching => ({
    type: GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_FETCHING,
    isFetching
})

export const getListAddressBooksSuccess = (data:any) : IGetListAddressBooksSuccess => ({
   type: GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_SUCCESS,
   data
})

export const getListAddressBooksError = (errorMsg:any) : IGetListAddressBooksError => ({
    type: GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_FAILD,
    error:errorMsg
})

export const getListAddressBooksFilter = (payload: any): IGetListAddressBooksFilter => ({
    type: GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_FILTER,
    payload
})

export const getListAddressBooksFilterDebounce = (payload: any): IGetListAddressBooksFiterDebounce => ({
    type: GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_FILTER_DEBOUNCE,
    payload 
})
