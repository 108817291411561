export function setStorageItem(key: string, value: string) {
  window.localStorage.setItem(key, value)
}

export function getStorageItem(key: string) {
  const data = window.localStorage.getItem(key)
  if (data) {
    try {
      const value = JSON.parse(data)
      return value
    } catch (e) {
      return data
    }
  }
}

export function removeStorageItem(key: string) {
  // Removes the key/value pair with the given key
  return window.localStorage.removeItem(key)
}

export function clearStorageItems() {
  // Remove all local storage items
  return Object.keys(localStorage)
    .filter((x) => !(x.startsWith('NCD_') || x.startsWith('nowa'))) // No clear data
    .forEach((x) => localStorage.removeItem(x))
  // return window.localStorage.clear();
}
