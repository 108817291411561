import { GetAllRankConfigType } from "./actionTypes";
import { IGetAllRankConfig, IGetAllRankConfigFetching, IGetAllRankConfigSuccess, IGetAllRankConfigError, IGetFilterAllRankConfig, IGetFiterDebounceAllRankConfig } from "./interfaces";


export const getAllRankConfig = (): IGetAllRankConfig => ({
    type: GetAllRankConfigType.GET_ALL_RANK_CONFIG
})

export const getAllRankConfigFetching = (isFetching: boolean): IGetAllRankConfigFetching => ({
    type: GetAllRankConfigType.GET_ALL_RANK_CONFIG_FETCHING,
    isFetching
})

export const getAllRankConfigSuccess = (data: any, list: any): IGetAllRankConfigSuccess => ({
    type: GetAllRankConfigType.GET_ALL_RANK_CONFIG_SUCCESS,
    data,
    list
})

export const getAllRankConfigError = (errorMsg: any): IGetAllRankConfigError => ({
    type: GetAllRankConfigType.GET_ALL_RANK_CONFIG_FAILD,
    error: errorMsg
})

export const setFilterAllRankConfig = (payload: any): IGetFilterAllRankConfig => ({
    type: GetAllRankConfigType.GET_FILTER_ALL_RANK_CONFIG,
    payload
})

export const setFilterDebounceAllRankConfig = (payload: any): IGetFiterDebounceAllRankConfig => ({
    type: GetAllRankConfigType.GET_FILTER_DEBOUNCE_ALL_RANK_CONFIG,
    payload
})