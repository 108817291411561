import { put, takeEvery } from 'redux-saga/effects';
import { handleEditUserByAdmin } from 'src/apis/service/user';
import {
  EditUserByAdminType,
  editUserByAdminAtFetching,
  editUserByAdminFaild,
  editUserByAdminSuccess,
} from 'src/store/actions/editUserByAdmin';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* editUserByAdminSaga(action: any): Generator<any> {
  const { payload } = action;
  yield put(editUserByAdminAtFetching(true));
  try {
    const data: any = yield handleEditUserByAdmin(payload);
    if (data.status === 200) {
      yield put(editUserByAdminSuccess(data));
      // yield put(getUserDetail(+payload.userId))
      toastSuccess('bo_edit_information_user_success');
    } else {
      toastError('bo_edit_information_user_fail');
    }
  } catch (error: any) {
    toastError('bo_edit_information_user_fail');
    yield put(editUserByAdminFaild(error.message));
  }
}

export function* watchEditUserByAdmin() {
  yield takeEvery(EditUserByAdminType.EDIT_USER_BY_ADMIN, editUserByAdminSaga);
}
