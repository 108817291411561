import { ListParams } from "src/models/common";

interface IDataGetOfferHistory {
  data:{
      items:any[];
      total:number;
      totalCount: number;
  };
  error:any;
  success:boolean;
}

export interface IGetOfferHistoryState {
    data:IDataGetOfferHistory;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetOfferHistoryState: IGetOfferHistoryState = {
    data:{
        data:{
            items:[],
            total:0,
            totalCount: 0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


