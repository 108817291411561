import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApi = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER
});

const handleCreateRankPeriodConfig = async (data: any): Promise<any> => {
  try {
    return await requestApi.post(`/Admin/CreateRankPeriodConfig`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAllRankPeriod = async (): Promise<any> => {
  try {
    return await requestApi.get(`/Admin/GetAllRankPeriod`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handlePostAllRankPeriod = async (data: any): Promise<any> => {
  try {
    return await requestApi.post(`/Admin/GetAllRankPeriod`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleCreateRankPeriodConfig,
  handleGetAllRankPeriod,
  handlePostAllRankPeriod
};
