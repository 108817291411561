import { TransactionManualPaymentType } from './actionTypes';
import {
  ITransactionManualPayment,
  IFetchingTransactionManualPayment,
  ITransactionManualPaymentError,
  ITransactionManualPaymentSuccess,
} from './interfaces';

export const transactionManualPaymentIsFetching = (
  isFetching: boolean
): IFetchingTransactionManualPayment => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_FETCHING,
  isFetching,
});

export const transactionManualPayment = (
  payload: Object,
  transactionType: string,
  filters: Object
): ITransactionManualPayment => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT,
  payload,
  transactionType,
  filters,
});

export const transactionManualPaymentSuccess = (
  data: any
): ITransactionManualPaymentSuccess => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_SUCCESS,
  data,
});

export const transactionManualPaymentFaild = (
  error: any
): ITransactionManualPaymentError => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_ERROR,
  error,
});
