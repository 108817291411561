import { CreateScoreKPITypes } from './actionTypes';
import { ICreateScoreKPI, ICreateScoreKPIFetching, ICreateScoreKPISuccess, ICreateScoreKPIError, ICreateScoreKPIFilter, ICreateScoreKPIFiterDebounce } from "./interfaces";


export const createScoreKPI = (payload: Object,
    history?: any): ICreateScoreKPI => ({
        type: CreateScoreKPITypes.CREATE_SCORE_KPI,
        payload,
        history
    })

export const createScoreKPIFetching = (isFetching: boolean): ICreateScoreKPIFetching => ({
    type: CreateScoreKPITypes.CREATE_SCORE_KPI_FETCHING,
    isFetching
})

export const createScoreKPISuccess = (data: any): ICreateScoreKPISuccess => ({
    type: CreateScoreKPITypes.CREATE_SCORE_KPI_SUCCESS,
    data
})

export const createScoreKPIError = (errorMsg: any): ICreateScoreKPIError => ({
    type: CreateScoreKPITypes.CREATE_SCORE_KPI_FAILD,
    error: errorMsg
})

export const createScoreKPIFilter = (payload: any): ICreateScoreKPIFilter => ({
    type: CreateScoreKPITypes.CREATE_SCORE_KPI_FILTER,
    payload
})

export const createScoreKPIFilterDebounce = (payload: any): ICreateScoreKPIFiterDebounce => ({
    type: CreateScoreKPITypes.CREATE_SCORE_KPI_FILTER_DEBOUNCE,
    payload
})
