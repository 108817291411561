import { ListParams } from "src/models/common";

interface IDataHandleBlockOffer {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  message: string;
  success:boolean;
}

export interface IHandleBlockOfferState {
    data:IDataHandleBlockOffer;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
    message: string
}

export const initialHandleBlockOfferState: IHandleBlockOfferState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false,
        message: ""
    },
    isFetching:false,
    message: "",
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


