import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetUserTypes } from '../../actions/getUser';
import { getUserFetching, getUserSuccess, getUserError, getUserFilter } from '../../actions/getUser/action';
import { handleGetUser } from '../../../apis/service/user';

function* getUserSaga(action: any): Generator<any> {
  yield put(getUserFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleGetUser()
    if (data && data?.status === 200) {
      yield put(getUserSuccess(data))
    } else {
      yield put(getUserError('error'))
    }
  } catch (error) {
    yield put(getUserError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getUserFilter(action.payload));
}
export function* watchGetUser() {
  yield takeEvery(GetUserTypes.GET_USER, getUserSaga)
  yield debounce(500, GetUserTypes.GET_USER_FILTER_DEBOUNCE, handleSearchDebounce)
}
