
interface IDataListAgencyStatusUpdate {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface IListAgencyStatusUpdateState {
    data: IDataListAgencyStatusUpdate;
    isFetching: boolean,
    success: boolean,
    successCount: number
}

export const initialListAgencyStatusUpdateState: IListAgencyStatusUpdateState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    success: false,
    successCount: 0
}

