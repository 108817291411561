import { ListParams } from "src/models/common";

interface IDataGetCryptoTransactionFee {
  data: any;
  error: any;
  success: boolean;
}

export interface IGetCryptoTransactionFeeState {
  data: IDataGetCryptoTransactionFee;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialGetCryptoTransactionFeeState: IGetCryptoTransactionFeeState =
  {
    data: {
      data: null,
      error: null,
      success: false,
    },
    isFetching: false,
    filter: {},
    pagination: {
      totalCount: 0,
    },
  };
