import { ListParams } from 'src/models/common';
import { GetCryptoWalletSendStatisticalType } from './actionTypes';
import { IFetchingGetCryptoWalletSendStatistical, IGetCryptoWalletSendStatistical, IGetCryptoWalletSendStatisticalError, IGetCryptoWalletSendStatisticalSuccess, IGetListFilterCryptoWalletSendStatistical } from './interfaces';

export const getCryptoWalletSendStatisticalIsFetching = (isFetching:boolean) : IFetchingGetCryptoWalletSendStatistical => ({
    type: GetCryptoWalletSendStatisticalType.GET_CRYPTO_WALLET_SEND_STATISTICAL_FETCHING,
    isFetching
})

export const getCryptoWalletSendStatistical = (payload: object) : IGetCryptoWalletSendStatistical => ({
    type: GetCryptoWalletSendStatisticalType.GET_CRYPTO_WALLET_SEND_STATISTICAL,
    payload
})

export const getCryptoWalletSendStatisticalSuccess = (data:any) : IGetCryptoWalletSendStatisticalSuccess => ({
    type: GetCryptoWalletSendStatisticalType.GET_CRYPTO_WALLET_SEND_STATISTICAL_SUCCESS,
    data
})

export const getCryptoWalletSendStatisticalFaild = (error:any) : IGetCryptoWalletSendStatisticalError => ({
    type: GetCryptoWalletSendStatisticalType.GET_CRYPTO_WALLET_SEND_STATISTICAL_ERROR,
    error
})

export const setFilterCryptoWalletSendStatistical = (payload: ListParams): IGetListFilterCryptoWalletSendStatistical => ({
    type: GetCryptoWalletSendStatisticalType.GET_LIST_FILTER_CRYPTO_WALLET_SEND_STATISTICAL,
    payload
})