import { AnyAction, Reducer } from 'redux';
import { initialTransactionManualPaymentState, TransactionManualPaymentState } from './state';
import { TransactionManualPaymentType } from '../../actions/transactionManualPayment/actionTypes';

export const TransactionManualPaymentReducer: Reducer = (
  state: TransactionManualPaymentState = initialTransactionManualPaymentState,
  action: AnyAction
) => {
  switch (action.type) {
    case TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
