import {GetUserWalletOverviewTypes} from './actionTypes';
import { IGetUserWalletOverview, IGetUserWalletOverviewFetching, IGetUserWalletOverviewSuccess, IGetUserWalletOverviewError, IGetUserWalletOverviewFilter, IGetUserWalletOverviewFiterDebounce } from "./interfaces";


export const getUserWalletOverview = (payload:Object): IGetUserWalletOverview => ({
    type: GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW,
    payload
})

export const getUserWalletOverviewFetching = (isFetching:boolean) : IGetUserWalletOverviewFetching => ({
    type: GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_FETCHING,
    isFetching
})

export const getUserWalletOverviewSuccess = (data:any) : IGetUserWalletOverviewSuccess => ({
   type: GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_SUCCESS,
   data
})

export const getUserWalletOverviewError = (errorMsg:any) : IGetUserWalletOverviewError => ({
    type: GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_FAILD,
    error:errorMsg
})

export const getUserWalletOverviewFilter = (payload: any): IGetUserWalletOverviewFilter => ({
    type: GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_FILTER,
    payload
})

export const getUserWalletOverviewFilterDebounce = (payload: any): IGetUserWalletOverviewFiterDebounce => ({
    type: GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_FILTER_DEBOUNCE,
    payload 
})
