import { put, takeEvery, debounce } from "redux-saga/effects";
import { HandleGetWalletDetailOverviewTypes } from "../../actions/handleGetWalletDetailOverview";
import {
  handleGetWalletDetailOverviewFetching,
  handleGetWalletDetailOverviewSuccess,
  handleGetWalletDetailOverviewError,
  handleGetWalletDetailOverviewFilter,
} from "../../actions/handleGetWalletDetailOverview/action";
import { handleGetWalletDetailOverview } from "src/apis/service/CryptoWallet";

function* handleGetWalletDetailOverviewSaga(action: any): Generator<any> {
  yield put(handleGetWalletDetailOverviewFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetWalletDetailOverview(payload);
    if (data && data?.status === 200) {
      yield put(handleGetWalletDetailOverviewSuccess(data));
    } else {
      yield put(handleGetWalletDetailOverviewError("error"));
    }
  } catch (error) {
    yield put(handleGetWalletDetailOverviewError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(handleGetWalletDetailOverviewFilter(action.payload));
}
export function* watchHandleGetWalletDetailOverview() {
  yield takeEvery(
    HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW,
    handleGetWalletDetailOverviewSaga
  );
  yield debounce(
    500,
    HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
