import { debounce, put, takeEvery } from "redux-saga/effects";
import { handleAgentTransferMoney } from "src/apis/service/Agent";
import { AgentTransferMoneyTypes } from "../../actions/agentTransferMoney";
import {
  agentTransferMoneyError,
  agentTransferMoneyFetching,
  agentTransferMoneyFilter,
  agentTransferMoneySuccess,
} from "../../actions/agentTransferMoney/action";
import { toastError, toastSuccess } from "src/utils/newUtils/toastHelper";

function* agentTransferMoneySaga(action: any): Generator<any> {
  yield put(agentTransferMoneyFetching(true));
  const { payload, getDataAfterSuccess } = action;
  try {
    const data: any = yield handleAgentTransferMoney(payload);
    if (data && data?.status === 200) {
      getDataAfterSuccess();
      toastSuccess("bo_tran_completed_description_label");
      yield put(agentTransferMoneySuccess(data));
    } else {
      toastError("bo_transfer_money_fail_label");
      yield put(agentTransferMoneyError("error"));
    }
  } catch (error) {
    toastError("bo_transfer_money_fail_label");
    yield put(agentTransferMoneyError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentTransferMoneyFilter(action.payload));
}
export function* watchAgentTransferMoney() {
  yield takeEvery(
    AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY,
    agentTransferMoneySaga
  );
  yield debounce(
    500,
    AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
