import { IWalletBalanceObject } from "..";

export interface IGetUserWalletOverviewState {
  data: IWalletBalanceObject[];
  isFetching: boolean;
}

export const initialGetUserWalletOverviewState: IGetUserWalletOverviewState = {
  data: [],
  isFetching: false,
};
