import { AnyAction, Reducer } from 'redux';
import { IAgentTransferredTransactionState, initialAgentTransferredTransactionState } from './state';
import { AgentTransferredTransactionTypes } from 'src/store/actions/agentTransferredTransaction';

export const AgentTransferredTransactionReducer: Reducer = (
  state: IAgentTransferredTransactionState = initialAgentTransferredTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
