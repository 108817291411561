import { GetAllWalletAdminBOType } from './actionTypes';
import {
  IFilterWalletSuperAdminBO,
  IGetAllWalletAdminBOFetching,
  IGetAllWalletSuperAdminBO,
  IGetAllWalletSuperAdminBOFaild,
  IGetAllWalletSuperAdminBOSuccess,
  IGetFiterDebounceWalletSuperAdminBO,
} from './interfaces';

export const getAllWalletAdminBOAtFetching = (
  isFetching: boolean
): IGetAllWalletAdminBOFetching => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_SUPER_ADMIN_BO_FETCHING,
  isFetching,
});

export const getAllWalletSuperAdminBO = (
  payload: Object
): IGetAllWalletSuperAdminBO => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_SUPER_ADMIN_BO,
  payload,
});

export const getAllWalletSuperAdminBOSuccess = (
  data: any
): IGetAllWalletSuperAdminBOSuccess => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_SUPER_ADMIN_BO_SUCCESS,
  data,
});

export const getAllWalletSuperAdminBOFailed = (
  error: any
): IGetAllWalletSuperAdminBOFaild => ({
  type: GetAllWalletAdminBOType.GET_ALL_WALLET_SUPER_ADMIN_BO_FAILD,
  error,
});

export const setFilterWalletSuperAdminBO = (
  payload: any
): IFilterWalletSuperAdminBO => ({
  type: GetAllWalletAdminBOType.FILTER_WALLET_SUPER_ADMIN_BO,
  payload,
});

export const filterDebounceWalletSuperAdminBO = (
  payload: any
): IGetFiterDebounceWalletSuperAdminBO => ({
  type: GetAllWalletAdminBOType.GET_FILTER_DEBOUNCE_WALLET_SUPER_ADMIN_BO,
  payload,
});
