import { lazy } from 'react';
import { routerConst } from '../routerConstants';

const LoginAgent = lazy(() => import('src/agentPages/auth'));
const Auth = lazy(() => import('src/pages/Authentication/auth'));

export const publicRoutes = [
  {
    path: '/',
    element: <Auth />,
    children: [
      { path: '/', element: <LoginAgent /> },
      { path: routerConst.Login, element: <LoginAgent /> },
    ],
  },
];
