import { put, takeEvery, select } from 'redux-saga/effects';
import { createAnnouncementCategory } from 'src/apis/service/Announcements';
import { CreateAnnouncementsCategoryType } from 'src/store/actions/Announcement/Categories/CreateAnnouncementsCategory/actionTypes';
import {
  CreateAnnouncementsCategoryIsFetching,
  CreateAnnouncementsCategorySuccess,
  CreateAnnouncementsCategoryFaild,
} from 'src/store/actions/Announcement/Categories/CreateAnnouncementsCategory/action';
import {IApplicationState} from 'src/store/IApplicationState';
import {getAnnouncementsCategory} from 'src/store/actions/Announcement/Categories/GetAnnouncementsCategory/action';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* createAnnouncementCategorySaga(action: any): Generator<any> {

  yield put(CreateAnnouncementsCategoryIsFetching(true));
  const { payload } = action;
  try {
    const data = yield createAnnouncementCategory(payload);
    if (data) {
      const filter = yield select((state:IApplicationState) => state.listAnnouncementsCategory.filter) 
      yield put(CreateAnnouncementsCategorySuccess(data));
      toastSuccess('bo_annoucement_category_create_success')
      // yield put(getAnnouncementsCategory(filter))
    } else {
      toastError('bo_annoucement_create_fail')
    }
  } catch (error) {
    yield put(CreateAnnouncementsCategoryFaild(error));
  }
}

export function* watchCreateListAnnouncementsCategory() {
  yield takeEvery(
    CreateAnnouncementsCategoryType.CREATE_ANNOUNCEMENTS_CATEGORY,
    createAnnouncementCategorySaga
  );
}
