import { PostCryptoCreateExchangeTransactionTypes } from './actionTypes';
import { IPostCryptoCreateExchangeTransaction, IPostCryptoCreateExchangeTransactionFetching, IPostCryptoCreateExchangeTransactionSuccess, IPostCryptoCreateExchangeTransactionError, IPostCryptoCreateExchangeTransactionFilter, IPostCryptoCreateExchangeTransactionFiterDebounce } from "./interfaces";


export const postCryptoCreateExchangeTransaction = (offerId: string, payload: Object): IPostCryptoCreateExchangeTransaction => ({
    type: PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION,
    payload,
    offerId
})

export const postCryptoCreateExchangeTransactionFetching = (isFetching: boolean): IPostCryptoCreateExchangeTransactionFetching => ({
    type: PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_FETCHING,
    isFetching
})

export const postCryptoCreateExchangeTransactionSuccess = (data: any): IPostCryptoCreateExchangeTransactionSuccess => ({
    type: PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_SUCCESS,
    data
})

export const postCryptoCreateExchangeTransactionError = (errorMsg: any): IPostCryptoCreateExchangeTransactionError => ({
    type: PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_FAILD,
    error: errorMsg
})

export const postCryptoCreateExchangeTransactionFilter = (payload: any): IPostCryptoCreateExchangeTransactionFilter => ({
    type: PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_FILTER,
    payload
})

export const postCryptoCreateExchangeTransactionFilterDebounce = (payload: any): IPostCryptoCreateExchangeTransactionFiterDebounce => ({
    type: PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_FILTER_DEBOUNCE,
    payload
})
