import { AnyAction, Reducer } from 'redux';
import { IGetListAdminRolesState, initialGetListAdminRolesState } from './state';
import { GetListAdminRolesTypes } from 'src/store/actions/getListAdminRoles';

export const GetListAdminRolesReducer: Reducer = (
  state: IGetListAdminRolesState = initialGetListAdminRolesState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES: {
      return {
        ...state,
        isFetching: 0,
      };
    }
    case GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_SUCCESS: {
      return {
        ...state,
        data: action.data.data,
        isFetching: 1,
      };
    }
    case GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_FAILD: {
      return {
        ...state,
        isFetching: 1,
        error: action.error,
      };
    }
    case GetListAdminRolesTypes.GET_LIST_ADMIN_ROLES_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetListAdminRolesTypes.CLEAN_LIST_ADMIN_ROLES: {
      return {
        ...state,
        data: initialGetListAdminRolesState.data,
      };
    }
    default: {
      return state;
    }
  }
};
