import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleCreateScoreKPI } from 'src/apis/service/ScorePeriodConfig';
import { CreateScoreKPITypes } from '../../actions/createScoreKPI';
import {
  createScoreKPIError,
  createScoreKPIFetching,
  createScoreKPIFilter,
  createScoreKPISuccess
} from '../../actions/createScoreKPI/action';
import { toastError, toastSuccess } from './../../../helpers/toastHelper';

function* createScoreKPISaga(action: any): Generator<any> {


  yield put(createScoreKPIFetching(true));
  const { payload, history } = action;
  try {
    const data: any = yield handleCreateScoreKPI(payload);
    if (data && data?.status === 200) {
      yield put(createScoreKPISuccess(true));
      toastSuccess('bo_create_score_kpi_config_success');
    } else {
      toastError('bo_you_can_t_create_score_kpi_config');
      // yield put(activeScoreKPIError('error'))
    }
    // yield put(history.push(`/dashboard/settings/user-score-setting`));
  } catch (error) {
    yield put(createScoreKPIError(error));
    toastError('bo_you_can_t_create_score_kpi_config');
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(createScoreKPIFilter(action.payload));
}
export function* watchCreateScoreKPI() {
  yield takeEvery(CreateScoreKPITypes.CREATE_SCORE_KPI, createScoreKPISaga);
  yield debounce(
    500,
    CreateScoreKPITypes.CREATE_SCORE_KPI_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
