import { AnyAction, Reducer } from 'redux';
import { IUpdateCryptoExchangeOffersListState, initialUpdateCryptoExchangeOffersListState } from './state';
import { UpdateCryptoExchangeOffersListTypes } from 'src/store/actions/updateCryptoExchangeOffersList';

export const UpdateCryptoExchangeOffersListReducer: Reducer = (
  state: IUpdateCryptoExchangeOffersListState = initialUpdateCryptoExchangeOffersListState,
  action: AnyAction
) => {
  switch (action.type) {
    case UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
