import { AnyAction, Reducer } from 'redux';
import { IAgentGetDirectDetailState, initialAgentGetDirectDetailState } from './state';
import { AgentGetDirectDetailTypes } from 'src/store/actions/agentGetDirectDetail';

export const AgentGetDirectDetailReducer: Reducer = (
  state: IAgentGetDirectDetailState = initialAgentGetDirectDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
