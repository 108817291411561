import { AnyAction, Reducer } from 'redux';
import { ISet2FAEnabledState, initialSet2FAEnabledState } from './state';
import { Set2FAEnabledTypes } from 'src/store/actions/set2FAEnabled';

export const Set2FAEnabledReducer: Reducer = (
  state: ISet2FAEnabledState = initialSet2FAEnabledState,
  action: AnyAction
) => {
  switch (action.type) {
    case Set2FAEnabledTypes.SET2FA_ENABLED: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case Set2FAEnabledTypes.SET2FA_ENABLED_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case Set2FAEnabledTypes.SET2FA_ENABLED_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case Set2FAEnabledTypes.SET2FA_ENABLED_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
