import { AnyAction, Reducer } from 'redux';
import { UpdateAgencyStatusType } from 'src/store/actions';
import { IListAgencyStatusUpdateState, initialListAgencyStatusUpdateState } from './state';


export const GetListAgencyStatusUpdateReducer: Reducer = (
  state: IListAgencyStatusUpdateState = initialListAgencyStatusUpdateState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UpdateAgencyStatusType.UPDATE_AGENCY_STATUS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateAgencyStatusType.UPDATE_AGENCY_STATUS_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case UpdateAgencyStatusType.UPDATE_AGENCY_STATUS_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        success: true,
        successCount: state.successCount + 1
      }
    }
    case UpdateAgencyStatusType.UPDATE_AGENCY_STATUS_RESET: {
      return {
        ...state,
        success: false
      }
    }
    default:
      return state;
  }
};