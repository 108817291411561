import {GetAgentCryptoSettingTypes} from './actionTypes';
import { IGetAgentCryptoSetting, IGetAgentCryptoSettingFetching, IGetAgentCryptoSettingSuccess, IGetAgentCryptoSettingError, IGetAgentCryptoSettingFilter, IGetAgentCryptoSettingFiterDebounce } from "./interfaces";


export const getAgentCryptoSetting = (payload:Object): IGetAgentCryptoSetting => ({
    type: GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING,
    payload
})

export const getAgentCryptoSettingFetching = (isFetching:boolean) : IGetAgentCryptoSettingFetching => ({
    type: GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_FETCHING,
    isFetching
})

export const getAgentCryptoSettingSuccess = (data:any) : IGetAgentCryptoSettingSuccess => ({
   type: GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_SUCCESS,
   data
})

export const getAgentCryptoSettingError = (errorMsg:any) : IGetAgentCryptoSettingError => ({
    type: GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_FAILD,
    error:errorMsg
})

export const getAgentCryptoSettingFilter = (payload: any): IGetAgentCryptoSettingFilter => ({
    type: GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_FILTER,
    payload
})

export const getAgentCryptoSettingFilterDebounce = (payload: any): IGetAgentCryptoSettingFiterDebounce => ({
    type: GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_FILTER_DEBOUNCE,
    payload 
})
