import { put, takeEvery, debounce } from "redux-saga/effects";
import { GetUserWalletOverviewTypes } from "../../actions/getUserWalletOverview";
import {
  getUserWalletOverviewFetching,
  getUserWalletOverviewSuccess,
  getUserWalletOverviewError,
  getUserWalletOverviewFilter,
} from "../../actions/getUserWalletOverview/action";
import { handleGetUserWalletOverview } from "src/apis/service/CryptoWallet";

function* getUserWalletOverviewSaga(): Generator<any> {
  yield put(getUserWalletOverviewFetching(true));
  try {
    const data: any = yield handleGetUserWalletOverview();
    if (data && data?.status === 200) {
      yield put(getUserWalletOverviewSuccess(data));
    } else {
      yield put(getUserWalletOverviewError("error"));
    }
  } catch (error) {
    yield put(getUserWalletOverviewError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getUserWalletOverviewFilter(action.payload));
}
export function* watchGetUserWalletOverview() {
  yield takeEvery(
    GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW,
    getUserWalletOverviewSaga
  );
  yield debounce(
    500,
    GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
