import { put, takeEvery, select } from 'redux-saga/effects';
import { handleDeleteWalletBO } from 'src/apis/service/Wallet';
import { toastSuccess, toastError } from 'src/helpers/toastHelper';
import { getListWallet } from 'src/store/actions';
import {
  DeleteWalletAdminBOType,
  deleteWalletAdminBOAtFetching,
  deleteWalletAdminBOSuccess,
  deleteWalletAdminBOFaild,
} from 'src/store/actions/WalletAdminBO/deleteWalletAdminBO';
import { getAllWalletSuperAdminBO } from 'src/store/actions/WalletAdminBO/getWalletSuperAdminBO/action';
import { IApplicationState } from 'src/store/IApplicationState';
import { getUserInfor } from 'src/utils/storage';

function* deleteWalletAdminBOSaga(action: any): Generator<any> {
  yield put(deleteWalletAdminBOAtFetching(true));
  const { payload } = action;
  try {
    const response: any = yield handleDeleteWalletBO(payload);
    if (response.status === 200) {
      //  const filter : any = yield select((state:IApplicationState) => state.listAllWalletAdmin.filter)
      const userInfo = getUserInfor();
      const { userId } = userInfo;
      yield put(deleteWalletAdminBOSuccess(response.data));
      // yield put(getAllWalletSuperAdminBO({filter,userId}))
      // yield put(getListWallet(userId, true))
      toastSuccess('bo_deleted_wallet_admin_successfully');
    } else {
      toastError(response?.message);
    }
  } catch (error) {
    toastError('bo_delete_wallet_admin_failed');
    yield put(deleteWalletAdminBOFaild(error));
  }
}

export function* watchDeleteWalletAdminBO() {
  yield takeEvery(
    DeleteWalletAdminBOType.DELETE_WALLET_ADMIN_BO,
    deleteWalletAdminBOSaga
  );
}
