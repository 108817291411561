import { ListParams } from "src/models/common";

interface IDataApplyKycDeclaration {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface IApplyKycDeclarationState {
    data: IDataApplyKycDeclaration;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
    successCount: number
}

export const initialApplyKycDeclarationState: IApplyKycDeclarationState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {},
    pagination: {
        totalCount: 0,
    },
    successCount: 0
}


