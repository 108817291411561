import {HandleRetryTransactionTypes} from './actionTypes';
import { IHandleRetryTransaction, IHandleRetryTransactionFetching, IHandleRetryTransactionSuccess, IHandleRetryTransactionError, IHandleRetryTransactionFilter, IHandleRetryTransactionFiterDebounce } from "./interfaces";


export const handleRetryTransaction = (payload:Object): IHandleRetryTransaction => ({
    type: HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION,
    payload
})

export const handleRetryTransactionFetching = (isFetching:boolean) : IHandleRetryTransactionFetching => ({
    type: HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_FETCHING,
    isFetching
})

export const handleRetryTransactionSuccess = (data:any) : IHandleRetryTransactionSuccess => ({
   type: HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_SUCCESS,
   data
})

export const handleRetryTransactionError = (errorMsg:any) : IHandleRetryTransactionError => ({
    type: HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_FAILD,
    error:errorMsg
})

export const handleRetryTransactionFilter = (payload: any): IHandleRetryTransactionFilter => ({
    type: HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_FILTER,
    payload
})

export const handleRetryTransactionFilterDebounce = (payload: any): IHandleRetryTransactionFiterDebounce => ({
    type: HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_FILTER_DEBOUNCE,
    payload 
})
