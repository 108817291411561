import { UpdateExchangeOfferType } from './actionTypes';
import { IUpdateExchangeOffer, IFetchingUpdateExchangeOffer, IUpdateExchangeOfferSuccess, IUpdateExchangeOfferError } from './interfaces';
import { History } from 'history';

export const updateExchangeOfferIsFetching = (isFetching: boolean): IFetchingUpdateExchangeOffer => ({
    type: UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER_FETCHING,
    isFetching
})

export const updateExchangeOffer = (payload: Object): IUpdateExchangeOffer => ({
    type: UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER,
    payload
})

export const updateExchangeOfferSuccess = (data: any): IUpdateExchangeOfferSuccess => ({
    type: UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER_SUCCESS,
    data
})

export const updateExchangeOfferFaild = (error: any): IUpdateExchangeOfferError => ({
    type: UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER_ERROR,
    error
})