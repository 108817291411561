import {put, takeEvery,debounce} from 'redux-saga/effects';
import { getAllAdminBO } from 'src/apis/service/Admin-management';
import { GetAllAdminBOType, getAdminBOAtFetching, getAdminBOSuccess, getAdminBOFaild,setFilterAdminBO  } from 'src/store/actions/admin-managements/getAllAdminBO';

function* getAllAdminBOSaga(action:any):Generator<any> {
    yield put(getAdminBOAtFetching(true))
    const {payload} = action;
    try {
      const data: any = yield getAllAdminBO(payload)
      if(data.status === 200){
        yield put(getAdminBOSuccess(data))
      }  
    } catch (error) {
        yield put(getAdminBOFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterAdminBO(action.payload));
}
export function* watchGetAllAdminBO() {
   yield takeEvery(GetAllAdminBOType.GET_ADMIN_BO, getAllAdminBOSaga)
   yield debounce(500,GetAllAdminBOType.FILTER_DEBOUNCE_ADMIN_BO, handleSearchDebounce)
}