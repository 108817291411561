import {GetKycDetailTypes} from './actionTypes';
import {
    IGetListFiterDebounceKycDetail,
    IGetListFiterKycDetail,
    IGetKycDetail,
    IGetKycDetailFaild,
    IGetKycDetailFetching,
    IGetKycDetailSuccess,
    IResetState
} from './interfaces';

export const getKycDetailAtFetching = (isFetching:boolean) : IGetKycDetailFetching  => ({
    type: GetKycDetailTypes.GET_KYC_DETAIL_FETCHING,
    isFetching
})

export const getKycDetail = (payload: Object) : IGetKycDetail => ({
    type: GetKycDetailTypes.GET_KYC_DETAIL,
    payload
})

export const getKycDetailSuccess = (data:any) : IGetKycDetailSuccess => ({
    type: GetKycDetailTypes.GET_KYC_DETAIL_SUCCESS,
    data
})

export const getKycDetailFaild = (error:any) : IGetKycDetailFaild => ({
    type: GetKycDetailTypes.GET_KYC_DETAIL_FAILD,
    error
})

export const setFilterKycDetail = (payload: any): IGetListFiterKycDetail => ({
    type: GetKycDetailTypes.GET_LIST_FILTER_KYC_DETAIL,
    payload
})

export const setFilterDebounceKycDetail = (payload: any): IGetListFiterDebounceKycDetail => ({
    type: GetKycDetailTypes.GET_LIST_FILTER_DEBOUNCE_KYC_DETAIL,
    payload 
})

export const resetState = () : IResetState => ({
    type: GetKycDetailTypes.RESET_STATE
})