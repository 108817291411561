import { toastSuccess, toastError } from './../../../helpers/toastHelper';
import { getP2PExRankAndScore } from './../../actions/getP2PExRankAndScore/action';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleAdminP2PSetAgentScore } from 'src/apis/service/Priority';
import { AdminP2PSetAgentScoreTypes } from '../../actions/adminP2PSetAgentScore';
import { adminP2PSetAgentScoreError, adminP2PSetAgentScoreFetching, adminP2PSetAgentScoreFilter, adminP2PSetAgentScoreSuccess } from '../../actions/adminP2PSetAgentScore/action';

function* adminP2PSetAgentScoreSaga(action:any):Generator<any> {


    yield put(adminP2PSetAgentScoreFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleAdminP2PSetAgentScore(payload)
      if(data && data?.status === 200){
        yield put(adminP2PSetAgentScoreSuccess(data))
        // yield put(getP2PExRankAndScore())
        toastSuccess('bo_set_score_for_user_success')
      } else {
        toastError('bo_set_score_for_user_fail')
        yield put(adminP2PSetAgentScoreError('error'))
      }
    } catch (error) {
        yield put(adminP2PSetAgentScoreError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(adminP2PSetAgentScoreFilter(action.payload));
}
export function* watchAdminP2PSetAgentScore() {
   yield takeEvery(AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE, adminP2PSetAgentScoreSaga)
   yield debounce(500,AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_FILTER_DEBOUNCE,handleSearchDebounce)
}
