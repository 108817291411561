import { LocalStorageKey } from 'src/contants/constantUtils/localStorageKey';
import parseJwt from '../parseJwt';

export function setStorageItem(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

export function getStorageItem(key: string) {
  return window.localStorage.getItem(key);
}

export function removeStorageItem(key: string) {
  // Removes the key/value pair with the given key
  return window.localStorage.removeItem(key);
}

export function clearStorageItems() {
  // Remove all local storage items
  return Object.keys(localStorage)
    .filter(x =>
      !x.startsWith('NCD_')) // No clear data
    .forEach(x =>
      localStorage.removeItem(x))
  // return window.localStorage.clear();
}

export function getUserInfor() {
  const userInfor = window.localStorage.getItem('user');
  return userInfor && JSON.parse(userInfor);
}

export function isValidToken(accessToken: string | null) {
  if (!accessToken) {
    return false;
  }

  const decoded = parseJwt(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded?.exp > currentTime;
}

export function getListCurrency() {
  const currencies = window.localStorage.getItem('NCD_currencies');
  return currencies && JSON.parse(currencies);
}

export function getListWallet() {
  const wallets = window.localStorage.getItem('listWallet');
  return wallets && JSON.parse(wallets);
}

export function getListCountry() {
  const countries = window.localStorage.getItem('NCD_countries');
  return countries && JSON.parse(countries);
}

export function getListLanguages() {
  const languages = window.localStorage.getItem('NCD_languages');
  return languages && JSON.parse(languages);
}

export function getListCryptoCurrency() {
  const cryptoCurrency = window.localStorage.getItem('NCD_listCryptoCurrency');
  return cryptoCurrency && JSON.parse(cryptoCurrency);
}

export function getLangEN() {
  return window.localStorage.getItem('listLanguageEN');
}

export function getLangKO() {
  return window.localStorage.getItem('listLanguageKO');
}


export function getUserLang() {
  return window.localStorage.getItem('NCD_webLanguage') ?? 'en';
}

export function getCryptoSetting() {
  const cryptoSetting = window.localStorage.getItem(LocalStorageKey.CryptoSetting);
  return cryptoSetting && JSON.parse(cryptoSetting);
}