import { AnyAction, Reducer } from 'redux';
import { IHandleNotiRefreshDataState, initialHandleNotiRefreshDataState } from './state';
import { HandleNotiRefreshDataTypes } from 'src/store/actions/handleNotiRefreshData';

export const HandleNotiRefreshDataReducer: Reducer = (
  state: IHandleNotiRefreshDataState = initialHandleNotiRefreshDataState,
  action: AnyAction
) => {
  switch (action.type) {
    case HandleNotiRefreshDataTypes.HANDLE_NOTI_REFRESH_DATA: {
      return {
        ...state
      };
    }
    case HandleNotiRefreshDataTypes.HANDLE_NOTI_REFRESH_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data
      };
    }
    default: {
      return state;
    }
  }
};
