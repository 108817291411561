export interface RemoveLanguageText {
    status: number,
    data: any,
    message: string
}

export interface RemoveLanguageTextState {
    data: RemoveLanguageText,
    isFetching: boolean,
    success: boolean
}

export const initialRemoveLanguageTextState: RemoveLanguageTextState = {
    data: {
        data: {},
        message: '',
        status: 0
    },
    isFetching: false,
    success: false
}