import { GetP2PBuyTransactionsType } from './actionTypes';
import { IFetchingGetP2PBuyTransactions, IGetListFilterDebounceP2PBuyTransactions, IGetListFilterP2PBuyTransactions, IGetP2PBuyTransactions, IGetP2PBuyTransactionsError, IGetP2PBuyTransactionsSuccess } from './interfaces';

export const getP2PBuyTransactionsIsFetching = (isFetching:boolean) : IFetchingGetP2PBuyTransactions => ({
    type: GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS_FETCHING,
    isFetching
})

export const getP2PBuyTransactions = (payload: Object) : IGetP2PBuyTransactions => ({
    type: GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS,
    payload
})

export const getP2PBuyTransactionsSuccess = (data:any) : IGetP2PBuyTransactionsSuccess => ({
    type: GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS_SUCCESS,
    data
})

export const getP2PBuyTransactionsFaild = (error:any) : IGetP2PBuyTransactionsError => ({
    type: GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS_ERROR,
    error
})

export const setFilterP2PBuyTransactions = (payload: any): IGetListFilterP2PBuyTransactions => ({
    type: GetP2PBuyTransactionsType.SET_LIST_FILTER_P2P_BUY_TRANSACTIONS,
    payload
})

export const setFilterDebounceP2PBuyTransactions = (payload: any): IGetListFilterDebounceP2PBuyTransactions => ({
    type: GetP2PBuyTransactionsType.SET_LIST_FILTER_DEBOUNCE_P2P_BUY_TRANSACTIONS,
    payload 
})