import { ListParams } from "src/models/common";

interface IDataAgentChangePINCode {
  data: boolean;
  error: any;
  success: boolean;
}

export interface IAgentChangePINCodeState {
  data: IDataAgentChangePINCode;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialAgentChangePINCodeState: IAgentChangePINCodeState = {
  data: {
    data: false,
    error: null,
    success: false,
  },
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
