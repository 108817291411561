import { KYC_STAGE_STATUS } from 'src/contants';
import { ListParams } from 'src/models/common';

interface IDataGetKYCStatus {
  address: string;
  createdDate: string;
  documentation: string;
  financialQuestionnaire: string;
  kycDeclaration: string;
  kycId: string;
  kycStage: KYC_STAGE_STATUS;
  kycSystemStatus: false;
  modifiedDate: string;
  nameAndContact: string;
  requireKYCStatus: false;
  status: number;
  transactionTimeOfUser: number;
  userId: string;
}

export interface IGetKYCStatusState {
  data: IDataGetKYCStatus | null;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
  error: any;
  success: boolean;
}

export const initialGetKYCStatusState: IGetKYCStatusState = {
  data: null,
  error: null,
  success: false,
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
