import { SetUserLanguageTypes } from './actionTypes';
import {
  ISetUserLanguage,
  ISetUserLanguageFetching,
  ISetUserLanguageSuccess,
  ISetUserLanguageError,
  ISetUserLanguageFilter,
  ISetUserLanguageFiterDebounce,
} from './interfaces';

export const setUserLanguage = (payload?: number): ISetUserLanguage => ({
  type: SetUserLanguageTypes.SET_USER_LANGUAGE,
  payload,
});

export const setUserLanguageFetching = (
  isFetching: boolean
): ISetUserLanguageFetching => ({
  type: SetUserLanguageTypes.SET_USER_LANGUAGE_FETCHING,
  isFetching,
});

export const setUserLanguageSuccess = (data: any): ISetUserLanguageSuccess => ({
  type: SetUserLanguageTypes.SET_USER_LANGUAGE_SUCCESS,
  data,
});

export const setUserLanguageError = (errorMsg: any): ISetUserLanguageError => ({
  type: SetUserLanguageTypes.SET_USER_LANGUAGE_FAILD,
  error: errorMsg,
});

export const setUserLanguageFilter = (
  payload: any
): ISetUserLanguageFilter => ({
  type: SetUserLanguageTypes.SET_USER_LANGUAGE_FILTER,
  payload,
});

export const setUserLanguageFilterDebounce = (
  payload: any
): ISetUserLanguageFiterDebounce => ({
  type: SetUserLanguageTypes.SET_USER_LANGUAGE_FILTER_DEBOUNCE,
  payload,
});
