export interface DeactivateUser  {
    data:any,
    errors:any,
    success:any
}

export interface DeactivateUserState {
    data: DeactivateUser,
    isFetching:boolean
}

export const initialDeactivateUserState: DeactivateUserState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}