interface IDataAddUserBankAccountAgent {
    data: any;
    message: string;
    status: number;
}

export interface IDataAddUserBankAccountAgentState {
   data: IDataAddUserBankAccountAgent,
   isFetching: boolean
}

export const initialAddBankAccAgentState : IDataAddUserBankAccountAgentState = {
    data: {
        data:null,
        message:'',
        status:0
    },
    isFetching:false
}