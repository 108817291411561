import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetDashboardDataAPI } from 'src/apis/service/CryptoWallet';
import {
    cryptoWalletDashboardIsFetching,
    cryptoWalletDashboardReqFailed,
    cryptoWalletDashboardReqSuccess,
    CryptWalletDashboardActionTypes
} from "../../actions/cryptoWalletDashboard";
import { has } from 'lodash';

function* getCryptoWalletDashboardSaga(action: any): Generator<any> {
    yield put(cryptoWalletDashboardIsFetching(true))
    try {
        const data: any = yield call(handleGetDashboardDataAPI, action.action)
        if (data && data?.status === 200) {
            if (has(data, 'data.transactionsByDay[0]')) data.data.transactionsByDay.shift()
            yield put(cryptoWalletDashboardReqSuccess(data))
        } else {
            yield put(cryptoWalletDashboardReqFailed('error'))
        }
    } catch (error) {
        yield put(cryptoWalletDashboardReqFailed(error))
    }
}

export function* watchCryptoWalletDashboard() {
    yield takeEvery(CryptWalletDashboardActionTypes.GET_CRYPTO_WALLET_DASHBOARD_DATA, getCryptoWalletDashboardSaga)
}
