export interface CreateNotification  {
    data:any,
    errors:any,
    success:any
}

export interface CreateNotificationState {
    data: CreateNotification,
    isFetching:boolean
}

export const initialCreateNotificationState: CreateNotificationState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}