import {CreateScorePeriodConfigTypes} from './actionTypes';
import { ICreateScorePeriodConfig, ICreateScorePeriodConfigFetching, ICreateScorePeriodConfigSuccess, ICreateScorePeriodConfigError, ICreateScorePeriodConfigFilter, ICreateScorePeriodConfigFiterDebounce } from "./interfaces";


export const createScorePeriodConfig = (payload:Object): ICreateScorePeriodConfig => ({
    type: CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG,
    payload
})

export const createScorePeriodConfigFetching = (isFetching:boolean) : ICreateScorePeriodConfigFetching => ({
    type: CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_FETCHING,
    isFetching
})

export const createScorePeriodConfigSuccess = (data:any) : ICreateScorePeriodConfigSuccess => ({
   type: CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_SUCCESS,
   data
})

export const createScorePeriodConfigError = (errorMsg:any) : ICreateScorePeriodConfigError => ({
    type: CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_FAILD,
    error:errorMsg
})

export const createScorePeriodConfigFilter = (payload: any): ICreateScorePeriodConfigFilter => ({
    type: CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_FILTER,
    payload
})

export const createScorePeriodConfigFilterDebounce = (payload: any): ICreateScorePeriodConfigFiterDebounce => ({
    type: CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_FILTER_DEBOUNCE,
    payload 
})
