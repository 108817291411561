import { put, takeEvery } from 'redux-saga/effects';
import { handleGetListHistoryNote } from 'src/apis/service/Kyc';
import { 
  getKycListHistoryNoteAtFetching,
  getKycListHistoryNoteFaild,
  getKycListHistoryNoteSuccess,
  GetKycHistoryNoteTypes
} from 'src/store/actions/kycHistoryNote';

function* getKycListHistoryNoteSaga(action:any):Generator<any> {
    yield put(getKycListHistoryNoteAtFetching(true))
    const {payload} = action;
    try {
      const dataKycListHistoryNote:any = yield handleGetListHistoryNote(payload)
      if(dataKycListHistoryNote?.status === 200) {
        yield put(getKycListHistoryNoteSuccess(dataKycListHistoryNote))
      } else {
        yield put(getKycListHistoryNoteFaild('error'))
      }
    } catch (error) {
        yield put(getKycListHistoryNoteFaild(error))
    }
}

export function* watchGetListKycHistoryNote() {
   yield takeEvery(GetKycHistoryNoteTypes.GET_KYC_HISTORY_NOTE, getKycListHistoryNoteSaga)
}