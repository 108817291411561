import { AnyAction, Reducer } from 'redux';
import { initialRemoveExchangeOfferState, RemoveExchangeOfferState } from './state';
import { RemoveExchangeOfferType } from '../../actions/removeExchangeOffer/actionTypes';

export const RemoveExchangeOfferReducer: Reducer = (
  state: RemoveExchangeOfferState = initialRemoveExchangeOfferState,
  action: AnyAction
) => {
  switch (action.type) {
    case RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case RemoveExchangeOfferType.REMOVE_EXCHANGE_OFFER_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
