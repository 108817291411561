import {ExportExchangeType} from './actionTypes';
import {
    IExportExchangeIsFetching,
    IExportExchangeSuccess,
    IExportExchangeFaild,
    IExportExchange
} from './interfaces';

export const exportExchangeAtFetching = (isFetching:boolean) : IExportExchangeIsFetching  => ({
    type: ExportExchangeType.EXPORT_EXCHANGE_FETCHING,
    isFetching
})

export const exportExchange = (payload:Object, xlsx:boolean) : IExportExchange => ({
    type: ExportExchangeType.EXPORT_EXCHANGE,
    payload,
    xlsx
})

export const exportExchangeSuccess = (data:any) : IExportExchangeSuccess => ({
    type: ExportExchangeType.EXPORT_EXCHANGE_SUCCESS,
    data
})

export const exportExchangeFaild = (error:any) : IExportExchangeFaild => ({
    type: ExportExchangeType.EXPORT_EXCHANGE_FAILD,
    error
})
