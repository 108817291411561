import { ListParams } from 'src/models/common';
import { GetCryptoActivatedWalletListType } from './actionTypes';
import { IFetchingGetCryptoActivatedWalletList, IGetCryptoActivatedWalletList, IGetCryptoActivatedWalletListError, IGetCryptoActivatedWalletListSuccess, IGetListFilterCryptoActivatedWalletList } from './interfaces';

export const getCryptoActivatedWalletListIsFetching = (isFetching:boolean) : IFetchingGetCryptoActivatedWalletList => ({
    type: GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST_FETCHING,
    isFetching
})

export const getCryptoActivatedWalletList = (payload: object) : IGetCryptoActivatedWalletList => ({
    type: GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST,
    payload
})

export const getCryptoActivatedWalletListSuccess = (data:any) : IGetCryptoActivatedWalletListSuccess => ({
    type: GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST_SUCCESS,
    data
})

export const getCryptoActivatedWalletListFaild = (error:any) : IGetCryptoActivatedWalletListError => ({
    type: GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST_ERROR,
    error
})

export const setFilterCryptoActivatedWalletList = (payload: ListParams): IGetListFilterCryptoActivatedWalletList => ({
    type: GetCryptoActivatedWalletListType.GET_LIST_FILTER_CRYPTO_ACTIVATED_WALLET_LIST,
    payload
})