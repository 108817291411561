import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { getDataBankAccount } from './../../actions/getDataBankAccount/action';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleUpdateUserBankAccount } from 'src/apis/service/BankAccount';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { EditUserBankInfoTypes } from '../../actions/editUserBankInfo';
import { editUserBankInfoError, editUserBankInfoFetching, editUserBankInfoFilter, editUserBankInfoSuccess } from '../../actions/editUserBankInfo/action';
function* editUserBankInfoSaga(action: any): Generator<any> {

  yield put(editUserBankInfoFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleUpdateUserBankAccount(payload)
    if (data && data?.status === 200) {
      yield put(editUserBankInfoSuccess(data));
      const payloadFilter = {
        userId: payload.userId,
        pageNumber: '1',
        pageSize: `${PAGE_SIZE_DEFAULT}`
      };
      yield put(getDataBankAccount(payloadFilter));
      toastSuccess('bo_bank_infomation_updated');
    } else {
      yield put(editUserBankInfoError('error'))
      toastError('bo_you_can_t_update_bank_infomation');
    }
  } catch (error) {
    toastError('bo_you_can_t_update_bank_infomation');
    yield put(editUserBankInfoError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(editUserBankInfoFilter(action.payload));
}
export function* watchEditUserBankInfo() {
  yield takeEvery(EditUserBankInfoTypes.EDIT_USER_BANK_INFO, editUserBankInfoSaga)
  yield debounce(500, EditUserBankInfoTypes.EDIT_USER_BANK_INFO_FILTER_DEBOUNCE, handleSearchDebounce)
}
