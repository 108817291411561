import { AnyAction, Reducer } from 'redux';
import { UpdateKycStepStatusType } from 'src/store/actions';
import { IListKycStepStatusUpdateState, initialListKycStepStatusUpdateState } from './state';

export const GetListKycStepStatusUpdateReducer: Reducer = (
  state: IListKycStepStatusUpdateState = initialListKycStepStatusUpdateState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        success: true
      }
    }
    case UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS_RESET: {
      return {
        ...state,
        success: false
      }
    }
    default:
      return state;
  }
};