import {put, takeEvery, select} from 'redux-saga/effects';
import { handleTransactionManualpayment } from 'src/apis/service/Transactions';
import { 
  transactionManualPaymentBuySuccess,
  transactionManualPaymentSellSuccess,
  transactionManualPaymentHistoryIsFetching,
  transactionManualPaymentHistoryFaild
} from 'src/store/actions/transactionManualPaymentHistory/action';
import { TransactionManualPaymentType } from 'src/store/actions/transactionManualPaymentHistory/actionTypes';


function* transactionManualPaymentHistorySaga(action:any):Generator<any>{
  yield put(transactionManualPaymentHistoryIsFetching(true))
  const {payload, transactionType} = action;
  try {
    const data: any = yield handleTransactionManualpayment(payload);
    if (data.status === 200) {
       if (transactionType === 'BUY') {
        yield put(transactionManualPaymentBuySuccess(data)) 
       }
       if (transactionType === 'SELL') {
        yield put(transactionManualPaymentSellSuccess(data)) 
       }
    } else {
      yield put(transactionManualPaymentHistoryFaild('error')) 
    }
  } catch (error) {
     yield put(transactionManualPaymentHistoryFaild(error)) 
  }
}
export function* watchTransactionManualPaymentHistory(){
    yield takeEvery(TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY, transactionManualPaymentHistorySaga)
}