import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetTransactionOverview, handleGetUserOverview } from 'src/apis/service/Dashboard';
import { getDashboardFaild, getDashboardIsFetching, getDashboardSuccess, GetDashboardType } from 'src/store/actions';

function* getTransactionOverviewSaga(): Generator<any>  {
  const data: any = yield call(handleGetTransactionOverview)
  if(data?.status === 200) {
    return data?.data
  } else {
    getDashboardFaild('Can not get transaction information overview!')
  }
}

function* getUserOverviewSaga(): Generator<any>  {
  const data: any = yield call(handleGetUserOverview);
  if(data?.status === 200) {
    return data?.data
  } else {
    getDashboardFaild('Can not get user information overview!')
  }   
}

function* fetchDashboard(): any {
  yield put(getDashboardIsFetching(true))
  try {
    // const [a, b] = yield all([
    //   call(getTransactionOverviewSaga),
    //   call(getUserOverviewSaga),
    // ]);
    const a = yield call(getTransactionOverviewSaga);
    const b = yield call(getUserOverviewSaga);
    const dashboardData = {...a, ...b}
    yield put(getDashboardSuccess(dashboardData));
  } catch (error) {
    yield put(getDashboardFaild('error'))
  }
}

export function* watchGetListDashboard() {
   yield takeEvery(GetDashboardType.GET_DASHBOARD , fetchDashboard)
}