import { put, takeEvery } from 'redux-saga/effects';
import { handleExportDirectTransfer } from 'src/apis/service/Offer';
import { ExportDirectTransferType } from 'src/store/actions';
import {
  exportDirectTransferAtFetching, exportDirectTransferFaild, exportDirectTransferSuccess
} from '../../actions/ExportDirectTransfer/action';
function* exportDirectTransferSaga(action: any): Generator<any> {
  yield put(exportDirectTransferAtFetching(true));
  const { payload, xlsx } = action;
  try {
    let data: any = yield handleExportDirectTransfer(payload);
    if (data) {
      const b64toBlob = (b64Data:any, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        return new Blob(byteArrays, {type: contentType});
      }
      if (xlsx) {
        const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = b64toBlob(data.data.fileStream,contentType)
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `transaction.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        const contentType = 'text/csv;charset=utf-8;';
        const blob = b64toBlob(data.data.fileStream,contentType)
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url;
        link.setAttribute('download', `transaction.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      yield put(exportDirectTransferSuccess(data));
    }
  } catch (error) {
    yield put(exportDirectTransferFaild(error));
  }
}
export function* watchExportDirectTransfer() {
  yield takeEvery(ExportDirectTransferType.EXPORT_DIRECT_TRANSFER, exportDirectTransferSaga);
}
