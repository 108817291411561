import { AnyAction, Reducer } from "redux";
import {
  IGetUserWalletOverviewState,
  initialGetUserWalletOverviewState,
} from "./state";
import { GetUserWalletOverviewTypes } from "src/store/actions/getUserWalletOverview";

export const GetUserWalletOverviewReducer: Reducer = (
  state: IGetUserWalletOverviewState = initialGetUserWalletOverviewState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_SUCCESS: {
      return {
        ...state,
        data: action.data.data,
        isFetching: false,
      };
    }
    case GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_FAILD: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case GetUserWalletOverviewTypes.GET_USER_WALLET_OVERVIEW_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
