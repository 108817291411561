import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetListTopupRequest } from 'src/apis/service/CryptoWallet';
import { GetListTopupRequestTypes } from '../../actions/getListTopupRequest';
import { getListTopupRequestError, getListTopupRequestFetching, getListTopupRequestFilter, getListTopupRequestSuccess } from '../../actions/getListTopupRequest/action';

function* getListTopupRequestSaga(action:any):Generator<any> {
    yield put(getListTopupRequestFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetListTopupRequest(payload)
      if(data && data?.status === 200){
        yield put(getListTopupRequestSuccess(data))
      } else {
        yield put(getListTopupRequestError('error'))
      }
    } catch (error) {
        yield put(getListTopupRequestError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListTopupRequestFilter(action.payload));
}
export function* watchGetListTopupRequest() {
   yield takeEvery(GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST, getListTopupRequestSaga)
   yield debounce(500,GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_FILTER_DEBOUNCE,handleSearchDebounce)
}
