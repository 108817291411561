
interface ISetMainPostAnnouncements {
   data: any;
   message: string;
   status: number
}

export interface ISetMainPostAnnouncementsState {
   data: ISetMainPostAnnouncements;
   isFetching: boolean,
   successCount: number
}

export const initialSetMainPostAnnouncementsState: ISetMainPostAnnouncementsState = {
   data: {
      data: false,
      message: '',
      status: 0
   },
   isFetching: false,
   successCount: 0

}