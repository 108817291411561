import { AnyAction, Reducer } from 'redux';
import { initialTransactionManualPaymentHistoryState, TransactionManualPaymentHistoryState } from './state';
import { TransactionManualPaymentType } from '../../actions/transactionManualPaymentHistory/actionTypes';


export const TransactionManualPaymentHistoryReducer: Reducer = (
  state: TransactionManualPaymentHistoryState = initialTransactionManualPaymentHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY_BUY_SUCCESS: {
      return {
        ...state,
        dataBuy: action.data,
        isFetching: false,
      };
    }
    case TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY_SELL_SUCCESS: {
      return {
        ...state,
        dataSell: action.data,
        isFetching: false,
      };
    }
    case TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
