import {
  KycDetailParam,
  KycSetting,
  KycStage,
  KycStepStatus,
  KycUpdateStatus,
} from './../../../models/KycUser';
import Request from '../../config';
import * as Sentry from '@sentry/react';
import { ListParams } from '../../../models/common';

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_API });

const getKycList = async (params: ListParams): Promise<any> => {
  const url = `/KYC/BO/GetListKyc`;
  try {
    return await requestApi.post(url, params);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getKycDetail = async (params: KycDetailParam): Promise<any> => {
  const url = `/KYC/BO/GetKycDetail?kycId=${params}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const setKycUpdateStatus = async (params: KycUpdateStatus): Promise<any> => {
  const url = `/KYC/BO/GetKycDetail`;
  try {
    return await requestApi.post(url, params);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const setKycUpdateStage = async (params: KycStage): Promise<any> => {
  const url = `/KYC/BO/ChangeKycStage`;
  try {
    return await requestApi.post(url, params);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const setKycUpdateStepStatus = async (params: KycStepStatus): Promise<any> => {
  const url = `/KYC/BO/UpdateStatus`;
  try {
    return await requestApi.post(url, params);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const setKycSetting = async (params: KycSetting): Promise<any> => {
  const url = `/KYC/BO/OnOffStatusKyc`;
  try {
    return await requestApi.post(url, params);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
interface DataPayload {
  kycId: string;
  pageNumber: number;
  pageSize: number;
}
const getKycListHistory = async (data: DataPayload): Promise<any> => {
  const url = '/KYC/BO/History';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
interface PayloadSendNoteKyc {
  kycId: string;
  rejectReason: string;
  userId: string;
}

interface PayloadListHistoryKYCNote {
  kycId: string;
  userId: string;
  pageNumber: number;
  pageSize: number;
}
const handleSendNoteKyc = async (data: PayloadSendNoteKyc): Promise<any> => {
  const url = '/KYC/BO/CreateNote';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListHistoryNote = async (
  data: PayloadListHistoryKYCNote
): Promise<any> => {
  const url = '/KYC/BO/GetNote';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  getKycList,
  getKycDetail,
  setKycUpdateStatus,
  setKycSetting,
  setKycUpdateStage,
  setKycUpdateStepStatus,
  getKycListHistory,
  handleSendNoteKyc,
  handleGetListHistoryNote,
};
