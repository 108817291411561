import { AnyAction, Reducer } from 'redux';
import { DeleteAnnouncementsType } from 'src/store/actions';
import { DeleteAnnouncementsState, initialDeleteAnnouncementsState } from './state';

export const DeleteAnnouncementsReducer: Reducer = (
  state: DeleteAnnouncementsState = initialDeleteAnnouncementsState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
