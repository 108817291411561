import { ListParams } from 'src/models/common';

interface IDataGetFileById {
  data: any;
  error: any;
  success: boolean;
}

export interface IGetFileByIdState {
  data: IDataGetFileById;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialGetFileByIdState: IGetFileByIdState = {
  data: {
    data: undefined,
    error: null,
    success: false,
  },
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
