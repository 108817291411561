import { GetKYCFuncByIdTypes } from './actionTypes';

export const getKYCFuncByIdFetch = () => ({
  type: GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID,
});

export const getKYCFuncByIdFetching = () => ({
  type: GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID_FETCH,
});

export const getKYCFuncByIdSuccess = (data: number[]) => ({
  type: GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID_SUCCESS,
  data,
});

export const getKYCFuncByIdError = () => ({
  type: GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID_ERROR,
});
