import { put, takeEvery } from 'redux-saga/effects';
import { handleCreateExchangeOffer } from 'src/apis/service/P2PExchange';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { createExchangeOfferFaild, createExchangeOfferIsFetching, createExchangeOfferSuccess } from '../../actions/createExchangeOffer/action';
import { CreateExchangeOfferType } from '../../actions/createExchangeOffer/actionTypes';
function* createExchangeOfferSaga(action: any): Generator<any> {


  yield put(createExchangeOfferIsFetching(true))
  const { payload, history } = action;
  try {
    const dataCreateExchangeOffer: any = yield handleCreateExchangeOffer(payload)
    if (dataCreateExchangeOffer?.status === 200) {
      yield put(createExchangeOfferSuccess(dataCreateExchangeOffer))
      toastSuccess('bo_create_exchange_offer_success')
    } else {
      toastError('bo_create_exchange_offer_fail')
    }
  } catch (error) {
    yield put(createExchangeOfferFaild(error))
  }
}
export function* watchCreateExchangeOffer() {
  yield takeEvery(CreateExchangeOfferType.CREATE_EXCHANGE_OFFER, createExchangeOfferSaga)
}