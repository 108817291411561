import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetListTokenSupport } from 'src/apis/service/Agent';
import { GetListTokenSupportTypes } from '../../actions/getListTokenSupport';
import { getListTokenSupportError, getListTokenSupportFetching, getListTokenSupportFilter, getListTokenSupportSuccess } from '../../actions/getListTokenSupport/action';

function* getListTokenSupportSaga():Generator<any> {
    yield put(getListTokenSupportFetching(true))
    try {
      const data:any = yield handleGetListTokenSupport()
      if(data && data?.status === 200){
        yield put(getListTokenSupportSuccess(data))
      } else {
        yield put(getListTokenSupportError('error'))
      }
    } catch (error) {
        yield put(getListTokenSupportError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListTokenSupportFilter(action.payload));
}
export function* watchGetListTokenSupport() {
   yield takeEvery(GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT, getListTokenSupportSaga)
   yield debounce(500,GetListTokenSupportTypes.GET_LIST_TOKEN_SUPPORT_FILTER_DEBOUNCE,handleSearchDebounce)
}
