import { InvoiceStatus } from "src/contants/invoice";
import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { ListParams } from "src/models/common";
import { UserRole } from "src/models/roles";
import roleTableService from "src/utils/roleTableService";

interface IDataAdminGetInvoiceList {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface IAdminGetInvoiceListState {
    data: IDataAdminGetInvoiceList;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
}

export const initialAdminGetInvoiceListState: IAdminGetInvoiceListState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT
    },
    pagination: {
        totalCount: 0,
    }
}
