import { GetP2PSellTransactionsType } from './actionTypes';
import { IFetchingGetP2PSellTransactions, IGetListFilterDebounceP2PSellTransactions, IGetListFilterP2PSellTransactions, IGetP2PSellTransactions, IGetP2PSellTransactionsError, IGetP2PSellTransactionsSuccess } from './interfaces';

export const getP2PSellTransactionsIsFetching = (isFetching:boolean) : IFetchingGetP2PSellTransactions => ({
    type: GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS_FETCHING,
    isFetching
})

export const getP2PSellTransactions = (payload: Object) : IGetP2PSellTransactions => ({
    type: GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS,
    payload
})

export const getP2PSellTransactionsSuccess = (data:any) : IGetP2PSellTransactionsSuccess => ({
    type: GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS_SUCCESS,
    data
})

export const getP2PSellTransactionsFaild = (error:any) : IGetP2PSellTransactionsError => ({
    type: GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS_ERROR,
    error
})

export const setFilterP2PSellTransactions = (payload: any): IGetListFilterP2PSellTransactions => ({
    type: GetP2PSellTransactionsType.SET_LIST_FILTER_P2P_SELL_TRANSACTIONS,
    payload
})

export const setFilterDebounceP2PSellTransactions = (payload: any): IGetListFilterDebounceP2PSellTransactions => ({
    type: GetP2PSellTransactionsType.SET_LIST_FILTER_DEBOUNCE_P2P_SELL_TRANSACTIONS,
    payload 
})