import { AnyAction, Reducer } from 'redux';
import { IGetRoleFunctionByAdminIdState, initialGetRoleFunctionByAdminIdState } from './state';
import { GetRoleFunctionByAdminIdTypes } from 'src/store/actions/getRoleFunctionByAdminId';

export const GetRoleFunctionByAdminIdReducer: Reducer = (
  state: IGetRoleFunctionByAdminIdState = initialGetRoleFunctionByAdminIdState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID: {
      return {
        ...state,
        isFetching: 0,
      };
    }
    case GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: 1,
        pagination: action.data.data.totalCount,
      };
    }
    case GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_FAILD: {
      return {
        ...state,
        isFetching: 1,
        error: action.error,
      };
    }
    case GetRoleFunctionByAdminIdTypes.GET_ROLE_FUNCTION_BY_ADMIN_ID_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetRoleFunctionByAdminIdTypes.CLEAN_ROLE_FUNCTION_BY_ADMIN_ID: {
      return {
        ...state,
        data: initialGetRoleFunctionByAdminIdState.data,
      };
    }
    default: {
      return state;
    }
  }
};
