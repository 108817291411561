import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetAdminTransactionUser } from 'src/apis/service/P2PTransaction';
import { GetAdminTransactionUserTypes } from '../../actions/getAdminTransactionUser';
import { getAdminTransactionUserError, getAdminTransactionUserFetching, getAdminTransactionUserFilter, getAdminTransactionUserSuccess } from '../../actions/getAdminTransactionUser/action';

function* getAdminTransactionUserSaga(action:any):Generator<any> {
    yield put(getAdminTransactionUserFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetAdminTransactionUser(payload)
      if(data && data?.status === 200){
        yield put(getAdminTransactionUserSuccess(data))
      } else {
        yield put(getAdminTransactionUserError('error'))
      }
    } catch (error) {
        yield put(getAdminTransactionUserError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAdminTransactionUserFilter(action.payload));
}
export function* watchGetAdminTransactionUser() {
   yield takeEvery(GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER, getAdminTransactionUserSaga)
   yield debounce(500,GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_FILTER_DEBOUNCE,handleSearchDebounce)
}
