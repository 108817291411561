import { AnyAction, Reducer } from 'redux';
import { ISet2FADisableState, initialSet2FADisableState } from './state';
import { Set2FADisableTypes } from 'src/store/actions/set2FADisable';

export const Set2FADisableReducer: Reducer = (
  state: ISet2FADisableState = initialSet2FADisableState,
  action: AnyAction
) => {
  switch (action.type) {
    case Set2FADisableTypes.SET2FA_DISABLE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case Set2FADisableTypes.SET2FA_DISABLE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case Set2FADisableTypes.SET2FA_DISABLE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case Set2FADisableTypes.SET2FA_DISABLE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
