import { GetP2PExRankAndScoreType } from "./actionTypes";
import { IGetP2PExRankAndScore, IGetP2PExRankAndScoreFetching, IGetP2PExRankAndScoreSuccess, IGetP2PExRankAndScoreError, IGetFilterP2PExRankAndScore, IGetFiterDebounceP2PExRankAndScore } from "./interfaces";


export const getP2PExRankAndScore = (payload?: any): IGetP2PExRankAndScore => ({
    type: GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE,
    payload
})

export const getP2PExRankAndScoreFetching = (isFetching: boolean): IGetP2PExRankAndScoreFetching => ({
    type: GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE_FETCHING,
    isFetching
})

export const getP2PExRankAndScoreSuccess = (data: any): IGetP2PExRankAndScoreSuccess => ({
    type: GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE_SUCCESS,
    data
})

export const getP2PExRankAndScoreError = (errorMsg: any): IGetP2PExRankAndScoreError => ({
    type: GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE_FAILD,
    error: errorMsg
})

export const setFilterP2PExRankAndScore = (payload: any): IGetFilterP2PExRankAndScore => ({
    type: GetP2PExRankAndScoreType.GET_FILTER_P2PEX_RANK_AND_SCORE,
    payload
})

export const setFilterDebounceP2PExRankAndScore = (payload: any): IGetFiterDebounceP2PExRankAndScore => ({
    type: GetP2PExRankAndScoreType.GET_FILTER_DEBOUNCE_P2PEX_RANK_AND_SCORE,
    payload
})