export interface Priority  {
    status: number,
    data: {
        firstPriority : number
    },
    message: string
}

export interface PriorityState {
    data: Priority,
    isFetching:boolean
}

export const initialPriorityState : PriorityState = {
    data:{
       data:{
        firstPriority: 0
       },
       message:'',
       status:0
    },
    isFetching:false
}