import {GetP2PExchangeHistoryAgentTypes} from './actionTypes';
import { IGetP2PExchangeHistoryAgent, IGetP2PExchangeHistoryAgentFetching, IGetP2PExchangeHistoryAgentSuccess, IGetP2PExchangeHistoryAgentError, IGetP2PExchangeHistoryAgentFilter, IGetP2PExchangeHistoryAgentFiterDebounce } from "./interfaces";


export const getP2PExchangeHistoryAgent = (payload:Object): IGetP2PExchangeHistoryAgent => ({
    type: GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT,
    payload
})

export const getP2PExchangeHistoryAgentFetching = (isFetching:boolean) : IGetP2PExchangeHistoryAgentFetching => ({
    type: GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_FETCHING,
    isFetching
})

export const getP2PExchangeHistoryAgentSuccess = (data:any) : IGetP2PExchangeHistoryAgentSuccess => ({
   type: GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_SUCCESS,
   data
})

export const getP2PExchangeHistoryAgentError = (errorMsg:any) : IGetP2PExchangeHistoryAgentError => ({
    type: GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_FAILD,
    error:errorMsg
})

export const getP2PExchangeHistoryAgentFilter = (payload: any): IGetP2PExchangeHistoryAgentFilter => ({
    type: GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_FILTER,
    payload
})

export const getP2PExchangeHistoryAgentFilterDebounce = (payload: any): IGetP2PExchangeHistoryAgentFiterDebounce => ({
    type: GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_FILTER_DEBOUNCE,
    payload 
})
