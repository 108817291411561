import {DeleteCryptoExchangeOffersListTypes} from './actionTypes';
import { IDeleteCryptoExchangeOffersList, IDeleteCryptoExchangeOffersListFetching, IDeleteCryptoExchangeOffersListSuccess, IDeleteCryptoExchangeOffersListError, IDeleteCryptoExchangeOffersListFilter, IDeleteCryptoExchangeOffersListFiterDebounce } from "./interfaces";


export const deleteCryptoExchangeOffersList = (payload:Object): IDeleteCryptoExchangeOffersList => ({
    type: DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST,
    payload
})

export const deleteCryptoExchangeOffersListFetching = (isFetching:boolean) : IDeleteCryptoExchangeOffersListFetching => ({
    type: DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_FETCHING,
    isFetching
})

export const deleteCryptoExchangeOffersListSuccess = (data:any) : IDeleteCryptoExchangeOffersListSuccess => ({
   type: DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS,
   data
})

export const deleteCryptoExchangeOffersListError = (errorMsg:any) : IDeleteCryptoExchangeOffersListError => ({
    type: DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD,
    error:errorMsg
})

export const deleteCryptoExchangeOffersListFilter = (payload: any): IDeleteCryptoExchangeOffersListFilter => ({
    type: DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER,
    payload
})

export const deleteCryptoExchangeOffersListFilterDebounce = (payload: any): IDeleteCryptoExchangeOffersListFiterDebounce => ({
    type: DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE,
    payload 
})
