import { GetCurrencyType } from './actionTypes';
import { IFetchingGetCurrency, IGetCurrency, IGetCurrencySuccess, IGetCurrencyError } from './interfaces';

export const getCurrencyIsFetching = (isFetching:boolean) : IFetchingGetCurrency => ({
    type: GetCurrencyType.GET_CURRENCY_FETCHING,
    isFetching
})

export const getCurrency = () : IGetCurrency => ({
    type: GetCurrencyType.GET_CURRENCY
})

export const getCurrencySuccess = (data:any) : IGetCurrencySuccess => ({
    type: GetCurrencyType.GET_CURRENCY_SUCCESS,
    data
})

export const getCurrencyFaild = (error:any) : IGetCurrencyError => ({
    type: GetCurrencyType.GET_CURRENCY_ERROR,
    error
})