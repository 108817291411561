import {put, takeEvery,debounce,select} from 'redux-saga/effects';
import {ActiveAgentRankTypes} from '../../actions/activeAgentRank';
import {activeAgentRankFetching,activeAgentRankSuccess,activeAgentRankError, activeAgentRankFilter} from '../../actions/activeAgentRank/action';
import {handleGetListUser} from '../../../apis/service/user';
import { IApplicationState } from 'src/store/IApplicationState';
import { getAgentRank } from 'src/store/actions';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { adminP2PSetAgentRank } from 'src/apis/service/Priority';
import { getAgentRankByAdmin } from 'src/store/actions/getAgentRankByAdmin';

function* activeAgentRankSaga(action:any):Generator<any> {

    // yield put(activeAgentRankFetching(true))
    const {payload} = action;
    try {
      const data:any = yield adminP2PSetAgentRank(payload)
    if(data.status === 200){
      const filter : any = yield select((state:IApplicationState) => state.getAgentRankByAdmin.filter)
       yield put(activeAgentRankSuccess(data));
      //  yield put(getAgentRankByAdmin(filter))
       toastSuccess('bo_active_agent_rank_successfully')
     }else{
       toastError('bo_you_can_t_active_agent_rank')
       yield put(activeAgentRankError('error'))
     }
   } catch (error) {
       toastError('bo_you_can_t_active_agent_rank')
       yield put(activeAgentRankError(error))
   }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(activeAgentRankFilter(action.payload));
}
export function* watchActiveAgentRank() {
   yield takeEvery(ActiveAgentRankTypes.ACTIVE_AGENT_RANK, activeAgentRankSaga)
   yield debounce(500,ActiveAgentRankTypes.ACTIVE_AGENT_RANK_FILTER_DEBOUNCE,handleSearchDebounce)
}
