export interface IDataAddAndEditCurrency {
    status: number;
    data: any;
    message: string;
}

export interface IDataAddAndEditCurrencyState {
   data: IDataAddAndEditCurrency,
   isFetching: boolean
}

export const initialAddAndEditCurrencyState : IDataAddAndEditCurrencyState = {
    data: {
        data:[],
        status:0,
        message:''
    },
    isFetching:false
}