import { CountryType } from './actionTypes';
import { IFetchingCountries, ICountries, ICountriesError, ICountriesSuccess } from './interfaces';

export const countriesIsFetching = (isFetching:boolean) : IFetchingCountries => ({
    type: CountryType.LOAD_COUNTRY_FETCHING,
    isFetching
})

export const countries = (payload: number) : ICountries => ({
    type: CountryType.LOAD_COUNTRY,
    payload
})

export const countriesSuccess = (data:any) : ICountriesSuccess => ({
    type: CountryType.LOAD_COUNTRY_SUCCESS,
    data
})

export const countriesFaild = (error:any) : ICountriesError => ({
    type: CountryType.LOAD_COUNTRY_ERROR,
    error
})