import {HandleGetWalletDetailOverviewTypes} from './actionTypes';
import { IHandleGetWalletDetailOverview, IHandleGetWalletDetailOverviewFetching, IHandleGetWalletDetailOverviewSuccess, IHandleGetWalletDetailOverviewError, IHandleGetWalletDetailOverviewFilter, IHandleGetWalletDetailOverviewFiterDebounce } from "./interfaces";


export const handleGetWalletDetailOverview = (payload:Object): IHandleGetWalletDetailOverview => ({
    type: HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW,
    payload
})

export const handleGetWalletDetailOverviewFetching = (isFetching:boolean) : IHandleGetWalletDetailOverviewFetching => ({
    type: HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_FETCHING,
    isFetching
})

export const handleGetWalletDetailOverviewSuccess = (data:any) : IHandleGetWalletDetailOverviewSuccess => ({
   type: HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_SUCCESS,
   data
})

export const handleGetWalletDetailOverviewError = (errorMsg:any) : IHandleGetWalletDetailOverviewError => ({
    type: HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_FAILD,
    error:errorMsg
})

export const handleGetWalletDetailOverviewFilter = (payload: any): IHandleGetWalletDetailOverviewFilter => ({
    type: HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_FILTER,
    payload
})

export const handleGetWalletDetailOverviewFilterDebounce = (payload: any): IHandleGetWalletDetailOverviewFiterDebounce => ({
    type: HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_FILTER_DEBOUNCE,
    payload 
})
