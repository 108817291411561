import { ListParams } from "src/models/common";

interface IDataDeleteScoreKPI {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface IDeleteScoreKPIState {
    data: IDataDeleteScoreKPI;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
    countSuccess: number
}

export const initialDeleteScoreKPIState: IDeleteScoreKPIState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {},
    pagination: {
        totalCount: 0,
    },
    countSuccess: 0
}


