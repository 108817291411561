import {CheckPhoneTypes} from './actionTypes';
import {ICheckPhoneSuccess, ICheckPhoneFaild, ICheckPhoneFetching, IResetCheckPhone, CheckPhoneData} from './interfaces';

export const checkPhoneAtFetching = (isFetching:boolean) : ICheckPhoneFetching  => ({
    type: CheckPhoneTypes.CHECK_PHONE_FETCHING,
    isFetching
})

export const checkPhone = (payload:CheckPhoneData) => ({
    type: CheckPhoneTypes.CHECK_PHONE,
    payload
})

export const checkPhoneSuccess = (data:any) : ICheckPhoneSuccess => ({
    type: CheckPhoneTypes.CHECK_PHONE_SUCCESS,
    data
})

export const checkPhoneFaild = (error:any) : ICheckPhoneFaild => ({
    type: CheckPhoneTypes.CHECK_PHONE_FAILD,
    error
})

export const resetCheckPhone = () : IResetCheckPhone => ({
    type: CheckPhoneTypes.RESET_CHECK_PHONE,
})