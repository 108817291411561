import { SetAgentRankType } from './actionTypes';
import {
   ISetAgentRank,
   ISetAgentRankFaild,
   ISetAgentRankFetching,
   ISetAgentRankSuccess,
   PayloadSetAgentRank
} from './interfaces';

export const setAgentRankAtFetching = (isFetching:boolean) : ISetAgentRankFetching  => ({
    type: SetAgentRankType.SET_AGENT_RANK_FETCHING,
    isFetching
})

export const setAgentRank = (payload:PayloadSetAgentRank, isUserScore: boolean, history?:any) : ISetAgentRank => ({
    type: SetAgentRankType.SET_AGENT_RANK,
    payload,
    isUserScore,
    history
})

export const setAgentRankSuccess = (data:any) : ISetAgentRankSuccess => ({
    type: SetAgentRankType.SET_AGENT_RANK_SUCCESS,
    data
})

export const setAgentRankFaild = (error:any) : ISetAgentRankFaild => ({
    type: SetAgentRankType.SET_AGENT_RANK_FAILD,
    error
})
