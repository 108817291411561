import {put, takeEvery} from 'redux-saga/effects';
import { handleGetVenlyWalletsUser } from 'src/apis/service/Wallet';
import { getVenlyWalletsUserFaild, getVenlyWalletsUserIsFetching, getVenlyWalletsUserSuccess, GetVenlyWalletsUserType } from 'src/store/actions/getVanlyWalletsUser';

function* getListVenlyWalletsUserSaga(): Generator<any> {
    yield put(getVenlyWalletsUserIsFetching(true))
    try {
      const data:any = yield handleGetVenlyWalletsUser()      
      if(data && data?.status === 200){
        yield put(getVenlyWalletsUserSuccess(data));
      } else {
        yield put(getVenlyWalletsUserFaild('error'))
      }
    } catch (error) {
        yield put(getVenlyWalletsUserFaild(error))
    }
}

export function* watchGetListVenlyWalletsUser() {
   yield takeEvery(GetVenlyWalletsUserType.GET_VENLY_WALLETS_USER , getListVenlyWalletsUserSaga)
}