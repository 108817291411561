import { put, takeLatest, delay, select } from 'redux-saga/effects';
import { handlePostToUser, handleUnPostToUser } from 'src/apis/service/Announcements';
import { toastError, toastErrorPutMess, toastSuccess } from 'src/helpers/toastHelper';
import { IApplicationState } from 'src/store/IApplicationState';
import { getAnnouncements } from 'src/store/actions/Announcement/Announcements/GetAnnouncements/action';
import { UnPostAndPostAnnouncementsType, UnPostAndPostAnnouncementsSuccess, UnPostAndPostAnnouncementsIsFetching, UnPostAndPostAnnouncementsFaild } from 'src/store/actions/Announcement/Announcements/UnpostAndPost';

function* setUnPostAndPostAnnouncementsSaga(action: any): Generator<any> {


  yield put(UnPostAndPostAnnouncementsIsFetching(true))
  const { payload, isPost } = action;
  try {
    if (isPost) {
      const response: any = yield handlePostToUser(payload);
      yield delay(1000)
      if (response && response.status === 200) {
        const filter: any = yield select((state: IApplicationState) => state.listAnnouncements.filter)
        yield put(UnPostAndPostAnnouncementsSuccess(response));
        toastSuccess('bo_post_success')
        // yield put(getAnnouncements(filter))
      } else {
        toastError('bo_post_failed');
        yield put(UnPostAndPostAnnouncementsFaild('error'))
      }
    } else {
      const response: any = yield handleUnPostToUser(payload);
      yield delay(1000)
      if (response && response.status === 200) {
        const filter: any = yield select((state: IApplicationState) => state.listAnnouncements.filter)
        yield put(UnPostAndPostAnnouncementsSuccess(response));
        toastSuccess('bo_un_post_success')
        // yield put(getAnnouncements(filter))
      } else {
        toastError('bo_un_post_failed');
        yield put(UnPostAndPostAnnouncementsFaild('error'))
      }
    }
  } catch (error) {
    yield put(UnPostAndPostAnnouncementsFaild(error))
  }
}

export function* watchSetUnPostAndPostAnnouncements() {
  yield takeLatest(UnPostAndPostAnnouncementsType.UNPOST_AND_POST_ANNOUNCEMENTS, setUnPostAndPostAnnouncementsSaga)
}