export interface RemoveOfferAll  {
    status: number,
    data:any,
    message:string
}

export interface RemoveOfferAllState {
    data: RemoveOfferAll,
    isFetching:boolean
}

export const initialRemoveOfferAllState: RemoveOfferAllState = {
    data:{
        data:{},
        message:'',
        status:0
    },
    isFetching:false
}