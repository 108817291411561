import { AddUserBankAccountforAgentType } from './actionTypes';
import {
    IAddBankAccAgent,
    IAddBankAccAgentError,
    IAddBankAccAgentSuccess,
    IFetchingAddBankAccAgent
} from './interfaces';

export const addBankAccAgentIsFetching = (isFetching:boolean) : IFetchingAddBankAccAgent => ({
    type:AddUserBankAccountforAgentType.ADD_USER_BANK_ACCOUNT_AGENT_FETCHING,
    isFetching
})

export const addBankAccAgent = (payload:Object, isEdit:boolean, getListRequest: any,handleClose:any) : IAddBankAccAgent => ({
    type:AddUserBankAccountforAgentType.ADD_USER_BANK_ACOOUNT_AGENT,
    payload,
    isEdit,
    getListRequest,
    handleClose
})

export const addBankAccAgentSuccess = (data:any) : IAddBankAccAgentSuccess => ({
    type:AddUserBankAccountforAgentType.ADD_USER_BANK_ACCOUNT_AGENT_SUCCESS,
    data
})

export const addBankAccAgentFaild = (error:any) : IAddBankAccAgentError => ({
    type:AddUserBankAccountforAgentType.ADD_USER_BANK_ACCOUNT_AGENT_ERROR,
    error
})