import { AnyAction, Reducer } from 'redux';
import { IAgentChangePINCodeState, initialAgentChangePINCodeState } from './state';
import { AgentChangePINCodeTypes } from 'src/store/actions/agentChangePINCode';

export const AgentChangePINCodeReducer: Reducer = (
  state: IAgentChangePINCodeState = initialAgentChangePINCodeState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
