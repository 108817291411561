import { AnyAction, Reducer } from 'redux';
import { IWalletInputState, initialWalletInputState } from './state';
import { WalletInputTypes } from 'src/store/actions/walletInput';

export const WalletInputReducer: Reducer = (
  state: IWalletInputState = initialWalletInputState,
  action: AnyAction
) => {
  switch (action.type) {
    case WalletInputTypes.WALLET_INPUT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case WalletInputTypes.WALLET_INPUT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    }
    case WalletInputTypes.WALLET_INPUT_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case WalletInputTypes.WALLET_INPUT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
