import { GetAdminOrAgentTransfersTypes } from './actionTypes';
import {
    IFilterAdminOrAgentTransfer,
    IFilterDebounceAdminOrAgentTransfer,
    IGetAdminOrAgentTransfers,
    IGetAdminOrAgentTransfersFaild,
    IGetAdminOrAgentTransfersFetching,
    IGetAdminOrAgentTransfersSuccess
} from './interfaces';

export const getAdminOrAgentTransfersAtFetching = (isFetching:boolean) : IGetAdminOrAgentTransfersFetching  => ({
    type: GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS_FETCHING,
    isFetching
})

export const getAdminOrAgentTransfers = (payload:Object,isAgent:boolean) : IGetAdminOrAgentTransfers => ({
    type: GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS,
    payload,
    isAgent
})

export const getAdminOrAgentTransfersSuccess = (data:any) : IGetAdminOrAgentTransfersSuccess => ({
    type: GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS_SUCCESS,
    data
})

export const getAdminOrAgentTransfersFaild = (error:any) : IGetAdminOrAgentTransfersFaild => ({
    type: GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS_FAILD,
    error
})

export const setFilterAdminOrAgentTransfers = (payload: any): IFilterAdminOrAgentTransfer => ({
    type: GetAdminOrAgentTransfersTypes.FILTER_ADMIN_OR_AGENT_TRANSFERS,
    payload
})

export const setFilterDebounceAdminOrAgentTransfers = (payload: any): IFilterDebounceAdminOrAgentTransfer => ({
    type: GetAdminOrAgentTransfersTypes.FILTER_DEBOUNCE_ADMIN_OR_AGENT_TRANSFERS,
    payload 
})