import { GetDirectTransactionDetailTypes } from './actionTypes';
import { IGetDirectTransactionDetail, IGetDirectTransactionDetailFetching, IGetDirectTransactionDetailSuccess, IGetDirectTransactionDetailError, IGetDirectTransactionDetailFilter, IGetDirectTransactionDetailFiterDebounce, IGetDirectTransactionDetailClean } from "./interfaces";


export const getDirectTransactionDetail = (payload: Object): IGetDirectTransactionDetail => ({
    type: GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL,
    payload
})

export const getDirectTransactionDetailFetching = (isFetching: boolean): IGetDirectTransactionDetailFetching => ({
    type: GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL_FETCHING,
    isFetching
})

export const getDirectTransactionDetailSuccess = (data: any): IGetDirectTransactionDetailSuccess => ({
    type: GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL_SUCCESS,
    data
})

export const getDirectTransactionDetailError = (errorMsg: any): IGetDirectTransactionDetailError => ({
    type: GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL_FAILD,
    error: errorMsg
})

export const getDirectTransactionDetailFilter = (payload: any): IGetDirectTransactionDetailFilter => ({
    type: GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL_FILTER,
    payload
})

export const getDirectTransactionDetailFilterDebounce = (payload: any): IGetDirectTransactionDetailFiterDebounce => ({
    type: GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL_FILTER_DEBOUNCE,
    payload
})

export const getDirectTransactionDetailClean = (): IGetDirectTransactionDetailClean => ({
    type: GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL_CLEAN
})