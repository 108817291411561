import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApi = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER
});

const handleGetAllScoreKPI = async (payload: any): Promise<any> => {
  try {
    return await requestApi.get(`/Admin/GetAllScoreKPI`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handlePostAllScoreKPI = async (payload: any): Promise<any> => {
  try {
    return await requestApi.post(`/Admin/GetAllScoreKPI`, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateScoreKPI = async (data: Object): Promise<any> => {
  try {
    return await requestApi.post(`/Admin/CreateScoreKPI`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateScoreKPI = async (data: Object): Promise<any> => {
  try {
    return await requestApi.post(`/Admin/UpdateScoreKPI`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteScoreKPI = async (data: Object): Promise<any> => {
  try {
    // @ts-ignore
    return await requestApi.post(`/Admin/DeleteScoreKPI?id=${data?.id}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
const handleDeactiveScoreKPI = async (data: Object): Promise<any> => {
  try {
    // @ts-ignore
    return await requestApi.post(`/Admin/DeactiveScoreKPI?id=${data?.id}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
const handleActiveScoreKPI = async (data: Object): Promise<any> => {
  try {
    // @ts-ignore
    return await requestApi.post(`/Admin/ActiveScoreKPI?id=${data?.id}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAllScorePeriodConfig = async (): Promise<any> => {
  try {
    return await requestApi.get(`/Admin/GetAllScorePeriodConfig`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateScorePeriodConfig = async (data: Object): Promise<any> => {
  try {
    return await requestApi.post(`/Admin/CreateScorePeriodConfig`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleGetAllScoreKPI,
  handlePostAllScoreKPI,
  handleCreateScoreKPI,
  handleUpdateScoreKPI,
  handleDeleteScoreKPI,
  handleDeactiveScoreKPI,
  handleActiveScoreKPI,
  handleGetAllScorePeriodConfig,
  handleCreateScorePeriodConfig
};
