import {put, takeEvery,debounce} from 'redux-saga/effects';
import {HandleDeleteOfferTypes} from '../../actions/handleDeleteOffer';
import {handleDeleteOfferFetching,handleDeleteOfferSuccess,handleDeleteOfferError, handleDeleteOfferFilter} from '../../actions/handleDeleteOffer/action';
import { handleDeleteOffer } from 'src/apis/service/CryptoExchange';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import i18next from 'i18next';
function* handleDeleteOfferSaga(action:any):Generator<any> {
    yield put(handleDeleteOfferFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleDeleteOffer(payload)
      if(data && data?.status === 200){
        toastSuccess(i18next.t('bo_delete_offer_success_label'));
        yield put(handleDeleteOfferSuccess(data))
      } else {
        toastError(i18next.t('bo_delete_offer_fail_label'));
        yield put(handleDeleteOfferError('error'))
      }
    } catch (error: any) {
        toastError(i18next.t('bo_delete_offer_fail_label'));
        yield put(handleDeleteOfferError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(handleDeleteOfferFilter(action.payload));
}
export function* watchHandleDeleteOffer() {
   yield takeEvery(HandleDeleteOfferTypes.HANDLE_DELETE_OFFER, handleDeleteOfferSaga)
   yield debounce(500,HandleDeleteOfferTypes.HANDLE_DELETE_OFFER_FILTER_DEBOUNCE,handleSearchDebounce)
}
