import {GetAdminTransfersTypes} from './actionTypes';
import { IGetAdminTransfers, IGetAdminTransfersFetching, IGetAdminTransfersSuccess, IGetAdminTransfersError, IGetAdminTransfersFilter, IGetAdminTransfersFiterDebounce } from "./interfaces";


export const getAdminTransfers = (payload:Object): IGetAdminTransfers => ({
    type: GetAdminTransfersTypes.GET_ADMIN_TRANSFERS,
    payload
})

export const getAdminTransfersFetching = (isFetching:boolean) : IGetAdminTransfersFetching => ({
    type: GetAdminTransfersTypes.GET_ADMIN_TRANSFERS_FETCHING,
    isFetching
})

export const getAdminTransfersSuccess = (data:any) : IGetAdminTransfersSuccess => ({
   type: GetAdminTransfersTypes.GET_ADMIN_TRANSFERS_SUCCESS,
   data
})

export const getAdminTransfersError = (errorMsg:any) : IGetAdminTransfersError => ({
    type: GetAdminTransfersTypes.GET_ADMIN_TRANSFERS_FAILD,
    error:errorMsg
})

export const getAdminTransfersFilter = (payload: any): IGetAdminTransfersFilter => ({
    type: GetAdminTransfersTypes.GET_ADMIN_TRANSFERS_FILTER,
    payload
})

export const getAdminTransfersFilterDebounce = (payload: any): IGetAdminTransfersFiterDebounce => ({
    type: GetAdminTransfersTypes.GET_ADMIN_TRANSFERS_FILTER_DEBOUNCE,
    payload 
})
