import { GetP2PExchangeTransactionsType } from './actionTypes';
import { IFetchingGetP2PExchangeTransactions, IGetListFilterDebounceP2PExchangeTransactions, IGetListFilterP2PExchangeTransactions, IGetP2PExchangeTransactions, IGetP2PExchangeTransactionsError, IGetP2PExchangeTransactionsSuccess } from './interfaces';

export const getP2PExchangeTransactionsIsFetching = (isFetching:boolean) : IFetchingGetP2PExchangeTransactions => ({
    type: GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS_FETCHING,
    isFetching
})

export const getP2PExchangeTransactions = (payload: object) : IGetP2PExchangeTransactions => ({
    type: GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS,
    payload
})

export const getP2PExchangeTransactionsSuccess = (data:any) : IGetP2PExchangeTransactionsSuccess => ({
    type: GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS_SUCCESS,
    data
})

export const getP2PExchangeTransactionsFaild = (error:any) : IGetP2PExchangeTransactionsError => ({
    type: GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS_ERROR,
    error
})

export const setFilterP2PExchangeTransactions = (payload: any): IGetListFilterP2PExchangeTransactions => ({
    type: GetP2PExchangeTransactionsType.SET_LIST_FILTER_P2P_EXCHANGE_TRANSACTIONS,
    payload
})

export const setFilterDebounceP2PExchangeTransactions = (payload: any): IGetListFilterDebounceP2PExchangeTransactions => ({
    type: GetP2PExchangeTransactionsType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_TRANSACTIONS,
    payload 
})