import Request from "../config";
import * as Sentry from "@sentry/react";

const requestApi = () => {
  try {
    return new Request({ url: process.env.REACT_APP_BASE_URL_ACCOUNT });
  } catch (error) {}
};

export const handleLogout = async (data: any): Promise<any> => {
  const url = "/Account/Logout";
  try {
    return await requestApi()?.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
