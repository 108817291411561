import { put, takeEvery } from 'redux-saga/effects';
import { SendNoteTypes } from '../../actions/noteKycDetail/actionTypes';
import {
  sendNoteKycAtFetching,
  sendNoteKycSuccess,
  sendNoteKycFaild
} from '../../actions/noteKycDetail/action';
import { handleSendNoteKyc } from '../../../apis/service/Kyc';
import { getKycListHistoryNote } from 'src/store/actions/kycHistoryNote/action';
function* sendNoteKycSaga(action: any): Generator<any> {
  yield put(sendNoteKycAtFetching(true));
  const { payload } = action;
  try {
    const dataSendNote: any = yield handleSendNoteKyc(payload);
    if (dataSendNote.status === 200) {
      // const payloadSendNoteKyc = {
      //   pageSize: 10,
      //   pageNumber: 1,
      //   userId: payload?.userId,
      //   kycId: payload?.kycId
      // };
      yield put(sendNoteKycSuccess(dataSendNote));
      // yield put(getKycListHistoryNote(payloadSendNoteKyc));
    } else {
      yield put(sendNoteKycFaild('error'));
    }
  } catch (error) {
    yield put(sendNoteKycFaild(error));
  }
}
export function* watchSendNoteKyc() {
  yield takeEvery(SendNoteTypes.NOTE_KYC, sendNoteKycSaga);
}
