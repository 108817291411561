import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetCryptoExchangeOffersListHistoriesTypes } from '../../actions/getCryptoExchangeOffersListHistories';
import { getCryptoExchangeOffersListHistoriesFetching, getCryptoExchangeOffersListHistoriesSuccess, getCryptoExchangeOffersListHistoriesError, getCryptoExchangeOffersListHistoriesFilter } from '../../actions/getCryptoExchangeOffersListHistories/action';
import { handleGetCryptoExchangeOffersListHistories } from 'src/apis/service/Agent';

function* getCryptoExchangeOffersListHistoriesSaga(action: any): Generator<any> {
  yield put(getCryptoExchangeOffersListHistoriesFetching(true))
  const { offerId, payload } = action;
  try {
    const data: any = yield handleGetCryptoExchangeOffersListHistories(offerId, payload)
    if (data && data?.status === 200) {
      yield put(getCryptoExchangeOffersListHistoriesSuccess(data))
    } else {
      yield put(getCryptoExchangeOffersListHistoriesError('error'))
    }
  } catch (error) {
    yield put(getCryptoExchangeOffersListHistoriesError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getCryptoExchangeOffersListHistoriesFilter(action.payload));
}
export function* watchGetCryptoExchangeOffersListHistories() {
  yield takeEvery(GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES, getCryptoExchangeOffersListHistoriesSaga)
  yield debounce(500, GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_FILTER_DEBOUNCE, handleSearchDebounce)
}
