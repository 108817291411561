import { AnyAction, Reducer } from 'redux';
import { initialExportOfferSearchState, ExportOfferSearchState } from './state';
import { ExportOfferSearchType } from '../../actions/ExportOfferSearch/actionTypes';


export const ExportOfferSearchReducer: Reducer = (
  state: ExportOfferSearchState = initialExportOfferSearchState,
  action: AnyAction
) => {
  switch (action.type) {
    case ExportOfferSearchType.EXPORT_OFFER_SEARCH: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ExportOfferSearchType.EXPORT_OFFER_SEARCH_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case ExportOfferSearchType.EXPORT_OFFER_SEARCH_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
