import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getOfferDetailById } from 'src/apis/service/Offer';
import { handleGetTransactionsByOfferId } from 'src/apis/service/TransactionByOffer';
import { getListTransactionByOfferIsFetching } from 'src/store/actions';
import {
  getOfferDetailFaild,
  getOfferDetailIsFetching,
  getOfferDetailSuccess, GetOfferDetailType
} from 'src/store/actions/myOfferFromAgent/OfferDetail';
import { IApplicationState } from 'src/store/IApplicationState';

function* getOfferDetailSaga(action: any): Generator<any> {
    yield put(getOfferDetailIsFetching(true))
    const { payload } = action;
    const filterListTransaction : any = yield select((state:IApplicationState) => state.listTransactionByOffer.filter)
    yield put(getListTransactionByOfferIsFetching(true))
    try {
      const data:any = yield call(getOfferDetailById, payload)  
      const listTransactionByOfferId : any = yield call(handleGetTransactionsByOfferId, {
        ...filterListTransaction,
        offerId: payload
      }); 

      if(data && data?.status === 200){
        if(listTransactionByOfferId && listTransactionByOfferId?.status === 200 ) {
          if(Boolean(listTransactionByOfferId)) {
            data.data['listTransaction'] = listTransactionByOfferId?.data?.items;
          }
          yield put(getOfferDetailSuccess(data));
        } else {
          yield put(getOfferDetailSuccess(data));
        }
      } else {
        yield put(getOfferDetailFaild('error'))
      }
    } catch (error) {
        yield put(getOfferDetailFaild(error))
    }
}

export function* watchGetOfferDetail() {
   yield takeEvery(GetOfferDetailType.GET_OFFER_DETAIL,getOfferDetailSaga)
}