import { cryptoWalletTransactionTypes } from '../../../contants';

export interface ICryptoWalletTransactionItem {
    createdAt: string,
    updatedAt: string,
    id: string,
    productId: string,
    fromCustodialWallet: string,
    toCustodialWallet: string,
    type: cryptoWalletTransactionTypes,
    status: number,
    amount: string,
    amountUsd: string,
    amountKrw: string,
    from: string,
    to: string,
    network: null,
    currency: string,
    txnHash: string,
    gasFee: string,
    commissionFee: string,
    withdrawCustodialWalletId: number,
    note: ""
}

interface ICryptoWalletTransactionsReqData {
    totalCount: number,
    totalValueUSD: string,
    totalValueKRW: string,
    page: number,
    size: number,
    items: ICryptoWalletTransactionItem[]
}

export interface IReqDataCryptoWalletTransactions {
    data: ICryptoWalletTransactionsReqData | null;
    error: any;
    success: boolean;
}

export interface ICryptoWalletTransactionsState {
    requestData: IReqDataCryptoWalletTransactions;
    isFetching: boolean,
    filter: {
        page: number,
        size: number
    }
}

export const initialCryptoWalletTransactionsState: ICryptoWalletTransactionsState = {
    requestData: {
        data: null,
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        page: 1,
        size: 25
    }
}

