import Request from "../../config";
import * as Sentry from "@sentry/react";
import { LocalStorageKey } from "src/contants/constantUtils/localStorageKey";
import { ListParams } from "src/models/common";
import { setStorageItem } from "src/utils/newUtils/localStorageHelper";

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_CONFIG});
const requestApiCrypto = new Request({url: process.env.REACT_APP_BASE_ADMIN_CRYPTO_URL});

const getCurrencies = async () : Promise<any> => {
  try {
    return await requestApi.get(`/Currency/GetAll`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const getCurrenciesBO = async (data: ListParams) : Promise<any> => {
  try {
    return await requestApi.post(`/Currency/GetAllCurrencyBO`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetAllCurrencyNoFilter = async (data: any) : Promise<any> => {
  try {
    return await requestApi.post(`/Currency/GetAllCurrencyBO`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}


const deleteCurrencyBO = async (data:any) : Promise<any> => {
  try {
    return await requestApi.post(`/Currency/delete?currencyId=${data.currencyId}`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const createCurrency = async (data:ListParams) : Promise<any> => {
  try {
    return await requestApi.post(`/Currency/create`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const editCurrency = async (data:ListParams) : Promise<any> => {
  try {
    return await requestApi.post(`/Currency/edit`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const enableCurrency = async (currencyId:number) : Promise<any> => {
  try {
    return await requestApi.post(`/Currency/enable?currencyId=${currencyId}`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const activeCurrencyBO = async (data:any) : Promise<any> => {
  try {
    return await requestApi.post(`/Currency/enable?currencyId=${data.currencyId}`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const getSupportedCryptoCurrenciesAPI = async () : Promise<any> => {
  try {
    const result = await requestApiCrypto.get('/supported-crypto-currencies', )
    setStorageItem(
      LocalStorageKey.ListCryptoSupported,
      JSON.stringify(result?.data)
    );
    return result
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export {handleGetAllCurrencyNoFilter, getCurrencies, getCurrenciesBO, deleteCurrencyBO, createCurrency, editCurrency, enableCurrency, activeCurrencyBO, getSupportedCryptoCurrenciesAPI };
