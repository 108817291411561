import { put, takeEvery } from 'redux-saga/effects';
import { handleGetListAdminFunction } from 'src/apis/service/AdminRoleSetting';
import { GetListAdminFunctionTypes } from '../../actions/getListAdminFunction';
import {
  getListAdminFunctionError, getListAdminFunctionFetching,
  getListAdminFunctionSuccess
} from '../../actions/getListAdminFunction/action';

function* getListAdminFunctionSaga(): Generator<any> {
  yield put(getListAdminFunctionFetching(true));
  try {
    const data: any = yield handleGetListAdminFunction();
    if (data && data?.status === 200) {
      yield put(getListAdminFunctionSuccess(data));
    } else {
      yield put(getListAdminFunctionError('error'));
    }
  } catch (error) {
    yield put(getListAdminFunctionError(error));
  }
}
export function* watchGetListAdminFunction() {
  yield takeEvery(
    GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION,
    getListAdminFunctionSaga
  );
}
