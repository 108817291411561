import { UpdateUserPriorityType } from './actionTypes';
import {
  IFetchingUpdateUserRolePriority,
  IUpdateUserPriorityError,
  IUpdateUserRolePriority,
  IUpdateUserRolePrioritySuccess,
} from './interfaces';

export const updateUserRoleIsFetching = (isFetching: boolean): IFetchingUpdateUserRolePriority => ({
  type: UpdateUserPriorityType.UPDATE_ROLES_USER_PRIORITY_FETCHING,
  isFetching,
});

export const updateUserRolePriority = (payload: Object): IUpdateUserRolePriority => ({
  type: UpdateUserPriorityType.UPDATE_ROLES_USER_PRIORITY,
  payload,
});

export const updateUserRolePrioritySuccess = (data: any): IUpdateUserRolePrioritySuccess => ({
  type: UpdateUserPriorityType.UPDATE_ROLES_USER_PRIORITY_SUCCESS,
  data,
});

export const updateUserRolePriorityFaild = (error: any): IUpdateUserPriorityError => ({
  type: UpdateUserPriorityType.UPDATE_ROLES_USER_PRIORITY_ERROR,
  error,
});
