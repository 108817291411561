import { AnyAction, Reducer } from 'redux';
import { GetDashboardType } from 'src/store/actions';
import { IDashboardState, initialDashboardState } from './state';


export const GetDashboardReducer: Reducer = (
  state: IDashboardState = initialDashboardState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetDashboardType.GET_DASHBOARD : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetDashboardType.GET_DASHBOARD_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetDashboardType.GET_DASHBOARD_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
      }
    }
    case GetDashboardType.GET_LIST_FILTER_DASHBOARD: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};