import {GetOffersTypes} from './actionTypes';
import { IGetOffers, IGetOffersFetching, IGetOffersSuccess, IGetOffersError, IGetOffersFilter, IGetOffersFiterDebounce } from "./interfaces";


export const getOffers = (payload:Object): IGetOffers => ({
    type: GetOffersTypes.GET_OFFERS,
    payload
})

export const getOffersFetching = (isFetching:boolean) : IGetOffersFetching => ({
    type: GetOffersTypes.GET_OFFERS_FETCHING,
    isFetching
})

export const getOffersSuccess = (data:any) : IGetOffersSuccess => ({
   type: GetOffersTypes.GET_OFFERS_SUCCESS,
   data
})

export const getOffersError = (errorMsg:any) : IGetOffersError => ({
    type: GetOffersTypes.GET_OFFERS_FAILD,
    error:errorMsg
})

export const getOffersFilter = (payload: any): IGetOffersFilter => ({
    type: GetOffersTypes.GET_OFFERS_FILTER,
    payload
})

export const getOffersFilterDebounce = (payload: any): IGetOffersFiterDebounce => ({
    type: GetOffersTypes.GET_OFFERS_FILTER_DEBOUNCE,
    payload 
})
