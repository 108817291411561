import { GetListAdminFunctionTypes } from './actionTypes';
import { IGetListAdminFunction, IGetListAdminFunctionFetching, IGetListAdminFunctionSuccess, IGetListAdminFunctionError, IGetListAdminFunctionFilter, IGetListAdminFunctionFiterDebounce } from "./interfaces";


export const getListAdminFunction = (): IGetListAdminFunction => ({
    type: GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION
})

export const getListAdminFunctionFetching = (isFetching: boolean): IGetListAdminFunctionFetching => ({
    type: GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_FETCHING,
    isFetching
})

export const getListAdminFunctionSuccess = (data: any): IGetListAdminFunctionSuccess => ({
    type: GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_SUCCESS,
    data
})

export const getListAdminFunctionError = (errorMsg: any): IGetListAdminFunctionError => ({
    type: GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_FAILD,
    error: errorMsg
})

export const getListAdminFunctionFilter = (payload: any): IGetListAdminFunctionFilter => ({
    type: GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_FILTER,
    payload
})

export const getListAdminFunctionFilterDebounce = (payload: any): IGetListAdminFunctionFiterDebounce => ({
    type: GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_FILTER_DEBOUNCE,
    payload
})

export const cleanListAdminFunction = (): any => ({
    type: GetListAdminFunctionTypes.CLEAN_LIST_ADMIN_FUNCTION
})