import { AnyAction, Reducer } from 'redux';
import { initialUnLockUserState, UnLockUserState } from './state';
import { UnLockUserType } from '../../actions/unLockUser/actionTypes';
import { toastSuccess, toastError } from '../../../helpers/toastHelper';

export const UnLockUserReducer: Reducer = (
  state: UnLockUserState = initialUnLockUserState,
  action: AnyAction
) => {
  switch (action.type) {
    case UnLockUserType.UNLOCK_USER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UnLockUserType.UNLOCK_USER_SUCCESS: {

      toastSuccess('bo_unlock_user_success');
      return {
        ...state,
        data: action.data,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case UnLockUserType.UNLOCK_USER_ERROR: {
      toastError(action.error);
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
