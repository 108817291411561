import { AnyAction, Reducer } from 'redux';
import { GetP2PExchangeOffersHistoryType } from 'src/store/actions/P2PExchangeOfferHistory';
import { IListP2PExchangeOffersHistoryState, initialListP2PExchangeOffersHistoryState } from './state';


export const GetListP2PExchangeOffersHistoryReducer: Reducer = (
  state: IListP2PExchangeOffersHistoryState = initialListP2PExchangeOffersHistoryState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      }
    }
    default:
      return state;
  }
};