import { ListParams } from "src/models/common";

interface IDataGetInvoiceDetail {
  data:any;
  error:any;
  success:boolean;
}

export interface IGetInvoiceDetailState {
    data:IDataGetInvoiceDetail;
    isFetching:boolean,
    filter: ListParams
}

export const initialGetInvoiceDetailState: IGetInvoiceDetailState = {
    data:{
        data:{},
        error:null,
        success:false
    },
    isFetching:false,
    filter: {}
}


