import {put, takeEvery,debounce} from 'redux-saga/effects';
import { adminP2PGetAgentRank } from 'src/apis/service/Priority';
import { GetAgentRankTypes, getAgentRankAtFetching, getAgentRankFaild, getAgentRankSuccess, setFilterAgentRank } from 'src/store/actions/Agent/GetAgentRank';

function* getAgentRankSaga(action:any):Generator<any> {
    // yield put(getAgentRankAtFetching(true))
    const {payload} = action;
    try {
      const data: any = yield adminP2PGetAgentRank(payload)
      if(data.status === 200){
        yield put(getAgentRankSuccess(data))
      }  
    } catch (error) {
        yield put(getAgentRankFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterAgentRank(action.payload));
}
export function* watchGetAllAgentRank() {
   yield takeEvery(GetAgentRankTypes.GET_AGENT_RANK, getAgentRankSaga)
   yield debounce(500,GetAgentRankTypes.FILTER_DEBOUNCE_AGENT_RANK, handleSearchDebounce)
}