import { AnyAction, Reducer } from 'redux';
import { initialAddAndEditCurrencyState, IDataAddAndEditCurrencyState } from './state';
import { CreateAndEditCurrencyType } from '../../../actions/currency';

export const CreateAndEditCurrencyReducer: Reducer = (
  state: IDataAddAndEditCurrencyState = initialAddAndEditCurrencyState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY: {
      return {
        ...state,
        isFetching: true,
        success: false
      }
    }
    case CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true
      }
    }
    case CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      }
    }
    default: {
      return state
    }
  }
}