import { ListParams } from "src/models/common";

export interface IGetCryptoExchangeOffersListState {
    data: any[];
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetCryptoExchangeOffersListState: IGetCryptoExchangeOffersListState = {
    data: [],
    isFetching: false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


