import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleEditWalletCrypto } from 'src/apis/service/Agent';
import { getListCryptoWallet } from 'src/store/actions/getListCryptoWallet';
import { EditWalletCryptoTypes } from '../../actions/editWalletCrypto';
import {
  editWalletCryptoError,
  editWalletCryptoFetching,
  editWalletCryptoFilter,
  editWalletCryptoSuccess,
} from '../../actions/editWalletCrypto/action';
import { toastError, toastSuccess } from 'src/utils/newUtils/toastHelper';

function* editWalletCryptoSaga(action: any): Generator<any> {
  yield put(editWalletCryptoFetching(true));
  const { payload, closeModal } = action;
  try {
    const data: any = yield handleEditWalletCrypto(payload);
    if (data && data?.status === 200) {
      closeModal && closeModal();
      if (payload?.isDefault) {
        toastSuccess('bo_set_default_wallet_success_label');
      } else {
        toastSuccess('bo_wallet_name_updated_success_label');
      }
      yield put(getListCryptoWallet());
      yield put(editWalletCryptoSuccess(data));
    } else {
      if (payload?.isDefault) {
        toastError('bo_set_default_wallet_fail_label');
      } else {
        toastError('bo_wallet_name_updated_fail_label');
      }
      yield put(editWalletCryptoError('error'));
    }
  } catch (error) {
    console.log(error);
    if (payload?.isDefault) {
      toastError('bo_set_default_wallet_fail_label');
    } else {
      toastError('bo_wallet_name_updated_fail_label');
    }
    yield put(editWalletCryptoError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(editWalletCryptoFilter(action.payload));
}
export function* watchEditWalletCrypto() {
  yield takeEvery(
    EditWalletCryptoTypes.EDIT_WALLET_CRYPTO,
    editWalletCryptoSaga
  );
  yield debounce(
    500,
    EditWalletCryptoTypes.EDIT_WALLET_CRYPTO_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
