import { ListParams } from "src/models/common";

interface IDataGetAllScorePeriodConfig {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  success:boolean;
}

export interface IGetAllScorePeriodConfigState {
    data:IDataGetAllScorePeriodConfig;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetAllScorePeriodConfigState: IGetAllScorePeriodConfigState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


