import { RemoveOfferBuyTransactionType } from './actionTypes';
import { IFetchingRemoveOfferBuyTransaction,IRemoveOfferBuyTransaction,IRemoveOfferBuyTransactionError,IRemoveOfferBuyTransactionSuccess } from './interfaces';


export const removeOfferBuyTransactionIsFetching = (isFetching:boolean) : IFetchingRemoveOfferBuyTransaction => ({
    type:RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION_FETCHING,
    isFetching
})

export const removeOfferBuyTransaction = (id: number) : IRemoveOfferBuyTransaction => ({
    type:RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION,
    id
})

export const removeOfferBuyTransactionSuccess = (data:any) : IRemoveOfferBuyTransactionSuccess => ({
    type:RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION_SUCCESS,
    data
})

export const removeOfferBuyTransactionFaild = (error:any) : IRemoveOfferBuyTransactionError => ({
    type:RemoveOfferBuyTransactionType.REMOVE_OFFER_BUY_TRANSACTION_ERROR,
    error
})