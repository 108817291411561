import { IWalletBalanceObject } from "../CryptoWalletGetNewOverview";

export interface IHandleGetWalletDetailOverviewState {
  data: IWalletBalanceObject[];
  isFetching: boolean;
}

export const initialHandleGetWalletDetailOverviewState: IHandleGetWalletDetailOverviewState =
  {
    data: [],
    isFetching: false,
  };
