import { lazy } from 'react';
import { routerConstants } from '../routerConstants';
const App = lazy(() => import('src/shade/layouts/App'));
const BlockchainDashboard = lazy(
  () => import('src/pages/dashboard/blockchainDashboard')
);

const HolaDashboard = lazy(() => import('src/pages/dashboard/holaDashboard'));

const SystemWallet = lazy(() => import('src/pages/walletManagement'));

const CryptoWalletOverview = lazy(
  () => import('src/pages/CryptoWalletManagement/Overview')
);
const CryptoWalletSystemWalletHotDetails = lazy(
  () => import('src/pages/CryptoWalletManagement/SystemWallet/Details/Hot')
);
const CryptoWalletSystemWalletColdDetails = lazy(
  () => import('src/pages/CryptoWalletManagement/SystemWallet/Details/Cold')
);
const CryptoWalletSystemWalletGasDetails = lazy(
  () => import('src/pages/CryptoWalletManagement/SystemWallet/Details/Gas')
);
const CryptoWalletSystemWalletTopUPHistory = lazy(
  () =>
    import('src/pages/CryptoWalletManagement/SystemWallet/TopUpRequestHistory')
);
const CryptoWalletUserWallet = lazy(
  () => import('src/pages/CryptoWalletManagement/UserWallet')
);
const CryptoWalletUserWalletDetails = lazy(
  () => import('src/pages/CryptoWalletManagement/UserWallet/Details')
);
const CryptoWalletDepositTransaction = lazy(
  () => import('src/pages/CryptoWalletManagement/Transactions/Deposit')
);
const CryptoWalletWithdrawTransaction = lazy(
  () => import('src/pages/CryptoWalletManagement/Transactions/Withdraw')
);
const CryptoWalletTransferTransaction = lazy(
  () => import('src/pages/CryptoWalletManagement/Transactions/Transfer')
);
const ExchangeManagementCryptoToCrypto = lazy(
  () => import('src/pages/ExchangeManagement/CryptoToCrypto')
);
const ExchangeManagementHolaToCrypto = lazy(
  () => import('src/pages/ExchangeManagement/HolaToCrypto')
);
const ExchangeManagementCryptoToCryptoDetails = lazy(
  () => import('src/pages/ExchangeManagement/CryptoToCrypto/Details')
);
const ExchangeManagementHolaToCryptoDetails = lazy(
  () => import('src/pages/ExchangeManagement/HolaToCrypto/Details')
);
const P2PExchangeOffers = lazy(
  () => import('src/pages/transactionManagement/exchange')
);
const P2PExchangeOffersDetail = lazy(
  () => import('src/pages/transactionManagement/exchange/detail')
);
const Admines = lazy(() => import('src/pages/userManagement/adminList'));
// const AdminRoleSetting = lazy(
//   () => import('src/pages/userManagement/adminLevel')
// );
const EditAdminFunction = lazy(
  () => import('src/pages/userManagement/adminLevel/detail')
);
const Applications = lazy(
  () => import('src/pages/userManagement/agentApplication')
);
const UserScore = lazy(() => import('src/pages/userManagement/agentRankScore'));
const ListUser = lazy(() => import('src/pages/userManagement/user'));
const UserDetail = lazy(() => import('src/pages/userManagement/user/detail'));
const AgentDetail = lazy(() => import('src/pages/userManagement/user/detail'));
const ListUserDeactivated = lazy(
  () => import('src/pages/userManagement/userBlackList')
);
const Announcements = lazy(() => import('src/pages/appSetting/announcement'));
const Categories = lazy(
  () => import('src/pages/appSetting/announcement/category')
);
const ListNotification = lazy(
  () => import('src/pages/appSetting/featureNotification')
);
const ListLanguageText = lazy(
  () => import('src/pages/appSetting/languageText')
);
// const Priorities = lazy(() => import('src/pages/appSetting/prioritySetting'));
const Currences = lazy(() => import('src/pages/appSetting/currency'));

const Wallets = lazy(() => import('src/pages/backofficeSetting/adminWallet'));

const SupperAdminWallet = lazy(
  () => import('src/pages/backofficeSetting/superAdminWallet')
);
const RankPeriodSettingPage = lazy(
  () => import('src/pages/backofficeSetting/agentRanks')
);
const UserScoreSetting = lazy(
  () => import('src/pages/backofficeSetting/agentScore')
);
const DirectTransfer = lazy(
  () => import('src/pages/P2PManagement/directTransfer')
);
const P2PTransaction = lazy(
  () => import('src/pages/P2PManagement/transaction')
);
const P2POffer = lazy(() => import('src/pages/P2PManagement/offer'));
const P2POfferDetail = lazy(
  () => import('src/pages/P2PManagement/offer/detail')
);

const Invoice = lazy(() => import('src/pages/P2PManagement/invoice'));
const InvoiceDetail = lazy(
  () => import('src/pages/P2PManagement/invoice/detail')
);

const KYCPage = lazy(() => import('src/pages/userManagement/kyc'));
const KYCDetailPage = lazy(() => import('src/pages/userManagement/kyc/detail'));
const P2PUserTransaction = lazy(
  () => import('src/pages/P2PManagement/userTransaction')
);

const Commission = lazy(() => import('src/pages/appSetting/commission'));
const TransactionLimit = lazy(
  () => import('src/pages/appSetting/transactionLimit')
);

const UserFunction = lazy(() => import('src/pages/appSetting/userFunction'));
const UserFunctionDetail = lazy(
  () => import('src/pages/appSetting/userFunction/detail')
);

export const superAdminPrivateRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      ///admin
      { path: routerConstants.HolaDashboard, element: <HolaDashboard /> },
      {
        path: routerConstants.BlockchainDashboard,
        element: <BlockchainDashboard />,
      },
      { path: routerConstants.UserScore, element: <UserScore /> },
      { path: routerConstants.Applications, element: <Applications /> },
      { path: routerConstants.ListUser, element: <ListUser /> },
      { path: routerConstants.UserDetail, element: <UserDetail /> },
      { path: routerConstants.AgentDetail, element: <AgentDetail /> },
      {
        path: routerConstants.ListUserDeactivated,
        element: <ListUserDeactivated />,
      },
      {
        path: routerConstants.P2PUserTransaction,
        element: <P2PUserTransaction />,
      },
      { path: routerConstants.P2PUserOffer, element: <P2POffer /> },
      { path: routerConstants.KYCDetail, element: <KYCDetailPage /> },
      { path: routerConstants.KYCList, element: <KYCPage /> },
      { path: routerConstants.KYCBlacklist, element: <KYCPage /> },
      { path: routerConstants.KYCApplication, element: <KYCPage /> },
      { path: routerConstants.KYCListDetail, element: <KYCDetailPage /> },
      { path: routerConstants.KYCBlacklistDetail, element: <KYCDetailPage /> },
      {
        path: routerConstants.KYCApplicationDetail,
        element: <KYCDetailPage />,
      },
      { path: routerConstants.P2PTransactionBuy, element: <P2PTransaction /> },
      { path: routerConstants.P2PTransactionSell, element: <P2PTransaction /> },
      { path: routerConstants.P2POfferBuy, element: <P2POffer /> },
      { path: routerConstants.P2POfferSell, element: <P2POffer /> },
      { path: routerConstants.P2POfferBuyDetail, element: <P2POfferDetail /> },
      { path: routerConstants.P2POfferSellDetail, element: <P2POfferDetail /> },
      { path: routerConstants.Invoice, element: <Invoice /> },
      { path: routerConstants.InvoiceDetail, element: <InvoiceDetail /> },
      { path: routerConstants.DirectTransfer, element: <DirectTransfer /> },
      {
        path: routerConstants.CryptoToCrypto,
        element: <ExchangeManagementCryptoToCrypto />,
      },
      {
        path: routerConstants.CryptoToCryptoDetails,
        element: <ExchangeManagementCryptoToCryptoDetails />,
      },
      {
        path: routerConstants.HolaToCrypto,
        element: <ExchangeManagementHolaToCrypto />,
      },
      {
        path: routerConstants.HolaToCryptoDetails,
        element: <ExchangeManagementHolaToCryptoDetails />,
      },
      { path: routerConstants.HolaToHola, element: <P2PExchangeOffers /> },
      {
        path: routerConstants.HolaToHolaDetails,
        element: <P2PExchangeOffersDetail />,
      },
      {
        path: routerConstants.CryptoWalletOverview,
        element: <CryptoWalletOverview />,
      },
      { path: routerConstants.CryptoSystemWallet, element: <SystemWallet /> },
      {
        path: routerConstants.CryptoSystemWalletHot,
        element: <CryptoWalletSystemWalletHotDetails />,
      },
      {
        path: routerConstants.CryptoSystemWalletCold,
        element: <CryptoWalletSystemWalletColdDetails />,
      },
      {
        path: routerConstants.CryptoSystemWalletGas,
        element: <CryptoWalletSystemWalletGasDetails />,
      },
      {
        path: routerConstants.TopupHistory,
        element: <CryptoWalletSystemWalletTopUPHistory />,
      },
      { path: routerConstants.UserWallet, element: <CryptoWalletUserWallet /> },
      {
        path: routerConstants.UserWalletDetails,
        element: <CryptoWalletUserWalletDetails />,
      },
      {
        path: routerConstants.Deposit,
        element: <CryptoWalletDepositTransaction />,
      },
      {
        path: routerConstants.Withdraw,
        element: <CryptoWalletWithdrawTransaction />,
      },
      {
        path: routerConstants.Transfer,
        element: <CryptoWalletTransferTransaction />,
      },
      { path: routerConstants.Currences, element: <Currences /> },
      { path: routerConstants.ListLanguageText, element: <ListLanguageText /> },
      { path: routerConstants.Announcements, element: <Announcements /> },
      { path: routerConstants.Categories, element: <Categories /> },
      { path: routerConstants.ListNotification, element: <ListNotification /> },
      { path: routerConstants.UserScoreSetting, element: <UserScoreSetting /> },
      {
        path: routerConstants.RankPeriodSettingPage,
        element: <RankPeriodSettingPage />,
      },
      // { path: routerConstants.Priorities, element: <Priorities /> },
      { path: routerConstants.Admines, element: <Admines /> },
      { path: routerConstants.Wallets, element: <Wallets /> },
      {
        path: routerConstants.SupperAdminWallets,
        element: <SupperAdminWallet />,
      },
      // { path: routerConstants.AdminRoleSetting, element: <AdminRoleSetting /> },
      {
        path: routerConstants.AdminRoleSettingDetail,
        element: <EditAdminFunction />,
      },
      { path: routerConstants.Commission, element: <Commission /> },
      { path: routerConstants.TransactionLimit, element: <TransactionLimit /> },
      { path: routerConstants.UserFunction, element: <UserFunction /> },
      {
        path: routerConstants.UserFunctionDetail,
        element: <UserFunctionDetail />,
      },
    ],
  },
];
