import { DeleteAnnouncementsType } from "./actionTypes"
import { IDeleteAnnouncements, IDeleteAnnouncementsError, IDeleteAnnouncementsSuccess, IFetchingDeleteAnnouncements } from "./interfaces"

export const deleteAnnouncementsIsFetching = (isFetching: boolean) : IFetchingDeleteAnnouncements => ({
    type: DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS_FETCHING,
    isFetching
})

export const deleteAnnouncements = (payload?: any) : IDeleteAnnouncements => ({
    type: DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS,
    payload
})

export const deleteAnnouncementsSuccess = (data: any) : IDeleteAnnouncementsSuccess => ({
    type: DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS_SUCCESS,
    data
})

export const deleteAnnouncementsFaild = (error: any) : IDeleteAnnouncementsError => ({
    type: DeleteAnnouncementsType.DELETE_ANNOUNCEMENTS_ERROR,
    error
})