import Request from "../../config";
import * as Sentry from "@sentry/react";

const requestApi = new Request({url: process.env.REACT_APP_BASE_ADMIN_CRYPTO_URL});

const handleGetNewCryptoOverviewData = async (): Promise<any[]> => {
    const url = `/overview`;
    try {
        return await requestApi.get(url, )
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

export {handleGetNewCryptoOverviewData};
