import { GetTranscationsByAgentTypes } from './actionTypes';
import {
  IGetTranscationsByAgent,
  IGetTranscationsByAgentFetching,
  IGetTranscationsByAgentSuccess,
  IGetTranscationsByAgentError,
  IGetTranscationsByAgentFilter,
  IGetTranscationsByAgentFiterDebounce,
} from './interfaces';

export const getTranscationsByAgent = (
  payload: Object
): IGetTranscationsByAgent => ({
  type: GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT,
  payload,
});

export const getTranscationsByAgentFetching = (
  isFetching: boolean
): IGetTranscationsByAgentFetching => ({
  type: GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT_FETCHING,
  isFetching,
});

export const getTranscationsByAgentSuccess = (
  data: any
): IGetTranscationsByAgentSuccess => ({
  type: GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT_SUCCESS,
  data,
});

export const getTranscationsByAgentError = (
  errorMsg: any
): IGetTranscationsByAgentError => ({
  type: GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT_FAILD,
  error: errorMsg,
});

export const getTranscationsByAgentFilter = (
  payload: any
): IGetTranscationsByAgentFilter => ({
  type: GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT_FILTER,
  payload,
});

export const getTranscationsByAgentFilterDebounce = (
  payload: any
): IGetTranscationsByAgentFiterDebounce => ({
  type: GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT_FILTER_DEBOUNCE,
  payload,
});
