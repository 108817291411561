import { LocalStorageKey } from "src/contants/constantUtils/localStorageKey";
import { getStorageItem } from "src/utils/newUtils/localStorageHelper";

export interface IUserAvatarState {
  data?: string;
  isFetching: boolean;
}
const user = getStorageItem(LocalStorageKey.UserInfo);
export const initialUserAvatarState: IUserAvatarState = {
  data: user?.imageUrl,
  isFetching: false,
};
