import { AnyAction, Reducer } from 'redux';
import { IActiveScoreKPIState, initialActiveScoreKPIState } from './state';
import { ActiveScoreKPITypes } from 'src/store/actions/activeScoreKPI';

export const ActiveScoreKPIReducer: Reducer = (
  state: IActiveScoreKPIState = initialActiveScoreKPIState,
  action: AnyAction
) => {
  switch (action.type) {
    case ActiveScoreKPITypes.ACTIVE_SCORE_KPI: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ActiveScoreKPITypes.ACTIVE_SCORE_KPI_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case ActiveScoreKPITypes.ACTIVE_SCORE_KPI_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case ActiveScoreKPITypes.ACTIVE_SCORE_KPI_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
