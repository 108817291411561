import { GetListUserWalletTypes } from "./actionTypes";
import {
  IGetListUserWallet,
  IGetListUserWalletFetching,
  IGetListUserWalletSuccess,
  IGetListUserWalletError,
  IGetListUserWalletFilter,
  IGetListUserWalletFiterDebounce,
} from "./interfaces";

export const getListUserWallet = (payload: Object): IGetListUserWallet => ({
  type: GetListUserWalletTypes.GET_LIST_USER_WALLET,
  payload,
});

export const getListUserWalletFetching = (
  isFetching: boolean
): IGetListUserWalletFetching => ({
  type: GetListUserWalletTypes.GET_LIST_USER_WALLET_FETCHING,
  isFetching,
});

export const getListUserWalletSuccess = (
  data: any
): IGetListUserWalletSuccess => ({
  type: GetListUserWalletTypes.GET_LIST_USER_WALLET_SUCCESS,
  data,
});

export const getListUserWalletError = (
  errorMsg: any
): IGetListUserWalletError => ({
  type: GetListUserWalletTypes.GET_LIST_USER_WALLET_FAILD,
  error: errorMsg,
});

export const getListUserWalletFilter = (
  payload: any
): IGetListUserWalletFilter => ({
  type: GetListUserWalletTypes.GET_LIST_USER_WALLET_FILTER,
  payload,
});

export const getListUserWalletFilterDebounce = (
  payload: any
): IGetListUserWalletFiterDebounce => ({
  type: GetListUserWalletTypes.GET_LIST_USER_WALLET_FILTER_DEBOUNCE,
  payload,
});
