import { toastSuccess, toastError } from 'src/helpers/toastHelper';
import { getListUser } from 'src/store/actions';
import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { SetKYCPriorityAllUserTypes } from '../../actions/setKYCPriorityAllUser';
import {
  setKYCPriorityAllUserError,
  setKYCPriorityAllUserFetching,
  setKYCPriorityAllUserFilter,
  setKYCPriorityAllUserSuccess
} from '../../actions/setKYCPriorityAllUser/action';
import { handleSetKYCPriorityAllUser } from 'src/apis/service/Priority';
import { getKYCSystemStatus } from 'src/store/actions/getKYCSystemStatus';

function* setKYCPriorityAllUserSaga(action: any): Generator<any> {


  yield put(setKYCPriorityAllUserFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleSetKYCPriorityAllUser(payload);
    if (data && data?.status === 200) {
      // yield put(setKYCPriorityAllUserSuccess(data));
      var filter = {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: 'LastLoginAttemptDate',
        orderDirection: 'desc'
      };
      yield put(getListUser(filter));
      yield put(getKYCSystemStatus({}));
      toastSuccess('bo_set_all_kyc_priority_config_success');
    } else {
      yield put(setKYCPriorityAllUserError('error'));
      toastError('bo_you_can_t_set_all_kyc_priority_config');
    }
  } catch (error) {
    yield put(setKYCPriorityAllUserError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setKYCPriorityAllUserFilter(action.payload));
}
export function* watchSetKYCPriorityAllUser() {
  yield takeEvery(
    SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER,
    setKYCPriorityAllUserSaga
  );
  yield debounce(
    500,
    SetKYCPriorityAllUserTypes.SETKYC_PRIORITY_ALL_USER_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
