
interface IDataListWallet {
  data:any[];
  error:any;
  success:boolean;
}

export interface IListWalletState {
    dataWalletOfOwner:IDataListWallet;
    dataWalletOfUser:IDataListWallet;
    isFetching:boolean;
}

export const initialListWalletState: IListWalletState = {
    dataWalletOfOwner:{
        data:[],
        error:null,
        success:false
    },
    dataWalletOfUser:{
        data:[],
        error:null,
        success:false
    },
    isFetching:false,
}

