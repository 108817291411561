import { UpdateScoreKPITypes } from './actionTypes';
import { IUpdateScoreKPI, IUpdateScoreKPIFetching, IUpdateScoreKPISuccess, IUpdateScoreKPIError, IUpdateScoreKPIFilter, IUpdateScoreKPIFiterDebounce } from "./interfaces";


export const updateScoreKPI = (payload: Object,
    history?: any): IUpdateScoreKPI => ({
        type: UpdateScoreKPITypes.UPDATE_SCORE_KPI,
        payload,
        history
    })

export const updateScoreKPIFetching = (isFetching: boolean): IUpdateScoreKPIFetching => ({
    type: UpdateScoreKPITypes.UPDATE_SCORE_KPI_FETCHING,
    isFetching
})

export const updateScoreKPISuccess = (data: any): IUpdateScoreKPISuccess => ({
    type: UpdateScoreKPITypes.UPDATE_SCORE_KPI_SUCCESS,
    data
})

export const updateScoreKPIError = (errorMsg: any): IUpdateScoreKPIError => ({
    type: UpdateScoreKPITypes.UPDATE_SCORE_KPI_FAILD,
    error: errorMsg
})

export const updateScoreKPIFilter = (payload: any): IUpdateScoreKPIFilter => ({
    type: UpdateScoreKPITypes.UPDATE_SCORE_KPI_FILTER,
    payload
})

export const updateScoreKPIFilterDebounce = (payload: any): IUpdateScoreKPIFiterDebounce => ({
    type: UpdateScoreKPITypes.UPDATE_SCORE_KPI_FILTER_DEBOUNCE,
    payload
})
