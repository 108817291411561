import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletReceiveHistoryType } from 'src/store/actions';
import { IListCryptoWalletReceiveHistoryState, initialListCryptoWalletReceiveHistoryState } from './state';


export const GetListCryptoWalletReceiveHistoryReducer: Reducer = (
  state: IListCryptoWalletReceiveHistoryState = initialListCryptoWalletReceiveHistoryState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY_SUCCESS : {
      return {
          ...state,
          data: action?.data.data,
          isFetching: false,
          pagination: action.data.data.totalCount
      }
    }
    case GetCryptoWalletReceiveHistoryType.GET_LIST_FILTER_CRYPTO_WALLET_RECEIVE_HISTORY: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};