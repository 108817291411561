import { debounce, put, takeEvery } from "redux-saga/effects";
import { handleAgentBuySearch } from "src/apis/service/Agent";
import { toastDismiss, toastError } from "src/utils/newUtils/toastHelper";
import { AgentBuySearchTypes } from "../../actions/agentBuySearch";
import {
  agentBuySearchError,
  agentBuySearchFetching,
  agentBuySearchFilter,
  agentBuySearchSuccess,
} from "../../actions/agentBuySearch/action";

function* agentBuySearchSaga(action: any): Generator<any> {
  yield put(agentBuySearchFetching(true));
  const { payload, getDataAfterSuccess } = action;
  try {
    const data: any = yield handleAgentBuySearch(payload);
    if (data && data?.status === 200) {
      toastDismiss()
      getDataAfterSuccess();
      yield put(agentBuySearchSuccess(data));
    } else {
      toastError("bo_failed_to_match_label", "bo_failed_to_match_label", true);
      yield put(agentBuySearchError("error"));
    }
  } catch (error) {
    toastError("bo_failed_to_match_label", "bo_failed_to_match_label", true);
    yield put(agentBuySearchError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentBuySearchFilter(action.payload));
}
export function* watchAgentBuySearch() {
  yield takeEvery(AgentBuySearchTypes.AGENT_BUY_SEARCH, agentBuySearchSaga);
  yield debounce(
    500,
    AgentBuySearchTypes.AGENT_BUY_SEARCH_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
