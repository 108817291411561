import {ExportCryptoType} from './actionTypes';
import {
    IExportCryptoIsFetching,
    IExportCryptoSuccess,
    IExportCryptoFaild,
    IExportCrypto
} from './interfaces';

export const exportCryptoAtFetching = (isFetching:boolean) : IExportCryptoIsFetching  => ({
    type: ExportCryptoType.EXPORT_CRYPTO_FETCHING,
    isFetching
})

export const exportCrypto = (payload:Object, xlsx:boolean) : IExportCrypto => ({
    type: ExportCryptoType.EXPORT_CRYPTO,
    payload,
    xlsx
})

export const exportCryptoSuccess = (data:any) : IExportCryptoSuccess => ({
    type: ExportCryptoType.EXPORT_CRYPTO_SUCCESS,
    data
})

export const exportCryptoFaild = (error:any) : IExportCryptoFaild => ({
    type: ExportCryptoType.EXPORT_CRYPTO_FAILD,
    error
})
