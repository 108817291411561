import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetTransactionsFilterStatuses } from 'src/apis/service/P2PTransaction';
import { GetTransactionsFilterStatusesTypes } from '../../actions/getTransactionsFilterStatuses';
import {
  getTransactionsFilterStatusesError,
  getTransactionsFilterStatusesFetching,
  getTransactionsFilterStatusesFilter,
  getTransactionsFilterStatusesSuccess,
} from '../../actions/getTransactionsFilterStatuses/action';
import { setStorageItem } from 'src/utils/newUtils/localStorageHelper';
import { LocalStorageKey } from 'src/contants/constantUtils/localStorageKey';

function* getTransactionsFilterStatusesSaga(action: any): Generator<any> {
  yield put(getTransactionsFilterStatusesFetching(true));

  try {
    const data: any = yield handleGetTransactionsFilterStatuses();
    if (data && data?.status === 200) {
      yield put(getTransactionsFilterStatusesSuccess(data));
      setStorageItem(
        LocalStorageKey.TransactionsFilterStatuses,
        JSON.stringify(data?.data)
      );
    } else {
      yield put(getTransactionsFilterStatusesError('error'));
    }
  } catch (error) {
    yield put(getTransactionsFilterStatusesError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getTransactionsFilterStatusesFilter(action.payload));
}
export function* watchGetTransactionsFilterStatuses() {
  yield takeEvery(
    GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES,
    getTransactionsFilterStatusesSaga
  );
  yield debounce(
    500,
    GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
