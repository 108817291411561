import { AnyAction, Reducer } from 'redux';
import { IGetAgentRankByAdminState, initialGetAgentRankByAdminState } from './state';
import { GetAgentRankByAdminTypes } from 'src/store/actions/getAgentRankByAdmin';

export const GetAgentRankByAdminReducer: Reducer = (
  state: IGetAgentRankByAdminState = initialGetAgentRankByAdminState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
