import {CreateCryptoExchangeOffersListTypes} from './actionTypes';
import { ICreateCryptoExchangeOffersList, ICreateCryptoExchangeOffersListFetching, ICreateCryptoExchangeOffersListSuccess, ICreateCryptoExchangeOffersListError, ICreateCryptoExchangeOffersListFilter, ICreateCryptoExchangeOffersListFiterDebounce } from "./interfaces";


export const createCryptoExchangeOffersList = (payload:Object): ICreateCryptoExchangeOffersList => ({
    type: CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST,
    payload
})

export const createCryptoExchangeOffersListFetching = (isFetching:boolean) : ICreateCryptoExchangeOffersListFetching => ({
    type: CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_FETCHING,
    isFetching
})

export const createCryptoExchangeOffersListSuccess = (data:any) : ICreateCryptoExchangeOffersListSuccess => ({
   type: CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS,
   data
})

export const createCryptoExchangeOffersListError = (errorMsg:any) : ICreateCryptoExchangeOffersListError => ({
    type: CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD,
    error:errorMsg
})

export const createCryptoExchangeOffersListFilter = (payload: any): ICreateCryptoExchangeOffersListFilter => ({
    type: CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER,
    payload
})

export const createCryptoExchangeOffersListFilterDebounce = (payload: any): ICreateCryptoExchangeOffersListFiterDebounce => ({
    type: CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE,
    payload 
})
