import { UserRole } from '../models/roles';
import { getStorageItem } from '../utils/storage';

export default (roles: UserRole[]) => {
  const user = getStorageItem('user');
  const userRoles = user ? JSON.parse(user).roles : [];
  const formatUserRoles = userRoles?.map((role: any) => {
    return role.roleId;
  });

  return roles?.reduce((acc, role) => {
    return acc || formatUserRoles?.includes(role);
  }, false);
};
