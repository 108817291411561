import { AnyAction, Reducer } from 'redux';
import { CreateAnnouncementsType } from 'src/store/actions';
import {
  ICreateAnnouncementsState,
  initialDataCreateAnnouncementsState,
} from './state';
import { toastError, toastSuccess } from '../../../../../helpers/toastHelper';

export const CreateAnnouncementsReducer: Reducer = (
  state: ICreateAnnouncementsState = initialDataCreateAnnouncementsState,
  action: AnyAction
) => {
  let feching = {
    ...state,
    isFetching: true,
  };
  switch (action.type) {
    case CreateAnnouncementsType.CREATE_ANNOUNCEMENTS: {
      return {
        ...state,
        isFetching: true,
        success: false,
      };
    }
    case CreateAnnouncementsType.FETCH_ANNOUNCEMENTS: {
      return feching;
    }
    case CreateAnnouncementsType.CREATE_ANNOUNCEMENTS_ERROR: {
      // toastError(action.error);
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case CreateAnnouncementsType.CREATE_ANNOUNCEMENTS_SUCCESS: {
      toastSuccess('bo_create_anouncement_success');
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        success: true,
      };
    }
    default:
      return state;
  }
};
