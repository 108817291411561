export interface UnLockUser {
    data: any,
    errors: any,
    success: any
}

export interface UnLockUserState {
    data: UnLockUser,
    isFetching: boolean,
    countSuccess: number
}

export const initialUnLockUserState: UnLockUserState = {
    data: {
        data: {},
        errors: '',
        success: false
    },
    isFetching: false,
    countSuccess: 0
}