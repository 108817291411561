import { put, takeLatest } from 'redux-saga/effects';
import {
  GetKYCFuncByIdTypes,
  getKYCFuncByIdError,
  getKYCFuncByIdFetching,
  getKYCFuncByIdSuccess,
} from 'src/store/actions/getKYCFunctionById';
import { getKycFuncByUserId } from 'src/apis/service/Kyc/limitedKyc';
import { getUserInfor } from 'src/utils/storage';

function* getKYCFuncByIdSaga(action: any): Generator<any> {
  yield put(getKYCFuncByIdFetching());

  try {
    const userInfo = getUserInfor();

    const data: any = yield getKycFuncByUserId({ userId: userInfo?.userId });
    if (data && data?.status === 200) {
      yield put(getKYCFuncByIdSuccess(data?.data));
    } else {
      yield put(getKYCFuncByIdError());
    }
  } catch (error) {
    yield put(getKYCFuncByIdError());
  }
}

export function* watchGetKYCFunctionById() {
  yield takeLatest(GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID, getKYCFuncByIdSaga);
}
