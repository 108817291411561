import { delay, put, takeLatest } from 'redux-saga/effects';
import { setKycSetting } from 'src/apis/service/Kyc';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getKycDetail, updateKycSettingFaild, updateKycSettingIsFetching, updateKycSettingSuccess, UpdateKycSettingType } from 'src/store/actions';

function* updateKycSetting(action: any): Generator<any> {


  const { payload, kycId } = action;  
  yield put(updateKycSettingIsFetching(true))
  const data: any = yield setKycSetting(payload);
  try {
    if(data && data?.status === 200){
      yield put(getKycDetail(kycId));
      yield delay(1000)
      yield put(updateKycSettingSuccess(data));
      yield toastSuccess('bo_updated_exchange_offer')
    } else {
      yield toastError('bo_error_label')
      yield put(updateKycSettingFaild('error'));
    }
  } catch (error: any) {
    yield put(updateKycSettingFaild(error))
    yield toastError('bo_error_label')
  }
}

export function* watchUpdateKycSetting() {
   yield takeLatest(UpdateKycSettingType.UPDATE_KYC_SETTING , updateKycSetting)
}