import { AnyAction, Reducer } from 'redux';
import { IGetTokenDetailState, initialGetTokenDetailState } from './state';
import { GetTokenDetailTypes } from 'src/store/actions/getTokenDetail';

export const GetTokenDetailReducer: Reducer = (
  state: IGetTokenDetailState = initialGetTokenDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetTokenDetailTypes.GET_TOKEN_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetTokenDetailTypes.GET_TOKEN_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetTokenDetailTypes.GET_TOKEN_DETAIL_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetTokenDetailTypes.GET_TOKEN_DETAIL_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
