import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getCountries } from 'src/apis/service/Countries';
import { getLanguages } from 'src/apis/service/Languages';
import {
  countriesFaild,
  countriesIsFetching,
  countriesSuccess,
  CreateAnnouncementsType,
  languagesFaild,
  languagesIsFetching,
  languagesSuccess,
} from 'src/store/actions';
import { setStorageItem } from 'src/utils/storage';

function* fetchLanguageList(): Generator<any> {
  yield put(languagesIsFetching(true));
  try {
    const data: any = yield call(getLanguages);
    if (data && data?.status === 200) {
      yield put(languagesSuccess(data));
      setStorageItem('NCD_languages', JSON.stringify(data?.data));
    } else {
      yield put(languagesFaild('error'));
    }
  } catch (error) {
    yield put(languagesFaild(error));
  }
}

function* fetchCountryList(): Generator<any> {
  yield put(countriesIsFetching(true));
  try {
    const data: any = yield call(getCountries);
    if (data) {
      const newData = data?.data.map((item: any) => {
        item = {
          ...item,
          value: item.countryId,
          label: item.name,
        };
        return item;
      });
      setStorageItem('NCD_countries', JSON.stringify(newData));
      yield put(countriesSuccess(newData));
    }
  } catch (error) {
    yield put(countriesFaild(error));
  }
}

function* fetchLanguageCountry() {
  try {
    yield all([call(fetchLanguageList), call(fetchCountryList)]);
  } catch (error) {}
}

export function* watchGetListCountry() {
  yield takeEvery(
    CreateAnnouncementsType.FETCH_LIST_COUNTRY,
    fetchLanguageCountry
  );
}
