import * as Sentry from "@sentry/react";
import Request from "src/apis/config";
import { ListParams } from "src/models/common";
export interface AdminTransferRequest {
  userId: string;
  currencyId: number;
  searchText?: string;
  pageNumber: number;
  pageSize: number;
}

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_TRANSFER});
const getAdminTransfers = async (data: AdminTransferRequest) : Promise<any> => {
  const url = `/Admin/SearchDirectTransfer`;
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const getDirectTransfersByAgent = async (data: ListParams) : Promise<any> => {
  const url = `/LatestTransactions/search`;
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export { getAdminTransfers,getDirectTransfersByAgent };
