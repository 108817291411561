import {HandleWithdrawTransactionTypes} from './actionTypes';
import { IHandleWithdrawTransaction, IHandleWithdrawTransactionFetching, IHandleWithdrawTransactionSuccess, IHandleWithdrawTransactionError, IHandleWithdrawTransactionFilter, IHandleWithdrawTransactionFiterDebounce } from "./interfaces";


export const handleWithdrawTransaction = (payload:Object): IHandleWithdrawTransaction => ({
    type: HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION,
    payload
})

export const handleWithdrawTransactionFetching = (isFetching:boolean) : IHandleWithdrawTransactionFetching => ({
    type: HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION_FETCHING,
    isFetching
})

export const handleWithdrawTransactionSuccess = (data:any) : IHandleWithdrawTransactionSuccess => ({
   type: HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION_SUCCESS,
   data
})

export const handleWithdrawTransactionError = (errorMsg:any) : IHandleWithdrawTransactionError => ({
    type: HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION_FAILD,
    error:errorMsg
})

export const handleWithdrawTransactionFilter = (payload: any): IHandleWithdrawTransactionFilter => ({
    type: HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION_FILTER,
    payload
})

export const handleWithdrawTransactionFilterDebounce = (payload: any): IHandleWithdrawTransactionFiterDebounce => ({
    type: HandleWithdrawTransactionTypes.HANDLE_WITHDRAW_TRANSACTION_FILTER_DEBOUNCE,
    payload 
})
