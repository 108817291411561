import { PAGE_SIZE_DEFAULT } from './../../../contants/pageSize';

// get transaction

import { ParamsDefault } from "src/contants/paramsDefault";

interface IDataItemTransaction {
  data:{
      items:any[];
      totalCount:number;
  };
  status:number;
  message:string;
}

export interface IListTransactionsState {
    data:IDataItemTransaction;
    isFetching:boolean,
    filter: Object,
    pagination: any,
}

export const initialListTransactionState: IListTransactionsState = {
    data:{
        data:{
            items:[],
            totalCount:0
        },
        message:'',
        status:0
    },
    isFetching:false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
    },
    pagination: {
        totalCount: 0,
    }
}

