import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetListWalletBO } from 'src/apis/service/Wallet';
import {
  GetAllWalletAdminBOType,
  getAllWalletAdminBOAtFetching,
  getAllWalletAdminBOFailed,
  getAllWalletAdminBOSuccess,
  setFilterWalletAdminBO,
} from 'src/store/actions/WalletAdminBO/getWalletAdminBO';

function* getAllWalletAdminBOSaga(action: any): Generator<any> {
  yield put(getAllWalletAdminBOAtFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetListWalletBO(payload);
    if (data.status === 200) {
      yield put(getAllWalletAdminBOSuccess(data));
    } else {
      yield put(getAllWalletAdminBOFailed(data.message));
    }
  } catch (error) {
    yield put(getAllWalletAdminBOFailed(error));
  }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterWalletAdminBO(action.payload));
}

export function* watchGetAllWalletAdminBO() {
  yield takeEvery(
    GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO,
    getAllWalletAdminBOSaga
  );
  yield debounce(
    500,
    GetAllWalletAdminBOType.GET_FILTER_DEBOUNCE_WALLET_ADMIN_BO,
    handleSearchDebounce
  );
}
