import { GetAllScoreKPITypes } from './actionTypes';
import { IGetAllScoreKPI, IGetAllScoreKPIFetching, IGetAllScoreKPISuccess, IGetAllScoreKPIError, IGetAllScoreKPIFilter, IGetAllScoreKPIFiterDebounce } from "./interfaces";


export const getAllScoreKPI = (payload: any): IGetAllScoreKPI => ({
    type: GetAllScoreKPITypes.GET_ALL_SCORE_KPI,
    payload
})

export const getAllScoreKPIFetching = (isFetching: boolean): IGetAllScoreKPIFetching => ({
    type: GetAllScoreKPITypes.GET_ALL_SCORE_KPI_FETCHING,
    isFetching
})

export const getAllScoreKPISuccess = (data: any): IGetAllScoreKPISuccess => ({
    type: GetAllScoreKPITypes.GET_ALL_SCORE_KPI_SUCCESS,
    data
})

export const getAllScoreKPIError = (errorMsg: any): IGetAllScoreKPIError => ({
    type: GetAllScoreKPITypes.GET_ALL_SCORE_KPI_FAILD,
    error: errorMsg
})

export const getAllScoreKPIFilter = (payload: any): IGetAllScoreKPIFilter => ({
    type: GetAllScoreKPITypes.GET_ALL_SCORE_KPI_FILTER,
    payload
})

export const getAllScoreKPIFilterDebounce = (payload: any): IGetAllScoreKPIFiterDebounce => ({
    type: GetAllScoreKPITypes.GET_ALL_SCORE_KPI_FILTER_DEBOUNCE,
    payload
})
