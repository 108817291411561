import { AnyAction, Reducer } from 'redux';
import { GetKycListTypes } from 'src/store/actions';
import { IListKycListState, initialListKycListState } from './state';


export const GetListKycListReducer: Reducer = (
  state: IListKycListState = initialListKycListState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetKycListTypes.GET_KYC_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetKycListTypes.GET_KYC_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetKycListTypes.GET_KYC_LIST_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case GetKycListTypes.GET_LIST_FILTER_KYC_LIST: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};