import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetTransactionSellBuySearch } from 'src/apis/service/P2PTransaction';
import { getP2PBuyTransactionsFaild, getP2PBuyTransactionsIsFetching, getP2PBuyTransactionsSuccess, GetP2PBuyTransactionsType, setFilterP2PBuyTransactions } from 'src/store/actions';


function* getListP2PBuyTransactions(action: any): Generator<any> {
    yield put(getP2PBuyTransactionsIsFetching(true))
    const { payload } = action;
    try {
      const data : any = yield call(handleGetTransactionSellBuySearch, payload);  
      if(data?.status === 200) {
        yield put(getP2PBuyTransactionsSuccess(data?.data === null ? [] : data?.data));
      } else {
        yield put(getP2PBuyTransactionsFaild('error'));
      }
    } catch (error) {
        yield put(getP2PBuyTransactionsFaild(error));
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterP2PBuyTransactions(action.payload));
}

export function* watchGetListP2PBuyTransactions() {
  yield takeEvery(GetP2PBuyTransactionsType.GET_P2P_BUY_TRANSACTIONS , getListP2PBuyTransactions)
  yield debounce(500, GetP2PBuyTransactionsType.SET_LIST_FILTER_DEBOUNCE_P2P_BUY_TRANSACTIONS, handleSearchDebounce)
}