import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handlePostAllScoreKPI } from 'src/apis/service/ScorePeriodConfig';
import { GetAllScoreKPITypes } from '../../actions/getAllScoreKPI';
import { getAllScoreKPIError, getAllScoreKPIFetching, getAllScoreKPIFilter, getAllScoreKPISuccess } from '../../actions/getAllScoreKPI/action';

function* getAllScoreKPISaga(action: any): Generator<any> {
  yield put(getAllScoreKPIFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handlePostAllScoreKPI(payload)
    if (data && data?.status === 200) {
      yield put(getAllScoreKPISuccess(data))
    } else {
      yield put(getAllScoreKPIError('error'))
    }
  } catch (error) {
    yield put(getAllScoreKPIError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAllScoreKPIFilter(action.payload));
}
export function* watchGetAllScoreKPI() {
  yield takeEvery(GetAllScoreKPITypes.GET_ALL_SCORE_KPI, getAllScoreKPISaga)
  yield debounce(500, GetAllScoreKPITypes.GET_ALL_SCORE_KPI_FILTER_DEBOUNCE, handleSearchDebounce)
}
