import { GetOfferDetailType } from './actionTypes';
import { 
    IFetchingGetOfferDetail,
    IGetListFilterOfferDetail,
    IGetOfferDetail,
    IGetOfferDetailClean,
    IGetOfferDetailError,
    IGetOfferDetailSuccess
 } from './interfaces';

export const getOfferDetailIsFetching = (isFetching:boolean) : IFetchingGetOfferDetail => ({
    type: GetOfferDetailType.GET_OFFER_DETAIL_FETCHING,
    isFetching
})

export const getOfferDetail = (payload: number) : IGetOfferDetail => ({
    type: GetOfferDetailType.GET_OFFER_DETAIL,
    payload
})

export const getOfferDetailSuccess = (data:any) : IGetOfferDetailSuccess => ({
    type: GetOfferDetailType.GET_OFFER_DETAIL_SUCCESS,
    data
})

export const getOfferDetailFaild = (error:any) : IGetOfferDetailError => ({
    type: GetOfferDetailType.GET_OFFER_DETAIL_ERROR,
    error
})

export const setFilterOfferDetail = (payload: any): IGetListFilterOfferDetail => ({
    type: GetOfferDetailType.SET_FILTER_LIST_OFFER_DETAIL,
    payload
})
export const getOfferDetailClean = () : IGetOfferDetailClean => ({
    type: GetOfferDetailType.GET_OFFER_DETAIL_CLEAN
})