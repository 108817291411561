import { delay, put, takeLatest } from 'redux-saga/effects';
import { setKycUpdateStepStatus } from 'src/apis/service/Kyc';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getKycDetail, updateKycStepStatusFaild, updateKycStepStatusIsFetching, updateKycStepStatusSuccess, UpdateKycStepStatusType } from 'src/store/actions';
import { getKycListHistory } from '../../actions/kycHistory/action';
import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';

function* updateKycStepStatus(action: any): Generator<any> {


  const { payload, kycId } = action;  
  yield put(updateKycStepStatusIsFetching(true))
  const data: any = yield setKycUpdateStepStatus(payload);
  try {
    if(data && data?.status === 200){
      yield put(getKycDetail(kycId));
      yield put(getKycListHistory({
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        kycId: kycId
      }));
      yield delay(1000)
      yield put(updateKycStepStatusSuccess(data));
      yield toastSuccess('bo_update_kyc_success')
    } else {
      yield toastError('bo_update_kyc_fail')
      yield put(updateKycStepStatusFaild('error'))
    }
  } catch (error: any) {
    yield put(updateKycStepStatusFaild(error))
    yield toastError('bo_error_label')
  }
}

export function* watchUpdateKycStepStatus() {
   yield takeLatest(UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS , updateKycStepStatus)
}