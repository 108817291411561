import Request from "../../config";
import * as Sentry from "@sentry/react";
import { ListParams } from "src/models/common";

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_TRANSFER});
interface CreateOfferSell {
    currencyId: number,
    bankAccountId: number,
    amount: number,
    minimumAmount: number,
    creatorPhone: string,
    country: {
      phoneCode: string
    }
}
const createOfferSell = async (data:CreateOfferSell) : Promise<any> => {
    try {
      return await requestApi.post(`/Offer/Sell/Create`,data)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const createOfferBuy = async (data:CreateOfferSell) : Promise<any> => {
    try {
      return await requestApi.post(`/Offer/Buy/Create`,data)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const handleDeleteOfferByAgent = async (data:any) : Promise<any> => {
    try {
      return await requestApi.post(`/Offer/delete/${data}`)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const editOffer = async (data:any) : Promise<any> => {
  try {
    return await requestApi.post(`/Offer/AgentEditOffer`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const getOfferDetailById = async (offerId:number) : Promise<any> => {
  try {
    return await requestApi.post(`/Offer/GetOfferById?offerId=${offerId}`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleExportOfferSearch = async (data: ListParams) : Promise<any[]> => {
  const url = '/Offer/ExportOfferSearch';
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleExportCrypto = async (data: ListParams) : Promise<any[]> => {
  const url = '/Offer/ExportCrypto';
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleExportDirectTransfer = async (data: ListParams) : Promise<any[]> => {
  const url = '/Admin/ExportDirectTransfer';
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleExportExchange = async (data: ListParams) : Promise<any[]> => {
  const url = '/P2PExchange/ExportAdminOfferSearch';
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleExportCryptoSendTransaction = async (data: ListParams) : Promise<any[]> => {
  const url = '/Overview/ExportSendHistoryCrypto';
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleExportCryptoReceiveTransaction = async (data: ListParams) : Promise<any[]> => {
  const url = '/Overview/ExportReceiveHistoryCrypto';
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export {
    createOfferBuy,
    createOfferSell,
    handleDeleteOfferByAgent,
    editOffer,
    getOfferDetailById,
    handleExportOfferSearch,
    handleExportCrypto,
    handleExportDirectTransfer,
    handleExportExchange,
    handleExportCryptoReceiveTransaction,
    handleExportCryptoSendTransaction
}