import { AnyAction, Reducer } from 'redux';
import { IGetListTransactionCryptoState, initialGetListTransactionCryptoState } from './state';
import { GetListTransactionCryptoTypes } from 'src/store/actions/getListTransactionCrypto';

export const GetListTransactionCryptoReducer: Reducer = (
  state: IGetListTransactionCryptoState = initialGetListTransactionCryptoState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
