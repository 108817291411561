import { put, takeEvery, select } from 'redux-saga/effects';
import { RemoveNotificationType } from '../../actions/removeNotification/actionTypes';
import { removeNotificationIsFetching, removeNotificationSuccess, removeNotificationFaild } from '../../actions/removeNotification/action';
import { handleDeleteNotification } from '../../../apis/service/Notifications';
import { getNotifications } from 'src/store/actions/getNotifications/action';
import { IApplicationState } from 'src/store/IApplicationState';
function* RemoveNotificationSaga(action: any): Generator<any> {
  yield put(removeNotificationIsFetching(true))
  const { payload } = action;
  try {
    const dataRemoveNotification: any = yield handleDeleteNotification(payload)
    if (dataRemoveNotification.status === 200) {
      // const params:any = yield select((state:IApplicationState) => state.listNotification.filter)
      yield put(removeNotificationSuccess(dataRemoveNotification))
      // after will select filter in store and get api getNotification
      // yield put(getNotifications(params))
    } else {
      yield put(removeNotificationFaild(dataRemoveNotification.errors[0]))
    }
  } catch (error) {
    yield put(removeNotificationFaild(error))
  }
}
export function* watchRemoveNotification() {
  yield takeEvery(RemoveNotificationType.REMOVE_NOTIFICATION, RemoveNotificationSaga)
}