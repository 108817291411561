import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetCryptoTransactionHistory } from 'src/apis/service/Agent';
import { GetCryptoTransactionHistoryTypes } from '../../actions/getCryptoTransactionHistory';
import { getCryptoTransactionHistoryError, getCryptoTransactionHistoryFetching, getCryptoTransactionHistoryFilter, getCryptoTransactionHistorySuccess } from '../../actions/getCryptoTransactionHistory/action';

function* getCryptoTransactionHistorySaga(action:any):Generator<any> {
    yield put(getCryptoTransactionHistoryFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetCryptoTransactionHistory(payload)
      if(data && data?.status === 200){
        yield put(getCryptoTransactionHistorySuccess(data))
      } else {
        yield put(getCryptoTransactionHistoryError('error'))
      }
    } catch (error) {
        yield put(getCryptoTransactionHistoryError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getCryptoTransactionHistoryFilter(action.payload));
}
export function* watchGetCryptoTransactionHistory() {
   yield takeEvery(GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY, getCryptoTransactionHistorySaga)
   yield debounce(500,GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_FILTER_DEBOUNCE,handleSearchDebounce)
}
