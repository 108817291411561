import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_ACCOUNT });

interface PayloadCreateKycRoleFunc {
  userId: string;
  functionId: Array<number>;
}

interface KycFuncByUser {
  userId: string;
}

const getKycListFunc = async (): Promise<any> => {
  const url = `/User/GetListKYCFunctions`;
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const createKycRoleFunction = async (
  data: PayloadCreateKycRoleFunc
): Promise<any> => {
  const url = '/User/CreateKycRoleFunction';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getKycFuncByUserId = async (params: KycFuncByUser): Promise<any> => {
  const url = `/User/GetKYCFunctionById`;
  try {
    return await requestApi.get(url, { params });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export { getKycListFunc, createKycRoleFunction, getKycFuncByUserId };
