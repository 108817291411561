import { AnyAction, Reducer } from 'redux';
import { toastError } from '../../../helpers/toastHelper';
import { CreateNotificationTypes } from '../../actions/createNotification/actionTypes';
import { CreateNotificationState, initialCreateNotificationState } from './state';

export const CreateNotificationReducer: Reducer = (
  state: CreateNotificationState = initialCreateNotificationState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateNotificationTypes.CREATE_NOTIFICATION: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case CreateNotificationTypes.CREATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true
      };
    }
    case CreateNotificationTypes.CREATE_NOTIFICATION_FAILD: {
      toastError(action.error);
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
