import {GetUnreadCountNotificationTypes} from './actionTypes';
import { IGetUnreadCountNotification, IGetUnreadCountNotificationFetching, IGetUnreadCountNotificationSuccess, IGetUnreadCountNotificationError, IGetUnreadCountNotificationFilter, IGetUnreadCountNotificationFiterDebounce } from "./interfaces";


export const getUnreadCountNotification = (): IGetUnreadCountNotification => ({
    type: GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION
})

export const getUnreadCountNotificationFetching = (isFetching:boolean) : IGetUnreadCountNotificationFetching => ({
    type: GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_FETCHING,
    isFetching
})

export const getUnreadCountNotificationSuccess = (data:any) : IGetUnreadCountNotificationSuccess => ({
   type: GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_SUCCESS,
   data
})

export const getUnreadCountNotificationError = (errorMsg:any) : IGetUnreadCountNotificationError => ({
    type: GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_FAILD,
    error:errorMsg
})

export const getUnreadCountNotificationFilter = (payload: any): IGetUnreadCountNotificationFilter => ({
    type: GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_FILTER,
    payload
})

export const getUnreadCountNotificationFilterDebounce = (payload: any): IGetUnreadCountNotificationFiterDebounce => ({
    type: GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_FILTER_DEBOUNCE,
    payload 
})
