import { AnyAction, Reducer } from 'redux';
import { GetP2PSellTransactionsType } from 'src/store/actions';
import { IListP2PSellTransactionsState, initialListP2PSellTransactionsState } from './state';


export const GetListP2PSellTransactionsReducer: Reducer = (
  state: IListP2PSellTransactionsState = initialListP2PSellTransactionsState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case GetP2PSellTransactionsType.GET_P2P_SELL_TRANSACTIONS_SUCCESS : {
      return {
        ...state,
        data: action?.data?.items,
        isFetching: false,
        pagination: action?.data?.totalCount
      }
    }
    case GetP2PSellTransactionsType.SET_LIST_FILTER_P2P_SELL_TRANSACTIONS: {
      return {
          ...state,
          filter: action.payload
      }
    }
      default:
        return state;
    }
};