import { GetListUserDeactivatedTypes } from './actionTypes';
import {
    IGetListFiterDebounceUserDeactivated,
    IGetListFiterUserDeactivated,
    IGetListUserDeactivated,
    IGetListUserDeactivatedFaild,
    IGetListUserDeactivatedFetching,
    IGetListUserDeactivatedSuccess
} from './interfaces';

export const getListUserDeactivatedAtFetching = (isFetching:boolean) : IGetListUserDeactivatedFetching  => ({
    type: GetListUserDeactivatedTypes.GET_LIST_USER_DEACTIVATED_FETCHING,
    isFetching
})

export const getListUserDeactivated = (payload:Object) : IGetListUserDeactivated => ({
    type: GetListUserDeactivatedTypes.GET_LIST_USER_DEACTIVATED,
    payload
})

export const getListUserDeactivatedSuccess = (data:any) : IGetListUserDeactivatedSuccess => ({
    type: GetListUserDeactivatedTypes.GET_LIST_USER_DEACTIVATED_SUCCESS,
    data
})

export const getListUserDeactivatedFaild = (error:any) : IGetListUserDeactivatedFaild => ({
    type: GetListUserDeactivatedTypes.GET_LIST_USER_DEACTIVATED_FAILD,
    error
})

export const setFilterListUserDeactivated = (payload: any): IGetListFiterUserDeactivated => ({
    type: GetListUserDeactivatedTypes.GET_LIST_FILTER_USER_DEACTIVATED,
    payload
})

export const setFilterDebounceUserDeactivated = (payload: any): IGetListFiterDebounceUserDeactivated => ({
    type: GetListUserDeactivatedTypes.GET_LIST_FILTER_DEBOUNCE_USER_DEACTIVATED,
    payload 
})