export interface IGetKYCFunctionByIdState {
  data: number[];
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export const initialGetKYCFunctionByIdState: IGetKYCFunctionByIdState = {
  data: [],
  isError: false,
  isFetching: false,
  isSuccess: false,
};
