import Request from "../../config";
import * as Sentry from "@sentry/react";
const requestApi = new Request({ url: process.env.REACT_APP_BASE_ADMIN_CRYPTO_URL });

const getListTransaction = async (payload: any): Promise<any> => {
    try {
        return await requestApi.get(`/wallet/transactions`, { params: payload })
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const handleWithdrawTransaction = async (payload: any): Promise<any> => {
    try {
        return await requestApi.patch(`/transactions/withdraw/${payload.transactionId}`, { status: payload.status })
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const handleRetryTransaction = async (payload:any): Promise<any> => {
    try {
        return await requestApi.post(`/transactions/${payload.transactionId}/retry`)
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const handleTransactionExportExcel = async (payload:any): Promise<any> => {
    try {
        return await requestApi.get(`/transactions/export`, { headers: {  responseType: 'blob',  'Content-Disposition': "attachment; filename=template.xlsx", 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }, params: payload, responseType: 'arraybuffer' })
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}


export {
    getListTransaction,
    handleWithdrawTransaction,
    handleRetryTransaction,
    handleTransactionExportExcel
}