import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_CONFIG });
const getCountries = async (): Promise<any> => {
  try {
    return await requestApi.get(`Country/GetCountries`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export { getCountries };
