import { AnyAction, Reducer } from 'redux';
import { initialActiveCurrencyState, IDataActiveCurrencyState } from './state';
import { ActiveCurrencyType } from '../../../actions/currency';

export const ActiveCurrencyReducer: Reducer = (
  state: IDataActiveCurrencyState = initialActiveCurrencyState,
  action: AnyAction
) => {
  switch (action.type) {
    case ActiveCurrencyType.ACTIVE_CURRENCY: {
      return {
        ...state,
        isFetching: true,
        success: false
      }
    }
    case ActiveCurrencyType.ACTIVE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true,
        successCount: state.successCount + 1
      }
    }
    case ActiveCurrencyType.ACTIVE_CURRENCY_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      }
    }
    default: {
      return state
    }
  }
}