import { AnyAction, Reducer } from 'redux';
import {
  IGetListUserWalletState,
  IWallet,
  initialGetListUserWalletState,
} from './state';
import { GetListUserWalletTypes } from 'src/store/actions/getListUserWallet';

export const GetListUserWalletReducer: Reducer = (
  state: IGetListUserWalletState = initialGetListUserWalletState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListUserWalletTypes.GET_LIST_USER_WALLET: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListUserWalletTypes.GET_LIST_USER_WALLET_SUCCESS: {
      const wallets: IWallet[] = [];

      action.data.data.wallets.forEach(
        ({ balances }: { balances: IWallet[] }) => {
          balances.forEach((wallet, index) => {
            wallets.push({
              ...wallet,
              hidePhone: !index ? false : true,
            });
          });
        }
      );
      return {
        ...state,
        totalWallet: action.data.data.totalWallet,
        data: wallets,
        isFetching: false,
        totalValueInUSD: Number(action.data.data.valueInUSD),
        totalValueInKRW: Number(action.data.data.valueInKRW),
        totalCount: action.data.data.totalCount,
      };
    }
    case GetListUserWalletTypes.GET_LIST_USER_WALLET_FAILD: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case GetListUserWalletTypes.GET_LIST_USER_WALLET_FILTER: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};
