interface RemoveUserBankAccAgent  {
    data:any,
    status:number,
    message:string
}

export interface RemoveUserBankAccAgentState {
    data:RemoveUserBankAccAgent,
    isFetching:boolean
}

export const initialRemoveUserBankAccAgentState: RemoveUserBankAccAgentState = {
    data:{
        data:null,
        status:0,
        message:''
    },
    isFetching:false
}