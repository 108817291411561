import { AnyAction, Reducer } from 'redux';
import { IGetInvoiceDetailState, initialGetInvoiceDetailState } from './state';
import { GetInvoiceDetailTypes } from 'src/store/actions/getInvoiceDetail';

export const GetInvoiceDetailReducer: Reducer = (
  state: IGetInvoiceDetailState = initialGetInvoiceDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetInvoiceDetailTypes.GET_INVOICE_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetInvoiceDetailTypes.GET_INVOICE_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetInvoiceDetailTypes.GET_INVOICE_DETAIL_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetInvoiceDetailTypes.GET_INVOICE_DETAIL_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetInvoiceDetailTypes.GET_INVOICE_DETAIL_CLEAN: {
      return {
        ...initialGetInvoiceDetailState,
      };
    }
    default: {
      return state;
    }
  }
};
