export const LocalStorageKey = Object.freeze({
  ListCryptoSupported: "listCryptoSupported",
  WebLanguage: "NCD_webLanguage",
  UserToken: "userToken",
  UserInfo: "user",
  ListHolaCurrencies: "NCD_currencies",
  UserPhoneCode: "userPhoneCode",
  CryptoSetting: "cryptoSetting",
  TransactionsFilterTypes: "transactionsFilterTypes",
  WalletDefault: "walletDefault",
  ListWallet: "listWallet",
  TransactionsFilterStatuses: "transactionsFilterStatuses",
});
