
// get transaction

import { ListParams } from "src/models/common";

interface IDataItemOfferHistoryStatus {
  data:{
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListOfferHistoryStatusState {
    data:IDataItemOfferHistoryStatus;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialListOfferHistoryStatusState: IListOfferHistoryStatusState = {
    data:{
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        // @ts-ignore
        PageNumber: 1,
        PageSize: 10,
    },
    pagination: {
        totalCount: 0,
    }
}

