import { AnyAction, Reducer } from 'redux';
import { initialWalletRefillState, WalletRefillState } from './state';
import { WalletRefillType } from '../../actions/wallet-refill/actionTypes';
import { toastSuccess,toastError } from '../../../helpers/toastHelper';

export const WalletRefillReducer: Reducer = (
  state: WalletRefillState = initialWalletRefillState,
  action: AnyAction
) => {

  switch (action.type) {
    case WalletRefillType.WALLET_REFILL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case WalletRefillType.WALLET_REFILL_SUCCESS: {


      toastSuccess('bo_balance_successfully_changed')
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case WalletRefillType.WALLET_REFILL_ERROR: {
      toastError(action.error)
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
