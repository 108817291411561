
import {put, takeEvery, select} from 'redux-saga/effects';
import { activeCurrencyBO } from 'src/apis/service/Currencies';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { ListParams } from 'src/models/common';
import { 
   ActiveCurrencyType,
   activeCurrencyIsFetching,
   activeCurrencySuccess,
   activeCurrencyFaild
 } from 'src/store/actions/currency';
import { getAllCurrencyBO } from 'src/store/actions/currency/getAllCurrency';
import { IApplicationState } from 'src/store/IApplicationState';

function* activeCurrencySaga(action:any): Generator<any> {


    yield put(activeCurrencyIsFetching(true))
    const {payload} = action
    try {
      const response:any = yield activeCurrencyBO(payload)      
      if(response.status === 200){
       const filter : any = yield select((state:IApplicationState) => state.listCurrencyBO.filter)
        yield put(activeCurrencySuccess(response.data));
        // yield put(getAllCurrencyBO(filter))
        toastSuccess('bo_currency_actived_successfully')
      } else {
        toastError('bo_cannot_active_these_currency')
      } 
    } catch (error) {
        yield put(activeCurrencyFaild(error))
    }
}

export function* watchActiveCurrency() {
   yield takeEvery(ActiveCurrencyType.ACTIVE_CURRENCY, activeCurrencySaga)
}