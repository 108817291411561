
interface IDataListVenlyWalletsUser {
  data: any[];
  status: number;
  message: string;
}

export interface IListVenlyWalletsUserState {
    data: IDataListVenlyWalletsUser;
    isFetching: boolean,
}

export const initialListVenlyWalletsUserState: IListVenlyWalletsUserState = {
    data: {
        data:[],
        message:'',
        status:0
    },
    isFetching: false,
}

