import {GetKYCSystemStatusTypes} from './actionTypes';
import { IGetKYCSystemStatus, IGetKYCSystemStatusFetching, IGetKYCSystemStatusSuccess, IGetKYCSystemStatusError, IGetKYCSystemStatusFilter, IGetKYCSystemStatusFiterDebounce } from "./interfaces";


export const getKYCSystemStatus = (payload:Object): IGetKYCSystemStatus => ({
    type: GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS,
    payload
})

export const getKYCSystemStatusFetching = (isFetching:boolean) : IGetKYCSystemStatusFetching => ({
    type: GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_FETCHING,
    isFetching
})

export const getKYCSystemStatusSuccess = (data:any) : IGetKYCSystemStatusSuccess => ({
   type: GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_SUCCESS,
   data
})

export const getKYCSystemStatusError = (errorMsg:any) : IGetKYCSystemStatusError => ({
    type: GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_FAILD,
    error:errorMsg
})

export const getKYCSystemStatusFilter = (payload: any): IGetKYCSystemStatusFilter => ({
    type: GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_FILTER,
    payload
})

export const getKYCSystemStatusFilterDebounce = (payload: any): IGetKYCSystemStatusFiterDebounce => ({
    type: GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_FILTER_DEBOUNCE,
    payload 
})
