import {put, takeEvery} from 'redux-saga/effects';
import {transactionHistoryStatusLog} from 'src/store/actions/transactionHistoryStatusLog/actionTypes';
import {getListTransactionHistoryLogIsFetching,getListTransactionHistoryLogBuySuccess,getListTransactionHistoryLogFaild,getListTransactionHistoryLogSellSuccess} from 'src/store/actions/transactionHistoryStatusLog/action';
import { handleTransactionHistorystatuslog } from 'src/apis/service/Transactions';
function* transactionHistoryLogSaga(action:any):Generator<any>{
  yield put(getListTransactionHistoryLogIsFetching(true))
  const {payload,transactionType} = action;
  try {
    const data: any = yield handleTransactionHistorystatuslog(payload)  
    if (data?.status === 200) {
      if (transactionType === 'BUY') {
       yield put (getListTransactionHistoryLogBuySuccess(data))
      }
      if (transactionType === 'SELL') {
        yield put (getListTransactionHistoryLogSellSuccess(data))
       }
    } else {
      yield put(getListTransactionHistoryLogFaild('error')) 
    }
  } catch (error) {
     yield put(getListTransactionHistoryLogFaild(error)) 
  }
}
export function* watchTransactionHistoryLog(){
    yield takeEvery(transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG, transactionHistoryLogSaga)
}