import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetCryptoTransactionFee } from 'src/apis/service/Agent';
import { GetCryptoTransactionFeeTypes } from '../../actions/getCryptoTransactionFee';
import { getCryptoTransactionFeeError, getCryptoTransactionFeeFetching, getCryptoTransactionFeeFilter, getCryptoTransactionFeeSuccess } from '../../actions/getCryptoTransactionFee/action';

function* getCryptoTransactionFeeSaga(action:any):Generator<any> {
    yield put(getCryptoTransactionFeeFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetCryptoTransactionFee(payload)
      if(data && data?.status === 200){
        yield put(getCryptoTransactionFeeSuccess(data))
      } else {
        yield put(getCryptoTransactionFeeError('error'))
      }
    } catch (error) {
        yield put(getCryptoTransactionFeeError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getCryptoTransactionFeeFilter(action.payload));
}
export function* watchGetCryptoTransactionFee() {
   yield takeEvery(GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE, getCryptoTransactionFeeSaga)
   yield debounce(500,GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE_FILTER_DEBOUNCE,handleSearchDebounce)
}
