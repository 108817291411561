import { toastSuccess, toastError } from 'src/helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleAgentSubmitBank } from 'src/apis/service/P2PTransaction';
import { AgentSubmitBankTypes } from '../../actions/agentSubmitBank';
import { agentSubmitBankError, agentSubmitBankFetching, agentSubmitBankFilter, agentSubmitBankSuccess } from '../../actions/agentSubmitBank/action';

function* agentSubmitBankSaga(action: any): Generator<any> {


  yield put(agentSubmitBankFetching(true))
  const { payload, getData } = action;
  try {
    const data: any = yield handleAgentSubmitBank(payload)
    if (data && data?.status === 200) {
      // yield put(agentSubmitBankSuccess(data))
      getData();
      toastSuccess('bo_submit_bank_account_success');
    } else {
      toastError('bo_you_can_t_submit_bank_account');
      yield put(agentSubmitBankError('error'))
    }
  } catch (error) {
    toastError('bo_you_can_t_submit_bank_account');
    yield put(agentSubmitBankError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentSubmitBankFilter(action.payload));
}
export function* watchAgentSubmitBank() {
  yield takeEvery(AgentSubmitBankTypes.AGENT_SUBMIT_BANK, agentSubmitBankSaga)
  yield debounce(500, AgentSubmitBankTypes.AGENT_SUBMIT_BANK_FILTER_DEBOUNCE, handleSearchDebounce)
}
