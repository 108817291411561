import { PriorityType } from './actionTypes';
import { 
    IGetPriority,
    IFetchingGetPriority,
    IGetPriorityError,
    IGetPrioritySuccess
} from './interfaces';

export const getPrioriryIsFetching = (isFetching:boolean) : IFetchingGetPriority => ({
    type: PriorityType.GET_PRIORITY_FETCHING,
    isFetching
})

export const getPriority = () : IGetPriority => ({
    type: PriorityType.GET_PRIORITY,
})

export const getPrioritySuccess = (data:any) : IGetPrioritySuccess => ({
    type: PriorityType.GET_PRIORITY_SUCCESS,
    data
})

export const getPriorityFaild = (error:any) : IGetPriorityError => ({
    type: PriorityType.GET_PRIORITY_FAILD,
    error
})
