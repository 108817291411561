import { AnyAction, Reducer } from 'redux';
import { IGetAdminTransactionUserState, initialGetAdminTransactionUserState } from './state';
import { GetAdminTransactionUserTypes } from 'src/store/actions/getAdminTransactionUser';

export const GetAdminTransactionUserReducer: Reducer = (
  state: IGetAdminTransactionUserState = initialGetAdminTransactionUserState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAdminTransactionUserTypes.GET_ADMIN_TRANSACTION_USER_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
