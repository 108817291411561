import { put, takeEvery } from "redux-saga/effects";
import { GetListWalletTypes } from "../../actions/getListWallet/actionTypes";
import {
  getListWalletrAtFetching,
  getListWalletOfOwnerSuccess,
  getListWalletOfUserSuccess,
  getListWalletFaild,
} from "../../actions/getListWallet/action";
import { handleGetAllWallet } from "../../../apis/service/user";
import { setStorageItem } from "../../../utils/storage";
import { LocalStorageKey } from "src/contants/constantUtils/localStorageKey";

function* getListWalletSaga(action: any): Generator<any> {
  yield put(getListWalletrAtFetching(true));
  const { payload, owner } = action;
  try {
    const dataListWallet: any = yield handleGetAllWallet(payload);
    if (dataListWallet && dataListWallet?.status === 200) {
      if (owner) {
        yield put(getListWalletOfOwnerSuccess(dataListWallet));
        setStorageItem("listWallet", JSON.stringify(dataListWallet?.data));
        setStorageItem(
          LocalStorageKey.WalletDefault,
          JSON.stringify(
            (dataListWallet?.data || []).find((w: any) => w.isDefault)
          )
        );
      } else {
        yield put(getListWalletOfUserSuccess(dataListWallet));
      }
    } else {
      yield put(getListWalletFaild("error"));
    }
  } catch (error) {
    yield put(getListWalletFaild(error));
  }
}
export function* watchGetListWallet() {
  yield takeEvery(GetListWalletTypes.GET_LIST_WALLET, getListWalletSaga);
}
