import { put, takeEvery, debounce } from 'redux-saga/effects';
import { UpdateCryptoExchangeOffersListTypes } from '../../actions/updateCryptoExchangeOffersList';
import {
  updateCryptoExchangeOffersListFetching,
  updateCryptoExchangeOffersListSuccess,
  updateCryptoExchangeOffersListError,
  updateCryptoExchangeOffersListFilter,
} from '../../actions/updateCryptoExchangeOffersList/action';
import { handleUpdateCryptoExchangeOffersList } from 'src/apis/service/Agent';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* updateCryptoExchangeOffersListSaga(action: any): Generator<any> {
  yield put(updateCryptoExchangeOffersListFetching(true));
  const { offerId, payload } = action;
  try {
    const data: any = yield handleUpdateCryptoExchangeOffersList(
      offerId,
      payload
    );
    if (data && data?.status === 200) {
      toastSuccess('bo_success');
      yield put(updateCryptoExchangeOffersListSuccess(data));
    } else {
      toastError('bo_failed');
      updateCryptoExchangeOffersListError('error');
    }
  } catch (error) {
    toastError('bo_failed');
    yield put(updateCryptoExchangeOffersListError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(updateCryptoExchangeOffersListFilter(action.payload));
}
export function* watchUpdateCryptoExchangeOffersList() {
  yield takeEvery(
    UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST,
    updateCryptoExchangeOffersListSaga
  );
  yield debounce(
    500,
    UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
