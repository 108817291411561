import { debounce, put, takeEvery } from 'redux-saga/effects';
import { getKycList } from 'src/apis/service/Kyc';
import { getKycListByStageAtFetching, getKycListByStageFaild, getKycListByStageSuccess, GetKycListByStageTypes, setFilterKycListByStage } from 'src/store/actions';

function* getKycListByStageSaga(action:any):Generator<any> {
    yield put(getKycListByStageAtFetching(true))
    const {payload} = action;
    try {
      const dataKycListByStage:any = yield getKycList(payload)
      if(dataKycListByStage?.status === 200) {
        yield put(getKycListByStageSuccess(dataKycListByStage))
      } else {
        yield put(getKycListByStageFaild('error'))
      }
    } catch (error) {
        yield put(getKycListByStageFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterKycListByStage(action.payload));
}

export function* watchGetListKycListByStage() {
   yield takeEvery(GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE, getKycListByStageSaga)
   yield debounce(500, GetKycListByStageTypes.GET_LIST_FILTER_DEBOUNCE_KYC_LIST_BY_STAGE, handleSearchDebounce)
}