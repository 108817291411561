import { AnyAction, Reducer } from 'redux';
import { ReSendFeatureNotificationType } from 'src/store/actions/reSendFeatureNotification/actionTypes';
import { IResendFeaturedNotificationState, initialResendFeaturedNotificationState } from 'src/store/reducers/reSendFeatureNotification/state';


export const resendFeaturedNotificationReducer: Reducer = (
  state: IResendFeaturedNotificationState = initialResendFeaturedNotificationState,
  action: AnyAction,
) => {
  switch (action.type) {
    case ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        success: true
      }
    }
    default:
      return state;
  }
};