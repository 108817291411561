import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { ListParams } from "src/models/common";

export interface IDataListTest {
    status: number;
    data: {
        items:any[],
        totalCount:number;
    };
    message: string;
}

export interface IDataListTestState {
   data: IDataListTest,
   filter: ListParams,
   pagination: any,
   isFetching: boolean
}

export const initialDataListTestState : IDataListTestState = {
    data: {
        data: {
            items:[],
            totalCount:0
        },
        status:0,
        message:''
    },
    filter:{
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT
    },
    pagination: {
        totalCount: 0,
    },
    isFetching:false
}