import { CancelP2PExchangeOffer } from './actionTypes';
import {
    ICancelP2PExchangeOffer,
    ICancelP2PExchangeOfferClear,
    ICancelP2PExchangeOfferFaild,
    ICancelP2PExchangeOfferFetching,
    ICancelP2PExchangeOfferSuccess
} from './interfaces';

export const cancelP2PExchangeOfferAtFetching = (isFetching: boolean): ICancelP2PExchangeOfferFetching => ({
    type: CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER_IS_FETCHING,
    isFetching
})

export const cancelP2PExchangeOffer = (offerId: number): ICancelP2PExchangeOffer => ({
    type: CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER,
    offerId
})

export const cancelP2PExchangeOfferSuccess = (data: any): ICancelP2PExchangeOfferSuccess => ({
    type: CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER_SUCCESS,
    data
})

export const cancelP2PExchangeOfferFaild = (error: any): ICancelP2PExchangeOfferFaild => ({
    type: CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER_FAILD,
    error
})

export const cancelP2PExchangeOfferClear = (): ICancelP2PExchangeOfferClear => ({
    type: CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER_CLEAR
})