
import { ChoosePriorityType } from './actionTypes';
import { IFetchingChoosePriority, IChoosePriority, IChoosePrioritySuccess, IChoosePriorityError } from './interfaces';


export const choosePriorityIsFetching = (isFetching:boolean) : IFetchingChoosePriority => ({
    type:ChoosePriorityType.CHOOSE_PRIORITY_FETCHING ,
    isFetching
})

export const choosePriority = (payload:Object) : IChoosePriority => ({
    type:ChoosePriorityType.CHOOSE_PRIORITY,
    payload
})

export const choosePrioritySuccess = (data:any) : IChoosePrioritySuccess => ({
    type:ChoosePriorityType.CHOOSE_PRIORITY_SUCCESS,
    data
})

export const choosePriorityFaild = (error:any) : IChoosePriorityError => ({
    type:ChoosePriorityType.CHOOSE_PRIORITY_ERROR,
    error
})