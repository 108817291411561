import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetListAddressBooks } from 'src/apis/service/Agent';
import { GetListAddressBooksTypes } from '../../actions/getListAddressBooks';
import { getListAddressBooksError, getListAddressBooksFetching, getListAddressBooksFilter, getListAddressBooksSuccess } from '../../actions/getListAddressBooks/action';

function* getListAddressBooksSaga(action:any):Generator<any> {
    yield put(getListAddressBooksFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetListAddressBooks(payload)
      if(data && data?.status === 200){
        yield put(getListAddressBooksSuccess(data))
      } else {
        yield put(getListAddressBooksError('error'))
      }
    } catch (error) {
        yield put(getListAddressBooksError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListAddressBooksFilter(action.payload));
}
export function* watchGetListAddressBooks() {
   yield takeEvery(GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS, getListAddressBooksSaga)
   yield debounce(500,GetListAddressBooksTypes.GET_LIST_ADDRESS_BOOKS_FILTER_DEBOUNCE,handleSearchDebounce)
}
