import { KycSetting } from 'src/models/KycUser';
import { UpdateKycSettingType } from './actionTypes';
import { IFetchingUpdateKycSetting, IUpdateKycSetting, IUpdateKycSettingError, IUpdateKycSettingReset, IUpdateKycSettingSuccess } from './interfaces';

export const updateKycSettingIsFetching = (isFetching:boolean) : IFetchingUpdateKycSetting => ({
    type: UpdateKycSettingType.UPDATE_KYC_SETTING_FETCHING,
    isFetching
})

export const updateKycSetting = (payload: KycSetting | undefined, kycId: string): IUpdateKycSetting => ({
    type: UpdateKycSettingType.UPDATE_KYC_SETTING,
    payload,
    kycId
})

export const updateKycSettingSuccess = (data:any): IUpdateKycSettingSuccess => ({
    type: UpdateKycSettingType.UPDATE_KYC_SETTING_SUCCESS,
    data
})

export const updateKycSettingFaild = (error:any): IUpdateKycSettingError => ({
    type: UpdateKycSettingType.UPDATE_KYC_SETTING_ERROR,
    error
})

export const updateKycSettingReset = (): IUpdateKycSettingReset => ({
    type: UpdateKycSettingType.UPDATE_KYC_SETTING_RESET,
})
