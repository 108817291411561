import { call, put, takeLatest } from 'redux-saga/effects';
import { createAnnouncement } from 'src/apis/service/Announcements';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { createAnnouncementsFaild, createAnnouncementsIsFetching, createAnnouncementsSuccess, CreateAnnouncementsType } from 'src/store/actions';

function* createAnnouncements(action: any): Generator<any>{


  yield put(createAnnouncementsIsFetching(true))
  const {payload, history} = action;
  try {
    const dataCreateAdmin: any = yield call(createAnnouncement, payload);
    if (Boolean(dataCreateAdmin.message === "Success")) {
        yield put(createAnnouncementsSuccess(dataCreateAdmin));
        history.push('/dashboard/announcement/items');
        toastSuccess('bo_create_announcement_success');
    } else {
      toastError('bo_annoucement_create_fail')
      history.push('/dashboard/announcement/items');
    }
  } catch (error) {
     yield put(createAnnouncementsFaild(error)) 
  }
}

export function* watchCreateAnnouncements() {
  // yield takeEvery(CreateAnnouncementsType.FETCH_ANNOUNCEMENTS , fetchAnnouncementsItems)
  yield takeLatest(CreateAnnouncementsType.CREATE_ANNOUNCEMENTS , createAnnouncements)
}