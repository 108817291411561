import { GetTransactionByOfferTypes } from './actionTypes';
import { 
    IFetchingTransactionsByOffer,
    IGetTransactionsByOffer,
    IGetTransactionsByOfferSuccess,
    IGetTransactionsByOfferError,
    IGetListFilterTransactionsByOffer
 } from './interfaces';

export const getListTransactionByOfferIsFetching = (isFetching:boolean) : IFetchingTransactionsByOffer => ({
    type: GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER_IS_FETCHING,
    isFetching
})

export const getListTransactionByOffer = (payload: Object) : IGetTransactionsByOffer => ({
    type: GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER,
    payload
})

export const getListTransactionByOfferSuccess = (data:any) : IGetTransactionsByOfferSuccess => ({
    type: GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER_SUCCESS,
    data
})

export const getListTransactionByOfferFaild = (error:any) : IGetTransactionsByOfferError => ({
    type: GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER_ERROR,
    error
})

export const setFilterListTransactionByOffer = (payload: any): IGetListFilterTransactionsByOffer => ({
    type: GetTransactionByOfferTypes.SET_LIST_TRANSACTION_BY_OFFER,
    payload
})