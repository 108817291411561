import { all, call, put, takeLatest } from 'redux-saga/effects';
import { handleGetReceiveCryptoHistory, handleGetStatistical } from 'src/apis/service/CryptoWallet';
import { getCryptoWalletReceiveHistoryFaild, getCryptoWalletReceiveHistoryIsFetching, getCryptoWalletReceiveHistorySuccess, GetCryptoWalletReceiveHistoryType, getCryptoWalletReceiveStatisticalSuccess } from 'src/store/actions';

function* getListCryptoWalletReceiveHistorySaga(action: any): Generator<any>  {
  const data:any = yield call(handleGetReceiveCryptoHistory, action)
  yield put(getCryptoWalletReceiveHistorySuccess(data));
}

function* getListStatisticalReceiveSaga(): Generator<any>  {
  const data: any = yield call(handleGetStatistical, 0)
  const strimData = data?.data.filter((_statistical: any, index: number) => index < 3)
  const sumPercent = strimData.reduce((acc: any, o: any) => acc + parseFloat(o.percent), 0);
  strimData.push({symbol: "bo_others_label", percent: (100 - sumPercent).toFixed(2)})
  const template = [
    {
        "id": 0,
        "color": "#3f51b5"
    },
    {
        "id": 1,
        "color": "#7986cb"
    },
    {
        "id": 2,
        "color": "#c5cae9"
    },
    {
      "id": 3,
      "color": "#c5cae9"
    }
  ]
  // @ts-ignore
  strimData?.map((item, index)=> Object.assign(item, template.find(itemTemp => itemTemp.id===index)))
  yield put(getCryptoWalletReceiveStatisticalSuccess(strimData));
}

function* fetchListCryptoReceiveWallet(action: any) {
  const { payload } = action;
  yield put(getCryptoWalletReceiveHistoryIsFetching(true))
  try {
    yield all([
      call(() => getListCryptoWalletReceiveHistorySaga(payload)),
      call(getListStatisticalReceiveSaga),
    ]);
    yield put(getCryptoWalletReceiveHistorySuccess('success'));
  } catch (error) {
    yield put(getCryptoWalletReceiveHistoryFaild('error'))
  }
}

export function* watchGetListCryptoWalletReceiveHistory() {
   yield takeLatest(GetCryptoWalletReceiveHistoryType.GET_CRYPTO_WALLET_RECEIVE_HISTORY , fetchListCryptoReceiveWallet)
}
