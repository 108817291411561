import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleDeactiveScoreKPI } from 'src/apis/service/ScorePeriodConfig';
import { DeactiveScoreKPITypes } from '../../actions/deactiveScoreKPI';
import {
  deactiveScoreKPIError, deactiveScoreKPIFetching, deactiveScoreKPIFilter, deactiveScoreKPISuccess
} from '../../actions/deactiveScoreKPI/action';
import { toastError, toastSuccess } from './../../../helpers/toastHelper';

function* deactiveScoreKPISaga(action: any): Generator<any> {


  yield put(deactiveScoreKPIFetching(true));
  const { payload, history } = action;
  try {
    const data: any = yield handleDeactiveScoreKPI(payload);
    if (data && data?.status === 200) {
      yield put(deactiveScoreKPISuccess(true));
      toastSuccess('bo_deactive_score_kpi_config_success');
    } else {
      toastError('bo_you_can_t_deactive_score_kpi_config');
      // yield put(activeScoreKPIError('error'))
    }
    // yield put(history.push(`/dashboard/settings/user-score-setting`));
  } catch (error) {
    yield put(deactiveScoreKPIError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(deactiveScoreKPIFilter(action.payload));
}
export function* watchDeactiveScoreKPI() {
  yield takeEvery(
    DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI,
    deactiveScoreKPISaga
  );
  yield debounce(
    500,
    DeactiveScoreKPITypes.DEACTIVE_SCORE_KPI_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
