import { AnyAction, Reducer } from 'redux';
import { IHandleBlockOfferState, initialHandleBlockOfferState } from './state';
import { HandleBlockOfferTypes } from 'src/store/actions/handleBlockOffer';

export const HandleBlockOfferReducer: Reducer = (
  state: IHandleBlockOfferState = initialHandleBlockOfferState,
  action: AnyAction
) => {
  switch (action.type) {
    case HandleBlockOfferTypes.HANDLE_BLOCK_OFFER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data?.data?.totalCount,
        message: action.data?.message,
        success: action.data?.success
      };
    }
    case HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_RESET_STATE: {
      return {
        ...initialHandleBlockOfferState,
        isFetching: false
      }
    }
    default: {
      return state;
    }
  }
};
