import { KycStepStatus } from 'src/models/KycUser';
import { UpdateKycStepStatusType } from './actionTypes';
import { IFetchingUpdateKycStepStatus, IUpdateKycStepStatus, IUpdateKycStepStatusError, IUpdateKycStepStatusReset, IUpdateKycStepStatusSuccess } from './interfaces';

export const updateKycStepStatusIsFetching = (isFetching:boolean) : IFetchingUpdateKycStepStatus => ({
    type: UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS_FETCHING,
    isFetching
})

export const updateKycStepStatus = (payload: KycStepStatus | undefined, kycId: string): IUpdateKycStepStatus => ({
    type: UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS,
    payload,
    kycId
})

export const updateKycStepStatusSuccess = (data:any): IUpdateKycStepStatusSuccess => ({
    type: UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS_SUCCESS,
    data
})

export const updateKycStepStatusFaild = (error:any): IUpdateKycStepStatusError => ({
    type: UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS_ERROR,
    error
})

export const updateKycStepStatusReset = (): IUpdateKycStepStatusReset => ({
    type: UpdateKycStepStatusType.UPDATE_KYC_STEP_STATUS_RESET,
})
