import { debounce, put, takeEvery } from "redux-saga/effects";
import { GetTokenDetailTypes } from "../../actions/getTokenDetail";
import {
  getTokenDetailError,
  getTokenDetailFetching,
  getTokenDetailFilter,
  getTokenDetailSuccess,
} from "../../actions/getTokenDetail/action";
import { handleGetTokenDetail } from "src/apis/service/Agent";

function* getTokenDetailSaga(action: any): Generator<any> {
  yield put(getTokenDetailFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetTokenDetail(payload);
    if (data && data?.status === 200) {
      yield put(getTokenDetailSuccess(data));
    } else {
      yield put(getTokenDetailError("error"));
    }
  } catch (error) {
    yield put(getTokenDetailError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getTokenDetailFilter(action.payload));
}
export function* watchGetTokenDetail() {
  yield takeEvery(GetTokenDetailTypes.GET_TOKEN_DETAIL, getTokenDetailSaga);
  yield debounce(
    500,
    GetTokenDetailTypes.GET_TOKEN_DETAIL_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
