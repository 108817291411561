import { AnyAction, Reducer } from 'redux';
import { TransactionHistoryState, initialTransactionHistoryState } from './state';
import { transactionHistoryStatusLog } from '../../actions/transactionHistoryStatusLog/actionTypes';


export const TransactionHistoryLogReducer: Reducer = (
  state: TransactionHistoryState = initialTransactionHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_SUCCESS_BUY: {
      return {
        ...state,
        dataBuy: action.data,
        isFetching: false,
      };
    }
    case transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_SUCCESS_SELL: {
      return {
        ...state,
        dataSell: action.data,
        isFetching: false,
      };
    }
    case transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_CLEAN: {
      return {
        ...initialTransactionHistoryState
      };
    }
    default:
      return state;
  }
};