import {TransactionOverviewTypes} from './actionTypes';
import { ITransactionOverview, ITransactionOverviewFetching, ITransactionOverviewSuccess, ITransactionOverviewError, ITransactionOverviewFilter, ITransactionOverviewFiterDebounce } from "./interfaces";


export const transactionOverview = (payload:Object): ITransactionOverview => ({
    type: TransactionOverviewTypes.TRANSACTION_OVERVIEW,
    payload
})

export const transactionOverviewFetching = (isFetching:boolean) : ITransactionOverviewFetching => ({
    type: TransactionOverviewTypes.TRANSACTION_OVERVIEW_FETCHING,
    isFetching
})

export const transactionOverviewSuccess = (data:any) : ITransactionOverviewSuccess => ({
   type: TransactionOverviewTypes.TRANSACTION_OVERVIEW_SUCCESS,
   data
})

export const transactionOverviewError = (errorMsg:any) : ITransactionOverviewError => ({
    type: TransactionOverviewTypes.TRANSACTION_OVERVIEW_FAILD,
    error:errorMsg
})

export const transactionOverviewFilter = (payload: any): ITransactionOverviewFilter => ({
    type: TransactionOverviewTypes.TRANSACTION_OVERVIEW_FILTER,
    payload
})

export const transactionOverviewFilterDebounce = (payload: any): ITransactionOverviewFiterDebounce => ({
    type: TransactionOverviewTypes.TRANSACTION_OVERVIEW_FILTER_DEBOUNCE,
    payload 
})
