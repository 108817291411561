import { EditUserPriorityType } from './actionTypes';
import { IEditUserPriority, IEditUserPriorityError, IEditUserPrioritySuccess, IFetchingEditUserPriority } from './interfaces';


export const editUserIsFetching = (isFetching:boolean) : IFetchingEditUserPriority => ({
    type: EditUserPriorityType.EDIT_USER_PRIORITY_FETCHING,
    isFetching
})

export const editUserPriority = (payload:Object) : IEditUserPriority => ({
    type: EditUserPriorityType.EDIT_USER_PRIORITY,
    payload
})

export const editUserPrioritySuccess = (data:any) : IEditUserPrioritySuccess => ({
    type: EditUserPriorityType.EDIT_USER_PRIORITY_SUCCESS,
    data
})

export const editUserPriorityFaild = (error:any) : IEditUserPriorityError => ({
    type: EditUserPriorityType.EDIT_USER_PRIORITY_ERROR,
    error
})