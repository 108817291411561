export interface CreateLanguageText {
    data: any,
    errors: any,
    success: any
}

export interface CreateLanguageTextState {
    data: CreateLanguageText,
    isFetching: boolean,
    success: false
}

export const initialCreateLanguageTextState: CreateLanguageTextState = {
    data: {
        data: {},
        errors: '',
        success: false
    },
    isFetching: false,
    success: false
}