import { AnyAction, Reducer } from 'redux';
import { IGetListAdminFunctionState, initialGetListAdminFunctionState } from './state';
import { GetListAdminFunctionTypes } from 'src/store/actions/getListAdminFunction';

export const GetListAdminFunctionReducer: Reducer = (
  state: IGetListAdminFunctionState = initialGetListAdminFunctionState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION: {
      return {
        ...state,
        isFetching: 0,
      };
    }
    case GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: 1,
        pagination: action.data.data.totalCount,
      };
    }
    case GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_FAILD: {
      return {
        ...state,
        isFetching: 1,
        error: action.error,
      };
    }
    case GetListAdminFunctionTypes.GET_LIST_ADMIN_FUNCTION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetListAdminFunctionTypes.CLEAN_LIST_ADMIN_FUNCTION: {
      return {
        ...state,
        data: initialGetListAdminFunctionState.data,
      };
    }
    default: {
      return state;
    }
  }
};
