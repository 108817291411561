import { AnyAction, Reducer } from 'redux';
import { IGetKYCSystemStatusState, initialGetKYCSystemStatusState } from './state';
import { GetKYCSystemStatusTypes } from 'src/store/actions/getKYCSystemStatus';

export const GetKYCSystemStatusReducer: Reducer = (
  state: IGetKYCSystemStatusState = initialGetKYCSystemStatusState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
