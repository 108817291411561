import { ListParams } from 'src/models/common';

interface IDataSetUserLanguage {
  data: {
    items: any[];
    total: number;
  };
  error: any;
  success: boolean;
}

export interface ISetUserLanguageState {
  data: IDataSetUserLanguage;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialSetUserLanguageState: ISetUserLanguageState = {
  data: {
    data: {
      items: [],
      total: 0,
    },
    error: null,
    success: false,
  },
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
