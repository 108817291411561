export interface ExportCrypto  {
    data:any,
    errors:any,
    success:any
}

export interface ExportCryptoState {
    data: ExportCrypto,
    isFetching:boolean
}

export const initialExportCryptoState: ExportCryptoState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}