import { PAGE_SIZE_DEFAULT } from './../../../../contants/pageSize';
import { ListParams } from "src/models/common";

interface IDataListAllAgentRank {
    data:{
      items:any[];
      totalCount:number;
    };
    message:string;
    status:number;
  }
  
  export interface IListAllAgentRankState {
      data:IDataListAllAgentRank;
      isFetching:boolean,
      filter: ListParams,
      pagination: {
        totalCount: number
      },
  }
  
  export const initialListAllAgentRankState: IListAllAgentRankState = {
      data:{
          data:{
              items:[],
              totalCount:0
          },
          message:'',
          status:0
      },
      isFetching:false,
      filter: {
          pageNumber: 1,
          pageSize: PAGE_SIZE_DEFAULT
      },
      pagination: {
          totalCount: 0,
      }
  }