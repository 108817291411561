import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
interface IDataListP2PBuyTransactions {
  data: {
    items: any[];
    totalCount: number;
  };
  status: number;
  message: string;
}

export interface IListP2PBuyTransactionsState {
  data: IDataListP2PBuyTransactions;
  isFetching: boolean;
  filter: Object;
  pagination: Object;
}

export const initialListP2PBuyTransactionsState: IListP2PBuyTransactionsState =
  {
    data: {
      data: {
        items: [],
        totalCount: 0,
      },
      status: 0,
      message: '',
    },
    filter: {
      searchText: '',
      transactionType: 'BUY',
      currencyFilter: [],
      statusFilter: '',
      matchedDateFilter: [],
      pageNumber: 1,
      pageSize: PAGE_SIZE_DEFAULT,
      orderBy: 'CreatedDate',
      orderDirection: 'desc',
    },
    pagination: 0,
    isFetching: false,
  };
