import { CreateAndEditCurrencyType } from './actionTypes';
import { IAddAndEditCurrency,IAddAndEditCurrencyError,IAddAndEditCurrencySuccess,IFetchingAddAndEditCurrency } from './interfaces';
import { History } from 'history';

export const addAndEditCurrencyIsFetching = (isFetching:boolean) : IFetchingAddAndEditCurrency => ({
    type:CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY_FETCHING,
    isFetching
})

export const addAndEditCurrency = (payload:Object, isEdit:boolean, history: History) : IAddAndEditCurrency => ({
    type:CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY,
    payload,
    isEdit,
    history
})

export const addAndEditCurrencySuccess = (data:any) : IAddAndEditCurrencySuccess => ({
    type:CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY_SUCCESS,
    data
})

export const addAndEditCurrencyFaild = (error:any) : IAddAndEditCurrencyError => ({
    type:CreateAndEditCurrencyType.CREATE_AND_EDIT_CURRENCY_FAILD,
    error
})