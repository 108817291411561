import {AdminGetInvoiceListTypes} from './actionTypes';
import { IAdminGetInvoiceList, IAdminGetInvoiceListFetching, IAdminGetInvoiceListSuccess, IAdminGetInvoiceListError, IAdminGetInvoiceListFilter, IAdminGetInvoiceListFiterDebounce } from "./interfaces";


export const adminGetInvoiceList = (payload:Object): IAdminGetInvoiceList => ({
    type: AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST,
    payload
})

export const adminGetInvoiceListFetching = (isFetching:boolean) : IAdminGetInvoiceListFetching => ({
    type: AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_FETCHING,
    isFetching
})

export const adminGetInvoiceListSuccess = (data:any) : IAdminGetInvoiceListSuccess => ({
   type: AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_SUCCESS,
   data
})

export const adminGetInvoiceListError = (errorMsg:any) : IAdminGetInvoiceListError => ({
    type: AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_FAILD,
    error:errorMsg
})

export const adminGetInvoiceListFilter = (payload: any): IAdminGetInvoiceListFilter => ({
    type: AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_FILTER,
    payload
})

export const adminGetInvoiceListFilterDebounce = (payload: any): IAdminGetInvoiceListFiterDebounce => ({
    type: AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_FILTER_DEBOUNCE,
    payload 
})
