import {GetAgentRankByAdminTypes} from './actionTypes';
import { IGetAgentRankByAdmin, IGetAgentRankByAdminFetching, IGetAgentRankByAdminSuccess, IGetAgentRankByAdminError, IGetAgentRankByAdminFilter, IGetAgentRankByAdminFiterDebounce } from "./interfaces";


export const getAgentRankByAdmin = (payload:Object): IGetAgentRankByAdmin => ({
    type: GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN,
    payload
})

export const getAgentRankByAdminFetching = (isFetching:boolean) : IGetAgentRankByAdminFetching => ({
    type: GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_FETCHING,
    isFetching
})

export const getAgentRankByAdminSuccess = (data:any) : IGetAgentRankByAdminSuccess => ({
   type: GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_SUCCESS,
   data
})

export const getAgentRankByAdminError = (errorMsg:any) : IGetAgentRankByAdminError => ({
    type: GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_FAILD,
    error:errorMsg
})

export const getAgentRankByAdminFilter = (payload: any): IGetAgentRankByAdminFilter => ({
    type: GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_FILTER,
    payload
})

export const getAgentRankByAdminFilterDebounce = (payload: any): IGetAgentRankByAdminFiterDebounce => ({
    type: GetAgentRankByAdminTypes.GET_AGENT_RANK_BY_ADMIN_FILTER_DEBOUNCE,
    payload 
})
