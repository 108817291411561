import { AnyAction, Reducer } from 'redux';
import { GetP2PExchangeTransactionsAgentType } from 'src/store/actions/P2PExchangeTransactionsAgent';
import { IListP2PExchangeTransactionsAgentState, initialListP2PExchangeTransactionsAgentState } from './state';


export const GetListP2PExchangeTransactionsAgentReducer: Reducer = (
  state: IListP2PExchangeTransactionsAgentState = initialListP2PExchangeTransactionsAgentState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT_ERROR : {
      return {
        ...state,
        error: action?.error,
        isFetching: false,
      };
    }
    case GetP2PExchangeTransactionsAgentType.GET_P2P_EXCHANGE_TRANSACTIONS_AGENT_SUCCESS : {
      return {
          ...state,
          data: action?.data?.data?.items,
          isFetching: false,
          pagination: action?.data?.data?.totalCount
      }
    }
    case GetP2PExchangeTransactionsAgentType.SET_LIST_FILTER_P2P_EXCHANGE_TRANSACTIONS_AGENT: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};