import {UserAvatarTypes} from './actionTypes';
import { IUserAvatar, IUserAvatarFetching, IUserAvatarSuccess, IUserAvatarError, IUserAvatarFilter, IUserAvatarFiterDebounce } from "./interfaces";


export const userAvatar = (payload:any): IUserAvatar => ({
    type: UserAvatarTypes.USER_AVATAR,
    payload
})

export const userAvatarFetching = (isFetching:boolean) : IUserAvatarFetching => ({
    type: UserAvatarTypes.USER_AVATAR_FETCHING,
    isFetching
})

export const userAvatarSuccess = (data:any) : IUserAvatarSuccess => ({
   type: UserAvatarTypes.USER_AVATAR_SUCCESS,
   data
})

export const userAvatarError = (errorMsg:any) : IUserAvatarError => ({
    type: UserAvatarTypes.USER_AVATAR_FAILD,
    error:errorMsg
})

export const userAvatarFilter = (payload: any): IUserAvatarFilter => ({
    type: UserAvatarTypes.USER_AVATAR_FILTER,
    payload
})

export const userAvatarFilterDebounce = (payload: any): IUserAvatarFiterDebounce => ({
    type: UserAvatarTypes.USER_AVATAR_FILTER_DEBOUNCE,
    payload 
})
