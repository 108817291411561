import { ListParams } from "src/models/common";

interface IDataGetOfferLogs {
//   data:{
//       items:any[];
//       total:number;
//   };
//   data: {
//     data: any[]
//   },
  data: any[];
  error:any;
  success:boolean;
}

export interface IGetOfferLogsState {
    data:IDataGetOfferLogs;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetOfferLogsState: IGetOfferLogsState = {
    data:{
        data: [],
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


