import {GetAgentDirectTransfersTypes} from './actionTypes';
import { IGetAgentDirectTransfers, IGetAgentDirectTransfersFetching, IGetAgentDirectTransfersSuccess, IGetAgentDirectTransfersError, IGetAgentDirectTransfersFilter, IGetAgentDirectTransfersFiterDebounce } from "./interfaces";


export const getAgentDirectTransfers = (payload:Object): IGetAgentDirectTransfers => ({
    type: GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS,
    payload
})

export const getAgentDirectTransfersFetching = (isFetching:boolean) : IGetAgentDirectTransfersFetching => ({
    type: GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_FETCHING,
    isFetching
})

export const getAgentDirectTransfersSuccess = (data:any) : IGetAgentDirectTransfersSuccess => ({
   type: GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_SUCCESS,
   data
})

export const getAgentDirectTransfersError = (errorMsg:any) : IGetAgentDirectTransfersError => ({
    type: GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_FAILD,
    error:errorMsg
})

export const getAgentDirectTransfersFilter = (payload: any): IGetAgentDirectTransfersFilter => ({
    type: GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_FILTER,
    payload
})

export const getAgentDirectTransfersFilterDebounce = (payload: any): IGetAgentDirectTransfersFiterDebounce => ({
    type: GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_FILTER_DEBOUNCE,
    payload 
})
