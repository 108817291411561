import { AgentTransferMoneyTypes } from "./actionTypes";
import {
  IAgentTransferMoney,
  IAgentTransferMoneyFetching,
  IAgentTransferMoneySuccess,
  IAgentTransferMoneyError,
  IAgentTransferMoneyFilter,
  IAgentTransferMoneyFiterDebounce,
} from "./interfaces";

export const agentTransferMoney = (
  payload: Object,
  getDataAfterSuccess: any
): IAgentTransferMoney => ({
  type: AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY,
  payload,
  getDataAfterSuccess,
});

export const agentTransferMoneyFetching = (
  isFetching: boolean
): IAgentTransferMoneyFetching => ({
  type: AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_FETCHING,
  isFetching,
});

export const agentTransferMoneySuccess = (
  data: any
): IAgentTransferMoneySuccess => ({
  type: AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_SUCCESS,
  data,
});

export const agentTransferMoneyError = (
  errorMsg: any
): IAgentTransferMoneyError => ({
  type: AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_FAILD,
  error: errorMsg,
});

export const agentTransferMoneyFilter = (
  payload: any
): IAgentTransferMoneyFilter => ({
  type: AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_FILTER,
  payload,
});

export const agentTransferMoneyFilterDebounce = (
  payload: any
): IAgentTransferMoneyFiterDebounce => ({
  type: AgentTransferMoneyTypes.AGENT_TRANSFER_MONEY_FILTER_DEBOUNCE,
  payload,
});
