import { AnyAction, Reducer } from 'redux';
import { IGetCryptoExchangeOffersListHistoriesState, initialGetCryptoExchangeOffersListHistoriesState } from './state';
import { GetCryptoExchangeOffersListHistoriesTypes } from 'src/store/actions/getCryptoExchangeOffersListHistories';

export const GetCryptoExchangeOffersListHistoriesReducer: Reducer = (
  state: IGetCryptoExchangeOffersListHistoriesState = initialGetCryptoExchangeOffersListHistoriesState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetCryptoExchangeOffersListHistoriesTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_HISTORIES_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
