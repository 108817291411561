import { put, takeEvery, debounce } from 'redux-saga/effects';
import { PostCryptoCreateExchangeTransactionTypes } from '../../actions/postCryptoCreateExchangeTransaction';
import { postCryptoCreateExchangeTransactionFetching, postCryptoCreateExchangeTransactionSuccess, postCryptoCreateExchangeTransactionError, postCryptoCreateExchangeTransactionFilter } from '../../actions/postCryptoCreateExchangeTransaction/action';
import { handlePostCryptoCreateExchangeTransaction } from 'src/apis/service/Agent';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* postCryptoCreateExchangeTransactionSaga(action: any): Generator<any> {
  yield put(postCryptoCreateExchangeTransactionFetching(true))
  const { payload, offerId } = action;
  try {
    const data: any = yield handlePostCryptoCreateExchangeTransaction(offerId, payload)
    if (data && data?.status === 200) {
      toastSuccess('bo_success')
      yield put(postCryptoCreateExchangeTransactionSuccess(data))
    } else {
      toastError('bo_failed')
      yield put(postCryptoCreateExchangeTransactionError('error'))
    }
  } catch (error) {
    toastError('bo_failed')
    yield put(postCryptoCreateExchangeTransactionError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(postCryptoCreateExchangeTransactionFilter(action.payload));
}
export function* watchPostCryptoCreateExchangeTransaction() {
  yield takeEvery(PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION, postCryptoCreateExchangeTransactionSaga)
  yield debounce(500, PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_FILTER_DEBOUNCE, handleSearchDebounce)
}
