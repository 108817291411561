import { AnyAction, Reducer } from 'redux';
import { IListAllAgentRankState, initialListAllAgentRankState } from './state';
import { GetAgentRankTypes } from '../../../actions/Agent/GetAgentRank';

export const GetListAgentRankReducer: Reducer = (
  state: IListAllAgentRankState = initialListAllAgentRankState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAgentRankTypes.GET_AGENT_RANK: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAgentRankTypes.GET_AGENT_RANK_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAgentRankTypes.GET_AGENT_RANK_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAgentRankTypes.FILTER_AGENT_RANK: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default: {
      return state;
    }
  }
};