import { GetP2PExchangeOfferDetailType } from './actionTypes';
import { IFetchingGetP2PExchangeOfferDetail, IGetP2PExchangeOfferDetail, IGetP2PExchangeOfferDetailError, IGetP2PExchangeOfferDetailSuccess } from './interfaces';

export const getP2PExchangeOfferDetailIsFetching = (isFetching:boolean) : IFetchingGetP2PExchangeOfferDetail => ({
    type: GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL_FETCHING,
    isFetching
})

export const getP2PExchangeOfferDetail = (payload: number) : IGetP2PExchangeOfferDetail => ({
    type: GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL,
    payload
})

export const getP2PExchangeOfferDetailSuccess = (data:any) : IGetP2PExchangeOfferDetailSuccess => ({
    type: GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL_SUCCESS,
    data
})

export const getP2PExchangeOfferDetailFaild = (error:any) : IGetP2PExchangeOfferDetailError => ({
    type: GetP2PExchangeOfferDetailType.GET_P2P_EXCHANGE_OFFER_DETAIL_ERROR,
    error
})