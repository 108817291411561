import { ReSendFeatureNotificationType } from './actionTypes';
import { 
    IFetchingResendFeaturedNotification,
    IResendFeaturedNotification,
    IResendFeaturedNotificationError,
    IResendFeaturedNotificationSuccess
 } from './interfaces';

export const resendFeaturedNotificationIsFetching = (isFetching:boolean) : IFetchingResendFeaturedNotification => ({
    type: ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION_IS_FETCHING,
    isFetching
})

export const resendFeaturedNotification = (payload: Object) : IResendFeaturedNotification => ({
    type: ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION,
    payload
})

export const resendFeaturedNotificationSuccess = (data:any) : IResendFeaturedNotificationSuccess => ({
    type: ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION_SUCCESS,
    data
})

export const resendFeaturedNotificationFaild = (error:any) : IResendFeaturedNotificationError => ({
    type: ReSendFeatureNotificationType.RESEND_FEATURED_NOTIFICATION_FAILD,
    error
})