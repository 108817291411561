import moment from "moment-timezone";

export function formatDateToUTCZeroTime(date: any, isEndDate?: boolean) {
  if (Boolean(date)) {
    const dateString = `${moment(date).utc(date).format("MM-DD-YYYY")}`;
    const arr = dateString.split("-");
    const dateUTC = isEndDate
      ? new Date(
          parseInt(arr[2]),
          parseInt(arr[0]) - 1,
          parseInt(arr[1]),
          23,
          59,
          59
        )
      : new Date(parseInt(arr[2]), parseInt(arr[0]) - 1, parseInt(arr[1]));
    return `${moment(dateUTC).utc().format("YYYY-MM-DDTHH:mm:ss")}Z`;
  }
}
export const formatDateToCustomType = (value: any, type: string) => {
  return `${moment(value).local().format(`YYYY${type}MM${type}DD`)}`;
};
export const formatDateInTable = (value: any, type: string) => {
  return `${moment(value).local().format(`DD${type}MM${type}YYYY`)}`;
};

export const FormatDate = (value: any, isNotHaveTime?: boolean) => {
  if (value === null || value === undefined || value === "0001-01-01T00:00:00")
    return "";
  var date = convertUTCDateToLocalDate(value);
  if (isNotHaveTime ?? false) return `${moment(date).format("YYYY-MM-DD")}`;
  return `${moment(date).format("YYYY-MM-DD")} ${moment(date).format(
    "HH:mm:ss"
  )}`;
};

const convertUTCDateToLocalDate = (date: any) => {
  return new Date(
    date.charAt(date.length - 1) === "Z" ? date : date + "Z"
  );
};
