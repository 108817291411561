import { GetListUserScoreType } from "./actionTypes";
import { IGetListUserScore, IGetListUserScoreFetching, IGetListUserScoreSuccess, IGetListUserScoreError, IGetListFiterUserScore, IGetListFiterDebounceUserScore } from "./interfaces";


export const getListUserScore = (payload:Object): IGetListUserScore => ({
    type: GetListUserScoreType.GET_LIST_USER_SCORE,
    payload
})

export const getListUserScoreFetching = (isFetching:boolean) : IGetListUserScoreFetching => ({
    type: GetListUserScoreType.GET_LIST_USER_SCORE_FETCHING,
    isFetching
})

export const getListUserScoreSuccess = (data:any) : IGetListUserScoreSuccess => ({
   type: GetListUserScoreType.GET_LIST_USER_SCORE_SUCCESS,
   data
})

export const getListUserScoreError = (errorMsg:any) : IGetListUserScoreError => ({
    type: GetListUserScoreType.GET_LIST_USER_SCORE_FAILD,
    error:errorMsg
})

export const setFilterUserScore = (payload: any): IGetListFiterUserScore => ({
    type: GetListUserScoreType.GET_LIST_FILTER_USER_SCORE,
    payload
})

export const setFilterDebounceUserScore = (payload: any): IGetListFiterDebounceUserScore => ({
    type: GetListUserScoreType.GET_LIST_FILTER_DEBOUNCE_USER_SCORE,
    payload 
})