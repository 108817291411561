import { GetAnnouncementsType } from './actionTypes';
import { IFetchingGetAnnouncements, IGetAnnouncements, IGetAnnouncementsSuccess, IGetAnnouncementsError, IGetListFilterAnnouncements, IGetListFilterDebounceAnnouncements } from './interfaces';

export const getAnnouncementsIsFetching = (isFetching:boolean) : IFetchingGetAnnouncements => ({
    type: GetAnnouncementsType.GET_ANNOUNCEMENTS_FETCHING,
    isFetching
})

export const getAnnouncements = (payload: Object) : IGetAnnouncements => ({
    type: GetAnnouncementsType.GET_ANNOUNCEMENTS,
    payload
})

export const getAnnouncementsSuccess = (data:any) : IGetAnnouncementsSuccess => ({
    type: GetAnnouncementsType.GET_ANNOUNCEMENTS_SUCCESS,
    data
})

export const getAnnouncementsFaild = (error:any) : IGetAnnouncementsError => ({
    type: GetAnnouncementsType.GET_ANNOUNCEMENTS_ERROR,
    error
})

export const setFilterAnnouncements = (payload: any): IGetListFilterAnnouncements => ({
    type: GetAnnouncementsType.SET_LIST_FILTER_ANNOUNCEMENTS,
    payload
})

export const setFilterDebounceAnnouncements = (payload: any): IGetListFilterDebounceAnnouncements => ({
    type: GetAnnouncementsType.SET_LIST_FILTER_DEBOUNCE_ANNOUNCEMENTS,
    payload 
})