import { AnyAction, Reducer } from 'redux';
import { IAgentGetAllWalletsState, initialAgentGetAllWalletsState } from './state';
import { AgentGetAllWalletsTypes } from 'src/store/actions/agentGetAllWallets';

export const AgentGetAllWalletsReducer: Reducer = (
  state: IAgentGetAllWalletsState = initialAgentGetAllWalletsState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
