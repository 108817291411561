import { RemoveUserBankAccAgentType } from './actionTypes';
import { 
    IFetchingRemoveUserBankAccAgent,
    IRemoveBankAccAgentError,
    IRemoveUserBankAccAgent,
    IRemoveUserBankAccAgentSuccess
 } from './interfaces';


export const removeUserBankAccAgentIsFetching = (isFetching:boolean) : IFetchingRemoveUserBankAccAgent => ({
    type:RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT_FETCHING,
    isFetching
})

export const removeUserBankAccAgent = (payload?:any, getListRequest?:any) : IRemoveUserBankAccAgent => ({
    type:RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT,
    payload,
    getListRequest
})

export const removeUserBankAccAgentSuccess = (data:any) : IRemoveUserBankAccAgentSuccess => ({
    type:RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT_SUCCESS,
    data
})

export const removeUserBankAccAgentFaild = (error:any) : IRemoveBankAccAgentError => ({
    type:RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT_ERROR,
    error
})