import { AnyAction, Reducer } from 'redux';
import { UpdateAnnouncementsCategoryType } from 'src/store/actions';
import { IUpdateListAnnouncementsCategoryState, initialUpdateListAnnouncementsCategoryState } from './state';


export const UpdateListAnnouncementsCategoryReducer: Reducer = (
  state: IUpdateListAnnouncementsCategoryState = initialUpdateListAnnouncementsCategoryState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case UpdateAnnouncementsCategoryType.UPDATE_ANNOUNCEMENTS_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        success: true
      }
    }
    default:
      return state;
  }
};