import { AnyAction, Reducer } from 'redux';
import {
  IListAllWalletAdminBOState,
  initialListAllWalletAdminBOState,
} from './state';
import { GetAllWalletAdminBOType } from '../../../actions/WalletAdminBO/getWalletAdminBO';

export const GetListAllWalletAdminBOReducer: Reducer = (
  state: IListAllWalletAdminBOState = initialListAllWalletAdminBOState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAllWalletAdminBOType.GET_ALL_WALLET_ADMIN_BO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAllWalletAdminBOType.FILTER_WALLET_ADMIN_BO: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
