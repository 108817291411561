import { debounce, put, takeLatest } from 'redux-saga/effects';
import { handleGetScoreHistory } from 'src/apis/service/P2PExchange';
import { getScoreHistoryFaild, getScoreHistoryIsFetching, getScoreHistorySuccess, GetScoreHistoryType, setFilterUserScore } from 'src/store/actions';

function* getScoreHistory(action:any):Generator<any>{
   yield put(getScoreHistoryIsFetching(true))
   const { payload } = action;
    try {
      const dataScoreHistory:any = yield handleGetScoreHistory(payload)
      if (dataScoreHistory.status === 200) {
        yield put(getScoreHistorySuccess(dataScoreHistory))
      }  
    } catch (error) {
        yield put(getScoreHistoryFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterUserScore(action.payload));
}

export function* watchGetScoreHistory () {
  yield takeLatest(GetScoreHistoryType.GET_SCORE_HISTORY, getScoreHistory);
  yield debounce(500, GetScoreHistoryType.SET_LIST_FILTER_DEBOUNCE_SCORE_HISTORY, handleSearchDebounce)
}