import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetAllScorePeriodConfig } from 'src/apis/service/ScorePeriodConfig';
import { GetAllScorePeriodConfigTypes } from '../../actions/getAllScorePeriodConfig';
import { getAllScorePeriodConfigError, getAllScorePeriodConfigFetching, getAllScorePeriodConfigFilter, getAllScorePeriodConfigSuccess } from '../../actions/getAllScorePeriodConfig/action';

function* getAllScorePeriodConfigSaga():Generator<any> {
    yield put(getAllScorePeriodConfigFetching(true))
    try {
      const data:any = yield handleGetAllScorePeriodConfig()
      if(data && data?.status === 200){
        yield put(getAllScorePeriodConfigSuccess(data))
      } else {
        yield put(getAllScorePeriodConfigError('error'))
      }
    } catch (error) {
        yield put(getAllScorePeriodConfigError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAllScorePeriodConfigFilter(action.payload));
}
export function* watchGetAllScorePeriodConfig() {
   yield takeEvery(GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG, getAllScorePeriodConfigSaga)
   yield debounce(500,GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_FILTER_DEBOUNCE,handleSearchDebounce)
}
