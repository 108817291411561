import { GetAgentRankTypes } from './actionTypes';
import {
    IFilterAgentRank,
    IFilterDebounceAgentRank,
    IGetAgentRank,
    IGetAgentRankFaild,
    IGetAgentRankFetching,
    IGetAgentRankSuccess
} from './interfaces';

export const getAgentRankAtFetching = (isFetching:boolean) : IGetAgentRankFetching  => ({
    type: GetAgentRankTypes.GET_AGENT_RANK_FETCHING,
    isFetching
})

export const getAgentRank = (payload:Object) : IGetAgentRank => ({
    type: GetAgentRankTypes.GET_AGENT_RANK,
    payload
})

export const getAgentRankSuccess = (data:any) : IGetAgentRankSuccess => ({
    type: GetAgentRankTypes.GET_AGENT_RANK_SUCCESS,
    data
})

export const getAgentRankFaild = (error:any) : IGetAgentRankFaild => ({
    type: GetAgentRankTypes.GET_AGENT_RANK_FAILD,
    error
})

export const setFilterAgentRank = (payload: any): IFilterAgentRank => ({
    type: GetAgentRankTypes.FILTER_AGENT_RANK,
    payload
})

export const setFilterDebounceAgentRank = (payload: any): IFilterDebounceAgentRank => ({
    type: GetAgentRankTypes.FILTER_DEBOUNCE_AGENT_RANK,
    payload 
})