
// get transaction
import { ParamsKycByStageDefault } from "src/contants/paramsDefault";

interface IDataItemKycListByStage {
  data:{
      items:any[];
      totalCount:number;
  };
  status:number;
  message:string;
}

export interface IListKycListByStageState {
    data: IDataItemKycListByStage;
    isFetching: boolean,
    filter: Object,
    pagination: any,
}

export const initialListKycListByStageState: IListKycListByStageState = {
    data:{
        data:{
            items:[],
            totalCount: 0
        },
        message: '',
        status: 0
    },
    isFetching:false,
    filter: ParamsKycByStageDefault,
    pagination: {
        totalCount: 0,
    }
}

