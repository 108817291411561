import {put, takeEvery, select} from 'redux-saga/effects';
import {EditNotificationType} from '../../actions/editNotification/actionTypes';
import {editNotificationFetching, editNotificationSuccess, editNotificationFaild} from '../../actions/editNotification/action';
import {getNotifications} from 'src/store/actions/getNotifications/action';
import {handleEditNotification} from '../../../apis/service/Notifications';
import {IApplicationState} from 'src/store/IApplicationState';
import { toastError } from 'src/helpers/toastHelper';
import { toastSuccess } from '../../../helpers/toastHelper';

function* EditNotificationSaga(action:any):Generator<any>{


  yield put(editNotificationFetching(true))
  const { payload, history } = action;
  try {
    const data: any = yield handleEditNotification(payload)  
    if (data?.status === 200) {
        // const params: any = yield select((state:IApplicationState) => state.listNotification.filter)
        // yield put(history.push('/dashboard/feature-notifications'))
        toastSuccess('bo_feature_notification_updated');
        yield put(editNotificationSuccess(data))
        // yield put(getNotifications(params))
    } else {
       data?.errors.forEach((err:any) => toastError(err))
      //  yield put(history.push('/dashboard/feature-notifications'))
    }
  } catch (error) {
     yield put(editNotificationFaild(error)) 
  }
}
export function* watchEditNotification(){
    yield takeEvery(EditNotificationType.EDIT_NOTIFICATION, EditNotificationSaga)
}