import {call, debounce, put, takeEvery} from 'redux-saga/effects';
import { handleOfferSearch } from 'src/apis/service/P2PTransaction';
import { getOfferTransactionAtFetching, getOfferTransactionFaild, getOfferTransactionSuccess, GetOfferTransactionTypes, setFilterOfferTransaction } from 'src/store/actions';

function* getOfferTransactionSaga(action:any):Generator<any> {
    yield put(getOfferTransactionAtFetching(true))
    const {payload} = action;
    try {
      const dataOfferTransaction:any = yield call(handleOfferSearch, payload)
      if(dataOfferTransaction && dataOfferTransaction?.status){
        yield put(getOfferTransactionSuccess(dataOfferTransaction))
      } else {
        yield put(getOfferTransactionFaild('error'))
      }
    } catch (error) {
        yield put(getOfferTransactionFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterOfferTransaction(action.payload));
}

export function* watchGetListOfferTransaction() {
  yield takeEvery(GetOfferTransactionTypes.GET_OFFER_TRANSACTION, getOfferTransactionSaga);
  yield debounce(500, GetOfferTransactionTypes.GET_LIST_FILTER_DEBOUNCE_OFFER_TRANSACTION, handleSearchDebounce)
}