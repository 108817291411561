import { AnyAction, Reducer } from 'redux';
import { IAgentSubmitBankState, initialAgentSubmitBankState } from './state';
import { AgentSubmitBankTypes } from 'src/store/actions/agentSubmitBank';

export const AgentSubmitBankReducer: Reducer = (
  state: IAgentSubmitBankState = initialAgentSubmitBankState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentSubmitBankTypes.AGENT_SUBMIT_BANK: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentSubmitBankTypes.AGENT_SUBMIT_BANK_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AgentSubmitBankTypes.AGENT_SUBMIT_BANK_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentSubmitBankTypes.AGENT_SUBMIT_BANK_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
