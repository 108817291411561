import { debounce, put, takeEvery } from 'redux-saga/effects';
import { GetAdminTransfersTypes } from '../../actions/getAdminTransfers';
import { getAdminTransfersError, getAdminTransfersFetching, getAdminTransfersFilter, getAdminTransfersSuccess } from '../../actions/getAdminTransfers/action';
import { getAdminTransfers } from 'src/apis/service/Direct/AdminTransfers';

function* getAdminTransfersSaga(action:any):Generator<any> {
    yield put(getAdminTransfersFetching(true))
    const {payload} = action;
    try {
      const data:any = yield getAdminTransfers(payload)
      if(data && data?.status === 200){
        yield put(getAdminTransfersSuccess(data))
      } else {
        yield put(getAdminTransfersError('error'))
      }
    } catch (error) {
        yield put(getAdminTransfersError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAdminTransfersFilter(action.payload));
}
export function* watchGetAdminTransfers() {
   yield takeEvery(GetAdminTransfersTypes.GET_ADMIN_TRANSFERS, getAdminTransfersSaga)
   yield debounce(500,GetAdminTransfersTypes.GET_ADMIN_TRANSFERS_FILTER_DEBOUNCE,handleSearchDebounce)
}
