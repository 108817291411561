import { AnyAction, Reducer } from 'redux';
import { ISelectWalletTypeState, initialSelectWalletTypeState } from './state';
import { SelectWalletTypeTypes } from 'src/store/actions/selectWalletType';

export const SelectWalletTypeReducer: Reducer = (
  state: ISelectWalletTypeState = initialSelectWalletTypeState,
  action: AnyAction
) => {
  switch (action.type) {
    case SelectWalletTypeTypes.SELECT_WALLET_TYPE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SelectWalletTypeTypes.SELECT_WALLET_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    }
    case SelectWalletTypeTypes.SELECT_WALLET_TYPE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case SelectWalletTypeTypes.SELECT_WALLET_TYPE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
