import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetCryptoExchangeOffersListTypes } from '../../actions/getCryptoExchangeOffersList';
import { getCryptoExchangeOffersListFetching, getCryptoExchangeOffersListSuccess, getCryptoExchangeOffersListError, getCryptoExchangeOffersListFilter } from '../../actions/getCryptoExchangeOffersList/action';
import { handleGetCryptoExchangeOffersList } from 'src/apis/service/Agent';

function* getCryptoExchangeOffersListSaga(action: any): Generator<any> {
  yield put(getCryptoExchangeOffersListFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleGetCryptoExchangeOffersList(payload)
    if (data && data?.status === 200) {
      yield put(getCryptoExchangeOffersListSuccess(data))
    } else {
      yield put(getCryptoExchangeOffersListError('error'))
    }
  } catch (error) {
    yield put(getCryptoExchangeOffersListError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getCryptoExchangeOffersListFilter(action.payload));
}
export function* watchGetCryptoExchangeOffersList() {
  yield takeEvery(GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST, getCryptoExchangeOffersListSaga)
  yield debounce(500, GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE, handleSearchDebounce)
}
