import { EditNotificationType } from './actionTypes';
import { IEditNotification, IFetchingEditNotification, IEditNotificationSuccess, IEditNotificationError } from './interfaces';
import { History } from 'history';

export const editNotificationFetching = (isFetching:boolean) : IFetchingEditNotification => ({
    type:EditNotificationType.EDIT_NOTIFICATION_FETCHING,
    isFetching
})

export const editNotification = (payload:Object, history: History) : IEditNotification => ({
    type:EditNotificationType.EDIT_NOTIFICATION,
    payload,
    history
})

export const editNotificationSuccess = (data:any) : IEditNotificationSuccess => ({
    type:EditNotificationType.EDIT_NOTIFICATION_SUCCESS,
    data
})

export const editNotificationFaild = (error:any) : IEditNotificationError => ({
    type:EditNotificationType.EDIT_NOTIFICATION_ERROR,
    error
})