import { CreateWalletAdminBOType } from './actionTypes';
import {
    ICreateWalletAdminBO,
    ICreateWalletAdminBOError,
    ICreateWalletAdminBOSuccess,
    IFetchingCreateWalletAdminBO
} from './interfaces';


export const createWalletAdminIsFetching = (isFetching: boolean): IFetchingCreateWalletAdminBO => ({
    type: CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO_IS_FETCHING,
    isFetching
})

export const createWalletAdmin = (payload: Object, history?: any): ICreateWalletAdminBO => ({
    type: CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO,
    payload,
    history
})

export const createWalletAdminSuccess = (data: any): ICreateWalletAdminBOSuccess => ({
    type: CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO_SUCCESS,
    data
})

export const createWalletAdminFaild = (error: any): ICreateWalletAdminBOError => ({
    type: CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO_FAILD,
    error
})