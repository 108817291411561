import { GetKYCStatusTypes } from './actionTypes';
import { IGetKYCStatus, IGetKYCStatusFetching, IGetKYCStatusSuccess, IGetKYCStatusError, IGetKYCStatusFilter, IGetKYCStatusFiterDebounce } from "./interfaces";


export const getKYCStatus = (): IGetKYCStatus => ({
    type: GetKYCStatusTypes.GETKYC_STATUS,
})

export const getKYCStatusFetching = (isFetching: boolean): IGetKYCStatusFetching => ({
    type: GetKYCStatusTypes.GETKYC_STATUS_FETCHING,
    isFetching
})

export const getKYCStatusSuccess = (data: any): IGetKYCStatusSuccess => ({
    type: GetKYCStatusTypes.GETKYC_STATUS_SUCCESS,
    data
})

export const getKYCStatusError = (errorMsg: any): IGetKYCStatusError => ({
    type: GetKYCStatusTypes.GETKYC_STATUS_FAILD,
    error: errorMsg
})

export const getKYCStatusFilter = (payload: any): IGetKYCStatusFilter => ({
    type: GetKYCStatusTypes.GETKYC_STATUS_FILTER,
    payload
})

export const getKYCStatusFilterDebounce = (payload: any): IGetKYCStatusFiterDebounce => ({
    type: GetKYCStatusTypes.GETKYC_STATUS_FILTER_DEBOUNCE,
    payload
})
