import { AnyAction, Reducer } from 'redux';
import { ICreateCryptoExchangeOffersListState, initialCreateCryptoExchangeOffersListState } from './state';
import { CreateCryptoExchangeOffersListTypes } from 'src/store/actions/createCryptoExchangeOffersList';

export const CreateCryptoExchangeOffersListReducer: Reducer = (
  state: ICreateCryptoExchangeOffersListState = initialCreateCryptoExchangeOffersListState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action?.data?.data?.totalCount,
        countSuccess: state.countSuccess + 1
      };
    }
    case CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
