import { AnyAction, Reducer } from 'redux';
import { GetTransactionByOfferTypes } from 'src/store/actions/TransactionByOffer';
import {
  IListTransactionByOfferState,
  initialListTransactionByOfferState
} from './state';

export const GetListTransactionByOfferReducer: Reducer = (
  state: IListTransactionByOfferState = initialListTransactionByOfferState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case GetTransactionByOfferTypes.GET_TRANSACTION_BY_OFFER_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetTransactionByOfferTypes.SET_LIST_TRANSACTION_BY_OFFER: {
      return {
        ...state,
        filter: action.payload
      };
    }
    default:
      return state;
  }
};
