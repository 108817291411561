import { DeleteAgentRankType } from './actionTypes';
import {
    IDeleteAgentRank,
    IDeleteAgentRankFaild,
    IDeleteAgentRankFetching,
    IDeleteAgentRankSuccess
} from './interfaces';

export const deleteAgentRankAtFetching = (isFetching: boolean): IDeleteAgentRankFetching => ({
    type: DeleteAgentRankType.DELETE_AGENT_RANK_FETCHING,
    isFetching
})

export const deleteAgentRank = (payload: any): IDeleteAgentRank => ({
    type: DeleteAgentRankType.DELETE_AGENT_RANK,
    payload
})

export const deleteAgentRankSuccess = (data: any): IDeleteAgentRankSuccess => ({
    type: DeleteAgentRankType.DELETE_AGENT_RANK_SUCCESS,
    data
})

export const deleteAgentRankFaild = (error: any): IDeleteAgentRankFaild => ({
    type: DeleteAgentRankType.DELETE_AGENT_RANK_FAILD,
    error
})
