import { put, select, takeEvery } from 'redux-saga/effects';
import {
  handleGetP2PExchangeOfferBlock,
  handleGetP2PExchangeOfferUnBlock
} from 'src/apis/service/P2PExchange';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getP2PExchangeOffers } from 'src/store/actions';
import {
  BlockAndUnblockP2PExchangeOffer,
  blockAndUnBlockP2PExchangeOfferAtFetching,
  blockAndUnBlockP2PExchangeOfferFaild,
  blockAndUnBlockP2PExchangeOfferSuccess
} from 'src/store/actions/blockOrUnBlockAndCancelP2PExchangeOffer/blockOrUnBlock';
import { getP2PExchangeOffersAgent } from 'src/store/actions/P2PExchangeOffersAgent';
import { IApplicationState } from 'src/store/IApplicationState';

function* blockOrUnBlockP2PExchangeOffer(action: any): Generator<any> {


  yield put(blockAndUnBlockP2PExchangeOfferAtFetching(true))
  const { payload, isBlock } = action;
  try {
    const res: any = isBlock ? yield handleGetP2PExchangeOfferBlock(payload) : yield handleGetP2PExchangeOfferUnBlock(payload)
    if (res && res.status === 200) {
      //  const filterAgent : any = yield select((state:IApplicationState) => state.p2pExchangeOfferAgent.filter)
      //  const filter : any = yield select((state:IApplicationState) => state.listP2PExchangeOffers.filter)
      if (isBlock) toastSuccess('bo_block_offer_success_label')
      if (!isBlock) toastSuccess('bo_unblock_offer_success_label')

      yield put(blockAndUnBlockP2PExchangeOfferSuccess(res.data))
      //  yield put(getP2PExchangeOffersAgent(filterAgent))
      //  yield put(getP2PExchangeOffers(filter))
    } else {
      toastError('bo_block_faild')
      yield put(blockAndUnBlockP2PExchangeOfferFaild('Block faild'))
    }
  } catch (error) {
    toastError('bo_block_faild')
    yield put(blockAndUnBlockP2PExchangeOfferFaild('Block faild'))
  }
}

export function* watchBlockOrUnBlockP2PExchangeOfferSaga() {
  yield takeEvery(BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER, blockOrUnBlockP2PExchangeOffer)
}