export interface UpdatePriority  {
    status: number,
    data: boolean,
    message: string
}

export interface UpdatePriorityState {
    data: UpdatePriority,
    isFetching:boolean
}

export const initialUpdatePriorityState : UpdatePriorityState = {
    data:{
       data:false,
       message:'',
       status:0
    },
    isFetching:false
}