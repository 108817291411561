export const STATUS_TRANSACTION_OPTIONS = [
  {
      id: 1,
      label: 'bo_transaction_status_waiting_for_bank_details',
      value: 'tran_waiting_bankdetails',
      sqlValue: 'WAITING_BANK_DETAILS'
  },
  {
      id: 2,
      label: 'bo_transaction_status_waiting_for_transfer',
      value: 'tran_pending',
      sqlValue: 'WAITING_TRANSFER'
  },
  {
      id: 3,
      label: 'bo_pending_transaction',
      value: 'pending_transaction',
      sqlValue: 'PENDING'
  },
  {
      id: 4,
      label: 'bo_transaction_status_completed',
      value: 'tran_completed',
      sqlValue: 'APPROVED'
  },
  {
      id: 5,
      label: 'bo_status_canceled',
      value: 'tran_canceled',
      sqlValue: 'CANCELED'
  },
  {
      id: 6,
      label: 'bo_transaction_status_timeout',
      value: 'tran_expired',
      sqlValue: 'EXPIRED'
  },
  {
      id:7,
      label: 'bo_money_transferred',
      value: 'tran_transferred',
      sqlValue: 'TRANSFERRED'
  }
]