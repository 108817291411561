import { AnyAction, Reducer } from 'redux';
import { IOfferTransactionState, initialOfferTransactionState } from './state';
import { GetOfferTransactionTypes } from '../../actions/getOfferTransaction/actionTypes';


export const GetOfferTransactionReducer: Reducer = (
  state: IOfferTransactionState = initialOfferTransactionState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetOfferTransactionTypes.GET_OFFER_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetOfferTransactionTypes.GET_OFFER_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetOfferTransactionTypes.GET_OFFER_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case GetOfferTransactionTypes.GET_LIST_FILTER_OFFER_TRANSACTION: {
      return {
          ...state,
          filter: action.payload
      }
    }
      default:
        return state;
    }
};