import {GetCryptoTransactionFeeTypes} from './actionTypes';
import { IGetCryptoTransactionFee, IGetCryptoTransactionFeeFetching, IGetCryptoTransactionFeeSuccess, IGetCryptoTransactionFeeError, IGetCryptoTransactionFeeFilter, IGetCryptoTransactionFeeFiterDebounce } from "./interfaces";


export const getCryptoTransactionFee = (payload:Object): IGetCryptoTransactionFee => ({
    type: GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE,
    payload
})

export const getCryptoTransactionFeeFetching = (isFetching:boolean) : IGetCryptoTransactionFeeFetching => ({
    type: GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE_FETCHING,
    isFetching
})

export const getCryptoTransactionFeeSuccess = (data:any) : IGetCryptoTransactionFeeSuccess => ({
   type: GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE_SUCCESS,
   data
})

export const getCryptoTransactionFeeError = (errorMsg:any) : IGetCryptoTransactionFeeError => ({
    type: GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE_FAILD,
    error:errorMsg
})

export const getCryptoTransactionFeeFilter = (payload: any): IGetCryptoTransactionFeeFilter => ({
    type: GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE_FILTER,
    payload
})

export const getCryptoTransactionFeeFilterDebounce = (payload: any): IGetCryptoTransactionFeeFiterDebounce => ({
    type: GetCryptoTransactionFeeTypes.GET_CRYPTO_TRANSACTION_FEE_FILTER_DEBOUNCE,
    payload 
})
