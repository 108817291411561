import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleDeleteOfferByAgent } from 'src/apis/service/Offer';
import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import {
  getOfferSellTransaction,
  getOfferTransaction,
} from 'src/store/actions';
import { DeleteOfferByAgentTypes } from '../../actions/deleteOfferByAgent';
import {
  deleteOfferByAgentError,
  deleteOfferByAgentFetching,
  deleteOfferByAgentFilter,
} from '../../actions/deleteOfferByAgent/action';

function* deleteOfferByAgentSaga(action: any): Generator<any> {
  yield put(deleteOfferByAgentFetching(true));
  const { payload, isP2PBuy, filters } = action;
  try {
    const data: any = yield handleDeleteOfferByAgent(payload);
    if (data && data?.status === 200) {
      if (isP2PBuy) {
        yield put(getOfferTransaction(filters));
      } else {
        yield put(getOfferSellTransaction(filters));
      }
      toastSuccess('bo_delete_offer_success');
    } else {
      toastError('bo_you_can_t_delete_this_offer');
      yield put(deleteOfferByAgentError('error'));
    }
  } catch (error) {
    yield put(deleteOfferByAgentError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(deleteOfferByAgentFilter(action.payload));
}
export function* watchDeleteOfferByAgent() {
  yield takeEvery(
    DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT,
    deleteOfferByAgentSaga
  );
  yield debounce(
    500,
    DeleteOfferByAgentTypes.DELETE_OFFER_BY_AGENT_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
