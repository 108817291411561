import moment from 'moment-timezone'

export function formatDate(date: any): string {
  const stillUtc = moment.utc(date).local().format('YYYY-MM-DD HH:mm')
  return stillUtc
}

export function formatDateTimeFull(date: any): string {
  if (typeof date === 'number') {
    date *= 1000
  }
  const stillUtc = moment.utc(date).local().format('YYYY-MM-DD HH:mm')
  return stillUtc
}

export function formatDateTime(date: number | string) {
  if(Boolean(date)) {
    return `${moment(date).format('MM/DD/YYYY')} | ${moment(date).format('HH:mm')}`
  }
}

export function formatDateYMD(date: any) {
  if(Boolean(date)) {
    return `${moment(date).utc().local().format('YYYY-MM-DD')}`
  }
}

export function dateToTimeStamp(strDate: string) {
  const datum = Date.parse(strDate);
  return datum/1000;
}

export function localDate(date:number | string) {
  var local_date= moment.utc(date).local().format('YYYY-MM-DD HH:mm');
  return local_date
}