import { AnyAction, Reducer } from 'redux';
import { GetNotificationsType } from 'src/store/actions';
import { IListNotificationState, initialListNotificationState } from './state';


export const GetListNotificationReducer: Reducer = (
  state: IListNotificationState = initialListNotificationState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetNotificationsType.GET_NOTIFICATIONS : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetNotificationsType.GET_NOTIFICATIONS_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case GetNotificationsType.GET_NOTIFICATIONS_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
          pagination: action.data.data.totalCount,
      }
    }
    case GetNotificationsType.GET_NOTIFICATION_SEARCH: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default:
      return state;
  }
};