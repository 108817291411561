import { ResetPinType } from './actionTypes';
import { IFetchingResetPin,IResetPin, IResetPinError, IResetPinSuccess } from './interfaces';


export const resetPinIsFetching = (isFetching:boolean) : IFetchingResetPin=> ({
    type: ResetPinType.RESET_PIN_FETCHING,
    isFetching
})

export const resetPin = (payload:Object) : IResetPin => ({
    type: ResetPinType.RESET_PIN,
    payload
})

export const resetPinSuccess = (data:any) : IResetPinSuccess => ({
    type: ResetPinType.RESET_PIN_SUCCESS,
    data
})

export const resetPinFaild = (error:any) : IResetPinError => ({
    type: ResetPinType.RESET_PIN_ERROR,
    error
})