import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
} from 'axios';
import Cookies from 'js-cookie';
import * as CONSTANT from '../../contants';
import { UserRole } from '../../models/roles';
import roleTableService from '../../utils/roleTableService';
import {
  clearStorageItems,
  getStorageItem,
  setStorageItem,
} from '../../utils/storage';
import { handleLogout } from '../service/logout';
import { mapData, mapError } from './mapData';
import { routerConst } from 'src/routers/routerConstants';
const { CancelToken } = axios;

interface PromiseWithCancel<R> extends Promise<R> {
  cancel?: () => void;
}
export default class Request {
  api: AxiosInstance;
  constructor(props: any) {
    this.api = axios.create({
      baseURL: props?.url,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.api.interceptors.request.use((config) => {
      if (config && config.headers) {
        const token = getStorageItem(CONSTANT.ACCESS_TOKEN);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    });
    this.api.interceptors.response.use(
      (response) =>
        // Do something with response data
        response,
      async (error) => {
        const { response } = error;
        // Do something with response error
        if (axios.isCancel(error)) {
          console.debug('Request canceled', error.message);
        }
        if (response?.status === 401) {
          try {
            const isAgent = roleTableService([
              UserRole.Agent,
              UserRole.SuperAgent,
            ]);
            const pathName = window.location.pathname;
            const timeRefresh = getStorageItem(CONSTANT.REFRESH_TIME);
            var isRefreshed = false;
            if (timeRefresh != null) {
              let date = new Date(timeRefresh);
              if (date.getTime() >= new Date().getTime()) {
                isRefreshed = true;
              }
            }
            if (!isRefreshed) {
              const user = getStorageItem('user');
              const userPhone = user ? JSON.parse(user).phoneNumber : null;
              if (isAgent) {
                const firebaseToken: any = getStorageItem('firebase-token');
                var payload = {
                  fireBaseToken: firebaseToken,
                  isMobile: false,
                };
                await handleLogout(payload);
              }
              clearStorageItems();
              Cookies.remove('token');
              var refreshTime = new Date();
              refreshTime.setMinutes(refreshTime.getMinutes() + 5);
              setStorageItem(
                CONSTANT.REFRESH_TIME,
                refreshTime.toLocaleString() + ''
              );
              if (response?.data?.message === 'user.locked.enter.wrong.pin') {
                setStorageItem(
                  CONSTANT.USER_PHONE,
                  'bo_your_account_has_been_locked_please_contact_support'
                );
              } else {
                setStorageItem(CONSTANT.USER_PHONE, '+' + userPhone);
              }

              if (pathName !== routerConst.Login) {
                window.location.href = routerConst.Login;
              }
            }
          } catch (error) {}
        }
        return window.Promise.reject(error);
      }
    );
  }

  setToken = (token: string) => {
    this.api.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  get = <T = any, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig = {}
  ): PromiseWithCancel<R> => {
    let cancel: Canceler;
    const apiConfig = {
      params: {
        ...config.params,
      },
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };
    const request: PromiseWithCancel<R> = this.api
      .get(url, apiConfig)
      .then(mapData)
      .catch(mapError);

    request.cancel = () => cancel();
    return request;
  };

  post = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {}
  ) => {
    let cancel: Canceler;
    const apiConfig = {
      params: {
        ...config.params,
      },
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };
    const request: PromiseWithCancel<R> = this.api
      .post(url, body, apiConfig)
      .then(mapData)
      .catch(mapError);
    request.cancel = () => cancel();
    return request;
  };

  put = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {}
  ) => {
    let cancel: Canceler;
    const apiConfig = {
      params: {
        ...config.params,
      },
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };
    const request: PromiseWithCancel<R> = this.api
      .put(url, body, apiConfig)
      .then(mapData)
      .catch(mapError);
    request.cancel = () => cancel();
    return request;
  };

  patch = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {}
  ) => {
    let cancel: Canceler;
    const apiConfig = {
      params: {
        ...config.params,
      },
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };
    const request: PromiseWithCancel<R> = this.api
      .patch(url, body, apiConfig)
      .then(mapData)
      .catch(mapError);
    request.cancel = () => cancel();
    return request;
  };

  delete = <T = any, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig = {}
  ): PromiseWithCancel<R> => {
    let cancel: Canceler;
    const apiConfig = {
      params: {
        ...config.params,
      },
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };
    const request: PromiseWithCancel<R> = this.api
      .delete(url, apiConfig)
      .then(mapData)
      .catch(mapError);
    request.cancel = () => cancel();
    return request;
  };

  loginAgent = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {}
  ) => {
    this.api.defaults.headers.common.WP_UserAgent = `v1;D; ; ; `;
    let cancel: Canceler;
    const apiConfig = {
      params: {
        ...config.params,
      },
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };
    const request: PromiseWithCancel<R> = this.api
      .post(url, body, apiConfig)
      .then(mapData)
      .catch(mapError);
    request.cancel = () => cancel();
    return request;
  };

  postFile = <T = any, R = AxiosResponse<T>>(
    url: string,
    body?: any,
    config: AxiosRequestConfig = {}
  ) => {
    let cancel: Canceler;
    // this.api.defaults.headers.common['Content-Type'] = `multipart/form-data`;
    const apiConfig = {
      params: {
        ...config.params,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    };
    const request: PromiseWithCancel<R> = this.api
      .post(url, body, apiConfig)
      .then(mapData)
      .catch(mapError);
    request.cancel = () => cancel();
    return request;
  };
}
