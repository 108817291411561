import { PAGE_SIZE_DEFAULT } from './../../../contants/pageSize';
interface IDataListTransactionByOffer {
  data: {
      items: any[];
      totalCount: number;
  };
  status: number;
  message: string;
}

export interface IListTransactionByOfferState {
    data: IDataListTransactionByOffer;
    isFetching: boolean,
    filter: Object;
    pagination: any,
}

export const initialListTransactionByOfferState: IListTransactionByOfferState = {
    data: {
        data: {
            items: [],
            totalCount: 0
        },
        status: 0,
        message: ''
    },
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT
    },
    pagination: {
        totalCount: 0,
    },
    isFetching: false,
}

