import { AnyAction, Reducer } from 'redux';
import { IAgentTransactionConfirmState, initialAgentTransactionConfirmState } from './state';
import { AgentTransactionConfirmTypes } from 'src/store/actions/agentTransactionConfirm';

export const AgentTransactionConfirmReducer: Reducer = (
  state: IAgentTransactionConfirmState = initialAgentTransactionConfirmState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
