import { AnyAction, Reducer } from 'redux';
import { UpdateKycSettingType } from 'src/store/actions';
import { IListKycSettingUpdateState, initialListKycSettingUpdateState } from './state';

export const GetListKycSettingUpdateReducer: Reducer = (
  state: IListKycSettingUpdateState = initialListKycSettingUpdateState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UpdateKycSettingType.UPDATE_KYC_SETTING : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateKycSettingType.UPDATE_KYC_SETTING_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case UpdateKycSettingType.UPDATE_KYC_SETTING_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
          success: true
      }
    }
    case UpdateKycSettingType.UPDATE_KYC_SETTING_RESET : {
      return {
          ...state,
          success: false
      }
    }
    default:
      return state;
  }
};