import {GetListTransactionCryptoTypes} from './actionTypes';
import { IGetListTransactionCrypto, IGetListTransactionCryptoFetching, IGetListTransactionCryptoSuccess, IGetListTransactionCryptoError, IGetListTransactionCryptoFilter, IGetListTransactionCryptoFiterDebounce } from "./interfaces";


export const getListTransactionCrypto = (payload:Object): IGetListTransactionCrypto => ({
    type: GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO,
    payload
})

export const getListTransactionCryptoFetching = (isFetching:boolean) : IGetListTransactionCryptoFetching => ({
    type: GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_FETCHING,
    isFetching
})

export const getListTransactionCryptoSuccess = (data:any) : IGetListTransactionCryptoSuccess => ({
   type: GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_SUCCESS,
   data
})

export const getListTransactionCryptoError = (errorMsg:any) : IGetListTransactionCryptoError => ({
    type: GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_FAILD,
    error:errorMsg
})

export const getListTransactionCryptoFilter = (payload: any): IGetListTransactionCryptoFilter => ({
    type: GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_FILTER,
    payload
})

export const getListTransactionCryptoFilterDebounce = (payload: any): IGetListTransactionCryptoFiterDebounce => ({
    type: GetListTransactionCryptoTypes.GET_LIST_TRANSACTION_CRYPTO_FILTER_DEBOUNCE,
    payload 
})
