import {GetOfferHistoryStatusTypes} from './actionTypes';
import {
    IGetOfferHistoryStatus,
    IGetOfferHistoryStatusFaild,
    IGetOfferHistoryStatusFetching,
    IGetOfferHistoryStatusSuccess
} from './interfaces';

export const getOfferHistoryStatusIsFetching = (isFetching:boolean) : IGetOfferHistoryStatusFetching  => ({
    type: GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS_FETCHING,
    isFetching
})

export const getOfferHistoryStatus = (payload: number) : IGetOfferHistoryStatus => ({
    type: GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS,
    payload
})

export const getOfferHistoryStatusSuccess = (data: any) : IGetOfferHistoryStatusSuccess => ({
    type: GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS_SUCCESS,
    data
})

export const getOfferHistoryStatusFaild = (error: any) : IGetOfferHistoryStatusFaild => ({
    type: GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS_FAILD,
    error
})