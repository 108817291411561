import {put, takeEvery,debounce} from 'redux-saga/effects';
import {AdminGetInvoiceListTypes} from '../../actions/adminGetInvoiceList';
import {adminGetInvoiceListFetching,adminGetInvoiceListSuccess,adminGetInvoiceListError, adminGetInvoiceListFilter} from '../../actions/adminGetInvoiceList/action';
import { handleAdminGetInvoiceList } from 'src/apis/service/Invoice';

function* adminGetInvoiceListSaga(action:any):Generator<any> {
    yield put(adminGetInvoiceListFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleAdminGetInvoiceList(payload)
      if(data && data?.status === 200){
        yield put(adminGetInvoiceListSuccess(data))
      } else {
        yield put(adminGetInvoiceListError('error'))
      }
    } catch (error) {
        yield put(adminGetInvoiceListError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(adminGetInvoiceListFilter(action.payload));
}
export function* watchAdminGetInvoiceList() {
   yield takeEvery(AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST, adminGetInvoiceListSaga)
   yield debounce(500,AdminGetInvoiceListTypes.ADMIN_GET_INVOICE_LIST_FILTER_DEBOUNCE,handleSearchDebounce)
}
