import { AnyAction, Reducer } from 'redux';
import { IGetUnreadCountNotificationState, initialGetUnreadCountNotificationState } from './state';
import { GetUnreadCountNotificationTypes } from 'src/store/actions/getUnreadCountNotification';

export const GetUnreadCountNotificationReducer: Reducer = (
  state: IGetUnreadCountNotificationState = initialGetUnreadCountNotificationState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
