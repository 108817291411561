import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetListCryptoWalletTypes } from '../../actions/getListCryptoWallet';
import { getListCryptoWalletFetching, getListCryptoWalletSuccess, getListCryptoWalletError, getListCryptoWalletFilter } from '../../actions/getListCryptoWallet/action';
import { handleGetListCryptoWallet } from 'src/apis/service/Agent';

function* getListCryptoWalletSaga(): Generator<any> {
  yield put(getListCryptoWalletFetching(true))
  try {
    const data: any = yield handleGetListCryptoWallet()
    if (data && data?.status === 200) {
      yield put(getListCryptoWalletSuccess(data))
    } else {
      yield put(getListCryptoWalletError('error'))
    }
  } catch (error) {
    yield put(getListCryptoWalletError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListCryptoWalletFilter(action.payload));
}
export function* watchGetListCryptoWallet() {
  yield takeEvery(GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET, getListCryptoWalletSaga)
  yield debounce(500, GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_FILTER_DEBOUNCE, handleSearchDebounce)
}
