import { put, takeEvery, select } from 'redux-saga/effects';
import {
  handleGetP2PExchangeOfferCancel
} from 'src/apis/service/P2PExchange';
import {
  CancelP2PExchangeOffer,
  cancelP2PExchangeOfferSuccess,
  cancelP2PExchangeOfferFaild,
  cancelP2PExchangeOfferAtFetching
} from 'src/store/actions/blockOrUnBlockAndCancelP2PExchangeOffer/cancelP2PExchangeOffer';
import { toastSuccess , toastError } from 'src/helpers/toastHelper';
import { ListParams } from 'src/models/common';
import { IApplicationState } from 'src/store/IApplicationState';
import { getP2PExchangeOffers } from 'src/store/actions';
import { getP2PExchangeOffersAgent } from 'src/store/actions/P2PExchangeOffersAgent';

function* cancelP2PExchangeOffer (action:any) :  Generator<any>  {


    yield put(cancelP2PExchangeOfferAtFetching(true))
    const { offerId  } = action;
    try {
        const res : any  =  yield handleGetP2PExchangeOfferCancel(offerId)
        if (res && res.status === 200) {
          const filter : ListParams | unknown = yield select((state:IApplicationState) => state.listP2PExchangeOffers.filter)
          const filterAgent : ListParams | unknown = yield select((state:IApplicationState) => state.p2pExchangeOfferAgent.filter)
           toastSuccess('bo_cancel_offer_success')
           yield put(cancelP2PExchangeOfferSuccess(res.data))
            // @ts-ignore
            yield put(getP2PExchangeOffers(filter))
            // @ts-ignore
            yield put(getP2PExchangeOffersAgent(filterAgent))
        } else {
           toastError('bo_cancel_offer_failed')
           yield put(cancelP2PExchangeOfferFaild('Cancel offer faild'))
        }
    } catch (error) {
        toastError('bo_cancel_offer_failed')
        yield put(cancelP2PExchangeOfferFaild('Cancel offer faild'))
    }
}

export function* watchCancelP2PExchangeOfferSaga () {
    yield takeEvery(CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER, cancelP2PExchangeOffer)
}
