import { EditUserScoreType } from './actionTypes';
import { IEditUserScore, IEditUserScoreError, IEditUserScoreSuccess, IFetchingEditUserScore } from './interfaces';


export const editUserIsFetching = (isFetching:boolean) : IFetchingEditUserScore => ({
    type:EditUserScoreType.EDIT_USER_SCORE_FETCHING,
    isFetching
})

export const editUserScore = (payload:Object) : IEditUserScore => ({
    type:EditUserScoreType.EDIT_USER_SCORE,
    payload
})

export const editUserScoreSuccess = (data:any) : IEditUserScoreSuccess => ({
    type:EditUserScoreType.EDIT_USER_SCORE_SUCCESS,
    data
})

export const editUserScoreFaild = (error:any) : IEditUserScoreError => ({
    type:EditUserScoreType.EDIT_USER_SCORE_ERROR,
    error
})