import {UpdateOfferNoteTypes} from './actionTypes';
import { IUpdateOfferNote, IUpdateOfferNoteFetching, IUpdateOfferNoteSuccess, IUpdateOfferNoteError, IUpdateOfferNoteFilter, IUpdateOfferNoteFiterDebounce } from "./interfaces";


export const updateOfferNote = (payload:Object): IUpdateOfferNote => ({
    type: UpdateOfferNoteTypes.UPDATE_OFFER_NOTE,
    payload
})

export const updateOfferNoteFetching = (isFetching:boolean) : IUpdateOfferNoteFetching => ({
    type: UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_FETCHING,
    isFetching
})

export const updateOfferNoteSuccess = (data:any) : IUpdateOfferNoteSuccess => ({
   type: UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_SUCCESS,
   data
})

export const updateOfferNoteError = (errorMsg:any) : IUpdateOfferNoteError => ({
    type: UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_FAILD,
    error:errorMsg
})

export const updateOfferNoteFilter = (payload: any): IUpdateOfferNoteFilter => ({
    type: UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_FILTER,
    payload
})

export const updateOfferNoteFilterDebounce = (payload: any): IUpdateOfferNoteFiterDebounce => ({
    type: UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_FILTER_DEBOUNCE,
    payload 
})
