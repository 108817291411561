import {put, takeEvery,debounce} from 'redux-saga/effects';
import { getDirectTransfers } from 'src/apis/service/Direct/DirectTransfers';
import { toastError } from 'src/helpers/toastHelper';
import { getDirectTransfersAtFetching, getDirectTransfersFaild, getDirectTransfersSuccess, GetDirectTransfersTypes, setFilterDirectTransfers } from 'src/store/actions';

function* getDirectTransfersSaga(action:any):Generator<any> {
    yield put(getDirectTransfersAtFetching(true))
    const {payload} = action;
    try {
      const data: any = yield getDirectTransfers(payload)
      if(data.status === 200){
        yield put(getDirectTransfersSuccess(data))
      } else {
        toastError('bo_data_not_found')
      }
    } catch (error) {
        yield put(getDirectTransfersFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterDirectTransfers(action.payload));
}
export function* watchGetDirectTransfers() {
   yield takeEvery(GetDirectTransfersTypes.GET_DIRECT_TRANSFERS, getDirectTransfersSaga)
   yield debounce(500,GetDirectTransfersTypes.FILTER_DEBOUNCE_DIRECT_TRANSFERS, handleSearchDebounce)
}