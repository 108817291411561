import { AnyAction, Reducer } from 'redux';
import { initialDataListCurrencyState, IDataListCurrencyState } from './state';
import { GetAllCurrencyType } from '../../../actions/currency';

export const ListCurrencyReducer: Reducer = (
  state: IDataListCurrencyState = initialDataListCurrencyState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAllCurrencyType.GET_ALL_CURRENCY: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GetAllCurrencyType.GET_ALL_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetAllCurrencyType.GET_ALL_CURRENCY_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case GetAllCurrencyType.GET_LIST_FILTER_CURRENCY: {
      return {
        ...state,
        filter: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
