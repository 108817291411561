import {GetTransactionsFilterTypesTypes} from './actionTypes';
import { IGetTransactionsFilterTypes, IGetTransactionsFilterTypesFetching, IGetTransactionsFilterTypesSuccess, IGetTransactionsFilterTypesError, IGetTransactionsFilterTypesFilter, IGetTransactionsFilterTypesFiterDebounce } from "./interfaces";


export const getTransactionsFilterTypes = (): IGetTransactionsFilterTypes => ({
    type: GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES
})

export const getTransactionsFilterTypesFetching = (isFetching:boolean) : IGetTransactionsFilterTypesFetching => ({
    type: GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES_FETCHING,
    isFetching
})

export const getTransactionsFilterTypesSuccess = (data:any) : IGetTransactionsFilterTypesSuccess => ({
   type: GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES_SUCCESS,
   data
})

export const getTransactionsFilterTypesError = (errorMsg:any) : IGetTransactionsFilterTypesError => ({
    type: GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES_FAILD,
    error:errorMsg
})

export const getTransactionsFilterTypesFilter = (payload: any): IGetTransactionsFilterTypesFilter => ({
    type: GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES_FILTER,
    payload
})

export const getTransactionsFilterTypesFilterDebounce = (payload: any): IGetTransactionsFilterTypesFiterDebounce => ({
    type: GetTransactionsFilterTypesTypes.GET_TRANSACTIONS_FILTER_TYPES_FILTER_DEBOUNCE,
    payload 
})
