import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeHistoryTransaction } from 'src/apis/service/P2PExchange';
import { getP2PExchangeTransactionsHistoryFaild, getP2PExchangeTransactionsHistoryIsFetching, getP2PExchangeTransactionsHistorySuccess, GetP2PExchangeTransactionsHistoryType } from 'src/store/actions';

function* getListP2PExchangeTransactionsHistorySaga(action: any): Generator<any> {
    yield put(getP2PExchangeTransactionsHistoryIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeHistoryTransaction, payload)      
      if(data && data?.status === 200){
        yield put(getP2PExchangeTransactionsHistorySuccess(data));
      } else {
        yield put(getP2PExchangeTransactionsHistoryFaild('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeTransactionsHistoryFaild(error))
    }
}

export function* watchGetListP2PExchangeTransactionsHistory() {
   yield takeEvery(GetP2PExchangeTransactionsHistoryType.GET_P2P_EXCHANGE_TRANSACTIONS_HISTORY, getListP2PExchangeTransactionsHistorySaga)
}