import {AnyAction, Reducer} from 'redux';
import { UserState,initialDataBankAccountState } from './state';
import {GetDataBankAccount} from '../../actions';

export const GetDataBankAccountUserReducer: Reducer = (
  state: UserState = initialDataBankAccountState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetDataBankAccount.GET_DATA_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetDataBankAccount.GET_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetDataBankAccount.GET_DATA_BY_PAGE: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetDataBankAccount.GET_DATA_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};