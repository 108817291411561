import { ListParams } from "src/models/common";



export interface IDataListUserPriority {
    status: number;
    data: {
      items: any[];
      totalCount: number;
    };
    message: string;
}

export interface IDataListUserPriorityState {
   data: IDataListUserPriority,
   isFetching: boolean,
   filter: ListParams,
   pagination: any;
}

export const initialListUserPriorityState : IDataListUserPriorityState = {
    data:{
        data:{
            items:[],
            totalCount:0
        },
        status:0,
        message:''
    },
    filter:{
        pageSize:10,
        pageNumber:1
    },
    pagination: {
        totalCount:0
    },
    isFetching:false
}