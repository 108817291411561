export interface EditUserDetail  {
    data:any,
    errors:any,
    success:any
}

export interface EditUserDetailState {
    data:EditUserDetail,
    isFetching:boolean
}

export const initialEditUserDetailState: EditUserDetailState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}