import {GetListLanguageTextTypes} from './actionTypes';
import {
    IGetListLangText,
    IGetListLangTextFetching,
    IGetListLangTextSuccess,
    IGetListLangTextFaild,
    IGetListSearchDebounceLangText,
    IGetListFiterLanguageText
} from './interfaces';

export const getListLangTextAtFetching = (isFetching:boolean) : IGetListLangTextFetching  => ({
    type: GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT_FETCHING,
    isFetching
})

export const getListLangText = (payload:Object) : IGetListLangText => ({
    type: GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT,
    payload
})

export const getListLangTextSuccess = (data:any) : IGetListLangTextSuccess => ({
    type: GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT_SUCCESS,
    data
})

export const setFilterLangText = (payload: any): IGetListFiterLanguageText => ({
    type: GetListLanguageTextTypes.GET_LIST_FILTER_LANGUAGE_TEXT,
    payload
})

export const getListLangTextFaild = (error:any) : IGetListLangTextFaild => ({
    type: GetListLanguageTextTypes.GET_LIST_LANGUAGE_TEXT_FAILD,
    error
})

export const setSearchDebounceLangText = (payload: any): IGetListSearchDebounceLangText => ({
    type: GetListLanguageTextTypes.GET_LIST_SEARCH_DEBOUNCE_LANGUAGE_TEXT,
    payload 
})