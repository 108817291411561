import { put, takeEvery, debounce } from 'redux-saga/effects';
import { getAdminTransfers, getDirectTransfersByAgent } from 'src/apis/service/Direct/AdminTransfers';
import { 
   getAdminOrAgentTransfersAtFetching,
   getAdminOrAgentTransfersSuccess,
   setFilterAdminOrAgentTransfers,
   getAdminOrAgentTransfersFaild,
   GetAdminOrAgentTransfersTypes
 } from 'src/store/actions/adminOrAgentTransfers';

function* getAdminOrAgentTransfersSaga(action:any):Generator<any> {
    yield put(getAdminOrAgentTransfersAtFetching(true))
    const {payload,isAgent} = action;
    try {
      const data: any = isAgent ? yield getDirectTransfersByAgent(payload) : yield getAdminTransfers(payload)
      if(data.status === 200){
        yield put(getAdminOrAgentTransfersSuccess(data))
      }  
    } catch (error) {
        yield put(getAdminOrAgentTransfersFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterAdminOrAgentTransfers(action.payload));
}
export function* watchGetAdminOrAgentTransfers() {
   yield takeEvery(GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS, getAdminOrAgentTransfersSaga)
   yield debounce(500,GetAdminOrAgentTransfersTypes.FILTER_DEBOUNCE_ADMIN_OR_AGENT_TRANSFERS, handleSearchDebounce)
}