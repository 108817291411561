import {GetWalletDetailTypes} from './actionTypes';
import { IGetWalletDetail, IGetWalletDetailFetching, IGetWalletDetailSuccess, IGetWalletDetailError, IGetWalletDetailFilter, IGetWalletDetailFiterDebounce } from "./interfaces";


export const getWalletDetail = (payload:Object): IGetWalletDetail => ({
    type: GetWalletDetailTypes.GET_WALLET_DETAIL,
    payload
})

export const getWalletDetailFetching = (isFetching:boolean) : IGetWalletDetailFetching => ({
    type: GetWalletDetailTypes.GET_WALLET_DETAIL_FETCHING,
    isFetching
})

export const getWalletDetailSuccess = (data:any) : IGetWalletDetailSuccess => ({
   type: GetWalletDetailTypes.GET_WALLET_DETAIL_SUCCESS,
   data
})

export const getWalletDetailError = (errorMsg:any) : IGetWalletDetailError => ({
    type: GetWalletDetailTypes.GET_WALLET_DETAIL_FAILD,
    error:errorMsg
})

export const getWalletDetailFilter = (payload: any): IGetWalletDetailFilter => ({
    type: GetWalletDetailTypes.GET_WALLET_DETAIL_FILTER,
    payload
})

export const getWalletDetailFilterDebounce = (payload: any): IGetWalletDetailFiterDebounce => ({
    type: GetWalletDetailTypes.GET_WALLET_DETAIL_FILTER_DEBOUNCE,
    payload 
})
