

interface IDataChangeWalletUserBalance {
    data: Object | null;
    errors: any;
    success: boolean;
}

export interface IChangeWalletUserBalanceState {
    data: IDataChangeWalletUserBalance,
    isFetching: boolean,
    countSuccess: number
}

export const initialChangeWalletUserBalanceState: IChangeWalletUserBalanceState = {
    data: {
        data: {},
        errors: '',
        success: false
    },
    isFetching: false,
    countSuccess: 0
}