import * as Sentry from '@sentry/react';
import { ListParams } from 'src/models/common';
import Request from '../../config';
import { convertObjectToParameter } from 'src/utils/newUtils/convertObjectToParameter';
import {
  GetCryptoTransactionByUserData,
  GetCryptoTransactionByUserParams,
  GetCryptoWalletByUserData,
  GetCryptoWalletByUserParams,
} from './type';
import qs from 'qs';
import isUndefined from 'lodash/isUndefined';

const requestApi = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});
const requestApiAccount = new Request({
  url: process.env.REACT_APP_BASE_URL_ACCOUNT,
});
const requestApiCrypto = new Request({
  url: process.env.REACT_APP_BASE_ADMIN_CRYPTO_URL,
});

const handleGetSendCryptoHistory = async (
  payload: ListParams
): Promise<any[]> => {
  const url = `/OverView/SendHistoryCrypto`;
  try {
    return await requestApi.post(url, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetReceiveCryptoHistory = async (
  payload: ListParams
): Promise<any[]> => {
  const url = `/OverView/ReceiverHistoryCrypto`;
  try {
    return await requestApi.post(url, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetStatistical = async (payload: number): Promise<any[]> => {
  const url = `/OverView/ChartOverView/${payload}`;
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetOverview = async (payload: any): Promise<any[]> => {
  const url = `/OverView/GetOverViewWalletCrypto`;
  try {
    return await requestApi.post(url, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetOverviewTransactionCrypto = async (
  payload: any
): Promise<any[]> => {
  const url = `/OverView/GetOverViewTransactionCrypto`;
  try {
    return await requestApi.post(url, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListActivatedWallet = async (
  payload: ListParams
): Promise<any[]> => {
  const url = `/User/GetAllActiveUser`;
  try {
    return await requestApiAccount.post(url, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListCryptoCurrency = async (): Promise<any[]> => {
  const url = `/VenLy/GetCryptoCurrency`;
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetDashboardDataAPI = async (data: any): Promise<any[]> => {
  let url = `dashboard${convertObjectToParameter(data)}`;
  try {
    return await requestApiCrypto.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetWalletProductBlockchain = async (data: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/wallet`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListTopupRequest = async (data: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/top-up`, {
      params: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateTopupTransaction = async (payload: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.post(`/top-up`, payload);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListUserWallet = async (payload: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(
      `/user-wallets${convertObjectToParameter(payload)}`,
      {}
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateWalletStatus = async (payload: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.patch(
      `/wallet/${payload.walletId}/status`,
      payload,
      {}
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetWalletDetail = async (payload: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/wallet/transactions`, {
      params: payload,
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetWalletDetailOverview = async (payload: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(`/wallet/transactions/overview`, {
      params: payload,
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const exportUserWalletToExcel = async (payload: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(`/user-wallets/export`, {
      headers: {
        responseType: 'blob',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: payload,
      responseType: 'arraybuffer',
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const exportUserWalletTransactionToExcel = async (
  payload: any
): Promise<any> => {
  try {
    return await requestApiCrypto.get(`/user-wallets/transactions/export`, {
      headers: {
        responseType: 'blob',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: payload,
      responseType: 'arraybuffer',
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const exportTransactionToExcel = async (payload: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(`/wallet/transactions/export`, {
      headers: {
        responseType: 'blob',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: payload,
      responseType: 'arraybuffer',
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetUserWalletOverview = async (): Promise<any[]> => {
  try {
    return await requestApiCrypto.get('/user-wallets/overview');
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleTransactionOverview = async (data: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.get(
      `wallet/transactions/overview${convertObjectToParameter(data)}`,
      {}
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const exportTopUpRequestHistoryToExcel = async (payload: any): Promise<any> => {
  try {
    return await requestApiCrypto.get(`/top-up/export`, {
      headers: {
        responseType: 'blob',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: payload,
      responseType: 'arraybuffer',
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateOfferNote = async (data: any): Promise<any[]> => {
  try {
    return await requestApiCrypto.patch(
      `/offers/${data?.offerId}/note`,
      { note: data?.note },
      {}
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetCryptoWalletByUser = async (
  params: GetCryptoWalletByUserParams
): Promise<GetCryptoWalletByUserData> => {
  try {
    return await requestApiCrypto.get(`/wallet-by-user`, { params });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetCryptoTransactionByUser = async (
  params: GetCryptoTransactionByUserParams
): Promise<GetCryptoTransactionByUserData> => {
  try {
    return await requestApiCrypto.get(`/wallet/transactions`, {
      params: {
        ...params,
        tranTypeByUser: isUndefined(params.type) ? 1 : 0,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
      },
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleGetSendCryptoHistory,
  handleGetReceiveCryptoHistory,
  handleGetStatistical,
  handleGetOverview,
  handleGetListActivatedWallet,
  handleGetOverviewTransactionCrypto,
  handleGetListCryptoCurrency,
  handleGetDashboardDataAPI,
  handleGetWalletProductBlockchain,
  handleGetListTopupRequest,
  handleCreateTopupTransaction,
  handleGetListUserWallet,
  handleUpdateWalletStatus,
  handleGetWalletDetail,
  handleGetWalletDetailOverview,
  exportUserWalletToExcel,
  exportTransactionToExcel,
  handleGetUserWalletOverview,
  handleTransactionOverview,
  exportTopUpRequestHistoryToExcel,
  exportUserWalletTransactionToExcel,
  handleUpdateOfferNote,
  handleGetCryptoWalletByUser,
  handleGetCryptoTransactionByUser,
};
