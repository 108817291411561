import { AnyAction, Reducer } from 'redux';
import { GetUserBalanceHistoryType } from 'src/store/actions';
import { IListUserBalanceHistoryState, initialListUserBalanceHistoryState } from './state';


export const GetListUserBalanceHistoryReducer: Reducer = (
  state: IListUserBalanceHistoryState = initialListUserBalanceHistoryState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY_SUCCESS : {
      return {
          ...state,
          data: action?.data.data.items,
          isFetching: false,
          pagination: action.data.data.totalCount
      }
    }
    case GetUserBalanceHistoryType.GET_LIST_FILTER_USER_BALANCE_HISTORY: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};