import {put, takeEvery} from 'redux-saga/effects';
import {EditUserScoreType} from '../../actions/editUserScore/actionTypes';
import {editUserIsFetching,editUserScoreSuccess,editUserScoreFaild} from '../../actions/editUserScore/action';
import {handleEditUserScore} from '../../../apis/service/user';
function* EditUserScoreSaga(action:any):Generator<any>{
  yield put(editUserIsFetching(true))
  const {payload} = action;
  try {
    const dataEditUserScore: any = yield handleEditUserScore(payload)  
    if (dataEditUserScore) {
        yield put(editUserScoreSuccess(dataEditUserScore))
    }
  } catch (error) {
     yield put(editUserScoreFaild(error)) 
  }
}
export function* watchEditUserScore(){
    yield takeEvery(EditUserScoreType.EDIT_USER_SCORE, EditUserScoreSaga)
}