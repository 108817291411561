import { AnyAction, Reducer } from 'redux';
import { IOfferSellTransactionState, initialOfferSellTransactionState } from './state';
import { GetOfferSellTransactionTypes } from '../../actions/getOfferSellTransaction/actionTypes';


export const GetOfferSellTransactionReducer: Reducer = (
  state: IOfferSellTransactionState = initialOfferSellTransactionState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetOfferSellTransactionTypes.GET_OFFER_SELL_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case GetOfferSellTransactionTypes.GET_LIST_FILTER_OFFER_SELL_TRANSACTION: {
      return {
          ...state,
          filter: action.payload
      }
    }
      default:
        return state;
    }
};