import { GetWalletsUserType } from './actionTypes';
import { IFetchingGetWalletsUser, IGetWalletsUser, IGetWalletsUserSuccess, IGetWalletsUserError } from './interfaces';

export const getWalletsUserIsFetching = (isFetching:boolean) : IFetchingGetWalletsUser => ({
    type: GetWalletsUserType.GET_WALLETS_USER_FETCHING,
    isFetching
})

export const getWalletsUser = () : IGetWalletsUser => ({
    type: GetWalletsUserType.GET_WALLETS_USER
})

export const getWalletsUserSuccess = (data:any) : IGetWalletsUserSuccess => ({
    type: GetWalletsUserType.GET_WALLETS_USER_SUCCESS,
    data
})

export const getWalletsUserFaild = (error:any) : IGetWalletsUserError => ({
    type: GetWalletsUserType.GET_WALLETS_USER_ERROR,
    error
})