import { getUserDetail } from 'src/store/actions/getDataUserDetail';
import { put, takeEvery, select } from 'redux-saga/effects';
import { ReactiveUserType } from '../../actions/reactiveUser/actionTypes';
import {
  reactivekUserIsFetching,
  reactiveUserSuccess,
  reactivekUserFaild
} from '../../actions/reactiveUser/action';
import { handleReactiveUser } from '../../../apis/service/user';
import { IApplicationState } from 'src/store/IApplicationState';
import { getListUserDeactivated } from 'src/store/actions/getListUserDeactivated/action';
function* ReactiveUserSaga(action: any): Generator<any> {
  yield put(reactivekUserIsFetching(true));
  const { payload, listUserDeactive } = action;
  try {
    const dataReactiveUser: any = yield handleReactiveUser(payload);
    if (dataReactiveUser.status === 200) {
      // if (listUserDeactive) {
      //   const filter: any = yield select(
      //     (state: IApplicationState) => state.listUserDeactivated.filter
      //   );
      //   yield put(getListUserDeactivated(filter));
      // }
      yield put(getUserDetail(payload.userId));
      yield put(reactiveUserSuccess(dataReactiveUser));
    } else {
      yield put(reactivekUserFaild(dataReactiveUser.errors[0]));
    }
  } catch (error) {
    yield put(reactivekUserFaild(error));
  }
}
export function* watchReactiveUser() {
  yield takeEvery(ReactiveUserType.REACTIVE_USER, ReactiveUserSaga);
}
