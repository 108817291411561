import { PAGE_SIZE_DEFAULT } from './../../../contants/pageSize';
interface IDataListUserBalanceHistory {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListUserBalanceHistoryState {
    data: IDataListUserBalanceHistory;
    isFetching: boolean,
    filter: object,
    pagination: number
}

export const initialListUserBalanceHistoryState: IListUserBalanceHistoryState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        // pageSize: PAGE_SIZE_DEFAULT,
    },
    pagination: 0,
}

