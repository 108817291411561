import { GetScoreHistoryType } from './actionTypes';
import { IFetchingGetScoreHistory, IGetListFilterDebounceScoreHistory, IGetListFilterScoreHistory, IGetScoreHistory, IGetScoreHistoryError, IGetScoreHistorySuccess } from './interfaces';

export const getScoreHistoryIsFetching = (isFetching:boolean) : IFetchingGetScoreHistory => ({
    type: GetScoreHistoryType.GET_SCORE_HISTORY_FETCHING,
    isFetching
})

export const getScoreHistory = (payload: object) : IGetScoreHistory => ({
    type: GetScoreHistoryType.GET_SCORE_HISTORY,
    payload
})

export const getScoreHistorySuccess = (data:any) : IGetScoreHistorySuccess => ({
    type: GetScoreHistoryType.GET_SCORE_HISTORY_SUCCESS,
    data
})

export const getScoreHistoryFaild = (error:any) : IGetScoreHistoryError => ({
    type: GetScoreHistoryType.GET_SCORE_HISTORY_ERROR,
    error
})

export const setFilterScoreHistory = (payload: any): IGetListFilterScoreHistory => ({
    type: GetScoreHistoryType.SET_LIST_FILTER_SCORE_HISTORY,
    payload
})

export const setFilterDebounceScoreHistory = (payload: any): IGetListFilterDebounceScoreHistory => ({
    type: GetScoreHistoryType.SET_LIST_FILTER_DEBOUNCE_SCORE_HISTORY,
    payload 
})