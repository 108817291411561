import { debounce, put, takeEvery } from "redux-saga/effects";
import { WalletInputTypes } from "../../actions/walletInput";
import {
  walletInputFetching,
  walletInputFilter,
  walletInputSuccess
} from "../../actions/walletInput/action";

function* walletInputSaga(action: any): Generator<any> {
  yield put(walletInputFetching(true));
  const { payload } = action;
  yield put(walletInputSuccess(payload));
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(walletInputFilter(action.payload));
}
export function* watchWalletInput() {
  yield takeEvery(WalletInputTypes.WALLET_INPUT, walletInputSaga);
  yield debounce(
    500,
    WalletInputTypes.WALLET_INPUT_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
