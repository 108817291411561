import {debounce, put, takeEvery} from 'redux-saga/effects';
import { getAnnouncementCategory } from 'src/apis/service/Announcements';
import { getAnnouncementsCategoryFaild, getAnnouncementsCategoryIsFetching, getAnnouncementsCategorySuccess, GetAnnouncementsCategoryType, setFilterAnnouncementsCategory } from 'src/store/actions';

function* getListAnnouncementsCategorySaga(action: any): Generator<any> {
    yield put(getAnnouncementsCategoryIsFetching(true))
    const { payload } = action;
    try {
      const data = yield getAnnouncementCategory(payload)      
      if(data){
        yield put(getAnnouncementsCategorySuccess(data));
      }  
    } catch (error) {
        yield put(getAnnouncementsCategoryFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterAnnouncementsCategory(action.payload));
}

export function* watchGetListAnnouncementsCategory() {
   yield takeEvery(GetAnnouncementsCategoryType.GET_ANNOUNCEMENTS_CATEGORY , getListAnnouncementsCategorySaga)
   yield debounce(500, GetAnnouncementsCategoryType.GET_LIST_FILTER_DEBOUNCE_ANNOUNCEMENTS_CATEGORY, handleSearchDebounce)
  }