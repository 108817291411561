import { AnyAction, Reducer } from 'redux';
import { RemoveAdminBOState, initialRemoveAdminBOState } from './state';
import { DeleteAdminBOType } from 'src/store/actions/admin-managements/removeAdminBO';
import { toastSuccess } from 'src/helpers/toastHelper';

export const RemoveAdminReducer: Reducer = (
  state: RemoveAdminBOState = initialRemoveAdminBOState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteAdminBOType.DELETE_ADMIN_BO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case DeleteAdminBOType.DELETE_ADMIN_BO_SUCCESS: {
      toastSuccess('bo_remove_admin_success');
      return {
        ...state,
        isFetching: false,
        countSuccess: state.countSuccess + 1,
      };
    }
    case DeleteAdminBOType.DELETE_ADMIN_BO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
