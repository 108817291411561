interface editUserByAdmin {
    data: any,
    status: number,
    message: string
}

export interface EditUserByAdminState {
    data: editUserByAdmin,
    isFetching: boolean,
    successCount: number
}

export const initialEditUserByAdminState: EditUserByAdminState = {
    data: {
        data: null,
        message: '',
        status: 0
    },
    isFetching: false,
    successCount: 0
}