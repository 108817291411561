import { AnyAction, Reducer } from 'redux';
import { GetP2PExchangeOffersType } from 'src/store/actions/P2PExchangeOffers';
import { IListP2PExchangeOffersState, initialListP2PExchangeOffersState } from './state';
import { toastError } from 'src/helpers/toastHelper';


export const GetListP2PExchangeOffersReducer: Reducer = (
  state: IListP2PExchangeOffersState = initialListP2PExchangeOffersState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS_ERROR: {
      return {
        ...state,
        error: action?.error,
        isFetching: false,
      };
    }
    case GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS_SUCCESS: {
      return {
        ...state,
        data: action?.data?.data?.items ? action?.data?.data?.items : [],
        isFetching: false,
        pagination: action?.data?.data?.totalCount
      }
    }
    case GetP2PExchangeOffersType.SET_LIST_FILTER_P2P_EXCHANGE_OFFERS: {
      return {
        ...state,
        filter: action.payload
      }
    }
    default:
      return state;
  }
};