import { AnyAction, Reducer } from 'redux';
import { IGetAllScorePeriodConfigState, initialGetAllScorePeriodConfigState } from './state';
import { GetAllScorePeriodConfigTypes } from 'src/store/actions/getAllScorePeriodConfig';

export const GetAllScorePeriodConfigReducer: Reducer = (
  state: IGetAllScorePeriodConfigState = initialGetAllScorePeriodConfigState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetAllScorePeriodConfigTypes.CLEAR_ALL_SCORE_PERIOD_CONFIG: {
      return {
        ...initialGetAllScorePeriodConfigState,
      };
    }
    default: {
      return state;
    }
  }
};
