import {GetTransactionsFilterStatusesTypes} from './actionTypes';
import { IGetTransactionsFilterStatuses, IGetTransactionsFilterStatusesFetching, IGetTransactionsFilterStatusesSuccess, IGetTransactionsFilterStatusesError, IGetTransactionsFilterStatusesFilter, IGetTransactionsFilterStatusesFiterDebounce } from "./interfaces";


export const getTransactionsFilterStatuses = (payload:Object): IGetTransactionsFilterStatuses => ({
    type: GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES,
    payload
})

export const getTransactionsFilterStatusesFetching = (isFetching:boolean) : IGetTransactionsFilterStatusesFetching => ({
    type: GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_FETCHING,
    isFetching
})

export const getTransactionsFilterStatusesSuccess = (data:any) : IGetTransactionsFilterStatusesSuccess => ({
   type: GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_SUCCESS,
   data
})

export const getTransactionsFilterStatusesError = (errorMsg:any) : IGetTransactionsFilterStatusesError => ({
    type: GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_FAILD,
    error:errorMsg
})

export const getTransactionsFilterStatusesFilter = (payload: any): IGetTransactionsFilterStatusesFilter => ({
    type: GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_FILTER,
    payload
})

export const getTransactionsFilterStatusesFilterDebounce = (payload: any): IGetTransactionsFilterStatusesFiterDebounce => ({
    type: GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_FILTER_DEBOUNCE,
    payload 
})
