import { put, takeEvery } from "redux-saga/effects";
import { handleDeleteBankByUser } from "src/apis/service/BankAccount";
import { toastError, toastSuccess } from "src/helpers/toastHelper";
import { getDataBankAccountAgent } from "src/store/actions/bankAccountByAgent/getDataBankAccountByAgent";
import {
  RemoveUserBankAccAgentType,
  removeUserBankAccAgentIsFetching,
  removeUserBankAccAgentFaild,
  removeUserBankAccAgentSuccess,
} from "src/store/actions/bankAccountByAgent/removeUserBankAccountAgent";

function* RemoveUserBankAccAgentSaga(action: any): Generator<any> {
  yield put(removeUserBankAccAgentIsFetching(true));
  const { payload, getListRequest } = action;
  try {
    const dataRemoveUserBankAcc: any = yield handleDeleteBankByUser(payload);
    if (dataRemoveUserBankAcc.status === 200) {
      yield put(removeUserBankAccAgentSuccess(dataRemoveUserBankAcc));
      yield put(getDataBankAccountAgent({ ...getListRequest }));
      toastSuccess("bo_delete_bank_account_user_success");
    } else {
      toastError(dataRemoveUserBankAcc.message);
      yield put(removeUserBankAccAgentFaild(dataRemoveUserBankAcc.message));
    }
  } catch (error) {
    yield put(removeUserBankAccAgentFaild(error));
  }
}
export function* watchRemoveUserBankAccAgent() {
  yield takeEvery(
    RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT,
    RemoveUserBankAccAgentSaga
  );
}
