import { AnyAction, Reducer } from 'redux';
import { initialAddBankAccAgentState, IDataAddUserBankAccountAgentState } from './state';
import { AddUserBankAccountforAgentType } from 'src/store/actions/bankAccountByAgent/addUserBankAccountForAgent';

export const AddBankAccAgentReducer: Reducer = (
  state: IDataAddUserBankAccountAgentState = initialAddBankAccAgentState,
  action: AnyAction
) => {
  switch (action.type) {
      case AddUserBankAccountforAgentType.ADD_USER_BANK_ACOOUNT_AGENT: {
         return {
             ...state,
             isFetching:true
         }
      }
      case AddUserBankAccountforAgentType.ADD_USER_BANK_ACCOUNT_AGENT_SUCCESS: {
          return {
              ...state,
              data: action.data,
              isFetching:false
          }
      }
      case AddUserBankAccountforAgentType.ADD_USER_BANK_ACCOUNT_AGENT_ERROR: {
        return {
            ...state,
            error: action.error,
            isFetching:false
        }
    }
    default:{
      return state
    }
  }
}