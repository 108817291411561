import { AnyAction, Reducer } from 'redux';
import {
  ICreateAndEditAdminBOState,
  initialCreateAndEditAdminBOState,
} from './state';
import { CreateAndEditAdminBOType } from '../../../actions/admin-managements/createAndeditAdminBO';

export const CreateAndEditAdminBOReducer: Reducer = (
  state: ICreateAndEditAdminBOState = initialCreateAndEditAdminBOState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1,
      };
    }
    case CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
        successCount: 0,
      };
    }
    default: {
      return state;
    }
  }
};
