import { AnyAction, Reducer } from 'redux';
import { ICreateWalletAdminBOState, initialCreateWalletAdminBOState } from './state';
import { CreateWalletAdminBOType } from '../../../actions/WalletAdminBO/createWalletAdminBO';

export const CreateWalletAdminBOReducer: Reducer = (
  state: ICreateWalletAdminBOState = initialCreateWalletAdminBOState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case CreateWalletAdminBOType.CREATE_WALLET_ADMIN_BO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};