import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetNavbarForAdmin } from 'src/apis/service/AdminRoleSetting';
import { GetNavbarForAdminTypes } from '../../actions/getNavbarForAdmin';
import { getNavbarForAdminError, getNavbarForAdminFetching, getNavbarForAdminFilter, getNavbarForAdminSuccess } from '../../actions/getNavbarForAdmin/action';

function* getNavbarForAdminSaga():Generator<any> {
    yield put(getNavbarForAdminFetching(true))
    try {
      const data:any = yield handleGetNavbarForAdmin()
      if(data && data?.status === 200){
        yield put(getNavbarForAdminSuccess(data))
      } else {
        yield put(getNavbarForAdminError('error'))
      }
    } catch (error) {
        yield put(getNavbarForAdminError(error))
    }
}

export function* watchGetNavbarForAdmin() {
   yield takeEvery(GetNavbarForAdminTypes.GET_NAVBAR_FOR_ADMIN, getNavbarForAdminSaga)
}
