import { AnyAction, Reducer } from 'redux';
import { IPostCryptoCreateExchangeTransactionState, initialPostCryptoCreateExchangeTransactionState } from './state';
import { PostCryptoCreateExchangeTransactionTypes } from 'src/store/actions/postCryptoCreateExchangeTransaction';

export const PostCryptoCreateExchangeTransactionReducer: Reducer = (
  state: IPostCryptoCreateExchangeTransactionState = initialPostCryptoCreateExchangeTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case PostCryptoCreateExchangeTransactionTypes.POST_CRYPTO_CREATE_EXCHANGE_TRANSACTION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
