



// login
interface Role {
    roleId:number,
    roleName:string
}
interface DataDetailLogin {
    countryId:number;
    expiration: string;
    phoneNumber: string;
    roles:Role[];
    token:string;
    userId:number;
    username:string;
}

interface AuthInfomation {
    data:DataDetailLogin;
    status:number;
    message:string;
    success:boolean;
}

export interface ILoginState {
    isFetching: boolean;
    userLoggin: AuthInfomation;
    loginSuccess: boolean;
}

export const initialLoginState: ILoginState = {
    isFetching: false,
    userLoggin:{
        data:{
            countryId:0,
            expiration: '',
            phoneNumber: '',
            roles:[
                {
                    roleId:0,
                    roleName:''
                }
            ],
            token:'',
            userId:0,
            username:''
        },
        message:'',
        success: false,
        status:0
    },
    loginSuccess: false
}


