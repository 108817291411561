
// get transaction
import { ParamsKycRiskManagementDefault } from "src/contants/paramsDefault";

interface IDataItemKycRiskManagement {
  data:{
      items:any[];
      totalCount:number;
  };
  status:number;
  message:string;
}

export interface IListKycRiskManagementState {
    data: IDataItemKycRiskManagement;
    isFetching: boolean,
    filter: Object,
    pagination: any,
}

export const initialListKycRiskManagementState: IListKycRiskManagementState = {
    data:{
        data:{
            items:[],
            totalCount: 0
        },
        message: '',
        status: 0
    },
    isFetching:false,
    filter: ParamsKycRiskManagementDefault,
    pagination: {
        totalCount: 0,
    }
}

