import { ParamsKycApplicationeDefault } from 'src/contants/paramsDefault';
import { ParamsKycByStageDefault } from 'src/contants/paramsDefault';
import { getKycListByStage } from './../../actions/kycListByStage/action';
import { PAGE_SIZE_DEFAULT } from './../../../contants/pageSize';
import { toastSuccess, toastError } from './../../../helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleSetUserKYCPriority } from 'src/apis/service/Priority';
import { SetUserKYCPriorityTypes } from '../../actions/setUserKYCPriority';
import {
  setUserKYCPriorityError,
  setUserKYCPriorityFetching,
  setUserKYCPriorityFilter,
  setUserKYCPrioritySuccess
} from '../../actions/setUserKYCPriority/action';
import { getListUser, getKycList } from 'src/store/actions';

function* setUserKYCPrioritySaga(action: any): Generator<any> {


  yield put(setUserKYCPriorityFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleSetUserKYCPriority(payload);
    if (data && data?.status === 200) {
      var filter = {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: 'LastLoginAttemptDate',
        orderDirection: 'desc'
      };
      // yield put(getListUser(filter));
      // const pathName = window.location.pathname;
      // if(pathName == '/dashboard/kyc/list-by-kyc-stage'){
      //   yield put(getKycListByStage(ParamsKycByStageDefault));
      // }else if (pathName == '/dashboard/kyc/application'){
      //   yield put(getKycList(ParamsKycApplicationeDefault));
      // }
      toastSuccess('bo_set_user_kyc_priority_config_success');
      yield put(setUserKYCPrioritySuccess(data));
    } else {
      toastError('bo_you_can_t_set_user_kyc_priority_config');
      yield put(setUserKYCPriorityError('error'));
    }
  } catch (error) {
    toastError('bo_you_can_t_set_user_kyc_priority_config');
    yield put(setUserKYCPriorityError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setUserKYCPriorityFilter(action.payload));
}
export function* watchSetUserKYCPriority() {
  yield takeEvery(
    SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY,
    setUserKYCPrioritySaga
  );
  yield debounce(
    500,
    SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
