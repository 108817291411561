import {ActiveAgentRankTypes} from './actionTypes';
import { IActiveAgentRank, IActiveAgentRankFetching, IActiveAgentRankSuccess, IActiveAgentRankError, IActiveAgentRankFilter, IActiveAgentRankFiterDebounce } from "./interfaces";


export const activeAgentRank = (payload:Object): IActiveAgentRank => ({
    type: ActiveAgentRankTypes.ACTIVE_AGENT_RANK,
    payload
})

export const activeAgentRankFetching = (isFetching:boolean) : IActiveAgentRankFetching => ({
    type: ActiveAgentRankTypes.ACTIVE_AGENT_RANK_FETCHING,
    isFetching
})

export const activeAgentRankSuccess = (data:any) : IActiveAgentRankSuccess => ({
   type: ActiveAgentRankTypes.ACTIVE_AGENT_RANK_SUCCESS,
   data
})

export const activeAgentRankError = (errorMsg:any) : IActiveAgentRankError => ({
    type: ActiveAgentRankTypes.ACTIVE_AGENT_RANK_FAILD,
    error:errorMsg
})

export const activeAgentRankFilter = (payload: any): IActiveAgentRankFilter => ({
    type: ActiveAgentRankTypes.ACTIVE_AGENT_RANK_FILTER,
    payload
})

export const activeAgentRankFilterDebounce = (payload: any): IActiveAgentRankFiterDebounce => ({
    type: ActiveAgentRankTypes.ACTIVE_AGENT_RANK_FILTER_DEBOUNCE,
    payload 
})
