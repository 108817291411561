import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { formatDateToUTCZeroTime } from 'src/utils/newUtils/formatDate';

export interface IListP2PExchangeOffersState {
  data: any[];
  isFetching: boolean;
  filter: object;
  pagination: number;
}

export const initialListP2PExchangeOffersState: IListP2PExchangeOffersState = {
  data: [],
  isFetching: false,
  filter: {
    pageNumber: 1,
    pageSize: PAGE_SIZE_DEFAULT,
    startDate: formatDateToUTCZeroTime(
      new Date().setFullYear(new Date().getFullYear() - 1)
    ),
    endDate: formatDateToUTCZeroTime(new Date(), true),
  },
  pagination: 0,
};
