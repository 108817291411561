import { debounce, put, takeEvery } from "redux-saga/effects";
import { handleAgentGetAdminTrxDetail } from "src/apis/service/P2PTransaction";
import { AgentGetAdminTrxDetailTypes } from "../../actions/agentGetAdminTrxDetail";
import {
  agentGetAdminTrxDetailError,
  agentGetAdminTrxDetailFetching,
  agentGetAdminTrxDetailFilter,
  agentGetAdminTrxDetailSuccess,
} from "../../actions/agentGetAdminTrxDetail/action";

function* agentGetAdminTrxDetailSaga(action: any): Generator<any> {
  yield put(agentGetAdminTrxDetailFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleAgentGetAdminTrxDetail(payload);
    if (data && data?.status === 200) {
      yield put(agentGetAdminTrxDetailSuccess(data));
    } else {
      yield put(agentGetAdminTrxDetailError("error"));
    }
  } catch (error) {
    yield put(agentGetAdminTrxDetailError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentGetAdminTrxDetailFilter(action.payload));
}
export function* watchAgentGetAdminTrxDetail() {
  yield takeEvery(
    AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL,
    agentGetAdminTrxDetailSaga
  );
  yield debounce(
    500,
    AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
