import { all, call, put, takeLatest } from 'redux-saga/effects';
import { handleGetListActivatedWallet, handleGetStatistical } from 'src/apis/service/CryptoWallet';
import { getCryptoActivatedWalletListFaild, getCryptoActivatedWalletListIsFetching, getCryptoActivatedWalletListSuccess, GetCryptoActivatedWalletListType, getCryptoWalletOverviewStatisticalSuccess } from 'src/store/actions';

function* getListStatisticalOverviewSaga(): Generator<any>  {
  const data: any = yield call(handleGetStatistical, 2)      
  const strimData = data?.data.filter((_statistical: any, index: number) => index < 3)
  const sumPercent = strimData.reduce((acc: any, o: any) => acc + parseFloat(o.percent), 0);
  strimData.push({symbol: "bo_others_label", percent: (100 - sumPercent).toFixed(2)})
  const template = [
    {
        "id": 0,
        "color": "#3f51b5"
    },
    {
        "id": 1,
        "color": "#7986cb"
    },
    {
        "id": 2,
        "color": "#c5cae9"
    },
    {
      "id": 3,
      "color": "#c5cae9"
    }
  ]
  strimData?.map((item: any, index: number)=> Object.assign(item, template.find(itemTemp => itemTemp.id===index)))
  yield put(getCryptoWalletOverviewStatisticalSuccess(strimData));
}

function* getListCryptoActivatedWalletSaga(action: any): Generator<any>  {
  const data: any = yield call(handleGetListActivatedWallet, action)      
  yield put(getCryptoActivatedWalletListSuccess(data));
}

function* fetchListCryptoOverview(action: any) {
  const { payload } = action;
  yield put(getCryptoActivatedWalletListIsFetching(true))
  try {
    yield all([
      call(() => getListCryptoActivatedWalletSaga(payload)),
      call(getListStatisticalOverviewSaga),
    ]);
    yield put(getCryptoActivatedWalletListSuccess('success'));
  } catch (error) {
    yield put(getCryptoActivatedWalletListFaild('error'))
  }
}

export function* watchGetListCryptoActivatedWalletList() {
  yield takeLatest(GetCryptoActivatedWalletListType.GET_CRYPTO_ACTIVATED_WALLET_LIST , fetchListCryptoOverview)
}