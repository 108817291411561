import { ListParams } from 'src/models/common';
import { GetDashboardType } from './actionTypes';
import { IFetchingGetDashboard, IGetDashboard, IGetDashboardError, IGetDashboardSuccess, IGetListFilterDashboard } from './interfaces';

export const getDashboardIsFetching = (isFetching:boolean) : IFetchingGetDashboard => ({
    type: GetDashboardType.GET_DASHBOARD_FETCHING,
    isFetching
})

export const getDashboard = () : IGetDashboard => ({
    type: GetDashboardType.GET_DASHBOARD,
})

export const getDashboardSuccess = (data:any) : IGetDashboardSuccess => ({
    type: GetDashboardType.GET_DASHBOARD_SUCCESS,
    data
})

export const getDashboardFaild = (error:any) : IGetDashboardError => ({
    type: GetDashboardType.GET_DASHBOARD_ERROR,
    error
})

export const setFilterDashboard = (payload: ListParams): IGetListFilterDashboard => ({
    type: GetDashboardType.GET_LIST_FILTER_DASHBOARD,
    payload
})