
interface IDataListCountry {
  data: any[],
  status:number,
  message:string
}

export interface IListCountryState {
    data: IDataListCountry;
    isFetching: boolean,
}

export const initialListCountryState: IListCountryState = {
    data: {
        data:[],
        message:'',
        status:0
    },
    isFetching: false,
}

