import { ListParams } from "src/models/common";

interface IDataGetRoleFunctionByAdminId {
  data:[];
  error:any;
  success:boolean;
}

export interface IGetRoleFunctionByAdminIdState {
    data:IDataGetRoleFunctionByAdminId;
    isFetching:any,
    filter: ListParams,
    pagination: any,
}

export const initialGetRoleFunctionByAdminIdState: IGetRoleFunctionByAdminIdState = {
    data:{
        data:[],
        error:null,
        success:false
    },
    isFetching:-1,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


