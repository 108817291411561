import {put, takeEvery,select} from 'redux-saga/effects';
import {UpdateLanguageTextType} from '../../actions/updateTextLanguage/actionTypes';
import {updateLanguageTextIsFetching,updateLanguageTextSuccess,updateLanguageTextFaild} from '../../actions/updateTextLanguage/action';
import {handleEditLanguageText} from '../../../apis/service/user';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { IApplicationState } from 'src/store/IApplicationState';
import { getListLangText } from 'src/store/actions/getListLanguageText/action';


function* updateLanguageTextSaga(action:any):Generator<any> {
    yield put(updateLanguageTextIsFetching(true))
    const { payload, history } = action;
    try {
      const dataUpdateLanguageText:any = yield handleEditLanguageText(payload)
      if(dataUpdateLanguageText?.status === 200){
        const filter : any = yield select((state:IApplicationState) => state.listLaguageText.filter)
        yield put(updateLanguageTextSuccess(dataUpdateLanguageText))
        // yield put(history.push('/dashboard/language'))
        toastSuccess('bo_updated_language_text');
        // yield put(getListLangText(filter))
      } else {
        toastError('bo_update_language_text_fail');
        yield put(updateLanguageTextFaild('error'))
        // yield put(history.push('/dashboard/language'))
      } 
    } catch (error) {
        yield put(updateLanguageTextFaild(error))
    }
}

export function* watchUpdateLanguageText() {
   yield takeEvery(UpdateLanguageTextType.UPDATE_LANGUAGE_TEXT, updateLanguageTextSaga)
}