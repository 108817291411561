import { AnyAction, Reducer } from 'redux';
import { IEditOfferBuyOrSellState, initialEditOfferBuyOrSellState } from './state';
import { EditOfferSellOrBuy } from '../../../actions/myOfferFromAgent/editOfferBuyOrSell';

export const EditOfferSellOrBuyReducer: Reducer = (
  state: IEditOfferBuyOrSellState = initialEditOfferBuyOrSellState,
  action: AnyAction
) => {
  switch (action.type) {
    case EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    }
    case EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};