import { put, takeEvery, debounce } from "redux-saga/effects";
import { AgentChangePINCodeTypes } from "../../actions/agentChangePINCode";
import {
  agentChangePINCodeFetching,
  agentChangePINCodeSuccess,
  agentChangePINCodeError,
  agentChangePINCodeFilter,
} from "../../actions/agentChangePINCode/action";
import { handleAgentChangePINCode } from "src/apis/service/Agent";
import { toastError, toastSuccess } from "src/utils/newUtils/toastHelper";

function* agentChangePINCodeSaga(action: any): Generator<any> {
  yield put(agentChangePINCodeFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleAgentChangePINCode(payload);
    if (data && data?.status === 200) {
      yield put(agentChangePINCodeSuccess(data));
      toastSuccess('bo_change_pin_success_label')
    } else {
      yield put(agentChangePINCodeError("error"));
      toastError('bo_change_pin_fail_label')
    }
  } catch (error) {
    toastError('bo_change_pin_fail_label')
    yield put(agentChangePINCodeError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentChangePINCodeFilter(action.payload));
}
export function* watchAgentChangePINCode() {
  yield takeEvery(
    AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE,
    agentChangePINCodeSaga
  );
  yield debounce(
    500,
    AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
