import { AnyAction, Reducer } from 'redux';
import { initialExportDirectTransferState, ExportDirectTransferState } from './state';
import { ExportDirectTransferType } from '../../actions/ExportDirectTransfer/actionTypes';


export const ExportDirectTransferReducer: Reducer = (
  state: ExportDirectTransferState = initialExportDirectTransferState,
  action: AnyAction
) => {
  switch (action.type) {
    case ExportDirectTransferType.EXPORT_DIRECT_TRANSFER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ExportDirectTransferType.EXPORT_DIRECT_TRANSFER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case ExportDirectTransferType.EXPORT_DIRECT_TRANSFER_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
