import Request from "../../config";
import * as Sentry from "@sentry/react";
import { ListParams } from "../../../models/common";

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_ACCOUNT});

const getAllAdminBO = async (data:ListParams) : Promise<any> => {
    try {
      return await requestApi.post(`/Account/GetListAdmin`,data)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const deleteAdminBO = async (data:any) : Promise<any> => {
    try {
      return await requestApi.post(`/Account/DeleteAdmin?adminId=${data.adminId}`)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const createAdminBO = async (data:any) : Promise<any> => {
    try {
      return await requestApi.post(`/User/create/admin`,data)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const editAdminBO = async (data:any) : Promise<any> => {
  try {
    return await requestApi.post(`/Account/EditAdmin`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export {
    getAllAdminBO,
    deleteAdminBO,
    createAdminBO,
    editAdminBO
}
