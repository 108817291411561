import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeOffers } from 'src/apis/service/P2PExchange';
import { toastError } from 'src/helpers/toastHelper';
import { getP2PExchangeOffersFaild, getP2PExchangeOffersIsFetching, getP2PExchangeOffersSuccess, GetP2PExchangeOffersType, setFilterP2PExchangeOffers } from 'src/store/actions/P2PExchangeOffers';

function* getListP2PExchangeOffersSaga(action: any): Generator<any> {
    yield put(getP2PExchangeOffersIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeOffers, payload)  
      if(data && data?.status === 200){
        yield put(getP2PExchangeOffersSuccess(data));
      } else {
        toastError('bo_data_not_found')
        yield put(getP2PExchangeOffersFaild('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeOffersFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterP2PExchangeOffers(action.payload));
}

export function* watchGetListP2PExchangeOffers() {
  yield takeEvery(GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS , getListP2PExchangeOffersSaga)
  yield debounce(500, GetP2PExchangeOffersType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_OFFERS, handleSearchDebounce)
}