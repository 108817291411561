import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleTransactionOverview } from 'src/apis/service/CryptoWallet';
import { TransactionOverviewTypes } from '../../actions/transactionOverview';
import { transactionOverviewError, transactionOverviewFetching, transactionOverviewFilter, transactionOverviewSuccess } from '../../actions/transactionOverview/action';

function* transactionOverviewSaga(action:any):Generator<any> {
    yield put(transactionOverviewFetching(true))
    const {payload} = action;
    if (!payload.status) {
      delete payload.status;
    }
    try {
      const data:any = yield handleTransactionOverview(payload)
      if(data && data?.status === 200){
        yield put(transactionOverviewSuccess(data))
      } else {
        yield put(transactionOverviewError('error'))
      }
    } catch (error) {
        yield put(transactionOverviewError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(transactionOverviewFilter(action.payload));
}
export function* watchTransactionOverview() {
   yield takeEvery(TransactionOverviewTypes.TRANSACTION_OVERVIEW, transactionOverviewSaga)
   yield debounce(500,TransactionOverviewTypes.TRANSACTION_OVERVIEW_FILTER_DEBOUNCE,handleSearchDebounce)
}
