import { AnyAction, Reducer } from 'redux';
import { RemoveAgentRankState, initialRemoveAgentRankState } from './state';
import { DeleteAgentRankType } from 'src/store/actions/Agent/RemoveAgentRank';

export const RemoveAgentRankReducer: Reducer = (
  state: RemoveAgentRankState = initialRemoveAgentRankState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteAgentRankType.DELETE_AGENT_RANK: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case DeleteAgentRankType.DELETE_AGENT_RANK_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case DeleteAgentRankType.DELETE_AGENT_RANK_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
