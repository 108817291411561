import { ListParams } from "src/models/common";

interface IDataGetListAdminFunction {
  data:{
    listParentId:any[];
    listAdminFunction:any[];
  };
  error:any;
  success:boolean;
}

export interface IGetListAdminFunctionState {
    data:IDataGetListAdminFunction;
    isFetching:any,
    filter: ListParams,
    pagination: any,
}

export const initialGetListAdminFunctionState: IGetListAdminFunctionState = {
    data:{
        data:{
            listParentId:[],
            listAdminFunction:[]
        },
        error:null,
        success:false
    },
    isFetching: -1,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


