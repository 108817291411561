import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetDirectTransactionDetail } from 'src/apis/service/P2PTransaction';
import { GetDirectTransactionDetailTypes } from '../../actions/getDirectTransactionDetail';
import { getDirectTransactionDetailError, getDirectTransactionDetailFetching, getDirectTransactionDetailFilter, getDirectTransactionDetailSuccess } from '../../actions/getDirectTransactionDetail/action';

function* getDirectTransactionDetailSaga(action:any):Generator<any> {
    yield put(getDirectTransactionDetailFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetDirectTransactionDetail(payload)
      if(data && data?.status === 200){
        yield put(getDirectTransactionDetailSuccess(data))
      } else {
        yield put(getDirectTransactionDetailError('error'))
      }
    } catch (error) {
        yield put(getDirectTransactionDetailError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getDirectTransactionDetailFilter(action.payload));
}
export function* watchGetDirectTransactionDetail() {
   yield takeEvery(GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL, getDirectTransactionDetailSaga)
   yield debounce(500,GetDirectTransactionDetailTypes.GET_DIRECT_TRANSACTION_DETAIL_FILTER_DEBOUNCE,handleSearchDebounce)
}
