import {AgentTransferredTransactionTypes} from './actionTypes';
import { IAgentTransferredTransaction, IAgentTransferredTransactionFetching, IAgentTransferredTransactionSuccess, IAgentTransferredTransactionError, IAgentTransferredTransactionFilter, IAgentTransferredTransactionFiterDebounce } from "./interfaces";


export const agentTransferredTransaction = (payload:Object,getData:any): IAgentTransferredTransaction => ({
    type: AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION,
    payload,
    getData
})

export const agentTransferredTransactionFetching = (isFetching:boolean) : IAgentTransferredTransactionFetching => ({
    type: AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_FETCHING,
    isFetching
})

export const agentTransferredTransactionSuccess = (data:any) : IAgentTransferredTransactionSuccess => ({
   type: AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_SUCCESS,
   data
})

export const agentTransferredTransactionError = (errorMsg:any) : IAgentTransferredTransactionError => ({
    type: AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_FAILD,
    error:errorMsg
})

export const agentTransferredTransactionFilter = (payload: any): IAgentTransferredTransactionFilter => ({
    type: AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_FILTER,
    payload
})

export const agentTransferredTransactionFilterDebounce = (payload: any): IAgentTransferredTransactionFiterDebounce => ({
    type: AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_FILTER_DEBOUNCE,
    payload 
})
