export interface ReactiveUser {
    data: any,
    errors: any,
    success: any
}

export interface ReactiveUserState {
    data: ReactiveUser,
    isFetching: boolean,
    countSuccess: number
}

export const initialReactiveUserState: ReactiveUserState = {
    data: {
        data: {},
        errors: '',
        success: false
    },
    isFetching: false,
    countSuccess: 0
}