import { debounce, put, takeEvery } from 'redux-saga/effects';
import { getDirectTransfersByAgent } from 'src/apis/service/Direct/AdminTransfers';
import { GetAgentDirectTransfersTypes } from '../../actions/getAgentDirectTransfers';
import { getAgentDirectTransfersError, getAgentDirectTransfersFetching, getAgentDirectTransfersFilter, getAgentDirectTransfersSuccess } from '../../actions/getAgentDirectTransfers/action';

function* getAgentDirectTransfersSaga(action:any):Generator<any> {
    yield put(getAgentDirectTransfersFetching(true))
    const {payload} = action;
    try {
      const data:any = yield getDirectTransfersByAgent(payload)
      if(data && data?.status === 200){
        yield put(getAgentDirectTransfersSuccess(data))
      } else {
        yield put(getAgentDirectTransfersError('error'))
      }
    } catch (error) {
        yield put(getAgentDirectTransfersError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAgentDirectTransfersFilter(action.payload));
}
export function* watchGetAgentDirectTransfers() {
   yield takeEvery(GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS, getAgentDirectTransfersSaga)
   yield debounce(500,GetAgentDirectTransfersTypes.GET_AGENT_DIRECT_TRANSFERS_FILTER_DEBOUNCE,handleSearchDebounce)
}
