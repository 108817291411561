import { put, takeEvery, select } from 'redux-saga/effects';
import { CreateLanguageTextType } from '../../actions/createLanguageText/actionTypes';
import { createLanguageTextIsFetching, createLanguageTextSuccess, createLanguageTextFaild } from '../../actions/createLanguageText/action';
import { handleCreateLanguageText } from '../../../apis/service/user';
import { toastError, toastErrorPutMess, toastSuccess } from 'src/helpers/toastHelper';
import { IApplicationState } from 'src/store/IApplicationState';
import { getListLangText } from 'src/store/actions/getListLanguageText/action';

function* createLanguageTextSaga(action: any): Generator<any> {


  yield put(createLanguageTextIsFetching(true))
  const { payload, history } = action;
  try {
    const dataCreateLanguageText: any = yield handleCreateLanguageText(payload)
    if (dataCreateLanguageText?.status === 200) {
      // const filter: any = yield select((state: IApplicationState) => state.listLaguageText.filter)
      yield put(createLanguageTextSuccess(dataCreateLanguageText))
      toastSuccess('bo_create_language_text_success')
      // yield put(history.push('/dashboard/language'))
      // yield put(getListLangText(filter))
    } else {
      toastError('bo_create_language_text_fail')
      // yield put(history.push('/dashboard/language'))
    }
  } catch (error) {
    yield put(createLanguageTextFaild(error))
  }
}
export function* watchCreateLanguageText() {
  yield takeEvery(CreateLanguageTextType.CREATE_LANGUAGE_TEXT, createLanguageTextSaga)
}