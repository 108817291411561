import { AnyAction, Reducer } from 'redux';
import { initialSendNoteState, SendNoteState } from './state';
import { SendNoteTypes } from '../../actions/noteKycDetail/actionTypes';
import { toastSuccess, toastError } from '../../../helpers/toastHelper';

export const noteKycReducer: Reducer = (
  state: SendNoteState = initialSendNoteState,
  action: AnyAction
) => {

  switch (action.type) {
    case SendNoteTypes.NOTE_KYC: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SendNoteTypes.NOTE_KYC_SUCCESS: {

      toastSuccess('bo_send_note_success');
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case SendNoteTypes.NOTE_KYC_FAILD: {
      toastError(action.error);
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
