import Cookies from 'js-cookie';
import { AnyAction, Reducer } from 'redux';
import { routerConst } from 'src/routers/routerConstants';
import { LoginActionsTypes, UserLogoutActionsTypes } from '../../actions';
import { ILoginState, initialLoginState } from './state';
import { clearStorageItems } from 'src/utils/newUtils/localStorageHelper';

export const LogginReducer: Reducer = (
  state: ILoginState = initialLoginState,
  action: AnyAction
) => {
  switch (action.type) {
    case LoginActionsTypes.GET_LOGIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case LoginActionsTypes.GET_LOGIN_SUCCESS: {
      return {
        ...state,
        login: action.login,
        isFetching: false,
        error: '',
        loginSuccess: true,
      };
    }
    case LoginActionsTypes.GET_LOGIN_FAIL: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
        login: initialLoginState,
      };
    }
    case UserLogoutActionsTypes.USER_LOGOUT: {
      clearStorageItems();
      Cookies.remove('token');
      window.location.href = routerConst.Login;
      return {
        ...state,
        error: '',
        isFetching: false,
        login: initialLoginState,
        loginSuccess: false,
      };
    }
    default:
      return state;
  }
};
