import { PAGE_SIZE_DEFAULT } from "./pageSize"

const ParamsDefault = {
  pageNumber: 1,
  pageSize: 10,
  searchText: '',
  statusFilter: [],
  orderBy: '',
  orderDirection: '',
  CountryId:0
}

const ParamsKycApplicationeDefault = {
  pageNumber: 1,
  pageSize: PAGE_SIZE_DEFAULT,
  searchText: '',
  orderBy: '',
  orderDirection: '',
  stage: [1]
}

const ParamsKycByStageDefault = {
  pageNumber: 1,
  pageSize: PAGE_SIZE_DEFAULT,
  searchText: '',
  orderBy: '',
  orderDirection: '',
  stage: [2, 3]
}

const ParamsKycRiskManagementDefault = {
  pageNumber: 1,
  pageSize: PAGE_SIZE_DEFAULT,
  searchText: '',
  orderBy: '',
  orderDirection: '',
  stage: [4, 5]
}

export { ParamsDefault, ParamsKycApplicationeDefault, ParamsKycByStageDefault, ParamsKycRiskManagementDefault }

