import { AnyAction, Reducer } from 'redux';
import { RemoveNotificationState, initialRemoveNotificationState } from './state';
import { RemoveNotificationType } from '../../actions/removeNotification/actionTypes';
import { toastSuccess, toastError } from 'src/helpers/toastHelper';

export const RemoveNotificationReducer: Reducer = (
  state: RemoveNotificationState = initialRemoveNotificationState,
  action: AnyAction
) => {

  switch (action.type) {
    case RemoveNotificationType.REMOVE_NOTIFICATION: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case RemoveNotificationType.REMOVE_NOTIFICATION_SUCCESS: {
      toastSuccess('bo_remove_notification_success')
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true
      };
    }
    case RemoveNotificationType.REMOVE_NOTIFICATION_ERROR: {
      toastError(action.error)
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
