import * as Sentry from "@sentry/react";
import Request from "../../config";
import { convertObjectToParameter } from "src/utils/newUtils/convertObjectToParameter";

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_TRANSFER });
const handleAdminGetInvoiceList = async (params: any): Promise<any> => {
  const url = `/Wallet/GetAllInvoice`
  try {
    return await requestApi.post(url, params)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetInvoiceDetail = async (params: any): Promise<any> => {
  const url = `/Wallet/GetInvoiceDetail${convertObjectToParameter(params)}`
  try {
    return await requestApi.post(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleDeleteInvoice = async (params: any): Promise<any> => {
  const url = `/Wallet/DeleteInvoice?invoiceId=${params}`
  try {
    return await requestApi.post(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleCancelInvoice = async (params: any): Promise<any> => {
  const url = `/Wallet/CancelInvoice?invoiceId=${params}`
  try {
    return await requestApi.post(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleAcceptInvoice = async (params: any): Promise<any> => {
  const url = `/Wallet/AcceptInvoice?invoiceId=${params}`
  try {
    return await requestApi.post(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleDeclineInvoice = async (params: any): Promise<any> => {
  const url = `/Wallet/DeclineInvoice?invoiceId=${params}`
  try {
    return await requestApi.post(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleCreateInvoice = async (data: any): Promise<any[]> => {
  const url = `/Wallet/CreateInvoice`;
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateInvoice = async (data: any): Promise<any[]> => {
  const url = `/Wallet/UpdateInvoice`;
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export { handleAdminGetInvoiceList, handleGetInvoiceDetail, handleDeclineInvoice, handleAcceptInvoice, handleCancelInvoice, handleDeleteInvoice, handleCreateInvoice, handleUpdateInvoice };

