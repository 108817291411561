import {AgentChangePINCodeTypes} from './actionTypes';
import { IAgentChangePINCode, IAgentChangePINCodeFetching, IAgentChangePINCodeSuccess, IAgentChangePINCodeError, IAgentChangePINCodeFilter, IAgentChangePINCodeFiterDebounce } from "./interfaces";


export const agentChangePINCode = (payload:Object): IAgentChangePINCode => ({
    type: AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE,
    payload
})

export const agentChangePINCodeFetching = (isFetching:boolean) : IAgentChangePINCodeFetching => ({
    type: AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_FETCHING,
    isFetching
})

export const agentChangePINCodeSuccess = (data:any) : IAgentChangePINCodeSuccess => ({
   type: AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_SUCCESS,
   data
})

export const agentChangePINCodeError = (errorMsg:any) : IAgentChangePINCodeError => ({
    type: AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_FAILD,
    error:errorMsg
})

export const agentChangePINCodeFilter = (payload: any): IAgentChangePINCodeFilter => ({
    type: AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_FILTER,
    payload
})

export const agentChangePINCodeFilterDebounce = (payload: any): IAgentChangePINCodeFiterDebounce => ({
    type: AgentChangePINCodeTypes.AGENT_CHANGE_PIN_CODE_FILTER_DEBOUNCE,
    payload 
})
