import { AnyAction, Reducer } from 'redux';
import { IListOfferHistoryStatusState, initialListOfferHistoryStatusState } from './state';
import { GetOfferHistoryStatusTypes } from '../../actions/getOfferHistoryStatus/actionTypes';


export const GetListOfferHistoryStatusReducer: Reducer = (
  state: IListOfferHistoryStatusState = initialListOfferHistoryStatusState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.total
      };
    }
    case GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    default:
      return state;
  }
};