interface ICreateAndEditAdminBO {
  data: any;
  status: number;
  message: string;
}

export interface ICreateAndEditAdminBOState {
  data: ICreateAndEditAdminBO;
  isFetching: boolean;
  successCount: number;
}

export const initialCreateAndEditAdminBOState: ICreateAndEditAdminBOState = {
  data: {
    data: false,
    status: 0,
    message: '',
  },
  isFetching: false,
  successCount: 0,
};
