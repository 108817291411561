import { ManualPayment } from 'src/models/manualPayment';
import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApiTransfer = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});
const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_API });

interface ExportFile {
  orderBy: string;
  orderDirection: string;
  pageNumber: number;
  pageSize: number;
  searchText: string;
}

interface TransactionManualPayment {
  amount?: number;
  notes?: string;
  transactionId?: number;
}

export interface ITransactionNotes {
  transId: string;
  note: string;
}

const handleGetTransactionSellBuySearch = async (
  data: Object
): Promise<any[]> => {
  const url = `/Transaction/sellbuysearch`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleOfferSearch = async (data: Object): Promise<any[]> => {
  const url = `/Offer/search`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteOffer = async (id: number): Promise<any> => {
  const url = `/Offer/deletebyadmin/${id}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCancelOfferService = async (id: number): Promise<any> => {
  const url = `/Offer/cacel/${id}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCancelOfferAdmin = async (id: number): Promise<any> => {
  const url = `/Offer/cancelbyadmin/${id}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCancelOffer = async (id: number): Promise<any> => {
  const url = `/Offer/cancel/${id}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetOfferHistory = async (id: number): Promise<any[]> => {
  const url = `/Offer/history/manualpayment/${id}`;
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleManualPayment = async (data: ManualPayment): Promise<any[]> => {
  const url = `/Offer/Transaction/search`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleExportFile = async (data: ExportFile): Promise<any[]> => {
  const urlExportFile = '/Transaction/exportfile';
  try {
    return await requestApiTransfer.post(urlExportFile, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleTransactionManualPayment = async (
  data: TransactionManualPayment
): Promise<any[]> => {
  const url = '/Offer/transaction/manualPayment';
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateNote = async (data: ITransactionNotes): Promise<any[]> => {
  const url = `/Transaction/UpdateNote`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetNote = async (transactionId: any): Promise<any[]> => {
  const url = `/Transaction/GetNote?transId=${transactionId}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetTransactionsByAgent = async (data: any): Promise<any[]> => {
  const url = `/LatestTransactions/search`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetTransactionDetailByAgent = async (data: any): Promise<any[]> => {
  const url = `/LatestTransactions/offer/${data}`;
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentGetDirectDetail = async (data: any): Promise<any[]> => {
  const url = `/LatestTransactions/direct/${data}`;
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentGetAdminTrxDetail = async (data: any): Promise<any[]> => {
  const url = `/LatestTransactions/admin/${data}`;
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentCancelTransaction = async (data: any): Promise<any[]> => {
  const url = `/Transaction/cancel`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentTransferredTransaction = async (data: any): Promise<any[]> => {
  const url = `/Transaction/transferred?TransId=${data?.tranId}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentSubmitBank = async (data: any): Promise<any[]> => {
  const url = `/Transaction/submitBankAccount`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleAgentTransactionConfirm = async (data: any): Promise<any[]> => {
  const url = `/Transaction/confirm?TransId=${data?.tranId}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetTransactionsFilterTypes = async (): Promise<any[]> => {
  const url = `/LatestTransactions/filter/types`;
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetTransactionsFilterStatuses = async (): Promise<any[]> => {
  const url = `/LatestTransactions/filter/statuses`;
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAllTransactionsByAdmin = async (data: any): Promise<any[]> => {
  const url = `/LatestTransactions/SearchForAdmin`;
  try {
    return await requestApiTransfer.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetDirectTransactionDetail = async (data: any): Promise<any[]> => {
  const url = `/LatestTransactions/DirectTransaction?id=${data?.id}&userId=${data?.userId}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAdminTransactionUser = async (data: any): Promise<any[]> => {
  const url = `/LatestTransactions/AdminTransaction?id=${data}`;
  try {
    return await requestApiTransfer.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUploadImage = async (data: any): Promise<any> => {
  const url = `/Gallery/UploadTransactionEvidence`;
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleRequestEvidence = async (data: any): Promise<any> => {
  const url = `/Transaction/RequestEvidence?transId=${data}`;
  try {
    return await requestApiTransfer.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleUpdateNote,
  handleGetNote,
  handleDeleteOffer,
  handleGetTransactionSellBuySearch,
  handleGetOfferHistory,
  handleManualPayment,
  handleExportFile,
  handleTransactionManualPayment,
  handleOfferSearch,
  handleGetTransactionsByAgent,
  handleGetTransactionDetailByAgent,
  handleAgentCancelTransaction,
  handleAgentTransferredTransaction,
  handleAgentSubmitBank,
  handleAgentTransactionConfirm,
  handleGetTransactionsFilterTypes,
  handleGetAllTransactionsByAdmin,
  handleGetDirectTransactionDetail,
  handleGetAdminTransactionUser,
  handleUploadImage,
  handleRequestEvidence,
  handleAgentGetDirectDetail,
  handleAgentGetAdminTrxDetail,
  handleGetTransactionsFilterStatuses,
  handleCancelOfferService,
  handleCancelOfferAdmin,
  handleCancelOffer,
};
