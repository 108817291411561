import {AnyAction, Reducer} from 'redux';
import { IListBankAccountAgentState, initialListBankAccountAgentState } from './state';
import { GetDataBankAccountAgent } from 'src/store/actions/bankAccountByAgent/getDataBankAccountByAgent';

export const GetDataBankAccountAgentReducer: Reducer = (
  state: IListBankAccountAgentState = initialListBankAccountAgentState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetDataBankAccountAgent.GET_DATA_BANK_AGENT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetDataBankAccountAgent.GET_DATA_BANK_AGENT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetDataBankAccountAgent.GET_DATA_BANK_AGENT_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};