import { ListParams } from 'src/models/common';
import { GetAllTestType } from './actionTypes';
import { 
    IFetchingGetAllTest,
    IGetAllTest,
    IGetAllTestError,
    IGetAllTestSuccess,
    IGetListFiterTest,
    IGetListFiterDebounceTest
} from './interfaces';


export const getAllTestIsFetching = (isFetching:boolean) : IFetchingGetAllTest => ({
    type:GetAllTestType.GET_ALL_TEST_FETCHING,
    isFetching
})

export const getAllTestBO = (payload:ListParams) : IGetAllTest => ({
    type:GetAllTestType.GET_ALL_TEST,
    payload,
})

export const getAllTestSuccess = (data:any) : IGetAllTestSuccess => ({
    type:GetAllTestType.GET_ALL_TEST_SUCCESS,
    data
})

export const getAllTestFaild = (error:any) : IGetAllTestError => ({
    type:GetAllTestType.GET_ALL_TEST_FAILD,
    error
})

export const setFilterListTest = (payload: any): IGetListFiterTest => ({
    type:GetAllTestType.GET_LIST_FILTER_TEST,
    payload
})

export const setFilterDebounceTest = (payload: any): IGetListFiterDebounceTest => ({
    type: GetAllTestType.GET_LIST_FILTER_DEBOUNCE_TEST,
    payload 
})