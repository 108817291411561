import { ListParams } from "src/models/common";

interface IDataGetAgentCryptoSetting {
  data: any;
  error: any;
  success: boolean;
}

export interface IGetAgentCryptoSettingState {
  data: IDataGetAgentCryptoSetting;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
  countSuccess: number;
}

export const initialGetAgentCryptoSettingState: IGetAgentCryptoSettingState = {
  data: {
    data: null,
    error: null,
    success: false,
  },
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
  countSuccess: 0,
};
