import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { ListParams } from "src/models/common";

export interface IDataListCurrency {
    status: number;
    data: {
        items: any[],
        totalCount: number;
    };
    message: string;
}

export interface IDataListCurrencyState {
    data: IDataListCurrency,
    filter: ListParams,
    pagination: any,
    isFetching: boolean
}

export const initialDataListCurrencyState: IDataListCurrencyState = {
    data: {
        data: {
            items: [],
            totalCount: 0
        },
        status: 0,
        message: ''
    },
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        currency: []
    },
    pagination: {
        totalCount: 0,
    },
    isFetching: false
}