import {GetUserKYCPriorityHistoryTypes} from './actionTypes';
import { IGetUserKYCPriorityHistory, IGetUserKYCPriorityHistoryFetching, IGetUserKYCPriorityHistorySuccess, IGetUserKYCPriorityHistoryError, IGetUserKYCPriorityHistoryFilter, IGetUserKYCPriorityHistoryFiterDebounce } from "./interfaces";


export const getUserKYCPriorityHistory = (payload:Object): IGetUserKYCPriorityHistory => ({
    type: GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY,
    payload
})

export const getUserKYCPriorityHistoryFetching = (isFetching:boolean) : IGetUserKYCPriorityHistoryFetching => ({
    type: GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_FETCHING,
    isFetching
})

export const getUserKYCPriorityHistorySuccess = (data:any) : IGetUserKYCPriorityHistorySuccess => ({
   type: GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_SUCCESS,
   data
})

export const getUserKYCPriorityHistoryError = (errorMsg:any) : IGetUserKYCPriorityHistoryError => ({
    type: GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_FAILD,
    error:errorMsg
})

export const getUserKYCPriorityHistoryFilter = (payload: any): IGetUserKYCPriorityHistoryFilter => ({
    type: GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_FILTER,
    payload
})

export const getUserKYCPriorityHistoryFilterDebounce = (payload: any): IGetUserKYCPriorityHistoryFiterDebounce => ({
    type: GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_FILTER_DEBOUNCE,
    payload 
})
