import {CreateAdminTypes} from './actionTypes';
import {
    ICreateAdmin,
    ICreateAdminIsFetching,
    ICreateAdminSuccess,
    ICreateAdminFaild
} from './interfaces';
import { History } from 'history';

export const createAdminAtFetching = (isFetching:boolean) : ICreateAdminIsFetching  => ({
    type: CreateAdminTypes.CREATE_ADMIN_FETCHING,
    isFetching
})

export const createAdmin = (payload: Object, history: History) : ICreateAdmin => ({
    type: CreateAdminTypes.CREATE_ADMIN,
    payload,
    history,
})

export const createAdminSuccess = (data:any) : ICreateAdminSuccess => ({
    type: CreateAdminTypes.CREATE_ADMIN_SUCCESS,
    data
})

export const createAdminFaild = (error:any) : ICreateAdminFaild => ({
    type: CreateAdminTypes.CREATE_ADMIN_FAILD,
    error
})
