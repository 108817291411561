import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GenerateCode2FATypes } from '../../actions/generateCode2FA';
import { generateCode2FAFetching, generateCode2FASuccess, generateCode2FAError, generateCode2FAFilter } from '../../actions/generateCode2FA/action';
import { handleGenerateCode2FA } from 'src/apis/service/Agent';

function* generateCode2FASaga(action: any): Generator<any> {
  yield put(generateCode2FAFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleGenerateCode2FA()
    if (data && data?.status === 200) {
      yield put(generateCode2FASuccess(data))
    } else {
      yield put(generateCode2FAError('error'))
    }
  } catch (error) {
    yield put(generateCode2FAError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(generateCode2FAFilter(action.payload));
}
export function* watchGenerateCode2FA() {
  yield takeEvery(GenerateCode2FATypes.GENERATE_CODE_2FA, generateCode2FASaga)
  yield debounce(500, GenerateCode2FATypes.GENERATE_CODE_2FA_FILTER_DEBOUNCE, handleSearchDebounce)
}
