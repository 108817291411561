import { GetAnnouncementsCategoryType } from './actionTypes';
import { IFetchingGetAnnouncementsCategory, IGetAnnouncementsCategory, IGetAnnouncementsCategoryError, IGetAnnouncementsCategorySuccess, IGetListFilterAnnouncementsCategory, IGetListFilterDebounceAnnouncementsCategory } from './interfaces';

export const getAnnouncementsCategoryIsFetching = (isFetching:boolean) : IFetchingGetAnnouncementsCategory => ({
    type: GetAnnouncementsCategoryType.GET_ANNOUNCEMENTS_CATEGORY_FETCHING,
    isFetching
})

export const getAnnouncementsCategory = (payload: any) : IGetAnnouncementsCategory => ({
    type: GetAnnouncementsCategoryType.GET_ANNOUNCEMENTS_CATEGORY,
    payload
})

export const getAnnouncementsCategorySuccess = (data:any) : IGetAnnouncementsCategorySuccess => ({
    type: GetAnnouncementsCategoryType.GET_ANNOUNCEMENTS_CATEGORY_SUCCESS,
    data
})

export const getAnnouncementsCategoryFaild = (error:any) : IGetAnnouncementsCategoryError => ({
    type: GetAnnouncementsCategoryType.GET_ANNOUNCEMENTS_CATEGORY_ERROR,
    error
})

export const setFilterAnnouncementsCategory = (payload: any): IGetListFilterAnnouncementsCategory => ({
    type: GetAnnouncementsCategoryType.GET_LIST_FILTER_ANNOUNCEMENTS_CATEGORY,
    payload
})

export const setFilterDebounceAnnouncementsCategory = (payload: any): IGetListFilterDebounceAnnouncementsCategory => ({
    type: GetAnnouncementsCategoryType.GET_LIST_FILTER_DEBOUNCE_ANNOUNCEMENTS_CATEGORY,
    payload 
})