interface IDataOfferDetail {
  data: any;
  message: string;
  status: number;
}

export interface IOfferDetailState {
    data: IDataOfferDetail;
    isFetching: boolean,
}

export const initialOfferDetailState: IOfferDetailState = {
    data: {
        data: {},
        status: 0,
        message: ''
    },
    isFetching: false
}

