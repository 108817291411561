import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetKYCSystemStatus } from 'src/apis/service/Priority';
import { GetKYCSystemStatusTypes } from '../../actions/getKYCSystemStatus';
import {
  getKYCSystemStatusError, getKYCSystemStatusFetching, getKYCSystemStatusFilter, getKYCSystemStatusSuccess
} from '../../actions/getKYCSystemStatus/action';

function* getKYCSystemStatusSaga(action: any): Generator<any> {
  yield put(getKYCSystemStatusFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetKYCSystemStatus(payload);
    if (data && data?.status === 200) {
      yield put(getKYCSystemStatusSuccess(data));
    } else {
      yield put(getKYCSystemStatusError('error'));
    }
  } catch (error) {
    yield put(getKYCSystemStatusError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getKYCSystemStatusFilter(action.payload));
}
export function* watchGetKYCSystemStatus() {
  yield takeEvery(
    GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS,
    getKYCSystemStatusSaga
  );
  yield debounce(
    500,
    GetKYCSystemStatusTypes.GETKYC_SYSTEM_STATUS_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
