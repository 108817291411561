import {call, put, takeEvery} from 'redux-saga/effects';
import { handleGetOfferHistory } from 'src/apis/service/P2PTransaction';
import { getManualPaymentHistoryAtFetching, getManualPaymentHistoryFaild, getManualPaymentHistorySuccess, GetManualPaymentHistoryTypes } from 'src/store/actions';

function* getManualPaymentHistorySaga(action:any):Generator<any> {
    yield put(getManualPaymentHistoryAtFetching(true))
    const {payload} = action;
    try {
      const dataManualPaymentHistory:any = yield call(handleGetOfferHistory, payload)
      if(dataManualPaymentHistory && dataManualPaymentHistory?.status === 200){
        yield put(getManualPaymentHistorySuccess(dataManualPaymentHistory))
      } else {
        yield put(getManualPaymentHistoryFaild('error'))
      }
    } catch (error) {
        yield put(getManualPaymentHistoryFaild(error))
    }
}

export function* watchGetListManualPaymentHistory() {
  yield takeEvery(GetManualPaymentHistoryTypes.GET_MANUAL_PAYMENT_HISTORY, getManualPaymentHistorySaga)
}