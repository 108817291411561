import { ListParams } from "src/models/common";
import { GetListUserPriorityType } from "./actionTypes";
import { IGetListUserPriority, IGetListUserPriorityFetching, IGetListUserPrioritySuccess, IGetListFiter, IGetListUserPriorityError, IGetListFiterDebounce } from "./interfaces";

  
export const getListUserPriority = (payload: ListParams): IGetListUserPriority => ({
    type: GetListUserPriorityType.GET_LIST_USER_PRIORITY,
    payload
})

export const getListUserPriorityFetching = (isFetching:boolean) : IGetListUserPriorityFetching => ({
    type: GetListUserPriorityType.GET_LIST_USER_PRIORITY_FETCHING,
    isFetching
})

export const getListUserPrioritySuccess = (data:any) : IGetListUserPrioritySuccess => ({
   type: GetListUserPriorityType.GET_LIST_USER_PRIORITY_SUCCESS,
   data
})

export const getListUserPriorityError = (errorMsg:any) : IGetListUserPriorityError => ({
    type: GetListUserPriorityType.GET_LIST_USER_PRIORITY_FAILD,
    error:errorMsg
})

export const setFilter = (payload: ListParams): IGetListFiter => ({
    type: GetListUserPriorityType.GET_LIST_FILTER,
    payload
})

export const setFilterDebounce = (payload: any): IGetListFiterDebounce => ({
    type: GetListUserPriorityType.GET_LIST_FILTER_DEBOUNCE,
    payload 
})