import { ListParams } from "src/models/common";

interface IDataGetKYCSystemStatus {
  data:any;
  error:any;
  success:boolean;
}

export interface IGetKYCSystemStatusState {
    data:IDataGetKYCSystemStatus;
    isFetching:boolean;
}

export const initialGetKYCSystemStatusState: IGetKYCSystemStatusState = {
    data:{
        data:null,
        error:null,
        success:false
    },
    isFetching:false,
}


