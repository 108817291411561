import * as Sentry from "@sentry/react";
import { ListParams } from '../../../models/common';
import Request from "../../config";

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_TRANSFER });

interface OfferNote {
  offerId: number,
  userId: string,
  note: string
}

const handleGetP2PExchangeOffers = async (payload: ListParams): Promise<any[]> => {
  const url = `/P2PExchange/AdminOfferSearch`;
  try {
    return await requestApi.post(url, payload)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetP2PExchangeOfferDetail = async (idOffer: number): Promise<any[]> => {
  const url = `/P2PExchange/AdminOfferDetails?id=${idOffer}`;
  try {
    return await requestApi.get(url)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetP2PExchangeTransactions = async (payload: ListParams): Promise<any[]> => {
  const url = `/P2PExchange/AdminTransactionSearch`;
  try {
    return await requestApi.post(url, payload)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetP2PExchangeTransactionsAgent = async (payload: ListParams): Promise<any[]> => {
  const url = `/P2PExchange/AgentTransactions`;
  try {
    return await requestApi.post(url, payload)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetP2PExchangeOfferNote = async (data: OfferNote): Promise<any[]> => {
  const url = `/P2PExchange/AdminEditOfferNote`;
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetP2PExchangeOfferCancel = async (id: number): Promise<any[]> => {
  const url = `/P2PExchange/DeleteOfferByAdmin?id=${id}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetP2PExchangeOfferBlock = async (data: any): Promise<any[]> => {
  const url = '/P2PExchange/AdminBlockOffer';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetP2PExchangeOfferUnBlock = async (data: any): Promise<any[]> => {
  const url = '/P2PExchange/AdminUnblockOffer';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetP2PExchangeHistoryOffer = async (data: any): Promise<any[]> => {
  const url = '/P2PExchange/AdminP2PHistoryOffer';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetP2PExchangeHistoryTransaction = async (data: any): Promise<any[]> => {
  const url = '/P2PExchange/AdminP2PTransactionHistories';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateExchangeOffer = async (data: any): Promise<any[]> => {
  const url = '/P2PExchange/CreateOffer';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleEditExchangeOffer = async (data: any): Promise<any[]> => {
  const url = '/P2PExchange/EditOffer';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteExchangeOffer = async (payload: any): Promise<any[]> => {
  const url = `/P2PExchange/DeleteOffer?id=${payload.offerId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetP2PExchangeOffersAgent = async (payload: ListParams): Promise<any[]> => {
  const url = `/P2PExchange/GetAllOfferExchange`;
  try {
    return await requestApi.post(url, payload)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetP2PExchangeRankAndScore = async (listparam: ListParams): Promise<any[]> => {
  const url = '/P2PExchange/GetAgentRank'
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handlePostP2PExchangeRankAndScore = async (listparam: ListParams): Promise<any[]> => {
  const url = '/P2PExchange/GetAgentRank'
  try {
    return await requestApi.post(url, listparam);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAllRankConfig = async (): Promise<any[]> => {
  const url = '/P2PExchange/GetAllRankConfig'
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetScoreHistory = async (payload: any) => {
  const url = `/Admin/GetScoreHistory?agentId=${payload.agentId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetP2PExchangeHistoryAgent = async (payload: any): Promise<any[]> => {
  const url = `/P2PExchange/History`;
  try {
    return await requestApi.post(url, payload)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handlePostCreateExchangeTransaction = async (payload: ListParams): Promise<any[]> => {
  const url = `/P2PExchange/CreateExchangeTransaction`;
  try {
    return await requestApi.post(url, payload)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export {
  handleGetP2PExchangeOffers,
  handleGetP2PExchangeOfferDetail,
  handleGetP2PExchangeOfferNote,
  handleGetP2PExchangeOfferCancel,
  handleGetP2PExchangeOfferBlock,
  handleGetP2PExchangeOfferUnBlock,
  handleGetP2PExchangeTransactions,
  handleGetP2PExchangeHistoryOffer,
  handleGetP2PExchangeHistoryTransaction,
  handleGetP2PExchangeTransactionsAgent,
  handleCreateExchangeOffer,
  handleEditExchangeOffer,
  handleDeleteExchangeOffer,
  handleGetP2PExchangeOffersAgent,
  handleGetP2PExchangeRankAndScore,
  handlePostP2PExchangeRankAndScore,
  handleGetAllRankConfig,
  handleGetP2PExchangeHistoryAgent,
  handleGetScoreHistory,
  handlePostCreateExchangeTransaction
};

