import { ListParams } from 'src/models/common';
import { GetAllCurrencyType } from './actionTypes';
import { 
    IFetchingGetAllCurrency,
    IGetAllCurrency,
    IGetAllCurrencyError,
    IGetAllCurrencySuccess,
    IGetListFiterCurrency,
    IGetListFiterDebounceCurrency
} from './interfaces';


export const getAllCurrencyIsFetching = (isFetching:boolean) : IFetchingGetAllCurrency => ({
    type:GetAllCurrencyType.GET_ALL_CURRENCY_FETCHING,
    isFetching
})

export const getAllCurrencyBO = (payload:ListParams) : IGetAllCurrency => ({
    type:GetAllCurrencyType.GET_ALL_CURRENCY,
    payload,
})

export const getAllCurrencySuccess = (data:any) : IGetAllCurrencySuccess => ({
    type:GetAllCurrencyType.GET_ALL_CURRENCY_SUCCESS,
    data
})

export const getAllCurrencyFaild = (error:any) : IGetAllCurrencyError => ({
    type:GetAllCurrencyType.GET_ALL_CURRENCY_FAILD,
    error
})

export const setFilterListCurrency = (payload: any): IGetListFiterCurrency => ({
    type:GetAllCurrencyType.GET_LIST_FILTER_CURRENCY,
    payload
})

export const setFilterDebounceCurrency = (payload: any): IGetListFiterDebounceCurrency => ({
    type: GetAllCurrencyType.GET_LIST_FILTER_DEBOUNCE_CURRENCY,
    payload 
})