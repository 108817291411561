import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetKYCSystemStatusHistory } from 'src/apis/service/Priority';
import { GetKYCSystemStatusHistoryTypes } from '../../actions/getKYCSystemStatusHistory';
import { getKYCSystemStatusHistoryError, getKYCSystemStatusHistoryFetching, getKYCSystemStatusHistoryFilter, getKYCSystemStatusHistorySuccess } from '../../actions/getKYCSystemStatusHistory/action';

function* getKYCSystemStatusHistorySaga():Generator<any> {
    yield put(getKYCSystemStatusHistoryFetching(true))
    try {
      const data:any = yield handleGetKYCSystemStatusHistory()
      if(data && data?.status === 200){
        yield put(getKYCSystemStatusHistorySuccess(data))
      } else {
        yield put(getKYCSystemStatusHistoryError('error'))
      }
    } catch (error) {
        yield put(getKYCSystemStatusHistoryError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getKYCSystemStatusHistoryFilter(action.payload));
}
export function* watchGetKYCSystemStatusHistory() {
   yield takeEvery(GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY, getKYCSystemStatusHistorySaga)
   yield debounce(500,GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_FILTER_DEBOUNCE,handleSearchDebounce)
}
