import { CreateOfferSellOrBuy } from "./actionTypes";
import {
  IcreateOfferBuyOrSell,
  IcreateOfferBuyOrSellFaild,
  IcreateOfferBuyOrSellFetching,
  IcreateOfferBuyOrSellSuccess,
} from "./interfaces";

export const createOfferSellOrBuyAtFetching = (
  isFetching: boolean
): IcreateOfferBuyOrSellFetching => ({
  type: CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY_IS_FETCHING,
  isFetching,
});

export const createOfferSellOrBuy = (
  payload: Object,
  isP2POfferBuy: boolean,
  closeModal: any,
  getDataAfterSuccess: any
): IcreateOfferBuyOrSell => ({
  type: CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY,
  payload,
  isP2POfferBuy,
  closeModal,
  getDataAfterSuccess
});

export const createOfferSellOrBuySuccess = (
  data: any
): IcreateOfferBuyOrSellSuccess => ({
  type: CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY_SUCCESS,
  data,
});

export const createOfferSellOrBuyFaild = (
  error: any
): IcreateOfferBuyOrSellFaild => ({
  type: CreateOfferSellOrBuy.CREATE_OFFER_SELL_OR_BUY_FAILD,
  error,
});
