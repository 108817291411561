import { ListParams } from "src/models/common";

export interface IWalletInputState {
  data: any;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialWalletInputState: IWalletInputState = {
  data: [],
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
