import { GetCryptoWalletGetOverviewType } from './actionTypes';
import { IFetchingGetCryptoWalletGetOverview, IGetCryptoWalletGetOverview, IGetCryptoWalletGetOverviewError, IGetCryptoWalletGetOverviewSuccess, IGetListFilterCryptoWalletGetOverview } from './interfaces';

export const getCryptoWalletGetOverviewIsFetching = (isFetching:boolean) : IFetchingGetCryptoWalletGetOverview => ({
    type: GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW_FETCHING,
    isFetching
})

export const getCryptoWalletGetOverview = (payload: object) : IGetCryptoWalletGetOverview => ({
    type: GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW,
    payload
})

export const getCryptoWalletGetOverviewSuccess = (data:any) : IGetCryptoWalletGetOverviewSuccess => ({
    type: GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW_SUCCESS,
    data
})

export const getCryptoWalletGetOverviewFaild = (error:any) : IGetCryptoWalletGetOverviewError => ({
    type: GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW_ERROR,
    error
})

export const setFilterCryptoWalletGetOverview = (payload: any): IGetListFilterCryptoWalletGetOverview => ({
    type: GetCryptoWalletGetOverviewType.GET_LIST_FILTER_CRYPTO_WALLET_GET_OVERVIEW,
    payload
})