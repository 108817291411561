import Request from "../../config";
import * as Sentry from "@sentry/react";
import { ListParams } from "src/models/common";

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_TRANSFER});
const requestApiUser = new Request({url: process.env.REACT_APP_BASE_URL_ACCOUNT});

interface UpdatePriority {
    firstPriority: number
}

interface SetAgentRank {
    id: number,
    agentId: string,
    agentPhone: string,
    rank: number
}

interface DeleteAgentRank {
  id:number
}
interface UpdateUserRole {
  userId: string,
  roleID: number
}
const getPriority = async () : Promise<any> => {
    try {
      return await requestApi.get(`/Priority/GetSettings`)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const updatePriority = async (data:UpdatePriority) : Promise<any> => {
    try {
      return await requestApi.post(`/Priority/UpdateSettings`,data)
    } catch (error) {
      Sentry.captureException(error);
      throw error
    }
}

const adminP2PGetAgentRank = async (data:ListParams) : Promise<any> => {
  try {
    return await requestApi.post(`/P2PExchange/AdminP2PGetAgentRank`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const adminP2PSetAgentRank = async (data:SetAgentRank) : Promise<any> => {
  try {
    return await requestApi.post(`/P2PExchange/AdminP2PSetAgentRank`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const  adminP2PDeleteAgentRank = async (data:DeleteAgentRank) : Promise<any> => {
  try {
    return await requestApi.post(`/P2PExchange/AdminP2PDeleteAgentRank?id=${data.id}`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const setSupperAgent = async (data:UpdateUserRole) : Promise<any> => {
  try {
    return await requestApiUser.post(`/User/UpdateUserRoles`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleAdminP2PSetAgentScore = async (data:any) : Promise<any> => {
  try {
    return await requestApi.post(`/P2PExchange/AdminP2PSetAgentScore`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleSetUserKYCPriority = async (data:any) : Promise<any> => {
  try {
    return await requestApiUser.post(`/User/SetPriority`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleSetKYCPriorityAllUser = async (data:any) : Promise<any> => {
  try {
    return await requestApiUser.post(`/User/SetKYCConfig`,data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetUserKYCPriorityHistory = async (data:any) : Promise<any> => {
  try {
    return await requestApiUser.get(`/User/GetKYCChangeHistoryByUserId?userId=${data.userId}`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetKYCSystemStatus = async (data:any) : Promise<any> => {
  try {
    return await requestApiUser.get(`/User/GetKYCSystemStatus`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const handleGetKYCSystemStatusHistory = async () : Promise<any> => {
  try {
    return await requestApiUser.get(`/User/GetKYCSystemStatusHistory`)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export {
    getPriority,
    updatePriority,
    adminP2PDeleteAgentRank,
    adminP2PGetAgentRank,
    adminP2PSetAgentRank,
    setSupperAgent,
    handleAdminP2PSetAgentScore,
    handleSetUserKYCPriority,
    handleSetKYCPriorityAllUser,
    handleGetUserKYCPriorityHistory,
    handleGetKYCSystemStatus,
    handleGetKYCSystemStatusHistory
}

