export interface ExportDirectTransfer  {
    data:any,
    errors:any,
    success:any
}

export interface ExportDirectTransferState {
    data: ExportDirectTransfer,
    isFetching:boolean
}

export const initialExportDirectTransferState: ExportDirectTransferState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}