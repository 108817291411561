import {TopupWalletListSelectTypes} from './actionTypes';
import { ITopupWalletListSelect, ITopupWalletListSelectFetching, ITopupWalletListSelectSuccess, ITopupWalletListSelectError, ITopupWalletListSelectFilter, ITopupWalletListSelectFiterDebounce } from "./interfaces";


export const topupWalletListSelect = (payload:Object): ITopupWalletListSelect => ({
    type: TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT,
    payload
})

export const topupWalletListSelectFetching = (isFetching:boolean) : ITopupWalletListSelectFetching => ({
    type: TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT_FETCHING,
    isFetching
})

export const topupWalletListSelectSuccess = (data:any) : ITopupWalletListSelectSuccess => ({
   type: TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT_SUCCESS,
   data
})

export const topupWalletListSelectError = (errorMsg:any) : ITopupWalletListSelectError => ({
    type: TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT_FAILD,
    error:errorMsg
})

export const topupWalletListSelectFilter = (payload: any): ITopupWalletListSelectFilter => ({
    type: TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT_FILTER,
    payload
})

export const topupWalletListSelectFilterDebounce = (payload: any): ITopupWalletListSelectFiterDebounce => ({
    type: TopupWalletListSelectTypes.TOPUP_WALLET_LIST_SELECT_FILTER_DEBOUNCE,
    payload 
})
