import { AnyAction, Reducer } from 'redux';
import { initialSupportedCryptoCurrenciesData, supportedCryptoCurrencyObjectType } from './state';
import { supportedCryptoCurrenciesActionTypes } from '../../actions';

export const SupportedCryptoCurrenciesReducer: Reducer = (
  state: supportedCryptoCurrencyObjectType[] = initialSupportedCryptoCurrenciesData,
  action: AnyAction
) => {
  switch (action.type) {
    case supportedCryptoCurrenciesActionTypes.GET_SUPPORTED_CRYPTO_CURRENCIES: {
      return state
    }
    case supportedCryptoCurrenciesActionTypes.SET_SUPPORTED_CRYPTO_CURRENCIES: {
      return action.data
    }
    default: {
      return state;
    }
  }
};
