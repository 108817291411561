import { GetInvoiceDetailTypes } from './actionTypes';
import { IGetInvoiceDetail, IGetInvoiceDetailFetching, IGetInvoiceDetailSuccess, IGetInvoiceDetailError, IGetInvoiceDetailFilter, IGetInvoiceDetailFiterDebounce, IGetInvoiceDetailClean } from "./interfaces";


export const getInvoiceDetail = (payload: Object): IGetInvoiceDetail => ({
    type: GetInvoiceDetailTypes.GET_INVOICE_DETAIL,
    payload
})

export const getInvoiceDetailFetching = (isFetching: boolean): IGetInvoiceDetailFetching => ({
    type: GetInvoiceDetailTypes.GET_INVOICE_DETAIL_FETCHING,
    isFetching
})

export const getInvoiceDetailSuccess = (data: any): IGetInvoiceDetailSuccess => ({
    type: GetInvoiceDetailTypes.GET_INVOICE_DETAIL_SUCCESS,
    data
})

export const getInvoiceDetailError = (errorMsg: any): IGetInvoiceDetailError => ({
    type: GetInvoiceDetailTypes.GET_INVOICE_DETAIL_FAILD,
    error: errorMsg
})

export const getInvoiceDetailFilter = (payload: any): IGetInvoiceDetailFilter => ({
    type: GetInvoiceDetailTypes.GET_INVOICE_DETAIL_FILTER,
    payload
})

export const getInvoiceDetailFilterDebounce = (payload: any): IGetInvoiceDetailFiterDebounce => ({
    type: GetInvoiceDetailTypes.GET_INVOICE_DETAIL_FILTER_DEBOUNCE,
    payload
})

export const getInvoiceDetailClean = (): IGetInvoiceDetailClean => ({
    type: GetInvoiceDetailTypes.GET_INVOICE_DETAIL_CLEAN,
})
