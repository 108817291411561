import { ListParams } from 'src/models/common';
import { GetCryptoWalletReceiveStatisticalType } from './actionTypes';
import { IFetchingGetCryptoWalletReceiveStatistical, IGetCryptoWalletReceiveStatistical, IGetCryptoWalletReceiveStatisticalError, IGetCryptoWalletReceiveStatisticalSuccess, IGetListFilterCryptoWalletReceiveStatistical } from './interfaces';

export const getCryptoWalletReceiveStatisticalIsFetching = (isFetching:boolean) : IFetchingGetCryptoWalletReceiveStatistical => ({
    type: GetCryptoWalletReceiveStatisticalType.GET_CRYPTO_WALLET_RECEIVE_STATISTICAL_FETCHING,
    isFetching
})

export const getCryptoWalletReceiveStatistical = (payload: object) : IGetCryptoWalletReceiveStatistical => ({
    type: GetCryptoWalletReceiveStatisticalType.GET_CRYPTO_WALLET_RECEIVE_STATISTICAL,
    payload
})

export const getCryptoWalletReceiveStatisticalSuccess = (data:any) : IGetCryptoWalletReceiveStatisticalSuccess => ({
    type: GetCryptoWalletReceiveStatisticalType.GET_CRYPTO_WALLET_RECEIVE_STATISTICAL_SUCCESS,
    data
})

export const getCryptoWalletReceiveStatisticalFaild = (error:any) : IGetCryptoWalletReceiveStatisticalError => ({
    type: GetCryptoWalletReceiveStatisticalType.GET_CRYPTO_WALLET_RECEIVE_STATISTICAL_ERROR,
    error
})

export const setFilterCryptoWalletReceiveStatistical = (payload: ListParams): IGetListFilterCryptoWalletReceiveStatistical => ({
    type: GetCryptoWalletReceiveStatisticalType.GET_LIST_FILTER_CRYPTO_WALLET_RECEIVE_STATISTICAL,
    payload
})