import { DeactivateUserType } from './actionTypes';
import { IDeactivateUser, IDeactivateUserSuccess, IDeactivateUserError, IFetchingDeactivateUser } from './interfaces';


export const deactivateUserIsFetching = (isFetching:boolean) : IFetchingDeactivateUser => ({
    type: DeactivateUserType.DEACTIVATE_USER_FETCHING,
    isFetching
})

export const deactivateUser = (payload:Object) : IDeactivateUser => ({
    type: DeactivateUserType.DEACTIVATE_USER,
    payload
})

export const deactivateUserSuccess = (data:any) : IDeactivateUserSuccess => ({
    type: DeactivateUserType.DEACTIVATE_USER_SUCCESS,
    data
})

export const deactivateUserFaild = (error:any) : IDeactivateUserError => ({
    type: DeactivateUserType.DEACTIVATE_USER_ERROR,
    error
})