import { put, call, takeEvery } from 'redux-saga/effects';
import { getHistory } from 'src/apis/service/Agency';
import { getAgencyStatusFaild, getAgencyStatusIsFetching, getAgencyStatusSuccess, GetAgencyStatusType } from 'src/store/actions';

function* getListAgencyStatusSaga(action: any): Generator<any> {
    yield put(getAgencyStatusIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(getHistory, payload)    
      if(data && data?.status === 200){
        const newData = [{ ...data, userId: payload.userId }];
        yield put(getAgencyStatusSuccess(newData));
      } else {
        yield put(getAgencyStatusFaild('error'))
      }
    } catch (error) {
        yield put(getAgencyStatusFaild(error))
    }
}

export function* watchGetListAgencyStatus() {
   yield takeEvery(GetAgencyStatusType.GET_AGENCY_STATUS , getListAgencyStatusSaga)
}