import { GetCryptoWalletSendHistoryType } from './actionTypes';
import { IFetchingGetCryptoWalletSendHistory, IGetCryptoWalletSendHistory, IGetCryptoWalletSendHistoryError, IGetCryptoWalletSendHistorySuccess, IGetListFilterCryptoWalletSendHistory } from './interfaces';
import { ListParams } from '../../../models/common';

export const getCryptoWalletSendHistoryIsFetching = (isFetching:boolean) : IFetchingGetCryptoWalletSendHistory => ({
    type: GetCryptoWalletSendHistoryType.GET_CRYPTO_WALLET_SEND_HISTORY_FETCHING,
    isFetching
})

export const getCryptoWalletSendHistory = (payload: object) : IGetCryptoWalletSendHistory => ({
    type: GetCryptoWalletSendHistoryType.GET_CRYPTO_WALLET_SEND_HISTORY,
    payload
})

export const getCryptoWalletSendHistorySuccess = (data:any) : IGetCryptoWalletSendHistorySuccess => ({
    type: GetCryptoWalletSendHistoryType.GET_CRYPTO_WALLET_SEND_HISTORY_SUCCESS,
    data
})

export const getCryptoWalletSendHistoryFaild = (error:any) : IGetCryptoWalletSendHistoryError => ({
    type: GetCryptoWalletSendHistoryType.GET_CRYPTO_WALLET_SEND_HISTORY_ERROR,
    error
})

export const setFilterCryptoWalletSendHistory = (payload: ListParams): IGetListFilterCryptoWalletSendHistory => ({
    type: GetCryptoWalletSendHistoryType.GET_LIST_FILTER_CRYPTO_WALLET_SEND_HISTORY,
    payload
})