import {ExportDirectTransferType} from './actionTypes';
import {
    IExportDirectTransferIsFetching,
    IExportDirectTransferSuccess,
    IExportDirectTransferFaild,
    IExportDirectTransfer
} from './interfaces';

export const exportDirectTransferAtFetching = (isFetching:boolean) : IExportDirectTransferIsFetching  => ({
    type: ExportDirectTransferType.EXPORT_DIRECT_TRANSFER_FETCHING,
    isFetching
})

export const exportDirectTransfer = (payload:Object, xlsx:boolean) : IExportDirectTransfer => ({
    type: ExportDirectTransferType.EXPORT_DIRECT_TRANSFER,
    payload,
    xlsx
})

export const exportDirectTransferSuccess = (data:any) : IExportDirectTransferSuccess => ({
    type: ExportDirectTransferType.EXPORT_DIRECT_TRANSFER_SUCCESS,
    data
})

export const exportDirectTransferFaild = (error:any) : IExportDirectTransferFaild => ({
    type: ExportDirectTransferType.EXPORT_DIRECT_TRANSFER_FAILD,
    error
})
