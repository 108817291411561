
interface IDataListCurrency {
  data: any[];
  status: number;
  message: string;
}

export interface IListCurrencyState {
    data: IDataListCurrency;
    isFetching: boolean,
}

export const initialListCurrencyState: IListCurrencyState = {
    data: {
        data:[],
        message:'',
        status:0
    },
    isFetching: false,
}

