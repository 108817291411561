import { 
    IFetchingGetNotificationFirebase, 
    IGetNotificationFirebase, 
    IGetNotificationFirebaseError, 
    IGetNotificationFirebaseSuccess, 
    IGetListFiterNotifications, 
    ISaveNewNotification, 
    IResetListNewNotification} from './interfaces';
import { GetNotificationInFirebaseType } from './actionTypes';


export const getNotificationsFirebaseIsFetching = (isFetching:boolean) : IFetchingGetNotificationFirebase => ({
    type: GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE_FETCHING,
    isFetching
})

export const getNotificationsFirebase = (payload: Object) : IGetNotificationFirebase => ({
    type: GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE,
    payload
})

export const getNotificationsFirebaseSuccess = (data:any) : IGetNotificationFirebaseSuccess => ({
    type: GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE_SUCCESS,
    data
})

export const getNotificationsFirebaseFaild = (error:any) : IGetNotificationFirebaseError => ({
    type: GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE_FAILD,
    error
})

export const setFilterNotifications = (payload: any): IGetListFiterNotifications => ({
    type: GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE_FILTER,
    payload
})

export const saveNewNotification = (payload : any) : ISaveNewNotification => ({
    type: GetNotificationInFirebaseType.SAVE_NEW_NOTIFICATION_FIREBASE,
    payload
})

export const resetListNewNotification = () : IResetListNewNotification => ({
    type: GetNotificationInFirebaseType.RESET_LIST_NEW_NOTIFICATION_FIREBASE
})