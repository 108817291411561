export const STATUS_EXCHANGE_OFFER = [
  {
    id: 0,
    label: 'bo_offer_status_placed',
    value: 0,
    background: '#517065',
    color: '#fff',
  },
  {
    id: 1,
    label: 'bo_exchange_offer_status_on_going',
    value: 1,
    background: '#c8e6c9',
    color: '#fff',
  },
  {
    id: 2,
    label: 'bo_status_blocked',
    value: 2,
    background: '#ffd8b2',
    color: '#fff',
  },
  {
    id: 3,
    label: 'bo_status_done',
    value: 3,
    background: '#ffcdd2',
    color: '#fff',
  },
  {
    id: 4,
    label: 'bo_status_expired',
    value: 4,
    background: '#ffd8b2',
    color: '#fff',
  },
];
//
