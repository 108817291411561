interface IDataListP2PExchangeTransactionsHistory {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListP2PExchangeTransactionsHistoryState {
    data: IDataListP2PExchangeTransactionsHistory;
    isFetching: boolean,
    filter: object,
    pagination: number
}

export const initialListP2PExchangeTransactionsHistoryState: IListP2PExchangeTransactionsHistoryState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        pageSize: 10,
    },
    pagination: 0,
}

