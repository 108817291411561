export enum LoginAgentActionsTypes {
    GET_LOGIN_AGENT = 'GET_LOGIN_AGENT',
    GET_LOGIN_AGENT_FAIL = 'GET_LOGIN_AGENT_FAIL',
    GET_LOGIN_AGENT_ATTEMPT = 'GET_LOGIN_AGENT_ATTEMPT',
    GET_LOGIN_AGENT_SUCCESS = 'GET_LOGIN_AGENT_SUCCESS',

    VERIFY_OTP = 'VERIFY_OTP',
    RESEND_OTP = 'RESEND_OTP',
    VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL',
    VERIFY_OTP_ATTEMPT = 'VERIFY_OTP_ATTEMPT',
    VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS',

    VERIFY_PIN = 'VERIFY_PIN',
    VERIFY_PIN_FAIL = 'VERIFY_PIN_FAIL',
    VERIFY_PIN_ATTEMPT = 'VERIFY_PIN_ATTEMPT',
    VERIFY_PIN_SUCCESS = 'VERIFY_PIN_SUCCESS',

    CLEAR_2FA = 'CLEAR_2FA',
}