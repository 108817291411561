import { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import Loader from "src/shade/Loaders/Loaders";
import { agentPrivateRoutes } from "./modules/agentPrivateRoutes";
import { publicRoutes } from "./modules/publicRoutes";
import roleTableService from "src/utils/roleTableService";
import { UserRole } from "src/models/roles";
import { superAdminPrivateRoutes } from "./modules/superAdminPrivateRoutes";
import { getStorageItem } from "src/utils/newUtils/localStorageHelper";
import { LocalStorageKey } from "src/contants/constantUtils/localStorageKey";
const Error404 = lazy(() => import("src/pages/404Error/404Error"));

const isAgent = roleTableService([UserRole.Agent, UserRole.SuperAgent]);
const userInfo = getStorageItem(LocalStorageKey.UserInfo);
const privateRoutes = isAgent ? agentPrivateRoutes : superAdminPrivateRoutes;

export const routes = [
  ...publicRoutes,
  { path: "*", element: <Error404 /> },
].concat(userInfo ? [...privateRoutes] : []);

const RouterComponent: React.FC = () => {
  return <Suspense fallback={<Loader />}>{useRoutes(routes)}</Suspense>;
};

export default RouterComponent;
