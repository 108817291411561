import { put, select, takeEvery } from 'redux-saga/effects';
import { handleDeleteUserBankAccount } from 'src/apis/service/BankAccount';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getDataBankAccount } from 'src/store/actions/getDataBankAccount';
import { IApplicationState } from 'src/store/IApplicationState';
import { getStorageItem } from 'src/utils/storage/auth';
import { removeUserBankAccFaild, removeUserBankAccIsFetching, removeUserBankAccSuccess } from '../../actions/removeUserBankAccount/action';
import { RemoveUserBankAccType } from '../../actions/removeUserBankAccount/actionTypes';

function* RemoveUserBankAccSaga(action:any):Generator<any>{


  yield put(removeUserBankAccIsFetching(true))
  const {payload} = action;
  try {
    const dataRemoveUserBankAcc: any = yield handleDeleteUserBankAccount(payload)  
    if (dataRemoveUserBankAcc.status === 200) {
      yield put(removeUserBankAccSuccess(dataRemoveUserBankAcc))
      const userId = getStorageItem('user')
      if (userId) {
         const parseId = JSON.parse(userId).userId
         const filter  = yield select((state: IApplicationState) => state.bankAccount.filter);
         const params = {
           userId:parseId,
           filter
         }
         yield put(getDataBankAccount(params))
         toastSuccess('bo_bank_account_removed')
        
      }
    } else {
      toastError(dataRemoveUserBankAcc.message)
      yield put(removeUserBankAccFaild(dataRemoveUserBankAcc.errors[0]))
    }
  } catch (error) {
     yield put(removeUserBankAccFaild(error)) 
  }
}
export function* watchRemoveUserBankAcc(){
    yield takeEvery(RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT, RemoveUserBankAccSaga)
}