import { AnyAction, Reducer } from 'redux';
import { IApplyKYCAddressState, initialApplyKYCAddressState } from './state';
import { ApplyKYCAddressTypes } from 'src/store/actions/applyKYCAddress';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

export const ApplyKYCAddressReducer: Reducer = (
  state: IApplyKYCAddressState = initialApplyKYCAddressState,
  action: AnyAction
) => {
  switch (action.type) {
    case ApplyKYCAddressTypes.APPLYKYC_ADDRESS: {

      return {
        ...state,
        isFetching: true,
      };
    }
    case ApplyKYCAddressTypes.APPLYKYC_ADDRESS_SUCCESS: {
      // toastSuccess("update_success")
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
        successCount: state.successCount + 1
      };
    }
    case ApplyKYCAddressTypes.APPLYKYC_ADDRESS_FAILD: {
      toastError("bo_failed")
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case ApplyKYCAddressTypes.APPLYKYC_ADDRESS_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
