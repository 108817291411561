export interface EditUserPriority  {
    data: any,
    errors: any,
    success: any
}

export interface EditUserPriorityState {
    data: EditUserPriority,
    isFetching: boolean
}

export const initialEditUserPriorityState: EditUserPriorityState = {
    data:{
        data: {},
        errors: '',
        success: false
    },
    isFetching: false
}