import {ApplyKYCDocsTypes} from './actionTypes';
import { IApplyKYCDocs, IApplyKYCDocsFetching, IApplyKYCDocsSuccess, IApplyKYCDocsError, IApplyKYCDocsFilter, IApplyKYCDocsFiterDebounce } from "./interfaces";


export const applyKYCDocs = (payload:Object): IApplyKYCDocs => ({
    type: ApplyKYCDocsTypes.APPLYKYC_DOCS,
    payload
})

export const applyKYCDocsFetching = (isFetching:boolean) : IApplyKYCDocsFetching => ({
    type: ApplyKYCDocsTypes.APPLYKYC_DOCS_FETCHING,
    isFetching
})

export const applyKYCDocsSuccess = (data:any) : IApplyKYCDocsSuccess => ({
   type: ApplyKYCDocsTypes.APPLYKYC_DOCS_SUCCESS,
   data
})

export const applyKYCDocsError = (errorMsg:any) : IApplyKYCDocsError => ({
    type: ApplyKYCDocsTypes.APPLYKYC_DOCS_FAILD,
    error:errorMsg
})

export const applyKYCDocsFilter = (payload: any): IApplyKYCDocsFilter => ({
    type: ApplyKYCDocsTypes.APPLYKYC_DOCS_FILTER,
    payload
})

export const applyKYCDocsFilterDebounce = (payload: any): IApplyKYCDocsFiterDebounce => ({
    type: ApplyKYCDocsTypes.APPLYKYC_DOCS_FILTER_DEBOUNCE,
    payload 
})
