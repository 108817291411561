import { CreateAnnouncementsType } from './actionTypes';
import { IFetchingCreateAnnouncements, ICreateAnnouncements, ICreateAnnouncementsError, ICreateAnnouncementsSuccess, IFetchListCountry, IFetchListLanguage, IFetchAnnouncements } from './interfaces';

export const createAnnouncementsIsFetching = (isFetching:boolean) : IFetchingCreateAnnouncements => ({
    type: CreateAnnouncementsType.CREATE_ANNOUNCEMENTS_FETCHING,
    isFetching
})

export const createAnnouncements = (payload: Object, history: Object) : ICreateAnnouncements => ({
    type: CreateAnnouncementsType.CREATE_ANNOUNCEMENTS,
    payload,
    history
})

export const fetchAnnouncements = () : IFetchAnnouncements => ({
    type: CreateAnnouncementsType.FETCH_ANNOUNCEMENTS
})

export const createAnnouncementsSuccess = (data:any) : ICreateAnnouncementsSuccess => ({
    type: CreateAnnouncementsType.CREATE_ANNOUNCEMENTS_SUCCESS,
    data
})

export const createAnnouncementsFaild = (error:any) : ICreateAnnouncementsError => ({
    type: CreateAnnouncementsType.CREATE_ANNOUNCEMENTS_ERROR,
    error
})

export const fetchListCountry = () : IFetchListCountry => ({
    type: CreateAnnouncementsType.FETCH_LIST_COUNTRY,
})

export const fetchListLanguage = () : IFetchListLanguage => ({
    type: CreateAnnouncementsType.FETCH_LIST_LANGUAGE,
})