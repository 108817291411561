import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetListSuperWalletBO } from 'src/apis/service/Wallet';
import {
  GetAllWalletAdminBOType,
  getAllWalletAdminBOAtFetching,
  getAllWalletSuperAdminBOFailed,
  getAllWalletSuperAdminBOSuccess,
  setFilterWalletSuperAdminBO,
} from 'src/store/actions/WalletAdminBO/getWalletSuperAdminBO';

function* getAllWalletSuperAdminBOSaga(action: any): Generator<any> {
  yield put(getAllWalletAdminBOAtFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetListSuperWalletBO(payload);
    if (data.status === 200) {
      yield put(getAllWalletSuperAdminBOSuccess(data));
    } else {
      yield put(getAllWalletSuperAdminBOFailed(data.message));
    }
  } catch (error) {
    yield put(getAllWalletSuperAdminBOFailed(error));
  }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterWalletSuperAdminBO(action.payload));
}

export function* watchGetAllSuperWalletAdminBO() {
  yield takeEvery(
    GetAllWalletAdminBOType.GET_ALL_WALLET_SUPER_ADMIN_BO,
    getAllWalletSuperAdminBOSaga
  );
  yield debounce(
    500,
    GetAllWalletAdminBOType.GET_FILTER_DEBOUNCE_WALLET_SUPER_ADMIN_BO,
    handleSearchDebounce
  );
}
