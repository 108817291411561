import { GetNotificationsType } from './actionTypes';
import { IGetNotifications, IFetchingGetNotifications, IGetNotificationsSuccess, IGetNotificationsError, ISearchNotification, ISearchDebounceNotification } from './interfaces';

export const getNotificationsIsFetching = (isFetching:boolean) : IFetchingGetNotifications => ({
    type: GetNotificationsType.GET_NOTIFICATIONS_FETCHING,
    isFetching
})

export const getNotifications = (payload?: Object | unknown) : IGetNotifications => ({
    type: GetNotificationsType.GET_NOTIFICATIONS,
    payload
})

export const getNotificationsSuccess = (data:any) : IGetNotificationsSuccess => ({
    type: GetNotificationsType.GET_NOTIFICATIONS_SUCCESS,
    data
})

export const getNotificationsFaild = (error:any) : IGetNotificationsError => ({
    type: GetNotificationsType.GET_NOTIFICATIONS_ERROR,
    error
})

export const setSearchNotification = (payload: any): ISearchNotification => ({
    type: GetNotificationsType.GET_NOTIFICATION_SEARCH,
    payload
})

export const setSearchDebounceNotification = (payload: any): ISearchDebounceNotification => ({
    type: GetNotificationsType.GET_NOTIFICATION_DEBOUNCE_SEARCH,
    payload 
})