import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetFileByIdTypes } from '../../actions/getFileById';
import { getFileByIdFetching, getFileByIdSuccess, getFileByIdError, getFileByIdFilter } from '../../actions/getFileById/action';
import { handleGetFileById } from 'src/apis/service/Notifications';

function* getFileByIdSaga(action: any): Generator<any> {
  yield put(getFileByIdFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleGetFileById(payload)
    if (data && data?.status === 200) {
      yield put(getFileByIdSuccess(data))
    } else {
      yield put(getFileByIdError('error'))
    }
  } catch (error) {
    yield put(getFileByIdError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getFileByIdFilter(action.payload));
}
export function* watchGetFileById() {
  yield takeEvery(GetFileByIdTypes.GET_FILE_BY_ID, getFileByIdSaga)
  yield debounce(500, GetFileByIdTypes.GET_FILE_BY_ID_FILTER_DEBOUNCE, handleSearchDebounce)
}
