import {put, takeEvery} from 'redux-saga/effects';
import { handleEditUserPriority } from 'src/apis/service/user';
import { editUserPriorityFaild, editUserPrioritySuccess, EditUserPriorityType } from 'src/store/actions/EditUserPriority';
import { editUserIsFetching } from 'src/store/actions/editUserScore';


function* EditUserPrioritySaga(action:any):Generator<any>{
  yield put(editUserIsFetching(true))
  const {payload} = action;
  try {
    const dataEditUserPriority: any = yield handleEditUserPriority(payload);
    if (dataEditUserPriority) {
        yield put(editUserPrioritySuccess(dataEditUserPriority))
    }
  } catch (error) {
     yield put(editUserPriorityFaild(error)) 
  }
}
export function* watchEditUserPriority(){
    yield takeEvery(EditUserPriorityType.EDIT_USER_PRIORITY, EditUserPrioritySaga)
}