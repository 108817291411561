import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { ListParams } from "src/models/common";


interface IDataListUserScore {
    data: {
        items:any[],
        totalCount: number
    };
    status: number;
    message: string;
}

export interface IDataListUserScoreState {
   data: IDataListUserScore,
   isFetching: boolean,
   filter: ListParams,
   pagination: {
    totalCount: number,
   },
}

export const initialListUserScoreState : IDataListUserScoreState = {
    data: {
        data: {
            items:[],
            totalCount:0
        },
        message:'',
        status:0
    },
    isFetching:false,
    filter: {
        pageNumber:1,
        pageSize:PAGE_SIZE_DEFAULT,
        rank: []
    },
    pagination: {
        totalCount: 0,
    }
}