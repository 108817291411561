import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import * as CONSTANT from '../contants';
import { getStorageItem } from '../utils/storage';
import { routerConst } from './routerConstants';

interface AuthenticationProviderProps {
  children: ReactNode;
}

const AuthenticationProvider: React.FC<AuthenticationProviderProps> = (
  props
) => {
  const { children } = props;
  const navigate = useNavigate();
  const token = getStorageItem(CONSTANT.ACCESS_TOKEN);
  if (token === null) {
    navigate(routerConst.Login);
  }

  return <>{children}</>;
};

export default AuthenticationProvider;
