import { AnyAction, Reducer } from 'redux';
import { IAgentGetAdminTrxDetailState, initialAgentGetAdminTrxDetailState } from './state';
import { AgentGetAdminTrxDetailTypes } from 'src/store/actions/agentGetAdminTrxDetail';

export const AgentGetAdminTrxDetailReducer: Reducer = (
  state: IAgentGetAdminTrxDetailState = initialAgentGetAdminTrxDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
