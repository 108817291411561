import { toastSuccess, toastError } from 'src/helpers/toastHelper';
import { getUserDetail } from 'src/store/actions/getDataUserDetail';
import { put, takeEvery } from 'redux-saga/effects';
import { DeactivateUserType } from '../../actions/deactivateUser/actionTypes';
import { deactivateUserIsFetching, deactivateUserSuccess, deactivateUserFaild } from '../../actions/deactivateUser/action';
import { handleDeactivate } from '../../../apis/service/user';

function* deactivateUserSaga(action: any): Generator<any> {


  yield put(deactivateUserIsFetching(true))
  const { payload } = action;
  try {
    const dataDeactivateUser: any = yield handleDeactivate(payload)
    if (dataDeactivateUser.status === 200) {
      yield put(getUserDetail(payload.userId))
      toastSuccess('bo_deactive_user_success');
    } else {
      toastError('bo_you_can_t_deactive_user');
      // yield put(deactivateUserFaild('error'))
    }
  } catch (error) {
    toastError('bo_you_can_t_deactive_user');
    yield put(deactivateUserFaild(error))
  }
}
export function* watchDeactivateUser() {
  yield takeEvery(DeactivateUserType.DEACTIVATE_USER, deactivateUserSaga)
}