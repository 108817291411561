import { AnyAction, Reducer } from 'redux';
import { initialEditUserDetailState, EditUserDetailState } from './state';
import { EditUserDetailType } from '../../actions/editUserDetail/actionTypes';
import { toastSuccess, toastError } from '../../../helpers/toastHelper';

export const EditUserDetailReducer: Reducer = (
  state: EditUserDetailState = initialEditUserDetailState,
  action: AnyAction
) => {

  switch (action.type) {
    case EditUserDetailType.EDIT_USER_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case EditUserDetailType.EDIT_USER_DETAIL_SUCCESS: {


      toastSuccess('bo_user_information_updated');
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case EditUserDetailType.EDIT_USER_DETAIL_ERROR: {


      toastError('bo_user_information_update_fail');
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
