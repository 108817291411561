import {GetListUserTypes} from './actionTypes';
import {
    IGetListUser,
    IGetListUserFetching,
    IGetListUserSuccess,
    IGetListUserFaild,
    IGetListFiterUser,
    IGetListFiterDebounceUser
} from './interfaces';

export const getListUserAtFetching = (isFetching:boolean) : IGetListUserFetching  => ({
    type: GetListUserTypes.GET_LIST_USER_FETCHING,
    isFetching
})

export const getListUser = (payload:Object) : IGetListUser => ({
    type: GetListUserTypes.GET_LIST_USER,
    payload
})

export const getListUserSuccess = (data:any) : IGetListUserSuccess => ({
    type: GetListUserTypes.GET_LIST_USER_SUCCESS,
    data
})

export const getListUserFaild = (error:any) : IGetListUserFaild => ({
    type: GetListUserTypes.GET_LIST_USER_FAILD,
    error
})

export const setFilterListUser = (payload: any): IGetListFiterUser => ({
    type: GetListUserTypes.GET_LIST_FILTER_USER,
    payload
})

export const setFilterDebounceUser = (payload: any): IGetListFiterDebounceUser => ({
    type: GetListUserTypes.GET_LIST_FILTER_DEBOUNCE_USER,
    payload 
})