import { put, takeEvery, debounce } from 'redux-saga/effects';
import { GetAllKYCTypes } from '../../actions/getAllKYC';
import { getAllKYCFetching, getAllKYCSuccess, getAllKYCError, getAllKYCFilter } from '../../actions/getAllKYC/action';
import { handleGetAllKYC } from 'src/apis/service/user';

function* getAllKYCSaga(action: any): Generator<any> {
  yield put(getAllKYCFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleGetAllKYC()
    if (data && data?.status === 200) {
      yield put(getAllKYCSuccess(data))
    } else {
      yield put(getAllKYCError('error'))
    }
  } catch (error) {
    yield put(getAllKYCError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAllKYCFilter(action.payload));
}
export function* watchGetAllKYC() {
  yield takeEvery(GetAllKYCTypes.GET_ALL_KYC, getAllKYCSaga)
  yield debounce(500, GetAllKYCTypes.GET_ALL_KYC_FILTER_DEBOUNCE, handleSearchDebounce)
}
