import { debounce, put, takeLatest } from 'redux-saga/effects';
import { handleUserPriority } from 'src/apis/service/user';
import { getListUserPriorityError, getListUserPriorityFetching, getListUserPrioritySuccess, GetListUserPriorityType, setFilter } from 'src/store/actions/getUserPriority';

function* getListUserPriority(action: any):Generator<any>{
   yield put(getListUserPriorityFetching(true));
    try {
      const dataListUserPriority:any = yield handleUserPriority(action.payload) 
      if (dataListUserPriority && dataListUserPriority?.status === 200) {
        yield put(getListUserPrioritySuccess(dataListUserPriority))
      } else {
        yield put(getListUserPriorityError('error'))
      } 
    } catch (error) {
        yield put(getListUserPriorityError(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilter(action.payload));
}

export function* watchGetListUserPriority () {
  yield takeLatest(GetListUserPriorityType.GET_LIST_USER_PRIORITY, getListUserPriority)
  yield debounce(500, GetListUserPriorityType.GET_LIST_FILTER_DEBOUNCE, handleSearchDebounce)
}