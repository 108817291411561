import { put, takeEvery, delay } from 'redux-saga/effects';
import {
  ChangePasswordType,
  ChangePassWordError,
  ChangePasswordIsFetching,
  ChangePasswordSuccess,
} from '../../actions/ChangePassword';
import { handleChangePassword } from '../../../apis/service/user';

function* handleChangePassSaga(action:any):Generator<any> {
    yield put(ChangePasswordIsFetching(true))
    yield delay(500)
    const { payload } = action;
    const dataChangePassword:any = yield handleChangePassword(payload)
    try {
        if (dataChangePassword.status === 200) {
            yield put(ChangePasswordSuccess(dataChangePassword))
        } else {
            yield put(ChangePassWordError(dataChangePassword.message))
        }
    } catch (error) {
        yield put(ChangePassWordError(error))
    }
}
export function* watchChangePassword() {
    yield takeEvery(ChangePasswordType.CHANGE_PASSWORD, handleChangePassSaga)
}
