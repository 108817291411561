import { AnyAction, Reducer } from 'redux';
import { IActiveAgentRankState, initialActiveAgentRankState } from './state';
import { ActiveAgentRankTypes } from 'src/store/actions/activeAgentRank';

export const ActiveAgentRankReducer: Reducer = (
  state: IActiveAgentRankState = initialActiveAgentRankState,
  action: AnyAction
) => {
  switch (action.type) {
    case ActiveAgentRankTypes.ACTIVE_AGENT_RANK: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ActiveAgentRankTypes.ACTIVE_AGENT_RANK_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
        successCount: state.successCount + 1
      };
    }
    case ActiveAgentRankTypes.ACTIVE_AGENT_RANK_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case ActiveAgentRankTypes.ACTIVE_AGENT_RANK_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
