import { put, takeEvery, debounce } from 'redux-saga/effects';
import { Set2FAEnabledTypes, set2FAEnabledError, set2FAEnabledFetching, set2FAEnabledFilter, set2FAEnabledSuccess } from '../../actions/set2FAEnabled';
import { handleSet2FAEnabled } from 'src/apis/service/Agent';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* set2FAEnabledSaga(action: any): Generator<any> {
  yield put(set2FAEnabledFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleSet2FAEnabled(payload)
    if (data && data?.status === 200) {
      toastSuccess('bo_success');
      yield put(set2FAEnabledSuccess(data))
    } else {
      toastError('bo_failed');
      yield put(set2FAEnabledError('error'))
    }
  } catch (error) {
    toastError('bo_failed');
    yield put(set2FAEnabledError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(set2FAEnabledFilter(action.payload));
}
export function* watchSet2FAEnabled() {
  yield takeEvery(Set2FAEnabledTypes.SET2FA_ENABLED, set2FAEnabledSaga)
  yield debounce(500, Set2FAEnabledTypes.SET2FA_ENABLED_FILTER_DEBOUNCE, handleSearchDebounce)
}
