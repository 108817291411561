import { UpdateCryptoExchangeOffersListTypes } from './actionTypes';
import { IUpdateCryptoExchangeOffersList, IUpdateCryptoExchangeOffersListFetching, IUpdateCryptoExchangeOffersListSuccess, IUpdateCryptoExchangeOffersListError, IUpdateCryptoExchangeOffersListFilter, IUpdateCryptoExchangeOffersListFiterDebounce } from "./interfaces";


export const updateCryptoExchangeOffersList = (offerId: string, payload: Object): IUpdateCryptoExchangeOffersList => ({
    type: UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST,
    payload,
    offerId
})

export const updateCryptoExchangeOffersListFetching = (isFetching: boolean): IUpdateCryptoExchangeOffersListFetching => ({
    type: UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_FETCHING,
    isFetching
})

export const updateCryptoExchangeOffersListSuccess = (data: any): IUpdateCryptoExchangeOffersListSuccess => ({
    type: UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS,
    data
})

export const updateCryptoExchangeOffersListError = (errorMsg: any): IUpdateCryptoExchangeOffersListError => ({
    type: UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD,
    error: errorMsg
})

export const updateCryptoExchangeOffersListFilter = (payload: any): IUpdateCryptoExchangeOffersListFilter => ({
    type: UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER,
    payload
})

export const updateCryptoExchangeOffersListFilterDebounce = (payload: any): IUpdateCryptoExchangeOffersListFiterDebounce => ({
    type: UpdateCryptoExchangeOffersListTypes.UPDATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE,
    payload
})
