import { put, takeLatest } from 'redux-saga/effects';
import { updateAnnouncement } from 'src/apis/service/Announcements';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { updateAnnouncementsFaild, updateAnnouncementsIsFetching, updateAnnouncementsSuccess, UpdateAnnouncementsType } from 'src/store/actions/Announcement/Announcements/UpdateAnnouncements';

function* updateAnnouncementsSaga(action: any): Generator<any> {


  yield put(updateAnnouncementsIsFetching(true))
  const { payload, history } = action;
  try {
    const data: any = yield updateAnnouncement(payload)
    if (data.message === "Success") {
      yield put(updateAnnouncementsSuccess(data));
      toastSuccess('bo_update_announcement_success');
    } else {
      toastError('bo_annoucement_update_fail')
    }
  } catch (error) {
    yield put(updateAnnouncementsFaild(error))
  }
}

export function* watchUpdateListAnnouncements() {
  yield takeLatest(UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS, updateAnnouncementsSaga)
}