import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletSendStatisticalType } from 'src/store/actions';
import { IListCryptoWalletSendStatisticalState, initialListCryptoWalletSendStatisticalState } from './state';


export const GetListCryptoWalletSendStatisticalReducer: Reducer = (
  state: IListCryptoWalletSendStatisticalState = initialListCryptoWalletSendStatisticalState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCryptoWalletSendStatisticalType.GET_CRYPTO_WALLET_SEND_STATISTICAL : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoWalletSendStatisticalType.GET_CRYPTO_WALLET_SEND_STATISTICAL_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetCryptoWalletSendStatisticalType.GET_CRYPTO_WALLET_SEND_STATISTICAL_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
      }
    }
    case GetCryptoWalletSendStatisticalType.GET_LIST_FILTER_CRYPTO_WALLET_SEND_STATISTICAL: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};