import {AdminP2PSetAgentScoreTypes} from './actionTypes';
import { IAdminP2PSetAgentScore, IAdminP2PSetAgentScoreFetching, IAdminP2PSetAgentScoreSuccess, IAdminP2PSetAgentScoreError, IAdminP2PSetAgentScoreFilter, IAdminP2PSetAgentScoreFiterDebounce } from "./interfaces";


export const adminP2PSetAgentScore = (payload:Object): IAdminP2PSetAgentScore => ({
    type: AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE,
    payload
})

export const adminP2PSetAgentScoreFetching = (isFetching:boolean) : IAdminP2PSetAgentScoreFetching => ({
    type: AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_FETCHING,
    isFetching
})

export const adminP2PSetAgentScoreSuccess = (data:any) : IAdminP2PSetAgentScoreSuccess => ({
   type: AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_SUCCESS,
   data
})

export const adminP2PSetAgentScoreError = (errorMsg:any) : IAdminP2PSetAgentScoreError => ({
    type: AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_FAILD,
    error:errorMsg
})

export const adminP2PSetAgentScoreFilter = (payload: any): IAdminP2PSetAgentScoreFilter => ({
    type: AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_FILTER,
    payload
})

export const adminP2PSetAgentScoreFilterDebounce = (payload: any): IAdminP2PSetAgentScoreFiterDebounce => ({
    type: AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_FILTER_DEBOUNCE,
    payload 
})
