import { put, takeLatest } from "redux-saga/effects";
import { updateTokenFirebase } from "src/apis/service/Notifications";
import { handleLogin } from "src/apis/service/user";
import * as CONSTANT from "src/contants";
import { setToken } from "src/helpers/saveCookies";
import { toastError, toastSuccess } from "src/helpers/toastHelper";
import { getStorageItem, setStorageItem } from "src/utils/storage";
import {
  LoginActionsTypes,
  getLoginAtFetching,
  getLoginError,
  getLoginSuccess,
} from "../../actions";
import { getSupportedCryptoCurrenciesAPI } from "src/apis/service/Currencies";

function* getLogin(action: any): Generator<any> {
  yield getLoginAtFetching(true);
  const { payload } = action;
  try {
    const dataLogin: any = yield handleLogin(payload);
    if (dataLogin?.status === 200) {
      const data = {
        ...dataLogin,
        success: true,
      };
      yield put(getLoginSuccess(data));
      const token = dataLogin.data && dataLogin.data.token;
      const refreshToken = dataLogin.data && dataLogin.data.refreshToken;
      setStorageItem("user", JSON.stringify(dataLogin.data));
      setToken(token);
      setStorageItem(CONSTANT.ACCESS_TOKEN, token);
      setStorageItem(CONSTANT.REFRESH_TOKEN, refreshToken);
      toastSuccess("bo_login_success");
      yield getSupportedCryptoCurrenciesAPI();
      const firebaseToken: any = getStorageItem("firebase-token");
      yield updateTokenFirebase(firebaseToken);
    } else {
      if (dataLogin?.message === "Admin don't have function") {
        toastError("bo_admin_no_role_error_label");
      } else {
        toastError(
          "bo_the_otp_you_entered_is_invalid_please_enter_the_correct_otp_label"
        );
      }
      yield put(getLoginError(dataLogin));
    }
  } catch (error) {
    toastError("bo_login_error");
  }
}
export function* watchLogin(): any {
  yield takeLatest(LoginActionsTypes.GET_LOGIN, getLogin);
}
