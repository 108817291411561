interface ICreateOfferBuyOrSell {
    data: any;
    status: number;
    message: string;
}

export interface ICreateOfferBuyOrSellState {
    data: ICreateOfferBuyOrSell;
    isFetching: boolean,
    countSuccess: number
}

export const initialCreateOfferBuyOrSellState: ICreateOfferBuyOrSellState = {
    data: {
        data: false,
        status: 0,
        message: ''
    },
    isFetching: false,
    countSuccess: 0
}