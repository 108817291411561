import {GetHistoryDeactivateUserTypes} from './actionTypes';
import { IGetHistoryDeactivateUser, IGetHistoryDeactivateUserFetching, IGetHistoryDeactivateUserSuccess, IGetHistoryDeactivateUserError, IGetHistoryDeactivateUserFilter, IGetHistoryDeactivateUserFiterDebounce } from "./interfaces";


export const getHistoryDeactivateUser = (payload:Object): IGetHistoryDeactivateUser => ({
    type: GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER,
    payload
})

export const getHistoryDeactivateUserFetching = (isFetching:boolean) : IGetHistoryDeactivateUserFetching => ({
    type: GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER_FETCHING,
    isFetching
})

export const getHistoryDeactivateUserSuccess = (data:any) : IGetHistoryDeactivateUserSuccess => ({
   type: GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER_SUCCESS,
   data
})

export const getHistoryDeactivateUserError = (errorMsg:any) : IGetHistoryDeactivateUserError => ({
    type: GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER_FAILD,
    error:errorMsg
})

export const getHistoryDeactivateUserFilter = (payload: any): IGetHistoryDeactivateUserFilter => ({
    type: GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER_FILTER,
    payload
})

export const getHistoryDeactivateUserFilterDebounce = (payload: any): IGetHistoryDeactivateUserFiterDebounce => ({
    type: GetHistoryDeactivateUserTypes.GET_HISTORY_DEACTIVATE_USER_FILTER_DEBOUNCE,
    payload 
})
