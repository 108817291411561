import { AnyAction, Reducer } from 'redux';
import { IEditUserBankInfoState, initialEditUserBankInfoState } from './state';
import { EditUserBankInfoTypes } from 'src/store/actions/editUserBankInfo';

export const EditUserBankInfoReducer: Reducer = (
  state: IEditUserBankInfoState = initialEditUserBankInfoState,
  action: AnyAction
) => {
  switch (action.type) {
    case EditUserBankInfoTypes.EDIT_USER_BANK_INFO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case EditUserBankInfoTypes.EDIT_USER_BANK_INFO_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
        successCount: state.successCount + 1
      };
    }
    case EditUserBankInfoTypes.EDIT_USER_BANK_INFO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case EditUserBankInfoTypes.EDIT_USER_BANK_INFO_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
