import { GetP2PExchangeOffersHistoryType } from './actionTypes';
import { IFetchingGetP2PExchangeOffersHistory, IGetP2PExchangeOffersHistory, IGetP2PExchangeOffersHistoryError, IGetP2PExchangeOffersHistorySuccess } from './interfaces';

export const getP2PExchangeOffersHistoryIsFetching = (isFetching:boolean) : IFetchingGetP2PExchangeOffersHistory => ({
    type: GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY_FETCHING,
    isFetching
})

export const getP2PExchangeOffersHistory = (payload: object) : IGetP2PExchangeOffersHistory => ({
    type: GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY,
    payload
})

export const getP2PExchangeOffersHistorySuccess = (data:any) : IGetP2PExchangeOffersHistorySuccess => ({
    type: GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY_SUCCESS,
    data
})

export const getP2PExchangeOffersHistoryFaild = (error:any) : IGetP2PExchangeOffersHistoryError => ({
    type: GetP2PExchangeOffersHistoryType.GET_P2P_EXCHANGE_OFFERS_HISTORY_ERROR,
    error
})