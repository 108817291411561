import * as Sentry from '@sentry/react';
import Request from '../../config';

const requestApi = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});
const requestApiUser = new Request({
  url: process.env.REACT_APP_BASE_URL_ACCOUNT,
});

const handleGetListAdminFunction = async (): Promise<any> => {
  try {
    return await requestApi.get('/User/GetListAdminFunctionForSuperAdmin');
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateOrUpdateAdminFunction = async (data: any): Promise<any> => {
  try {
    return await requestApi.post('/User/CreateRoleFunction', data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetRoleFunctionByAdminId = async (data: any): Promise<any> => {
  try {
    return await requestApi.get(
      `/User/GetRoleFunctionById?userId=${data.userId}`
    );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetNavbarForAdmin = async (): Promise<any> => {
  try {
    return await requestApiUser.post('/User/GetListAdminFunction');
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleGetListAdminFunction,
  handleCreateOrUpdateAdminFunction,
  handleGetRoleFunctionByAdminId,
  handleGetNavbarForAdmin,
};
