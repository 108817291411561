import { debounce, put, takeEvery } from "redux-saga/effects";
import { SelectWalletTypeTypes } from "../../actions/selectWalletType";
import {
  selectWalletTypeFetching,
  selectWalletTypeFilter,
  selectWalletTypeSuccess
} from "../../actions/selectWalletType/action";

function* selectWalletTypeSaga(action: any): Generator<any> {
  yield put(selectWalletTypeFetching(true));
  const { payload } = action;
  yield put(selectWalletTypeSuccess(payload));
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(selectWalletTypeFilter(action.payload));
}
export function* watchSelectWalletType() {
  yield takeEvery(
    SelectWalletTypeTypes.SELECT_WALLET_TYPE,
    selectWalletTypeSaga
  );
  yield debounce(
    500,
    SelectWalletTypeTypes.SELECT_WALLET_TYPE_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
