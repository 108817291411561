import { DeleteAdminBOType } from './actionTypes';
import {
    IDeleteAdminBO,
    IDeleteAdminBOFaild,
    IDeleteAdminBOFetching,
    IDeleteAdminBOSuccess
} from './interfaces';

export const deleteAdminBOAtFetching = (isFetching: boolean): IDeleteAdminBOFetching => ({
    type: DeleteAdminBOType.DELETE_ADMIN_BO_FETCHING,
    isFetching
})

export const deleteAdminBO = (payload: any): IDeleteAdminBO => ({
    type: DeleteAdminBOType.DELETE_ADMIN_BO,
    payload
})

export const deleteAdminBOSuccess = (): IDeleteAdminBOSuccess => ({
    type: DeleteAdminBOType.DELETE_ADMIN_BO_SUCCESS
})

export const deleteAdminBOFaild = (error: any): IDeleteAdminBOFaild => ({
    type: DeleteAdminBOType.DELETE_ADMIN_BO_FAILD,
    error
})
