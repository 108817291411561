import {put, takeEvery, debounce} from 'redux-saga/effects';
import { getCurrenciesBO } from 'src/apis/service/Currencies';
import { 
   GetAllCurrencyType,
   getAllCurrencyIsFetching,
   getAllCurrencySuccess,
   getAllCurrencyFaild,
   setFilterListCurrency
 } from 'src/store/actions/currency';

function* getListCurrencyBOSaga(action:any): Generator<any> {
    yield put(getAllCurrencyIsFetching(true))
    const {payload} = action;
    try {
      const response : any = yield getCurrenciesBO(payload) 
      if(response.status === 200){
        yield put(getAllCurrencySuccess(response));
      }  
    } catch (error) {
        yield put(getAllCurrencyFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterListCurrency(action.payload));
}
export function* watchGetListCurrencyBO() {
   yield takeEvery(GetAllCurrencyType.GET_ALL_CURRENCY , getListCurrencyBOSaga)
   yield debounce(500, GetAllCurrencyType.GET_LIST_FILTER_DEBOUNCE_CURRENCY, handleSearchDebounce)
}