interface RemoveAgentRankBO {
    data: any,
    status: number,
    message: string
}

export interface RemoveAgentRankState {
    data: RemoveAgentRankBO,
    isFetching: boolean,
    successCount: number
}

export const initialRemoveAgentRankState: RemoveAgentRankState = {
    data: {
        data: false,
        message: '',
        status: 0
    },
    isFetching: false,
    successCount: 0
}