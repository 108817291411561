export interface RemoveExchangeOffer {
    status: number,
    data: any,
    message: string
}

export interface RemoveExchangeOfferState {
    data: RemoveExchangeOffer,
    isFetching: boolean,
    successCount: number
}

export const initialRemoveExchangeOfferState: RemoveExchangeOfferState = {
    data: {
        data: {},
        message: '',
        status: 0
    },
    isFetching: false,
    successCount: 0
}