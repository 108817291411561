import { BankAccParams } from "src/apis/service/BankAccount";
import { GetDataBankAccount } from "./actionTypes";
import {
   IFetchingDataBankAccount,
   IGetDataBankAccount,
   IGetDataBankAccountError,
   IGetDataBankAccountSuccess,
   IGetListFiterBankAccount
} from "./interfaces";

export const getDataBankAccountIsFetching = (isFetching:boolean) : IFetchingDataBankAccount => ({
    type: GetDataBankAccount.GET_DATA_FETCHING,
    isFetching
})

export const getDataBankAccount =  (params:BankAccParams) : IGetDataBankAccount => ({
    type: GetDataBankAccount.GET_DATA,
    params
})

export const getDataBankAccountSuccess = (data:any[]) : IGetDataBankAccountSuccess => ({
    type: GetDataBankAccount.GET_DATA_SUCCESS,
    data
})

export const getDataBankAccountInPage = (payload:any) : IGetListFiterBankAccount => ({
    type: GetDataBankAccount.GET_DATA_BY_PAGE,
    payload
}) 
export const getDataBankAccountError = (error:any) : IGetDataBankAccountError => ({
    type: GetDataBankAccount.GET_DATA_ERROR,
    error
})