import {GetTransactionsTypes} from './actionTypes';
import {
    IGetListFiterDebounceTransaction,
    IGetListFiterTransaction,
    IGetTransactions,
    IGetTransactionsFaild,
    IGetTransactionsFetching,
    IGetTransactionsSuccess
} from './interfaces';

export const getTransactionsAtFetching = (isFetching:boolean) : IGetTransactionsFetching  => ({
    type: GetTransactionsTypes.GET_TRANSACTION_FETCHING,
    isFetching
})

export const getTransactions = (payload:Object) : IGetTransactions => ({
    type: GetTransactionsTypes.GET_TRANSACTION,
    payload
})

export const getTransactionsSuccess = (data:any) : IGetTransactionsSuccess => ({
    type: GetTransactionsTypes.GET_TRANSACTION_SUCCESS,
    data
})

export const getTransactionsFaild = (error:any) : IGetTransactionsFaild => ({
    type: GetTransactionsTypes.GET_TRANSACTION_FAILD,
    error
})

export const setFilterTransaction = (payload: any): IGetListFiterTransaction => ({
    type: GetTransactionsTypes.GET_LIST_FILTER_TRANSACTION,
    payload
})

export const setFilterDebounceTransaction = (payload: any): IGetListFiterDebounceTransaction => ({
    type: GetTransactionsTypes.GET_LIST_FILTER_DEBOUNCE_TRANSACTION,
    payload 
})