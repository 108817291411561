import Cookies from 'js-cookie';

const token = 'token'

const storeDataCookie = async (key: string, value: string) => {
    try {
      if (key && value) {
        await Cookies.set(key,value)
      } else {
        Cookies.remove(key);
      }
    } catch (e) {
      console.log(`ERROR SAVE DATA ${key}`, e);
    }
};

const getDataCookie = async (key: string) => {
    try {
      return await Cookies.get(key);
    } catch (e) {
      console.log(`ERROR GET DATA FROM ${key}`, e);
    }
  };
  const setToken = (newToken?: string) => {
    storeDataCookie(token, newToken || '');
  };
  
  const getToken = (tokenUser: string) => getDataCookie(tokenUser);
  
  export {setToken, getToken , getDataCookie};