import { PAGE_SIZE_DEFAULT } from './../../../../contants/pageSize';
interface IDataListAllAdminBO {
    data:{
      items:any[];
      totalCount:number;
    };
    error:any;
    success:boolean;
  }
  
  export interface IListAllAdminBOState {
      data:IDataListAllAdminBO;
      isFetching:boolean,
      filter: any,
      pagination: any,
  }
  
  export const initialListAllAdminBOState: IListAllAdminBOState = {
      data:{
          data:{
              items:[],
              totalCount:0
          },
          error:null,
          success:false
      },
      isFetching:false,
      filter: {
          pageNumber: 1,
          pageSize: PAGE_SIZE_DEFAULT,
          searchText:''
      },
      pagination: {
          totalCount: 0,
      }
  }