import { WalletRefillType } from './actionTypes';
import { IWalletRefill, IFetchingWalletRefill, IWalletRefillSuccess, IWalletRefillError } from './interfaces';


export const walletRefillIsFetching = (isFetching:boolean) : IFetchingWalletRefill => ({
    type: WalletRefillType.WALLET_REFILL_FETCHING,
    isFetching
})

export const walletRefill = (payload:Object) : IWalletRefill => ({
    type: WalletRefillType.WALLET_REFILL,
    payload
})

export const walletRefillSuccess = (data:any) : IWalletRefillSuccess => ({
    type: WalletRefillType.WALLET_REFILL_SUCCESS,
    data
})

export const walletRefillFaild = (error:any) : IWalletRefillError => ({
    type: WalletRefillType.WALLET_REFILL_ERROR,
    error
})