import { RemoveOfferSellTransactionType } from './actionTypes';
import { IFetchingRemoveOfferSellTransaction,IRemoveOfferSellTransaction,IRemoveOfferSellTransactionError,IRemoveOfferSellTransactionSuccess } from './interfaces';


export const removeOfferSellTransactionIsFetching = (isFetching:boolean) : IFetchingRemoveOfferSellTransaction => ({
    type:RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION_FETCHING,
    isFetching
})

export const removeOfferSellTransaction = (id: number) : IRemoveOfferSellTransaction => ({
    type:RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION,
    id
})

export const removeOfferSellTransactionSuccess = (data:any) : IRemoveOfferSellTransactionSuccess => ({
    type:RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION_SUCCESS,
    data
})

export const removeOfferSellTransactionFaild = (error:any) : IRemoveOfferSellTransactionError => ({
    type:RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION_ERROR,
    error
})