import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { PAGE_SIZE } from 'src/contants/pageSize';

interface IOfferTransaction {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  success:boolean;
}

export interface IOfferTransactionState {
    data:IOfferTransaction;
    isFetching:boolean,
    filter: Object,
    pagination: any,
}

export const IFilterOfferBuy = {
    pageNumber: 1,
    pageSize: PAGE_SIZE_DEFAULT,
    searchText: '',
    offerType: 'buy'
} 

export const initialOfferTransactionState: IOfferTransactionState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: IFilterOfferBuy,
    pagination: {
        total: 1,
    }
}

