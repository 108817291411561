import { CreateLanguageTextType } from './actionTypes';
import { ICreateLanguageText, ICreateLanguageTextError, ICreateLanguageTextSuccess, IFetchingCreateLanguageText} from './interfaces';
import { History } from 'history';

export const createLanguageTextIsFetching = (isFetching:boolean) : IFetchingCreateLanguageText => ({
    type: CreateLanguageTextType.CREATE_LANGUAGE_TEXT_FETCHING,
    isFetching
})

export const createLanguageText = (payload:Object, history: History) : ICreateLanguageText => ({
    type: CreateLanguageTextType.CREATE_LANGUAGE_TEXT,
    payload,
    history
})

export const createLanguageTextSuccess = (data:any) : ICreateLanguageTextSuccess => ({
    type: CreateLanguageTextType.CREATE_LANGUAGE_TEXT_SUCCESS,
    data
})

export const createLanguageTextFaild = (error:any) : ICreateLanguageTextError => ({
    type: CreateLanguageTextType.CREATE_LANGUAGE_TEXT_ERROR,
    error
})