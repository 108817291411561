import { handleGetTransactionsByAgent } from 'src/apis/service/P2PTransaction';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { GetTranscationsByAgentTypes } from '../../actions/getTranscationsByAgent';
import { getTranscationsByAgentError, getTranscationsByAgentFetching, getTranscationsByAgentFilter, getTranscationsByAgentSuccess } from '../../actions/getTranscationsByAgent/action';

function* getTranscationsByAgentSaga(action:any):Generator<any> {
    yield put(getTranscationsByAgentFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetTransactionsByAgent(payload)
      if(data && data?.status === 200){
        yield put(getTranscationsByAgentSuccess(data))
      } else {
        yield put(getTranscationsByAgentError('error'))
      }
    } catch (error) {
        yield put(getTranscationsByAgentError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getTranscationsByAgentFilter(action.payload));
}
export function* watchGetTranscationsByAgent() {
   yield takeEvery(GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT, getTranscationsByAgentSaga)
   yield debounce(500,GetTranscationsByAgentTypes.GET_TRANSCATIONS_BY_AGENT_FILTER_DEBOUNCE,handleSearchDebounce)
}
