import {GetCryptoTransactionHistoryTypes} from './actionTypes';
import { IGetCryptoTransactionHistory, IGetCryptoTransactionHistoryFetching, IGetCryptoTransactionHistorySuccess, IGetCryptoTransactionHistoryError, IGetCryptoTransactionHistoryFilter, IGetCryptoTransactionHistoryFiterDebounce } from "./interfaces";


export const getCryptoTransactionHistory = (payload:Object): IGetCryptoTransactionHistory => ({
    type: GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY,
    payload
})

export const getCryptoTransactionHistoryFetching = (isFetching:boolean) : IGetCryptoTransactionHistoryFetching => ({
    type: GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_FETCHING,
    isFetching
})

export const getCryptoTransactionHistorySuccess = (data:any) : IGetCryptoTransactionHistorySuccess => ({
   type: GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_SUCCESS,
   data
})

export const getCryptoTransactionHistoryError = (errorMsg:any) : IGetCryptoTransactionHistoryError => ({
    type: GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_FAILD,
    error:errorMsg
})

export const getCryptoTransactionHistoryFilter = (payload: any): IGetCryptoTransactionHistoryFilter => ({
    type: GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_FILTER,
    payload
})

export const getCryptoTransactionHistoryFilterDebounce = (payload: any): IGetCryptoTransactionHistoryFiterDebounce => ({
    type: GetCryptoTransactionHistoryTypes.GET_CRYPTO_TRANSACTION_HISTORY_FILTER_DEBOUNCE,
    payload 
})
