
interface IDataListWalletsUser {
  data: any[];
  status: number;
  message: string;
}

export interface IListWalletsUserState {
    data: IDataListWalletsUser;
    isFetching: boolean,
}

export const initialListWalletsUserState: IListWalletsUserState = {
    data: {
        data:[],
        message:'',
        status:0
    },
    isFetching: false,
}

