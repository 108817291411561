import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";


interface IDataAdminOrAgentTransfers {
  data:{
    items:any[];
    totalCount:number;
  };
  status:number;
  message:string;
}

export interface IAdminOrAgentTransfersState {
    data:IDataAdminOrAgentTransfers;
    isFetching:boolean,
    filter: any,
    pagination: any,
}

export const initialAdminOrAgentTransfersState: IAdminOrAgentTransfersState = {
    data:{
        data:{
            items:[],
            totalCount:0
        },
        message:'',
        status:0
    },
    isFetching:false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        currency: []
    },
    pagination: {
        totalCount: 0,
    }
}

