import { GetVenlyWalletsUserType } from './actionTypes';
import { IFetchingGetVenlyWalletsUser, IGetVenlyWalletsUser, IGetVenlyWalletsUserSuccess, IGetVenlyWalletsUserError } from './interfaces';

export const getVenlyWalletsUserIsFetching = (isFetching:boolean) : IFetchingGetVenlyWalletsUser => ({
    type: GetVenlyWalletsUserType.GET_VENLY_WALLETS_USER_FETCHING,
    isFetching
})

export const getVenlyWalletsUser = () : IGetVenlyWalletsUser => ({
    type: GetVenlyWalletsUserType.GET_VENLY_WALLETS_USER
})

export const getVenlyWalletsUserSuccess = (data:any) : IGetVenlyWalletsUserSuccess => ({
    type: GetVenlyWalletsUserType.GET_VENLY_WALLETS_USER_SUCCESS,
    data
})

export const getVenlyWalletsUserFaild = (error:any) : IGetVenlyWalletsUserError => ({
    type: GetVenlyWalletsUserType.GET_VENLY_WALLETS_USER_ERROR,
    error
})