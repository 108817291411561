import {GetOfferLogsTypes} from './actionTypes';
import { IGetOfferLogs, IGetOfferLogsFetching, IGetOfferLogsSuccess, IGetOfferLogsError, IGetOfferLogsFilter, IGetOfferLogsFiterDebounce } from "./interfaces";


export const getOfferLogs = (payload:Object): IGetOfferLogs => ({
    type: GetOfferLogsTypes.GET_OFFER_LOGS,
    payload
})

export const getOfferLogsFetching = (isFetching:boolean) : IGetOfferLogsFetching => ({
    type: GetOfferLogsTypes.GET_OFFER_LOGS_FETCHING,
    isFetching
})

export const getOfferLogsSuccess = (data:any) : IGetOfferLogsSuccess => ({
   type: GetOfferLogsTypes.GET_OFFER_LOGS_SUCCESS,
   data
})

export const getOfferLogsError = (errorMsg:any) : IGetOfferLogsError => ({
    type: GetOfferLogsTypes.GET_OFFER_LOGS_FAILD,
    error:errorMsg
})

export const getOfferLogsFilter = (payload: any): IGetOfferLogsFilter => ({
    type: GetOfferLogsTypes.GET_OFFER_LOGS_FILTER,
    payload
})

export const getOfferLogsFilterDebounce = (payload: any): IGetOfferLogsFiterDebounce => ({
    type: GetOfferLogsTypes.GET_OFFER_LOGS_FILTER_DEBOUNCE,
    payload 
})
