import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { getAnnouncement } from 'src/apis/service/Announcements';
import { getAnnouncementsFaild, getAnnouncementsIsFetching, getAnnouncementsSuccess, GetAnnouncementsType, setFilterAnnouncements } from 'src/store/actions';

function* getListAnnouncementsSaga(action: any): Generator<any> {
    yield put(getAnnouncementsIsFetching(true))
    const { payload } = action;
    try {
      const data = yield call(getAnnouncement, payload);     
      if(data) {
        yield put(getAnnouncementsSuccess(data));
      }  
    } catch (error) {
        yield put(getAnnouncementsFaild(error));
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterAnnouncements(action.payload));
}

export function* watchGetListAnnouncements() {
  yield takeEvery(GetAnnouncementsType.GET_ANNOUNCEMENTS , getListAnnouncementsSaga)
  yield debounce(500, GetAnnouncementsType.SET_LIST_FILTER_DEBOUNCE_ANNOUNCEMENTS, handleSearchDebounce)
}