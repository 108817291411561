interface IDataUserDetail {
  data: Object;
  error: any;
  success: boolean;
}

export interface IUserDetailState {
  data: IDataUserDetail;
  isFetching: boolean;
}

export const initialUserDetailState: IUserDetailState = {
  data: {
    data: {},
    error: null,
    success: false,
  },
  isFetching: false,
};
