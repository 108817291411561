

export interface IDataChangePassword {
    data: Object | null;
    errors: any;
    success: boolean;
}

export interface IChangePassState {
   data: IDataChangePassword,
   isFetching: boolean
}

export const initialChangePassState : IChangePassState = {
    data: {
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}