import { cryptoCurrencyTypes } from '../../../contants/wallet';

export interface IWalletBalanceObject { totalBalance: string, cryptoCurrency: cryptoCurrencyTypes };

interface IOverviewReqData {
        detail: {
            createdAt: string,
            updatedAt: string,
            id: number,
            contactPointEmail: string,
            contactPointPhoneNumber: string,
            productName: string,
            imageUrl: string,
            websiteUrl: string,
            address: string,
            contractNo: string,
            contractPointName: string,
            contractStartDate: string,
            contractEndDate: string,
            apiKey: string,
            apiKeyStatus: string,
            updatedBy: string,
            generatedAt: string,
            deletedAt: string,
        },
        walletBalances: IWalletBalanceObject[]
}

export interface IReqDataCryptoWalletNewOverviewGet {
    data: IOverviewReqData | null;
    error: any;
    success: boolean;
}

export interface IListCryptoWalletGetNewOverviewState {
    requestData: IReqDataCryptoWalletNewOverviewGet;
    isFetching: boolean,
}

export const initialListCryptoWalletGetNewOverviewState: IListCryptoWalletGetNewOverviewState = {
    requestData: {
        data: null,
        error: null,
        success: false
    },
    isFetching: false,
}

