import { AnyAction, Reducer } from 'redux';
import { IHandleRetryTransactionState, initialHandleRetryTransactionState } from './state';
import { HandleRetryTransactionTypes } from 'src/store/actions/handleRetryTransaction';

export const HandleRetryTransactionReducer: Reducer = (
  state: IHandleRetryTransactionState = initialHandleRetryTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case HandleRetryTransactionTypes.HANDLE_RETRY_TRANSACTION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
