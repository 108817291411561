import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetOffersTypes} from '../../actions/getOffers';
import {getOffersFetching,getOffersSuccess,getOffersError, getOffersFilter} from '../../actions/getOffers/action';
import { getOffers } from 'src/apis/service/CryptoExchange';

function* getOffersSaga(action:any):Generator<any> {
    yield put(getOffersFetching(true))
    const {payload} = action;
    try {
      const data:any = yield getOffers(payload)
      if(data && data?.status === 200){
        yield put(getOffersSuccess(data))
      } else {
        yield put(getOffersError('error'))
      }
    } catch (error) {
        yield put(getOffersError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getOffersFilter(action.payload));
}
export function* watchGetOffers() {
   yield takeEvery(GetOffersTypes.GET_OFFERS, getOffersSaga)
   yield debounce(500,GetOffersTypes.GET_OFFERS_FILTER_DEBOUNCE,handleSearchDebounce)
}
