import { AnyAction, Reducer } from 'redux';
import { initialP2PExRankAndScoreState, IDataP2PExRankAndScoreState } from './state';
import { GetP2PExRankAndScoreType } from '../../actions/getP2PExRankAndScore';


export const GetP2PExRankAndScoreReducer: Reducer = (
  state: IDataP2PExRankAndScoreState = initialP2PExRankAndScoreState,
  action: AnyAction
) => {
  switch (action.type) {
      case GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE: {
         return {
             ...state,
             isFetching:true
         }
      }
      case GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE_SUCCESS: {
          return {
              ...state,
              data: action.data,
              isFetching:false,
              pagination: action.data.data.totalCount
          }
      }
      case GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE_FAILD: {
        return {
            ...state,
            error: action.error,
            isFetching:false
        }
    }
    case GetP2PExRankAndScoreType.GET_FILTER_P2PEX_RANK_AND_SCORE: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:{
      return state
    }
  }
}