import { AnyAction, Reducer } from 'redux';
import { initialListWalletState, IListWalletState } from './state';
import { GetListWalletTypes } from '../../actions/getListWallet/actionTypes';


export const GetListWalletReducer: Reducer = (
  state: IListWalletState = initialListWalletState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetListWalletTypes.GET_LIST_WALLET: {
      return {
        ...state,
        dataWalletOfOwner: initialListWalletState.dataWalletOfOwner,
        isFetching: true,
      };
    }
    case GetListWalletTypes.GET_LIST_WALLET_OF_OWNER_SUCCESS: {
      return {
        ...state,
        dataWalletOfOwner: action.data,
        isFetching: false,
      };
    }
    case GetListWalletTypes.GET_LIST_WALLET_OF_USER_SUCCESS: {
      return {
        ...state,
        dataWalletOfUser: action.data,
        isFetching: false,
      };
    }
    case GetListWalletTypes.GET_LIST_WALLET_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    default:
      return state;
  }
};