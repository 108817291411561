import { GetKycListHistoryTypes } from './actionTypes';
import {
   IGetKycListHistory,
   IGetKycListHistoryFaild,
   IGetKycListHistoryFetching,
   IGetKycListHistorySuccess,
   IGetListFiterListKycHistory
} from './interfaces';

export const getKycListHistoryAtFetching = (isFetching:boolean) : IGetKycListHistoryFetching  => ({
    type: GetKycListHistoryTypes.GET_KYC_LIST_HISTORY_FETCHING,
    isFetching
})

export const getKycListHistory = (payload:Object) : IGetKycListHistory => ({
    type: GetKycListHistoryTypes.GET_KYC_LIST_HISTORY,
    payload
})

export const getKycListHistorySuccess = (data:any) : IGetKycListHistorySuccess => ({
    type: GetKycListHistoryTypes.GET_KYC_LIST_HISTORY_SUCCESS,
    data
})

export const getKycListHistoryFaild = (error:any) : IGetKycListHistoryFaild => ({
    type: GetKycListHistoryTypes.GET_KYC_LIST_HISTORY_FAILD,
    error
})

export const setFilterListKycHistory = (payload: any) : IGetListFiterListKycHistory => ({
    type: GetKycListHistoryTypes.GET_LIST_FILTER_LIST_HISTORY,
    payload
})