import { GetUserTypes } from './actionTypes';
import { IGetUser, IGetUserFetching, IGetUserSuccess, IGetUserError, IGetUserFilter, IGetUserFiterDebounce } from "./interfaces";


export const getUser = (): IGetUser => ({
    type: GetUserTypes.GET_USER
})

export const getUserFetching = (isFetching: boolean): IGetUserFetching => ({
    type: GetUserTypes.GET_USER_FETCHING,
    isFetching
})

export const getUserSuccess = (data: any): IGetUserSuccess => ({
    type: GetUserTypes.GET_USER_SUCCESS,
    data
})

export const getUserError = (errorMsg: any): IGetUserError => ({
    type: GetUserTypes.GET_USER_FAILD,
    error: errorMsg
})

export const getUserFilter = (payload: any): IGetUserFilter => ({
    type: GetUserTypes.GET_USER_FILTER,
    payload
})

export const getUserFilterDebounce = (payload: any): IGetUserFiterDebounce => ({
    type: GetUserTypes.GET_USER_FILTER_DEBOUNCE,
    payload
})
