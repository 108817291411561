interface SetOrAddAgentRankBO {
    data: any,
    status: number,
    message: string
}

export interface SetOrAddAgentRankState {
    data: SetOrAddAgentRankBO,
    isFetching: boolean
    successCount: number
}

export const initialSetAgentRankState: SetOrAddAgentRankState = {
    data: {
        data: false,
        message: '',
        status: 0
    },
    isFetching: false,
    successCount: 0
}