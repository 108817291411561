import { AgentTransactionConfirmTypes } from './actionTypes';
import { IAgentTransactionConfirm, IAgentTransactionConfirmFetching, IAgentTransactionConfirmSuccess, IAgentTransactionConfirmError, IAgentTransactionConfirmFilter, IAgentTransactionConfirmFiterDebounce } from "./interfaces";


export const agentTransactionConfirm = (payload: Object, getData: any): IAgentTransactionConfirm => ({
    type: AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM,
    payload, getData
})

export const agentTransactionConfirmFetching = (isFetching: boolean): IAgentTransactionConfirmFetching => ({
    type: AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_FETCHING,
    isFetching
})

export const agentTransactionConfirmSuccess = (data: any): IAgentTransactionConfirmSuccess => ({
    type: AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_SUCCESS,
    data
})

export const agentTransactionConfirmError = (errorMsg: any): IAgentTransactionConfirmError => ({
    type: AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_FAILD,
    error: errorMsg
})

export const agentTransactionConfirmFilter = (payload: any): IAgentTransactionConfirmFilter => ({
    type: AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_FILTER,
    payload
})

export const agentTransactionConfirmFilterDebounce = (payload: any): IAgentTransactionConfirmFiterDebounce => ({
    type: AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_FILTER_DEBOUNCE,
    payload
})
