import { GetAllKYCTypes } from './actionTypes';
import { IGetAllKYC, IGetAllKYCFetching, IGetAllKYCSuccess, IGetAllKYCError, IGetAllKYCFilter, IGetAllKYCFiterDebounce } from "./interfaces";


export const getAllKYC = (): IGetAllKYC => ({
    type: GetAllKYCTypes.GET_ALL_KYC,
})

export const getAllKYCFetching = (isFetching: boolean): IGetAllKYCFetching => ({
    type: GetAllKYCTypes.GET_ALL_KYC_FETCHING,
    isFetching
})

export const getAllKYCSuccess = (data: any): IGetAllKYCSuccess => ({
    type: GetAllKYCTypes.GET_ALL_KYC_SUCCESS,
    data
})

export const getAllKYCError = (errorMsg: any): IGetAllKYCError => ({
    type: GetAllKYCTypes.GET_ALL_KYC_FAILD,
    error: errorMsg
})

export const getAllKYCFilter = (payload: any): IGetAllKYCFilter => ({
    type: GetAllKYCTypes.GET_ALL_KYC_FILTER,
    payload
})

export const getAllKYCFilterDebounce = (payload: any): IGetAllKYCFiterDebounce => ({
    type: GetAllKYCTypes.GET_ALL_KYC_FILTER_DEBOUNCE,
    payload
})
