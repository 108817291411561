import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetLanguageForBO } from '../../../apis/service/user';
import { GetLanguageForBOTypes } from '../../actions/getLanguageForBO';
import { getLanguageForBOError, getLanguageForBOFetching, getLanguageForBOFilter, getLanguageForBOSuccess } from '../../actions/getLanguageForBO/action';
import { setStorageItem } from 'src/utils/storage';
import i18n from 'src/translation/i18n';
import i18next from 'i18next';

function* getLanguageForBOSaga(action:any):Generator<any> {
    yield put(getLanguageForBOFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetLanguageForBO(payload)
      if(data && data?.status === 200){
        if(payload === 'en'){
          // i18next.addResourceBundle('en', '', JSON.stringify(data?.data));
          // setStorageItem('listLanguageEN', JSON.stringify(data?.data))
          // if(i18n.language === 'en'){
          //   i18n.changeLanguage(i18n.language);
          // }
        }else{
          // setStorageItem('listLanguageKO',JSON.stringify(data?.data))
          // if(i18n.language === 'ko'){
          //   i18n.changeLanguage(i18n.language);
          // }
        }
        yield put(getLanguageForBOSuccess(data))
      } else {
        yield put(getLanguageForBOError('error'))
      }
    } catch (error) {
        yield put(getLanguageForBOError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getLanguageForBOFilter(action.payload));
}
export function* watchGetLanguageForBO() {
   yield takeEvery(GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO, getLanguageForBOSaga)
   yield debounce(500,GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO_FILTER_DEBOUNCE,handleSearchDebounce)
}
