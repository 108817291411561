interface IDataListNotificationInFirebase {
  status: number;
  data: {
    items: any[];
    totalCount: number;
    unreadCount: number;
  };
  message: string;
}

export interface IListNotificationFirebaseState {
  data: IDataListNotificationInFirebase;
  filter: any;
  isFetching: boolean;
  pagination: any;
  newNotifications: any[];
}

export const initialListNotificationFirebaseState: IListNotificationFirebaseState = {
  data: {
    data:{
        items:[],
        totalCount:0,
        unreadCount:0
    },
    message:'',
    status:0
  },
  filter: {
    pageNumber: 1,
    pageSize: 10
  },
  isFetching: false,
  pagination: {
    totalCount: 0,
  },
  newNotifications:[]
};
