import { ListParams } from "src/models/common";

interface IDataGetTransactionsFilterTypes {
    data: any[];
    error: any;
    success: boolean;
}

export interface IGetTransactionsFilterTypesState {
    data: IDataGetTransactionsFilterTypes;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetTransactionsFilterTypesState: IGetTransactionsFilterTypesState = {
    data: {
        data: [],
        error: null,
        success: false
    },
    isFetching: false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


