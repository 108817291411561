import {ExportCryptoReceiveTransactionType} from './actionTypes';
import {
    IExportCryptoReceiveTransactionIsFetching,
    IExportCryptoReceiveTransactionSuccess,
    IExportCryptoReceiveTransactionFaild,
    IExportCryptoReceiveTransaction
} from './interfaces';

export const exportCryptoReceiveTransactionAtFetching = (isFetching:boolean) : IExportCryptoReceiveTransactionIsFetching  => ({
    type: ExportCryptoReceiveTransactionType.EXPORT_CRYPTO_RECEIVE_TRANSACTION_FETCHING,
    isFetching
})

export const exportCryptoReceiveTransaction = (payload:Object, xlsx:boolean) : IExportCryptoReceiveTransaction => ({
    type: ExportCryptoReceiveTransactionType.EXPORT_CRYPTO_RECEIVE_TRANSACTION,
    payload,
    xlsx
})

export const exportCryptoReceiveTransactionSuccess = (data:any) : IExportCryptoReceiveTransactionSuccess => ({
    type: ExportCryptoReceiveTransactionType.EXPORT_CRYPTO_RECEIVE_TRANSACTION_SUCCESS,
    data
})

export const exportCryptoReceiveTransactionFaild = (error:any) : IExportCryptoReceiveTransactionFaild => ({
    type: ExportCryptoReceiveTransactionType.EXPORT_CRYPTO_RECEIVE_TRANSACTION_FAILD,
    error
})
