import { AgentSellSearchTypes } from "./actionTypes";
import {
  IAgentSellSearch,
  IAgentSellSearchFetching,
  IAgentSellSearchSuccess,
  IAgentSellSearchError,
  IAgentSellSearchFilter,
  IAgentSellSearchFiterDebounce,
} from "./interfaces";

export const agentSellSearch = (
  payload: Object,
  getDataAfterSuccess: any
): IAgentSellSearch => ({
  type: AgentSellSearchTypes.AGENT_SELL_SEARCH,
  payload,
  getDataAfterSuccess,
});

export const agentSellSearchFetching = (
  isFetching: boolean
): IAgentSellSearchFetching => ({
  type: AgentSellSearchTypes.AGENT_SELL_SEARCH_FETCHING,
  isFetching,
});

export const agentSellSearchSuccess = (data: any): IAgentSellSearchSuccess => ({
  type: AgentSellSearchTypes.AGENT_SELL_SEARCH_SUCCESS,
  data,
});

export const agentSellSearchError = (errorMsg: any): IAgentSellSearchError => ({
  type: AgentSellSearchTypes.AGENT_SELL_SEARCH_FAILD,
  error: errorMsg,
});

export const agentSellSearchFilter = (
  payload: any
): IAgentSellSearchFilter => ({
  type: AgentSellSearchTypes.AGENT_SELL_SEARCH_FILTER,
  payload,
});

export const agentSellSearchFilterDebounce = (
  payload: any
): IAgentSellSearchFiterDebounce => ({
  type: AgentSellSearchTypes.AGENT_SELL_SEARCH_FILTER_DEBOUNCE,
  payload,
});
