import { AnyAction, Reducer } from 'redux';
import { GetOfferAllTypes } from 'src/store/actions';
import { IOfferAllState, initialOfferAllState } from './state';


export const GetOfferAllReducer: Reducer = (
  state: IOfferAllState = initialOfferAllState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetOfferAllTypes.GET_OFFER_ALL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetOfferAllTypes.GET_OFFER_ALL_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount
      };
    }
    case GetOfferAllTypes.GET_OFFER_ALL_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case GetOfferAllTypes.GET_LIST_FILTER_OFFER_ALL: {
      return {
          ...state,
          filter: action.payload
      }
    }
      default:
        return state;
    }
};