import { debounce, put, takeEvery } from 'redux-saga/effects';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { handleCheckPasswordAdmin, sendOTP } from '../../../apis/service/user';
import { CheckPasswordAdminTypes } from '../../actions/checkPasswordAdmin';
import { checkPasswordAdminError, checkPasswordAdminFetching, checkPasswordAdminFilter, checkPasswordAdminSuccess } from '../../actions/checkPasswordAdmin/action';

function* checkPasswordAdminSaga(action: any): Generator<any> {
  yield put(checkPasswordAdminFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleCheckPasswordAdmin(payload)
    if (data && data?.status === 200) {
      toastSuccess('bo_check_phone_success_msg');
      yield sendOTP(payload);
      yield put(checkPasswordAdminSuccess(data))
    } else {
      if (data.message === "password.incorrect") {
        toastError("bo_password_incorrect");
      }else if (data.message === "user.locked") {
        toastError('bo_user_locked');
      } else {
        toastError('bo_login_error');
      }
      yield put(checkPasswordAdminError(data));
    }
  } catch (error) {
    toastError('bo_login_error');
    // yield put(checkPasswordAdminError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(checkPasswordAdminFilter(action.payload));
}
export function* watchCheckPasswordAdmin() {
  yield takeEvery(CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN, checkPasswordAdminSaga)
  yield debounce(500, CheckPasswordAdminTypes.CHECK_PASSWORD_ADMIN_FILTER_DEBOUNCE, handleSearchDebounce)
}
