
interface IDataListKycStageUpdate {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListKycStageUpdateState {
    data: IDataListKycStageUpdate;
    isFetching: boolean,
    success: boolean,
}

export const initialListKycStageUpdateState: IListKycStageUpdateState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    success: false
}

