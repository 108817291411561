import { AnyAction, Reducer } from 'redux';
import { initialCreatePinState, CreatePinState } from './state';
import { CreatePinType } from '../../actions/createPIN/actionTypes';
import { toastSuccess, toastError } from '../../../helpers/toastHelper';

export const CreatePinReducer: Reducer = (
  state: CreatePinState = initialCreatePinState,
  action: AnyAction
) => {

  switch (action.type) {
    case CreatePinType.CREATE_PIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreatePinType.CREATE_PIN_SUCCESS: {


      toastSuccess('bo_reset_pin_success');
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case CreatePinType.CREATE_PIN_ERROR: {
      toastError(action.error);
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
