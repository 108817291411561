
import Request from "../../config";
import * as Sentry from "@sentry/react";
const requestApi = new Request({url: process.env.REACT_APP_BASE_ADMIN_CRYPTO_URL});

const getOfferHistories = async (payload:any): Promise<any> => {
    try {
        return await requestApi.get(`/offers/${payload.offerId}/histories`, { params: payload})
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const getOffers = async (payload:any): Promise<any> => {
    try {
        return await requestApi.get(`/offers`, { params: payload})
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const getOfferDetail = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.get(`/offers/${payload.offerId}`, { params: payload})
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const handleBlockOffer = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.post(`/offers/${payload.offerId}/block`, { status: payload.status }, )
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const updateOfferNote = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.patch(`/offers/${payload.offerId}/note`, { note: payload.note }, )
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const handleDeleteOffer = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.delete(`/offers/${payload.offerId}`, )
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const handleExportOffer  = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.get(`/offers/${payload.offerId}/note`, )
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const getOfferLogs  = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.get(`/offers/${payload.offerId}/logs`, )
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const exportOfferToExcel = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.get(`/offers/export`, {headers: { responseType: 'blob',  'Content-Disposition': "attachment; filename=template.xlsx", 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}, params: payload, responseType: 'arraybuffer'})
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

const exportHistoryToExcel = async (payload:any) : Promise<any> => {
    try {
        return await requestApi.get(`/offers/${payload.offerId}/histories/export`, {headers: { responseType: 'blob',  'Content-Disposition': "attachment; filename=template.xlsx", 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}, params: payload, responseType: 'arraybuffer'})
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

export {
    getOfferHistories,
    getOfferDetail,
    handleBlockOffer,
    updateOfferNote,
    handleExportOffer,
    handleDeleteOffer,
    exportOfferToExcel,
    exportHistoryToExcel,
    getOffers,
    getOfferLogs
}