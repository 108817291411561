import { put, takeEvery, debounce } from 'redux-saga/effects';
import { ApplyKYCUserInfoTypes } from '../../actions/ApplyKYCUserInfo';
import { applyKYCUserInfoFetching, applyKYCUserInfoSuccess, applyKYCUserInfoError, applyKYCUserInfoFilter } from '../../actions/ApplyKYCUserInfo/action';
import { handleApplyKYCUserInfo } from '../../../apis/service/user';
import { getAllKYC } from 'src/store/actions/getAllKYC';

function* applyKYCUserInfoSaga(action: any): Generator<any> {
  yield put(applyKYCUserInfoFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleApplyKYCUserInfo(payload)
    if (data && data?.status === 200) {
      yield put(getAllKYC())
      yield put(applyKYCUserInfoSuccess(data))
    } else {
      yield put(applyKYCUserInfoError('error'))
    }
  } catch (error) {
    yield put(applyKYCUserInfoError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(applyKYCUserInfoFilter(action.payload));
}
export function* watchApplyKYCUserInfo() {
  yield takeEvery(ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO, applyKYCUserInfoSaga)
  yield debounce(500, ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_FILTER_DEBOUNCE, handleSearchDebounce)
}
