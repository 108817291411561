import { KycStage } from 'src/models/KycUser';
import { UpdateKycStageType } from './actionTypes';
import { IFetchingUpdateKycStage, IUpdateKycStage, IUpdateKycStageError, IUpdateKycStageReset, IUpdateKycStageSuccess } from './interfaces';

export const updateKycStageIsFetching = (isFetching:boolean) : IFetchingUpdateKycStage => ({
    type: UpdateKycStageType.UPDATE_KYC_STAGE_FETCHING,
    isFetching
})

export const updateKycStage = (payload: KycStage, kycId: string): IUpdateKycStage => ({
    type: UpdateKycStageType.UPDATE_KYC_STAGE,
    payload,
    kycId
})

export const updateKycStageSuccess = (data:any): IUpdateKycStageSuccess => ({
    type: UpdateKycStageType.UPDATE_KYC_STAGE_SUCCESS,
    data
})

export const updateKycStageFaild = (error:any): IUpdateKycStageError => ({
    type: UpdateKycStageType.UPDATE_KYC_STAGE_ERROR,
    error
})

export const updateKycStageReset = (): IUpdateKycStageReset => ({
    type: UpdateKycStageType.UPDATE_KYC_STAGE_RESET,
})
