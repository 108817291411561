import {GetLanguageForBOTypes} from './actionTypes';
import { IGetLanguageForBO, IGetLanguageForBOFetching, IGetLanguageForBOSuccess, IGetLanguageForBOError, IGetLanguageForBOFilter, IGetLanguageForBOFiterDebounce } from "./interfaces";


export const getLanguageForBO = (payload:Object): IGetLanguageForBO => ({
    type: GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO,
    payload
})

export const getLanguageForBOFetching = (isFetching:boolean) : IGetLanguageForBOFetching => ({
    type: GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO_FETCHING,
    isFetching
})

export const getLanguageForBOSuccess = (data:any) : IGetLanguageForBOSuccess => ({
   type: GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO_SUCCESS,
   data
})

export const getLanguageForBOError = (errorMsg:any) : IGetLanguageForBOError => ({
    type: GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO_FAILD,
    error:errorMsg
})

export const getLanguageForBOFilter = (payload: any): IGetLanguageForBOFilter => ({
    type: GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO_FILTER,
    payload
})

export const getLanguageForBOFilterDebounce = (payload: any): IGetLanguageForBOFiterDebounce => ({
    type: GetLanguageForBOTypes.GET_LANGUAGE_FOR_BO_FILTER_DEBOUNCE,
    payload 
})
