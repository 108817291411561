import { toastError, toastSuccess } from './../../../helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleAgentCancelTransaction } from 'src/apis/service/P2PTransaction';
import { AgentCancelTransactionTypes } from '../../actions/agentCancelTransaction';
import { agentCancelTransactionError, agentCancelTransactionFetching, agentCancelTransactionFilter, agentCancelTransactionSuccess } from '../../actions/agentCancelTransaction/action';

function* agentCancelTransactionSaga(action: any): Generator<any> {


  yield put(agentCancelTransactionFetching(true))
  const { payload, getData } = action;
  try {
    const data: any = yield handleAgentCancelTransaction(payload)
    if (data?.status === 200) {
      // yield put(agentCancelTransactionSuccess(data))
      getData();
      toastSuccess('bo_cancel_transaction_success');
    } else {
      toastError('bo_you_can_t_cancel_transaction');
      yield put(agentCancelTransactionError('error'))
    }
  } catch (error) {
    toastError('bo_set_rank_for_user_fail');
    yield put(agentCancelTransactionError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentCancelTransactionFilter(action.payload));
}
export function* watchAgentCancelTransaction() {
  yield takeEvery(AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION, agentCancelTransactionSaga)
  yield debounce(500, AgentCancelTransactionTypes.AGENT_CANCEL_TRANSACTION_FILTER_DEBOUNCE, handleSearchDebounce)
}
