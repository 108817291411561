import { AnyAction, Reducer } from 'redux';
import { initialReactiveUserState, ReactiveUserState } from './state';
import { toastSuccess, toastError } from '../../../helpers/toastHelper';
import { ReactiveUserType } from 'src/store/actions/reactiveUser';

export const ReactiveUserReducer: Reducer = (
  state: ReactiveUserState = initialReactiveUserState,
  action: AnyAction
) => {


  switch (action.type) {
    case ReactiveUserType.REACTIVE_USER: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ReactiveUserType.REACTIVE_USER_SUCCESS: {

      toastSuccess('bo_reactive_user_success');
      return {
        ...state,
        data: action.data,
        isFetching: false,
        countSuccess: state.countSuccess + 1
      };
    }
    case ReactiveUserType.REACTIVE_USER_ERROR: {

      toastError('bo_can_t_reactivate_user');
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
