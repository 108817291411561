import { put, takeLatest } from 'redux-saga/effects';
import { handleCheckPhone } from 'src/apis/service/user';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import {
  checkPhoneSuccess,
  checkPhoneFaild,
  checkPhoneAtFetching,
  CheckPhoneTypes
} from '../../actions';
function* checkPhoneNumber(action: any): Generator<any> {
  yield put(checkPhoneAtFetching(true))
  const { payload } = action;
  try {
    const dataCheckWhenSuccess: any = yield handleCheckPhone(payload)
    if (dataCheckWhenSuccess?.status === 200) {
      const data = {
        ...dataCheckWhenSuccess,
        success: true
      }
      yield put(checkPhoneSuccess(data));
      toastSuccess('bo_check_phone_success_msg');
    } else {
      const data = {
        ...dataCheckWhenSuccess,
        success: false
      }
      yield put(checkPhoneFaild(data));
      if (dataCheckWhenSuccess.message === "user.locked") {
        toastError('bo_user_locked');
      } else if (dataCheckWhenSuccess.message === "login.notfound"){
        toastError('bo_phone_number_not_found');
      } else {
        toastError('bo_error');
      }
    }
  } catch (error) {
    toastError('bo_error');
  }
}

export function* watchCheckPhone(): any {
  yield takeLatest(CheckPhoneTypes.CHECK_PHONE, checkPhoneNumber);
}