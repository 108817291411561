import {GetListWalletTypes} from './actionTypes';
import {
    IGetListWallet,
    IGetListWalletFetching,
    IGetListWalletOfOwnerSuccess,
    IGetListWalletOfUserSuccess,
    IGetListWalletFaild
} from './interfaces';

export const getListWalletrAtFetching = (isFetching:boolean) : IGetListWalletFetching  => ({
    type: GetListWalletTypes.GET_LIST_WALLET_FETCHING,
    isFetching
})

export const getListWallet = (payload: number, owner: boolean) : IGetListWallet => ({
    type: GetListWalletTypes.GET_LIST_WALLET,
    payload,
    owner
})

export const getListWalletOfOwnerSuccess = (data:any) : IGetListWalletOfOwnerSuccess => ({
    type: GetListWalletTypes.GET_LIST_WALLET_OF_OWNER_SUCCESS,
    data
})

export const getListWalletOfUserSuccess = (data:any) : IGetListWalletOfUserSuccess => ({
    type: GetListWalletTypes.GET_LIST_WALLET_OF_USER_SUCCESS,
    data
})

export const getListWalletFaild = (error:any) : IGetListWalletFaild => ({
    type: GetListWalletTypes.GET_LIST_WALLET_FAILD,
    error
})
