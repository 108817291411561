import { GetListCryptoWalletTypes } from './actionTypes';
import { IGetListCryptoWallet, IGetListCryptoWalletFetching, IGetListCryptoWalletSuccess, IGetListCryptoWalletError, IGetListCryptoWalletFilter, IGetListCryptoWalletFiterDebounce } from "./interfaces";


export const getListCryptoWallet = (): IGetListCryptoWallet => ({
    type: GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET
})

export const getListCryptoWalletFetching = (isFetching: boolean): IGetListCryptoWalletFetching => ({
    type: GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_FETCHING,
    isFetching
})

export const getListCryptoWalletSuccess = (data: any): IGetListCryptoWalletSuccess => ({
    type: GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_SUCCESS,
    data
})

export const getListCryptoWalletError = (errorMsg: any): IGetListCryptoWalletError => ({
    type: GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_FAILD,
    error: errorMsg
})

export const getListCryptoWalletFilter = (payload: any): IGetListCryptoWalletFilter => ({
    type: GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_FILTER,
    payload
})

export const getListCryptoWalletFilterDebounce = (payload: any): IGetListCryptoWalletFiterDebounce => ({
    type: GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_FILTER_DEBOUNCE,
    payload
})
