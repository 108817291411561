import { debounce, put, takeLatest } from 'redux-saga/effects';
import { handlePostP2PExchangeRankAndScore } from 'src/apis/service/P2PExchange';
import { getP2PExRankAndScoreError, getP2PExRankAndScoreFetching, getP2PExRankAndScoreSuccess, GetP2PExRankAndScoreType, setFilterP2PExRankAndScore } from 'src/store/actions';

function* getP2PExRankAndScore(action: any): Generator<any> {
  yield put(getP2PExRankAndScoreFetching(true))
  const { payload } = action;
  try {
    const dataListUserScore: any = yield handlePostP2PExchangeRankAndScore(payload)
    if (dataListUserScore.status === 200) {
      yield put(getP2PExRankAndScoreSuccess(dataListUserScore))
    }
  } catch (error) {
    yield put(getP2PExRankAndScoreError(error))
  }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterP2PExRankAndScore(action.payload));
}

export function* watchGetP2PExRankAndScore() {
  yield takeLatest(GetP2PExRankAndScoreType.GET_P2PEX_RANK_AND_SCORE, getP2PExRankAndScore);
  yield debounce(500, GetP2PExRankAndScoreType.GET_FILTER_DEBOUNCE_P2PEX_RANK_AND_SCORE, handleSearchDebounce);
}