import {CryptWalletDashboardActionTypes} from './actionTypes';
import {
    ICryptoWalletDashboardReqSuccess,
    ICryptoWalletDashboardDataFetching,
    ICryptoWalletDashboardDataReq,
    ICryptoWalletDashboardReqError
} from './interfaces';
import {ICryptoWalletDashboardReqData} from "../../reducers/cryptoWalletDashboard";

export const cryptoWalletDashboardIsFetching = (isFetching: boolean): ICryptoWalletDashboardDataFetching => ({
    type: CryptWalletDashboardActionTypes.CRYPTO_WALLET_DASHBOARD_IS_FETCHING,
    isFetching
})

export const getCryptoWalletDashboardDataReq = (action: any): ICryptoWalletDashboardDataReq => ({
    type: CryptWalletDashboardActionTypes.GET_CRYPTO_WALLET_DASHBOARD_DATA,
    action
})

export const cryptoWalletDashboardReqSuccess = (data: ICryptoWalletDashboardReqData): ICryptoWalletDashboardReqSuccess => ({
    type: CryptWalletDashboardActionTypes.CRYPTO_WALLET_DASHBOARD_SUCCESS,
    data
})

export const cryptoWalletDashboardReqFailed = (error: any): ICryptoWalletDashboardReqError => ({
    type: CryptWalletDashboardActionTypes.CRYPTO_WALLET_DASHBOARD_ERROR,
    error
})
