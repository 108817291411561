import { lazy } from 'react';
import { agentRouterConstants } from '../routerConstants';
const App = lazy(() => import('src/shade/layouts/App'));

const AgentDashboard = lazy(() => import('src/agentPages/dashboard'));

const DirectTransfer = lazy(
  () => import('src/agentPages/transactionManagement/directTransfer')
);

const P2PTransaction = lazy(
  () => import('src/agentPages/transactionManagement/transaction')
);

const Invoice = lazy(
  () => import('src/agentPages/transactionManagement/invoice')
);

const InvoiceDetail = lazy(
  () => import('src/agentPages/transactionManagement/invoice/detail')
);

const P2POffer = lazy(
  () => import('src/agentPages/transactionManagement/offer')
);

const P2POfferDetail = lazy(
  () => import('src/agentPages/transactionManagement/offer/detail')
);

const LinkedAccount = lazy(
  () => import('src/agentPages/setting/linkedAccount')
);

// const ExchangeTransaction = lazy(
//   () => import("src/agentPages/transactionManagement/exchange")
// );

const AgentKYC = lazy(() => import('src/agentPages/setting/kyc'));

const HolaMoneyWallet = lazy(() => import('src/agentPages/myWallet/holaMoney'));
const CryptoWallet = lazy(
  () => import('src/agentPages/myWallet/crypto/cryptoWallet')
);
const CryptoDetail = lazy(
  () => import('src/agentPages/myWallet/crypto/cryptoDetail')
);

const ExchangeMyOffer = lazy(
  () => import('src/agentPages/offerManagement/exchange/myOffer')
);
const ExchangeAllOffer = lazy(
  () => import('src/agentPages/offerManagement/exchange/allOffer')
);
const TwoFA = lazy(() => import('src/agentPages/setting/twoFA'));

export const agentPrivateRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: agentRouterConstants.AgentDashboard,
        element: <AgentDashboard />,
      },
      {
        path: agentRouterConstants.DirectTransferTransaction,
        element: <DirectTransfer />,
      },
      {
        path: agentRouterConstants.P2PBuyTransaction,
        element: <P2PTransaction />,
      },
      {
        path: agentRouterConstants.P2PSellTransaction,
        element: <P2PTransaction />,
      },
      { path: agentRouterConstants.InvoiceTransaction, element: <Invoice /> },
      {
        path: agentRouterConstants.InvoiceTransactionCreate,
        element: <InvoiceDetail />,
      },
      {
        path: agentRouterConstants.InvoiceTransactionDetail,
        element: <InvoiceDetail />,
      },
      // {
      //   path: agentRouterConstants.ExchangeTransaction,
      //   element: <ExchangeTransaction />,
      // },
      { path: agentRouterConstants.AgentKYC, element: <AgentKYC /> },
      { path: agentRouterConstants.P2PBuyOffer, element: <P2POffer /> },
      { path: agentRouterConstants.P2PSellOffer, element: <P2POffer /> },
      {
        path: agentRouterConstants.P2PBuyOfferDetail,
        element: <P2POfferDetail />,
      },
      {
        path: agentRouterConstants.P2PSellOfferDetail,
        element: <P2POfferDetail />,
      },
      {
        path: agentRouterConstants.LinkedAccount,
        element: <LinkedAccount />,
      },
      {
        path: agentRouterConstants.HolaMoneyWallet,
        element: <HolaMoneyWallet />,
      },
      {
        path: agentRouterConstants.ExchangeMyOffer,
        element: <ExchangeMyOffer />,
      },
      {
        path: agentRouterConstants.ExchangeAllOffer,
        element: <ExchangeAllOffer />,
      },
      {
        path: agentRouterConstants.CryptoWallet,
        element: <CryptoWallet />,
      },
      {
        path: agentRouterConstants.TokenDetail,
        element: <CryptoDetail />,
      },
      {
        path: agentRouterConstants.TwoFA,
        element: <TwoFA />,
      },
    ],
  },
];
