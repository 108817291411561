import {CreateRankPeriodConfigTypes} from './actionTypes';
import { ICreateRankPeriodConfig, ICreateRankPeriodConfigFetching, ICreateRankPeriodConfigSuccess, ICreateRankPeriodConfigError, ICreateRankPeriodConfigFilter, ICreateRankPeriodConfigFiterDebounce } from "./interfaces";


export const createRankPeriodConfig = (payload:Object): ICreateRankPeriodConfig => ({
    type: CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG,
    payload
})

export const createRankPeriodConfigFetching = (isFetching:boolean) : ICreateRankPeriodConfigFetching => ({
    type: CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_FETCHING,
    isFetching
})

export const createRankPeriodConfigSuccess = (data:any) : ICreateRankPeriodConfigSuccess => ({
   type: CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_SUCCESS,
   data
})

export const createRankPeriodConfigError = (errorMsg:any) : ICreateRankPeriodConfigError => ({
    type: CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_FAILD,
    error:errorMsg
})

export const createRankPeriodConfigFilter = (payload: any): ICreateRankPeriodConfigFilter => ({
    type: CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_FILTER,
    payload
})

export const createRankPeriodConfigFilterDebounce = (payload: any): ICreateRankPeriodConfigFiterDebounce => ({
    type: CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_FILTER_DEBOUNCE,
    payload 
})
