import { RemoveNotificationType } from './actionTypes';
import { IFetchingRemoveNotification, IRemoveNotification, IRemoveNotificationError, IRemoveNotificationSuccess } from './interfaces';


export const removeNotificationIsFetching = (isFetching:boolean) : IFetchingRemoveNotification => ({
    type:RemoveNotificationType.REMOVE_NOTIFICATION_FETCHING,
    isFetching
})

export const removeNotification = (payload?:any) : IRemoveNotification => ({
    type:RemoveNotificationType.REMOVE_NOTIFICATION,
    payload
})

export const removeNotificationSuccess = (data:any) : IRemoveNotificationSuccess => ({
    type:RemoveNotificationType.REMOVE_NOTIFICATION_SUCCESS,
    data
})

export const removeNotificationFaild = (error:any) : IRemoveNotificationError => ({
    type:RemoveNotificationType.REMOVE_NOTIFICATION_ERROR,
    error
})