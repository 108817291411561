import { toastSuccess, toastError } from './../../../helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleAgentTransferredTransaction } from 'src/apis/service/P2PTransaction';
import { AgentTransferredTransactionTypes } from '../../actions/agentTransferredTransaction';
import { agentTransferredTransactionError, agentTransferredTransactionFetching, agentTransferredTransactionFilter, agentTransferredTransactionSuccess } from '../../actions/agentTransferredTransaction/action';

function* agentTransferredTransactionSaga(action: any): Generator<any> {

  yield put(agentTransferredTransactionFetching(true))
  const { payload, getData } = action;
  try {
    const data: any = yield handleAgentTransferredTransaction(payload)
    if (data?.status === 200) {
      // yield put(agentTransferredTransactionSuccess(data))
      getData();
      toastSuccess('bo_transferred_transaction_success');
    } else {
      toastError('bo_you_can_t_transferred_transaction');
      yield put(agentTransferredTransactionError('error'))
    }
  } catch (error) {
    toastError('bo_you_can_t_transferred_transaction');
    yield put(agentTransferredTransactionError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentTransferredTransactionFilter(action.payload));
}
export function* watchAgentTransferredTransaction() {
  yield takeEvery(AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION, agentTransferredTransactionSaga)
  yield debounce(500, AgentTransferredTransactionTypes.AGENT_TRANSFERRED_TRANSACTION_FILTER_DEBOUNCE, handleSearchDebounce)
}
