import { ListParams } from "src/models/common";

interface IDataGetBlockchainDashboard {
  data:any;
  error:any;
  success:boolean;
}

export interface IGetBlockchainDashboardState {
    data:IDataGetBlockchainDashboard;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetBlockchainDashboardState: IGetBlockchainDashboardState = {
    data:{
        data:null,
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


