import { GenerateCode2FATypes } from './actionTypes';
import { IGenerateCode2FA, IGenerateCode2FAFetching, IGenerateCode2FASuccess, IGenerateCode2FAError, IGenerateCode2FAFilter, IGenerateCode2FAFiterDebounce } from "./interfaces";


export const generateCode2FA = (): IGenerateCode2FA => ({
    type: GenerateCode2FATypes.GENERATE_CODE_2FA
})

export const generateCode2FAFetching = (isFetching: boolean): IGenerateCode2FAFetching => ({
    type: GenerateCode2FATypes.GENERATE_CODE_2FA_FETCHING,
    isFetching
})

export const generateCode2FASuccess = (data: any): IGenerateCode2FASuccess => ({
    type: GenerateCode2FATypes.GENERATE_CODE_2FA_SUCCESS,
    data
})

export const generateCode2FAError = (errorMsg: any): IGenerateCode2FAError => ({
    type: GenerateCode2FATypes.GENERATE_CODE_2FA_FAILD,
    error: errorMsg
})

export const generateCode2FAFilter = (payload: any): IGenerateCode2FAFilter => ({
    type: GenerateCode2FATypes.GENERATE_CODE_2FA_FILTER,
    payload
})

export const generateCode2FAFilterDebounce = (payload: any): IGenerateCode2FAFiterDebounce => ({
    type: GenerateCode2FATypes.GENERATE_CODE_2FA_FILTER_DEBOUNCE,
    payload
})
