import { AnyAction, Reducer } from 'redux';
import { IAdminP2PSetAgentScoreState, initialAdminP2PSetAgentScoreState } from './state';
import { AdminP2PSetAgentScoreTypes } from 'src/store/actions/adminP2PSetAgentScore';

export const AdminP2PSetAgentScoreReducer: Reducer = (
  state: IAdminP2PSetAgentScoreState = initialAdminP2PSetAgentScoreState,
  action: AnyAction
) => {
  switch (action.type) {
    case AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
        successCount: state.successCount + 1
      };
    }
    case AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case AdminP2PSetAgentScoreTypes.ADMINP2P_SET_AGENT_SCORE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
