import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetDashboardDataAPI } from 'src/apis/service/CryptoWallet';
import { GetBlockchainDashboardTypes } from '../../actions/getBlockchainDashboard';
import { getBlockchainDashboardError, getBlockchainDashboardFetching, getBlockchainDashboardFilter, getBlockchainDashboardSuccess } from '../../actions/getBlockchainDashboard/action';

function* getBlockchainDashboardSaga(action:any):Generator<any> {
    yield put(getBlockchainDashboardFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetDashboardDataAPI(payload)
      if(data && data?.status === 200){
        yield put(getBlockchainDashboardSuccess(data))
      } else {
        yield put(getBlockchainDashboardError('error'))
      }
    } catch (error) {
        yield put(getBlockchainDashboardError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getBlockchainDashboardFilter(action.payload));
}
export function* watchGetBlockchainDashboard() {
   yield takeEvery(GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD, getBlockchainDashboardSaga)
   yield debounce(500,GetBlockchainDashboardTypes.GET_BLOCKCHAIN_DASHBOARD_FILTER_DEBOUNCE,handleSearchDebounce)
}
