import { CreateAndEditAdminBOType } from './actionTypes';
import {
  IcreateAndeditAdminBO,
  IcreateAndeditAdminBOFaild,
  IcreateAndeditAdminBOFetching,
  IcreateAndeditAdminBOSuccess,
} from './interfaces';

export const createAndeditAdminBOAtFetching = (
  isFetching: boolean
): IcreateAndeditAdminBOFetching => ({
  type: CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO_FETCHING,
  isFetching,
});

export const createAndeditAdminBO = (
  payload: Object,
  history: any,
  isCreate: boolean,
  filters?: any
): IcreateAndeditAdminBO => ({
  type: CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO,
  payload,
  history,
  isCreate,
  filters,
});

export const createAndeditAdminBOSuccess = (
  data: any
): IcreateAndeditAdminBOSuccess => ({
  type: CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO_SUCCESS,
  data,
});

export const createAndeditAdminBOFaild = (
  error: any
): IcreateAndeditAdminBOFaild => ({
  type: CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO_FAILD,
  error,
});
