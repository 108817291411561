import { AnyAction, Reducer } from 'redux';
import { initialChangePassState, IChangePassState } from './state';
import { ChangePasswordType } from '../../actions/ChangePassword';
import { toastError, toastSuccess } from '../../../helpers/toastHelper';

export const ChangePasswordReducer: Reducer = (
  state: IChangePassState = initialChangePassState,
  action: AnyAction
) => {
  switch (action.type) {
      case ChangePasswordType.CHANGE_PASSWORD: {
         return {
             ...state,
             isFetching:true
         }
      }
      case ChangePasswordType.CHANGE_PASSWORD_SUCCESSS: {
  
          toastSuccess('bo_change_password_success')
          return {
              ...state,
              data: action.data,
              isFetching:false
          }
      }
      case ChangePasswordType.CHANGE_PASSWORD_ERROR: {
          toastError(action.error)
        return {
            ...state,
            error: action.error,
            isFetching:false
        }
    }
    default:{
      return state
    }
  }
}