import {ApplyKYCBankInfoTypes} from './actionTypes';
import { IApplyKYCBankInfo, IApplyKYCBankInfoFetching, IApplyKYCBankInfoSuccess, IApplyKYCBankInfoError, IApplyKYCBankInfoFilter, IApplyKYCBankInfoFiterDebounce } from "./interfaces";


export const applyKYCBankInfo = (payload:Object): IApplyKYCBankInfo => ({
    type: ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO,
    payload
})

export const ApplyKYCBankInfoFetching = (isFetching:boolean) : IApplyKYCBankInfoFetching => ({
    type: ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO_FETCHING,
    isFetching
})

export const ApplyKYCBankInfoSuccess = (data:any) : IApplyKYCBankInfoSuccess => ({
   type: ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO_SUCCESS,
   data
})

export const ApplyKYCBankInfoError = (errorMsg:any) : IApplyKYCBankInfoError => ({
    type: ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO_FAILD,
    error:errorMsg
})

export const ApplyKYCBankInfoFilter = (payload: any): IApplyKYCBankInfoFilter => ({
    type: ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO_FILTER,
    payload
})

export const ApplyKYCBankInfoFilterDebounce = (payload: any): IApplyKYCBankInfoFiterDebounce => ({
    type: ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO_FILTER_DEBOUNCE,
    payload 
})
