import { getUserDetail } from 'src/store/actions/getDataUserDetail';
import { put, takeEvery, select } from 'redux-saga/effects';
import { UnLockUserType } from '../../actions/unLockUser/actionTypes';
import { unLockUserIsFetching, unLockUserSuccess, unLockUserFaild } from '../../actions/unLockUser/action';
import { handleUnLockUser } from '../../../apis/service/user';
import { IApplicationState } from 'src/store/IApplicationState';
import { getListUserDeactivated } from 'src/store/actions';

function* UnLockUsereSaga(action: any): Generator<any> {
  yield put(unLockUserIsFetching(true))
  const { payload, listUserDeactive } = action;
  try {
    const dataUnLockUser: any = yield handleUnLockUser(payload)
    if (dataUnLockUser.status === 200) {
      yield put(getUserDetail(payload.userId));
      yield put(unLockUserSuccess(dataUnLockUser))
      // if (listUserDeactive) {
      //   const filter: any = yield select(
      //     (state: IApplicationState) => state.listUserDeactivated.filter
      //   );
      //   yield put(getListUserDeactivated(filter));
      // }
    } else {
      yield put(unLockUserFaild(dataUnLockUser.message))
    }
  } catch (error) {
    yield put(unLockUserFaild(error))
  }
}
export function* watchUnLockUser() {
  yield takeEvery(UnLockUserType.UNLOCK_USER, UnLockUsereSaga)
}