import { AnyAction, Reducer } from 'redux';
import { EditUserByAdminState, initialEditUserByAdminState } from './state';
import { EditUserByAdminType } from 'src/store/actions/editUserByAdmin';

export const EditUserByAdminReducer: Reducer = (
  state: EditUserByAdminState = initialEditUserByAdminState,
  action: AnyAction
) => {
  switch (action.type) {
    case EditUserByAdminType.EDIT_USER_BY_ADMIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case EditUserByAdminType.EDIT_USER_BY_ADMIN_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case EditUserByAdminType.EDIT_USER_BY_ADMIN_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
