import { debounce, put, takeEvery } from 'redux-saga/effects';
import { UserAvatarTypes } from '../../actions/userAvatar';
import { userAvatarFetching, userAvatarFilter, userAvatarSuccess } from '../../actions/userAvatar/action';
import { getStorageItem } from 'src/utils/newUtils/localStorageHelper';
import { LocalStorageKey } from 'src/contants/constantUtils/localStorageKey';

function* userAvatarSaga(action:any):Generator<any> {
    yield put(userAvatarFetching(true))
    const {payload} = action;
    if(payload !== undefined){
      yield put(userAvatarSuccess(payload))
    }else{
      const user = getStorageItem(LocalStorageKey.UserInfo)
      yield put(userAvatarSuccess(user?.imageUrl))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(userAvatarFilter(action.payload));
}
export function* watchUserAvatar() {
   yield takeEvery(UserAvatarTypes.USER_AVATAR, userAvatarSaga)
   yield debounce(500,UserAvatarTypes.USER_AVATAR_FILTER_DEBOUNCE,handleSearchDebounce)
}
