import { AddUserBankAccountType } from './actionTypes';
import { IAddBankAcc,IAddBankAccError,IAddSuccess,IFetchingAddBankAcc } from './interfaces';
import { History } from 'history';

export const addBankAccIsFetching = (isFetching:boolean) : IFetchingAddBankAcc => ({
    type:AddUserBankAccountType.ADD_USER_BANK_ACCOUNT_FETCHING,
    isFetching
})

export const addBankAcc = (payload:Object, isEdit:boolean, history: History, userId:string | null) : IAddBankAcc => ({
    type:AddUserBankAccountType.ADD_USER_BANK_ACOOUNT,
    payload,
    isEdit,
    history,
    userId
})

export const addBankAccSuccess = (data:any) : IAddSuccess => ({
    type:AddUserBankAccountType.ADD_USER_BANK_ACCOUNT_SUCCESS,
    data
})

export const addBankAccFaild = (error:any) : IAddBankAccError => ({
    type:AddUserBankAccountType.ADD_USER_BANK_ACCOUNT_ERROR,
    error
})