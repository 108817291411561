import { debounce, put, takeEvery } from "redux-saga/effects";
import { handleAgentGetAllWallets } from "src/apis/service/Agent";
import { AgentGetAllWalletsTypes } from "../../actions/agentGetAllWallets";
import {
  agentGetAllWalletsError,
  agentGetAllWalletsFetching,
  agentGetAllWalletsFilter,
  agentGetAllWalletsSuccess,
} from "../../actions/agentGetAllWallets/action";
import { setStorageItem } from "src/utils/newUtils/localStorageHelper";
import { LocalStorageKey } from "src/contants/constantUtils/localStorageKey";

function* agentGetAllWalletsSaga(): Generator<any> {
  yield put(agentGetAllWalletsFetching(true));
  try {
    const data: any = yield handleAgentGetAllWallets();
    if (data && data?.status === 200) {
      setStorageItem(LocalStorageKey.ListWallet, JSON.stringify(data?.data));
      setStorageItem(
        LocalStorageKey.WalletDefault,
        JSON.stringify((data?.data || []).find((w: any) => w.isDefault))
      );
      yield put(agentGetAllWalletsSuccess(data));
    } else {
      yield put(agentGetAllWalletsError("error"));
    }
  } catch (error) {
    yield put(agentGetAllWalletsError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentGetAllWalletsFilter(action.payload));
}
export function* watchAgentGetAllWallets() {
  yield takeEvery(
    AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS,
    agentGetAllWalletsSaga
  );
  yield debounce(
    500,
    AgentGetAllWalletsTypes.AGENT_GET_ALL_WALLETS_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
