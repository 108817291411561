import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { ListParams } from '../../../models/common';
interface IDataListCryptoWalletSendHistory {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListCryptoWalletSendHistoryState {
    data: IDataListCryptoWalletSendHistory;
    isFetching: boolean,
    filter: ListParams,
    pagination: number
}

export const initialListCryptoWalletSendHistoryState: IListCryptoWalletSendHistoryState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
    },
    pagination: 0,
}

