import * as Sentry from "@sentry/react";
import Request from "src/apis/config";

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_TRANSFER});

const handleGetTransactionsByOfferId = async (data:any) : Promise<any> => {
    try {
        const url = '/Offer/Transaction/search';
        return await requestApi.post(url,data)  
    } catch (error) {
        Sentry.captureException(error);
        throw error
    }
}

export { handleGetTransactionsByOfferId }
