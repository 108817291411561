import { put, takeEvery } from 'redux-saga/effects';
import { createAdminBO, editAdminBO } from 'src/apis/service/Admin-management';
import {
  CreateAndEditAdminBOType,
  createAndeditAdminBOAtFetching,
  createAndeditAdminBOSuccess,
  createAndeditAdminBOFaild,
} from 'src/store/actions/admin-managements/createAndeditAdminBO';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getAdminBO } from 'src/store/actions';

function* createAndEditAdminBOSaga(action: any): Generator<any> {
  const { payload, isCreate, filters } = action;
  yield put(createAndeditAdminBOAtFetching(true));
  try {
    if (isCreate) {
      const response: any = yield createAdminBO(payload);
      if (response && response.status === 200) {
        if (response.message === 'Success') {
          yield put(createAndeditAdminBOSuccess(response));
          yield put(getAdminBO({ ...filters }));
          toastSuccess('bo_create_new_admin_success');
        } else {
          toastError(response.message || 'bo_create_new_admin_failed');
          yield put(createAndeditAdminBOFaild('error'));
        }
      } else {
        toastError(response.message || 'bo_create_new_admin_failed');
        yield put(createAndeditAdminBOFaild('error'));
      }
    } else {
      const response: any = yield editAdminBO(payload);
      if (response && response.status === 200) {
        yield put(createAndeditAdminBOSuccess(response));
        yield put(getAdminBO({ ...filters }));
        toastSuccess('bo_edit_information_admin_success');
        // history.push('/dashboard/admin-management')
      } else {
        toastError('bo_edit_information_admin_failed');
        yield put(createAndeditAdminBOFaild('error'));
      }
    }
  } catch (error: any) {
    yield put(createAndeditAdminBOFaild(error.message));
  }
}

export function* watchCreateAndEditAdminBO() {
  yield takeEvery(
    CreateAndEditAdminBOType.CREATE_AND_EDIT_ADMIN_BO,
    createAndEditAdminBOSaga
  );
}
