import {HandleBlockOfferTypes} from './actionTypes';
import { IHandleBlockOffer, IHandleBlockOfferFetching, IHandleBlockOfferSuccess, IHandleBlockOfferError, IHandleBlockOfferFilter, IHandleBlockOfferFiterDebounce } from "./interfaces";


export const handleBlockOffer = (payload:Object): IHandleBlockOffer => ({
    type: HandleBlockOfferTypes.HANDLE_BLOCK_OFFER,
    payload
})

export const handleBlockOfferFetching = (isFetching:boolean) : IHandleBlockOfferFetching => ({
    type: HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_FETCHING,
    isFetching
})

export const handleBlockOfferSuccess = (data:any) : IHandleBlockOfferSuccess => ({
   type: HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_SUCCESS,
   data
})

export const handleBlockOfferError = (errorMsg:any) : IHandleBlockOfferError => ({
    type: HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_FAILD,
    error:errorMsg
})

export const handleBlockOfferFilter = (payload: any): IHandleBlockOfferFilter => ({
    type: HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_FILTER,
    payload
})

export const handleBlockOfferFilterDebounce = (payload: any): IHandleBlockOfferFiterDebounce => ({
    type: HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_FILTER_DEBOUNCE,
    payload 
})

export const handleResetBlockOfferState = () : any => ({
    type:  HandleBlockOfferTypes.HANDLE_BLOCK_OFFER_RESET_STATE
});