import {put, takeEvery, debounce} from 'redux-saga/effects';
import { getAgencyManagement } from 'src/apis/service/Agency';
import { getAgencyManagementFaild, getAgencyManagementIsFetching, getAgencyManagementSuccess, GetAgencyManagementType, setFilterAgencyManagement } from 'src/store/actions';

function* getListAgencySaga(action:any): Generator<any> {
    yield put(getAgencyManagementIsFetching(true))
    const {payload} = action;
    try {
      const dataTransactions: any = yield getAgencyManagement(payload)
      if(dataTransactions && dataTransactions?.status === 200){
        yield put(getAgencyManagementSuccess(dataTransactions))
      } else {
        yield put(getAgencyManagementFaild('error'))
      }
    } catch (error) {
        yield put(getAgencyManagementFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterAgencyManagement(action.payload));
}

export function* watchGetListAgency() {
   yield takeEvery(GetAgencyManagementType.GET_AGENCY_MANAGEMENT , getListAgencySaga)
   yield debounce(500, GetAgencyManagementType.GET_LIST_FILTER_DEBOUNCE_AGENCY, handleSearchDebounce)
}