import { UpdateAgencyStatusType } from './actionTypes';
import { IFetchingUpdateAgencyStatus, IUpdateAgencyStatus, IUpdateAgencyStatusError, IUpdateAgencyStatusReset, IUpdateAgencyStatusSuccess } from './interfaces';

export const updateAgencyStatusIsFetching = (isFetching:boolean) : IFetchingUpdateAgencyStatus => ({
    type: UpdateAgencyStatusType.UPDATE_AGENCY_STATUS_FETCHING,
    isFetching
})

export const updateAgencyStatus = (payload: Object): IUpdateAgencyStatus => ({
    type: UpdateAgencyStatusType.UPDATE_AGENCY_STATUS,
    payload
})

export const updateAgencyStatusSuccess = (data:any): IUpdateAgencyStatusSuccess => ({
    type: UpdateAgencyStatusType.UPDATE_AGENCY_STATUS_SUCCESS,
    data
})

export const updateAgencyStatusFaild = (error:any): IUpdateAgencyStatusError => ({
    type: UpdateAgencyStatusType.UPDATE_AGENCY_STATUS_ERROR,
    error
})

export const updateAgencyStatusReset = (): IUpdateAgencyStatusReset => ({
    type: UpdateAgencyStatusType.UPDATE_AGENCY_STATUS_RESET,
})
