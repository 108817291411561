import { put, takeEvery } from 'redux-saga/effects';
import { editOffer } from 'src/apis/service/Offer';
import { EditOfferSellOrBuy, editOfferSellOrBuyAtFetching, editOfferSellOrBuyFaild, editOfferSellOrBuySuccess  } from 'src/store/actions/myOfferFromAgent/editOfferBuyOrSell';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* editOfferSellOrBuySaga(action: any): Generator<any> {


  const { payload, history, editType } = action;
  yield put(editOfferSellOrBuyAtFetching(true))
  try {
     const response : any = yield editOffer(payload)
     if (response.status === 200) {
       yield put(editOfferSellOrBuySuccess(response))
       toastSuccess('bo_edit_offer_success')
       if (editType === 'Buy') {
          history.push('/dashboard/p2p-offer-management/buy')
       } else {
          history.push('/dashboard/p2p-offer-management/sell')
       }
     } else {
      toastError(`${response.message}`)
     }
  } catch (error:any) {
      toastError('bo_edit_offer_fail')
      yield put(editOfferSellOrBuyFaild(error.message))
  }
}

export function* watchEditOfferBuyOrSell() {
   yield takeEvery(EditOfferSellOrBuy.EDIT_OFFER_SELL_OR_BUY , editOfferSellOrBuySaga)
}