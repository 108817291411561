import { AnyAction, Reducer } from 'redux';
import {
  IGetKYCFunctionByIdState,
  initialGetKYCFunctionByIdState,
} from './state';
import { GetKYCFuncByIdTypes } from 'src/store/actions/getKYCFunctionById';

export const GetKYCFunctionByIdReducer: Reducer<IGetKYCFunctionByIdState> = (
  state: IGetKYCFunctionByIdState = initialGetKYCFunctionByIdState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID_FETCH: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        isError: false,
        isSuccess: true,
      };
    }
    case GetKYCFuncByIdTypes.GET_KYC_FUNC_BY_ID_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        isSuccess: false,
      };
    }

    default: {
      return state;
    }
  }
};
