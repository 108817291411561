import {GetKycRiskManagementTypes} from './actionTypes';
import {
    IGetListFiterDebounceKycRiskManagement,
    IGetListFiterKycRiskManagement,
    IGetKycRiskManagement,
    IGetKycRiskManagementFaild,
    IGetKycRiskManagementFetching,
    IGetKycRiskManagementSuccess
} from './interfaces';

export const getKycRiskManagementAtFetching = (isFetching:boolean) : IGetKycRiskManagementFetching  => ({
    type: GetKycRiskManagementTypes.GET_KYC_RISK_MANAGEMENT_FETCHING,
    isFetching
})

export const getKycRiskManagement = (payload:Object) : IGetKycRiskManagement => ({
    type: GetKycRiskManagementTypes.GET_KYC_RISK_MANAGEMENT,
    payload
})

export const getKycRiskManagementSuccess = (data:any) : IGetKycRiskManagementSuccess => ({
    type: GetKycRiskManagementTypes.GET_KYC_RISK_MANAGEMENT_SUCCESS,
    data
})

export const getKycRiskManagementFaild = (error:any) : IGetKycRiskManagementFaild => ({
    type: GetKycRiskManagementTypes.GET_KYC_RISK_MANAGEMENT_FAILD,
    error
})

export const setFilterKycRiskManagement = (payload: any): IGetListFiterKycRiskManagement => ({
    type: GetKycRiskManagementTypes.GET_LIST_FILTER_KYC_RISK_MANAGEMENT,
    payload
})

export const setFilterDebounceKycRiskManagement = (payload: any): IGetListFiterDebounceKycRiskManagement => ({
    type: GetKycRiskManagementTypes.GET_LIST_FILTER_DEBOUNCE_KYC_RISK_MANAGEMENT,
    payload 
})