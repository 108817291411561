import Request from '../../config';
import * as Sentry from '@sentry/react';

const requestApi = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER
});
const requestApiConfig = new Request({
  url: process.env.REACT_APP_BASE_URL_CONFIG
});
const requestApiAccount = new Request({
  url: process.env.REACT_APP_BASE_URL_ACCOUNT
});

const requestCryptoApi = new Request({
  url: process.env.REACT_APP_BASE_ADMIN_CRYPTO_URL
});

// transaction all
const handleGetAllTransaction = async (data: Object): Promise<any[]> => {
  try {
    return await requestApi.post(`/Transaction/search`, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCurrencyGetByListId = async (data: any) => {
  try {
    return await requestApiConfig.post('/Currency/GetByListId', data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUserGetUsersBankInfo = async (data: any) => {
  try {
    return await requestApiAccount.post('/User/GetUsersBankInfo', data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetOfferHistoryStatus = async (id: number): Promise<any[]> => {
  try {
    return await requestApi.get(`/Offer/history/status/log/${id}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleTransactionHistorystatuslog = async (id: number) => {
  const url = `/Transaction/historystatuslog?transId=${id}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleTransactionManualpayment = async (data: number[]) => {
  const url = '/Transaction/manualpayment';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateTransactionStatus = async (data: any) => {
  const url = '/Transaction/UpdateTransactionStatus';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAllCryptoTransactions = async (data: any) => {
  const url = '/wallet/transactions';
  const query = '?' + new URLSearchParams(data?.filter || {}).toString()

  try {
    return await requestCryptoApi.get(url + query, );
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  handleGetAllTransaction,
  handleGetOfferHistoryStatus,
  handleCurrencyGetByListId,
  handleUserGetUsersBankInfo,
  handleTransactionHistorystatuslog,
  handleTransactionManualpayment,
  handleUpdateTransactionStatus,
  handleGetAllCryptoTransactions
};
