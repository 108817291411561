import { AnyAction, Reducer } from 'redux';
import { RemoveUserBankAccAgentState, initialRemoveUserBankAccAgentState } from './state';
import { RemoveUserBankAccAgentType } from 'src/store/actions/bankAccountByAgent/removeUserBankAccountAgent';

export const RemoveUserBankAccAgentReducer: Reducer = (
  state: RemoveUserBankAccAgentState = initialRemoveUserBankAccAgentState,
  action: AnyAction
) => {
  switch (action.type) {
    case RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case RemoveUserBankAccAgentType.REMOVE_USER_BANK_ACCOUNT_AGENT_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
