import { AnyAction, Reducer } from 'redux';
import { IGetAllScoreKPIState, initialGetAllScoreKPIState } from './state';
import { GetAllScoreKPITypes } from 'src/store/actions/getAllScoreKPI';

export const GetAllScoreKPIReducer: Reducer = (
  state: IGetAllScoreKPIState = initialGetAllScoreKPIState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAllScoreKPITypes.GET_ALL_SCORE_KPI: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAllScoreKPITypes.GET_ALL_SCORE_KPI_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAllScoreKPITypes.GET_ALL_SCORE_KPI_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAllScoreKPITypes.GET_ALL_SCORE_KPI_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
