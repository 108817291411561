import {AgentGetAdminTrxDetailTypes} from './actionTypes';
import { IAgentGetAdminTrxDetail, IAgentGetAdminTrxDetailFetching, IAgentGetAdminTrxDetailSuccess, IAgentGetAdminTrxDetailError, IAgentGetAdminTrxDetailFilter, IAgentGetAdminTrxDetailFiterDebounce } from "./interfaces";


export const agentGetAdminTrxDetail = (payload:Object): IAgentGetAdminTrxDetail => ({
    type: AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL,
    payload
})

export const agentGetAdminTrxDetailFetching = (isFetching:boolean) : IAgentGetAdminTrxDetailFetching => ({
    type: AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_FETCHING,
    isFetching
})

export const agentGetAdminTrxDetailSuccess = (data:any) : IAgentGetAdminTrxDetailSuccess => ({
   type: AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_SUCCESS,
   data
})

export const agentGetAdminTrxDetailError = (errorMsg:any) : IAgentGetAdminTrxDetailError => ({
    type: AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_FAILD,
    error:errorMsg
})

export const agentGetAdminTrxDetailFilter = (payload: any): IAgentGetAdminTrxDetailFilter => ({
    type: AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_FILTER,
    payload
})

export const agentGetAdminTrxDetailFilterDebounce = (payload: any): IAgentGetAdminTrxDetailFiterDebounce => ({
    type: AgentGetAdminTrxDetailTypes.AGENT_GET_ADMIN_TRX_DETAIL_FILTER_DEBOUNCE,
    payload 
})
