import {GetKycListTypes} from './actionTypes';
import {
    IGetListFiterDebounceKycList,
    IGetListFiterKycList,
    IGetKycList,
    IGetKycListFaild,
    IGetKycListFetching,
    IGetKycListSuccess
} from './interfaces';

export const getKycListAtFetching = (isFetching:boolean) : IGetKycListFetching  => ({
    type: GetKycListTypes.GET_KYC_LIST_FETCHING,
    isFetching
})

export const getKycList = (payload:Object) : IGetKycList => ({
    type: GetKycListTypes.GET_KYC_LIST,
    payload
})

export const getKycListSuccess = (data:any) : IGetKycListSuccess => ({
    type: GetKycListTypes.GET_KYC_LIST_SUCCESS,
    data
})

export const getKycListFaild = (error:any) : IGetKycListFaild => ({
    type: GetKycListTypes.GET_KYC_LIST_FAILD,
    error
})

export const setFilterKycList = (payload: any): IGetListFiterKycList => ({
    type: GetKycListTypes.GET_LIST_FILTER_KYC_LIST,
    payload
})

export const setFilterDebounceKycList = (payload: any): IGetListFiterDebounceKycList => ({
    type: GetKycListTypes.GET_LIST_FILTER_DEBOUNCE_KYC_LIST,
    payload 
})