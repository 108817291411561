import {put, takeEvery} from 'redux-saga/effects';
import {getStorageItem} from 'src/utils/storage/auth';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { deleteAnnouncementsCategoryFaild, deleteAnnouncementsCategoryIsFetching, deleteAnnouncementsCategorySuccess, DeleteAnnouncementsCategoryType, getAnnouncementsCategory } from 'src/store/actions';
import { deleteAnnouncementCategory } from 'src/apis/service/Announcements';

function* deleteAnnouncementsCategory(action:any):Generator<any>{


  yield put(deleteAnnouncementsCategoryIsFetching(true))
  const {payload} = action;
  const filter = {
    PageNumber: 1,
    PageSize: 10,
   }
   
  try {
    const result: any = yield deleteAnnouncementCategory(payload);
    if (result.message === "Success") {
      yield put(deleteAnnouncementsCategorySuccess(result))
      const userId = getStorageItem('user')
      if (userId) {
        toastSuccess('bo_annoucement_category_delete_success')
        // yield put(getAnnouncementsCategory(filter));
      }
    } else {
      toastError('bo_annoucement_delete_fail')
      yield put(deleteAnnouncementsCategoryFaild(result.errors[0]))
    }
  } catch (error) {
     yield put(deleteAnnouncementsCategoryFaild(error)) 
  }
}
export function* watchDeleteAnnouncementsCategory(){
    yield takeEvery(DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY, deleteAnnouncementsCategory)
}
