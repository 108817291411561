import { AnyAction, Reducer } from 'redux';
import { GetKycDetailTypes } from 'src/store/actions';
import { IListKycDetailState, initialListKycDetailState } from './state';


export const GetListKycDetailReducer: Reducer = (
  state: IListKycDetailState = initialListKycDetailState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetKycDetailTypes.GET_KYC_DETAIL: {
      return {
        ...state,
        isFetching: true,
        isSuccess: false
      };
    }
    case GetKycDetailTypes.GET_KYC_DETAIL_SUCCESS: {      
      return {
        ...state,
        data: action.data,
        isFetching: false,
        isSuccess: true
      };
    }
    case GetKycDetailTypes.GET_KYC_DETAIL_FAILD: {
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        error: action.error
      };
    }
    case GetKycDetailTypes.RESET_STATE: {
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        data:{}
      }
    }
    default:
      return state;
  }
};