import { GetAllRankPeriodTypes } from './actionTypes';
import { IGetAllRankPeriod, IGetAllRankPeriodFetching, IGetAllRankPeriodSuccess, IGetAllRankPeriodError, IGetAllRankPeriodFilter, IGetAllRankPeriodFiterDebounce } from "./interfaces";


export const getAllRankPeriod = (payload: any): IGetAllRankPeriod => ({
    type: GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD,
    payload
})

export const getAllRankPeriodFetching = (isFetching: boolean): IGetAllRankPeriodFetching => ({
    type: GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD_FETCHING,
    isFetching
})

export const getAllRankPeriodSuccess = (data: any): IGetAllRankPeriodSuccess => ({
    type: GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD_SUCCESS,
    data
})

export const getAllRankPeriodError = (errorMsg: any): IGetAllRankPeriodError => ({
    type: GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD_FAILD,
    error: errorMsg
})

export const getAllRankPeriodFilter = (payload: any): IGetAllRankPeriodFilter => ({
    type: GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD_FILTER,
    payload
})

export const getAllRankPeriodFilterDebounce = (payload: any): IGetAllRankPeriodFiterDebounce => ({
    type: GetAllRankPeriodTypes.GET_ALL_RANK_PERIOD_FILTER_DEBOUNCE,
    payload
})
