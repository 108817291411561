export interface EditNotification  {
    data:any,
    errors:any,
    success:any
}

export interface EditNotificationState {
    data:EditNotification,
    isFetching:boolean
}

export const initialEditNotificationState: EditNotificationState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false
}