import { ListParams } from "src/models/common";

interface IDataUpdateCryptoExchangeOffersList {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  success:boolean;
}

export interface IUpdateCryptoExchangeOffersListState {
    data:IDataUpdateCryptoExchangeOffersList;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
    countSuccess: number
}

export const initialUpdateCryptoExchangeOffersListState: IUpdateCryptoExchangeOffersListState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    },
    countSuccess: 0
}


