import {put, takeEvery} from 'redux-saga/effects';
import { getCurrencies } from 'src/apis/service/Currencies';
import { getCurrencyFaild, getCurrencyIsFetching, getCurrencySuccess, GetCurrencyType } from 'src/store/actions';
import { setStorageItem } from 'src/utils/storage';

function* getListCurrencySaga(): Generator<any> {
    yield put(getCurrencyIsFetching(true))
    try {
      const data:any = yield getCurrencies()      
      if(data && data?.status === 200){
        yield put(getCurrencySuccess(data));
        setStorageItem('NCD_currencies',JSON.stringify(data?.data))
      } else {
        yield put(getCurrencyFaild('error'))
      }
    } catch (error) {
        yield put(getCurrencyFaild(error))
    }
}

export function* watchGetListCurrency() {
   yield takeEvery(GetCurrencyType.GET_CURRENCY , getListCurrencySaga)
}