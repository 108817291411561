import { put, takeEvery, debounce } from 'redux-saga/effects';
import { ApplyKYCBankInfoTypes } from '../../actions/ApplyKYCBankInfo';
import { ApplyKYCBankInfoFetching, ApplyKYCBankInfoSuccess, ApplyKYCBankInfoError, ApplyKYCBankInfoFilter } from '../../actions/ApplyKYCBankInfo/action';
import { handleApplyKYCBankInfo, handleGetListUser } from '../../../apis/service/user';
import { getAllKYC } from 'src/store/actions/getAllKYC';

function* ApplyKYCBankInfoSaga(action: any): Generator<any> {
  yield put(ApplyKYCBankInfoFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleApplyKYCBankInfo(payload)
    if (data && data?.status === 200) {
      yield put(getAllKYC())
      yield put(ApplyKYCBankInfoSuccess(data))
    } else {
      yield put(ApplyKYCBankInfoError('error'))
    }
  } catch (error) {
    yield put(ApplyKYCBankInfoError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(ApplyKYCBankInfoFilter(action.payload));
}
export function* watchApplyKYCBankInfo() {
  yield takeEvery(ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO, ApplyKYCBankInfoSaga)
  yield debounce(500, ApplyKYCBankInfoTypes.APPLY_KYC_BANK_INFO_FILTER_DEBOUNCE, handleSearchDebounce)
}
