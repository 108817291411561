import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetOverview } from 'src/apis/service/CryptoWallet';
import { getCryptoWalletGetOverviewFaild, getCryptoWalletGetOverviewIsFetching, getCryptoWalletGetOverviewSuccess, GetCryptoWalletGetOverviewType } from 'src/store/actions';

function* getCryptoWalletOverviewSaga(action:any):Generator<any> {
    yield put(getCryptoWalletGetOverviewIsFetching(true))
    const {payload} = action;
    try {
      const data:any = yield call(handleGetOverview, payload)
      if(data && data?.status === 200) {
        yield put(getCryptoWalletGetOverviewSuccess(data))
      } else {
        yield put(getCryptoWalletGetOverviewFaild('error'))
      }
    } catch (error) {
        yield put(getCryptoWalletGetOverviewFaild(error))
    }
}

export function* watchGetListCryptoWalletOverview() {
   yield takeEvery(GetCryptoWalletGetOverviewType.GET_CRYPTO_WALLET_GET_OVERVIEW, getCryptoWalletOverviewSaga)
}