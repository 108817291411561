import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
interface IDataListP2PSellTransactions {
  data: {
    items: any[];
    totalCount: number;
  };
  status: number;
  message: string;
}

export interface IListP2PSellTransactionsState {
  data: IDataListP2PSellTransactions;
  isFetching: boolean;
  filter: Object;
  pagination: Object;
}

export const initialListP2PSellTransactionsState: IListP2PSellTransactionsState =
  {
    data: {
      data: {
        items: [],
        totalCount: 0,
      },
      status: 0,
      message: '',
    },
    filter: {
      searchText: '',
      transactionType: 'SELL',
      currencyFilter: [],
      statusFilter: [],
      matchedDateFilter: [],
      pageNumber: 1,
      pageSize: PAGE_SIZE_DEFAULT,
      orderBy: 'CreatedDate',
      orderDirection: 'desc',
    },
    pagination: 0,
    isFetching: false,
  };
