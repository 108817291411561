import {GetAllCurrencyNoFilterTypes} from './actionTypes';
import { IGetAllCurrencyNoFilter, IGetAllCurrencyNoFilterFetching, IGetAllCurrencyNoFilterSuccess, IGetAllCurrencyNoFilterError, IGetAllCurrencyNoFilterFilter, IGetAllCurrencyNoFilterFiterDebounce } from "./interfaces";


export const getAllCurrencyNoFilter = (payload:Object): IGetAllCurrencyNoFilter => ({
    type: GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER,
    payload
})

export const getAllCurrencyNoFilterFetching = (isFetching:boolean) : IGetAllCurrencyNoFilterFetching => ({
    type: GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER_FETCHING,
    isFetching
})

export const getAllCurrencyNoFilterSuccess = (data:any) : IGetAllCurrencyNoFilterSuccess => ({
   type: GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER_SUCCESS,
   data
})

export const getAllCurrencyNoFilterError = (errorMsg:any) : IGetAllCurrencyNoFilterError => ({
    type: GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER_FAILD,
    error:errorMsg
})

export const getAllCurrencyNoFilterFilter = (payload: any): IGetAllCurrencyNoFilterFilter => ({
    type: GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER_FILTER,
    payload
})

export const getAllCurrencyNoFilterFilterDebounce = (payload: any): IGetAllCurrencyNoFilterFiterDebounce => ({
    type: GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER_FILTER_DEBOUNCE,
    payload 
})
