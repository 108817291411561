export enum UserRole {
  Visitor,
  User,
  Agent,
  Admin,
  SuperAdmin,
  StoreUser,
  GrantedUser,
  SuperAgent
}
