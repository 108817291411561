import { ListParams } from "src/models/common";

interface IDataGetNavbarForAdmin {
  data:{
      items:any[];
      total:number;
  };
  error:any;
  success:boolean;
}

export interface IGetNavbarForAdminState {
    data:IDataGetNavbarForAdmin;
    isFetching:any,
    filter: ListParams,
    pagination: any,
}

export const initialGetNavbarForAdminState: IGetNavbarForAdminState = {
    data:{
        data:{
            items:[],
            total:0
        },
        error:null,
        success:false
    },
    isFetching:-1,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


