import { AnyAction, Reducer } from 'redux';
import { ISetUserKYCPriorityState, initialSetUserKYCPriorityState } from './state';
import { SetUserKYCPriorityTypes } from 'src/store/actions/setUserKYCPriority';

export const SetUserKYCPriorityReducer: Reducer = (
  state: ISetUserKYCPriorityState = initialSetUserKYCPriorityState,
  action: AnyAction
) => {
  switch (action.type) {
    case SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
