import { toastSuccess, toastError } from './../../../helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleAgentTransactionConfirm } from 'src/apis/service/P2PTransaction';
import { AgentTransactionConfirmTypes } from '../../actions/agentTransactionConfirm';
import { agentTransactionConfirmError, agentTransactionConfirmFetching, agentTransactionConfirmFilter, agentTransactionConfirmSuccess } from '../../actions/agentTransactionConfirm/action';

function* agentTransactionConfirmSaga(action: any): Generator<any> {


  yield put(agentTransactionConfirmFetching(true))
  const { payload, getData } = action;
  try {
    const data: any = yield handleAgentTransactionConfirm(payload)
    if (data?.status === 200) {
      // yield put(agentTransactionConfirmSuccess(data))
      getData();
      toastSuccess('bo_confirm_transaction_success');
    } else {
      toastError('bo_you_can_t_confirm_transaction');
      yield put(agentTransactionConfirmError('error'))
    }
  } catch (error) {
    toastError('bo_you_can_t_confirm_transaction');
    yield put(agentTransactionConfirmError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentTransactionConfirmFilter(action.payload));
}
export function* watchAgentTransactionConfirm() {
  yield takeEvery(AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM, agentTransactionConfirmSaga)
  yield debounce(500, AgentTransactionConfirmTypes.AGENT_TRANSACTION_CONFIRM_FILTER_DEBOUNCE, handleSearchDebounce)
}
