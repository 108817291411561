import { AnyAction, Reducer } from 'redux';
import { DeleteAnnouncementsCategoryType } from 'src/store/actions';
import { DeleteAnnouncementsCategoryState, initialDeleteAnnouncementsCategoryState } from './state';

export const DeleteAnnouncementsCategoryReducer: Reducer = (
  state: DeleteAnnouncementsCategoryState = initialDeleteAnnouncementsCategoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY: {
      return {
        ...state,
        isFetching: true,
        success: false
      };
    }
    case DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        success: true
      };
    }
    case DeleteAnnouncementsCategoryType.DELETE_ANNOUNCEMENTS_CATEGORY_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
