import { put, takeEvery, select } from 'redux-saga/effects';
import { UpdateUserPriorityType } from '../../actions/UpdateUserRolesPriorities';
import {
  updateUserRoleIsFetching,
  updateUserRolePrioritySuccess,
  updateUserRolePriorityFaild
} from '../../actions/UpdateUserRolesPriorities/action';
import { handleUpdateUserRole } from '../../../apis/service/user';
import { getAgentRank } from 'src/store/actions/Agent/GetAgentRank';
import { IApplicationState } from 'src/store/IApplicationState';
import { toastSuccess,toastError } from 'src/helpers/toastHelper';

function* UpdateUserRoleSaga(action: any): Generator<any> {


  yield put(updateUserRoleIsFetching(true));
  const { payload } = action;
  try {
    const dataUpdateUserRole: any = yield handleUpdateUserRole(payload);
    if (dataUpdateUserRole.status === 200) {
      const filter: any = yield select(
        (state: IApplicationState) => state.listAgentRank.filter
      );
      yield put(updateUserRolePrioritySuccess(dataUpdateUserRole));
      toastSuccess('bo_update_role_success');
      yield put(getAgentRank(filter));
    }
  } catch (error) {
    toastError('bo_update_role_fail');
    yield put(updateUserRolePriorityFaild(error));
  }
}
export function* watchUpdateUserRole() {
  yield takeEvery(
    UpdateUserPriorityType.UPDATE_ROLES_USER_PRIORITY,
    UpdateUserRoleSaga
  );
}