import { DeleteScoreKPITypes } from './actionTypes';
import {
  IDeleteScoreKPI,
  IDeleteScoreKPIFetching,
  IDeleteScoreKPISuccess,
  IDeleteScoreKPIError,
  IDeleteScoreKPIFilter,
  IDeleteScoreKPIFiterDebounce,
} from './interfaces';

export const deleteScoreKPI = (payload: Object): IDeleteScoreKPI => ({
  type: DeleteScoreKPITypes.DELETE_SCORE_KPI,
  payload,
});

export const deleteScoreKPIFetching = (
  isFetching: boolean
): IDeleteScoreKPIFetching => ({
  type: DeleteScoreKPITypes.DELETE_SCORE_KPI_FETCHING,
  isFetching,
});

export const deleteScoreKPISuccess = (): IDeleteScoreKPISuccess => ({
  type: DeleteScoreKPITypes.DELETE_SCORE_KPI_SUCCESS,
});

export const deleteScoreKPIError = (errorMsg: any): IDeleteScoreKPIError => ({
  type: DeleteScoreKPITypes.DELETE_SCORE_KPI_FAILD,
  error: errorMsg,
});

export const deleteScoreKPIFilter = (payload: any): IDeleteScoreKPIFilter => ({
  type: DeleteScoreKPITypes.DELETE_SCORE_KPI_FILTER,
  payload,
});

export const deleteScoreKPIFilterDebounce = (
  payload: any
): IDeleteScoreKPIFiterDebounce => ({
  type: DeleteScoreKPITypes.DELETE_SCORE_KPI_FILTER_DEBOUNCE,
  payload,
});
