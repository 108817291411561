import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetListCryptoDetailTypes} from '../../actions/getListCryptoDetail';
import {getListCryptoDetailFetching,getListCryptoDetailSuccess,getListCryptoDetailError, getListCryptoDetailFilter} from '../../actions/getListCryptoDetail/action';
import {handleGetListUser} from '../../../apis/service/user';
import { handleGetListCryptoDetail } from 'src/apis/service/Agent';

function* getListCryptoDetailSaga(action:any):Generator<any> {
    yield put(getListCryptoDetailFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetListCryptoDetail(payload)
      if(data && data?.status === 200){
        yield put(getListCryptoDetailSuccess(data))
      } else {
        yield put(getListCryptoDetailError('error'))
      }
    } catch (error) {
        yield put(getListCryptoDetailError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListCryptoDetailFilter(action.payload));
}
export function* watchGetListCryptoDetail() {
   yield takeEvery(GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL, getListCryptoDetailSaga)
   yield debounce(500,GetListCryptoDetailTypes.GET_LIST_CRYPTO_DETAIL_FILTER_DEBOUNCE,handleSearchDebounce)
}
