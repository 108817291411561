import {GetNavbarForAdminTypes} from './actionTypes';
import { IGetNavbarForAdmin, IGetNavbarForAdminFetching, IGetNavbarForAdminSuccess, IGetNavbarForAdminError, IGetNavbarForAdminFilter, IGetNavbarForAdminFiterDebounce } from "./interfaces";


export const getNavbarForAdmin = (): IGetNavbarForAdmin => ({
    type: GetNavbarForAdminTypes.GET_NAVBAR_FOR_ADMIN
})

export const getNavbarForAdminFetching = (isFetching:boolean) : IGetNavbarForAdminFetching => ({
    type: GetNavbarForAdminTypes.GET_NAVBAR_FOR_ADMIN_FETCHING,
    isFetching
})

export const getNavbarForAdminSuccess = (data:any) : IGetNavbarForAdminSuccess => ({
   type: GetNavbarForAdminTypes.GET_NAVBAR_FOR_ADMIN_SUCCESS,
   data
})

export const getNavbarForAdminError = (errorMsg:any) : IGetNavbarForAdminError => ({
    type: GetNavbarForAdminTypes.GET_NAVBAR_FOR_ADMIN_FAILD,
    error:errorMsg
})

export const getNavbarForAdminFilter = (payload: any): IGetNavbarForAdminFilter => ({
    type: GetNavbarForAdminTypes.GET_NAVBAR_FOR_ADMIN_FILTER,
    payload
})

export const getNavbarForAdminFilterDebounce = (payload: any): IGetNavbarForAdminFiterDebounce => ({
    type: GetNavbarForAdminTypes.GET_NAVBAR_FOR_ADMIN_FILTER_DEBOUNCE,
    payload 
})
