import { GetUserDetailType } from './actionTypes';
import {
  IFetchingGetUserDetail,
  IGetUserDetail,
  IGetUserDetailSuccess,
  IGetUserDetailError,
} from './interfaces';

export const getUserDetailIsFetching = (
  isFetching: boolean
): IFetchingGetUserDetail => ({
  type: GetUserDetailType.GET_USER_DETAIL_FETCHING,
  isFetching,
});

export const getUserDetail = (
  payload: number,
  isGetAvatar?: boolean
): IGetUserDetail => ({
  type: GetUserDetailType.GET_USER_DETAIL,
  payload,
  isGetAvatar,
});

export const getUserDetailSuccess = (data: any): IGetUserDetailSuccess => ({
  type: GetUserDetailType.GET_USER_DETAIL_SUCCESS,
  data,
});

export const getUserDetailFaild = (error: any): IGetUserDetailError => ({
  type: GetUserDetailType.GET_USER_DETAIL_ERROR,
  error,
});
