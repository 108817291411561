import { AnyAction, Reducer } from 'redux';
import { initialEditUserScoreState, EditUserScoreState } from './state';
import { EditUserScoreType } from '../../actions/editUserScore/actionTypes';
import { toastSuccess, toastError } from '../../../helpers/toastHelper';

export const EditUserScoreReducer: Reducer = (
  state: EditUserScoreState = initialEditUserScoreState,
  action: AnyAction
) => {

  switch (action.type) {
    case EditUserScoreType.EDIT_USER_SCORE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case EditUserScoreType.EDIT_USER_SCORE_SUCCESS: {


      toastSuccess('bo_score_updated');
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case EditUserScoreType.EDIT_USER_SCORE_ERROR: {


      toastError('bo_score_update_fail');
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
