import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleTransactionAgentOverview } from 'src/apis/service/Dashboard';
import { TransactionAgentOverviewTypes } from '../../actions/transactionAgentOverview';
import { transactionAgentOverviewError, transactionAgentOverviewFetching, transactionAgentOverviewFilter, transactionAgentOverviewSuccess } from '../../actions/transactionAgentOverview/action';

function* transactionAgentOverviewSaga():Generator<any> {
    yield put(transactionAgentOverviewFetching(true))
    try {
      const data:any = yield handleTransactionAgentOverview()
      if(data && data?.status === 200){
        yield put(transactionAgentOverviewSuccess(data))
      } else {
        yield put(transactionAgentOverviewError('error'))
      }
    } catch (error) {
        yield put(transactionAgentOverviewError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(transactionAgentOverviewFilter(action.payload));
}
export function* watchTransactionAgentOverview() {
   yield takeEvery(TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW, transactionAgentOverviewSaga)
   yield debounce(500,TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_FILTER_DEBOUNCE,handleSearchDebounce)
}
