import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleAgentGetDirectDetail } from 'src/apis/service/P2PTransaction';
import { AgentGetDirectDetailTypes } from '../../actions/agentGetDirectDetail';
import { agentGetDirectDetailError, agentGetDirectDetailFetching, agentGetDirectDetailFilter, agentGetDirectDetailSuccess } from '../../actions/agentGetDirectDetail/action';

function* agentGetDirectDetailSaga(action:any):Generator<any> {
    yield put(agentGetDirectDetailFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleAgentGetDirectDetail(payload)
      if(data && data?.status === 200){
        yield put(agentGetDirectDetailSuccess(data))
      } else {
        yield put(agentGetDirectDetailError('error'))
      }
    } catch (error) {
        yield put(agentGetDirectDetailError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(agentGetDirectDetailFilter(action.payload));
}
export function* watchAgentGetDirectDetail() {
   yield takeEvery(AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL, agentGetDirectDetailSaga)
   yield debounce(500,AgentGetDirectDetailTypes.AGENT_GET_DIRECT_DETAIL_FILTER_DEBOUNCE,handleSearchDebounce)
}
