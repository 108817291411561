import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { ListParams } from "src/models/common";


interface IDataScoreHistory {
    data: {
        items:any[],
        totalCount: number
    };
    status: number;
    message: string;
}

export interface IDataScoreHistoryState {
   data: IDataScoreHistory,
   isFetching: boolean,
   filter: ListParams,
   pagination: {
    totalCount: number,
   },
}

export const initialScoreHistoryState : IDataScoreHistoryState = {
    data: {
        data: {
            items:[],
            totalCount:0
        },
        message:'',
        status:0
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}