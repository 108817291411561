import { AnyAction, Reducer } from 'redux';
import { IGetCryptoExchangeOffersListState, initialGetCryptoExchangeOffersListState } from './state';
import { GetCryptoExchangeOffersListTypes } from 'src/store/actions/getCryptoExchangeOffersList';

export const GetCryptoExchangeOffersListReducer: Reducer = (
  state: IGetCryptoExchangeOffersListState = initialGetCryptoExchangeOffersListState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data?.data?.items || [],
        isFetching: false,
      };
    }
    case GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetCryptoExchangeOffersListTypes.GET_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
