import { put, takeEvery, debounce } from "redux-saga/effects";
import { GetWalletDetailTypes } from "../../actions/getWalletDetail";
import {
  getWalletDetailFetching,
  getWalletDetailSuccess,
  getWalletDetailError,
  getWalletDetailFilter,
} from "../../actions/getWalletDetail/action";
import { handleGetWalletDetail } from "src/apis/service/CryptoWallet";

function* getWalletDetailSaga(action: any): Generator<any> {
  yield put(getWalletDetailFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetWalletDetail(payload);
    if (data && data?.status === 200) {
      yield put(getWalletDetailSuccess(data));
    } else {
      yield put(getWalletDetailError("error"));
    }
  } catch (error) {
    yield put(getWalletDetailError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getWalletDetailFilter(action.payload));
}
export function* watchGetWalletDetail() {
  yield takeEvery(GetWalletDetailTypes.GET_WALLET_DETAIL, getWalletDetailSaga);
  yield debounce(
    500,
    GetWalletDetailTypes.GET_WALLET_DETAIL_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
