import {CreateNotificationTypes} from './actionTypes';
import {
    ICreateNotification,
    ICreateNotificationFaild,
    ICreateNotificationSuccess,
    ICreateNotificationIsFetching
} from './interfaces';
import { History } from 'history';

export const createNotificationFetching = (isFetching:boolean) : ICreateNotificationIsFetching  => ({
    type: CreateNotificationTypes.CREATE_NOTIFICATION_FETCHING,
    isFetching
})

export const createNotification = (payload:Object, history: History) : ICreateNotification => ({
    type: CreateNotificationTypes.CREATE_NOTIFICATION,
    payload,
    history
})

export const createNotificationSuccess = (data:any) : ICreateNotificationSuccess => ({
    type: CreateNotificationTypes.CREATE_NOTIFICATION_SUCCESS,
    data
})

export const createNotificationFaild = (error:any) : ICreateNotificationFaild => ({
    type: CreateNotificationTypes.CREATE_NOTIFICATION_FAILD,
    error
})
