export interface DetailDataTransactionHistoryLog  {
    changeBy: string,
    status: string,
    oldStatus: string,
    changeDate: string
}
interface DataTransactionHistoryLog  {
    data:DetailDataTransactionHistoryLog[],
    status: number,
    message: string
}

export interface TransactionHistoryState {
    dataBuy: DataTransactionHistoryLog,
    dataSell: DataTransactionHistoryLog,
    isFetching:boolean
}

export const initialTransactionHistoryState: TransactionHistoryState  = {
    dataBuy:{
        data:[],
        status:0,
        message:''
    },
    dataSell:{
        data:[],
        status:0,
        message:''
    },
    isFetching:false
}