import { AnyAction, Reducer } from 'redux';
import { initialExportCryptoState, ExportCryptoState } from './state';
import { ExportCryptoType } from '../../actions/ExportCrypto/actionTypes';


export const ExportCryptoReducer: Reducer = (
  state: ExportCryptoState = initialExportCryptoState,
  action: AnyAction
) => {
  switch (action.type) {
    case ExportCryptoType.EXPORT_CRYPTO: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ExportCryptoType.EXPORT_CRYPTO_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case ExportCryptoType.EXPORT_CRYPTO_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
