import { AnyAction, Reducer } from "redux";
import {
  IHandleGetWalletDetailOverviewState,
  initialHandleGetWalletDetailOverviewState,
} from "./state";
import { HandleGetWalletDetailOverviewTypes } from "src/store/actions/handleGetWalletDetailOverview";

export const HandleGetWalletDetailOverviewReducer: Reducer = (
  state: IHandleGetWalletDetailOverviewState = initialHandleGetWalletDetailOverviewState,
  action: AnyAction
) => {
  switch (action.type) {
    case HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_SUCCESS: {
      return {
        ...state,
        data: action.data.data,
        isFetching: false,
      };
    }
    case HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_FAILD: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case HandleGetWalletDetailOverviewTypes.HANDLE_GET_WALLET_DETAIL_OVERVIEW_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
