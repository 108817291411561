import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getCryptoWalletTransactionsIsFetching,
  getCryptoWalletTransactionsFailed,
  getCryptoWalletTransactionsSuccess,
  GetCryptoWalletTransactionsTypes
} from '../../actions';
import { handleGetAllCryptoTransactions } from 'src/apis/service/Transactions';

function* getCryptoWalletTransactionsSaga(filter: any):Generator<any> {
  yield put(getCryptoWalletTransactionsIsFetching(true))
  try {
    // const filterData = select((state: RootState) => state.cryptoWalletTransactions.filter)
    const data:any = yield call(handleGetAllCryptoTransactions, filter)
    if(data && data?.status === 200) {
      yield put(getCryptoWalletTransactionsSuccess(data))
    } else {
      yield put(getCryptoWalletTransactionsFailed('error'))
    }
  } catch (error) {
    yield put(getCryptoWalletTransactionsFailed(error))
  } finally {
    yield put(getCryptoWalletTransactionsIsFetching(false))
  }
}

export function* watchGetCryptoWalletTransactions() {
  yield takeEvery(GetCryptoWalletTransactionsTypes.GET_CRYPTO_WALLET_TRANSACTIONS, getCryptoWalletTransactionsSaga);
}
