import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetUnreadCountNotification } from 'src/apis/service/Notifications';
import { GetUnreadCountNotificationTypes } from '../../actions/getUnreadCountNotification';
import {
  getUnreadCountNotificationError,
  getUnreadCountNotificationFetching,
  getUnreadCountNotificationFilter,
  getUnreadCountNotificationSuccess,
} from '../../actions/getUnreadCountNotification/action';

function* getUnreadCountNotificationSaga(): Generator<any> {
  yield put(getUnreadCountNotificationFetching(true));
  try {
    const data: any = yield handleGetUnreadCountNotification();

    if (data && data?.status === 200) {
      yield put(getUnreadCountNotificationSuccess(data));
    } else {
      yield put(getUnreadCountNotificationError('error'));
    }
  } catch (error) {
    yield put(getUnreadCountNotificationError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getUnreadCountNotificationFilter(action.payload));
}
export function* watchGetUnreadCountNotification() {
  yield takeEvery(
    GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION,
    getUnreadCountNotificationSaga
  );
  yield debounce(
    500,
    GetUnreadCountNotificationTypes.GET_UNREAD_COUNT_NOTIFICATION_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
