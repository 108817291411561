import { ListParams } from "src/models/common";

interface IDataHandleDeleteOffer {
  data:{
      items:any[];
      total:number;
      success: boolean,
      message: string
  };
  success: boolean;
  message: string;
  error:any;
}

export interface IHandleDeleteOfferState {
    data:IDataHandleDeleteOffer;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialHandleDeleteOfferState: IHandleDeleteOfferState = {
    data:{
        data:{
            items:[],
            total:0,
            message: "",
            success: false
        },
        error:null,
        success:false,
        message: ""
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


