import { MainAnnoucement } from 'src/apis/service/Announcements';
import { SetMainPostAnnouncementsType } from './actionTypes';
import { 
    IFetchingSetMainAnnouncements,
    ISetMainPostAnnouncements,
    ISetMainPostAnnouncementsError,
    ISetMainPostAnnouncementsSuccess
 } from './interfaces';

export const SetMainPostAnnouncementsIsFetching = (isFetching:boolean) : IFetchingSetMainAnnouncements => ({
    type: SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS_IS_FETCHING,
    isFetching
})

export const SetMainPostAnnouncements = (payload: any) : ISetMainPostAnnouncements => ({
    type: SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS,
    payload
})

export const SetMainPostAnnouncementsSuccess = (data:any) : ISetMainPostAnnouncementsSuccess => ({
    type: SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS_SUCCESS,
    data
})

export const SetMainPostAnnouncementsFaild = (error:any) : ISetMainPostAnnouncementsError => ({
    type: SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS_FAILD,
    error
})