import { ListParams } from "src/models/common";

interface IDataGetTokenDetail {
  data: Object;
  message: string;
  status: number;
  success: boolean;
  walletAddress: string;
}

export interface IGetTokenDetailState {
  data: IDataGetTokenDetail;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialGetTokenDetailState: IGetTokenDetailState = {
  data: {
    data: {},
    message: "",
    status: 0,
    success: false,
    walletAddress: "",
  },
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
