import { put, takeEvery } from 'redux-saga/effects';
import { handleGetOfferHistoryStatus } from 'src/apis/service/Transactions';
import { getOfferHistoryStatusFaild, getOfferHistoryStatusIsFetching, getOfferHistoryStatusSuccess, GetOfferHistoryStatusTypes } from 'src/store/actions';

function* getListOfferHistoryStatusSaga(action: any): Generator<any> {
    yield put(getOfferHistoryStatusIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield handleGetOfferHistoryStatus(payload)      
      if(data && data?.status === 200){
        yield put(getOfferHistoryStatusSuccess(data));
      } else {
        yield put(getOfferHistoryStatusFaild('error'))
      }
    } catch (error) {
        yield put(getOfferHistoryStatusFaild(error))
    }
}

export function* watchGetListOfferHistoryStatus() {
   yield takeEvery(GetOfferHistoryStatusTypes.GET_OFFER_HISTORY_STATUS , getListOfferHistoryStatusSaga)
}