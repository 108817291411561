import { put, takeEvery, select } from 'redux-saga/effects';
import { handleDeleteOffer } from 'src/apis/service/P2PTransaction';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getOfferSellTransaction, removeOfferSellTransactionFaild, removeOfferSellTransactionIsFetching, removeOfferSellTransactionSuccess } from 'src/store/actions';
import { IApplicationState } from 'src/store/IApplicationState';
import { RemoveOfferSellTransactionType } from '../../actions/removeOfferSellTransaction/actionTypes';

function* RemoveOfferSellTransactionSaga(action:any):Generator<any>{


  yield put(removeOfferSellTransactionIsFetching(true))
  const { id } = action;
  try {
    const dataRemoveOfferSellTransaction: any = yield handleDeleteOffer(id);
    if (dataRemoveOfferSellTransaction.status === 200) {
      const IFilterOfferSell: any = yield select((state:IApplicationState) => state.offerSellTransaction.filter)
      toastSuccess('bo_delete_offer_success');
      yield put(removeOfferSellTransactionSuccess(dataRemoveOfferSellTransaction))
      yield put(getOfferSellTransaction(IFilterOfferSell));
    } else {
      toastError('bo_you_can_t_delete_this_offer_due_to_pending_transaction')
    }
  } catch (error) {
     yield put(removeOfferSellTransactionFaild(error)) 
  }
}

export function* watchRemoveOfferSellTransaction(){
    yield takeEvery(RemoveOfferSellTransactionType.REMOVE_OFFER_SELL_TRANSACTION, RemoveOfferSellTransactionSaga)
}