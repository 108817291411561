import { ListParams } from "src/models/common";

interface IDataSet2FAEnabled {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface ISet2FAEnabledState {
    data: IDataSet2FAEnabled;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
    countSuccess: number
}

export const initialSet2FAEnabledState: ISet2FAEnabledState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {},
    pagination: {
        totalCount: 0,
    },
    countSuccess: 0
}


