import { AnyAction, Reducer } from 'redux';
import { IGetKYCSystemStatusHistoryState, initialGetKYCSystemStatusHistoryState } from './state';
import { GetKYCSystemStatusHistoryTypes } from 'src/store/actions/getKYCSystemStatusHistory';

export const GetKYCSystemStatusHistoryReducer: Reducer = (
  state: IGetKYCSystemStatusHistoryState = initialGetKYCSystemStatusHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetKYCSystemStatusHistoryTypes.GETKYC_SYSTEM_STATUS_HISTORY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
