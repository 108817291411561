import { StatusAgency } from "src/models/agency";
import { ListParams } from "src/models/common";
import Request from "../../config";
import * as Sentry from "@sentry/react";
import i18next from "i18next";

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_API });

const getAgencyManagement = async (listparam: ListParams): Promise<any[]> => {
  try {
    return await requestApi.post(`api/AgencyManagement/Search`, listparam)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const getHistory = async (data: ListParams): Promise<any[]> => {
  try {
    return await requestApi.post(`api/AgencyManagement/history`, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const updateStatusAgency = async (data: StatusAgency): Promise<any[]> => {
  try {
    return await requestApi.post(`api/AgencyManagement/status/update`, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

const donwloadKYCAgreement = async () : Promise<any> => {
  try {
      return await requestApi.get(`api/Assets/docs/kyc/downloaddocument?languageCode=${i18next.language}`, {headers: { responseType: 'blob',  'Content-Disposition': "attachment; filename=template.docx", 'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}, responseType: 'arraybuffer'})
  } catch (error) {
      Sentry.captureException(error);
      throw error
  }
}

export { getAgencyManagement, getHistory, updateStatusAgency, donwloadKYCAgreement };
