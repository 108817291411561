import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetAllCurrencyNoFilter } from 'src/apis/service/Currencies';
import { GetAllCurrencyNoFilterTypes } from '../../actions/getAllCurrencyNoFilter';
import { getAllCurrencyNoFilterError, getAllCurrencyNoFilterFetching, getAllCurrencyNoFilterFilter, getAllCurrencyNoFilterSuccess } from '../../actions/getAllCurrencyNoFilter/action';

function* getAllCurrencyNoFilterSaga(action:any):Generator<any> {
    yield put(getAllCurrencyNoFilterFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetAllCurrencyNoFilter(payload)
      if(data && data?.status === 200){
        yield put(getAllCurrencyNoFilterSuccess(data))
      } else {
        yield put(getAllCurrencyNoFilterError('error'))
      }
    } catch (error) {
        yield put(getAllCurrencyNoFilterError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getAllCurrencyNoFilterFilter(action.payload));
}
export function* watchGetAllCurrencyNoFilter() {
   yield takeEvery(GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER, getAllCurrencyNoFilterSaga)
   yield debounce(500,GetAllCurrencyNoFilterTypes.GET_ALL_CURRENCY_NO_FILTER_FILTER_DEBOUNCE,handleSearchDebounce)
}
