export interface DeleteAnnouncements {
    data: any,
    errors: any,
    success: any
}

export interface DeleteAnnouncementsState {
    data: DeleteAnnouncements,
    isFetching: boolean,
    successCount: number
}

export const initialDeleteAnnouncementsState: DeleteAnnouncementsState = {
    data: {
        data: {},
        errors: '',
        success: false
    },
    isFetching: false,
    successCount: 0
}