import { AnyAction, Reducer } from 'redux';
import { GetAdminOrAgentTransfersTypes } from 'src/store/actions';
import { IAdminOrAgentTransfersState, initialAdminOrAgentTransfersState } from './state';

export const GetAdminOrAgentTransfersReducer: Reducer = (
  state: IAdminOrAgentTransfersState = initialAdminOrAgentTransfersState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetAdminOrAgentTransfersTypes.GET_ADMIN_OR_AGENT_TRANSFERS_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAdminOrAgentTransfersTypes.FILTER_ADMIN_OR_AGENT_TRANSFERS: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
