import { AnyAction, Reducer } from 'redux';
import { toastError } from '../../../helpers/toastHelper';
import { CreateAdminTypes } from '../../actions/createAdmin/actionTypes';
import { CreateAdminState, initialCreateAdminState } from './state';

export const CreateAdminReducer: Reducer = (
  state: CreateAdminState = initialCreateAdminState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateAdminTypes.CREATE_ADMIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateAdminTypes.CREATE_ADMIN_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case CreateAdminTypes.CREATE_ADMIN_FAILD: {
      toastError(action.error);
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
