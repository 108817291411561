import { ChangeUserBalanceType } from './actionTypes';
import { IChangeUserBalance, IChangeUserBalanceSuccess, IChangeUserBalanceError, IFetchingChangeUserBalance } from './interfaces';


export const changeUserBalanceIsFetching = (isFetching:boolean) : IFetchingChangeUserBalance => ({
    type:ChangeUserBalanceType.CHANGE_USER_BALANCE_FETCHING,
    isFetching
})

export const changeUserBalance = (payload:Object) : IChangeUserBalance => ({
    type:ChangeUserBalanceType.CHANGE_USER_BALANCE,
    payload
})

export const changeUserBalanceSuccess = (data:any) : IChangeUserBalanceSuccess => ({
    type:ChangeUserBalanceType.CHANGE_USER_BALANCE_SUCCESS,
    data
})

export const changeUserBalanceFaild = (error:any) : IChangeUserBalanceError => ({
    type:ChangeUserBalanceType.CHANGE_USER_BALANCE_ERROR,
    error
})