import { AnyAction, Reducer } from 'redux';
import { initialAddBankAccState, IDataAddUserBankAccountState } from './state';
import { AddUserBankAccountType } from '../../actions/addUserBankAccount';

export const AddBankAccReducer: Reducer = (
  state: IDataAddUserBankAccountState = initialAddBankAccState,
  action: AnyAction
) => {
  switch (action.type) {
      case AddUserBankAccountType.ADD_USER_BANK_ACOOUNT: {
         return {
             ...state,
             isFetching:true
         }
      }
      case AddUserBankAccountType.ADD_USER_BANK_ACCOUNT_SUCCESS: {
          return {
              ...state,
              data: action.data,
              isFetching:false
          }
      }
      case AddUserBankAccountType.ADD_USER_BANK_ACCOUNT_ERROR: {
        return {
            ...state,
            error: action.error,
            isFetching:false
        }
    }
    default:{
      return state
    }
  }
}