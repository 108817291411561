import { put, takeEvery } from 'redux-saga/effects';
import { getSupportedCryptoCurrenciesAPI } from 'src/apis/service/Currencies';
import { setSupportedCryptoCurrenciesAction, supportedCryptoCurrenciesActionTypes } from '../../actions';
import { setStorageItem } from 'src/utils/newUtils/localStorageHelper';
import { LocalStorageKey } from 'src/contants/constantUtils/localStorageKey';

function* getSupportedCryptoCurrenciesSaga(action: any): Generator<any> {
  try {
    const response: any = yield getSupportedCryptoCurrenciesAPI();
    if (response.status === 200 && response?.data?.length) {
      setStorageItem(LocalStorageKey.ListCryptoSupported, JSON.stringify(response?.data))
      yield put(setSupportedCryptoCurrenciesAction(response.data));
    }
  } catch (error) {
  }
}

export function* watchSupportedCryptoCurrenciesSaga() {
  yield takeEvery(supportedCryptoCurrenciesActionTypes.GET_SUPPORTED_CRYPTO_CURRENCIES, getSupportedCryptoCurrenciesSaga);
}
