import { call, debounce, put, takeEvery } from 'redux-saga/effects';
import { handleGetP2PExchangeTransactions } from 'src/apis/service/P2PExchange';
import { toastError } from 'src/helpers/toastHelper';
import { getP2PExchangeTransactionsFaild, getP2PExchangeTransactionsIsFetching, getP2PExchangeTransactionsSuccess, GetP2PExchangeTransactionsType, setFilterP2PExchangeTransactions } from 'src/store/actions/P2PExchangeTransactions';

function* getListP2PExchangeTransactionsSaga(action: any): Generator<any> {
    yield put(getP2PExchangeTransactionsIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetP2PExchangeTransactions, payload)  
      if(data && data?.status === 200){
        yield put(getP2PExchangeTransactionsSuccess(data));
      } else {
        yield put(getP2PExchangeTransactionsFaild('error'))
      }
    } catch (error) {
        yield put(getP2PExchangeTransactionsFaild(error))
    }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterP2PExchangeTransactions(action.payload));
}

export function* watchGetListP2PExchangeTransactions() {
  yield takeEvery(GetP2PExchangeTransactionsType.GET_P2P_EXCHANGE_TRANSACTIONS , getListP2PExchangeTransactionsSaga)
  yield debounce(500, GetP2PExchangeTransactionsType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_TRANSACTIONS, handleSearchDebounce)
}