import { GetTransactionDetailByAgentTypes } from './actionTypes';
import { IGetTransactionDetailByAgent, IGetTransactionDetailByAgentFetching, IGetTransactionDetailByAgentSuccess, IGetTransactionDetailByAgentError, IGetTransactionDetailByAgentFilter, IGetTransactionDetailByAgentFiterDebounce, IGetTransactionDetailByAgentClean } from "./interfaces";


export const getTransactionDetailByAgent = (payload: Object): IGetTransactionDetailByAgent => ({
    type: GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT,
    payload
})

export const getTransactionDetailByAgentFetching = (isFetching: boolean): IGetTransactionDetailByAgentFetching => ({
    type: GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_FETCHING,
    isFetching
})

export const getTransactionDetailByAgentSuccess = (data: any): IGetTransactionDetailByAgentSuccess => ({
    type: GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_SUCCESS,
    data
})

export const getTransactionDetailByAgentError = (errorMsg: any): IGetTransactionDetailByAgentError => ({
    type: GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_FAILD,
    error: errorMsg
})

export const getTransactionDetailByAgentFilter = (payload: any): IGetTransactionDetailByAgentFilter => ({
    type: GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_FILTER,
    payload
})

export const getTransactionDetailByAgentFilterDebounce = (payload: any): IGetTransactionDetailByAgentFiterDebounce => ({
    type: GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_FILTER_DEBOUNCE,
    payload
})

export const getTransactionDetailByAgentClean = (): IGetTransactionDetailByAgentClean => ({
    type: GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_CLEAN
})