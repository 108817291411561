import { CreateExchangeOfferType } from './actionTypes';
import { ICreateExchangeOffer, ICreateExchangeOfferError, ICreateExchangeOfferSuccess, IFetchingCreateExchangeOffer } from './interfaces';
import { History } from 'history';

export const createExchangeOfferIsFetching = (isFetching: boolean): IFetchingCreateExchangeOffer => ({
    type: CreateExchangeOfferType.CREATE_EXCHANGE_OFFER_FETCHING,
    isFetching
})

export const createExchangeOffer = (payload: Object): ICreateExchangeOffer => ({
    type: CreateExchangeOfferType.CREATE_EXCHANGE_OFFER,
    payload
})

export const createExchangeOfferSuccess = (data: any): ICreateExchangeOfferSuccess => ({
    type: CreateExchangeOfferType.CREATE_EXCHANGE_OFFER_SUCCESS,
    data
})

export const createExchangeOfferFaild = (error: any): ICreateExchangeOfferError => ({
    type: CreateExchangeOfferType.CREATE_EXCHANGE_OFFER_ERROR,
    error
})