import { debounce, put, takeEvery, select } from "redux-saga/effects";
import { getKycDetail } from "src/apis/service/Kyc";
import {
  getKycDetailAtFetching,
  getKycDetailFaild,
  getKycDetailSuccess,
  GetKycDetailTypes,
  setFilterKycDetail,
} from "src/store/actions";
import { IApplicationState } from "src/store/IApplicationState";
import moment from "moment";

export const GENDER = [
  {
    value: 0,
    name: "Female",
    label: "bo_female",
  },
  {
    value: 1,
    name: "Male",
    label: "bo_male",
  },
  {
    value: 2,
    name: "Other",
    label: "bo_others_label",
  },
];
const renderName = (value: number, list: any[]) => {
  if (Boolean(list) && list.length > 0) {
    const detail = list?.filter((o: any) => o.value === value);
    return detail[0]?.name;
  }
};

function* getKycDetailSaga(action: any): Generator<any> {
  yield put(getKycDetailAtFetching(true));
  const { payload } = action;
  try {
    const dataKycDetail: any = yield getKycDetail(payload);
    const countryList: any = yield select(
      (state: IApplicationState) => state.listCountry.data
    );
    let newData = { ...dataKycDetail.data };
    if (newData.bankInformation)
      newData = {
        ...dataKycDetail.data,
        bankInformation: {
          ...dataKycDetail.data.bankInformation,
          bankCountryDetail: renderName(
            Number(dataKycDetail.data.bankInformation?.bankCountryDetail),
            countryList
          ),
        },
      };

    if (newData.userInformation)
      newData = {
        ...dataKycDetail.data,
        userInformation: {
          ...dataKycDetail.data?.userInformation,
          gender: renderName(
            Number(dataKycDetail.data?.userInformation?.gender),
            GENDER
          ),
          dateOfBirth: moment
            .utc(dataKycDetail.data?.userInformation?.dateOfBirth)
            .local()
            .format("YYYY-MM-DD"),
        },
      };

    if (newData.address)
      newData = {
        ...dataKycDetail.data,
        address: {
          ...dataKycDetail?.data?.address,
          countryId: renderName(
            Number(dataKycDetail.data?.address?.countryId),
            countryList
          ),
        },
      };
    yield put(getKycDetailSuccess(newData));
  } catch (error) {
    yield put(getKycDetailFaild(error));
  }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterKycDetail(action.payload));
}

export function* watchGetListKycDetail() {
  yield takeEvery(GetKycDetailTypes.GET_KYC_DETAIL, getKycDetailSaga);
  yield debounce(
    500,
    GetKycDetailTypes.GET_LIST_FILTER_DEBOUNCE_KYC_DETAIL,
    handleSearchDebounce
  );
}
