
// get transaction

import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { ListParams } from "src/models/common";

interface IDataListUserDeactivated {
  data:{
      items:any[];
      totalCount:number;
  };
  status: number;
  message: string;
}

export interface IListUserDeactivatedState {
    data:IDataListUserDeactivated;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialListUserDeactivatedState: IListUserDeactivatedState = {
    data:{
        data:{
            items:[],
            totalCount:0
        },
        message:'',
        status:0
    },
    isFetching:false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        // OrderBy: 'lastLoginAttemptDate',
        // orderDirection: 'desc',
        // CountryId: null,
        // Username: ''
    },
    pagination: {
        totalCount: 0,
    }
}

