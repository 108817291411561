import { UnLockUserType } from './actionTypes';
import { IFetchingUnLockUser,IUnLockUser, IUnLockUserError, IUnLockUserSuccess } from './interfaces';


export const unLockUserIsFetching = (isFetching:boolean) : IFetchingUnLockUser => ({
    type: UnLockUserType.UNLOCK_USER_FETCHING,
    isFetching
})

export const unLockUser = (payload:Object,listUserDeactive: boolean) : IUnLockUser => ({
    type: UnLockUserType.UNLOCK_USER,
    payload,
    listUserDeactive
})

export const unLockUserSuccess = (data:any) : IUnLockUserSuccess => ({
    type: UnLockUserType.UNLOCK_USER_SUCCESS,
    data
})

export const unLockUserFaild = (error:any) : IUnLockUserError => ({
    type: UnLockUserType.UNLOCK_USER_ERROR,
    error
})