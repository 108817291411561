import { GetAgencyManagementType } from './actionTypes';
import { IFetchingGetAgencyManagement,IGetAgencyManagement, IGetAgencyManagementSuccess, IGetAgencyManagementError, IGetListFilterAgencyManagement, IGetListFiterDebounceAgency } from './interfaces';

export const getAgencyManagementIsFetching = (isFetching:boolean) : IFetchingGetAgencyManagement => ({
    type: GetAgencyManagementType.GET_AGENCY_MANAGEMENT_FETCHING,
    isFetching
})

export const getAgencyManagement = (payload: any) : IGetAgencyManagement => ({
    type: GetAgencyManagementType.GET_AGENCY_MANAGEMENT,
    payload
})

export const getAgencyManagementSuccess = (data:any) : IGetAgencyManagementSuccess => ({
    type: GetAgencyManagementType.GET_AGENCY_MANAGEMENT_SUCCESS,
    data
})

export const getAgencyManagementFaild = (error:any) : IGetAgencyManagementError => ({
    type: GetAgencyManagementType.GET_AGENCY_MANAGEMENT_ERROR,
    error
})

export const setFilterAgencyManagement = (payload: any): IGetListFilterAgencyManagement => ({
    type: GetAgencyManagementType.GET_LIST_AGENCY_MANAGEMENT_FILTER,
    payload
})

export const setFilterDebounceAgency = (payload: any): IGetListFiterDebounceAgency => ({
    type: GetAgencyManagementType.GET_LIST_FILTER_DEBOUNCE_AGENCY,
    payload 
})