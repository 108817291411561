import { put, takeEvery, debounce } from "redux-saga/effects";
import { GetWalletProductBlockchainTypes } from "../../actions/getWalletProductBlockchain";
import {
  getWalletProductBlockchainFetching,
  getWalletProductBlockchainSuccess,
  getWalletProductBlockchainError,
  getWalletProductBlockchainFilter,
} from "../../actions/getWalletProductBlockchain/action";
import { handleGetWalletProductBlockchain } from "src/apis/service/CryptoWallet";

function* getWalletProductBlockchainSaga(action: any): Generator<any> {
  yield put(getWalletProductBlockchainFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetWalletProductBlockchain(payload);
    if (data && data?.status === 200) {
      yield put(getWalletProductBlockchainSuccess(data));
    } else {
      yield put(getWalletProductBlockchainError("error"));
    }
  } catch (error) {
    yield put(getWalletProductBlockchainError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getWalletProductBlockchainFilter(action.payload));
}
export function* watchGetWalletProductBlockchain() {
  yield takeEvery(
    GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN,
    getWalletProductBlockchainSaga
  );
  yield debounce(
    500,
    GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
