import { transactionHistoryStatusLog } from './actionTypes';
import {
    ITransactionHistoryStatusLog,
    ITransactionHistoryStatusLogError,
    ITransactionHistoryStatusLogIsFetching,
    ITransactionHistoryStatusLogBuySuccess,
    ITransactionHistoryStatusLogSellSuccess,
    ITransactionHistoryStatusLogClean
} from './interfaces';

export const getListTransactionHistoryLog = (payload: number, transactionType: string): ITransactionHistoryStatusLog => ({
    type: transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG,
    payload,
    transactionType
})

export const getListTransactionHistoryLogIsFetching = (isFetching: boolean): ITransactionHistoryStatusLogIsFetching => ({
    type: transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_FETCHING,
    isFetching
})

export const getListTransactionHistoryLogBuySuccess = (data: any): ITransactionHistoryStatusLogBuySuccess => ({
    type: transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_SUCCESS_BUY,
    data
})

export const getListTransactionHistoryLogSellSuccess = (data: any): ITransactionHistoryStatusLogSellSuccess => ({
    type: transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_SUCCESS_SELL,
    data
})

export const getListTransactionHistoryLogFaild = (error: any): ITransactionHistoryStatusLogError => ({
    type: transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_FAILD,
    error
})

export const getListTransactionHistoryLogClean = (): ITransactionHistoryStatusLogClean => ({
    type: transactionHistoryStatusLog.TRANSACTION_HISTORY_STATUS_LOG_CLEAN,
})