import { ListParams } from "src/models/common";

interface IDataGetDirectTransactionDetail {
  data:any;
  error:any;
  success:boolean;
}

export interface IGetDirectTransactionDetailState {
    data:IDataGetDirectTransactionDetail;
    isFetching:boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetDirectTransactionDetailState: IGetDirectTransactionDetailState = {
    data:{
        data:null,
        error:null,
        success:false
    },
    isFetching:false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


