import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetTransactionDetailByAgentTypes} from '../../actions/getTransactionDetailByAgent';
import {getTransactionDetailByAgentFetching,getTransactionDetailByAgentSuccess,getTransactionDetailByAgentError, getTransactionDetailByAgentFilter} from '../../actions/getTransactionDetailByAgent/action';
import {handleGetListUser} from '../../../apis/service/user';
import { handleGetTransactionDetailByAgent } from 'src/apis/service/P2PTransaction';

function* getTransactionDetailByAgentSaga(action:any):Generator<any> {
    yield put(getTransactionDetailByAgentFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleGetTransactionDetailByAgent(payload)
      if(data && data?.status === 200){
        yield put(getTransactionDetailByAgentSuccess(data))
      } else {
        yield put(getTransactionDetailByAgentError('error'))
      }
    } catch (error) {
        yield put(getTransactionDetailByAgentError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getTransactionDetailByAgentFilter(action.payload));
}
export function* watchGetTransactionDetailByAgent() {
   yield takeEvery(GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT, getTransactionDetailByAgentSaga)
   yield debounce(500,GetTransactionDetailByAgentTypes.GET_TRANSACTION_DETAIL_BY_AGENT_FILTER_DEBOUNCE,handleSearchDebounce)
}
