import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getUserLang } from 'src/utils/storage';
import translationEN from '../locales/en/translation.json';
import translationKO from '../locales/ko/translation.json';
// import translationEN from '../locales/translationEN.json'
// import translationKO from '../locales/translationKO.json'

const URL = process.env.REACT_APP_BASE_URL_CONFIG;

// the translations
const resources = {
  en: {
    general: translationEN,
  },
  ko: {
    general: translationKO,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    backend: {
      loadPath: `${URL}Language/locales/{{lng}}/translation.json`,
      customHeaders: () => ({
        'x-api-key': 'apiKey',
      }),
    },
    fallbackLng: getUserLang(),
    debug: false,
    react: {
      useSuspense: true,
      wait: true,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    initImmediate: false,
    fallbackNS: 'general',
    partialBundledLanguages: true,
  })
  .catch((error) => {
    console.log(error);
  });

// i18n.addResourceBundle('en', 'general', resources.en, true, true);
// i18n.addResourceBundle('ko', 'general', resources.ko, true, true);

export default i18n;
