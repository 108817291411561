import { AnyAction, Reducer } from 'redux';
import { IApplyKycDeclarationState, initialApplyKycDeclarationState } from './state';
import { ApplyKycDeclarationTypes } from 'src/store/actions/ApplyKycDeclaration';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

export const ApplyKycDeclarationReducer: Reducer = (
  state: IApplyKycDeclarationState = initialApplyKycDeclarationState,
  action: AnyAction
) => {
  switch (action.type) {
    case ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_SUCCESS: {
      toastSuccess("bo_application_completed")
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
        successCount: state.successCount + 1
      };
    }
    case ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_FAILD: {
      toastError("bo_failed")
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
