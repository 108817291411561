import { AnyAction, Reducer } from 'redux';
import {
  initialBlockAndUnBlockP2PExchangeOfferState,
  IBlockAndUnBlockP2PExchangeOfferState
} from './state';
import { BlockAndUnblockP2PExchangeOffer } from '../../../actions/blockOrUnBlockAndCancelP2PExchangeOffer/blockOrUnBlock';

export const blockAndUnBlockP2PExchangeOfferReducer: Reducer = (
  state: IBlockAndUnBlockP2PExchangeOfferState = initialBlockAndUnBlockP2PExchangeOfferState,
  action: AnyAction
) => {
  switch (action.type) {
    case BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER: {
      return {
        ...state,
        isFetching: true
      };
    }
    case BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case BlockAndUnblockP2PExchangeOffer.BLOCK_OR_UNBLOCK_P2PEXCHANGE_OFFER_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    default: {
      return state;
    }
  }
};
