import { AnyAction, Reducer } from 'redux';
import { initialListUserScoreState, IDataListUserScoreState } from './state';
import { GetListUserScoreType } from '../../actions/getListUserScore';


export const GetListUserScoreReducer: Reducer = (
  state: IDataListUserScoreState = initialListUserScoreState,
  action: AnyAction
) => {
  switch (action.type) {
      case GetListUserScoreType.GET_LIST_USER_SCORE: {
         return {
             ...state,
             isFetching:true
         }
      }
      case GetListUserScoreType.GET_LIST_USER_SCORE_SUCCESS: {
          return {
              ...state,
              data: action.data,
              isFetching:false,
              pagination: action.data.data.totalCount
          }
      }
      case GetListUserScoreType.GET_LIST_USER_SCORE_FAILD: {
        return {
            ...state,
            error: action.error,
            isFetching:false
        }
    }
    case GetListUserScoreType.GET_LIST_FILTER_USER_SCORE: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:{
      return state
    }
  }
}