import { AnyAction, Reducer } from 'redux';
import { IGetUserKYCPriorityHistoryState, initialGetUserKYCPriorityHistoryState } from './state';
import { GetUserKYCPriorityHistoryTypes } from 'src/store/actions/getUserKYCPriorityHistory';

export const GetUserKYCPriorityHistoryReducer: Reducer = (
  state: IGetUserKYCPriorityHistoryState = initialGetUserKYCPriorityHistoryState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetUserKYCPriorityHistoryTypes.GET_USER_KYC_PRIORITY_HISTORY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
