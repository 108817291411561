export interface IDataActiveCurrency {
    status: number;
    data: any;
    message: string;
}

export interface IDataActiveCurrencyState {
    data: IDataActiveCurrency,
    isFetching: boolean,
    success: boolean,
    successCount: number
}

export const initialActiveCurrencyState: IDataActiveCurrencyState = {
    data: {
        data: [],
        status: 0,
        message: ''
    },
    isFetching: false,
    success: false,
    successCount: 0
}