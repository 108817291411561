import { debounce, put, takeLatest } from 'redux-saga/effects';
import { handleGetAllRankConfig } from 'src/apis/service/P2PExchange';
import { getAllRankConfigError, getAllRankConfigFetching, getAllRankConfigSuccess, GetAllRankConfigType, setFilterAllRankConfig } from 'src/store/actions';
import { setStorageItem } from 'src/utils/storage';

function* getAllRankConfig(): Generator<any> {
  yield put(getAllRankConfigFetching(true))
  try {
    const dataListUserScore: any = yield handleGetAllRankConfig()
    if (dataListUserScore.status === 200) {
      var list = dataListUserScore?.data.map((config: any) => ({
        id: config.id,
        name: config.rankName,
        label: config.rankName,
        value: config.id,
        minScore: config.minScore
      }));
      yield put(getAllRankConfigSuccess(dataListUserScore, list))
    }
  } catch (error) {
    yield put(getAllRankConfigError(error))
  }
}

function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterAllRankConfig(action.payload));
}

export function* watchGetAllRankConfig() {
  yield takeLatest(GetAllRankConfigType.GET_ALL_RANK_CONFIG, getAllRankConfig);
  yield debounce(500, GetAllRankConfigType.GET_FILTER_DEBOUNCE_ALL_RANK_CONFIG, handleSearchDebounce)
}