import { put , takeEvery } from "redux-saga/effects";
import { handleGetBankByUser } from "src/apis/service/BankAccount";
import { 
   GetDataBankAccountAgent,
   getDataBankAccountAgentError,
   getDataBankAccountAgentIsFetching,
   getDataBankAccountAgentSuccess
 } from "src/store/actions/bankAccountByAgent/getDataBankAccountByAgent";
function* getDataBankAccAgent(action:any): Generator<any> {
    yield put(getDataBankAccountAgentIsFetching(true))
    try {
      const { payload } = action;
      const dataBankAccc:any = yield handleGetBankByUser(payload)
      if (dataBankAccc && dataBankAccc?.status === 200) {
          yield put(getDataBankAccountAgentSuccess(dataBankAccc))
      } else {
        yield put(getDataBankAccountAgentError('error'))
      }   
    } catch (error) {
        yield put(getDataBankAccountAgentError(error))
    }
}
export function* watchGetDataBankAccAgent(){
    yield takeEvery(GetDataBankAccountAgent.GET_DATA_BANK_AGENT, getDataBankAccAgent)
}