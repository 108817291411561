import { put , takeEvery } from "redux-saga/effects";
import { handleGetAllDataBankAccUser } from "src/apis/service/BankAccount";
import { GetDataBankAccount, getDataBankAccountIsFetching, getDataBankAccountError, getDataBankAccountSuccess } from "../../actions";
function* getDataBankAcc(action:any): Generator<any> {
    yield put(getDataBankAccountIsFetching(true))
    const { params } = action;
    try {
      const dataBankAccc:any = yield handleGetAllDataBankAccUser(params)
      if (dataBankAccc && dataBankAccc?.status === 200) {
          yield put(getDataBankAccountSuccess(dataBankAccc))
      } else {
        yield put(getDataBankAccountError('error'))
      }   
    } catch (error) {
        yield put(getDataBankAccountError(error))
    }
}
export function* watchGetDataBankAcc(){
    yield takeEvery(GetDataBankAccount.GET_DATA, getDataBankAcc)
}