import { AnyAction, Reducer } from "redux";
import {
  IGetAgentCryptoSettingState,
  initialGetAgentCryptoSettingState,
} from "./state";
import { GetAgentCryptoSettingTypes } from "src/store/actions/getAgentCryptoSetting";

export const GetAgentCryptoSettingReducer: Reducer = (
  state: IGetAgentCryptoSettingState = initialGetAgentCryptoSettingState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
        countSuccess: state.countSuccess,
      };
    }
    case GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetAgentCryptoSettingTypes.GET_AGENT_CRYPTO_SETTING_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
