import { UpdateAnnouncementsType } from './actionTypes';
import { IFetchingUpdateAnnouncements, IUpdateAnnouncements, IUpdateAnnouncementsError, IUpdateAnnouncementsSuccess } from './interfaces';

export const updateAnnouncementsIsFetching = (isFetching:boolean) : IFetchingUpdateAnnouncements => ({
    type: UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS_FETCHING,
    isFetching
})

export const updateAnnouncements = (payload: Object, history: Object) : IUpdateAnnouncements => ({
    type: UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS,
    payload,
    history
})

export const updateAnnouncementsSuccess = (data:any) : IUpdateAnnouncementsSuccess => ({
    type: UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS_SUCCESS,
    data
})

export const updateAnnouncementsFaild = (error:any) : IUpdateAnnouncementsError => ({
    type: UpdateAnnouncementsType.UPDATE_ANNOUNCEMENTS_ERROR,
    error
})