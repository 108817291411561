import {GetWalletProductBlockchainTypes} from './actionTypes';
import { IGetWalletProductBlockchain, IGetWalletProductBlockchainFetching, IGetWalletProductBlockchainSuccess, IGetWalletProductBlockchainError, IGetWalletProductBlockchainFilter, IGetWalletProductBlockchainFiterDebounce } from "./interfaces";


export const getWalletProductBlockchain = (payload:Object): IGetWalletProductBlockchain => ({
    type: GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN,
    payload
})

export const getWalletProductBlockchainFetching = (isFetching:boolean) : IGetWalletProductBlockchainFetching => ({
    type: GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN_FETCHING,
    isFetching
})

export const getWalletProductBlockchainSuccess = (data:any) : IGetWalletProductBlockchainSuccess => ({
   type: GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN_SUCCESS,
   data
})

export const getWalletProductBlockchainError = (errorMsg:any) : IGetWalletProductBlockchainError => ({
    type: GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN_FAILD,
    error:errorMsg
})

export const getWalletProductBlockchainFilter = (payload: any): IGetWalletProductBlockchainFilter => ({
    type: GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN_FILTER,
    payload
})

export const getWalletProductBlockchainFilterDebounce = (payload: any): IGetWalletProductBlockchainFiterDebounce => ({
    type: GetWalletProductBlockchainTypes.GET_WALLET_PRODUCT_BLOCKCHAIN_FILTER_DEBOUNCE,
    payload 
})
