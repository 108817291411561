import { put, takeEvery, debounce } from "redux-saga/effects";
import { GetListUserWalletTypes } from "../../actions/getListUserWallet";
import {
  getListUserWalletFetching,
  getListUserWalletSuccess,
  getListUserWalletError,
  getListUserWalletFilter,
} from "../../actions/getListUserWallet/action";
import { handleGetListUserWallet } from "src/apis/service/CryptoWallet";

function* getListUserWalletSaga(action:any): Generator<any> {
  yield put(getListUserWalletFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleGetListUserWallet(payload);
    if (data && data?.status === 200) {
      yield put(getListUserWalletSuccess(data));
    } else {
      yield put(getListUserWalletError("error"));
    }
  } catch (error) {
    yield put(getListUserWalletError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getListUserWalletFilter(action.payload));
}
export function* watchGetListUserWallet() {
  yield takeEvery(
    GetListUserWalletTypes.GET_LIST_USER_WALLET,
    getListUserWalletSaga
  );
  yield debounce(
    500,
    GetListUserWalletTypes.GET_LIST_USER_WALLET_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
