import { ListParams } from "src/models/common";

export interface IGetListAdminRolesState {
    data: any;
    isFetching: any,
    filter: ListParams,
    pagination: any,
}

export const initialGetListAdminRolesState: IGetListAdminRolesState = {
    data: [],
    isFetching: -1,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


