import { put, takeEvery, debounce } from "redux-saga/effects";
import { CreateCryptoExchangeOffersListTypes } from "../../actions/createCryptoExchangeOffersList";
import {
  createCryptoExchangeOffersListFetching,
  createCryptoExchangeOffersListSuccess,
  createCryptoExchangeOffersListError,
  createCryptoExchangeOffersListFilter,
} from "../../actions/createCryptoExchangeOffersList/action";
import { handleCreateCryptoExchangeOffersList } from "src/apis/service/Agent";
import { toastError, toastSuccess } from "src/helpers/toastHelper";

function* createCryptoExchangeOffersListSaga(action: any): Generator<any> {
  yield put(createCryptoExchangeOffersListFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleCreateCryptoExchangeOffersList(payload);
    if (data && data?.status === 201) {
      toastSuccess("bo_success");
      yield put(createCryptoExchangeOffersListSuccess(data));
    } else if (data && data?.status === 404 && data?.message) {
      toastError(data?.message);
      yield put(createCryptoExchangeOffersListError("error"));
    } else {
      toastError("bo_failed");
      yield put(createCryptoExchangeOffersListError("error"));
    }
  } catch (error: any) {
    if (error?.response?.data?.message !== "") {
      toastError(error?.response?.data?.message);
    } else {
      toastError("bo_failed");
    }
    yield put(createCryptoExchangeOffersListError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(createCryptoExchangeOffersListFilter(action.payload));
}
export function* watchCreateCryptoExchangeOffersList() {
  yield takeEvery(
    CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST,
    createCryptoExchangeOffersListSaga
  );
  yield debounce(
    500,
    CreateCryptoExchangeOffersListTypes.CREATE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
