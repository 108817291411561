import {put, takeEvery,debounce} from 'redux-saga/effects';
import {GetOfferHistoryTypes} from '../../actions/getOfferHistory';
import {getOfferHistoryFetching,getOfferHistorySuccess,getOfferHistoryError, getOfferHistoryFilter} from '../../actions/getOfferHistory/action';
import { getOfferHistories } from 'src/apis/service/CryptoExchange';

function* getOfferHistorySaga(action:any):Generator<any> {
    yield put(getOfferHistoryFetching(true))
    const {payload} = action;
    try {
      const data:any = yield getOfferHistories(payload)
      if(data && data?.status === 200){
        yield put(getOfferHistorySuccess(data))
      } else {
        yield put(getOfferHistoryError('error'))
      }
    } catch (error) {
        yield put(getOfferHistoryError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(getOfferHistoryFilter(action.payload));
}
export function* watchGetOfferHistory() {
   yield takeEvery(GetOfferHistoryTypes.GET_OFFER_HISTORY, getOfferHistorySaga)
   yield debounce(500,GetOfferHistoryTypes.GET_OFFER_HISTORY_FILTER_DEBOUNCE,handleSearchDebounce)
}
