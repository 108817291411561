export interface RemoveOfferSellTransaction  {
    status: number,
    data:any,
    message:string
}

export interface RemoveOfferSellTransactionState {
    data: RemoveOfferSellTransaction,
    isFetching:boolean
}

export const initialRemoveOfferSellTransactionState: RemoveOfferSellTransactionState = {
    data:{
        data:{},
        message:'',
        status:0
    },
    isFetching:false
}