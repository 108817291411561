import {put, takeEvery,debounce} from 'redux-saga/effects';
import {UpdateOfferNoteTypes} from '../../actions/updateOfferNote';
import {updateOfferNoteFetching,updateOfferNoteSuccess,updateOfferNoteError, updateOfferNoteFilter} from '../../actions/updateOfferNote/action';
import { updateOfferNote } from 'src/apis/service/CryptoExchange';

function* updateOfferNoteSaga(action:any):Generator<any> {
    yield put(updateOfferNoteFetching(true))
    const {payload} = action;
    try {
      const data:any = yield updateOfferNote(payload)
      if(data && data?.status === 200){
        yield put(updateOfferNoteSuccess(data))
      } else {
        yield put(updateOfferNoteError('error'))
      }
    } catch (error) {
        yield put(updateOfferNoteError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(updateOfferNoteFilter(action.payload));
}
export function* watchUpdateOfferNote() {
   yield takeEvery(UpdateOfferNoteTypes.UPDATE_OFFER_NOTE, updateOfferNoteSaga)
   yield debounce(500,UpdateOfferNoteTypes.UPDATE_OFFER_NOTE_FILTER_DEBOUNCE,handleSearchDebounce)
}
