export const LIST_SCORE_CONFIG_TYPES = [
  {
    id: 1,
    label: 'bo_amount_sell',
    value: 'TYPE_TRANS_AMOUNT_SELL'
  },
  {
    id: 2,
    label: 'bo_amount_buy',
    value: 'TYPE_TRANS_AMOUNT_BUY'
  },
  {
    id: 3,
    label: 'bo_transaction_number',
    value: 'TYPE_TRANS_NUMBER'
  },
  {
    id: 4,
    label: 'bo_timeout_or_cancel_transaction',
    value: 'TYPE_TRANS_CANCEL_TIMEOUT'
  }
];

export const TYPE_TRANS_AMOUNT_SELL =  'TYPE_TRANS_AMOUNT_SELL';
export const TYPE_TRANS_AMOUNT_BUY =  'TYPE_TRANS_AMOUNT_BUY';
export const TYPE_TRANS_NUMBER =  'TYPE_TRANS_NUMBER';
export const TYPE_TRANS_CANCEL_TIMEOUT =  'TYPE_TRANS_CANCEL_TIMEOUT';
