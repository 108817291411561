
interface IResendFeaturedNotification {
    data:any;
    message: string;
    status:number
  }
  
  export interface IResendFeaturedNotificationState {
      data: IResendFeaturedNotification;
      isFetching: boolean,
  }
  
  export const initialResendFeaturedNotificationState: IResendFeaturedNotificationState = {
     data:{
        data:false,
        message:'',
        status:0
     },
     isFetching:false
  }