import {AnyAction, Reducer} from 'redux';
import {initialCryptoWalletDashboardState} from './state';
import {CryptWalletDashboardActionTypes} from "../../actions/cryptoWalletDashboard";

export const cryptoWalletDashboardReducer: Reducer = (
    state = initialCryptoWalletDashboardState,
    action: AnyAction,
) => {
    switch (action.type) {
        case CryptWalletDashboardActionTypes.CRYPTO_WALLET_DASHBOARD_IS_FETCHING : {
            return {
                ...state,
                isFetching: !!action?.isFetching,
            };
        }
        case CryptWalletDashboardActionTypes.CRYPTO_WALLET_DASHBOARD_ERROR : {
            return {
                ...state,
                error: action.error,
                isFetching: false,
            };
        }
        case CryptWalletDashboardActionTypes.CRYPTO_WALLET_DASHBOARD_SUCCESS : {
            return {
                ...state,
                requestData: action?.data,
                isFetching: false,
            }
        }
        default:
            return state;
    }
};
