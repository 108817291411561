export interface UpdateExchangeOffer  {
    data:any,
    errors:any,
    success:any
}

export interface UpdateExchangeOfferState {
    data: UpdateExchangeOffer,
    isFetching:boolean,
    countSuccess: number
}

export const initialUpdateExchangeOfferState : UpdateExchangeOfferState = {
    data:{
        data:{},
        errors:'',
        success:false
    },
    isFetching:false,
    countSuccess: 0
}