
// get transaction

import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { ParamsDefault } from "src/contants/paramsDefault";
import { ListParams } from "src/models/common";

interface IDataItemKycListHistory {
  data:{
      items:any[];
      totalCount:number;
  };
  status:number;
  message:string;
}

export interface IListKycListHistoryState {
    data: IDataItemKycListHistory;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
}

export const initialListKycListHistoryState: IListKycListHistoryState = {
    data:{
        data:{
            items:[],
            totalCount: 0
        },
        message: '',
        status: 0
    },
    isFetching:false,
    filter:{
        pageNumber:1,
        pageSize:PAGE_SIZE_DEFAULT,
        kycId:''
    },
    pagination: {
        totalCount: 0,
    }
}

