import { toastError, toastSuccess } from './../../../helpers/toastHelper';
import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleCreateRankPeriodConfig } from 'src/apis/service/Setting';
import { CreateRankPeriodConfigTypes } from '../../actions/createRankPeriodConfig';
import {
  createRankPeriodConfigError, createRankPeriodConfigFetching, createRankPeriodConfigFilter, createRankPeriodConfigSuccess
} from '../../actions/createRankPeriodConfig/action';

function* createRankPeriodConfigSaga(action: any): Generator<any> {


  yield put(createRankPeriodConfigFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleCreateRankPeriodConfig(payload);
    if (data && data?.status === 200) {
      yield put(createRankPeriodConfigSuccess(data));
      toastSuccess('bo_update_rank_period_config_success');
    } else {
      // yield put(createRankPeriodConfigError('error'));
      toastError('bo_you_can_t_rank_period_config');
    }
  } catch (error) {
    yield put(createRankPeriodConfigError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(createRankPeriodConfigFilter(action.payload));
}
export function* watchCreateRankPeriodConfig() {
  yield takeEvery(
    CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG,
    createRankPeriodConfigSaga
  );
  yield debounce(
    500,
    CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
