import { RemoveOfferAllType } from './actionTypes';
import { IFetchingRemoveOfferAll,IRemoveOfferAll,IRemoveOfferAllError,IRemoveOfferAllSuccess } from './interfaces';


export const removeOfferAllIsFetching = (isFetching:boolean) : IFetchingRemoveOfferAll => ({
    type:RemoveOfferAllType.REMOVE_OFFER_ALL_FETCHING,
    isFetching
})

export const removeOfferAll = (id: number) : IRemoveOfferAll => ({
    type:RemoveOfferAllType.REMOVE_OFFER_ALL,
    id
})

export const removeOfferAllSuccess = (data:any) : IRemoveOfferAllSuccess => ({
    type:RemoveOfferAllType.REMOVE_OFFER_ALL_SUCCESS,
    data
})

export const removeOfferAllFaild = (error:any) : IRemoveOfferAllError => ({
    type:RemoveOfferAllType.REMOVE_OFFER_ALL_ERROR,
    error
})