import * as Sentry from "@sentry/react";
import Request from "src/apis/config";
import { ListParams } from "src/models/common";

const requestApi = new Request({url: process.env.REACT_APP_BASE_URL_TRANSFER});

const getDirectTransfers = async (data: ListParams) : Promise<any> => {
  const url = `/Wallet/GetDirectTransfers`;
  try {
    return await requestApi.post(url, data)
  } catch (error) {
    Sentry.captureException(error);
    throw error
  }
}

export { getDirectTransfers };
