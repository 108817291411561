import { ListParams } from "src/models/common";
import { IWalletBalanceObject } from "../CryptoWalletGetNewOverview";

interface IDataTransactionOverview {
  data?: IWalletBalanceObject[];
  error: any;
  success: boolean;
}

export interface ITransactionOverviewState {
  data: IDataTransactionOverview;
  isFetching: boolean;
  filter: ListParams;
  pagination: any;
}

export const initialTransactionOverviewState: ITransactionOverviewState = {
  data: {
    data:[],
    error: null,
    success: false,
  },
  isFetching: false,
  filter: {},
  pagination: {
    totalCount: 0,
  },
};
