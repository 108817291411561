import { ActiveScoreKPITypes } from './actionTypes';
import {
  IActiveScoreKPI,
  IActiveScoreKPIFetching,
  IActiveScoreKPISuccess,
  IActiveScoreKPIError,
  IActiveScoreKPIFilter,
  IActiveScoreKPIFiterDebounce
} from './interfaces';

export const activeScoreKPI = (
  payload: Object,
  history?: any
): IActiveScoreKPI => ({
  type: ActiveScoreKPITypes.ACTIVE_SCORE_KPI,
  payload,
  history
});

export const activeScoreKPIFetching = (
  isFetching: boolean
): IActiveScoreKPIFetching => ({
  type: ActiveScoreKPITypes.ACTIVE_SCORE_KPI_FETCHING,
  isFetching
});

export const activeScoreKPISuccess = (data: any): IActiveScoreKPISuccess => ({
  type: ActiveScoreKPITypes.ACTIVE_SCORE_KPI_SUCCESS,
  data
});

export const activeScoreKPIError = (errorMsg: any): IActiveScoreKPIError => ({
  type: ActiveScoreKPITypes.ACTIVE_SCORE_KPI_FAILD,
  error: errorMsg
});

export const activeScoreKPIFilter = (payload: any): IActiveScoreKPIFilter => ({
  type: ActiveScoreKPITypes.ACTIVE_SCORE_KPI_FILTER,
  payload
});

export const activeScoreKPIFilterDebounce = (
  payload: any
): IActiveScoreKPIFiterDebounce => ({
  type: ActiveScoreKPITypes.ACTIVE_SCORE_KPI_FILTER_DEBOUNCE,
  payload
});
