import { ListParams } from '../../../models/common';

interface IDataListAgencyStatus {
  data: {
      items: any[];
      total: number;
  };
  error: any;
  success: boolean;
}

export interface IListAgencyStatusState {
    data: IDataListAgencyStatus;
    isFetching: boolean,
    filter: object
}

export const initialListAgencyStatusState: IListAgencyStatusState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        pageSize: 1000,
    },
}

