import { put, takeLatest } from 'redux-saga/effects';
import { handleUpdateMainAnnouncement } from 'src/apis/service/Announcements';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getAnnouncements } from 'src/store/actions';
import { SetMainPostAnnouncementsFaild, SetMainPostAnnouncementsIsFetching, SetMainPostAnnouncementsSuccess, SetMainPostAnnouncementsType } from 'src/store/actions/Announcement/Announcements/SetMainPost';

function* setMainPostAnnouncementsSaga(action: any): Generator<any> {


    yield put(SetMainPostAnnouncementsIsFetching(true))
    const { payload } = action;
    try {
      const data: any = yield handleUpdateMainAnnouncement(payload) 
      if (data?.status === 200) {
        toastSuccess('bo_change_main_annoucement_success')
        // yield put(getAnnouncements({
        //   pageSize: 10,
        //   pageNumber: 1
        // }))
        yield put(SetMainPostAnnouncementsSuccess(data))
      } else {
        toastError('bo_change_main_announcement_faild')
        yield put(SetMainPostAnnouncementsFaild('error'))
      }
    } catch (error) {
        yield put(SetMainPostAnnouncementsFaild(error))
    }
}

export function* watchSetMainPostAnnouncements() {
   yield takeLatest(SetMainPostAnnouncementsType.SET_MAIN_POST_ANNOUNCEMENTS , setMainPostAnnouncementsSaga)
}