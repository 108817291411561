import { AnyAction, Reducer } from 'redux';
import { initialExportCryptoSendTransactionState, ExportCryptoSendTransactionState } from './state';
import { ExportCryptoSendTransactionType } from '../../actions/ExportCryptoSendTransaction/actionTypes';


export const ExportCryptoSendTransactionReducer: Reducer = (
  state: ExportCryptoSendTransactionState = initialExportCryptoSendTransactionState,
  action: AnyAction
) => {
  switch (action.type) {
    case ExportCryptoSendTransactionType.EXPORT_CRYPTO_SEND_TRANSACTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ExportCryptoSendTransactionType.EXPORT_CRYPTO_SEND_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case ExportCryptoSendTransactionType.EXPORT_CRYPTO_SEND_TRANSACTION_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
