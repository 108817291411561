import { AnyAction, Reducer } from 'redux';
import { ICreateScoreKPIState, initialCreateScoreKPIState } from './state';
import { CreateScoreKPITypes } from 'src/store/actions/createScoreKPI';

export const CreateScoreKPIReducer: Reducer = (
  state: ICreateScoreKPIState = initialCreateScoreKPIState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateScoreKPITypes.CREATE_SCORE_KPI: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateScoreKPITypes.CREATE_SCORE_KPI_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case CreateScoreKPITypes.CREATE_SCORE_KPI_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case CreateScoreKPITypes.CREATE_SCORE_KPI_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
