import { TransactionManualPaymentType } from './actionTypes';
import {
  IFetchingTransactionManualPaymentHistory,
  ITransactionManualPaymentError,
  ITransactionManualPaymentHistory,
  ITransactionManualPaymentHistoryBuySuccess,
  ITransactionManualPaymentHistorySellSuccess
} from './interfaces';

export const transactionManualPaymentHistoryIsFetching = (
  isFetching: boolean
): IFetchingTransactionManualPaymentHistory => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY_FETCHING,
  isFetching,
});

export const transactionManualPaymentHistory = (payload: number[], transactionType:string): ITransactionManualPaymentHistory => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY,
  payload,
  transactionType
});

export const transactionManualPaymentBuySuccess = (data: any): ITransactionManualPaymentHistoryBuySuccess => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY_BUY_SUCCESS,
  data,
});

export const transactionManualPaymentSellSuccess = (data: any): ITransactionManualPaymentHistorySellSuccess => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY_SELL_SUCCESS,
  data,
});

export const transactionManualPaymentHistoryFaild = (error: any): ITransactionManualPaymentError => ({
  type: TransactionManualPaymentType.TRANSACTION_MANUALPAYMENT_HISTORY_ERROR,
  error,
});
