import { AnyAction, Reducer } from 'redux';
import { IDeleteCryptoExchangeOffersListState, initialDeleteCryptoExchangeOffersListState } from './state';
import { DeleteCryptoExchangeOffersListTypes } from 'src/store/actions/deleteCryptoExchangeOffersList';

export const DeleteCryptoExchangeOffersListReducer: Reducer = (
  state: IDeleteCryptoExchangeOffersListState = initialDeleteCryptoExchangeOffersListState,
  action: AnyAction
) => {
  switch (action.type) {
    case DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
