import {GetKycListByStageTypes} from './actionTypes';
import {
    IGetListFiterDebounceKycListByStage,
    IGetListFiterKycListByStage,
    IGetKycListByStage,
    IGetKycListByStageFaild,
    IGetKycListByStageFetching,
    IGetKycListByStageSuccess
} from './interfaces';

export const getKycListByStageAtFetching = (isFetching:boolean) : IGetKycListByStageFetching  => ({
    type: GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE_FETCHING,
    isFetching
})

export const getKycListByStage = (payload:Object) : IGetKycListByStage => ({
    type: GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE,
    payload
})

export const getKycListByStageSuccess = (data:any) : IGetKycListByStageSuccess => ({
    type: GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE_SUCCESS,
    data
})

export const getKycListByStageFaild = (error:any) : IGetKycListByStageFaild => ({
    type: GetKycListByStageTypes.GET_KYC_LIST_BY_STAGE_FAILD,
    error
})

export const setFilterKycListByStage = (payload: any): IGetListFiterKycListByStage => ({
    type: GetKycListByStageTypes.GET_LIST_FILTER_KYC_LIST_BY_STAGE,
    payload
})

export const setFilterDebounceKycListByStage = (payload: any): IGetListFiterDebounceKycListByStage => ({
    type: GetKycListByStageTypes.GET_LIST_FILTER_DEBOUNCE_KYC_LIST_BY_STAGE,
    payload 
})