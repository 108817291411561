import { GetAllScorePeriodConfigTypes } from './actionTypes';
import { IGetAllScorePeriodConfig, IGetAllScorePeriodConfigFetching, IGetAllScorePeriodConfigSuccess, IGetAllScorePeriodConfigError, IGetAllScorePeriodConfigFilter, IGetAllScorePeriodConfigFiterDebounce, IClearAllScorePeriodConfig } from "./interfaces";


export const getAllScorePeriodConfig = (): IGetAllScorePeriodConfig => ({
    type: GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG
})

export const getAllScorePeriodConfigFetching = (isFetching: boolean): IGetAllScorePeriodConfigFetching => ({
    type: GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_FETCHING,
    isFetching
})

export const getAllScorePeriodConfigSuccess = (data: any): IGetAllScorePeriodConfigSuccess => ({
    type: GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_SUCCESS,
    data
})

export const getAllScorePeriodConfigError = (errorMsg: any): IGetAllScorePeriodConfigError => ({
    type: GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_FAILD,
    error: errorMsg
})

export const getAllScorePeriodConfigFilter = (payload: any): IGetAllScorePeriodConfigFilter => ({
    type: GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_FILTER,
    payload
})

export const getAllScorePeriodConfigFilterDebounce = (payload: any): IGetAllScorePeriodConfigFiterDebounce => ({
    type: GetAllScorePeriodConfigTypes.GET_ALL_SCORE_PERIOD_CONFIG_FILTER_DEBOUNCE,
    payload
})

export const clearScorePeriodConfig = (): IClearAllScorePeriodConfig => ({
    type: GetAllScorePeriodConfigTypes.CLEAR_ALL_SCORE_PERIOD_CONFIG
})

