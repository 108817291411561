import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleSetUserLanguage } from '../../../apis/service/user';
import { SetUserLanguageTypes } from '../../actions/setUserLanguage';
import {
  setUserLanguageError,
  setUserLanguageFetching,
  setUserLanguageFilter,
  setUserLanguageSuccess,
} from '../../actions/setUserLanguage/action';

function* setUserLanguageSaga(action: any): Generator<any> {
  yield put(setUserLanguageFetching(true));
  const { payload } = action;
  try {
    const data: any = yield handleSetUserLanguage(payload);
    if (data && data?.status === 200) {
      yield put(setUserLanguageSuccess(data));
    } else {
      yield put(setUserLanguageError('error'));
    }
  } catch (error) {
    yield put(setUserLanguageError(error));
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setUserLanguageFilter(action.payload));
}
export function* watchSetUserLanguage() {
  yield takeEvery(SetUserLanguageTypes.SET_USER_LANGUAGE, setUserLanguageSaga);
  yield debounce(
    500,
    SetUserLanguageTypes.SET_USER_LANGUAGE_FILTER_DEBOUNCE,
    handleSearchDebounce
  );
}
