import { put, takeEvery } from 'redux-saga/effects';
import { HandleNotiRefreshDataTypes } from '../../actions/handleNotiRefreshData';
import { handleNotiRefreshDataSuccess } from '../../actions/handleNotiRefreshData/action';

function* handleNotiRefreshDataSaga(action: any): Generator<any> {
  yield put(handleNotiRefreshDataSuccess(action?.payload))
}

export function* watchHandleNotiRefreshData() {
  yield takeEvery(HandleNotiRefreshDataTypes.HANDLE_NOTI_REFRESH_DATA, handleNotiRefreshDataSaga)
}
