export const PAGE_SIZE = [
    {
        id:1,
        value:10,
        label:'10'
    },
    {
        id:2,
        value:20,
        label:'20'
    },
    {
        id:3,
        value:50,
        label:'50'
    },
    {
        id:4,
        value:100,
        label:'100'
    }
]

export const PAGE_SIZE_5 = [
    {
        id:1,
        value:5,
        label:'5'
    },
    {
        id:2,
        value:10,
        label:'10'
    },
    {
        id:3,
        value:20,
        label:'20'
    },
    {
        id:4,
        value:50,
        label:'50'
    },
    {
        id:5,
        value:100,
        label:'100'
    }
]

export const PAGE_SIZE_DEFAULT = 100000000