

interface IDataItemKycListHistoryNote {
  data:{
      items:any[];
      totalCount:number;
  };
  status:number;
  message:string;
}

export interface IListKycListHistoryNoteState {
    data: IDataItemKycListHistoryNote;
    isFetching: boolean,
    filter: Object,
    pagination: any,
}

export const initialListKycListHistoryNoteState: IListKycListHistoryNoteState = {
    data:{
        data:{
            items:[],
            totalCount: 0
        },
        message: '',
        status: 0
    },
    isFetching:false,
    filter: {
        pageSize:10,
        pageNumber:1
    },
    pagination: {
        totalCount: 0,
    }
}

