import { AnyAction, Reducer } from 'redux';
import { IGetTransactionsFilterStatusesState, initialGetTransactionsFilterStatusesState } from './state';
import { GetTransactionsFilterStatusesTypes } from 'src/store/actions/getTransactionsFilterStatuses';

export const GetTransactionsFilterStatusesReducer: Reducer = (
  state: IGetTransactionsFilterStatusesState = initialGetTransactionsFilterStatusesState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetTransactionsFilterStatusesTypes.GET_TRANSACTIONS_FILTER_STATUSES_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
