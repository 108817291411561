import { HandleNotiRefreshDataTypes } from './actionTypes';
import { IHandleNotiRefreshData, IHandleNotiRefreshDataSuccess } from "./interfaces";


export const handleNotiRefreshData = (payload:Object): IHandleNotiRefreshData => ({
    type: HandleNotiRefreshDataTypes.HANDLE_NOTI_REFRESH_DATA,
    payload
})

export const handleNotiRefreshDataSuccess = (data:any) : IHandleNotiRefreshDataSuccess => ({
   type: HandleNotiRefreshDataTypes.HANDLE_NOTI_REFRESH_DATA_SUCCESS,
   data
})
