import {put, takeEvery,debounce} from 'redux-saga/effects';
import {ApplyKYCDocsTypes} from '../../actions/applyKYCDocs';
import {applyKYCDocsFetching,applyKYCDocsSuccess,applyKYCDocsError, applyKYCDocsFilter} from '../../actions/applyKYCDocs/action';
import {handleApplyKYCDocs, handleGetListUser} from '../../../apis/service/user';
import { getAllKYC } from 'src/store/actions/getAllKYC';

function* applyKYCDocsSaga(action:any):Generator<any> {
    yield put(applyKYCDocsFetching(true))
    const {payload} = action;
    try {
      const data:any = yield handleApplyKYCDocs(payload)
      if(data && data?.status === 200){
        yield put(getAllKYC())
        yield put(applyKYCDocsSuccess(data))
      } else {
        yield put(applyKYCDocsError('error'))
      }
    } catch (error) {
        yield put(applyKYCDocsError(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(applyKYCDocsFilter(action.payload));
}
export function* watchApplyKYCDocs() {
   yield takeEvery(ApplyKYCDocsTypes.APPLYKYC_DOCS, applyKYCDocsSaga)
   yield debounce(500,ApplyKYCDocsTypes.APPLYKYC_DOCS_FILTER_DEBOUNCE,handleSearchDebounce)
}
