
interface ISetUnPostAndPostAnnouncements {
    data:any;
    message: string;
    status:number
  }
  
  export interface IUnPostAndPostAnnouncementsState {
      data: ISetUnPostAndPostAnnouncements;
      isFetching: boolean,
  }
  
  export const initialUnPostAndPostAnnouncementsState: IUnPostAndPostAnnouncementsState = {
     data:{
        data:false,
        message:'',
        status:0
     },
     isFetching:false
  }