import { AnyAction, Reducer } from 'redux';
import { GetCryptoWalletGetOverviewTransactionCryptoType } from 'src/store/actions';
import { IListCryptoWalletGetOverviewTransactionCryptoState, initialListCryptoWalletGetOverviewTransactionCryptoState } from '.';


export const GetListCryptoWalletGetOverviewTransactionCryptoReducer: Reducer = (
  state: IListCryptoWalletGetOverviewTransactionCryptoState = initialListCryptoWalletGetOverviewTransactionCryptoState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case GetCryptoWalletGetOverviewTransactionCryptoType.GET_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO_SUCCESS : {
      return {
          ...state,
          data: action?.data.data,
          isFetching: false,
          pagination: action.data.data.totalCount
      }
    }
    case GetCryptoWalletGetOverviewTransactionCryptoType.GET_LIST_FILTER_CRYPTO_WALLET_GET_OVERVIEW_TRANSACTION_CRYPTO: {
      return {
          ...state,
          filter: action.payload
      }
    }
    default:
      return state;
  }
};