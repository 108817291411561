import {put, takeEvery, debounce} from 'redux-saga/effects';
import { getCurrenciesBO } from 'src/apis/service/Currencies';
import { 
   GetAllTestType,
   getAllTestIsFetching,
   getAllTestSuccess,
   getAllTestFaild,
   setFilterListTest
 } from 'src/store/actions/testAction';

function* getListTestSaga(action:any): Generator<any> {
    yield put(getAllTestIsFetching(true))
    const {payload} = action;
    try {
      const response : any = yield getCurrenciesBO(payload) 
      if(response.status === 200){
        yield put(getAllTestSuccess(response));
      }  
    } catch (error) {
        yield put(getAllTestFaild(error))
    }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(setFilterListTest(action.payload));
}
export function* watchGetListTest() {
   yield takeEvery(GetAllTestType.GET_ALL_TEST , getListTestSaga)
   yield debounce(500, GetAllTestType.GET_LIST_FILTER_DEBOUNCE_TEST, handleSearchDebounce)
}