import { AnyAction, Reducer } from 'redux';
import {
  initialCancelP2PExchangeOfferState,
  ICancelP2PExchangeOfferState
} from './state';
import { CancelP2PExchangeOffer } from '../../../actions/blockOrUnBlockAndCancelP2PExchangeOffer/cancelP2PExchangeOffer';

export const cancelP2PExchangeOfferReducer: Reducer = (
  state: ICancelP2PExchangeOfferState = initialCancelP2PExchangeOfferState,
  action: AnyAction
) => {
  switch (action.type) {
    case CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER: {
      return {
        ...state,
        isFetching: true
      };
    }
    case CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    }
    case CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case CancelP2PExchangeOffer.CANCEL_P2PEXCHANGE_OFFER_CLEAR: {
      return {
        ...initialCancelP2PExchangeOfferState
      };
    }
    default: {
      return state;
    }
  }
};