interface RemoveWalletAdminBO {
    data: any,
    status: number,
    message: string
}

export interface RemoveWalletAdminBOState {
    data: RemoveWalletAdminBO,
    isFetching: boolean,
    countSuccess: number
}

export const initialRemoveWalletAdminBOState: RemoveWalletAdminBOState = {
    data: {
        data: false,
        message: '',
        status: 0
    },
    isFetching: false,
    countSuccess: 0
}