import {ApplyKYCUserInfoTypes} from './actionTypes';
import { IApplyKYCUserInfo, IApplyKYCUserInfoFetching, IApplyKYCUserInfoSuccess, IApplyKYCUserInfoError, IApplyKYCUserInfoFilter, IApplyKYCUserInfoFiterDebounce } from "./interfaces";


export const applyKYCUserInfo = (payload:Object): IApplyKYCUserInfo => ({
    type: ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO,
    payload
})

export const applyKYCUserInfoFetching = (isFetching:boolean) : IApplyKYCUserInfoFetching => ({
    type: ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_FETCHING,
    isFetching
})

export const applyKYCUserInfoSuccess = (data:any) : IApplyKYCUserInfoSuccess => ({
   type: ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_SUCCESS,
   data
})

export const applyKYCUserInfoError = (errorMsg:any) : IApplyKYCUserInfoError => ({
    type: ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_FAILD,
    error:errorMsg
})

export const applyKYCUserInfoFilter = (payload: any): IApplyKYCUserInfoFilter => ({
    type: ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_FILTER,
    payload
})

export const applyKYCUserInfoFilterDebounce = (payload: any): IApplyKYCUserInfoFiterDebounce => ({
    type: ApplyKYCUserInfoTypes.APPLYKYC_USER_INFO_FILTER_DEBOUNCE,
    payload 
})
