import { ListParams } from "src/models/common";

interface IDataGetListWalletsForUser {
    data: any[];
    error: any;
    success: boolean;
}

export interface IGetListWalletsForUserState {
    data: IDataGetListWalletsForUser;
    isFetching: boolean,
    filter: ListParams,
    pagination: any,
}

export const initialGetListWalletsForUserState: IGetListWalletsForUserState = {
    data: {
        data: [],
        error: null,
        success: false
    },
    isFetching: false,
    filter: {},
    pagination: {
        totalCount: 0,
    }
}


