import { AnyAction, Reducer } from 'redux';
import { ITransactionAgentOverviewState, initialTransactionAgentOverviewState } from './state';
import { TransactionAgentOverviewTypes } from 'src/store/actions/transactionAgentOverview';

export const TransactionAgentOverviewReducer: Reducer = (
  state: ITransactionAgentOverviewState = initialTransactionAgentOverviewState,
  action: AnyAction
) => {
  switch (action.type) {
    case TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case TransactionAgentOverviewTypes.TRANSACTION_AGENT_OVERVIEW_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
