import { AnyAction, Reducer } from 'redux';
import { GetNotificationInFirebaseType } from 'src/store/actions/notificationFromFirebase/getAllNotification/actionTypes';
import { IListNotificationFirebaseState, initialListNotificationFirebaseState } from './state';
import { get } from 'lodash';


export const GetListNotificationsFirebaseReducer: Reducer = (
  state: IListNotificationFirebaseState = initialListNotificationFirebaseState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE_FAILD: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    case GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
        pagination: get(action, 'data.data.unreadCount', 0)
      }
    }
    case GetNotificationInFirebaseType.GET_NOTIFICATION_FIREBASE_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetNotificationInFirebaseType.SAVE_NEW_NOTIFICATION_FIREBASE: {
      const listNewNotification = [...state.newNotifications, action.payload]
      return {
        ...state,
        newNotifications: listNewNotification
      }
    }
    case GetNotificationInFirebaseType.RESET_LIST_NEW_NOTIFICATION_FIREBASE: {
      return {
        ...state,
        newNotifications: []
      }
    }
    default:
      return state;
  }
};