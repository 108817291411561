import { GetP2PExchangeOffersType } from './actionTypes';
import { IFetchingGetP2PExchangeOffers, IGetListFilterDebounceP2PExchangeOffers, IGetListFilterP2PExchangeOffers, IGetP2PExchangeOffers, IGetP2PExchangeOffersError, IGetP2PExchangeOffersSuccess } from './interfaces';

export const getP2PExchangeOffersIsFetching = (isFetching:boolean) : IFetchingGetP2PExchangeOffers => ({
    type: GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS_FETCHING,
    isFetching
})

export const getP2PExchangeOffers = (payload: object) : IGetP2PExchangeOffers => ({
    type: GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS,
    payload
})

export const getP2PExchangeOffersSuccess = (data:any) : IGetP2PExchangeOffersSuccess => ({
    type: GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS_SUCCESS,
    data
})

export const getP2PExchangeOffersFaild = (error:any) : IGetP2PExchangeOffersError => ({
    type: GetP2PExchangeOffersType.GET_P2P_EXCHANGE_OFFERS_ERROR,
    error
})

export const setFilterP2PExchangeOffers = (payload: any): IGetListFilterP2PExchangeOffers => ({
    type: GetP2PExchangeOffersType.SET_LIST_FILTER_P2P_EXCHANGE_OFFERS,
    payload
})

export const setFilterDebounceP2PExchangeOffers = (payload: any): IGetListFilterDebounceP2PExchangeOffers => ({
    type: GetP2PExchangeOffersType.SET_LIST_FILTER_DEBOUNCE_P2P_EXCHANGE_OFFERS,
    payload 
})