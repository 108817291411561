import {SelectWalletTypeTypes} from './actionTypes';
import { ISelectWalletType, ISelectWalletTypeFetching, ISelectWalletTypeSuccess, ISelectWalletTypeError, ISelectWalletTypeFilter, ISelectWalletTypeFiterDebounce } from "./interfaces";


export const selectWalletType = (payload:any): ISelectWalletType => ({
    type: SelectWalletTypeTypes.SELECT_WALLET_TYPE,
    payload
})

export const selectWalletTypeFetching = (isFetching:boolean) : ISelectWalletTypeFetching => ({
    type: SelectWalletTypeTypes.SELECT_WALLET_TYPE_FETCHING,
    isFetching
})

export const selectWalletTypeSuccess = (data:any) : ISelectWalletTypeSuccess => ({
   type: SelectWalletTypeTypes.SELECT_WALLET_TYPE_SUCCESS,
   data
})

export const selectWalletTypeError = (errorMsg:any) : ISelectWalletTypeError => ({
    type: SelectWalletTypeTypes.SELECT_WALLET_TYPE_FAILD,
    error:errorMsg
})

export const selectWalletTypeFilter = (payload: any): ISelectWalletTypeFilter => ({
    type: SelectWalletTypeTypes.SELECT_WALLET_TYPE_FILTER,
    payload
})

export const selectWalletTypeFilterDebounce = (payload: any): ISelectWalletTypeFiterDebounce => ({
    type: SelectWalletTypeTypes.SELECT_WALLET_TYPE_FILTER_DEBOUNCE,
    payload 
})
