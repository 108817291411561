import { AnyAction, Reducer } from 'redux';
import { ICreateRankPeriodConfigState, initialCreateRankPeriodConfigState } from './state';
import { CreateRankPeriodConfigTypes } from 'src/store/actions/createRankPeriodConfig';

export const CreateRankPeriodConfigReducer: Reducer = (
  state: ICreateRankPeriodConfigState = initialCreateRankPeriodConfigState,
  action: AnyAction
) => {
  switch (action.type) {
    case CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        successCount: state.successCount + 1
      };
    }
    case CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case CreateRankPeriodConfigTypes.CREATE_RANK_PERIOD_CONFIG_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
