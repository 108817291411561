import {put, takeEvery} from 'redux-saga/effects';
import {ChoosePriorityType} from '../../actions/choosePriority/actionTypes';
import {choosePriorityIsFetching, choosePrioritySuccess, choosePriorityFaild} from '../../actions/choosePriority/action';
import {handleChoosePriority} from '../../../apis/service/user';
function* choosePrioritySaga(action:any):Generator<any>{
  yield put(choosePriorityIsFetching(true))
  const {payload} = action;
  try {
    const choosePriority: any = yield handleChoosePriority(payload)  
    if (choosePriority) {
        yield put(choosePrioritySuccess(choosePriority))
    }
  } catch (error) {
     yield put(choosePriorityFaild(error)) 
  }
}
export function* watchChoosePriority(){
    yield takeEvery(ChoosePriorityType.CHOOSE_PRIORITY, choosePrioritySaga)
}