import { AnyAction, Reducer } from 'redux';
import { UpdateKycStageType } from 'src/store/actions';
import { IListKycStageUpdateState, initialListKycStageUpdateState } from './state';

export const GetListKycStageUpdateReducer: Reducer = (
  state: IListKycStageUpdateState = initialListKycStageUpdateState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UpdateKycStageType.UPDATE_KYC_STAGE : {
      return {
        ...state,
        isFetching: true,
      };
    }
    case UpdateKycStageType.UPDATE_KYC_STAGE_ERROR : {
      return {
        ...state,
        error: action.error,
        isFetching:false
      };
    }
    case UpdateKycStageType.UPDATE_KYC_STAGE_SUCCESS : {
      return {
          ...state,
          data: action?.data,
          isFetching: false,
          success: true
      }
    }
    case UpdateKycStageType.UPDATE_KYC_STAGE_RESET : {
      return {
          ...state,
          success: false
      }
    }
    default:
      return state;
  }
};