import {SetUserKYCPriorityTypes} from './actionTypes';
import { ISetUserKYCPriority, ISetUserKYCPriorityFetching, ISetUserKYCPrioritySuccess, ISetUserKYCPriorityError, ISetUserKYCPriorityFilter, ISetUserKYCPriorityFiterDebounce } from "./interfaces";


export const setUserKYCPriority = (payload:Object): ISetUserKYCPriority => ({
    type: SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY,
    payload
})

export const setUserKYCPriorityFetching = (isFetching:boolean) : ISetUserKYCPriorityFetching => ({
    type: SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_FETCHING,
    isFetching
})

export const setUserKYCPrioritySuccess = (data:any) : ISetUserKYCPrioritySuccess => ({
   type: SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_SUCCESS,
   data
})

export const setUserKYCPriorityError = (errorMsg:any) : ISetUserKYCPriorityError => ({
    type: SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_FAILD,
    error:errorMsg
})

export const setUserKYCPriorityFilter = (payload: any): ISetUserKYCPriorityFilter => ({
    type: SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_FILTER,
    payload
})

export const setUserKYCPriorityFilterDebounce = (payload: any): ISetUserKYCPriorityFiterDebounce => ({
    type: SetUserKYCPriorityTypes.SET_USER_KYC_PRIORITY_FILTER_DEBOUNCE,
    payload 
})
