import { put, takeEvery, debounce } from 'redux-saga/effects';
import { DeleteCryptoExchangeOffersListTypes } from '../../actions/deleteCryptoExchangeOffersList';
import { deleteCryptoExchangeOffersListFetching, deleteCryptoExchangeOffersListSuccess, deleteCryptoExchangeOffersListError, deleteCryptoExchangeOffersListFilter } from '../../actions/deleteCryptoExchangeOffersList/action';
import { handleDeleteCryptoExchangeOffersList } from 'src/apis/service/Agent';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* deleteCryptoExchangeOffersListSaga(action: any): Generator<any> {
  yield put(deleteCryptoExchangeOffersListFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleDeleteCryptoExchangeOffersList(payload)
    if (data && data?.status === 200) {
      toastSuccess('bo_success')
      yield put(deleteCryptoExchangeOffersListSuccess(data))
    } else {
      toastError('bo_failed')
      yield put(deleteCryptoExchangeOffersListError('error'))
    }
  } catch (error) {
    toastError('bo_failed')
    yield put(deleteCryptoExchangeOffersListError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(deleteCryptoExchangeOffersListFilter(action.payload));
}
export function* watchDeleteCryptoExchangeOffersList() {
  yield takeEvery(DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST, deleteCryptoExchangeOffersListSaga)
  yield debounce(500, DeleteCryptoExchangeOffersListTypes.DELETE_CRYPTO_EXCHANGE_OFFERS_LIST_FILTER_DEBOUNCE, handleSearchDebounce)
}
