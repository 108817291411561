
import {put, takeEvery, select} from 'redux-saga/effects';
import { deleteCurrencyBO } from 'src/apis/service/Currencies';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { ListParams } from 'src/models/common';
import { 
   DeleteCurrencyType,
   deleteCurrencyIsFetching,
   deleteCurrencySuccess,
   deleteCurrencyFaild
 } from 'src/store/actions/currency';
import { getAllCurrencyBO } from 'src/store/actions/currency/getAllCurrency';
import { IApplicationState } from 'src/store/IApplicationState';

function* deleteCurrencySaga(action:any): Generator<any> {


    yield put(deleteCurrencyIsFetching(true))
    const {payload} = action
    try {
      const response:any = yield deleteCurrencyBO(payload)      
      if(response.status === 200){
       const filter : any = yield select((state:IApplicationState) => state.listCurrencyBO.filter)
        yield put(deleteCurrencySuccess(response.data));
        // yield put(getAllCurrencyBO(filter))
        toastSuccess('bo_deactive_currency_successfully')
      } else {
        toastError('bo_you_can_t_deactive_currency')
      } 
    } catch (error) {
        yield put(deleteCurrencyFaild(error))
        toastError('bo_you_can_t_deactive_user')
    }
}

export function* watchDeleteCurrency() {
   yield takeEvery(DeleteCurrencyType.DELETE_CURRENCY, deleteCurrencySaga)
}