import { AnyAction, Reducer } from 'redux';
import { IGenerateCode2FAState, initialGenerateCode2FAState } from './state';
import { GenerateCode2FATypes } from 'src/store/actions/generateCode2FA';

export const GenerateCode2FAReducer: Reducer = (
  state: IGenerateCode2FAState = initialGenerateCode2FAState,
  action: AnyAction
) => {
  switch (action.type) {
    case GenerateCode2FATypes.GENERATE_CODE_2FA: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GenerateCode2FATypes.GENERATE_CODE_2FA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case GenerateCode2FATypes.GENERATE_CODE_2FA_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GenerateCode2FATypes.GENERATE_CODE_2FA_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
