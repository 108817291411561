import { AnyAction, Reducer } from 'redux';
import { RemoveUserBankAccState, initialRemoveUserBankAccState } from './state';
import { RemoveUserBankAccType } from '../../actions/removeUserBankAccount/actionTypes';

export const RemoveUserBankAccReducer: Reducer = (
  state: RemoveUserBankAccState = initialRemoveUserBankAccState,
  action: AnyAction
) => {
  switch (action.type) {
    case RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case RemoveUserBankAccType.REMOVE_USER_BANK_ACCOUNT_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
