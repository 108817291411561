import {ApplyKycDeclarationTypes} from './actionTypes';
import { IApplyKycDeclaration, IApplyKycDeclarationFetching, IApplyKycDeclarationSuccess, IApplyKycDeclarationError, IApplyKycDeclarationFilter, IApplyKycDeclarationFiterDebounce } from "./interfaces";


export const applyKycDeclaration = (payload:Object): IApplyKycDeclaration => ({
    type: ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION,
    payload
})

export const ApplyKycDeclarationFetching = (isFetching:boolean) : IApplyKycDeclarationFetching => ({
    type: ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_FETCHING,
    isFetching
})

export const ApplyKycDeclarationSuccess = (data:any) : IApplyKycDeclarationSuccess => ({
   type: ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_SUCCESS,
   data
})

export const ApplyKycDeclarationError = (errorMsg:any) : IApplyKycDeclarationError => ({
    type: ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_FAILD,
    error:errorMsg
})

export const ApplyKycDeclarationFilter = (payload: any): IApplyKycDeclarationFilter => ({
    type: ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_FILTER,
    payload
})

export const ApplyKycDeclarationFilterDebounce = (payload: any): IApplyKycDeclarationFiterDebounce => ({
    type: ApplyKycDeclarationTypes.APPLY_KYC_DECLARATION_FILTER_DEBOUNCE,
    payload 
})
