import { put, takeEvery } from 'redux-saga/effects';
import { handleEditExchangeOffer } from 'src/apis/service/P2PExchange';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { updateExchangeOfferFaild, updateExchangeOfferIsFetching, updateExchangeOfferSuccess, UpdateExchangeOfferType } from 'src/store/actions/updateExchangeOffer';


function* updateExchangeOfferSaga(action: any): Generator<any> {


  yield put(updateExchangeOfferIsFetching(true))
  const { payload, history } = action;
  try {
    const data: any = yield handleEditExchangeOffer(payload)
    if (data?.status === 200) {
      yield put(updateExchangeOfferSuccess(data))
      toastSuccess('bo_updated_exchange_offer');
    } else {
      toastError('bo_update_exchange_offer_fail');
      yield put(updateExchangeOfferFaild('error'))
    }
  } catch (error) {
    yield put(updateExchangeOfferFaild(error))
  }
}

export function* watchUpdateExchangeOffer() {
  yield takeEvery(UpdateExchangeOfferType.UPDATE_EXCHANGE_OFFER, updateExchangeOfferSaga)
}