import { debounce, put, takeEvery } from 'redux-saga/effects';
import { handleCreateScorePeriodConfig } from 'src/apis/service/ScorePeriodConfig';
import { CreateScorePeriodConfigTypes } from '../../actions/createScorePeriodConfig';
import { createScorePeriodConfigError, createScorePeriodConfigFetching, createScorePeriodConfigFilter, createScorePeriodConfigSuccess } from '../../actions/createScorePeriodConfig/action';
import { toastError, toastSuccess } from './../../../helpers/toastHelper';

function* createScorePeriodConfigSaga(action: any): Generator<any> {


  yield put(createScorePeriodConfigFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleCreateScorePeriodConfig(payload)
    if (data && data?.status === 200) {
      yield put(createScorePeriodConfigSuccess(data))
      toastSuccess('bo_update_score_period_config_success');
    } else {
      toastError('bo_you_can_t_update_score_period_config');
    }
  } catch (error) {
    toastError('bo_you_can_t_update_score_period_config');
    yield put(createScorePeriodConfigError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(createScorePeriodConfigFilter(action.payload));
}
export function* watchCreateScorePeriodConfig() {
  yield takeEvery(CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG, createScorePeriodConfigSaga)
  yield debounce(500, CreateScorePeriodConfigTypes.CREATE_SCORE_PERIOD_CONFIG_FILTER_DEBOUNCE, handleSearchDebounce)
}
