export const USER_ROLE = [
  {
    id: "1",
    name: "bo_user",
    code: "USER",
    value: 1,
    label: "bo_user_label",
    background: "#ffd8b2",
    color: "#805b36",
    sqlValue: 1,
    bootstrapClassName: "circle-red text-red",
  },
  {
    id: "2",
    name: "bo_agent",
    code: "AGENT",
    value: 2,
    label: "bo_user_role_agent",
    background: "#b3e5fc",
    color: "#23547b",
    sqlValue: 2,
    bootstrapClassName: "circle-blue text-blue",
  },
  {
    id: "3",
    name: "bo_admin",
    code: "ADMIN",
    value: 3,
    label: "bo_admin",
    background: "#c8e6c9",
    color: "#4caf50",
    sqlValue: 3,
    bootstrapClassName: "circle-green text-green",
  },
  {
    id: "4",
    name: "bo_super_admin",
    code: "SUPPER_ADMIN",
    value: 4,
    label: "bo_super_admin",
    background: "#0408491A",
    color: "#040849",
    sqlValue: 4,
    bootstrapClassName: "circle-yellow text-yellow",
  },
  // {
  //   id: '5',
  //   name: 'MEMBER STORE USER',
  //   code: 'MEMBER_STORE_USER',
  //   value: 5,
  //   label: 'Member store user',
  //   background:'#0408491A',
  //   color:'#040849',
  //   sqlValue: 5
  // },
  // {
  //   id: '6',
  //   name: 'GRANTED USER',
  //   code: 'GRANTED_USER',
  //   value: 6,
  //   label: 'Granted user',
  //   background:'#ffcdd2',
  //   color:'#f44336',
  //   sqlValue: 6
  // },
  // {
  //   id: '7',
  //   name: 'SUPER AGENT',
  //   code: 'SUPER_AGENT',
  //   value: 7,
  //   label: 'Super agent',
  //   background:'#ffcdd2',
  //   color:'#f44336',
  //   sqlValue: 7
  // },
];

export const STATUS = [
  {
    id: 1,
    value: 0,
    label: "bo_active",
    background: "#b3e5fc",
    color: "#23547b",
  },
  {
    id: 2,
    value: 2,
    label: "bo_user_status_deactivated",
    background: "#ffcdd2",
    color: "#f44336",
  },
  {
    id: 3,
    value: 1,
    label: "bo_status_blocked",
    background: "#ffcdd2",
    color: "#f44336",
  },
];
