import { AnyAction, Reducer } from 'redux';
import { IGetListTopupRequestState, initialGetListTopupRequestState } from './state';
import { GetListTopupRequestTypes } from 'src/store/actions/getListTopupRequest';

export const GetListTopupRequestReducer: Reducer = (
  state: IGetListTopupRequestState = initialGetListTopupRequestState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListTopupRequestTypes.GET_LIST_TOPUP_REQUEST_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
