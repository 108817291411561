import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { ListParams } from 'src/models/common';

interface IDataP2PExRankAndScore {
  data: [];
  status: number;
  message: string;
}

export interface IDataP2PExRankAndScoreState {
  data: IDataP2PExRankAndScore;
  isFetching: boolean;
  filter: ListParams;
  pagination: {
    totalCount: number;
  };
}

export const initialP2PExRankAndScoreState: IDataP2PExRankAndScoreState = {
  data: {
    data: [],
    message: '',
    status: 0
  },
  isFetching: false,
  filter: { pageNumber: 1, pageSize: PAGE_SIZE_DEFAULT },
  pagination: {
    totalCount: 0
  }
};
