
import {put, takeEvery, select} from 'redux-saga/effects';
import { adminP2PDeleteAgentRank } from 'src/apis/service/Priority';
import { toastSuccess, toastError } from 'src/helpers/toastHelper';
import { 
   DeleteAgentRankType,
   deleteAgentRankAtFetching,
   deleteAgentRankFaild,
   deleteAgentRankSuccess
 } from 'src/store/actions/Agent/RemoveAgentRank';
import { IApplicationState } from 'src/store/IApplicationState';
import { getAgentRankByAdmin } from 'src/store/actions/getAgentRankByAdmin';

function* deleteAgentRankSaga(action:any): Generator<any> {


    // yield put(deleteAgentRankAtFetching(true))
    const {payload} = action
    try {
      const response:any = yield adminP2PDeleteAgentRank(payload)      
      if(response.status === 200){
       const filter : any = yield select((state:IApplicationState) => state.getAgentRankByAdmin.filter)
        yield put(deleteAgentRankSuccess(response));
        yield put(getAgentRankByAdmin(filter))
        toastSuccess('bo_deactive_agent_rank_successfully')
      }else{
        toastError('bo_you_can_t_deactive_agent_rank')
        yield put(deleteAgentRankFaild('error'))
      }
    } catch (error) {
        toastError('bo_you_can_t_deactive_agent_rank')
        yield put(deleteAgentRankFaild(error))
    }
}

export function* watchDeleteAgentRank() {
   yield takeEvery(DeleteAgentRankType.DELETE_AGENT_RANK, deleteAgentRankSaga)
}