import {AnyAction, Reducer} from 'redux';
import {ICheckPhone, initialCheckUserAccountState} from './state';
import {CheckPhoneTypes} from '../../actions';

export const CheckPhoneReducer: Reducer = (
  state: ICheckPhone = initialCheckUserAccountState,
  action: AnyAction,
) => {
  switch (action.type) {
    case CheckPhoneTypes.CHECK_PHONE: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case CheckPhoneTypes.CHECK_PHONE_SUCCESS: {
      return {
        ...state,
        checked: action.data,
        isFetching: false,
      };
    }
    case CheckPhoneTypes.CHECK_PHONE_FAILD: {
      return {
        ...state,
        isFetching: false,
        checked: initialCheckUserAccountState,
      };
    }
    case CheckPhoneTypes.RESET_CHECK_PHONE: {
      return {
        ...state,
        isFetching: false,
        checked: initialCheckUserAccountState,
      }
    }
    default:
      return state;
  }
};