import { put, takeEvery, select } from 'redux-saga/effects';
import { handleDeleteOffer } from 'src/apis/service/P2PTransaction';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';
import { getOfferAll, removeOfferAllFaild, removeOfferAllIsFetching, removeOfferAllSuccess } from 'src/store/actions';
import { IApplicationState } from 'src/store/IApplicationState';
import { RemoveOfferAllType } from '../../actions/removeOfferAll/actionTypes';

function* RemoveOfferAllSaga(action:any):Generator<any>{


  yield put(removeOfferAllIsFetching(true))
  const { id } = action;
  try {
    const IFilterOfferAll: any = yield select((state:IApplicationState) => state.offerAll.filter)
    const dataRemoveOfferAll: any = yield handleDeleteOffer(id);
    if (dataRemoveOfferAll.status === 200) {
      toastSuccess('bo_delete_offer_success');
      yield put(removeOfferAllSuccess(dataRemoveOfferAll))
      yield put(getOfferAll(IFilterOfferAll));
    } else {
      toastError('bo_you_can_t_delete_this_offer_due_to_pending_transaction')
    }
  } catch (error) {
     yield put(removeOfferAllFaild(error)) 
  }
}

export function* watchRemoveOfferAll(){
    yield takeEvery(RemoveOfferAllType.REMOVE_OFFER_ALL, RemoveOfferAllSaga)
}