import { put, takeEvery, debounce } from 'redux-saga/effects';
import { Set2FADisableTypes } from '../../actions/set2FADisable';
import { set2FADisableFetching, set2FADisableSuccess, set2FADisableError, set2FADisableFilter } from '../../actions/set2FADisable/action';
import { handleSet2FADisable } from 'src/apis/service/Agent';
import { toastError, toastSuccess } from 'src/helpers/toastHelper';

function* set2FADisableSaga(action: any): Generator<any> {
  yield put(set2FADisableFetching(true))
  const { payload } = action;
  try {
    const data: any = yield handleSet2FADisable()
    if (data && data?.status === 200) {
      toastSuccess('bo_success');
      yield put(set2FADisableSuccess(data))
    } else {
      toastError('bo_failed');
      yield put(set2FADisableError('error'))
    }
  } catch (error) {
    toastError('bo_failed');
    yield put(set2FADisableError(error))
  }
}
function* handleSearchDebounce(action: any): Generator<any> {
  yield put(set2FADisableFilter(action.payload));
}
export function* watchSet2FADisable() {
  yield takeEvery(Set2FADisableTypes.SET2FA_DISABLE, set2FADisableSaga)
  yield debounce(500, Set2FADisableTypes.SET2FA_DISABLE_FILTER_DEBOUNCE, handleSearchDebounce)
}
