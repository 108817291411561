import { IWalletBalanceObject } from '../CryptoWalletGetNewOverview';

interface IReqData {
        walletBalances: IWalletBalanceObject[],
        transactionsByDay: {
            formatted_time: string //string number
            totalWithdraw: string //string number
            totalDeposit: string //string number
        }[]
}

export interface ICryptoWalletDashboardReqData {
    data: IReqData | null;
    error: any;
    success: boolean;
}

export interface ICryptoWalletDashboardState {
    requestData: ICryptoWalletDashboardReqData;
    isFetching: boolean,
    filter: {
        startDate: Date,
        endDate: Date
    },
}

export const initialCryptoWalletDashboardState: ICryptoWalletDashboardState = {
    requestData: {
        data: null,
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        startDate: new Date,
        endDate: new Date
    },
}

