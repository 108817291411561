import { call, put, takeEvery } from 'redux-saga/effects';
import { handleGetHistoryWalletBalance } from 'src/apis/service/Wallet';
import { getUserBalanceHistoryFaild, getUserBalanceHistoryIsFetching, getUserBalanceHistorySuccess, GetUserBalanceHistoryType } from 'src/store/actions';

function* getListUserBalanceHistorySaga(action: any): Generator<any> {
    yield put(getUserBalanceHistoryIsFetching(true))
    const { payload } = action;
    try {
      const data:any = yield call(handleGetHistoryWalletBalance, payload)      
      if(data && data?.status === 200){
        yield put(getUserBalanceHistorySuccess(data));
      } else {
        yield put(getUserBalanceHistoryFaild('error'))
      }
    } catch (error) {
        yield put(getUserBalanceHistoryFaild(error))
    }
}

export function* watchGetListUserBalanceHistory() {
   yield takeEvery(GetUserBalanceHistoryType.GET_USER_BALANCE_HISTORY , getListUserBalanceHistorySaga)
}