import { AnyAction, Reducer } from 'redux';
import { IGetP2PExchangeHistoryAgentState, initialGetP2PExchangeHistoryAgentState } from './state';
import { GetP2PExchangeHistoryAgentTypes } from 'src/store/actions/getP2PExchangeHistoryAgent';

export const GetP2PExchangeHistoryAgentReducer: Reducer = (
  state: IGetP2PExchangeHistoryAgentState = initialGetP2PExchangeHistoryAgentState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
      };
    }
    case GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_FAILD: {
      return {
        ...state,
        isFetching: false,
        data: null,
        error: action.error,
      };
    }
    case GetP2PExchangeHistoryAgentTypes.GETP2P_EXCHANGE_HISTORY_AGENT_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
