import {ExportOfferSearchType} from './actionTypes';
import {
    IExportOfferSearchIsFetching,
    IExportOfferSearchSuccess,
    IExportOfferSearchFaild,
    IExportOfferSearch
} from './interfaces';

export const exportOfferSearchAtFetching = (isFetching:boolean) : IExportOfferSearchIsFetching  => ({
    type: ExportOfferSearchType.EXPORT_OFFER_SEARCH_FETCHING,
    isFetching
})

export const exportOfferSearch = (payload:Object, xlsx:boolean) : IExportOfferSearch => ({
    type: ExportOfferSearchType.EXPORT_OFFER_SEARCH,
    payload,
    xlsx
})

export const exportOfferSearchSuccess = (data:any) : IExportOfferSearchSuccess => ({
    type: ExportOfferSearchType.EXPORT_OFFER_SEARCH_SUCCESS,
    data
})

export const exportOfferSearchFaild = (error:any) : IExportOfferSearchFaild => ({
    type: ExportOfferSearchType.EXPORT_OFFER_SEARCH_FAILD,
    error
})
