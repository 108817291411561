import { AnyAction, Reducer } from "redux";
import { GetOfferDetailType } from "src/store/actions/myOfferFromAgent/OfferDetail";
import { IOfferDetailState, initialOfferDetailState } from "./state";

export const GetOfferDetailReducer: Reducer = (
  state: IOfferDetailState = initialOfferDetailState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetOfferDetailType.GET_OFFER_DETAIL: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetOfferDetailType.GET_OFFER_DETAIL_ERROR: {
      return {
        ...state,
        error: action?.error,
        isFetching: false,
      };
    }
    case GetOfferDetailType.GET_OFFER_DETAIL_SUCCESS: {
      return {
        ...state,
        data: action?.data,
        isFetching: false,
      };
    }
    case GetOfferDetailType.SET_FILTER_LIST_OFFER_DETAIL: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case GetOfferDetailType.GET_OFFER_DETAIL_CLEAN: {
      return {
        ...initialOfferDetailState,
      };
    }
    default:
      return state;
  }
};
